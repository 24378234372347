import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from './Table'
import { NavLink } from 'react-router-dom'

const Results = ({ items, endpoint, entity }) => {
  return (
    <div className="table__results">
      {items ? (
        items.length ? (
          <p>
            Displaying {items.length} {entity.toLowerCase()}.
          </p>
        ) : (
          <p>No {entity.toLowerCase()} found.</p>
        )
      ) : (
        <p>Loading...</p>
      )}
      <NavLink className="btn table__new" exact to={`${endpoint}/new`}>
        Add New
      </NavLink>
    </div>
  )
}

Results.displayName = 'Results'
Results.propTypes = {
  items: propTypes.array,
  endpoint: propTypes.string,
  entity: propTypes.object,
}

class ItemLists extends Component {
  static propTypes = {
    // mapStateToProps
    itemsLists: propTypes.array,
    itemEndpoint: propTypes.string,
    itemId: propTypes.string,
    lists: propTypes.array,
    loading: propTypes.bool,
    pageError: propTypes.string,
  }

  render() {
    const { itemsLists, itemEndpoint, itemId, lists, loading, pageError } =
      this.props
    return (
      <div className="content__main">
        {!pageError &&
          itemsLists.map((list, index) => {
            const items = lists[index] || []
            const listEndpoint = `${itemEndpoint}/${itemId}${list.endpoint}`
            return (
              <div key={list.endpoint} className="item-list">
                <h2>{list.title}</h2>
                <Results
                  items={items}
                  endpoint={listEndpoint}
                  entity={list.title}
                />
                <Table
                  classes={list.classes}
                  fields={list.fields}
                  items={items}
                  sorting={list.sorting}
                  id={list.id}
                  loading={loading}
                  listEndpoint={listEndpoint}
                  isRelation={true}
                />
              </div>
            )
          })}
      </div>
    )
  }
}

ItemLists.displayName = 'ItemLists'

export default connect(
  (state) => ({
    ...state.itemLists,
    itemsLists: state.page.itemLists,
    itemEndpoint: state.page.item.endpoint,
    itemId: state.page.id,
    pageError: state.page.error,
  }),
  {}
)(ItemLists)
