import { isString, isEmpty } from './utils'
import { pageType } from './constants'
import { formatEnum } from './helpers'
import { sub } from 'date-fns'

export const cssTransitionFade = {
  key: 'item',
  classNames: 'fade',
  timeout: { enter: 250, exit: 0 }
}

export const makeFieldValue = (field, item) => {
  if (!field) return ''
  if (field.includes('.')) {
    field = field.split('.')
    return item[field[0]] ? item[field[0]][field[1]] : ''
  } else {
    return item[field] || item[field] === 0 ? item[field] : ''
  }
}

export const getLastAttr = attr => {
  if (!attr.includes('.')) return attr
  const parts = attr.split('.')
  return parts[parts.length - 1].replace('-', '_')
}

export const makeRelationsEntity = endpoint => {
  const parts = endpoint.split('/')
  return parts[parts.length - 1].replace('-', '_')
}

export const makeRelations = (resp, endpoint, entity, relation) => {
  relation = relation || makeRelationsEntity(endpoint)
  if (entity === null) return resp[relation]
  entity = entity || relation.slice(0, -1)
  return resp[relation].map(i => {
    // important for ...i[entity] to come first in case
    // the relation has a same-named entity
    return { ...i[entity], ...i }
  })
}

export const makeListEndpoint = (endpoint, id, relation) => {
  if (!endpoint) return ''
  let listEndpoint = endpoint.includes(':id')
    ? endpoint.replace(':id', id)
    : endpoint
  return (listEndpoint = relation
    ? `${listEndpoint}/${relation}`
    : listEndpoint)
}

export const makeRelationListEndpoint = (endpoint, path, id, idd) => {
  return `${endpoint.replace(':id', id)}/${idd}/${path}`
}

const makeDatePickerValue = value => {
  switch (value) {
    case 'today':
      return new Date()
    case 'yesterday':
      return sub(new Date(), { days: 1 })
    case 'allTime':
      return null
    default:
      return value
  }
}

export const makeStateOptions = (options, stateOptions, id) => {
  const itemOptions = options.reduce((obj, key) => {
    const optKey = isString(key) ? key : key.endpoint
    const opts = stateOptions[optKey]
    const filtered = key.filter
      ? opts.filter(i => i[key.filter] === parseInt(id))
      : opts
    obj[optKey] = filtered
    return obj
  }, {})
  return itemOptions
}

export const makeLoadingFilter = filter => {
  return {
    ...filter,
    options: [{ name: 'loading...', value: '' }],
    value: ''
  }
}

const getFilterNone = endpoint => {
  switch (endpoint) {
    case 'taxes':
      return [
        { name: 'none selected', value: '' },
        { name: 'Sales Tax', value: 0 }
      ]
    default:
      return [{ name: 'none selected', value: '' }]
  }
}

export const makePageFilters = (
  filters,
  options,
  stateOptions,
  savedFilters
) => {
  const newOptions = options ? makeStateOptions(options, stateOptions) : null
  const newFilters = filters.map(filter => {
    if (filter.type === 'link') {
      return { ...filter }
    }
    if (filter.type === 'datePicker') {
      const value = makeDatePickerValue(filter.value)
      return { ...filter, value: value }
    }
    if (!filter.settings) return { ...filter }
    const { endpoint, key, value, label } = filter.settings || {}
    if (!newOptions || !newOptions[endpoint]) return makeLoadingFilter(filter)
    let loadedOptions = key ? newOptions[endpoint][key] : newOptions[endpoint]
    if (filter.activeOnly) {
      loadedOptions = loadedOptions.filter(i => i.is_active)
    }
    let filterOptions = filter.includeNone ? getFilterNone(endpoint) : []
    loadedOptions.map(i => {
      if (i.isDisabled) {
        filterOptions.push({ ...i, value: '' })
      } else {
        const filterName = Array.isArray(label)
          ? `${i[label[0]]} (${formatEnum(i[label[1]])})`
          : i[label]
        filterOptions.push({ name: filterName, value: i[value] })
      }
    })
    if (filter.includeUnassigned) {
      filterOptions.push({ name: 'Not Assigned', value: 0 })
    }
    // note: removed "|| value !== ''" from filter on 2019-10-30
    // to reset filters on page refresh for /items page
    const savedFilter =
      filter.savedFilter && savedFilters
        ? savedFilters[filter.savedFilter]
        : null
    const derived = filterOptions.filter(i => !i.isDisabled)
    const filterValue = savedFilter
      ? savedFilter
      : filter.defaultValue || derived.length
      ? derived[0].value
      : ''
    return {
      ...filter,
      options: filterOptions,
      value: filterValue
    }
  })
  // if no filters are selected (since one filter is required)
  // check to see if we have a default filter and choose the
  // first option in that filter
  const required = newFilters.filter(i => i.isRequired)
  const selected = required.filter(i => i.value)
  const isDefault = newFilters.find(i => i.isDefault)
  if (required.length && !selected.length && isDefault) {
    const withDefault = newFilters.map(i => {
      if (i.isDefault) {
        i.value = i.options.find(i => i.value).value
      }
      return i
    })
    return withDefault
  }
  return newFilters
}

export const makeOptionKey = endpoint => {
  const parts = endpoint.split('/')
  return parts[parts.length - 1]
}

export const makeItemEndpoint = (page, item) => {
  return !isEmpty(item.data)
    ? `${page.item.backEndpoint || page.item.endpoint}/${
        item.data[page.item.id]
      }`
    : null
}

// handles attr such as 'this' or 'this.name',
export const makeEntityName = (attr, obj) => {
  if (attr.includes('.')) {
    const [entity, attribute] = attr.split('.')
    return obj[entity] ? obj[entity][attribute] : ''
  }
  return obj[attr]
}

// handles attr such as 'this', 'this.name', and ['this.first', 'that.last']
export const makeName = (attr, obj, sep = ' ') => {
  if (!obj || !attr) return ''
  if (typeof attr === 'string') {
    return makeEntityName(attr, obj)
  }
  return attr.map(a => makeEntityName(a, obj)).join(sep)
}

export const makeItemTitle = (attr, obj, pageTitle) => {
  if (!attr) return pageTitle
  let title = makeName(attr, obj)
  title = title ? title.toString() : title
  return !title || !title.replace(/\s/g, '').length
    ? `new ${pageTitle ? pageTitle.toLowerCase() : ''}`
    : title.length > 25
    ? `${title.slice(0, 25)}...`
    : title
}

export const makePageSubtitle = (page, order) => {
  // const subtitle =
  //   page.route.includes('revenue-centers/:id') && item && item.data
  //     ? `${item.data.revenue_center_type} Revenue Center`
  //     : page.subtitle
  switch (page.type) {
    case pageType.order:
      return order.orderId
        ? "You're currently editing an existing order"
        : "You're currently placing a new order"
    default:
      return page.subtitle
  }
}

export const makePageTitle = (page, item, relation, receipt, order) => {
  switch (page.type) {
    case pageType.content:
    case pageType.list:
    case pageType.orderMgmtStores:
      return page.title
    case pageType.itemList:
    case pageType.itemSetting:
    case pageType.itemAttributes:
    case pageType.revenueCenterAttributes:
    case pageType.itemRelations:
    case pageType.deliveryZone:
    case pageType.discountItems:
    case pageType.menu:
    case pageType.hours:
    case pageType.holidays:
    case pageType.blockedHours:
    case pageType.inventory:
    case pageType.recommendedItems:
      return makeItemTitle(page.item.title, item.data, page.title)
    case pageType.orderMgmt:
      return item && item.data ? item.data.full_name : page.title
    case pageType.itemFiles:
      return page.item
        ? makeItemTitle(page.item.title, item.data, page.title)
        : page.title
    case pageType.receipt:
    case pageType.receiptList:
      return `Order #${receipt.data ? receipt.data[page.item.title] : ''}`
    case pageType.order:
      return order.orderId ? `Editing Order #${order.orderId}` : 'New Order'
    case pageType.item:
      return page.list
        ? {
            listLink: page.list.backEndpoint || page.list.endpoint,
            listTitle: page.list.title,
            itemTitle: makeItemTitle(page.item.title, item.data)
          }
        : makeItemTitle(page.item.title, item.data, page.title)
    case pageType.itemRelation:
      return {
        itemLink: (
          page.relation.backEndpoint || page.relation.endpoint
        ).replace(':id', page.id),
        itemTitle: makeItemTitle(page.item.title, item.data),
        relationsTitle: page.relations.title,
        relationTitle: makeItemTitle(
          page.relation.title,
          relation.data,
          page.title
        )
      }
    case pageType.relatedItem:
      return {
        itemLink: (
          page.relatedItem.backEndpoint || page.relatedItem.endpoint
        ).replace(':id', page.id),
        itemTitle: makeItemTitle(page.item.title, item.data),
        relationsTitle: page.relatedItems.title,
        relationTitle: makeItemTitle(
          page.relatedItem.title,
          relation.data,
          page.title
        )
      }
    case pageType.dayparts:
      return makeItemTitle(page.item.title, item.data, page.title)
    default:
      return page.title
  }
}

export const makePageParams = ({ location, match }) => {
  return isEmpty(match.params)
    ? [location.pathname, {}]
    : [match.path, match.params]
}

export const makeNextPosition = (sorting, items) => {
  if (!sorting || sorting.sortType !== 'order') return {}
  const maxPosition =
    items && items.length
      ? Math.max(...items.map(i => i[sorting.sortBy])) || 0
      : 0
  return { [sorting.sortBy]: maxPosition + 1 }
}

export const makeFilterLookup = filters => {
  return filters
    .filter(i => i.value)
    .reduce((obj, item) => {
      obj[item.field] = item
      return obj
    }, {})
}
