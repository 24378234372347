import { isEmpty } from './utils'
import pages from '../lib/pages'
import { valTypes, extApiTypeMap } from './constants'
import {
  makeLocalDateStr,
  maybeMakeDateStr,
  formatDateStr,
  zonedDateStrToDateStr,
  reformatMilitaryTime
} from './helpersDatetime'

// GENERAL UTILITIES

export const capitalize = s => {
  if (!s || !s.length) return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const contains = (arr, values) => {
  return values.filter(i => arr.includes(i)).length > 0
}

export const enumToTitle = s => {
  return !s
    ? ''
    : s
        .split('_')
        .map(i => capitalize(i))
        .join(' ')
}

// const extensions = ['extension', 'ext', 'x']
// let [p, x] = phone.split('extension')
// if (!x) [p, x] = phone.split('ext')
// if (!x) [p, x] = phone.split('x')
// if (!x) [p, x] = phone, null
// p = p.replace(/\D/g, '')
// x = p.replace(/\D/g, '')

export const cleanPhone = phone => {
  if (!phone) return ''
  let p = phone.replace(/\D/g, '')
  if (p.length > 11) return phone
  p = p.length === 11 && p[0] === '1' ? p.slice(1, 11) : p
  p =
    p.length === 10 ? `${p.slice(0, 3)}-${p.slice(3, 6)}-${p.slice(6, 10)}` : p
  return p
}

export const serialize = obj => {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

// https://gist.github.com/mathewbyrne/1280286
export const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace('_', '-') // replace _ with -
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const cleanObj = (obj, keys) => {
  const newObj = {}
  for (const key in obj) {
    if (keys.includes(key)) newObj[key] = obj[key]
  }
  return newObj
}

export const makeMap = (items, id) => {
  return items.reduce((obj, item) => {
    obj[item[id]] = item
    return obj
  }, {})
}

// SPECIALIZED UTILITIES

export const pathStart = pathname => '/' + pathname.split('/')[1]

const maybeMakeParamsDate = value => {
  switch (value) {
    case 'today':
      return makeLocalDateStr(0)
    case 'yesterday':
      return makeLocalDateStr(-1)
    case 'allTime':
      return null
    default:
      return maybeMakeDateStr(value)
  }
}

const maybeMakeNotAssigned = filter => {
  return filter.includeUnassigned && (filter.value === '0' || !filter.value)
    ? 'null'
    : filter.value
}

export const makeQueryParams = params => {
  return !params
    ? null
    : params
        .filter(i => i.value !== '')
        .map(i => ({ ...i, value: maybeMakeParamsDate(i.value) }))
        .map(i => ({ ...i, value: maybeMakeNotAssigned(i) }))
        .filter(i => !(i.type === 'datePicker' && !i.value))
        .map(i => `${i.field}=${i.value}`)
        .join('&')
}

export const convertFromAPI = (route, entityType, data) => {
  const page = pages[route]
  const fields = page[entityType].fields
  if (!fields) return data
  fields
    .filter(f => f.object)
    .map(f => {
      data[f.field] = data[f.object] ? data[f.object][f.field] : ''
    })
  fields.map(f => {
    if (f.valType === valTypes.time) {
      data[f.field] = reformatMilitaryTime(data[f.field])
    }
  })
  return data
}

// SORTING

export const sortAlpha = (a, b) => {
  const first = a ? a.toLowerCase() : ''
  const second = b ? b.toLowerCase() : ''
  if (first < second) return -1
  if (first > second) return 1
  return 0
}

export const sortByArray = (items, sortBy, sortArray) => {
  const itemsLookup = items.reduce((obj, i) => {
    obj[i[sortBy]] = i
    return obj
  }, {})
  return sortArray
    .filter(i => Object.keys(itemsLookup).includes(i))
    .reduce((arr, i) => {
      return [...arr, itemsLookup[i]]
    }, [])
}

export const sortItems = (items, sorting) => {
  if (!sorting || isEmpty(sorting)) return items
  const { sortBy, sortType, sortArray } = sorting
  if (sortArray) return sortByArray(items, sortBy, sortArray)
  items.map(i => {
    if (sortBy.includes('.')) {
      const [entity, field] = sortBy.split('.')
      i.sortBy = i[entity][field]
    } else {
      i.sortBy = i[sortBy]
    }
  })
  let sorted
  switch (sortType) {
    case 'alpha':
      sorted = items.sort((a, b) => sortAlpha(a.sortBy, b.sortBy))
      break
    case 'order':
      sorted = items.sort((a, b) => a.sortBy - b.sortBy)
      break
    case 'alphaTime':
      items = items.map(i => ({ ...i, sortBy: i.sortBy.padStart(8, '0') }))
      sorted = items.sort((a, b) => sortAlpha(a.sortBy, b.sortBy))
      break
    case 'datetime':
      items = items.map(i => ({ ...i, sortBy: new Date(i.sortBy) }))
      sorted = items.sort((a, b) => a.sortBy - b.sortBy)
      break
    default:
      sorted = items || []
  }
  return sorting.desc ? sorted.reverse() : sorted
}

// STRING FORMATTING

const convertEnum = s => {
  return s
    .split('_')
    .map(p => capitalize(p))
    .join(' ')
}

export const formatEnum = s => {
  if (typeof s !== 'string') return s
  switch (s) {
    case 'OLO':
    case 'POS':
      return s
    case 'MC':
      return 'MasterCard'
    case 'MAIN_MENU':
      return 'OLO'
    case 'BRANDIBBLE':
      return 'Open Tender'
    case 'EATSA':
      return 'Brightloom'
    default:
      return convertEnum(s)
  }
}

export const addCommas = (x, d) => {
  return x.toFixed(d).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatQuantity = n => {
  return addCommas(parseFloat(n), 0)
}

export const formatDollars = (str, space = '') => {
  if (str === null || str === '') return 'n/a'
  const floatPrice = parseFloat(str)
  return floatPrice < 0
    ? `($${addCommas(Math.abs(floatPrice), 2)})`
    : `$${addCommas(floatPrice, 2)}${space}`
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const formatCurrency = (x, decimals = 0) => {
  return (
    '$' +
    parseFloat(x)
      .toFixed(decimals)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  )
}

export const formatPercentage = (str, decimals = 3) => {
  return `${parseFloat(str).toFixed(decimals)}%`
}

export const makeAddress = addr => {
  return `${addr.street}, ${addr.city}, ${addr.state} ${addr.postal_code}`
}

export const formatAddress = ([street, city, state, postal_code]) => {
  if (street === '') return ''
  return `${street}, ${city}, ${state} ${postal_code}`
}

export const formatPhone = phone => {
  if (!phone) return phone
  const n = phone.replace(/\D/g, '')
  if (n.length !== 10) return phone
  return `${n.slice(0, 3)}-${n.slice(3, 6)}-${n.slice(6, 10)}`
}

export const formatString = (val, format, tz) => {
  switch (format) {
    case 'truncate':
      return `${val.slice(0, 25)}...`
    case 'bool':
      return val === true ? 'Yes' : 'No'
    case 'int':
      return isNaN(parseInt(val)) ? val : parseInt(val)
    case 'fixed2':
      return isNaN(parseFloat(val)) ? val : parseFloat(val).toFixed(2)
    case 'enum':
      return formatEnum(val)
    case 'phone':
      return formatPhone(val)
    case 'time':
      return reformatMilitaryTime(val)
    case 'datetime':
      return val === '' ? val : formatDateStr(val)
    case 'datetimeDate':
      return val === '' ? val : formatDateStr(val, null, 'MMM d, yyyy')
    case 'datetimeZoned':
      return val === '' ? val : zonedDateStrToDateStr(val, tz)
    case 'quantity':
      return formatQuantity(val)
    case 'currency':
      return formatCurrency(val)
    case 'dollars':
      return formatDollars(val)
    case 'percentage':
      return formatPercentage(val)
    case 'percentage1':
      return formatPercentage(val, 1)
    case 'arrayEnum':
      return Array.isArray(val) ? val.map(i => formatEnum(i)).join(', ') : ''
    case 'address':
      return formatAddress(val)
    case 'extApiType':
      return extApiTypeMap[val]
    default:
      return val
  }
}
