import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from '../lib/utils'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  upsertRelatedItem,
  deleteRelatedItem,
  showItemErrors
} from '../reducers/relation'
import { makeStateOptions, cssTransitionFade } from '../lib/helpersPage'
import Form from './Form'
import ClipLoader from 'react-spinners/ClipLoader'

class RelatedItem extends Component {
  static propTypes = {
    // from props
    endpoint: propTypes.string,
    id: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    validation: propTypes.object,
    // from relation state object
    data: propTypes.object,
    loading: propTypes.bool,
    errors: propTypes.object,
    // from relation reducer
    upsertRelatedItem: propTypes.func,
    deleteRelatedItem: propTypes.func,
    showItemErrors: propTypes.func,
    // other state items
    page: propTypes.object,
    stateOptions: propTypes.object
  }

  render() {
    const { page, stateOptions } = this.props
    const formOptions = page.options
      ? makeStateOptions(page.options, stateOptions, page.id)
      : null
    const isNew = isEmpty(this.props.data)
    if (isNew) window.scrollTo(0, 0)
    return (
      <div className="content__main">
        {this.props.loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!this.props.loading ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="content__main__form">
                <Form
                  isNew={isNew}
                  endpoint={this.props.endpoint}
                  idField={this.props.id}
                  fields={this.props.fields}
                  data={this.props.data}
                  errors={this.props.errors}
                  options={formOptions}
                  upsertItem={this.props.upsertRelatedItem}
                  deleteItem={this.props.deleteRelatedItem}
                  hasDelete={this.props.hasDelete}
                  validation={this.props.validation}
                  showErrors={this.props.showItemErrors}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

RelatedItem.displayName = 'RelatedItem'

export default connect(
  state => ({
    ...state.page.relatedItem,
    ...state.relation,
    page: state.page,
    stateOptions: state.options
  }),
  {
    upsertRelatedItem,
    deleteRelatedItem,
    showItemErrors
  }
)(RelatedItem)
