import { isEmpty } from '../lib/utils'
import { request } from '../lib/services'
import { makeDateValues } from '../lib/helpersDatetime'
import { flashMessage } from './messages'
import { reloadOptions } from './options'
import { makeQueryParams, sortItems } from '../lib/helpers'
import {
  handlePageError,
  clearPageError,
  redirectPage,
  updatePage
} from './page'
import { retryCharges } from './receipt'
import { startNewOrder } from './order'

const initState = {
  items: [],
  listID: null,
  links: {},
  loading: true,
  error: '',
  listId: null,
  reorderEndpoint: null,
  range: null,
  refreshNeeded: false
}

export const parseCursor = url => {
  if (!url) return null
  const params = url.split('?')[1].split('&')
  return params.filter(p => p.startsWith('cursor'))[0]
}

export const ITEMS_CLEAR = 'ITEMS_CLEAR'
export const ITEMS_CLEAR_ONLY = 'ITEMS_CLEAR_ONLY'
export const ITEMS_CLEAR_LOADING = 'ITEMS_CLEAR_LOADING'
export const ITEMS_LOAD = 'ITEMS_LOAD'
export const ITEMS_UPDATE = 'ITEMS_UPDATE'
export const ITEMS_ERROR = 'ITEMS_ERROR'
export const LINKS_SHOW = 'LINKS_SHOW'
export const ITEMS_REORDER_SET = 'ITEMS_REORDER_SET'
export const ITEMS_REORDER_CLEAR = 'ITEMS_REORDER_CLEAR'
export const ITEMS_RANGE = 'ITEMS_RANGE'
export const ITEMS_REFRESH_NEEDED = 'ITEMS_REFRESH_NEEDED'

export const loadItems = items => ({ type: ITEMS_LOAD, payload: items })
export const showLinks = links => ({ type: LINKS_SHOW, payload: links })
export const clearItems = () => ({ type: ITEMS_CLEAR })
export const clearItemsOnly = () => ({ type: ITEMS_CLEAR_ONLY })
export const clearLoading = () => ({ type: ITEMS_CLEAR_LOADING })
export const showItemsError = msg => ({ type: ITEMS_ERROR, payload: msg })
export const setReorderEndpoint = data => ({
  type: ITEMS_REORDER_SET,
  payload: data
})
export const clearReorderEndpoint = () => ({ type: ITEMS_REORDER_CLEAR })
export const updateRange = range => ({ type: ITEMS_RANGE, payload: range })
export const updateRefresh = bool => ({
  type: ITEMS_REFRESH_NEEDED,
  payload: bool
})

export const groupItems = (items, { child, parent }, sorting) => {
  const children = items.filter(i => i[child])
  const groups = children.reduce((obj, i) => {
    obj[i[child]] = obj[i[child]] ? [...obj[i[child]], i] : [i]
    obj[i[child]] = sortItems(obj[i[child]], sorting)
    return obj
  }, {})
  const parents = items
    .filter(i => !i[child])
    .map(c => ({ ...c, children: groups[c[parent]] || [] }))
  return parents
}

const filterItems = (items, filterBy) => {
  const { field, values } = filterBy
  return items.filter(i => values.includes(i[field]))
}

const makeParamsObj = params => {
  if (!params) return null
  const splitParams = params.split('&')
  return splitParams.reduce((obj, item) => {
    const [key, value] = item.split('=')
    obj[key] = value
    return obj
  }, {})
}

const makeGroupLinks = groupUrl => {
  const itemName = groupUrl.includes('categories') ? 'Menu Items' : 'Modifiers'
  const links = [
    { name: 'Images', icon: 'Image', url: `${groupUrl}/files` },
    { name: 'Menus', icon: 'Map', url: `${groupUrl}/menus` },
    { name: 'Dayparts', icon: 'Clock', url: `${groupUrl}/dayparts` },
    { name: itemName, icon: 'List', url: `${groupUrl}/items` }
  ]
  return links
}

const processCategories = (items, params) => {
  const withLinks = items.map(i => {
    const groupUrl = `/categories/${i.category_id}`
    const links = makeGroupLinks(groupUrl)
    return { ...i, links }
  })
  const paramsObj = makeParamsObj(params)
  if (!paramsObj || !('menu_id' in paramsObj)) return withLinks
  return withLinks.map(i => {
    i.menus.map(c => {
      if (c.menu.menu_id === parseInt(paramsObj.menu_id)) {
        i = { ...c, ...i }
      }
    })
    return i
  })
}

const processModifierGroups = items => {
  return items.map(i => {
    const groupUrl = `/modifier-groups/${i.modifier_group_id}`
    const links = makeGroupLinks(groupUrl).filter(i => i.name !== 'Dayparts')
    return { ...i, links }
  })
}

const makeItemLinks = (groupUrl, itemUrl, includeMods = false) => {
  const links = [
    { name: 'Prices', icon: 'DollarSign', url: `${itemUrl}/attributes` },
    { name: 'Images', icon: 'Image', url: `${itemUrl}/files` },
    { name: 'Taxes', icon: 'Percent', url: `${itemUrl}/taxes` },
    { name: 'Tags', icon: 'Tag', url: `${itemUrl}/tags` },
    { name: 'Allergens', icon: 'AlertTriangle', url: `${itemUrl}/allergens` },
    {
      name: 'Nutritional Info',
      icon: 'Info',
      url: `${itemUrl}/nutritional-info`
    }
  ]
  if (includeMods) {
    const modsLink = {
      name: 'Modifier Groups',
      icon: 'List',
      url: `${groupUrl}${itemUrl}`
    }
    links.push(modsLink)
  }
  return links
}

export const processMenuItems = (items, params) => {
  const paramsObj = makeParamsObj(params)
  if (!paramsObj) return items
  if ('category_id' in paramsObj) {
    return items.map(i => {
      i.categories.map(c => {
        const groupId = c.category.category_id
        if (groupId === parseInt(paramsObj.category_id)) {
          const groupUrl = `/categories/${groupId}`
          const itemUrl = `/items/${i.item_id}`
          const links = makeItemLinks(groupUrl, itemUrl, true)
          i = { ...c, ...i, links }
        }
      })
      return i
    })
  } else if ('modifier_group_id' in paramsObj) {
    return items.map(i => {
      i.modifier_groups.map(c => {
        const groupId = c.modifier_group.modifier_group_id
        if (groupId === parseInt(paramsObj.modifier_group_id)) {
          const groupUrl = `/modifier-groups/${groupId}`
          const itemUrl = `/items/${i.item_id}`
          const links = makeItemLinks(groupUrl, itemUrl)
          i = { ...c, ...i, links }
        }
      })
      return i
    })
  }
  return items.map(i => {
    const itemUrl = `/items/${i.item_id}`
    const links = makeItemLinks(null, itemUrl)
    return { ...i, links }
  })
  // return items
}

const processItems = (items, endpoint, queryParams) => {
  const endpointRoot = endpoint.split('?')[0]
  switch (endpointRoot) {
    case '/categories':
      return processCategories(items, queryParams)
    case '/modifier-groups':
      return processModifierGroups(items)
    case '/items':
      return processMenuItems(items, queryParams)
    case '/stores':
      return items.filter(i => !i.is_master)
    case '/revenue-centers':
      return items.filter(i => i.store && !i.store.is_master)
    case '/regenerate-menus':
      return processRegenItems(items)
    default:
      return items
  }
}

const processRegenItems = items => {
  const newItems = items.map(item => {
    if (!item.errors) {
      item.status = item.pending ? 'In queue' : '--'
      return item
    }
    const { errors, price_errors, mapping_errors } = item.errors
    if (errors.length) {
      item.status = 'Failed'
      item.errorsType = 'Fatal'
      item.errors = errors
    } else if (price_errors.length) {
      item.status = 'Failed'
      item.errorsType = 'Price'
      item.errors = price_errors
    } else if (mapping_errors.length) {
      item.status = 'Completed with errors'
      item.errorsType = 'Mapping'
      item.errors = mapping_errors
    } else {
      item.status = 'Completed'
      item.errorsType = null
      item.errors = null
    }
    return item
  })
  return newItems
}

export const fetchItems = cursor => {
  return (dispatch, getState) => {
    const { token, brand, page, search } = getState()
    const { list } = page
    // if this page doesn't have a list, do nothing
    if (isEmpty(list) || search.query) return
    dispatch(clearItems())
    dispatch(clearPageError())
    let endpoint = list.endpoint
    let filters = list.filters || []
    const nonEmptyFilters = filters.filter(f => f.value !== '')
    if (list.filtersRequired && !nonEmptyFilters.length) {
      dispatch(loadItems([]))
      return dispatch(showLinks({}))
    }
    if (list.params) filters = [...filters, ...list.params]
    // handle path filters and special reorder endpoints (for relations)
    const pathFilters = filters.filter(i => i.isPath)
    let nonEmpty = []
    if (pathFilters.length) {
      nonEmpty = pathFilters.filter(p => p.value)
      if (nonEmpty.length) {
        const e = nonEmpty[0]
        endpoint = endpoint.replace(':id', e.value)
        if (e.endpoint) {
          const listId = parseInt(e.value)
          const reorderEndpoint = e.endpoint.replace(':id', listId)
          dispatch(setReorderEndpoint({ reorderEndpoint, listId }))
        }
      }
    }
    if (pathFilters.length && !nonEmpty.length) {
      dispatch(loadItems([]))
      return dispatch(showLinks({}))
    }
    // remove path filters
    filters = filters.filter(i => !i.isPath)
    // handle pure reorder endpoints separately
    const reorderFilters = filters.filter(i => i.endpoint && !i.isPath)
    if (reorderFilters.length) {
      nonEmpty = reorderFilters.filter(p => p.value)
      if (nonEmpty.length) {
        const e = nonEmpty[0]
        const listId = parseInt(e.value)
        const reorderEndpoint = e.endpoint.replace(':id', listId)
        dispatch(setReorderEndpoint({ reorderEndpoint, listId }))
      }
    }
    // clear reorderEnpoint if not relevant
    if (!pathFilters.length && !reorderFilters.length) {
      dispatch(clearReorderEndpoint())
    }
    const queryParams = makeQueryParams(filters)
    if (queryParams) endpoint += `?${queryParams}`
    if (cursor) endpoint += queryParams ? `&${cursor}` : `?${cursor}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        let items = resp.data || resp
        // TODO: add a timezone
        if (list.entity) items = items.map(i => ({ ...i[list.entity], ...i }))
        items = processItems(items, endpoint, queryParams)
        if (list.filterBy) items = filterItems(items, list.filterBy)
        if (list.groupBy) items = groupItems(items, list.groupBy, list.sorting)
        items = sortItems(items, list.sorting)
        dispatch(loadItems(items))
        !items.length
          ? dispatch(showLinks({}))
          : dispatch(showLinks(resp.links || {}))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(clearLoading()))
  }
}

export const refreshItems = () => {
  return dispatch => dispatch(fetchItems())
}

export const updateItems = item => {
  return (dispatch, getState) => {
    const { list, page } = getState()
    if (!page.list || !list.items || !list.items.length) return
    let newItems = list.items.filter(
      i => i[page.item.id] !== item[page.item.id]
    )
    newItems = [...newItems, item]
    newItems = sortItems(newItems, page.list.sorting)
    dispatch({ type: ITEMS_UPDATE, payload: newItems })
  }
}

export const removeItem = itemId => {
  return (dispatch, getState) => {
    const { list, page } = getState()
    if (!list.items || !list.items.length) return
    let newItems = list.items.filter(i => i[page.item.id] !== itemId)
    newItems = sortItems(newItems, page.list.sorting)
    dispatch({ type: ITEMS_UPDATE, payload: newItems })
  }
}

export const updateItem = (id, field, value) => {
  return (dispatch, getState) => {
    const { list, page } = getState()
    const item = list.items.filter(i => i[page.list.id] === id)
    const updatedItem = { ...item[0], [field]: value }
    const otherItems = list.items.filter(i => i[page.list.id] !== id)
    let newItems = [...otherItems, updatedItem]
    newItems = sortItems(newItems, page.list.sorting)
    dispatch({ type: ITEMS_UPDATE, payload: newItems })
  }
}

export const listActions = actionType => {
  return (dispatch, getState) => {
    const { token, brand, page, list } = getState()
    let items, method
    if (actionType === 'upsertItems') {
      method = 'PUT'
      let fields = page.list.fields
        .filter(f => f.format === 'select')
        .map(f => f.field[0])
      fields = [...fields, page.list.id]
      items = list.items.map(item => {
        return fields.reduce((obj, field) => {
          obj[field] = item[field]
          return obj
        }, {})
      })
    } else if (actionType === 'regenMenus') {
      method = 'POST'
      items = list.items
        .filter(i => i.isSelected)
        .map(i => ({ [page.list.id]: i[page.list.id] }))
    } else if (actionType === 'newOrder') {
      return dispatch(startNewOrder())
    } else if (actionType === 'giftCardBatch') {
      return dispatch(redirectPage('/gift-card-batches/new'))
    } else if (actionType === 'retryCharges') {
      const orderIds = list.items
        .filter(i => i.isSelected)
        .map(i => i[page.list.id])
      return dispatch(retryCharges(orderIds))
    }
    request(token, brand, page.list.endpoint, method, items)
      .then(() => {
        switch (actionType) {
          case 'regenMenus':
            dispatch(fetchItems())
            return dispatch(flashMessage('Regen tasks submitted!'))
          default:
            return dispatch(flashMessage('Successfully updated!'))
        }
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const selectAll = () => {
  return (dispatch, getState) => {
    const { list, page } = getState()
    const { sorting } = page.list
    const selectedItems = list.items.filter(i => i.isSelected)
    const isSelected = selectedItems.length ? false : true
    let newItems = list.items.map(i => ({ ...i, isSelected: isSelected }))
    newItems = sortItems(newItems, sorting)
    dispatch({ type: ITEMS_UPDATE, payload: newItems })
  }
}

export const selectItem = (itemId, isSelected) => {
  return (dispatch, getState) => {
    const { list, page } = getState()
    const { id, sorting } = page.list
    let selectedItem = list.items.filter(i => i[id] === itemId)
    if (!selectedItem.length) return dispatch(flashMessage('Cannot find item'))
    selectedItem = { ...selectedItem[0], isSelected: isSelected }
    const otherItems = list.items.filter(i => i[id] !== itemId)
    let newItems = [...otherItems, selectedItem]
    newItems = sortItems(newItems, sorting)
    dispatch({ type: ITEMS_UPDATE, payload: newItems })
  }
}

export const fetchLink = link => {
  return dispatch => {
    dispatch(fetchItems(parseCursor(link)))
  }
}

export const reorderList = (endpoint, data) => {
  return (dispatch, getState) => {
    const { token, brand, list, page } = getState()
    const { id: idField, sorting } = page.list
    // update order before request
    const orderMap = data.reduce((obj, item) => {
      obj[item[idField]] = item[sorting.sortBy]
      return obj
    }, {})
    const newItems = list.items.map(i => {
      i[sorting.sortBy] = orderMap[i[idField]] || i[sorting.sortBy]
      return i
    })
    const items = sortItems(newItems, sorting)
    dispatch(loadItems(items))
    endpoint = list.reorderEndpoint || `${endpoint}/reorder`
    const method = endpoint.includes('reorder') ? 'POST' : 'PUT'
    request(token, brand, endpoint, method, data)
      .then(() => {
        dispatch(flashMessage('Items reordered!'))
        dispatch(reloadOptions(endpoint))
      })
      .catch(err => {
        // if reorder request fails, restore original order
        dispatch(loadItems(list.items))
        window.scroll(0, 0)
        dispatch(handlePageError(err))
      })
  }
}

export const updateDateRange = range => {
  return (dispatch, getState) => {
    dispatch(updateRange(range))
    const { brand, page } = getState()
    const { start, end } = makeDateValues(range, brand.start_day)
    // need to do it this way to avoid mutating the existing filters
    let newFilters = []
    page.list.filters.map(i => {
      if (i.field === 'start_date') {
        newFilters.push({ ...i, value: start })
      } else if (i.field === 'end_date') {
        newFilters.push({ ...i, value: end })
      } else {
        newFilters.push({ ...i })
      }
    })
    const newList = { ...page.list, filters: newFilters }
    dispatch(updatePage({ list: newList }))
    dispatch(fetchItems())
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ITEMS_CLEAR:
      return { ...initState }
    case ITEMS_CLEAR_ONLY:
      return { ...initState, loading: false }
    case ITEMS_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case ITEMS_UPDATE:
      return { ...state, items: action.payload }
    case LINKS_SHOW:
      return { ...state, links: action.payload }
    case ITEMS_ERROR:
      return { ...state, error: action.payload, loading: false }
    case ITEMS_CLEAR_LOADING:
      return { ...state, loading: false }
    case ITEMS_REORDER_SET:
      return { ...state, ...action.payload }
    case ITEMS_REORDER_CLEAR:
      return { ...state, reorderEndpoint: null }
    case ITEMS_RANGE:
      return { ...state, range: action.payload }
    case ITEMS_REFRESH_NEEDED:
      return {
        ...state,
        refreshNeeded: action.payload,
        items: action.payload === true ? [] : state.items
      }
    default:
      return state
  }
}
