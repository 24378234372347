import propTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeCrumbs } from '../lib/routes'
import { pageType, pageClass } from '../lib/constants'
import { slugify } from '../lib/helpers'
import {
  makePageTitle,
  makePageSubtitle,
  makePageParams
} from '../lib/helpersPage'
import { loadPage, toggleTabs, toggleSidebar } from '../reducers/page'
import { toggleCart } from '../reducers/order'
import { resetOptions } from '../reducers/options'
import { PageError } from './PageError'
import MainOverlay from './MainOverlay'
import Aside from './Aside'
import Image from './Image'
import Sidebar from './Sidebar'
import Nav from './Nav'
import Tabs from './Tabs'
import Header from './Header'
import List from './List'
import Item from './Item'
import Relations from './Relations'
import Relation from './Relation'
import Setting from './Setting'
import ItemSetting from './ItemSetting'
import Files from './Files'
import Dayparts from './Dayparts'
import Avails from './Avails'
import Attributes from './Attributes'
import Modal from './Modal'
import Content from './Content'
import Message from './Message'
import PageHeader from './PageHeader'
import DeliveryZone from './DeliveryZone'
import DiscountItems from './DiscountItems'
import BuiltMenu from './BuiltMenu'
import Hours from './Hours'
import Holidays from './Holidays'
import Receipt from './Receipt'
import ReceiptTabs from './ReceiptTabs'
import Orders from './Orders'
import OrderMgmt from './OrderMgmt'
import OrderMgmtStores from './OrderMgmtStores'
import Order from './Order'
import OrderSidebar from './OrderSidebar'
import ItemList from './ItemList'
import ItemLists from './ItemLists'
import Punches from './Punches'
import RelatedItem from './RelatedItem'
import DailyItems from './DailyItems'
import Inventory from './Inventory'
import Reward from './Reward'
import GiftCard from './GiftCard'
import Iframe from './Iframe'
import BlockedHours from './BlockedHours'
import Mapping from './Mapping'
import MenuExt from './MenuExt'
import MenuExtUpdate from './MenuExtUpdate'
import Report from './Report'
import EntityExt from './EntityExt'
import Import from './Import'
import ImportErrors from './ImportErrors'
import ListOfItems from './ListOfItems'
import HoursForWeek from './HoursForWeek'
import Sync from './Sync'
import MenuExtUpdates from './MenuExtUpdates'
import OrderTimes from './OrderTimes'
import Config from './Config'
import RecommendedItems from './RecommendedItems'
import Footer from './Footer'
import { maybeRefreshVersion } from '../lib/version'
import Customer from './Customer'
// import logo from '../assets/open-tender-logo-black_3600x484.png'

const ItemTabs = ({ pathname }) => (
  <>
    {/* IMPORTANT: must pass the pathname as a prop in order
     to get the Nav component to re-render when we change pages */}
    <Nav pathname={pathname} />
    <Tabs />
  </>
)

ItemTabs.displayName = 'ItemTabs'
ItemTabs.propTypes = {
  pathname: propTypes.string
}

const renderPage = page => {
  switch (page.type) {
    case 'content':
      return <Content {...page} />
    case 'list':
      return <List {...page.list} />
    case 'item':
      return <Item />
    case 'itemList':
    case 'receiptList':
      return <ItemList />
    case 'itemLists':
      return <ItemLists />
    case 'itemFiles':
      return <Files />
    case 'itemSetting':
      return <ItemSetting />
    case 'setting':
      return <Setting />
    case 'itemRelations':
      return <Relations />
    case 'itemRelation':
      return <Relation {...page.relation} />
    case 'relatedItem':
      return <RelatedItem />
    case 'dayparts':
      return <Dayparts />
    case 'avails':
      return <Avails />
    case 'dailyItems':
      return <DailyItems />
    case 'inventory':
      return <Inventory />
    case 'attributes':
    case 'itemAttributes':
    case 'revenueCenterAttributes':
      return <Attributes />
    case 'deliveryZone':
      return <DeliveryZone />
    case 'menu':
      return <BuiltMenu />
    case 'reward':
      return <Reward />
    case 'giftCard':
      return <GiftCard />
    case 'receipt':
      return <Receipt />
    case 'order':
      return <Order />
    case 'orders':
      return <Orders />
    case 'orderMgmt':
      return <OrderMgmt />
    case 'orderMgmtStores':
      return <OrderMgmtStores />
    case 'discountItems':
      return <DiscountItems />
    case 'hours':
      return <Hours />
    case 'holidays':
      return <Holidays />
    case 'blockedHours':
      return <BlockedHours />
    case 'orderTimes':
      return <OrderTimes />
    case 'hoursForWeek':
      return <HoursForWeek />
    case 'punches':
      return <Punches />
    case 'iframe':
      return <Iframe />
    case 'mapping':
      return <Mapping />
    case 'menuExt':
      return <MenuExt />
    case 'menuExtUpdate':
      return <MenuExtUpdate />
    case 'menuExtUpdates':
      return <MenuExtUpdates />
    case 'entityExt':
      return <EntityExt />
    case 'report':
      return <Report />
    case 'import':
      return <Import />
    case 'sync':
      return <Sync />
    case 'importErrors':
      return <ImportErrors />
    case 'listOfItems':
      return <ListOfItems />
    case 'config':
      return <Config />
    case 'recommendedItems':
      return <RecommendedItems />
    case 'customer':
      return <Customer />
    default:
      return null
  }
}

const renderTabs = (page, pathname) => {
  switch (page.type) {
    case 'item':
    case 'itemList':
    case 'itemLists':
    case 'itemFiles':
    case 'itemSetting':
    case 'itemRelations':
    case 'itemRelation':
    case 'relatedItem':
    case 'setting':
    case 'dayparts':
    case 'deliveryZone':
    case 'discountItems':
    case 'hours':
    case 'holidays':
    case 'blockedHours':
    case 'orderTimes':
    case 'itemAttributes':
    case 'revenueCenterAttributes':
    case 'inventory':
    case 'menu':
    case 'mapping':
    case 'menuExt':
    case 'menuExtUpdate':
    case 'menuExtUpdates':
    case 'entityExt':
    case 'import':
    case 'importErrors':
    case 'listOfItems':
    case 'sync':
    case 'config':
    case 'recommendedItems':
    case 'iframe':
    case 'customer':
      return <ItemTabs pathname={pathname} />
    case 'receipt':
    case 'receiptList':
      return <ReceiptTabs pathname={pathname} {...page} />
    case 'order':
      return <OrderSidebar />
    default:
      return null
  }
}

class Page extends Component {
  static propTypes = {
    location: propTypes.object,
    match: propTypes.object,
    loadPage: propTypes.func,
    clearItem: propTypes.func,
    user: propTypes.object,
    brand: propTypes.object,
    page: propTypes.object,
    item: propTypes.object,
    receipt: propTypes.object,
    order: propTypes.object,
    relation: propTypes.object,
    modal: propTypes.object,
    toggleCart: propTypes.func,
    toggleSidebar: propTypes.func,
    toggleTabs: propTypes.func,
    resetOptions: propTypes.func
  }

  componentDidMount() {
    const { resetOptions } = this.props
    maybeRefreshVersion(resetOptions)
    this.props.loadPage(...makePageParams(this.props))
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { resetOptions } = this.props
      maybeRefreshVersion(resetOptions)
      window.scrollTo(0, 0)
      this.props.loadPage(...makePageParams(this.props))
    }
  }

  toggleOverlay = evt => {
    evt.preventDefault()
    const { page, toggleTabs, toggleSidebar, toggleCart } = this.props
    page.sidebarOpen
      ? toggleSidebar()
      : page.tabsOpen
      ? toggleTabs()
      : toggleCart()
    evt.target.blur()
  }

  render() {
    const {
      page,
      item,
      relation,
      receipt,
      order,
      location: { pathname }
    } = this.props
    // const isNew = pathname.includes('new')
    const isContent = page.type === pageType.content
    const crumbs = makeCrumbs(pathname, page, item, relation, receipt, order)
    if (page.type !== pageType.orderMgmt) {
      const docTitle = [...crumbs]
        .reverse()
        .map(i => i.name)
        .join(' - ')
      document.title =
        docTitle === 'Welcome' ? 'Open Tender Admin Portal' : docTitle
    }
    const pageClassName = page.appClass || pageClass[page.type]
    const tabsOpen = page.tabsOpen ? '-open' : ''
    const sidebarOpen = page.sidebarOpen ? '-open-sidebar' : ''
    const pageClasses = `app ${pageClassName} ${
      page.classes || ''
    } ${tabsOpen} ${sidebarOpen}`
    const isAside = pageClasses.includes('app--aside')
    const contentClasses = page.contentClasses || ''
    const pageTitle = makePageTitle(page, item, relation, receipt, order)
    const pageSubtitle = makePageSubtitle(page, order)
    const header = { title: pageTitle, subtitle: pageSubtitle }
    const redirect = !this.props.user ? '/login' : page.redirect || ''
    // const modalClass = modal && modal.title.length ? 'is-modal' : ''
    const showOverlay = page.tabsOpen || page.sidebarOpen || order.cartOpen
    return (
      <div>
        {redirect.length && redirect !== pathname ? (
          <Redirect to={redirect} />
        ) : (
          <div className={pageClasses}>
            <Modal />
            <div className="app__content">
              <Sidebar brand={this.props.brand} user={this.props.user} />
              <Header crumbs={crumbs} />
              <MainOverlay show={showOverlay} toggle={this.toggleOverlay} />
              <div id="top" className={`main page--${slugify(page.title)}`}>
                <div className={`content ${contentClasses}`}>
                  <div className="content__container">
                    {!page.hideHeader && (
                      <>
                        <PageHeader {...header} />
                        <PageError msg={page.error} />
                      </>
                    )}
                    {renderPage(page)}
                  </div>
                </div>
                <Footer user={this.props.user} />
                {isContent && <Image image={page.image} />}
              </div>
              <Aside show={isAside} />
              {renderTabs(page, pathname)}
              <Message />
            </div>
          </div>
        )}
      </div>
    )
  }
}

Page.displayName = 'Page'

export default connect(
  state => ({
    page: state.page,
    item: state.item,
    receipt: state.receipt,
    order: state.order,
    relation: state.relation,
    user: state.user,
    brand: state.brand,
    modal: state.modal
  }),
  { loadPage, toggleTabs, toggleSidebar, toggleCart, resetOptions }
)(Page)
