import propTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-scroll'
import { slugify } from '../lib/helpers'

// https://pantaley.com/blog/Create-sticky-header-only-with-React-Hooks/
// https://www.digitalocean.com/community/tutorials/how-to-implement-smooth-scrolling-in-react
// https://www.npmjs.com/package/react-scroll

const StickyNav = ({ items, offset = -100 }) => {
  const [isSticky, setSticky] = useState(false)
  const stickyRef = useRef(null)

  const handleScroll = () => {
    if (stickyRef.current) {
      setSticky(stickyRef.current.getBoundingClientRect().top <= 50)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const stickyClass = `sticky ${isSticky ? '-stuck' : ''}`

  return (
    <div className={stickyClass} ref={stickyRef}>
      <div className="sticky__inner">
        <div className="container">
          <div className="sticky__items">
            <ul>
              {items.map(item => {
                const sectionId = slugify(item)
                return (
                  <li key={sectionId}>
                    {/* <a href={`#${sectionId}`}>{item}</a> */}
                    <Link
                      activeClass="active"
                      to={sectionId}
                      spy={true}
                      smooth={true}
                      offset={offset}
                      duration={500}
                    >
                      {item}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

StickyNav.displayName = 'StickyNav'
StickyNav.propTypes = {
  items: propTypes.array,
  offset: propTypes.number
}

export default StickyNav
