import propTypes from 'prop-types'
import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { makeRelationsEntity } from '../lib/helpersPage'
import { fetchItem } from '../reducers/item'
import { searchAddRelation, uploadCSV } from '../reducers/relations'
import { searchAddRelationListItem } from '../reducers/relation'
import { RefreshCw } from 'react-feather'

const UploadCSV = ({ text, endpoint, uploadCSV }) => {
  const uploadButton = useRef()
  const fileInputRef = useRef()
  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const onFilesAdded = evt => {
    uploadButton.current.blur()
    const csv = evt.target.files[0]
    uploadCSV(endpoint, csv)
    evt.target.value = null
  }

  return (
    <button className="btn" onClick={openFileDialog} ref={uploadButton}>
      {text}
      <input
        ref={fileInputRef}
        className="dz-file-input"
        type="file"
        onChange={onFilesAdded}
      />
    </button>
  )
}

UploadCSV.displayName = 'UploadCSV'
UploadCSV.propTypes = {
  text: propTypes.string,
  endpoint: propTypes.string,
  uploadCSV: propTypes.func
}

class RelationsResults extends Component {
  static propTypes = {
    items: propTypes.array,
    endpoint: propTypes.string,
    id: propTypes.string,
    search: propTypes.object,
    hideNew: propTypes.bool,
    upload: propTypes.object,
    fetchItem: propTypes.func,
    searchAddRelation: propTypes.func,
    searchAddRelationListItem: propTypes.func,
    uploadCSV: propTypes.func
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.fetchItem()
    evt.target.blur()
  }

  handleClick = evt => {
    evt.preventDefault()
    this.props.search.isRelationList
      ? this.props.searchAddRelationListItem(this.props.search)
      : this.props.searchAddRelation(this.props.search)
  }

  render() {
    const { items, endpoint, search, hideNew, upload, uploadCSV } = this.props
    const entity = makeRelationsEntity(endpoint).replace('_', ' ')
    return (
      <div className="table__results">
        <div className="table__results__message">
          <span className="table__results__refresh">
            <button className="btn-link" onClick={this.handleRefresh}>
              <RefreshCw size={16} />
            </button>
          </span>
          {items ? (
            items.length ? (
              <p>
                Displaying {items.length} {entity}.
              </p>
            ) : (
              <p>No {entity} found.</p>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="table__results__buttons">
          {upload && <UploadCSV {...upload} uploadCSV={uploadCSV} />}
          {search ? (
            <button className="btn table__new" onClick={this.handleClick}>
              Add New
            </button>
          ) : !hideNew ? (
            <NavLink className="btn table__new" exact to={`${endpoint}/new`}>
              Add New
            </NavLink>
          ) : null}
        </div>
      </div>
    )
  }
}

RelationsResults.displayName = 'RelationsResults'

export default connect(null, {
  fetchItem,
  searchAddRelation,
  searchAddRelationListItem,
  uploadCSV
})(RelationsResults)
