import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  addNewRecommendedItem,
  fetchRecommendedItems
} from '../reducers/recommendedItems'
import { RefreshCw } from 'react-feather'

class RecommendedItemsResults extends Component {
  static propTypes = {
    items: propTypes.array,
    recommendationType: propTypes.string
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.fetchRecommendedItems()
    evt.target.blur()
  }

  handleClick = evt => {
    evt.preventDefault()
    const config = {
      title: `Add new ${this.props.recommendationType.toLowerCase()} item`,
      subtitle: 'Choose an item from the same menu',
      classes: 'modal--big',
      type: 'recommendedItem',
      args: { recommendationType: this.props.recommendationType }
    }
    this.props.addNewRecommendedItem(config)
  }

  render() {
    const { items, recommendationType } = this.props

    return (
      <div className="table__results">
        <div className="table__results__message">
          <span className="table__results__refresh">
            <button className="btn-link" onClick={this.handleRefresh}>
              <RefreshCw size={16} />
            </button>
          </span>
          {items ? (
            items.length ? (
              <p>
                Displaying {items.length} {recommendationType.toLowerCase()}{' '}
                items.
              </p>
            ) : (
              <p>No {recommendationType.toLowerCase()} items found.</p>
            )
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="table__results__buttons">
          <button className="btn table__new" onClick={this.handleClick}>
            Update List
          </button>
        </div>
      </div>
    )
  }
}

RecommendedItemsResults.displayName = 'RecommendedItemsResults'

export default connect(null, {
  addNewRecommendedItem,
  fetchRecommendedItems
})(RecommendedItemsResults)
