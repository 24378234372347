import { handleError } from '../lib/errors'

const initState = {
  data: {},
  errors: {},
  reset: false,
  submitting: false,
  show: false,
}

const url = process.env.REACT_APP_API_URL

export const RESET_RESET_FORM = 'RESET_RESET_FORM'
export const UPDATE_RESET_FORM = 'UPDATE_RESET_FORM'
export const SHOW_RESET_ERRORS = 'SHOW_RESET_ERRORS'
export const TOGGLE_SUBMITTING_RESET = 'TOGGLE_SUBMITTING_RESET'
export const TOGGLE_RESET_RESET = 'TOGGLE_RESET_RESET'
export const TOGGLE_SHOW_PASSWORD = 'TOGGLE_SHOW_PASSWORD'

export const loadForm = (data) => ({ type: UPDATE_RESET_FORM, payload: data })
export const showFormErrors = (errors) => ({
  type: SHOW_RESET_ERRORS,
  payload: errors,
})
export const toggleSubmitting = () => ({ type: TOGGLE_SUBMITTING_RESET })
export const toggleReset = () => ({ type: TOGGLE_RESET_RESET })
export const toggleShow = () => ({ type: TOGGLE_SHOW_PASSWORD })
export const resetForm = () => ({ type: RESET_RESET_FORM })

export const updateInput = (field, value) => {
  return (dispatch, getState) => {
    const { data } = getState().reset
    dispatch(loadForm({ ...data, [field]: value }))
  }
}

export const submitForm = () => {
  return (dispatch, getState) => {
    const { data: form } = getState().reset
    if (form.password !== form.confirm) {
      const msg = "Passwords don't match. Please try again."
      return dispatch(showFormErrors({ form: msg }))
    }
    dispatch(toggleSubmitting())
    const data = {
      new_password: form.password,
      token: form.token,
    }
    fetch(`${url}/reset-password/set-new-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? null : res.json()
      })
      .then((err) => {
        if (err) {
          const { form, fields } = handleError(err)
          throw new Error(fields.new_password || fields.token || form)
        }
        dispatch(toggleReset())
      })
      .catch((err) => {
        dispatch(showFormErrors({ form: err.message }))
      })
      .finally(() => dispatch(toggleSubmitting()))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case RESET_RESET_FORM:
      return { ...initState }
    case UPDATE_RESET_FORM:
      return { ...state, data: action.payload, errors: {} }
    case SHOW_RESET_ERRORS:
      return { ...state, errors: action.payload }
    case TOGGLE_SUBMITTING_RESET:
      return { ...state, submitting: !state.submitting }
    case TOGGLE_SHOW_PASSWORD:
      return { ...state, show: !state.show }
    case TOGGLE_RESET_RESET:
      return { ...state, reset: !state.reset, data: {}, errors: {} }
    default:
      return state
  }
}
