import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
// import ButtonCSV from './ButtonCSV'
import Table from '../Table'

const fields = [
  {
    name: 'Store',
    field: ['store_name']
  },
  {
    name: 'Orders',
    field: ['order_count']
  }
]

const makeFields = (fields, itemTypes) => {
  const itemFields = itemTypes.reduce((arr, i) => {
    return [
      ...arr,
      {
        name: i.item_type_name,
        field: [i.item_type_name],
        format: 'int'
      },
      {
        name: 'Per',
        field: [`${i.item_type_name}_per`],
        format: 'fixed2'
      }
    ]
  }, [])
  return [...fields, ...itemFields]
}

const makeTableData = data => {
  return data.reduce((arr, i) => {
    const itemCounts = i.counts_by_item_type.reduce((obj, c) => {
      const perOrder = c.item_type_count / i.order_count
      return {
        ...obj,
        [c.item_type_name]: c.item_type_count,
        [`${c.item_type_name}_per`]: perOrder
      }
    }, {})
    return [...arr, { ...i, ...itemCounts }]
  }, [])
}

const makeTotals = (data, itemTypes) => {
  if (!data || !data.length) return null
  const totalChecks = data.reduce(
    (t, i) => (t += parseFloat(i.order_count)),
    0.0
  )
  const itemTotals = itemTypes.reduce((obj, itemType) => {
    const itemCount = data.reduce(
      (t, i) => (t += parseFloat(i[itemType.item_type_name] || 0)),
      0.0
    )
    return {
      ...obj,
      [itemType.item_type_name]: itemCount,
      [`${itemType.item_type_name}_per`]: itemCount / totalChecks
    }
  }, {})

  return {
    store_name: 'Store',
    order_count: parseInt(totalChecks),
    ...itemTotals
  }
}

class ItemCountsByStore extends Component {
  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, handlers } = this.props
    let itemNames = []
    data.forEach(i => {
      itemNames =
        i.counts_by_item_type.length > itemNames.length
          ? i.counts_by_item_type
          : itemNames
    })
    const fieldWithItems = makeFields(fields, itemNames)
    const dataWithItems = makeTableData(data)
    const totals = makeTotals(dataWithItems, itemNames)
    const tableData = totals ? [...dataWithItems, totals] : [...dataWithItems]
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            {/* <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/throughput/csv" />
            </div> */}
          </div>
        </div>
        <TransitionGroup component={null}>
          <CSSTransition {...cssTransitionFade}>
            <div className="reports">
              <div className="report report--item-counts report--totals">
                <div className="report__header">
                  <h2>Item Counts by Store</h2>
                </div>
                <Table fields={fieldWithItems} items={tableData} />
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </>
    )
  }
}

ItemCountsByStore.displayName = 'ItemCountsByStore'

export default connect(null, {})(ItemCountsByStore)
