import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as constants from '../lib/constants'
import { logoutUser } from '../reducers/user'
import { showChangePassword } from '../reducers/changePassword'
import Sidebar from './Sidebar'
import Header from './Header'
import PageHeader from './PageHeader'
import Message from './Message'
import { Input, Select } from './FormInputs'
import Modal from './Modal'
import Aside from './Aside'

const fields = {
  firstName: {
    label: 'First Name',
    field: 'first_name',
    type: 'text',
    readonly: true,
    classes: '-half'
  },
  lastName: {
    label: 'Last Name',
    field: 'last_name',
    type: 'text',
    readonly: true,
    classes: '-half -right'
  },
  email: {
    label: 'Email',
    field: 'email',
    type: 'email',
    readonly: true,
    classes: '-half'
  },
  role: {
    label: 'Role',
    field: 'role',
    type: 'select',
    options: [...constants.roles].slice(1),
    readonly: true,
    classes: '-half -right'
  }
}

class Profile extends Component {
  static propTypes = {
    user: propTypes.object,
    brand: propTypes.object,
    logoutUser: propTypes.func,
    showChangePassword: propTypes.func
  }

  handleModal = evt => {
    evt.preventDefault()
    this.props.showChangePassword()
    evt.target.blur()
  }

  render() {
    document.title = 'Profile | Open Tender Admin Portal'
    const { user, brand } = this.props
    const crumbs = [{ name: 'Profile' }]
    const title = user ? `${user.first_name} ${user.last_name}` : ''
    const subtitle = 'Review your profile and change your password'
    return (
      <div>
        {!user ? (
          <Redirect to="/login" />
        ) : (
          <div className="app app--aside">
            <Modal />
            <div className="app__content">
              <Sidebar brand={brand} user={user} />
              <Header crumbs={crumbs} />
              <div className="main page--profile">
                <div className="content">
                  <div className="content__container">
                    <PageHeader title={title} subtitle={subtitle} />
                    <div className="content__main">
                      <form>
                        <Input
                          field={fields.firstName}
                          value={user.first_name}
                        />
                        <Input field={fields.lastName} value={user.last_name} />
                        <Input field={fields.email} value={user.email} />
                        <Select
                          field={fields.role}
                          value={user.role}
                          options={[...constants.roles].slice(1)}
                        />
                        <div className="form__submit">
                          <button className="btn" onClick={this.handleModal}>
                            Change Password
                          </button>
                          <button
                            className="btn"
                            onClick={this.props.logoutUser}
                          >
                            Logout
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <Aside show={true} />
              <Message />
            </div>
          </div>
        )}
      </div>
    )
  }
}

Profile.displayName = 'Profile'

export default connect(
  state => ({
    user: state.user,
    brand: state.brand
  }),
  { logoutUser, showChangePassword }
)(Profile)
