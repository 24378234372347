import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateDeclaredCash } from '../../reducers/report'
import { showModalErrors } from '../../reducers/modal'
import Form from '../Form'
import { PageError } from '../PageError'

const fields = [
  {
    label: 'Amount',
    field: 'amount',
    type: 'text'
  },
  {
    field: 'cash_transfer_id',
    type: 'hidden'
  }
]

class DeclaredCash extends Component {
  static propTypes = {
    // props
    data: propTypes.object,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    updateDeclaredCash: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { data, errors } = this.props
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={false}
          idField="cash_transfer_id"
          fields={fields}
          data={data || {}}
          errors={errors || {}}
          upsertItem={this.props.updateDeclaredCash}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </>
    )
  }
}

DeclaredCash.displayName = 'DeclaredCash'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { updateDeclaredCash, showModalErrors }
)(DeclaredCash)
