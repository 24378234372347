import React, { Component } from 'react'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const withGecoder = (WrappedComponent) => {
  return class Geocoder extends Component {
    componentDidMount() {
      this.googleMapScript = document.createElement('script')
      this.googleMapScript.setAttribute('id', 'google-map-script')
      this.googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&v=3.exp`
      window.document.body.appendChild(this.googleMapScript)
    }

    componentWillUnmount() {
      this.googleMapScript.remove()
    }

    geocode = (address) => {
      return new Promise((resolve, reject) => {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const latlng = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }
            resolve(latlng)
          } else {
            const msg = `Unable to geocode this address. Please check the street, city, state, and zip code and try again.`
            reject(msg)
          }
        })
      })
    }

    render() {
      return <WrappedComponent geocode={this.geocode} {...this.props} />
    }
  }
}

export default withGecoder
