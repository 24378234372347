import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addItemToRelated, moveItem } from '../../reducers/item'
import { Select } from '../FormInputs'
import { PageError } from '../PageError'

class ItemCategory extends Component {
  static propTypes = {
    itemId: propTypes.number,
    relatedId: propTypes.number,
    isMod: propTypes.bool,
    endpoint: propTypes.string,
    defaults: propTypes.object,
    categories: propTypes.array,
    modifierGroups: propTypes.array,
    errors: propTypes.object,
    addItemToRelated: propTypes.func,
    moveItem: propTypes.func
  }

  handleSelection = evt => {
    const { value: newRelatedId } = evt.target
    const { itemId, relatedId, endpoint, defaults } = this.props
    if (endpoint.includes('move')) {
      this.props.moveItem(itemId, relatedId, endpoint, newRelatedId)
    } else {
      const item = { item_id: itemId, ...defaults }
      this.props.addItemToRelated(item, newRelatedId, endpoint)
    }
  }

  render() {
    const { isMod, categories, modifierGroups, errors } = this.props
    const options = isMod ? modifierGroups : categories
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Select
          field={{ field: 'value' }}
          value=""
          options={options}
          handler={this.handleSelection}
        />
      </>
    )
  }
}

ItemCategory.displayName = 'ItemCategory'

export default connect(
  state => ({
    categories: state.options.categories,
    modifierGroups: state.options['modifier-groups'],
    errors: state.modal.errors
  }),
  { addItemToRelated, moveItem }
)(ItemCategory)
