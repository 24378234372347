import propTypes from 'prop-types'
import React, { Component } from 'react'
import { isEmpty } from '../lib/utils'
import { connect } from 'react-redux'
import { formatDollars } from '../lib/helpers'
import { fmtLocalDate } from '../lib/helpersDatetime'
import { updateFilter, resetFilters, refreshOrders } from '../reducers/orders'
import { TableResults } from './TableResults'
import TableCell from './TableCell'
import Table from './Table'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'
import TableCellLabel from './TableCellLabel'
// import BarLoader from 'react-spinners/BarLoader'

const OrderRows = ({ orders, fields }) => {
  return orders.map((order, index) => {
    const id = 'receipt_id'
    // const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
    const serviceType = order.order_type === 'MAIN_MENU' ? '-olo' : '-catering'
    const orderType = order.service_type === 'PICKUP' ? '-pickup' : '-delivery'
    const status = order.receipt_status
      ? `-${order.receipt_status.toLowerCase()}`
      : ''
    const reviewed =
      order.order_type === 'CATERING' && !order.is_reviewed
        ? '-not-reviewed'
        : ''
    const last = index + 1 === orders.length ? '-last' : ''
    const disabled = order.disabled ? '-disabled' : ''
    const classes = `${orderType} ${serviceType} ${status} ${reviewed} ${last} ${disabled}`
    return (
      <tr key={order[id]} className={classes}>
        {fields.map(field => (
          <td
            key={`${order[id]}-${field.field.join('-')}`}
            className={field.classes}
          >
            {/* {order.disabled && (
              <div className="table__group__loader">
                <div>
                  <BarLoader size={36} color={'#5a5aff'} />
                </div>
              </div>
            )} */}
            <TableCellLabel {...field} />
            <TableCell item={order} {...field} />
          </td>
        ))}
      </tr>
    )
  })
}

OrderRows.displayName = 'OrderRows'
OrderRows.propTypes = {
  orders: propTypes.array,
  fields: propTypes.array
}

const TableGroup = ({ item, fields, groupBy }) => {
  const header =
    groupBy === 'business_date'
      ? fmtLocalDate(item[groupBy], 'EEEE, MMMM do')
      : item.orders[0].revenue_center.full_name
  const total = item.orders
    ? item.orders.reduce((t, i) => (t += parseFloat(i.total)), 0.0).toFixed(2)
    : '0.00'
  return (
    <>
      <tr className="table__spacer">
        <td colSpan={fields.length}>&nbsp;</td>
      </tr>
      <tr className="table__group__header">
        <td colSpan={fields.length - 2}>{header}</td>
        <td>{item.orders.length} Orders</td>
        <td className="-right">{formatDollars(total)}</td>
      </tr>
      <OrderRows orders={item.orders} fields={fields} />
      {/* <tr className="table__group__totals">
        <td colSpan={fields.length - 1}>{item.orders.length} Orders</td>
        <td className="-right">{formatDollars(total)}</td>
      </tr> */}
    </>
  )
}

TableGroup.displayName = 'TableGroup'
TableGroup.propTypes = {
  item: propTypes.object,
  fields: propTypes.array,
  groupBy: propTypes.string
}

class Orders extends Component {
  static propTypes = {
    // ...state.page.list
    filters: propTypes.array,
    classes: propTypes.string,
    fields: propTypes.array,
    sorting: propTypes.object,
    // ...state.orders
    items: propTypes.array,
    links: propTypes.object,
    loading: propTypes.bool,
    error: propTypes.string,
    // mapstatetoprops
    user: propTypes.object,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    refreshOrders: propTypes.func
  }

  handleFilter = evt => {
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    this.props.updateFilter(filter)
    if (type !== 'datepicker') evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.resetFilters()
    evt.target.blur()
  }

  render() {
    const { filters, classes, fields, items, loading, sorting } = this.props
    const groupByFilter = filters.find(i => i.field === 'group_by')
    const groupBy = groupByFilter ? groupByFilter.value : null
    const rowGroupBy = groupBy === 'business_date' ? groupBy : 'store_id'
    const hasFilters = !isEmpty(filters)
    const flatOrders = items.reduce((arr, i) => [...arr, ...i.orders], [])
    return (
      <div className="content__main">
        {hasFilters ? (
          <div className="table__filters">
            {filters.map(filter => Filter(filter, this.handleFilter))}
          </div>
        ) : null}
        <TableResults
          user={this.props.user}
          items={flatOrders}
          hideNew={true}
          hasFilters={hasFilters}
          reset={this.handleResetFilters}
          refreshItems={this.props.refreshOrders}
        />
        {!groupBy ? (
          <Table
            classes={classes}
            fields={fields}
            items={items}
            sorting={sorting}
            loading={loading}
          />
        ) : (
          <div className="table__wrapper">
            {loading ? (
              <div className="table__loading">
                <ClipLoader size={36} color={'#5a5aff'} />
                <span>Retrieving items...</span>
              </div>
            ) : null}
            <table className={`table--grouped ${classes || ''}`}>
              <thead>
                <tr>
                  {fields &&
                    fields.map(field => (
                      <th key={field.field}>{field.name}</th>
                    ))}
                </tr>
              </thead>
              {items.length ? (
                <tbody>
                  {items.map(item => {
                    return (
                      <TableGroup
                        key={item[rowGroupBy]}
                        groupBy={groupBy}
                        item={item}
                        fields={fields}
                      />
                    )
                  })}
                </tbody>
              ) : (
                <tbody>
                  {!loading ? (
                    <tr className="empty-table">
                      <td colSpan={fields.length}>
                        There {"aren't"} any items matching the applied filters.
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    )
  }
}

Orders.displayName = 'Orders'

export default connect(
  state => ({
    ...state.page.orders,
    ...state.orders,
    user: state.user
  }),
  { updateFilter, resetFilters, refreshOrders }
)(Orders)
