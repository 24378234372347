import { request } from '../lib/services'
import {
  serviceTypesIndexed,
  orderTypesIndexed,
  timezoneMap
} from '../lib/constants'
import { makeIso, makeNowTime } from '../lib/helpersOrder'
import {
  makeCustomer,
  makeAddress,
  makeRevenueCenter,
  startOrder
} from './order'
import { redirectPage, handlePageError, clearPageError } from './page'
import { openModal, loadingModal, closeModal } from './modal'
import { flashMessage } from './messages'
import { fetchItems } from './list'

const initState = {
  data: null,
  loading: false,
  updating: false,
  error: '',
  query: '',
  loadingResults: false,
  searchError: '',
  refund: null
}

export const RECEIPT_CLEAR = 'RECEIPT_CLEAR'
export const RECEIPT_LOADING = 'RECEIPT_LOADING'
export const RECEIPT_UPDATING = 'RECEIPT_UPDATING'
export const RECEIPT_LOADING_CLEAR = 'RECEIPT_LOADING_CLEAR'
export const RECEIPT_LOAD = 'RECEIPT_LOAD'
export const RECEIPT_ERROR = 'RECEIPT_ERROR'
export const RECEIPT_QUERY_UPDATE = 'RECEIPT_QUERY_UPDATE'
export const RECEIPT_QUERY_LOADING = 'RECEIPT_QUERY_LOADING'
export const RECEIPT_QUERY_CLEAR = 'RECEIPT_QUERY_CLEAR'
export const RECEIPT_QUERY_ERROR = 'RECEIPT_QUERY_ERROR'
export const RECEIPT_LOAD_REFUND = 'RECEIPT_LOAD_REFUND'
export const RECEIPT_CLEAR_REFUND = 'RECEIPT_CLEAR_REFUND'

export const clearReceipt = () => ({ type: RECEIPT_CLEAR })
export const loadingReceipt = () => ({ type: RECEIPT_LOADING })
export const clearLoading = () => ({ type: RECEIPT_LOADING_CLEAR })
export const toggleUpdating = () => ({ type: RECEIPT_UPDATING })
export const loadReceipt = data => ({ type: RECEIPT_LOAD, payload: data })
export const showReceiptError = msg => ({ type: RECEIPT_ERROR, payload: msg })

export const updateQuery = text => ({
  type: RECEIPT_QUERY_UPDATE,
  payload: text
})
export const clearQuery = () => ({ type: RECEIPT_QUERY_CLEAR })
export const loadingQueryResults = () => ({ type: RECEIPT_QUERY_LOADING })
export const showQueryError = msg => ({
  type: RECEIPT_QUERY_ERROR,
  payload: msg
})

export const loadRefund = refund => ({
  type: RECEIPT_LOAD_REFUND,
  payload: refund
})
export const clearRefund = () => ({ type: RECEIPT_CLEAR_REFUND })

export const fetchReceipt = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, item } = page
    dispatch(clearReceipt())
    dispatch(loadingReceipt())
    const endpoint = `${item.endpoint}/${id}`
    request(token, brand, endpoint, 'GET')
      .then(order => {
        dispatch(clearPageError())
        dispatch(loadReceipt(order))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(clearLoading())
      })
  }
}

export const clearQueryError = () => {
  return dispatch => {
    dispatch(showQueryError(''))
  }
}

export const findOrder = () => {
  return (dispatch, getState) => {
    const { token, brand, receipt } = getState()
    dispatch(loadingQueryResults())
    const orderId = parseInt(receipt.query)
    if (isNaN(orderId) || orderId <= 0) {
      return dispatch(showQueryError('Please enter a positive integer'))
    }
    const endpoint = `/orders/${orderId}`
    request(token, brand, endpoint, 'GET')
      .then(() => {
        dispatch(clearQuery())
        dispatch(clearQueryError())
        dispatch(redirectPage(endpoint))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        let msg = `${err.detail || err.message}`
        if (msg.includes('does not exist'))
          msg = 'Order not found. Please try again.'
        dispatch(showQueryError(msg))
      })
  }
}

const makeReceiptAddress = address => {
  if (!address) return null
  address.latitude = address.lat ? address.lat.toFixed(7) : null
  address.longitude = address.lng ? address.lng.toFixed(7) : null
  return makeAddress(address)
}

const makeOrder = order => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const params =
      'expand=store&with_settings=address&with_related=delivery_zones'
    const endpoint = `/revenue-centers/${order.location_id}?${params}`
    return request(token, brand, endpoint, 'GET').then(resp => {
      return {
        orderType: orderTypesIndexed[order.order_type_id],
        revenueCenter: makeRevenueCenter(resp),
        serviceType: serviceTypesIndexed[order.service_type_id],
        timezone: timezoneMap[order.timezone],
        customer: makeCustomer(order.customer),
        address: makeReceiptAddress(order.address),
        oldCart: order.items
      }
    })
  }
}

export const showEditOrder = () => {
  return (dispatch, getState) => {
    dispatch(loadingModal())
    const { data: order } = getState().receipt
    const orderId = order.receipt_id
    const config = {
      title: 'custom',
      type: 'orderEdit',
      args: { orderId }
    }
    dispatch(openModal(config))
  }
}

export const updateReceipt = (orderId, data) => {
  return (dispatch, getState) => {
    dispatch(toggleUpdating())
    const { token, brand } = getState()
    const endpoint = `/orders/${orderId}/edit`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        dispatch(fetchReceipt())
        dispatch(flashMessage(`Order ${orderId} successfully updated!`))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(toggleUpdating()))
  }
}

export const editOrder = order => {
  return (dispatch, getState) => {
    order = order || getState().receipt.data
    dispatch(makeOrder(order)).then(data => {
      data.orderId = order.receipt_id
      data.requestedAt = makeIso(order.requested_at)
      data.surchargeIds = order.surcharges.map(i => i.surcharge_id)
      data.discountIds = order.discounts
        .filter(i => !i.is_promo_code)
        .map(i => i.discount_id)
      data.promoCodes = order.discounts
        .filter(i => i.is_promo_code)
        .map(i => i.name)
      data.tip = order.tip
      dispatch(startOrder(data, data.requestedAt))
    })
  }
}

export const reorder = () => {
  return (dispatch, getState) => {
    const { data: order } = getState().receipt
    dispatch(makeOrder(order)).then(data => {
      const todayIso = makeNowTime(order.requested_at)
      dispatch(startOrder(data, todayIso))
    })
  }
}

export const submitRefund = (submitType, refund) => {
  return (dispatch, getState) => {
    const { token, brand, receipt } = getState()
    const orderId = receipt.data.receipt_id
    const endpoint = `/orders/${orderId}/${submitType}-partial-refund`
    request(token, brand, endpoint, 'POST', refund)
      .then(refund => {
        if (submitType === 'calculate') {
          dispatch(loadRefund(refund))
        } else {
          dispatch(closeModal())
          dispatch(fetchReceipt())
          const msg = `Order ${orderId} successfully refunded!`
          dispatch(flashMessage(msg))
        }
      })
      .catch(err => {
        dispatch(clearRefund())
        dispatch(handlePageError(err))
      })
  }
}

const makeRemaining = (order, remaining) => {
  const giftCardBalances = remaining.gift_cards.reduce((obj, i) => {
    return { ...obj, [i.gift_card_id]: i.amount }
  }, {})
  const gift_cards = order.gift_cards
    .filter(i =>
      Object.keys(giftCardBalances).includes(i.gift_card_id.toString())
    )
    .map(i => ({ ...i, amount: giftCardBalances[i.gift_card_id.toString()] }))
  const surchargeIds = remaining.surcharges.map(i => i.surcharge_id)
  const surcharges = order.surcharges.filter(i =>
    surchargeIds.includes(i.surcharge_id)
  )
  const lookup = remaining.items.reduce((obj, i) => {
    return { ...obj, [i.line_no.toString()]: i.quantity }
  }, {})
  const items = order.items
    .map(i => {
      const flatOptions = i.groups.reduce(
        (arr, group) => [...arr, ...group.options],
        []
      )
      const options = flatOptions
        .map(o => {
          const unitPrice = parseFloat(o.price_total) / o.quantity_total
          const quantity = lookup[o.line_no.toString()] || 0
          const quantity_total = quantity
          const price_total = (unitPrice * quantity).toFixed(2)
          return { ...o, quantity, quantity_total, price_total }
        })
        .filter(o => o.quantity > 0)
      // const unitPrice = parseFloat(i.price) / i.quantity
      const quantity = lookup[i.line_no.toString()] || 0
      const quantity_total = quantity
      const price_total = (i.price * quantity).toFixed(2)
      return { ...i, quantity, quantity_total, price_total, options }
    })
    .filter(i => i.quantity > 0 || i.options.length)
  const tenders = order.tenders.map(i => {
    const tender = remaining.tenders.find(r => r.tender_id === i.tender_id)
    const amount = tender ? tender.amount : '0.00'
    return { ...i, amount }
  })
  const total = remaining.tenders
    .reduce((t, i) => (t += parseFloat(i.amount)), 0.0)
    .toFixed(2)
  return {
    ...order,
    items,
    gift_cards,
    surcharges,
    tip: remaining.tip,
    shipping: remaining.shipping,
    tenders,
    total
  }
}

export const refundOrder = () => {
  return (dispatch, getState) => {
    dispatch(loadingModal())
    const { token, brand, receipt } = getState()
    const { data: order } = receipt
    const endpoint = `/orders/${order.receipt_id}/remaining-refund`
    request(token, brand, endpoint)
      .then(remaining => {
        const remainingOrder = makeRemaining(order, remaining)
        const config = {
          title: `Refund Order #${order.receipt_id}`,
          subtitle: "Choose the items, etc. you'd like to refund below",
          classes: 'modal--big modal--wide',
          type: 'refund',
          args: { order: remainingOrder }
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const markPaid = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/orders/${orderId}/mark-paid`
    request(token, brand, endpoint, 'POST')
      .then(() => {
        dispatch(fetchReceipt())
        const msg = `Order ${orderId} marked paid!`
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const markNotPaid = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/orders/${orderId}/mark-not-paid`
    request(token, brand, endpoint, 'POST')
      .then(() => {
        dispatch(fetchReceipt())
        const msg = `Order ${orderId} marked NOT paid!`
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const markVoid = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/orders/${orderId}/cancel`
    request(token, brand, endpoint, 'POST')
      .then(() => {
        dispatch(fetchReceipt())
        const msg = `Order ${orderId} has been voided!`
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const retryCharge = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/orders/${orderId}/charge`
    request(token, brand, endpoint, 'POST')
      .then(() => {
        dispatch(fetchReceipt())
        const msg = `Order ${orderId} successfully charged!`
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const retryCharges = orderIds => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    let failed = []
    const requests = orderIds.map(orderId => {
      const endpoint = `/orders/${orderId}/charge`
      return request(token, brand, endpoint, 'POST').catch(() =>
        failed.push(orderId)
      )
    })
    Promise.all(requests)
      .then(() => {
        // need to re-fetch the list of uncharged orders to remove
        // those that were successfully re-charged
        dispatch(fetchItems())
        if (failed.length) {
          const failedOrders = failed.join(', ')
          const msg = `Orders ${failedOrders} failed to process. Please reach out to the customer for each of these orders.`
          throw new Error(msg)
        } else {
          const msg = `All orders were successfully charged!`
          dispatch(flashMessage(msg))
        }
      })
      .catch(err => dispatch(handlePageError(err)))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case RECEIPT_CLEAR:
      return { ...initState }
    case RECEIPT_LOADING:
      return { ...state, loading: true }
    case RECEIPT_LOADING_CLEAR:
      return { ...state, loading: false }
    case RECEIPT_UPDATING:
      return { ...state, updating: !state.updating }
    case RECEIPT_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        updating: false,
        error: ''
      }
    case RECEIPT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        updating: false
      }
    case RECEIPT_LOAD_REFUND:
      return { ...state, refund: action.payload }
    case RECEIPT_CLEAR_REFUND:
      return { ...state, refund: null }
    case RECEIPT_QUERY_UPDATE:
      return { ...state, query: action.payload }
    case RECEIPT_QUERY_LOADING:
      return { ...state, loadingResults: true }
    case RECEIPT_QUERY_CLEAR:
      return { ...state, query: '', loadingResults: false }
    case RECEIPT_QUERY_ERROR:
      return { ...state, searchError: action.payload, loadingResults: false }
    default:
      return state
  }
}
