import propTypes from 'prop-types'
import React, { Component, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { editNotesInternal } from '../../reducers/orderMgmt'
import { Textarea } from '../FormInputs'

const NotesForm = ({ notesInternal, submitNotes, field }) => {
  const submitButton = useRef()
  const [value, setValue] = useState(notesInternal)
  const [error, setError] = useState(null)

  const handleInput = evt => {
    setError(null)
    setValue(evt.target.value)
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    submitNotes(value)
    submitButton.current.blur()
  }

  return (
    <form id="form" onSubmit={handleSubmit}>
      <Textarea
        field={field}
        value={value}
        error={error}
        handler={handleInput}
      />
      <div className="form__submit">
        <button type="submit" className="btn" ref={submitButton}>
          Submit
        </button>
      </div>
    </form>
  )
}

NotesForm.displayName = 'NotesForm'
NotesForm.propTypes = {
  notesInternal: propTypes.string,
  field: propTypes.object,
  submitNotes: propTypes.func
}

class EditNotesInternal extends Component {
  static propTypes = {
    orderId: propTypes.number,
    notesInternal: propTypes.string,
    editNotesInternal: propTypes.func
  }

  handleSubmit = notesInternal => {
    const { orderId } = this.props
    this.props.editNotesInternal(orderId, notesInternal)
  }

  render() {
    const field = { label: '', field: 'notesInternal', type: 'textarea' }
    return (
      <NotesForm
        {...this.props}
        field={field}
        submitNotes={this.handleSubmit}
      />
    )
  }
}

EditNotesInternal.displayName = 'EditNotesInternal'

export default connect(null, { editNotesInternal })(EditNotesInternal)
