import propTypes from 'prop-types'
import React from 'react'
import { slugify } from '../../lib/helpers'

const Table = ({ data, classes = '', headerRows = 1 }) => {
  const klass = `table--report ${classes}`
  const thead = data.slice(0, headerRows)
  const tbody = data.slice(headerRows)
  return (
    <table className={klass}>
      <thead>
        {thead.map((row, rIndex) => (
          <tr key={`thead-row-${rIndex}`}>
            {row.map((i, index) => (
              <th key={`thead-${i}-${index}`}>{i}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tbody.map((row, index) => {
          const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
          // const evenOdd = ''
          const name = row[0]
          const klass = `${evenOdd} -${name ? slugify(name) : 'none'}`
          return (
            <tr key={name} className={klass}>
              {row.map((col, i) => (
                <td key={`${name}-${i}`}>{col}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

Table.displayName = 'Table'
Table.propTypes = {
  data: propTypes.array,
  classes: propTypes.string,
  headerRows: propTypes.number
}

export default Table
