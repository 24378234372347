import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requestFile } from '../lib/services'
import { showPageError } from '../reducers/page'

// https://medium.com/the-thinkmill/how-to-safely-inject-html-in-react-using-an-iframe-adc775d458bc
// https://stackoverflow.com/questions/33913737/inserting-the-iframe-into-react-component
// https://reactjs.org/docs/dom-elements.html

class Iframe extends Component {
  constructor(props) {
    super(props)
    this.state = null
  }

  static propTypes = {
    token: propTypes.string,
    brand: propTypes.object,
    endpoint: propTypes.string,
    data: propTypes.object,
    showPageError: propTypes.func
  }

  componentDidMount() {
    const { token, brand, endpoint } = this.props
    requestFile(token, brand, endpoint, 'text/html')
      .then(html => this.setState({ __html: html }))
      .catch(err => showPageError(err.detail))
  }

  componentDidUpdate(prevProps) {
    const { token, brand, endpoint } = this.props
    if (endpoint !== prevProps.endpoint) {
      requestFile(token, brand, endpoint, 'text/html')
        .then(html => this.setState({ __html: html }))
        .catch(err => showPageError(err.detail))
    }
  }

  render() {
    return this.state ? (
      <iframe
        style={{ width: '100%', height: '2400px' }}
        frameBorder="0"
        title="Email Preview"
        srcDoc={this.state.__html}
      />
    ) : null
  }
}

Iframe.displayName = 'Iframe'

export default connect(state => ({
  token: state.token,
  brand: state.brand,
  endpoint: state.page.iframe.endpoint,
  data: state.item.data
}))(Iframe)
