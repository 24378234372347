import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Plus, Minus } from 'react-feather'
import {
  setItemQuantity,
  incrementItem,
  decrementItem,
  selectOption,
  setOptionQuantity,
  incrementOption,
  decrementOption,
  updateMadeFor,
  updateItemNotes,
  addItemToCart
} from '../../reducers/order'
import { RadioButtonGroup, QuantityInput } from '../OrderInputs'

const Quantity = ({
  name,
  id,
  quantity,
  adjust,
  increment,
  decrement,
  size = null,
  classes = '',
  incrementDisabled = false
}) => {
  return (
    <div className={`quantity ${classes}`}>
      <button
        className="btn"
        id={`decrease-${id}`}
        onClick={decrement}
        disabled={quantity === 0}
      >
        <Minus size={size} />
      </button>
      <QuantityInput
        name={name}
        id={`quantity-${id}`}
        value={quantity}
        handler={adjust}
      />
      <button
        className="btn"
        id={`increase-${id}`}
        onClick={increment}
        disabled={incrementDisabled}
      >
        <Plus size={size} />
      </button>
    </div>
  )
}

const displayPrice = price => {
  return parseFloat(price).toFixed(2)
}

Quantity.displayName = 'Quantity'
Quantity.propTypes = {
  name: propTypes.string,
  id: propTypes.oneOfType([propTypes.number, propTypes.string]),
  classes: propTypes.string,
  quantity: propTypes.oneOfType([propTypes.number, propTypes.string]),
  adjust: propTypes.func,
  increment: propTypes.func,
  decrement: propTypes.func,
  size: propTypes.number,
  incrementDisabled: propTypes.bool
}

const Option = ({
  group,
  option,
  adjust,
  increment,
  decrement,
  size = null,
  classes = ''
}) => {
  const name = `${option.name} quantity in ${group.name} group`
  const isMaxedOut = group.max !== 0 && group.quantity === group.max
  return (
    <li key={option.id}>
      <span className="modal__option">
        <span className="modal__option__name">{option.name}</span>
        <span className="modal__option__price">
          ${displayPrice(option.price)}
        </span>
        <span className="modal__option__quantity">
          <Quantity
            name={name}
            id={`${group.id}-${option.id}`}
            quantity={option.quantity}
            adjust={adjust}
            increment={increment}
            decrement={decrement}
            size={size}
            classes={classes}
            incrementDisabled={isMaxedOut}
          />
        </span>
        <span className="modal__option__price">
          ${displayPrice(option.totalPrice)}
        </span>
      </span>
    </li>
  )
}

Option.displayName = 'Option'
Option.propTypes = {
  group: propTypes.object,
  option: propTypes.object,
  adjust: propTypes.func,
  increment: propTypes.func,
  decrement: propTypes.func,
  size: propTypes.number,
  classes: propTypes.string
}

const GroupWarning = ({ quantity, min, max }) => {
  const isRadio = min === 1 && max === 1
  if (quantity < min) {
    return <span className="modal__group__warning -min">Below Minimum</span>
  } else if (quantity === max && max !== 0 && !isRadio) {
    return <span className="modal__group__warning -max">At Maximum</span>
  } else {
    return null
  }
}

GroupWarning.displayName = 'GroupWarning'
GroupWarning.propTypes = {
  group: propTypes.object
}

class OrderItem extends Component {
  static propTypes = {
    // state.order
    id: propTypes.oneOfType([propTypes.number, propTypes.string]),
    name: propTypes.string,
    groups: propTypes.array,
    price: propTypes.number,
    quantity: propTypes.oneOfType([propTypes.number, propTypes.string]),
    totalPrice: propTypes.number,
    maxQuantity: propTypes.number,
    minQuantity: propTypes.number,
    increment: propTypes.number,
    notes: propTypes.string,
    madeFor: propTypes.string,
    // mapdispatchtoprops
    setItemQuantity: propTypes.func,
    incrementItem: propTypes.func,
    decrementItem: propTypes.func,
    selectOption: propTypes.func,
    setOptionQuantity: propTypes.func,
    incrementOption: propTypes.func,
    decrementOption: propTypes.func,
    updateItemNotes: propTypes.func,
    updateMadeFor: propTypes.func,
    addItemToCart: propTypes.func
  }

  handleAdjust = evt => {
    const value = parseInt(evt.target.value)
    const quantity = isNaN(value) || value < 1 ? '' : value
    this.props.setItemQuantity(quantity)
  }

  handleIncrement = evt => {
    evt.preventDefault()
    this.props.incrementItem()
    evt.target.blur()
  }

  handleDecrement = evt => {
    evt.preventDefault()
    this.props.decrementItem()
    evt.target.blur()
  }

  handleMadeFor = evt => {
    this.props.updateMadeFor(evt.target.value)
  }

  handleNotes = evt => {
    this.props.updateItemNotes(evt.target.value)
  }

  handleRadio = evt => {
    const groupId = parseInt(evt.target.name)
    const optionId = parseInt(evt.target.value)
    this.props.selectOption(groupId, optionId)
    evt.target.blur()
  }

  handleAdjustOption = evt => {
    const [, groupId, optionId] = evt.target.id.split('-').map(i => parseInt(i))
    const value = parseInt(evt.target.value)
    const quantity = isNaN(value) ? '' : value
    this.props.setOptionQuantity(groupId, optionId, quantity)
  }

  handleIncrementOption = evt => {
    evt.preventDefault()
    const [, groupId, optionId] = evt.target.id.split('-').map(i => parseInt(i))
    this.props.incrementOption(groupId, optionId)
    evt.target.blur()
  }

  handleDecrementOption = evt => {
    evt.preventDefault()
    const [, groupId, optionId] = evt.target.id.split('-').map(i => parseInt(i))
    this.props.decrementOption(groupId, optionId)
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.addItemToCart()
    evt.target.blur()
  }

  render() {
    const { id, name, price, quantity, totalPrice, groups, notes, madeFor } =
      this.props
    const isIncomplete = groups.filter(g => g.quantity < g.min).length > 0
    return (
      <div className="modal__item">
        <form className="modal__groups">
          {groups.map(group => (
            <div key={group.id} className="modal__group">
              <div className="modal__group__header">
                <h3 className="modal__group__name">{group.name}</h3>
                <p className="modal__group__settings">
                  <GroupWarning {...group} />
                  <span>
                    {group.min} min, {group.max} max, {group.inc} included
                  </span>
                </p>
              </div>
              <div className="modal__options">
                {group.min === 1 && group.max === 1 ? (
                  <RadioButtonGroup
                    id={group.id}
                    options={group.options}
                    handler={this.handleRadio}
                  />
                ) : (
                  <ul>
                    {group.options.map(option => (
                      <Option
                        key={`${group.id}-${option.id}`}
                        group={group}
                        option={option}
                        adjust={this.handleAdjustOption}
                        increment={this.handleIncrementOption}
                        decrement={this.handleDecrementOption}
                      />
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
          <div className="modal__quantity">
            <div className="modal__option">
              <div className="modal__option__name">Item Quantity</div>
              <div className="modal__option__price">${displayPrice(price)}</div>
              <div className="modal__option__quantity">
                <Quantity
                  name={`${name} quantity`}
                  id={id}
                  quantity={quantity}
                  adjust={this.handleAdjust}
                  increment={this.handleIncrement}
                  decrement={this.handleDecrement}
                />
              </div>
              <div className="modal__option__price">
                ${displayPrice(totalPrice)}
              </div>
            </div>
          </div>
          <div className="modal__notes">
            <label htmlFor="item-notes" className="label">
              <span>Notes</span>
              <textarea
                id="item-notes"
                value={notes || ''}
                onChange={this.handleNotes}
              />
            </label>
          </div>
          <div className="modal__submit">
            <div className="modal__made-for">
              <label htmlFor="made-for" className="label">
                <span>Made For</span>
                <input
                  id="made-for"
                  type="text"
                  value={madeFor || ''}
                  onChange={this.handleMadeFor}
                />
              </label>
            </div>
            <button
              className="btn"
              onClick={this.handleSubmit}
              disabled={isIncomplete}
            >
              Add To Cart
            </button>
          </div>
        </form>
      </div>
    )
  }
}

OrderItem.displayName = 'OrderItem'

export default connect(
  state => ({
    ...state.order.currentItem
  }),
  {
    setItemQuantity,
    incrementItem,
    decrementItem,
    selectOption,
    setOptionQuantity,
    incrementOption,
    decrementOption,
    updateMadeFor,
    updateItemNotes,
    addItemToCart
  }
)(OrderItem)
