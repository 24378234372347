import propTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ItemCategory from './buttons/ItemCategory'
// import { Break } from './FormInputs'
import RemoveRelated from './buttons/RemoveRelated'
import { capitalize } from '../lib/helpers'
import ItemCategoryMove from './buttons/ItemCategoryMove'

const BoolCell = ({ val }) => {
  return val ? <span>Yes</span> : <span className="alert-error">No</span>
}

BoolCell.displayName = 'BoolCell'
BoolCell.propTypes = {
  val: propTypes.bool
}

const ItemCategoriesTable = ({ categories, itemId }) => {
  return (
    <div className="table__wrapper">
      <table className="">
        <tbody>
          {categories.map((i, index) => {
            const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
            const category = { ...i, ...i.category }
            const groupId = category.category_id
            const key = `category-${groupId}`
            const catUrl = `/categories/${groupId}/items`
            const groupsUrl = `/categories/${groupId}/items/${itemId}`
            const pricesUrl = `/items/${itemId}/attributes`
            const item = { item_id: itemId }
            const removeUrl = '/categories/:id/items'
            const moveUrl = '/categories/:id/items/:idd/move'
            return (
              <tr key={key} className={evenOdd}>
                <td>
                  <NavLink to={catUrl}>{category.short_name}</NavLink>
                </td>
                <td>
                  <NavLink to={groupsUrl}>edit modifier groups</NavLink>
                </td>
                <td>
                  <ItemCategoryMove
                    itemId={itemId}
                    relatedId={groupId}
                    endpoint={moveUrl}
                  />
                </td>
                <td>
                  <NavLink to={pricesUrl}>edit prices</NavLink>
                </td>
                <td>
                  <RemoveRelated
                    item={item}
                    relatedId={groupId}
                    endpoint={removeUrl}
                  />
                </td>
                {/* <td>{groupId}</td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

ItemCategoriesTable.displayName = 'ItemCategoriesTable'
ItemCategoriesTable.propTypes = {
  categories: propTypes.array,
  itemId: propTypes.number,
  isMod: propTypes.bool
}

const ItemModifierGroupsTable = ({ categories, itemId }) => {
  return (
    <div className="table__wrapper">
      <table className="">
        <tbody>
          {categories.map((i, index) => {
            const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
            const modifierGroup = { ...i, ...i.modifier_group }
            const groupId = modifierGroup.modifier_group_id
            const key = `modiifier-group-${groupId}`
            const catUrl = `/modifier-groups/${groupId}/items`
            const pricesUrl = `/items/${itemId}/attributes`
            const item = { item_id: itemId }
            const removeUrl = '/modifier-groups/:id/items'
            return (
              <tr key={key} className={evenOdd}>
                <td>
                  <NavLink to={catUrl}>{modifierGroup.short_name}</NavLink>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <NavLink to={pricesUrl}>edit prices</NavLink>
                </td>
                <td>
                  <RemoveRelated
                    item={item}
                    relatedId={groupId}
                    endpoint={removeUrl}
                  />
                </td>
                {/* <td>{groupId}</td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

ItemModifierGroupsTable.displayName = 'ItemModifierGroupsTable'
ItemModifierGroupsTable.propTypes = {
  categories: propTypes.array,
  itemId: propTypes.number
}

const catProps = {
  endpoint: '/categories/:id/items',
  defaults: {
    menu_position: 99
  }
}

const modProps = {
  endpoint: '/modifier-groups/:id/items',
  isMod: true,
  defaults: {
    menu_position: 99
  }
}

const ItemCategories = ({ itemId, categories, isMod = false }) => {
  const entity = isMod ? 'modifier groups' : 'categories'
  const desc = isMod
    ? 'Prices will vary by modifier group.'
    : 'Modifier groups and prices for this item will vary by category.'
  const isEmpty = !categories.length
  const tableProps = { categories, itemId }
  let addNewProps = isMod ? modProps : catProps
  addNewProps = { itemId, isEmpty, ...addNewProps }
  return (
    <div className={`item__categories ${isEmpty ? '-empty' : ''}`}>
      <h2>{capitalize(entity)}</h2>
      {!isEmpty ? (
        <>
          <p>
            This item lives in the {entity} below. {desc}
          </p>
          {isMod ? (
            <ItemModifierGroupsTable {...tableProps} />
          ) : (
            <ItemCategoriesTable {...tableProps} />
          )}
        </>
      ) : (
        <p>
          This item {"hasn't"} been added to any {entity} yet.
        </p>
      )}
      <p>
        <ItemCategory {...addNewProps} />
      </p>
    </div>
  )
}

ItemCategories.displayName = 'ItemCategories'
ItemCategories.propTypes = {
  itemId: propTypes.number,
  categories: propTypes.array,
  isMod: propTypes.bool
}

export default ItemCategories
