import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../reducers/modal'

class OrderCancel extends Component {
  static propTypes = {
    orderId: propTypes.number,
    cancel: propTypes.func,
    closeModal: propTypes.func
  }

  handleCancel = evt => {
    evt.preventDefault()
    this.props.cancel()
    evt.target.blur()
  }

  handleCloseModal = evt => {
    evt.preventDefault()
    this.props.closeModal()
    evt.target.blur()
  }

  render() {
    const { orderId } = this.props
    return (
      <div className="order-close text">
        <p>
          Are you sure {"you're"} ready to cancel order #{orderId}?
        </p>
        <p>{"There's"} no way to undo this.</p>
        <div className="btn-group">
          <button
            className="btn-group__btn -last-2nd-odd"
            onClick={this.handleCancel}
          >
            <span>Yes, Cancel Order</span>
          </button>
          <button
            className="btn-group__btn -last-even"
            onClick={this.handleCloseModal}
          >
            <span>Nevermind, Not Now</span>
          </button>
        </div>
      </div>
    )
  }
}

OrderCancel.displayName = 'OrderCancel'

export default connect(null, { closeModal })(OrderCancel)
