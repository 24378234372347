import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { updateBusinessDate, retrieveErrors } from '../reducers/mapping'
import MappingRevenueCenters from './MappingRevenueCenters'
import { DateFilter } from './Import'
import ClipLoader from 'react-spinners/ClipLoader'
import Table from './Table'

const makeFields = () => {
  return [
    // { name: `${orderSource} ID`, field: ['ext_order_id'] },
    { name: 'Created At', field: ['ext_created_at'], format: 'datetime' },
    { name: 'Channel', field: ['ext_channel'] },
    { name: 'Channel ID', field: ['ext_channel_order_id'] },
    { name: 'Service Type', field: ['service_type'] },
    { name: 'Customer', field: ['ext_customer'] },
    { name: 'Total', field: ['ext_total'], format: 'dollars' },
    { name: 'Error Type', field: ['error_type'] },
    { name: 'Error Message', field: ['errMsg'] },
    {
      name: 'Details',
      field: ['view details', ''],
      button: 'import_error_id',
      format: 'path',
      settings: {
        title: 'Order Details',
        type: 'importError',
        classes: 'modal--big modal--wide'
      }
    },
    {
      name: 'Order ID',
      field: ['order_id'],
      link: 'order_id',
      linkEndpoint: '/orders'
    }
  ]
}

class ImportErrors extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    orderSource: propTypes.string,
    loading: propTypes.bool,
    submitting: propTypes.bool,
    revenueCenterId: propTypes.number,
    businessDate: propTypes.string,
    importResults: propTypes.object,
    importErrors: propTypes.array,
    updateBusinessDate: propTypes.func,
    importOrders: propTypes.func,
    retrieveErrors: propTypes.func
  }

  componentDidMount() {
    if (!this.props.businessDate) {
      const businessDate = format(new Date(), 'yyyy-MM-dd')
      this.props.updateBusinessDate(businessDate)
    }
  }

  handleDatepicker = date => {
    const businessDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
    this.props.updateBusinessDate(businessDate)
  }

  handleReview = evt => {
    evt.preventDefault()
    this.props.retrieveErrors()
    evt.target.blur()
  }

  render() {
    const {
      businessDate,
      submitting,
      loading,
      revenueCenterId,
      importErrors: errors
    } = this.props
    const submitDisabled = submitting || loading || !revenueCenterId
    const fields = makeFields()
    return (
      <div className="mapping">
        <div className="mapping__mappings__inputs">
          <MappingRevenueCenters />
          <DateFilter
            businessDate={businessDate}
            handler={this.handleDatepicker}
          />
        </div>
        <div className="mapping__mappings">
          <div className="mapping__import">
            <div className="mapping__import__header">
              <h2>Review Import Errors</h2>
              <p>
                Review import errors for this revenue center for the selected
                business date
              </p>
              {!revenueCenterId && (
                <p className="err">
                  Please choose a revenue center before retrieving errors.
                </p>
              )}
            </div>
            <form
              id="form-errors"
              className="form"
              onSubmit={this.handleReview}
            >
              <div className="form__submit">
                <input
                  className="btn"
                  type="submit"
                  value={loading ? 'Retrieving...' : 'Retrieve Errors'}
                  disabled={submitDisabled}
                  ref={this.submitButton}
                />
              </div>
            </form>
            {loading ? (
              <div className="mapping__loading">
                <ClipLoader size={44} color={'#5a5aff'} />
              </div>
            ) : (
              errors && (
                <div className="mapping__import__results">
                  <Table fields={fields} items={errors} />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    )
  }
}

ImportErrors.displayName = 'ImportErrors'

export default connect(
  state => ({
    ...state.mapping,
    ...state.page.mapping,
    ...state.page.importErrors
  }),
  { updateBusinessDate, retrieveErrors }
)(ImportErrors)
