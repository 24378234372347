import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { downloadCsv } from '../../reducers/report'

class ButtonCSV extends Component {
  static propTypes = {
    label: propTypes.string,
    endpoint: propTypes.string,
    downloadCsv: propTypes.func
  }

  handleCsv = (evt, endpoint) => {
    evt.preventDefault()
    this.props.downloadCsv(endpoint)
    evt.target.blur()
  }

  render() {
    const { label, endpoint } = this.props
    return (
      <button
        type="button"
        className="btn"
        onClick={evt => this.handleCsv(evt, endpoint)}
      >
        {label || 'Download CSV'}
      </button>
    )
  }
}

ButtonCSV.displayName = 'ButtonCSV'

export default connect(
  state => ({
    stores: state.options.stores
  }),
  { downloadCsv }
)(ButtonCSV)
