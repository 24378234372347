import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { isEmpty } from '../lib/utils'
import { errMessages } from '../lib/errors'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import { PageError } from './PageError'
import { Input } from './TableInputs'
import {
  updateTime,
  updateHours,
  clearHours,
  resetHours,
  copyDown
} from '../reducers/hours'

export const HoursInput = ({
  weekday,
  name,
  serviceType,
  hourType,
  error,
  handler
}) => {
  const field = `${name}-${serviceType}-${hourType}`
  const value = weekday[serviceType] ? weekday[serviceType][hourType] : ''
  return (
    <td>
      <Input
        field={field}
        value={value}
        placeholder="closed"
        error={error || ''}
        handler={handler}
      />
    </td>
  )
}

HoursInput.displayName = 'HoursInput'
HoursInput.propTypes = {
  weekday: propTypes.object,
  name: propTypes.string,
  serviceType: propTypes.string,
  hourType: propTypes.string,
  error: propTypes.string,
  handler: propTypes.func
}

const CopyButton = ({ name, serviceType, handler }) => (
  <button
    className="btn-link"
    onClick={evt => handler(evt, serviceType, name.toLowerCase())}
  >
    {name}
  </button>
)

CopyButton.displayName = 'CopyButton'
CopyButton.propTypes = {
  name: propTypes.string,
  serviceType: propTypes.string,
  handler: propTypes.func
}

const hoursFields = [
  ['PICKUP', 'open'],
  ['PICKUP', 'close'],
  ['DELIVERY', 'open'],
  ['DELIVERY', 'close']
]

class Hours extends Component {
  static propTypes = {
    // state.hours
    loading: propTypes.bool,
    hours: propTypes.array,
    errors: propTypes.object,
    // mapdispatchtoprops
    updateTime: propTypes.func,
    updateHours: propTypes.func,
    clearHours: propTypes.func,
    resetHours: propTypes.func,
    copyDown: propTypes.func
  }

  handleInput = evt => {
    const [weekday, serviceType, hourType] = evt.target.id.split('-')
    this.props.updateTime(weekday, serviceType, hourType, evt.target.value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateHours()
    evt.target.blur()
  }

  handleDelete = evt => {
    evt.preventDefault()
    this.props.clearHours()
    window.scroll(0, 0)
    evt.target.blur()
  }

  handleReset = evt => {
    evt.preventDefault()
    this.props.resetHours()
    window.scroll(0, 0)
    evt.target.blur()
  }

  copyDown = (evt, serviceType, hourType) => {
    evt.preventDefault()
    this.props.copyDown(serviceType, hourType)
    evt.target.blur()
  }

  render() {
    const { loading, hours, errors } = this.props
    const errMsg = !isEmpty(errors)
      ? errors.form || errMessages.invalidHours
      : ''
    return (
      <div className="content__main">
        <PageError msg={errMsg} />
        {loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!loading && hours ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="table__wrapper">
                <table className="table--hours table--light">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th colSpan="2">Pickup</th>
                      <th colSpan="2">Delivery</th>
                      <th>&nbsp;</th>
                    </tr>
                    <tr>
                      <th>Weekday</th>
                      <th>&nbsp;</th>
                      <th>
                        <CopyButton
                          name="Open"
                          serviceType="PICKUP"
                          handler={this.copyDown}
                        />
                      </th>
                      <th>
                        <CopyButton
                          name="Close"
                          serviceType="PICKUP"
                          handler={this.copyDown}
                        />
                      </th>
                      <th>
                        <CopyButton
                          name="Open"
                          serviceType="DELIVERY"
                          handler={this.copyDown}
                        />
                      </th>
                      <th>
                        <CopyButton
                          name="Close"
                          serviceType="DELIVERY"
                          handler={this.copyDown}
                        />
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hours.map((weekday, index) => {
                      const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
                      return (
                        <tr key={weekday.name} className={evenOdd}>
                          <td>
                            {weekday.name}&nbsp; &nbsp;
                            <span className="mobile">
                              pickup top / delivery bottom
                            </span>
                          </td>
                          <td>&nbsp;</td>
                          {hoursFields.map(field => {
                            const [serviceType, hourType] = field
                            const key = `${weekday.name}-${serviceType}-${hourType}`
                            return (
                              <HoursInput
                                key={key}
                                weekday={weekday}
                                name={weekday.name}
                                serviceType={serviceType}
                                hourType={hourType}
                                handler={this.handleInput}
                                error={errors[key]}
                              />
                            )
                          })}
                          <td>&nbsp;</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="form__submit">
                  <button className="btn" onClick={this.handleSubmit}>
                    Update
                  </button>
                  <button
                    className="btn btn--secondary"
                    onClick={this.handleDelete}
                  >
                    Clear All
                  </button>
                  <button
                    className="btn btn--secondary"
                    onClick={this.handleReset}
                  >
                    Reset Changes
                  </button>
                </div>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Hours.displayName = 'Hours'

export default connect(
  state => ({
    ...state.hours
  }),
  { updateTime, updateHours, clearHours, resetHours, copyDown }
)(Hours)
