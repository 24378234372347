import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { pageType } from '../lib/constants'
import TabActions from './TabsActions'
import { isEmpty } from '../lib/utils'

export const NavItem = ({ name, path, endpoint }) => {
  return (
    <li>
      <NavLink exact to={`${endpoint}${path ? '/' : ''}${path}`}>
        {name}
      </NavLink>
    </li>
  )
}

NavItem.displayName = 'NavItem'
NavItem.propTypes = {
  name: propTypes.string,
  path: propTypes.string,
  endpoint: propTypes.string
}

export const NavTabs = ({ item, id, route, data }) => {
  if (!item.tabs) return null
  if (!item.tabs && !item.tabs.length && id === 'new') return null
  const itemEndpoint = id
    ? `${route.split(':id')[0]}${id}`
    : item.tabsEndpoint || item.endpoint
  const filtered = item.tabs.filter(i => {
    if (!i.disabled || !data) return true
    return !i.disabled.values.includes(data[i.disabled.field])
  })
  return (
    <ul>
      {filtered.map(tab => (
        <NavItem key={tab.path} endpoint={itemEndpoint} {...tab} />
      ))}
    </ul>
  )
}

NavTabs.displayName = 'NavTabs'
NavTabs.propTypes = {
  item: propTypes.object,
  id: propTypes.string,
  route: propTypes.string,
  data: propTypes.object
}

class Tabs extends Component {
  static propTypes = {
    route: propTypes.string,
    id: propTypes.string,
    idd: propTypes.string,
    item: propTypes.object,
    relation: propTypes.object,
    relatedItem: propTypes.object,
    type: propTypes.string,
    data: propTypes.object
  }

  render() {
    const { route, id, item, relation, relatedItem, type, data } = this.props
    const newEndpoint = relation
      ? `${relation.endpoint.replace(':id', id)}/new`
      : relatedItem
      ? `${route.replace(':idd', 'new').replace(':id', id)}`
      : type === pageType.item
      ? `${route.replace(':id', 'new')}`
      : null
    const isNew = type === pageType.item && isEmpty(data)
    return (
      <div className="nav tabs">
        <div className="nav__cover">
          <div className="nav__overlay" />
        </div>
        <div className="nav__container">
          <div className="nav__title">
            <div className="container">
              {newEndpoint && !isNew && (
                <NavLink className="btn" exact to={newEndpoint}>
                  Add New
                </NavLink>
              )}
            </div>
          </div>
          {!isNew && (
            <>
              <nav className="nav__nav">
                <NavTabs item={item} id={id} route={route} data={data} />
              </nav>
              {item.tabActions && (
                <div className="nav__actions">
                  <div className="container">
                    <TabActions actions={item.tabActions} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

Tabs.displayName = 'Tabs'

// export default Tabs
export default connect(
  state => ({
    ...state.page,
    data: state.item ? state.item.data : null
  }),
  {}
)(Tabs)
