import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  addNewItem,
  editCustomer,
  editAddress,
  changeOrderType,
  updateServiceType,
  changeRevenueCenter,
  addOrderAlert
} from '../reducers/order'
import { PageErrors } from './PageError'
import Menu from './Menu'
import Checkout from './Checkout'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { isoToZonedDateStr } from '../lib/helpersDatetime'
import { capitalize } from '../lib/helpers'

class Order extends Component {
  static propTypes = {
    // state.order
    loading: propTypes.bool,
    menu: propTypes.array,
    customer: propTypes.object,
    address: propTypes.object,
    orderType: propTypes.string,
    serviceType: propTypes.string,
    revenueCenter: propTypes.object,
    timeUpdated: propTypes.bool,
    requestedAt: propTypes.string,
    timezone: propTypes.string,
    order: propTypes.object,
    orderId: propTypes.number,
    errors: propTypes.object,
    // mapdispatchtoprops
    addNewItem: propTypes.func,
    editCustomer: propTypes.func,
    editAddress: propTypes.func,
    changeOrderType: propTypes.func,
    updateServiceType: propTypes.func,
    changeRevenueCenter: propTypes.func,
    addOrderAlert: propTypes.func
  }

  itemEdit = item => {
    // console.log(item)
    this.props.addNewItem(item)
  }

  handleCustomer = evt => {
    evt.preventDefault()
    this.props.editCustomer()
    evt.target.blur()
  }

  handleAddress = evt => {
    evt.preventDefault()
    this.props.editAddress()
    evt.target.blur()
  }

  handleOrderType = evt => {
    evt.preventDefault()
    this.props.changeOrderType()
    evt.target.blur()
  }

  handlePickupSwitch = evt => {
    this.props.updateServiceType('PICKUP')
    evt.target.blur()
  }

  handleRevenueCenter = evt => {
    evt.preventDefault()
    this.props.changeRevenueCenter()
    evt.target.blur()
  }

  // handleAlert = evt => {
  //   evt.preventDefault()
  //   this.props.addOrderAlert(`This is a test order alert ${new Date()}`)
  //   evt.target.blur()
  // }

  render() {
    const {
      loading,
      menu,
      errors,
      orderId,
      order,
      customer,
      address,
      orderType,
      serviceType,
      revenueCenter,
      timezone,
      timeUpdated,
      requestedAt
    } = this.props
    const needAddress = !address && serviceType === 'DELIVERY'
    const newAddressUrl = customer
      ? `/customers/${customer.customer_id}/addresses/new`
      : null
    const requestedDateStr = requestedAt
      ? ` for ${isoToZonedDateStr(requestedAt, timezone, 'EEE, MMM d @ h:mma')}`
      : ''
    const orderTypeStr = orderType
      ? `${orderType === 'MAIN_MENU' ? 'OLO' : capitalize(orderType)}`
      : ''
    const revenueCenterName = revenueCenter ? ` from ${revenueCenter.name}` : ''
    return (
      <>
        <div className="content__header">
          <h1>{orderId ? `Order #${orderId}` : 'New Order'}</h1>
          <p>
            {orderId
              ? `Editing ${orderTypeStr} order`
              : `Placing a new ${orderTypeStr} order`}
            {requestedDateStr}
            {revenueCenterName}
          </p>
        </div>
        <div className="content__main">
          {errors && <PageErrors errors={errors} noField={true} />}
          {order ? (
            <Checkout />
          ) : orderType && revenueCenter && !needAddress ? (
            <Menu menu={menu} loading={loading} itemEdit={this.itemEdit} />
          ) : !customer ? (
            <div className="menu__category text -steps">
              <h2 className="menu__category__title">{"Let's"} get started!</h2>
              <p>First off, {"let's"} add a customer.</p>
              <p>
                <button className="btn" onClick={this.handleCustomer}>
                  Choose a customer
                </button>
              </p>
              <p>
                <NavLink to="/customers/new">
                  Or click here to add a new one
                </NavLink>
              </p>
              {/* <p>
              <button className="btn" onClick={this.handleAlert}>
                Test Order Alert
              </button>
            </p> */}
            </div>
          ) : needAddress ? (
            <div className="menu__category text -steps">
              <h2 className="menu__category__title">
                Great! Now {"let's"} choose an address
              </h2>
              <p>
                <button className="btn" onClick={this.handleAddress}>
                  Choose an address
                </button>
              </p>
              {newAddressUrl && (
                <p>
                  <NavLink to={newAddressUrl}>
                    Or click here to add a new address for this customer
                  </NavLink>
                </p>
              )}
              <p>Placing an order for pickp and {"don't"} need an address?</p>
              <p>
                <button className="btn" onClick={this.handlePickupSwitch}>
                  Switch to Pickup
                </button>
              </p>
            </div>
          ) : !orderType ? (
            <div className="menu__category text -steps">
              <h2 className="menu__category__title">
                Now choose an order type
              </h2>
              <p>
                Looks like you {"haven't"} selected an order type yet.{' '}
                {"You'll"} need to do that before you can choose a revenue
                center
              </p>
              <p>
                <button className="btn" onClick={this.handleOrderType}>
                  Choose Order Type
                </button>
              </p>
            </div>
          ) : !timeUpdated ? (
            <div className="menu__category text -steps">
              <h2 className="menu__category__title">
                Update your order date & time
              </h2>
              <p>
                When does the customer want this order? {"It's"} best to choose
                this before choosing a revenue center so we can show you the
                most relevant order times available.
              </p>
              <p>
                <strong>
                  Click on the {'"Requested At"'} time in the sidebar to update
                  the selected date & time.
                </strong>
              </p>
            </div>
          ) : (
            !revenueCenter && (
              <div className="menu__category text -steps">
                <h2 className="menu__category__title">
                  Great! Now for the last step...
                </h2>
                <p>Choose a revenue center and {"you're"} off to the races!</p>
                <p>
                  <button className="btn" onClick={this.handleRevenueCenter}>
                    Choose Revenue Center
                  </button>
                </p>
              </div>
            )
          )}
        </div>
      </>
    )
  }
}

Order.displayName = 'Order'

export default connect(
  state => ({
    ...state.order
  }),
  {
    addNewItem,
    editCustomer,
    editAddress,
    changeOrderType,
    updateServiceType,
    changeRevenueCenter,
    addOrderAlert
  }
)(Order)
