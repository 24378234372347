import propTypes from 'prop-types'
import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const PageLoading = ({ loading }) => {
  return (
    loading && (
      <div className="content__loading">
        <ClipLoader size={44} color={'#5a5aff'} />
      </div>
    )
  )
}

PageLoading.displayName = 'PageLoading'
PageLoading.propTypes = {
  loading: propTypes.bool
}

export default PageLoading
