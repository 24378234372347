import { request } from '../lib/services'
import { flashMessage } from './messages'
import { loadItem } from './item'
import { convertFromAPI, sortItems } from '../lib/helpers'
import {
  serviceTypes,
  weekdays,
  revenueCenterOrderTypeMap
} from '../lib/constants'
import { handlePageError } from './page'

const initState = {
  dayparts: null,
  data: null,
  error: ''
}

export const DAYPARTS_LOAD = 'DAYPARTS_LOAD'
export const AVAILS_CLEAR = 'AVAILS_CLEAR'
export const AVAILS_LOAD = 'AVAILS_LOAD'
export const AVAILS_ERRORS = 'AVAILS_ERRORS'
export const AVAILS_UPDATE = 'AVAILS_UPDATE'

export const loadDayparts = dayparts => ({
  type: DAYPARTS_LOAD,
  payload: dayparts
})
export const clearAvails = () => ({ type: AVAILS_CLEAR })
export const loadAvails = data => ({ type: AVAILS_LOAD, payload: data })
export const showAvailsErrors = msg => ({ type: AVAILS_ERRORS, payload: msg })

const makeAvails = dayparts => {
  return dayparts.map(i => {
    return {
      daypart_id: i.daypart.daypart_id,
      service_type: i.service_type,
      weekday: i.weekday
    }
  })
}

export const fetchAvails = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, item } = page
    dispatch(clearAvails())
    if (!id || !item) return
    let endpoint = `${item.endpoint}/${id}?with_related=dayparts`
    if (endpoint.includes('revenue-centers')) endpoint += '&expand=store'
    let orderType = '',
      data = {}
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        data = convertFromAPI(page.route, 'item', resp)
        dispatch(loadItem(data))
        orderType =
          data.order_type || revenueCenterOrderTypeMap[data.revenue_center_type]
        return request(token, brand, '/dayparts', 'GET')
      })
      .then(resp => {
        const dayparts = resp.data.filter(i => i.order_type === orderType)
        const sorting = { sortBy: 'start_time', sortType: 'alphaTime' }
        const sortedDayparts = sortItems(dayparts, sorting)
        dispatch(loadDayparts(sortedDayparts))
        // console.log(JSON.stringify(data.dayparts, null, 2))
        const avails = makeAvails(data.dayparts)
        // console.log(JSON.stringify(avails, null, 2))
        dispatch(loadAvails(avails))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const updateAvail = (avail, active) => {
  return (dispatch, getState) => {
    const {
      avails: { data }
    } = getState()
    const newData = active
      ? [...data, avail]
      : data.filter(i => {
          return (
            i.daypart_id !== avail.daypart_id ||
            i.service_type !== avail.service_type ||
            i.weekday !== avail.weekday
          )
        })
    dispatch({ type: AVAILS_UPDATE, payload: newData })
  }
}

export const updateWeekday = (daypartId, weekday) => {
  return (dispatch, getState) => {
    const { avails } = getState()
    const matching = avails.data.filter(
      i => i.daypart_id === daypartId && i.weekday === weekday
    )
    let newData = []
    if (matching.length < 3) {
      const notMatching = avails.data.filter(
        i => i.daypart_id !== daypartId || i.weekday !== weekday
      )
      const newWeekday = serviceTypes.map(serviceType => {
        return {
          daypart_id: daypartId,
          service_type: serviceType,
          weekday: weekday
        }
      })
      newData = [...notMatching, ...newWeekday]
    } else {
      newData = avails.data.filter(
        i => i.daypart_id !== daypartId || i.weekday !== weekday
      )
    }
    dispatch({ type: AVAILS_UPDATE, payload: newData })
  }
}

export const updateServiceType = (daypartId, serviceType) => {
  return (dispatch, getState) => {
    const { avails } = getState()
    const matching = avails.data.filter(
      i => i.daypart_id === daypartId && i.service_type === serviceType
    )
    let newData = []
    if (matching.length < 7) {
      const notMatching = avails.data.filter(
        i => i.daypart_id !== daypartId || i.service_type !== serviceType
      )
      const newServiceType = weekdays.map(weekday => {
        return {
          daypart_id: daypartId,
          service_type: serviceType,
          weekday: weekday.toUpperCase()
        }
      })
      newData = [...notMatching, ...newServiceType]
    } else {
      newData = avails.data.filter(
        i => i.daypart_id !== daypartId || i.service_type !== serviceType
      )
    }
    dispatch({ type: AVAILS_UPDATE, payload: newData })
  }
}

export const updateDaypart = daypartId => {
  return (dispatch, getState) => {
    const { avails } = getState()
    const matching = avails.data.filter(i => i.daypart_id === daypartId)
    let newData = []
    if (matching.length < 21) {
      const notMatching = avails.data.filter(i => i.daypart_id !== daypartId)
      let newDaypart = []
      weekdays.map(weekday => {
        serviceTypes.map(serviceType => {
          newDaypart.push({
            daypart_id: daypartId,
            service_type: serviceType,
            weekday: weekday.toUpperCase()
          })
        })
      })
      newData = [...notMatching, ...newDaypart]
    } else {
      newData = avails.data.filter(i => i.daypart_id !== daypartId)
    }
    dispatch({ type: AVAILS_UPDATE, payload: newData })
  }
}

export const updateAvails = () => {
  return (dispatch, getState) => {
    const { token, brand, avails, page } = getState()
    const endpoint = page.route.replace(':id', page.id)
    request(token, brand, endpoint, 'PUT', avails.data)
      .then(() => {
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case AVAILS_CLEAR:
      return { ...initState }
    case DAYPARTS_LOAD:
      return { ...state, dayparts: action.payload }
    case AVAILS_LOAD:
      return { ...state, data: action.payload, error: '' }
    case AVAILS_UPDATE:
      return { ...state, data: action.payload }
    case AVAILS_ERRORS:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
