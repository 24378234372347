import { request } from '../lib/services'
import { handleError } from '../lib/errors'
import { sortItems } from '../lib/helpers'
import {
  makeRelationsEntity,
  makeRelationListEndpoint,
  makeEntityName,
  makeNextPosition
} from '../lib/helpersPage'
import { flashMessage } from './messages'
import { redirectPage, handlePageError } from './page'
import { updateRelations, loadRelations, clearRelations } from './relations'
import { fetchAvails } from './itemAvails'
import { loadingModal, openModal, closeModal, showModalErrors } from './modal'
import { clearSearchResults, showSearchError } from './search'
import { reloadOptions } from './options'

const initState = {
  data: null,
  loading: false,
  items: [],
  item: null,
  errors: {}
}

export const RELATION_CLEAR = 'RELATION_CLEAR'
export const RELATION_LOADING = 'RELATION_LOADING'
export const RELATION_LOAD = 'RELATION_LOAD'
export const RELATION_ERRORS = 'RELATION_ERRORS'
export const RELATION_LIST_CLEAR = 'RELATION_LIST_CLEAR'
export const RELATION_LIST_LOAD = 'RELATION_LIST_LOAD'
export const RELATION_LIST_REORDER = 'RELATION_LIST_REORDER'
export const RELATION_LIST_EDIT_ITEM = 'RELATION_LIST_EDIT_ITEM'
export const RELATION_LIST_CLEAR_ITEM = 'RELATION_LIST_CLEAR_ITEM'

export const clearRelation = () => ({ type: RELATION_CLEAR })
export const loadRelation = data => ({ type: RELATION_LOAD, payload: data })
export const loadingRelation = () => ({ type: RELATION_LOADING })
export const showItemErrors = errors => ({
  type: RELATION_ERRORS,
  payload: errors
})
export const clearRelationList = () => ({ type: RELATION_LIST_CLEAR })
export const loadRelationList = items => ({
  type: RELATION_LIST_LOAD,
  payload: items
})
export const setRelationListItem = id => ({
  type: RELATION_LIST_EDIT_ITEM,
  payload: id
})
export const clearRelationListItem = () => ({ type: RELATION_LIST_CLEAR_ITEM })

export const fetchRelation = () => {
  return (dispatch, getState) => {
    const {
      page: { idd, relation },
      relations: { items }
    } = getState()
    if (!relation) return
    dispatch({ type: RELATION_LOADING })
    const data =
      idd === 'new'
        ? {}
        : items.filter(i => i[relation.id] === parseInt(idd))[0]
    dispatch(loadRelation(data))
    dispatch(fetchRelationList())
  }
}

export const upsertRelation = values => {
  return (dispatch, getState) => {
    const { token, brand, page, relation } = getState()
    const { id, idd, relation: rel } = page
    const endpoint = rel.endpoint.replace(':id', id)
    const items = [{ [rel.id]: parseInt(idd), ...values }]
    return request(token, brand, endpoint, 'POST', items)
      .then(data => {
        if (idd === 'new') {
          dispatch(reloadOptions(rel.endpoint))
          const redirect = rel.redirect
            ? rel.redirect.replace(':id', id)
            : endpoint
          dispatch(redirectPage(redirect))
          dispatch(flashMessage('Successfully created!'))
        } else {
          const relationData = { ...relation.data, ...data[0] }
          dispatch(updateRelations(relationData))
          dispatch(loadRelation(relationData))
          dispatch(flashMessage('Successfully updated!'))
        }
      })
      .catch(err => {
        const errors = handleError(err, true)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

export const deleteRelation = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, relation: rel } = page
    const endpoint = rel.endpoint.replace(':id', id)
    const data = [{ [rel.id]: parseInt(idd) }]
    request(token, brand, endpoint, 'DELETE', data)
      .then(() => {
        dispatch(reloadOptions(rel.endpoint))
        const redirect = rel.redirect
          ? rel.redirect.replace(':id', id)
          : endpoint
        dispatch(redirectPage(redirect))
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        const errors = handleError(err, true)
        err.detail = errors.fields[rel.id] || errors.form
        dispatch(handlePageError(err))
      })
  }
}

export const upsertItem = values => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, item, relation } = page
    values = { [item.id]: parseInt(id), ...values }
    let endpoint =
      relation.upsertEndpoint || makeRelationsEntity(relation.endpoint)
    endpoint = `/${endpoint}${idd === 'new' ? '' : '/' + idd}`
    const method = idd === 'new' ? 'POST' : 'PUT'
    return request(token, brand, endpoint, method, values)
      .then(data => {
        if (idd === 'new') {
          const itemEndpoint = relation.endpoint.replace(':id', id)
          dispatch(redirectPage(`${itemEndpoint}/${data[relation.id]}`))
          dispatch(flashMessage('Successfully created!'))
        } else {
          dispatch(updateRelations(data))
          dispatch(loadRelation(data))
          dispatch(flashMessage('Successfully updated!'))
        }
      })
      .catch(err => {
        const errors = handleError(err)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

export const deleteItem = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, relation } = page
    let endpoint =
      relation.upsertEndpoint || makeRelationsEntity(relation.endpoint)
    endpoint = `/${endpoint}/${idd}`
    request(token, brand, endpoint, 'DELETE')
      .then(() => {
        const relationsEndpoint = relation.endpoint.replace(':id', id)
        dispatch(redirectPage(relationsEndpoint))
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchRelationList = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, relation } = page
    if (!relation || !relation.list || idd === 'new')
      return dispatch(clearRelationList())
    const args = [relation.endpoint, relation.list.path, id, idd]
    const endpoint = `${makeRelationListEndpoint(...args)}?expand=true`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const items = sortItems(resp, relation.list.sorting)
        dispatch(loadRelationList(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

// as of 2019-10-20, this is only used for reordering
// modifier groups associated with an item
export const reorderRelationList = (endpoint, data) => {
  return (dispatch, getState) => {
    const { token, brand, relation, page } = getState()
    const { sortBy } = page.relation.list.sorting
    // update order before request
    const orderMap = data.reduce((obj, item) => {
      obj[item[page.relation.list.id]] = item[sortBy]
      return obj
    }, {})
    const newItems = relation.items.map(item => {
      item[sortBy] = orderMap[item[page.relation.list.id]]
      return item
    })
    const items = sortItems(newItems, page.relation.list.sorting)
    dispatch({ type: RELATION_LIST_REORDER, payload: items })
    // post new order to API
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        dispatch(flashMessage('Items reordered!'))
      })
      .catch(err => {
        dispatch({ type: RELATION_LIST_REORDER, payload: relation.items })
        dispatch(handlePageError(err))
      })
  }
}

const makeFormEndpoint = (endpoint, path, { id, idd }, itemId, itemPath) => {
  const relationEndpoint = endpoint.replace(':id', id)
  const relationsPath = itemId ? `/${itemId}/${itemPath}` : ''
  return `${relationEndpoint}/${idd}/${path}${relationsPath}`
}

export const editRelationListItem = (itemId, settings) => {
  return (dispatch, getState) => {
    const { relation, page } = getState()
    const field = page.relation.list.id
    const data = relation.items.filter(i => itemId === i[field])[0]
    dispatch(setRelationListItem(data))
    const config = {
      title: settings.modalTitle,
      subtitle: settings.modalSubtitle,
      classes: settings.classes,
      type: settings.modalType
    }
    dispatch(openModal(config))
  }
}

export const upsertRelationListItem = values => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const {
      relation: { list, endpoint }
    } = page
    const formEndpoint = makeFormEndpoint(endpoint, list.path, page)
    return request(token, brand, formEndpoint, 'POST', [values])
      .then(() => {
        dispatch(clearRelationListItem())
        dispatch(fetchRelationList())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const deleteRelationListItem = itemId => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const {
      relation: { list, endpoint }
    } = page
    const relationEndpoint = makeFormEndpoint(endpoint, list.path, page)
    const data = [{ [list.id]: parseInt(itemId) }]
    request(token, brand, relationEndpoint, 'DELETE', data)
      .then(() => {
        dispatch(fetchRelationList())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully delete!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const confirmDeleteRelationListItem = (itemId, settings) => {
  return dispatch => {
    const config = {
      title: settings.modalTitle,
      subtitle: settings.modalSubtitle,
      classes: settings.classes,
      deleteItemId: parseInt(itemId)
    }
    dispatch(openModal(config))
  }
}

const makeRowCol = (obj, itemId) => {
  const endpoint = Array.isArray(obj.endpoint)
    ? obj.endpoint
    : obj.endpoint.replace(':id', itemId)
  return { ...obj, endpoint: endpoint }
}

export const editRelationListItemRelations = (itemId, settings) => {
  return (dispatch, getState) => {
    const { relation, page } = getState()
    const {
      relation: { list, endpoint }
    } = page
    const itemsEndpoint = makeFormEndpoint(
      endpoint,
      list.path,
      page,
      itemId,
      settings.items
    )
    const row = makeRowCol(settings.rows, itemId)
    const col = makeRowCol(settings.cols, itemId)
    const item = { endpoint: itemsEndpoint, isModifiers: true }
    const data = relation.items.filter(i => itemId === i[list.id])[0]
    const config = {
      title: makeEntityName(settings.modalTitle, data),
      subtitle: settings.modalSubtitle,
      classes: settings.classes,
      type: 'itemAvails'
    }
    dispatch(fetchAvails(row, col, item, config))
    dispatch(loadingModal())
  }
}

export const searchAddRelationListItem = search => {
  return dispatch => {
    dispatch(clearSearchResults())
    const config = {
      title: search.modalTitle,
      subtitle: search.modalSubtitle,
      classes: `modal--big ${search.classes}`,
      type: search.modalType
    }
    dispatch(openModal(config))
  }
}

export const addNewRelationListItem = (id, resultId, defaults) => {
  return (dispatch, getState) => {
    const { token, brand, page, relation } = getState()
    const {
      relation: { list, endpoint }
    } = page
    const relationListEndpoint = makeFormEndpoint(endpoint, list.path, page)
    const nextPosition = relation.items.length
      ? makeNextPosition(list.sorting, relation.items)
      : 0
    const values = { ...defaults, ...nextPosition }
    const items = [{ [resultId]: parseInt(id), ...values }]
    request(token, brand, relationListEndpoint, 'POST', items)
      .then(() => {
        dispatch(fetchRelationList())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully created!'))
        setTimeout(() => {
          dispatch(clearSearchResults())
        }, 500)
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        // console.log(errors)
        dispatch(showSearchError(errors.form))
      })
  }
}

export const fetchRelatedItems = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { relatedItems: r } = page
    dispatch(clearRelations())
    const endpoint = `${r.endpoint}?${r.filter}=${page.id}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const items = sortItems(resp.data, r.sorting)
        dispatch(loadRelations(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchRelatedItem = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(loadingRelation())
    dispatch(fetchRelatedItems())
    const { relatedItem, idd } = page
    if (idd === 'new') return dispatch(loadRelation({}))
    const endpoint = `${relatedItem.endpoint}/${idd}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadRelation(resp))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const upsertRelatedItem = values => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { route, id, idd, item, relatedItem } = page
    values = { [item.id]: parseInt(id), ...values }
    const endpt = relatedItem.endpoint
    const endpoint = idd === 'new' ? endpt : `${endpt}/${idd}`
    const method = idd === 'new' ? 'POST' : 'PUT'
    return request(token, brand, endpoint, method, values)
      .then(data => {
        if (idd === 'new') {
          dispatch(reloadOptions(endpoint))
          const newId = data[relatedItem.id]
          const newEndpoint = route.replace(':idd', newId).replace(':id', id)
          dispatch(redirectPage(newEndpoint))
          dispatch(flashMessage('Successfully created!'))
        } else {
          dispatch(reloadOptions(endpoint))
          dispatch(updateRelations(data))
          dispatch(loadRelation(data))
          dispatch(flashMessage('Successfully updated!'))
        }
      })
      .catch(err => {
        const errors = handleError(err)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

export const deleteRelatedItem = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, relatedItem } = page
    const endpoint = `${relatedItem.endpoint}/${idd}`
    request(token, brand, endpoint, 'DELETE')
      .then(() => {
        const backEndpoint = relatedItem.backEndpoint.replace(':id', id)
        dispatch(redirectPage(backEndpoint))
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case RELATION_CLEAR:
      return { ...initState }
    case RELATION_LOADING:
      return { ...state, loading: true }
    case RELATION_LOAD:
      return { ...state, data: action.payload, loading: false, errors: {} }
    case RELATION_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    case RELATION_LIST_CLEAR:
      return { ...state, items: [] }
    case RELATION_LIST_LOAD:
      return { ...state, items: action.payload }
    case RELATION_LIST_REORDER:
      return { ...state, items: action.payload }
    case RELATION_LIST_EDIT_ITEM:
      return { ...state, item: action.payload }
    case RELATION_LIST_CLEAR_ITEM:
      return { ...state, item: null }
    default:
      return state
  }
}
