export const makeStoreLookup = stores => {
  return stores.reduce((obj, i) => {
    return { ...obj, [i.store_id]: i.short_name || i.full_name }
  }, {})
}

export const makeRevenueCenterLookup = revenueCenters => {
  return Object.entries(revenueCenters).reduce((obj, i) => {
    const [rcType, rcs] = i
    const rcsObj = rcs.reduce((o, r) => {
      return {
        ...o,
        [r.revenue_center_id]: { name: r.full_name, type: rcType }
      }
    }, {})
    return { ...obj, ...rcsObj }
  }, {})
}

export const makeTaxesLookup = taxes => {
  return taxes.reduce(
    (obj, i) => {
      return { ...obj, [i.tax_id]: i.name }
    },
    { '0': 'Sales Tax' }
  )
}

export const makeDeptLookup = departments => {
  return departments.reduce(
    (obj, i) => ({ ...obj, [i.department_id]: i.name }),
    {}
  )
}
