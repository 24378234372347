import propTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import PageLoading from './PageLoading'
import { ReceiptSection, formatPrice } from './ReceiptSection'
import { Label } from './FormInputs'
import DatePicker from 'react-datepicker'
import {
  updateCredit,
  addCredit,
  updateStatus,
  updateExpiration,
  addCustomer,
  removeCustomer
} from '../reducers/giftCard'
import { fmtDate, makeLocalDate } from '../lib/helpersDatetime'

const CardStatus = ({ isActive, handler }) => {
  return (
    <>
      <span>{isActive ? 'Active' : 'Inactive'} -- </span>
      <button onClick={handler} className="btn-link">
        {isActive ? 'deactivate' : 'activate'}
      </button>
    </>
  )
}

CardStatus.displayName = 'CardStatus'
CardStatus.propTypes = {
  isActive: propTypes.bool,
  handler: propTypes.func
}

class CustomInput extends Component {
  static propTypes = {
    value: propTypes.string,
    onClick: propTypes.func
  }
  render() {
    const { value, onClick } = this.props
    return (
      <button className="btn-link" onClick={onClick}>
        {value || 'n/a'}
      </button>
    )
  }
}

const DatePickerButton = ({ expiration, handler }) => {
  // const value = expiration ? new Date(expiration) : null
  const value = expiration ? makeLocalDate(expiration) : null
  const [startDate, setStartDate] = useState(value)
  return (
    <DatePicker
      showPopperArrow={false}
      dateFormat="yyyy-MM-dd"
      selected={startDate}
      popperPlacement="bottom-end"
      onChange={date => setStartDate(date)}
      onCalendarClose={() => handler(startDate)}
      customInput={<CustomInput />}
    />
  )
}

DatePickerButton.displayName = 'DatePickerButton'
DatePickerButton.propTypes = {
  expiration: propTypes.string,
  handler: propTypes.func
}

const makeGiftCard = (g, handleStatus, handleExpiration) => {
  if (!g) return {}
  // console.log(g.created_at)
  // console.log(g.activated_at)
  // console.log(g.updated_at)
  const activatedAt = !g.activated_at ? 'n/a' : fmtDate(g.activated_at)
  const updatedAt = !g.updated_at ? 'n/a' : fmtDate(g.updated_at)
  const summary = [
    { label: 'Gift Card Summary', value: ' ' },
    { label: 'Card Number', value: g.card_number },
    { label: 'Code', value: g.code },
    { label: 'Batch ID', value: g.batch_id },
    {
      label: 'Status',
      value: <CardStatus isActive={g.is_active} handler={handleStatus} />
    },
    { label: 'Created At', value: fmtDate(g.created_at) },
    { label: 'Activated At', value: activatedAt },
    { label: 'Last Used', value: updatedAt },
    {
      label: 'Expiration',
      value: (
        <DatePickerButton
          expiration={g.expiration}
          handler={handleExpiration}
        />
      )
    },
    { label: 'Balance', value: formatPrice(g.balance) },
    { label: 'Orders Placed', value: g.orders }
  ]
  return summary
}

const CustomerRemove = ({ handler }) => (
  <button onClick={handler} className="btn-link">
    remove this customer
  </button>
)

CustomerRemove.displayName = 'CustomerRemove'
CustomerRemove.propTypes = {
  handler: propTypes.func
}

const makeCustomer = (c, removeHandler) => {
  if (!c) return null
  const customer = [
    { label: 'Customer', value: <CustomerRemove handler={removeHandler} /> },
    {
      label: 'Name',
      value: [c.first_name, c.last_name].join(' '),
      path: '/customers/:id',
      id: c.customer_id
    },
    { label: 'Email', value: c.email },
    { label: 'Phone', value: c.phone },
    { label: 'Company', value: c.company }
  ]
  return customer
}

const GiftCardCustomer = ({ customerId, customer, handler }) => {
  if (!customerId) {
    return (
      <div className="receipt__section">
        <div className="receipt__row">
          <div className="receipt__label">Customer</div>
          <div className="receipt__value" />
        </div>
        <div className="receipt__row -full-mobile">
          <div className="receipt__label">
            No customer associated with this gift card
          </div>
          <div className="receipt__value">
            <button className="btn-link" onClick={handler}>
              Add Customer
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <TransitionGroup>
        {customer ? (
          <CSSTransition {...cssTransitionFade}>
            <ReceiptSection rows={customer} />
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    )
  }
}

GiftCardCustomer.displayName = 'GiftCardCustomer'
GiftCardCustomer.propTypes = {
  customerId: propTypes.number,
  customer: propTypes.object,
  handler: propTypes.func
}

class GiftCard extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    loading: propTypes.bool,
    data: propTypes.object,
    customer: propTypes.object,
    amount: propTypes.string,
    error: propTypes.string,
    updateCredit: propTypes.func,
    addCredit: propTypes.func,
    updateStatus: propTypes.func,
    updateExpiration: propTypes.func,
    addCustomer: propTypes.func,
    removeCustomer: propTypes.func
  }

  handleInput = evt => {
    this.props.updateCredit(evt.target.value)
  }

  handleStatus = evt => {
    evt.preventDefault()
    this.props.updateStatus()
    evt.target.blur()
  }

  handleExpiration = date => {
    const expiration = fmtDate(date)
    this.props.updateExpiration(expiration)
  }

  handleCustomer = evt => {
    evt.preventDefault()
    this.props.addCustomer()
    evt.target.blur()
  }

  removeCustomer = evt => {
    evt.preventDefault()
    this.props.removeCustomer()
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.addCredit()
    this.submitButton.current.blur()
    window.scroll(0, 0)
  }

  render() {
    const { loading, data, customer, amount } = this.props
    const summary = makeGiftCard(data, this.handleStatus, this.handleExpiration)
    const customerSummary = makeCustomer(customer, this.removeCustomer)
    return (
      <div className="content__main">
        <PageLoading loading={loading} />
        <TransitionGroup>
          {!loading && data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="receipt">
                <ReceiptSection rows={summary} />
                <GiftCardCustomer
                  customerId={data.customer_id}
                  customer={customerSummary}
                  handler={this.handleCustomer}
                />
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="amount" className="label">
                    <Label label="Add credit to gift card" />
                    <input
                      id="amount"
                      type="text"
                      value={amount || ''}
                      placeholder="0.00"
                      onChange={this.handleInput}
                    />
                  </label>
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value="Add Credit"
                      ref={this.submitButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

GiftCard.displayName = 'GiftCard'

export default connect(
  state => ({
    ...state.giftCard
  }),
  {
    updateCredit,
    addCredit,
    updateStatus,
    updateExpiration,
    addCustomer,
    removeCustomer
  }
)(GiftCard)
