import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { capitalize } from '../lib/helpers'
import { addOrderTime, removeOrderTime } from '../reducers/orderTimes'

const Results = ({ orderTimes, loading, handler }) => {
  return (
    <div className="table__results">
      <div className="table__results__message">
        <p>
          {loading
            ? 'Loading...'
            : orderTimes.length
            ? `Displaying ${orderTimes.length} order windows.`
            : 'No order windows found for this revenue center '}
        </p>
      </div>
      <div className="table__results__buttons">
        <button className="btn table__new" onClick={handler}>
          Add New
        </button>
      </div>
    </div>
  )
}

Results.displayName = 'Results'
Results.propTypes = {
  orderTimes: propTypes.array,
  loading: propTypes.bool,
  handler: propTypes.func
}

class OrderTimes extends Component {
  static propTypes = {
    orderTimes: propTypes.array,
    loading: propTypes.bool,
    pageError: propTypes.string,
    addOrderTime: propTypes.func,
    removeOrderTime: propTypes.func
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.addOrderTime()
    evt.target.blur()
  }

  handleRemove = (evt, index) => {
    evt.preventDefault()
    this.props.removeOrderTime(parseInt(index))
    evt.target.blur()
  }

  render() {
    const { orderTimes, loading } = this.props
    return (
      <div className="content__main">
        <Results
          orderTimes={orderTimes}
          loading={loading}
          handler={this.handleNew}
        />
        <table className="table--blocked-hours">
          <thead>
            <tr>
              <th>Weekday</th>
              <th>Service Type</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Order By Weekday</th>
              <th>Order By Time</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {orderTimes.map((orderTime, index) => {
              const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
              const key = `${orderTime.weekday}-${orderTime.start_time}`
              return (
                <tr key={key} className={evenOdd}>
                  <td>{capitalize(orderTime.weekday)}</td>
                  <td>{capitalize(orderTime.service_type)}</td>
                  <td>{orderTime.start_time}</td>
                  <td>{orderTime.end_time}</td>
                  <td>{capitalize(orderTime.order_by_weekday)}</td>
                  <td>{orderTime.order_by_time}</td>
                  <td>
                    <button
                      className="btn-link"
                      onClick={evt => this.handleRemove(evt, index)}
                    >
                      remove
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

OrderTimes.displayName = 'OrderTimes'

export default connect(
  state => ({
    ...state.orderTimes
  }),
  { addOrderTime, removeOrderTime }
)(OrderTimes)
