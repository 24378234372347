import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { makeDatepickerArgs } from '../../lib/helpersOrder'
import { dateToIso } from '../../lib/helpersDatetime'
import { editRequestedAt } from '../../reducers/orderMgmt'

class EditRequestedAt extends Component {
  constructor(props) {
    super(props)
    this.state = { date: props.requestedAt }
  }

  static propTypes = {
    orderId: propTypes.number,
    requestedAt: propTypes.object,
    tz: propTypes.string,
    interval: propTypes.number,
    weekdayTimes: propTypes.object,
    excludedTimes: propTypes.object,
    holidays: propTypes.array,
    windowRef: propTypes.shape({ current: propTypes.any }),
    errors: propTypes.object,
    editRequestedAt: propTypes.func
  }

  setDate = date => {
    this.setState({ date: date })
  }

  submitDate = () => {
    const orderId = this.props.orderId
    const reqestedAtIso = dateToIso(this.state.date, this.props.tz)
    this.props.editRequestedAt(orderId, reqestedAtIso)
  }

  render() {
    const {
      requestedAt,
      interval = 15,
      weekdayTimes = {},
      excludedTimes = {},
      holidays = []
    } = this.props
    const { excludeTimes, isClosed } = makeDatepickerArgs(
      requestedAt,
      weekdayTimes,
      excludedTimes
    )
    return (
      <div className="datepicker-inline">
        <DatePicker
          showPopperArrow={false}
          showTimeSelect
          timeCaption="Time"
          timeFormat="h:mm aa"
          dateFormat="yyyy-MM-dd h:mm aa"
          timeIntervals={interval}
          excludeDates={holidays}
          excludeTimes={excludeTimes}
          filterDate={isClosed}
          // selected={requestedAt}
          // onChange={date => this.submitDate(date)}
          selected={this.state.date}
          onChange={date => this.setDate(date)}
          inline
          shouldCloseOnSelect={false}
        />
        <div className="form__submit">
          <button className="btn" onClick={this.submitDate}>
            Submit Update
          </button>
        </div>
      </div>
    )
  }
}

EditRequestedAt.displayName = 'EditRequestedAt'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { editRequestedAt }
)(EditRequestedAt)
