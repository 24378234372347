import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import { formatDollars } from '../../lib/helpers'
import { minutesToTime } from '../../lib/helpersDatetime'

const makeServiceTypes = data => {
  if (!data.length) return null
  let serviceTypes = data[0].service_types.map(i => [i.service_type])
  data.map(i =>
    i.service_types.map((s, index) => {
      serviceTypes[index].push(formatDollars(s.sales))
    })
  )
  return serviceTypes
}

const makeHours = data => {
  if (!data.length) return null
  let hours = data[0].hours.map(i => [minutesToTime(i.hour * 60)])
  data.map(i =>
    i.hours.map((s, index) => {
      hours[index].push(formatDollars(s.sales))
    })
  )
  return hours
}

const makeDayparts = data => {
  if (!data.length) return null
  let dayparts = data[0].dayparts.map(i => [i.daypart])
  data.map(i =>
    i.dayparts.map((s, index) => {
      dayparts[index].push(formatDollars(s.sales))
    })
  )
  return dayparts
}

const DashboardTable = ({ headers, data, totals, classes = '' }) => {
  if (!headers || !data || !totals) return null
  return (
    <table className={`table--report table--dashboard ${classes}`}>
      <thead>
        <tr>
          {headers.map((i, index) => (
            <th key={`thead-${i}-${index}`}>{i}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
          const name = row[0]
          return (
            <tr key={name} className={evenOdd}>
              {row.map((col, i) => (
                <td key={`${name}-${i}`}>{col}</td>
              ))}
            </tr>
          )
        })}
        <tr className="">
          {totals.map((col, i) => (
            <td key={`totals-${i}`}>{col}</td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

DashboardTable.displayName = 'DashboardTable'
DashboardTable.propTypes = {
  headers: propTypes.array,
  data: propTypes.array,
  totals: propTypes.array,
  classes: propTypes.string
}

const Dashboard = ({ data, handlers }) => {
  const headers = data.map(i => i.name)
  const totals = ['Total'].concat(
    data.map(i => formatDollars(i.net_sales_total))
  )
  const serviceTypes = makeServiceTypes(data)
  const dayparts = makeDayparts(data)
  const hours = makeHours(data)
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/dashboard/csv" />
          </div>
        </div>
      </div>
      <div className="report__table -overflow">
        {/* <Table fields={fields} items={data} /> */}
        <DashboardTable
          headers={['Service Types', ...headers]}
          data={serviceTypes}
          totals={totals}
        />
        <DashboardTable
          headers={['Dayparts', ...headers]}
          data={dayparts}
          totals={totals}
        />
        <DashboardTable
          headers={['Hours', ...headers]}
          data={hours}
          totals={totals}
        />
      </div>
    </>
  )
}

Dashboard.displayName = 'Dashboard'
Dashboard.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default Dashboard
