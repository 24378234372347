import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Menu, ShoppingBag } from 'react-feather'
import { pageType } from '../lib/constants'
import { toggleTabs, toggleSidebar } from '../reducers/page'
import { toggleCart } from '../reducers/order'
import { editWaitTimes } from '../reducers/orderMgmt'
import OrderMgmtHours from './OrderMgmtHours'
// import logo from '../assets/open-tender-logo-black.png'

const Crumb = ({ name, path }) => (
  <li>{path ? <NavLink to={path}>{name}</NavLink> : name}</li>
)

Crumb.displayName = 'Crumb'
Crumb.propTypes = {
  name: propTypes.string,
  path: propTypes.string
}

const HeaderSidebar = ({ toggleSidebar }) => {
  return (
    <div className="header__sidebar">
      <button className="btn-link" onClick={toggleSidebar}>
        <Menu size={null} />
      </button>
    </div>
  )
}

HeaderSidebar.displayName = 'HeaderSidebar'
HeaderSidebar.propTypes = {
  toggleSidebar: propTypes.func
}

const HeaderMenu = ({ toggleMenu }) => (
  <div className="header__menu">
    <button className="btn-link" onClick={toggleMenu}>
      <Menu size={null} />
    </button>
  </div>
)

HeaderMenu.displayName = 'HeaderMenu'
HeaderMenu.propTypes = {
  toggleMenu: propTypes.func
}

const OrderCart = ({ toggleCart }) => (
  <div className="header__menu">
    <button className="btn-link" onClick={toggleCart}>
      <ShoppingBag size={null} />
    </button>
  </div>
)

OrderCart.displayName = 'OrderCart'
OrderCart.propTypes = {
  toggleCart: propTypes.func
}

const renderHeaderMenu = (
  type,
  toggleTabs,
  toggleCart,
  hours,
  editWaitTimes
) => {
  switch (type) {
    case 'item':
    case 'itemList':
    case 'itemLists':
    case 'itemFiles':
    case 'itemSetting':
    case 'itemRelations':
    case 'itemRelation':
    case 'relatedItem':
    case 'setting':
    case 'dayparts':
    case 'deliveryZone':
    case 'discountItems':
    case 'hours':
    case 'holidays':
    case 'blockedHours':
    case 'itemAttributes':
    case 'revenueCenterAttributes':
    case 'dailyItems':
    case 'inventory':
    case 'menu':
    case 'mapping':
    case 'menuExt':
    case 'menuExtUpdate':
    case 'importErrors':
    case 'receipt':
    case 'iframe':
      return <HeaderMenu toggleMenu={toggleTabs} />
    case 'orderMgmt':
      return <OrderMgmtHours hours={hours} edit={editWaitTimes} />
    case 'order':
      return <OrderCart toggleCart={toggleCart} />
    default:
      return null
  }
}

class Header extends Component {
  static propTypes = {
    crumbs: propTypes.array,
    page: propTypes.object,
    hours: propTypes.object,
    toggleTabs: propTypes.func,
    toggleSidebar: propTypes.func,
    toggleCart: propTypes.func,
    editWaitTimes: propTypes.func
  }

  toggleTabs = evt => {
    evt.preventDefault()
    this.props.toggleTabs()
    evt.target.blur()
  }

  toggleSidebar = evt => {
    evt.preventDefault()
    this.props.toggleSidebar()
    evt.target.blur()
  }

  toggleCart = evt => {
    evt.preventDefault()
    this.props.toggleCart()
    evt.target.blur()
  }

  editWaitTimes = evt => {
    evt.preventDefault()
    this.props.editWaitTimes()
    evt.target.blur()
  }

  render() {
    const { crumbs, page, hours } = this.props
    const isHours = page.type === pageType.orderMgmt && hours
    return (
      <div className={`header ${isHours ? '-hours' : ''}`}>
        <div className="container">
          <div className="header__left">
            <HeaderSidebar toggleSidebar={this.toggleSidebar} />
            <div className="header__crumbs">
              <ul>
                {crumbs.map(crumb => (
                  <Crumb key={`${crumb.name}-${crumb.path}`} {...crumb} />
                ))}
              </ul>
            </div>
          </div>
          {renderHeaderMenu(
            page.type,
            this.toggleTabs,
            this.toggleCart,
            hours,
            this.editWaitTimes
          )}
        </div>
      </div>
    )
  }
}

Header.displayName = 'Header'

export default connect(
  state => ({
    page: state.page,
    hours: state.orderMgmt ? state.orderMgmt.hours : null
  }),
  { toggleTabs, toggleSidebar, toggleCart, editWaitTimes }
)(Header)
