import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../reducers/modal'

class RemoveRelation extends Component {
  static propTypes = {
    itemId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    openModal: propTypes.func
  }

  handleClick = evt => {
    const config = {
      title: 'Are you sure?',
      subtitle: 'Please click the button below to confirm removal',
      type: 'deleteRelation',
      args: [this.props.itemId]
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    return (
      <button className="btn-link" onClick={this.handleClick}>
        remove
      </button>
    )
  }
}

RemoveRelation.displayName = 'RemoveRelation'

export default connect(null, {
  openModal
})(RemoveRelation)
