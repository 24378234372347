import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deletePunch } from '../../reducers/punches'

class DeletePunch extends Component {
  static propTypes = {
    punchInId: propTypes.number,
    punchOutId: propTypes.number,
    deletePunch: propTypes.func
  }

  handleDelete = evt => {
    evt.preventDefault()
    const { punchInId, punchOutId } = this.props
    this.props.deletePunch(punchInId, punchOutId)
    evt.target.blur()
  }

  render() {
    return (
      <button className="btn" onClick={this.handleDelete}>
        Delete
      </button>
    )
  }
}

DeletePunch.displayName = 'DeletePunch'

export default connect(null, { deletePunch })(DeletePunch)
