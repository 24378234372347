import { request } from '../lib/services'
import { flashMessage } from './messages'
import { handlePageError, clearPageError } from './page'

const initState = {
  items: null,
  error: ''
}

export const FILES_CLEAR = 'FILES_CLEAR'
export const FILES_LOAD = 'FILES_LOAD'
export const FILES_ERRORS = 'FILE_ERRORS'

export const clearFiles = () => ({ type: FILES_CLEAR })
export const loadFiles = items => ({ type: FILES_LOAD, payload: items })
export const showFileError = msg => ({ type: FILES_ERRORS, payload: msg })

export const fetchFiles = () => {
  return (dispatch, getState) => {
    const {
      token,
      brand,
      page: { id, files }
    } = getState()
    if (!files) return
    dispatch(clearFiles())
    const endpoint = files.endpoint.replace(':id', id)
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(clearPageError())
        dispatch(loadFiles(resp.data || resp))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const deleteFile = fileId => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const endpoint = `${page.files.endpoint.replace(':id', page.id)}/${fileId}`
    request(token, brand, endpoint, 'DELETE')
      .then(() => {
        dispatch(fetchFiles())
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case FILES_CLEAR:
      return { ...initState }
    case FILES_LOAD:
      return { ...state, items: action.payload, error: '' }
    case FILES_ERRORS:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
