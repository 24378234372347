import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { closeModal } from '../../reducers/modal'

class OrderClosed extends Component {
  static propTypes = {
    order: propTypes.object,
    closeModal: propTypes.func
  }

  handleClose = evt => {
    evt.preventDefault()
    this.props.closeModal()
    evt.target.blur()
  }

  render() {
    const { order } = this.props
    const orderId = order.receipt_id
    const orderStatus =
      order.receipt_status === 'VOID'
        ? 'voided'
        : order.receipt_status.toLowerCase()
    return (
      <div className="modal__content">
        <div className="modal__header">
          <h2>Order no longer editable</h2>
        </div>
        <div className="modal__body text">
          <p>
            Order #{orderId} has been {orderStatus} and can no longer be edited.
          </p>
          <p>
            Please visit{' '}
            <NavLink to={`/orders/${orderId}`}>
              the {"order's"} summary page
            </NavLink>{' '}
            if you need to apply a refund.
          </p>
          <br />
          <button className="btn" onClick={this.handleClose}>
            Close Window
          </button>
        </div>
      </div>
    )
  }
}

OrderClosed.displayName = 'OrderClosed'

export default connect(null, { closeModal })(OrderClosed)
