import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RefreshCw } from 'react-feather'
import { refreshStores } from '../reducers/orderMgmtStores'

const makeStatus = revenueCenter => {
  return !revenueCenter ? (
    <span>--</span>
  ) : revenueCenter.closed ? (
    <span className="-alert">Closed</span>
  ) : revenueCenter.tempClosed ? (
    <span className="-alert bold">Temp Closed</span>
  ) : (
    <span className="success">Open</span>
  )
}

const makeMinutes = (defaultMin, currentMin) => {
  return currentMin > defaultMin ? (
    <span className="-alert bold">{currentMin} minutes</span>
  ) : currentMin < defaultMin ? (
    <span className="success">{currentMin} minutes</span>
  ) : (
    <span>{currentMin} minutes</span>
  )
}

class OrderMgmtStores extends Component {
  static propTypes = {
    loading: propTypes.bool,
    stores: propTypes.array,
    refreshStores: propTypes.func
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.refreshStores()
    evt.target.blur()
  }

  render() {
    const { stores } = this.props
    return (
      <div className="content__main">
        <div className="table__results">
          <div>
            <span className="table__results__refresh">
              <button className="btn-link" onClick={this.handleRefresh}>
                <RefreshCw size={16} />
              </button>
            </span>
            <p>Displaying {stores.length} stores</p>
          </div>
        </div>
        <div className="table__wrapper">
          <table className="table--manage-orders">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>OLO</th>
                <th colSpan={2}>OLO Pickup Wait Times</th>
                <th colSpan={2}>OLO Delivery Wait Times</th>
                <th>&nbsp;</th>
                <th>Catering</th>
                <th>Catering</th>
                <th>&nbsp;</th>
                <th>Merch</th>
              </tr>
              <tr>
                <th>Store</th>
                <th>Status</th>
                <th className="-align-right">Default</th>
                <th className="-align-left">Current</th>
                <th className="-align-right">Default</th>
                <th className="-align-left">Current</th>
                <th>&nbsp;</th>
                <th>Status</th>
                <th>Lead Time</th>
                <th>&nbsp;</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {stores.map((store, index) => {
                const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
                const times = store.olo ? store.olo.waitTimes : null
                return (
                  <tr key={store.store_id} className={evenOdd}>
                    <td>
                      <NavLink to={`/manage-orders/${store.store_id}`}>
                        {store.short_name}
                      </NavLink>
                    </td>
                    <td>{makeStatus(store.olo)}</td>
                    {!times ? (
                      <>
                        <td className="-align-right">--</td>
                        <td className="-align-left">--</td>
                        <td className="-align-right">--</td>
                        <td className="-align-left">--</td>
                      </>
                    ) : (
                      <>
                        <td className="-align-right">
                          {times.defaultPickup} minutes
                        </td>
                        <td className="-align-left">
                          {makeMinutes(
                            times.defaultPickup,
                            times.currentPickup
                          )}
                        </td>
                        <td className="-align-right">
                          {times.defaultDelivery} minutes
                        </td>
                        <td className="-align-left">
                          {makeMinutes(
                            times.defaultDelivery,
                            times.currentDelivery
                          )}
                        </td>
                      </>
                    )}
                    <td>&nbsp;</td>
                    <td>{makeStatus(store.catering)}</td>
                    <td>
                      {store.catering
                        ? `${store.catering.leadTime} hours`
                        : '--'}
                    </td>
                    <td>&nbsp;</td>
                    <td>{makeStatus(store.merch)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

OrderMgmtStores.displayName = 'OrderMgmtStores'

export default connect(
  state => ({
    ...state.orderMgmtStores
  }),
  { refreshStores }
)(OrderMgmtStores)
