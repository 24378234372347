import { request, uploadFile } from '../lib/services'
import { sortItems } from '../lib/helpers'
import { makeRelationsEntity, makeNextPosition } from '../lib/helpersPage'
import { flashMessage } from './messages'
import { openModal, closeModal } from './modal'
import { clearSearchResults, showSearchError } from './search'
import { fetchItem } from './item'
import { handleError } from '../lib/errors'
import { handlePageError } from './page'
import { reloadOptions } from './options'

const initState = {
  items: [],
  loading: true,
  error: ''
}

export const RELATIONS_CLEAR = 'RELATIONS_CLEAR'
export const RELATIONS_LOAD = 'RELATIONS_LOAD'
export const RELATIONS_UPDATE = 'RELATIONS_UPDATE'
export const RELATIONS_REORDER = 'RELATIONS_REORDER'
export const RELATIONS_ERROR = 'RELATIONS_ERROR'

export const clearRelations = () => ({ type: RELATIONS_CLEAR })
export const loadRelations = items => ({ type: RELATIONS_LOAD, payload: items })
export const showRelationsError = msg => ({
  type: RELATIONS_ERROR,
  payload: msg
})

export const updateRelations = relation => {
  return (dispatch, getState) => {
    const { relations, page } = getState()
    const pageRelations = page.relations || page.relatedItems
    let newRelations = relations.items.filter(
      i => i[pageRelations.id] !== relation[pageRelations.id]
    )
    newRelations = [...newRelations, relation]
    newRelations = sortItems(newRelations, pageRelations.sorting)
    dispatch({ type: RELATIONS_UPDATE, payload: newRelations })
  }
}

// used to reorder items that are being treated as M2M relations
// as well as M2M relations (e.g. items in categories and modifier groups)
export const reorderItems = (endpoint, data) => {
  return (dispatch, getState) => {
    const { token, brand, relations, page } = getState()
    const { sortBy, fields, isEntity } = page.relations.sorting
    // update order before request
    const orderMap = data.reduce((obj, item) => {
      obj[item[page.relations.id]] = item[sortBy]
      return obj
    }, {})
    const newItems = relations.items.map(i => {
      i[sortBy] = orderMap[i[page.relations.id]]
      return i
    })
    const items = sortItems(newItems, page.relations.sorting)
    dispatch({ type: RELATIONS_REORDER, payload: items })
    // post new order to API
    let reorderEndpoint = '',
      method = '',
      optionsEndpoint = ''
    // fields indicates that we're reordering a list of M2M relations
    // which may require fields other than the item ID and display order
    if (fields) {
      optionsEndpoint = page.relations.endpoint
      reorderEndpoint = page.relations.endpoint.replace(':id', page.id)
      method = 'PUT'
    } else {
      const entity = !isEntity ? makeRelationsEntity(endpoint) : endpoint
      reorderEndpoint = `/${entity}/reorder`.replace('//', '/')
      method = 'POST'
    }
    // console.log(method, reorderEndpoint)
    // console.log(JSON.stringify(data, null, 2))
    request(token, brand, reorderEndpoint, method, data)
      .then(() => {
        dispatch(reloadOptions(optionsEndpoint))
        dispatch(flashMessage('Items reordered!'))
      })
      .catch(err => {
        dispatch({ type: RELATIONS_REORDER, payload: relations.items })
        dispatch(handlePageError(err))
      })
  }
}

export const searchAddRelation = search => {
  return dispatch => {
    dispatch(clearSearchResults())
    const config = {
      title: search.modalTitle,
      subtitle: search.modalSubtitle,
      classes: `modal--big ${search.classes}`,
      type: search.modalType,
      args: search.modalSettings
    }
    dispatch(openModal(config))
  }
}

export const addNewRelation = (id, resultId, defaults) => {
  return (dispatch, getState) => {
    const { token, brand, page, relations } = getState()
    const { endpoint } = page.relations
    const relationsEndpoint = endpoint.replace(':id', page.id)
    const nextPosition = makeNextPosition(
      page.relations.sorting,
      relations.items
    )
    const values = { ...defaults, ...nextPosition }
    const items = [{ [resultId]: parseInt(id), ...values }]
    request(token, brand, relationsEndpoint, 'POST', items)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchItem())
        dispatch(reloadOptions(endpoint))
        dispatch(flashMessage('Successfully created!'))
        setTimeout(() => {
          dispatch(clearSearchResults())
        }, 500)
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showSearchError(errors.form))
      })
  }
}

export const deleteRelation = itemId => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, relations } = page
    const endpoint = relations.endpoint.replace(':id', id)
    const data = [{ [relations.id]: itemId }]
    request(token, brand, endpoint, 'DELETE', data)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchItem())
        dispatch(reloadOptions(relations.endpoint))
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        const errors = handleError(err, true)
        err.detail = errors.fields[relations.id] || errors.form
        dispatch(handlePageError(err))
      })
  }
}

export const uploadCSV = (endpoint, csv) => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const uploadEndpoint = endpoint.replace(':id', page.id)
    uploadFile(token, brand, uploadEndpoint, csv)
      .then(resp => {
        dispatch(fetchItem())
        const msg = `${resp.count} emails successfully added!`
        dispatch(flashMessage(msg))
        if (resp.invalid.length) {
          const invalid = resp.invalid.join(', ')
          const errMsg = `The following emails are invalid: ${invalid}`
          throw new Error(errMsg)
        }
      })
      .catch(err => {
        err.detail = err.title || err.message
        dispatch(handlePageError(err))
      })
  }
}

const relationsReducer = (state = initState, action) => {
  switch (action.type) {
    case RELATIONS_CLEAR:
      return { ...initState }
    case RELATIONS_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case RELATIONS_UPDATE:
      return { ...state, items: action.payload }
    case RELATIONS_REORDER:
      return { ...state, items: action.payload }
    case RELATIONS_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

export default relationsReducer
