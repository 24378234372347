import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'
import { ResponsiveBar } from './Charts'

const fields = [
  {
    name: 'Promo Code',
    field: ['promo_code'],
    link: 'discount_id',
    linkEndpoint: '/promo-codes'
  },
  {
    name: 'Redeemed At',
    field: ['redeemed_at']
  },
  {
    name: 'Order ID',
    field: ['receipt_id'],
    link: 'receipt_id',
    linkEndpoint: '/orders'
  },
  {
    name: 'Customer',
    field: ['customer'],
    link: 'customer_id',
    linkEndpoint: '/customers'
  },
  { name: 'Location', field: ['location'] },
  { name: 'Code', field: ['code'] },
  { name: 'Order Type', field: ['order_type'] },
  // { name: 'Service Type', field: ['service_type'] },
  // { name: 'Tender Type', field: ['tender_type'] },
  {
    name: 'Subtotal',
    field: ['subtotal'],
    format: 'dollars'
  },
  {
    name: 'Discount',
    field: ['discount'],
    format: 'dollars'
  },
  {
    name: 'Total',
    field: ['total'],
    format: 'dollars'
  }
]

const makeChartData = data => {
  if (!data || !data.length) return []
  const years = data.map(i => i.date.split('-')[0])
  const unique = [...new Set(years)]
  const chartData = data.map(i => {
    const values = i.data.reduce(
      (obj, d) => ({ ...obj, [d.name]: d.count }),
      {}
    )
    const name =
      unique.length === 1 ? i.date.replace(`${unique[0]}-`, '') : i.date
    return { name, ...values }
  })
  const interval = unique.length === 1 && data.length <= 31 ? 0 : null
  return [chartData, interval]
}

const makeChartBars = data => {
  if (!data || !data.length) return []
  return data[0].data.map(i => i.name)
}

// const testData = [
//   {
//     name: '05-01',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 3,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 2,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 1
//   },
//   {
//     name: '05-02',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 4,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 5,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 3
//   },
//   {
//     name: '05-03',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 10,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 3,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 2
//   },
//   {
//     name: '05-04',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 15,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 4,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 6
//   },
//   {
//     name: '05-05',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 12,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 10,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 6
//   },
//   {
//     name: '05-06',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 8,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 12,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 9
//   },
//   {
//     name: '05-07',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 7,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 13,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 4
//   },
//   {
//     name: '05-08',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 10,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 22,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 13
//   },
//   {
//     name: '05-09',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 11,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 18,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 15
//   },
//   {
//     name: '05-10',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 14,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 26,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 18
//   },
//   {
//     name: '05-11',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 8,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 29,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 18
//   },
//   {
//     name: '05-12',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 12,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 20,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 24
//   },
//   {
//     name: '05-13',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 15,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 13,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 24
//   },
//   {
//     name: '05-14',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 18,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 9,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 27
//   },
//   {
//     name: '05-15',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 20,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 5,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 22
//   },
//   {
//     name: '05-16',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-17',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-18',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-19',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-20',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-21',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-22',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-23',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-24',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-25',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-26',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-27',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-28',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-29',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-30',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   },
//   {
//     name: '05-31',
//     X69HPVLIWBS4I58OOPXXCQ31J3A033HZ: 0,
//     O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0: 0,
//     LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU: 0
//   }
// ]

// const testBars = [
//   'X69HPVLIWBS4I58OOPXXCQ31J3A033HZ',
//   'O33Z3GY2LV068KIUBCGPK43PGSXQ3XJ0',
//   'LHI1RGLPEE25RPFNAVEOMQ1Y6HGOWRFU'
// ]

const PromoCodes = ({ data, handlers }) => {
  const [tableData, graphData] = data
  const [chartData, interval] = makeChartData(graphData)
  const chartBars = makeChartBars(graphData)

  return (
    <>
      <div className="reports">
        <div className="report -full">
          <div className="report__header">
            <h2>Redemptions Over Time</h2>
          </div>
          <ResponsiveBar
            height={500}
            data={chartData}
            bars={chartBars}
            maxBarSize={15}
            stackId="a"
            layout="horizontal"
            xAxis={{
              dataKey: 'name',
              type: 'category',
              orientation: 'bottom',
              interval
              // angle: 45,
              // tickMargin: 20
            }}
            yAxis={{ type: 'number', orientation: 'left', width: 70 }}
            showLabel={false}
            margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
          />
        </div>
      </div>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={tableData} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/promo-codes/csv" />
          </div>
        </div>
      </div>
      <div className="report__table">
        <Table fields={fields} items={tableData} />
      </div>
    </>
  )
}

PromoCodes.displayName = 'PromoCodes'
PromoCodes.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default PromoCodes
