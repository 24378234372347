import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  {
    name: 'Customer',
    field: ['first_name', 'last_name'],
    link: 'customer_id',
    linkEndpoint: '/customers'
  },
  {
    name: 'Created At',
    field: ['created_at']
  },
  { name: 'Phone', field: ['phone'] },
  { name: 'Email', field: ['email'] },
  { name: 'LevelUp Email', field: ['levelup_email'] }
]

const LevelUpConnections = ({ data, handlers }) => {
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/levelup-connections/csv" />
          </div>
        </div>
      </div>
      <div className="report__table">
        <Table fields={fields} items={data} />
      </div>
    </>
  )
}

LevelUpConnections.displayName = 'LevelUpConnections'
LevelUpConnections.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default LevelUpConnections
