import { clearSearchResults } from './search'
import { handlePageError } from './page'
import { handleError } from '../lib/errors'

const initState = {
  loading: false,
  title: '',
  subtitle: '',
  errorMsg: null,
  type: null,
  args: [],
  content: null,
  deleteItemId: null,
  errors: null,
  hideClose: false
}

export const MODAL_CLOSE = 'MODAL_CLOSE'
export const MODAL_LOADING = 'MODAL_LOADING'
export const MODAL_LOAD = 'MODAL_LOAD'
export const MODAL_ERRORS = 'MODAL_ERRORS'

export const loadModal = config => ({ type: MODAL_LOAD, payload: config })
export const showModalErrors = errors => ({
  type: MODAL_ERRORS,
  payload: errors
})

export const handleModalErrors = err => {
  return dispatch => {
    dispatch(handlePageError(err, true))
    const errors = handleError(err, true)
    dispatch(showModalErrors(errors))
  }
}

export const loadingModal = () => {
  return dispatch => {
    document.body.classList.add('is-modal')
    dispatch({ type: MODAL_LOADING })
  }
}

export const openModal = config => {
  return dispatch => {
    document.body.classList.add('is-modal')
    dispatch(clearSearchResults())
    dispatch(loadModal(config))
  }
}

export const closeModal = () => {
  return dispatch => {
    document.body.classList.remove('is-modal')
    dispatch({ type: MODAL_CLOSE })
  }
}

export const testModal = () => {
  return dispatch => {
    const config = {
      title: 'Testing Modal',
      subtitle: 'Subtitle goes here'
    }
    dispatch(loadModal(config))
  }
}

export const openVideoModal = (title, subtitle, youtubeId) => {
  return dispatch => {
    const config = {
      title: title,
      subtitle: subtitle,
      classes: 'modal--video',
      type: 'youtube',
      args: { title, youtubeId }
    }
    dispatch(loadModal(config))
  }
}

export const showWorkingModal = () => {
  return dispatch => {
    const config = {
      title: 'Working...',
      subtitle: 'This will be done in just a sec',
      classes: 'modal--loading',
      type: 'submitting'
    }
    dispatch(openModal(config))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case MODAL_CLOSE:
      return { ...initState }
    case MODAL_LOAD:
      return { ...state, loading: false, ...action.payload }
    case MODAL_LOADING:
      return { ...state, loading: true }
    case MODAL_ERRORS:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
