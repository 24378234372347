import { request } from '../lib/services'
import { handlePageError, clearPageError } from './page'
import { sortItems } from '../lib/helpers'
import { flashMessage } from './messages'

const initState = {
  loading: false,
  stores: []
}

export const ORDER_MGMT_STORES_CLEAR = 'ORDER_MGMT_STORES_CLEAR'
export const ORDER_MGMT_STORES_LOADING = 'ORDER_MGMT_STORES_LOADING'
export const ORDER_MGMT_STORES_LOAD = 'ORDER_MGMT_STORES_LOAD'

export const clearStores = () => ({ type: ORDER_MGMT_STORES_CLEAR })
export const loadingStores = () => ({ type: ORDER_MGMT_STORES_LOADING })
export const loadStores = stores => ({
  type: ORDER_MGMT_STORES_LOAD,
  payload: stores
})

export const makeWaitTimes = (deliveryWaitTimes, pickupWaitTimes) => {
  return {
    DELIVERY: deliveryWaitTimes
      ? deliveryWaitTimes.reduce(
          (obj, i) => ({
            ...obj,
            [i.delivery_wait_time_id]: i.delivery_wait_time
          }),
          {}
        )
      : {},
    PICKUP: pickupWaitTimes
      ? pickupWaitTimes.reduce(
          (obj, i) => ({ ...obj, [i.pickup_wait_time_id]: i.pickup_wait_time }),
          {}
        )
      : {}
  }
}

const makeStore = (store, waitTimes) => {
  const rcs = store.revenue_centers.reduce((obj, i) => {
    const tempPickupId =
      i.temporary_pickup_wait_time_id || i.pickup_wait_time_id
    const tempDeliveryId =
      i.temporary_delivery_wait_time_id || i.delivery_wait_time_id
    const settings = {
      defaultPickup: waitTimes.PICKUP[i.pickup_wait_time_id],
      currentPickup: waitTimes.PICKUP[tempPickupId],
      defaultDelivery: waitTimes.DELIVERY[i.delivery_wait_time_id],
      currentDelivery: waitTimes.DELIVERY[tempDeliveryId],
      leadTime: i.order_lead_time
    }
    obj[i.revenue_center_type] = { ...i, settings }
    return obj
  }, {})
  const { full_name, short_name } = store
  const name = `${full_name}${short_name ? ` (${short_name})` : ''}`
  const newStore = {
    store_id: store.store_id,
    name: name,
    full_name: full_name,
    short_name: short_name || '',
    olo: rcs.OLO
      ? {
          closed: rcs.OLO.closed,
          tempClosed: rcs.OLO.is_temporarily_closed,
          waitTimes: rcs.OLO.settings
        }
      : null,
    catering: rcs.CATERING
      ? {
          closed: rcs.CATERING.closed,
          tempClosed: rcs.CATERING.is_temporarily_closed,
          leadTime: rcs.CATERING.settings.leadTime
        }
      : null,
    merch: rcs.MERCH
      ? {
          closed: rcs.MERCH.closed,
          tempClosed: rcs.MERCH.is_temporarily_closed
        }
      : null
  }
  return newStore
}

export const fetchOrderMgmtStores = isRefresh => {
  return (dispatch, getState) => {
    const { token, brand, options } = getState()
    dispatch(clearPageError())
    dispatch(loadingStores())
    const endpoint = `/stores?with_related=revenue_centers&limit=1000`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const waitTimes = makeWaitTimes(
          options['delivery-wait-times'],
          options['pickup-wait-times']
        )
        let stores = resp.data
          .filter(i => !i.is_master)
          .map(i => makeStore(i, waitTimes))
        stores = sortItems(stores, { sortBy: 'short_name', sortType: 'alpha' })
        dispatch(loadStores(stores))
        if (isRefresh) dispatch(flashMessage('Stores refreshed!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const refreshStores = () => {
  return dispatch => {
    dispatch(fetchOrderMgmtStores(true))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ORDER_MGMT_STORES_CLEAR:
      return { ...initState }
    case ORDER_MGMT_STORES_LOADING:
      return { ...state, loading: true }
    case ORDER_MGMT_STORES_LOAD:
      return { ...state, stores: action.payload, loading: false }
    default:
      return state
  }
}
