import propTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

class ErrorBoundary extends React.Component {
  static propTypes = {
    error: propTypes.object,
    info: propTypes.object,
    user: propTypes.object
  }

  resetApp = evt => {
    evt.preventDefault()
    window.location.replace('/login')
    evt.target.blur()
  }

  render() {
    const { error, info } = this.props
    // const user = this.props.user || {}
    const fileUrl =
      'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578405841_asian-food-spread-top-down_1800x1200.jpg'
    return (
      <div
        className="landing landing--error"
        style={{
          backgroundImage: `url(${fileUrl})`,
          backgroundPosition: `center center`
        }}
      >
        <div className="landing__overlay"></div>
        <div className="landing__content">
          <div className="landing__text text">
            <div className="landing__text__content">
              <h1>Uh oh. Something went wrong.</h1>
              <p>An unexpected error occurred on the page you were visiting.</p>
              <p>
                {"We're"} really sorry about this. Our development team has
                already been alerted, and {"we're"} looking into it.
              </p>
              <p>
                <button className="btn-link" onClick={this.resetApp}>
                  Please click here to reset your session and give it another
                  try.
                </button>
              </p>
            </div>
          </div>
        </div>
        <div className="landing__content">
          <div className="landing__error">
            <div className="landing__error__content">
              <pre>
                {error.toString()}
                {info.componentStack}
              </pre>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ErrorBoundary.displayName = 'ErrorBoundary'

export default connect(state => ({ user: state.user }), {})(ErrorBoundary)
