import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { weekdayOptions } from '../../lib/constants'
import Form from '../Form'
import { PageError } from '../PageError'
import { insertBlocked } from '../../reducers/blockedHours'
import { isEmpty } from '../../lib/utils'

const fields = [
  {
    label: 'Weekday',
    field: 'weekday',
    type: 'select',
    options: weekdayOptions,
    defaultValue: 'SUNDAY'
  },
  {
    label: 'Start Time',
    field: 'start_time',
    type: 'text'
  },
  {
    label: 'End Time',
    field: 'end_time',
    type: 'text'
  }
]

class BlockedHours extends Component {
  static propTypes = {
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    insertBlocked: propTypes.func
  }

  render() {
    const { errors, windowRef, insertBlocked } = this.props
    if (!isEmpty(errors) && windowRef.current) windowRef.current.scrollTop = 0
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={false}
          fields={fields}
          data={{}}
          errors={errors || {}}
          upsertItem={insertBlocked}
          windowRef={windowRef}
        />
      </>
    )
  }
}

BlockedHours.displayName = 'BlockedHours'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { insertBlocked }
)(BlockedHours)
