import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import PrintRouter from './PrintRouter'
import { clearPrint } from '../reducers/orderMgmt'

// https://github.com/gregnb/react-to-print/blob/master/README.md

class PrintOrderRouter extends Component {
  constructor(props) {
    super(props)
    this.printButton = React.createRef()
  }

  static propTypes = {
    args: propTypes.object,
    clearPrint: propTypes.func
  }

  componentDidMount() {
    this.printButton.current.click()
  }

  render() {
    const { args } = this.props
    return (
      <div style={{ display: 'none' }}>
        <ReactToPrint
          trigger={() => (
            <button className="btn" ref={this.printButton}>
              Print
            </button>
          )}
          content={() => this.componentRef}
          onAfterPrint={() => this.props.clearPrint()}
        />
        <PrintRouter {...args} ref={el => (this.componentRef = el)} />
      </div>
    )
  }
}

PrintOrderRouter.displayName = 'PrintOrderRouter'

export default connect(null, { clearPrint })(PrintOrderRouter)
