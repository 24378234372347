import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  {
    name: 'Order ID',
    field: ['order_id'],
    link: 'order_id',
    linkEndpoint: '/orders'
  },
  { name: 'POS Order ID', field: ['local_id'] },
  {
    name: 'Date & Time',
    field: ['created_at'],
    format: 'datetimeZoned',
    tz: 'timezone'
  },
  {
    name: 'Cashier',
    field: ['employee'],
    link: 'employee_id',
    linkEndpoint: '/employees'
  },
  { name: 'Timeclock ID', field: ['timeclock_id'] },
  { name: 'POS Terminal', field: ['terminal'] },
  { name: 'Service Type', field: ['service_type'], format: 'enum' },
  {
    name: 'Amount',
    field: ['amount'],
    format: 'dollars'
  }
]

const Voids = ({ data, handlers }) => {
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/voids/csv" />
          </div>
        </div>
      </div>
      <div className="report__table">
        <Table fields={fields} items={data} />
      </div>
    </>
  )
}

Voids.displayName = 'Voids'
Voids.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default Voids
