import { request } from '../lib/services'
import { makeQueryParams, sortItems } from '../lib/helpers'
import {
  updatePage,
  makeNewFilters,
  makeResetFilters,
  handlePageError,
  clearPageError
} from './page'
import { flashMessage } from './messages'
import { loadingModal, openModal, closeModal } from './modal'
import { fetchItems } from './list'

const initState = {
  items: [],
  links: {},
  loading: true,
  error: ''
}

export const ORDERS_CLEAR = 'ORDERS_CLEAR'
export const ORDERS_CLEAR_ITEMS = 'ORDERS_CLEAR_ITEMS'
export const ORDERS_LOAD = 'ORDERS_LOAD'
export const ORDERS_ERROR = 'ORDERS_ERROR'

export const clearOrders = () => ({ type: ORDERS_CLEAR })
export const clearOrdersItems = () => ({ type: ORDERS_CLEAR_ITEMS })
export const loadOrders = items => ({ type: ORDERS_LOAD, payload: items })
export const showOrdersError = msg => ({ type: ORDERS_ERROR, payload: msg })

const makeGroupedOrders = (items, key) => {
  let entity
  if (key.includes('.')) {
    // eslint-disable-next-line no-extra-semi
    ;[entity, key] = key.split('.')
    items = items.map(i => ({ ...i, [key]: i[entity][key] }))
  }
  return items.reduce((arr, i) => {
    const existing = arr.filter(j => j[key] === i[key])
    existing.length
      ? existing[0].orders.push(i)
      : arr.push({ [key]: i[key], orders: [i] })
    return arr
  }, [])
}

export const fetchOrders = noClear => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const orders = page.orders
    let filters = orders.filters.filter(i => i.field !== 'group_by')
    if (orders.params) filters = [...filters, ...orders.params]
    const queryParams = makeQueryParams(filters)
    const endpoint = `/bulk-orders?exclude_in_store_orders=true&${queryParams}`
    // console.log(endpoint)
    if (!noClear) dispatch(clearOrdersItems())
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(clearPageError())
        // console.log(JSON.stringify(resp.data[0], null, 2))
        let items = resp.data || resp
        if (orders.exclude) {
          orders.exclude.map(ex => {
            items = items.filter(i => !ex.value.includes(i[ex.field]))
          })
        }
        const groupBy = orders.filters.find(i => i.field === 'group_by')
        if (groupBy) {
          const groupedOrders = makeGroupedOrders(items, groupBy.value)
          return dispatch(loadOrders(groupedOrders))
        }
        items = sortItems(items, orders.sorting)
        return dispatch(loadOrders(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const refreshOrders = () => {
  return dispatch => dispatch(fetchOrders())
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    const { orders } = getState().page
    const newFilters = makeNewFilters(orders.filters, filter)
    const newOrders = { ...orders, filters: newFilters }
    dispatch(updatePage({ orders: newOrders }))
    dispatch(fetchOrders())
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    const { orders } = getState().page
    const newFilters = makeResetFilters(orders.filters)
    const newOrders = { ...orders, filters: newFilters }
    dispatch(updatePage({ orders: newOrders }))
    dispatch(fetchOrders())
  }
}

export const getOrderOrders = (items, orderId) => {
  let order
  for (let i = 0; i < items.length; i++) {
    order = items[i].orders.find(o => parseInt(orderId) === o.receipt_id)
    if (order) break
  }
  return order
}

export const disableOrder = orderId => {
  return (dispatch, getState) => {
    const { items } = getState().orders
    const updatedItems = items.map(item => {
      const orders = item.orders.map(i => {
        if (i.receipt_id === orderId) i.disabled = true
        return i
      })
      return { ...item, orders }
    })
    dispatch(loadOrders(updatedItems))
  }
}

export const enableOrder = orderId => {
  return (dispatch, getState) => {
    const { items } = getState().orders
    const updatedItems = items.map(item => {
      const orders = item.orders.map(i => {
        if (i.receipt_id === orderId) delete i.disabled
        return i
      })
      return { ...item, orders }
    })
    dispatch(loadOrders(updatedItems))
  }
}

export const updateOrderOrders = (orderId, data) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    const endpoint = `/orders/${orderId}/edit`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        dispatch(fetchOrders(true))
        dispatch(flashMessage(`Order ${orderId} successfully updated!`))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(enableOrder(orderId)))
  }
}

export const confirmCancel = orderId => {
  return dispatch => {
    dispatch(loadingModal())
    const cancel = () => dispatch(cancelOrder(orderId))
    const config = {
      title: `Confirm Cancel`,
      type: 'orderCancel',
      args: { orderId, cancel }
    }
    dispatch(openModal(config))
  }
}

export const cancelOrder = orderId => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    const { token, brand, page } = getState()
    dispatch(disableOrder(orderId))
    request(token, brand, `/orders/${orderId}/cancel`, 'POST')
      .then(() => {
        page.orders ? dispatch(fetchOrders()) : dispatch(fetchItems())
        dispatch(flashMessage('Order successfully cancelled!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(enableOrder(orderId))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ORDERS_CLEAR:
      return { ...initState }
    case ORDERS_CLEAR_ITEMS:
      return { ...state, items: [], loading: true, error: '' }
    case ORDERS_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case ORDERS_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
