import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { contains } from '../../lib/helpers'
import { updateSelected, updateRecItems } from '../../reducers/recommendedItems'
import ItemSelection from '../ItemSelection'

class RecommendedItem extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    endpoint: propTypes.string,
    resultId: propTypes.string,
    resultName: propTypes.string,
    resultParens: propTypes.string,
    placeholder: propTypes.string,
    defaults: propTypes.object,
    addNewRelation: propTypes.func
  }

  componentDidMount() {
    const { items, recommendationType } = this.props
    const selected = items
      .filter(i => i.recommendation_type === recommendationType)
      .map(i => ({ category_id: i.category_id, item_id: i.item_id }))
    this.props.updateSelected(selected)
  }

  handleSelectedItems = evt => {
    const values = [...evt.target.options]
      .filter(i => i.selected)
      .map(i => {
        const [categoryId, itemId] = i.value.split('-').map(i => parseInt(i))
        return { category_id: categoryId, item_id: itemId }
      })
    this.props.updateSelected(values)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateRecItems(this.props.recommendationType)
    this.submitButton.current.blur()
  }

  render() {
    const { menus, categories, items, recommendationType } = this.props
    const selected = items
      .filter(i => i.recommendation_type === recommendationType)
      .map(i => ({ category_id: i.category_id, item_id: i.item_id }))
    const menuIds = menus.map(i => i.menu.menu_id)
    const filtered = categories
      .map(i => ({
        ...i,
        menuIds: i.menus.map(j => j.menu.menu_id)
      }))
      .filter(i => contains(i.menuIds, menuIds))
    return (
      <form id="form" className="form" onSubmit={this.handleSubmit}>
        <ItemSelection
          label="Items"
          // field="discounted_items"
          menu={filtered}
          selected={selected}
          handler={this.handleSelectedItems}
          size="20"
        />
        <div className="form__submit">
          <input
            className="btn"
            type="submit"
            value="Update"
            ref={this.submitButton}
          />
        </div>
      </form>
    )
  }
}

RecommendedItem.displayName = 'RecommendedItem'

export default connect(
  state => ({
    ...state.recommendedItems,
    menus: state.item.data.menus
  }),
  { updateSelected, updateRecItems }
)(RecommendedItem)
