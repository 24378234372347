import propTypes from 'prop-types'
import React from 'react'
import { Error } from './FormInputs'

const PassWordInput = ({
  label,
  id,
  value,
  error = '',
  show,
  reset,
  handleInput,
  handleToggle
}) => {
  return (
    <label htmlFor="password" className="label">
      <span>{label}</span>
      <button
        className="btn-link label__btn"
        onClick={handleToggle}
        tabIndex="-1"
      >
        {show ? 'hide' : 'show'}
      </button>
      <input
        id={id}
        type={show ? 'text' : 'password'}
        value={reset ? '' : value || ''}
        onChange={handleInput}
        autoComplete={id}
      />
      {error.length ? <Error error={error} /> : null}
    </label>
  )
}

PassWordInput.displayName = 'PassWordInput'
PassWordInput.propTypes = {
  label: propTypes.string,
  id: propTypes.string,
  value: propTypes.string,
  error: propTypes.string,
  reset: propTypes.bool,
  show: propTypes.bool,
  handleInput: propTypes.func,
  handleToggle: propTypes.func
}

export default PassWordInput
