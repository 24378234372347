import { request } from '../lib/services'
import { makeQueryParams } from '../lib/helpers'
import {
  updatePage,
  makeNewFilters,
  handlePageError,
  clearPageError,
  redirectPage
} from './page'
import { flashMessage } from './messages'
import { fmtDate } from '../lib/helpersDatetime'

const initState = {
  loading: false,
  storeId: null,
  startDate: null,
  endDate: null,
  hours: [],
  error: ''
}

export const WEEK_CLEAR = 'WEEK_CLEAR'
export const WEEK_CLEAR_HOURS = 'WEEK_CLEAR_HOURS'
export const WEEK_LOAD = 'WEEK_LOAD'
export const WEEK_UPDATE = 'WEEK_UPDATE'
export const WEEK_ERROR = 'WEEK_ERROR'

export const clearHoursForWeek = () => ({ type: WEEK_CLEAR })
export const clearHours = () => ({ type: WEEK_CLEAR_HOURS })
export const loadHours = hoursForWeek => ({
  type: WEEK_LOAD,
  payload: hoursForWeek
})
export const showHoursError = msg => ({ type: WEEK_ERROR, payload: msg })
export const updateHours = update => ({
  type: WEEK_UPDATE,
  payload: update
})

const mapFilters = filters => {
  return filters.reduce((obj, i) => {
    obj[i.field] = i.value
    return obj
  }, {})
}

const makeDates = (start, end) => {
  if (end < start) {
    end = start
  }
  return { start, end }
}

export const fetchHoursForWeek = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(clearHours())
    const { token, brand, page, hoursForWeek } = getState()
    const args = mapFilters(page.hoursForWeek.filters)
    const { storeId, startDate, endDate } = hoursForWeek
    // handle the revenue center filter
    if (storeId) {
      if (storeId !== args.store_id) {
        const filter = { field: 'store_id', value: storeId }
        return dispatch(updateFilter(filter))
      }
    } else if (args.store_id) {
      dispatch(updateHours({ storeId: args.store_id }))
    }
    // handle start date filter
    if (startDate) {
      const startFilter = args.start_date ? fmtDate(args.start_date) : null
      if (startDate !== startFilter) {
        const newStart = new Date(`${startDate} 00:00:00`)
        const filter = { field: 'start_date', value: newStart }
        return dispatch(updateFilter(filter))
      }
    } else if (args.start_date) {
      dispatch(updateHours({ startDate: fmtDate(args.start_date) }))
    }
    // handle end date filter
    if (endDate) {
      const endFilter = args.end_date ? fmtDate(args.end_date) : null
      if (endDate !== endFilter) {
        const newEnd = new Date(`${endDate} 00:00:00`)
        const filter = { field: 'end_date', value: newEnd }
        return dispatch(updateFilter(filter))
      }
    } else if (args.end_date) {
      dispatch(updateHours({ endDate: fmtDate(args.end_date) }))
    }
    if (!args.start_date || !args.end_date) return
    const { start, end } = makeDates(args.start_date, args.end_date)
    if (end !== args.end_date) {
      return dispatch(updateFilter({ field: 'end_date', value: end }))
    }
    const startDateStr = fmtDate(start)
    const endDateStr = fmtDate(end)
    let queryParams = `start_date=${startDateStr}&end_date=${endDateStr}`
    if (args.store_id) queryParams += `&store_id=${args.store_id}`
    const endpoint = `/reporting/hours-for-week?${queryParams}`
    request(token, brand, endpoint, 'GET')
      .then(hours => {
        dispatch(loadHours(hours))
        dispatch(flashMessage('Successfully retrieved!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showHoursError('Something went wrong'))
      })
  }
}

export const refreshHoursForWeek = () => {
  return dispatch => {
    dispatch(updateHours({ startDate: null, endDate: null }))
    dispatch(fetchHoursForWeek())
  }
}

const translateField = field => {
  const fields = {
    store_id: 'storeId',
    start_date: 'startDate',
    end_date: 'endDate'
  }
  return fields[field]
}

const makeFilterValue = filter => {
  if (filter.field === 'store_id') {
    return filter.value ? parseInt(filter.value) : null
  }
  return fmtDate(filter.value)
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    const { page, hoursForWeek } = getState()
    if (filter.field === 'start_date' || filter.field === 'end_date') {
      if (!filter.value) filter.value = new Date()
    }
    const updated = { [translateField(filter.field)]: makeFilterValue(filter) }
    dispatch(updateHours(updated))
    let newFilters = makeNewFilters(page.hoursForWeek.filters, filter)
    if (filter.field === 'start_date') {
      const args = mapFilters(newFilters)
      const currentEnd = new Date(`${hoursForWeek.endDate} 00:00:00`)
      const { end } = makeDates(args.start_date, currentEnd)
      if (end !== args.end_date) {
        const endFilter = { field: 'end_date', value: end }
        dispatch(updateHours({ endDate: makeFilterValue(endFilter) }))
        newFilters = makeNewFilters(newFilters, endFilter)
      }
    }
    const newHours = { ...page.hoursForWeek, filters: newFilters }
    dispatch(updatePage({ hoursForWeek: newHours }))
    dispatch(fetchHoursForWeek())
  }
}

export const downloadCsv = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { filters } = page.hoursForWeek
    dispatch(clearPageError())
    const params = makeQueryParams(filters || [])
    const endpoint = '/reporting/hours-for-week/csv'
    const csvEndpoint = endpoint + (params ? `?${params}` : '')
    request(token, brand, csvEndpoint, 'POST')
      .then(() => dispatch(redirectPage('/downloads')))
      .catch(err => dispatch(handlePageError(err)))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case WEEK_CLEAR:
      return { ...initState }
    case WEEK_CLEAR_HOURS:
      return { ...state, hours: [], loading: true, error: '' }
    case WEEK_LOAD:
      return { ...state, hours: action.payload, loading: false, error: '' }
    case WEEK_UPDATE:
      return { ...state, ...action.payload }
    case WEEK_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
