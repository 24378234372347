import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addTenderOther } from '../../reducers/order'
import { showModalErrors } from '../../reducers/modal'
import Form from '../Form'
import { PageError } from '../PageError'
import { valTypes } from '../../lib/constants'
import { tendersTotalAndBalance } from '../../lib/helpersOrder'

const fields = [
  {
    label: 'Dollar Amount',
    field: 'amount',
    type: 'text',
    valType: valTypes.decimal
  },
  {
    field: 'tenderType',
    type: 'hidden'
  }
]

class TenderOther extends Component {
  static propTypes = {
    // state.order.customer
    tenderType: propTypes.string,
    tenders: propTypes.array,
    orderTotal: propTypes.string,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    addTenderOther: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { tenderType, tenders, orderTotal, errors } = this.props
    const [, remaining] = tendersTotalAndBalance(tenders, orderTotal)
    const data = { amount: remaining, tenderType: tenderType }
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={true}
          idField="customer_id"
          fields={fields}
          data={data}
          errors={errors || {}}
          upsertItem={this.props.addTenderOther}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </>
    )
  }
}

TenderOther.displayName = 'TenderOther'

export default connect(
  state => ({
    tenders: state.order.tenders,
    orderTotal: state.order.order.total,
    errors: state.modal.errors
  }),
  { addTenderOther, showModalErrors }
)(TenderOther)
