import { handleError } from '../lib/errors'
import { errors as requestErrors } from '../lib/services'
import { openModal, closeModal, loadingModal } from './modal'
import { flashMessage } from './messages'
import { handlePageError } from './page'

const initState = {
  data: {},
  errors: {},
  submiting: false,
  show: false,
}

const url = process.env.REACT_APP_API_URL

export const CHANGE_RESET_FORM = 'CHANGE_RESET_FORM'
export const CHANGE_UPDATE_FORM = 'CHANGE_UPDATE_FORM'
export const CHANGE_TOGGLE_SHOW = 'CHANGE_TOGGLE_SHOW'
export const CHANGE_TOGGLE_SUBMITTING = 'CHANGE_TOGGLE_SUBMITTING'
export const CHANGE_ERRORS = 'CHANGE_ERRORS'

export const resetForm = () => ({ type: CHANGE_RESET_FORM })
export const updateForm = (data) => ({
  type: CHANGE_UPDATE_FORM,
  payload: data,
})
export const toggleShow = () => ({ type: CHANGE_TOGGLE_SHOW })
export const toggleSubmitting = () => ({ type: CHANGE_TOGGLE_SUBMITTING })
export const showErrors = (errors) => ({ type: CHANGE_ERRORS, payload: errors })

export const showChangePassword = () => {
  return (dispatch) => {
    dispatch(resetForm())
    dispatch(loadingModal())
    const config = {
      title: 'Change your password',
      subtitle: 'Enter your existing password and a new one',
      type: 'changePassword',
    }
    dispatch(openModal(config))
  }
}

export const updateInput = (field, value) => {
  return (dispatch, getState) => {
    const { data } = getState().changePassword
    dispatch(updateForm({ ...data, [field]: value }))
  }
}

export const submitForm = () => {
  return (dispatch, getState) => {
    const { changePassword, token, brand } = getState()
    const { data } = changePassword
    dispatch(toggleSubmitting())
    fetch(`${url}/users/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'brand-id': `${brand.brand_id}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        // return res.status === 204 ? null : res.json()
        if (res.status === 204) return null
        if (res.status === 401) return requestErrors.notAuthorized
        return res.json()
      })
      .then((err) => {
        if (err) {
          dispatch(handlePageError(err, true))
          const errors = handleError(err)
          dispatch(showErrors(errors))
        } else {
          dispatch(resetForm())
          dispatch(closeModal())
          dispatch(flashMessage('Password successfully updated!'))
        }
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(toggleSubmitting()))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case CHANGE_RESET_FORM:
      return { ...initState }
    case CHANGE_UPDATE_FORM:
      return { ...state, data: action.payload, errors: '' }
    case CHANGE_TOGGLE_SUBMITTING:
      return { ...state, submiting: !state.submiting }
    case CHANGE_TOGGLE_SHOW:
      return { ...state, show: !state.show }
    case CHANGE_ERRORS:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
