import { clearPageError } from './page'

const MESSAGE_SHOW = 'MESSAGE_SHOW'
const MESSAGE_HIDE = 'MESSAGE_HIDE'

export const showMessage = msg => ({ type: MESSAGE_SHOW, payload: msg })
export const hideMessage = () => ({ type: MESSAGE_HIDE })
export const flashMessage = msg => {
  return dispatch => {
    dispatch(clearPageError())
    dispatch(showMessage(msg))
    setTimeout(() => dispatch(hideMessage()), 3000)
  }
}

export default function(state = '', action) {
  switch (action.type) {
    case MESSAGE_SHOW:
      return action.payload
    case MESSAGE_HIDE:
      return ''
    default:
      return state
  }
}
