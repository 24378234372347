import { request } from '../lib/services'
import { handleError } from '../lib/errors'
import { flashMessage } from './messages'
import { convertFromAPI } from '../lib/helpers'
import { handlePageError } from './page'

const initState = {
  data: null,
  errors: {},
  parent: null,
  isOverriding: false
}

export const SETTING_CLEAR = 'SETTING_CLEAR'
export const SETTING_LOAD = 'SETTING_LOAD'
export const SETTING_PARENT = 'SETTING_PARENT'
export const SETTING_OVERRIDE = 'SETTING_OVERRIDE'
export const SETTING_ERRORS = 'SETTING_ERRORS'

export const clearSetting = () => ({ type: SETTING_CLEAR })
export const loadSetting = data => ({ type: SETTING_LOAD, payload: data })
export const setParent = url => ({ type: SETTING_PARENT, payload: url })
export const setOverriding = bool => ({ type: SETTING_OVERRIDE, payload: bool })
export const showSettingErrors = errors => ({
  type: SETTING_ERRORS,
  payload: errors
})

const maybeMakeMapping = (resp, setting, options) => {
  if (!setting.listId) return resp
  const data = resp.map(i => i[setting.listId])
  const entity = options[setting.listEntity]
  return setting.fields.reduce((obj, field) => {
    const entityValues = entity[field.field]
      ? entity[field.field].map(i => i[setting.listId])
      : []
    obj[field.field] = data.filter(i => entityValues.includes(i))
    return obj
  }, {})
}

const maybeMakeList = (values, setting) => {
  if (!setting.listId) return values
  return [...new Set(Object.values(values).flat())].map(i => ({
    [setting.listId]: parseInt(i)
  }))
}

export const fetchSetting = () => {
  return (dispatch, getState) => {
    const { token, brand, page, options } = getState()
    const { id, setting } = page
    dispatch(clearSetting())
    const endpoint = setting.endpoint.replace(':id', id)
    // console.log(endpoint)
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // console.log(resp)
        // console.log(JSON.stringify(resp, null, 2))
        if (!resp) return dispatch(loadSetting({}))
        if (resp.from) {
          if (resp.from[setting.id]) {
            setting.parent && dispatch(setOverriding(true))
            const data = convertFromAPI(page.route, 'setting', resp.data)
            dispatch(loadSetting(data))
          } else {
            setting.parent && dispatch(setParent(setting.parent))
            dispatch(loadSetting({}))
          }
        } else {
          let data = maybeMakeMapping(resp, setting, options)
          data = setting.convert
            ? convertFromAPI(page.route, 'setting', resp)
            : data
          dispatch(loadSetting(data))
        }
      })
      .catch(err => {
        return id || err.status === 401
          ? dispatch(handlePageError(err))
          : dispatch(loadSetting({}))
      })
  }
}

export const upsertSetting = values => {
  return (dispatch, getState) => {
    const { token, brand, page, options } = getState()
    const { id, setting } = page
    const endpoint = setting.endpoint.replace(':id', id)
    const data = maybeMakeList(values, setting)
    // console.log(endpoint)
    // console.log(JSON.stringify(data, null, 2))
    return request(token, brand, endpoint, 'PUT', data)
      .then(resp => {
        // console.log(resp)
        if (resp.from) {
          const data = convertFromAPI(page.route, 'setting', resp.data)
          setting.parent && dispatch(setOverriding(true))
          dispatch(setParent(null))
          dispatch(loadSetting(data))
        } else {
          let data = maybeMakeMapping(resp, setting, options)
          data = setting.convert
            ? convertFromAPI(page.route, 'setting', resp)
            : data
          dispatch(loadSetting(data))
        }
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showSettingErrors(errors))
      })
  }
}

export const deleteSetting = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, setting } = page
    const endpoint = setting.endpoint.replace(':id', id)
    request(token, brand, endpoint, 'DELETE')
      .then(() => {
        dispatch(fetchSetting())
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case SETTING_CLEAR:
      return { ...initState }
    case SETTING_LOAD:
      return { ...state, data: action.payload, errors: {} }
    case SETTING_PARENT:
      return { ...state, parent: action.payload }
    case SETTING_OVERRIDE:
      return { ...state, isOverriding: action.payload }
    case SETTING_ERRORS:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
