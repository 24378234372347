import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addPromoCode } from '../../reducers/order'
import Search from '../Search'

class PromoCodes extends Component {
  static propTypes = {
    addPromoCode: propTypes.func
  }

  render() {
    return (
      <Search
        endpoint="discounts?is_promo_code=true"
        resultId="name"
        resultName="name"
        placeholder="enter part of promo code"
        resultAction={this.props.addPromoCode}
      />
    )
  }
}

PromoCodes.displayName = 'PromoCodes'

export default connect(
  null,
  { addPromoCode }
)(PromoCodes)
