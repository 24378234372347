import propTypes from 'prop-types'
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Plus, Minus } from 'react-feather'
import { QuantityInput } from '../OrderInputs'
import { submitRefund, clearRefund } from '../../reducers/receipt'
import { closeModal } from '../../reducers/modal'
import { CheckboxSimple } from '../FormInputs'
import { formatDollars } from '../../lib/helpers'
import ClipLoader from 'react-spinners/ClipLoader'

const RefundQuantity = ({
  name,
  id,
  quantity,
  refunded,
  increment,
  decrement
}) => {
  return (
    <div className="quantity">
      <button className="btn" onClick={decrement} disabled={refunded === 0}>
        <Minus size={null} />
      </button>
      <QuantityInput name={name} id={id} value={refunded} readOnly={true} />
      <button
        className="btn"
        onClick={increment}
        disabled={refunded === quantity}
      >
        <Plus size={null} />
      </button>
    </div>
  )
}

RefundQuantity.displayName = 'RefundQuantity'
RefundQuantity.propTypes = {
  name: propTypes.string,
  id: propTypes.string,
  quantity: propTypes.number,
  refunded: propTypes.number,
  increment: propTypes.func,
  decrement: propTypes.func
}

const RefundItem = ({
  item,
  refunded,
  increment,
  decrement,
  isOption = false
}) => {
  const unitPrice =
    item.quantity === 0 ? 0.0 : parseFloat(item.price_total) / item.quantity
  const refundedAmount = -unitPrice * refunded
  const handleIncrement = (evt, lineNo) => {
    evt.preventDefault()
    increment(lineNo)
    evt.target.blur()
  }
  const handleDecrement = (evt, lineNo) => {
    evt.preventDefault()
    decrement(lineNo)
    evt.target.blur()
  }
  return (
    <li>
      <span className="modal__option">
        {isOption ? (
          <span className="modal__option__name">-- {item.name}</span>
        ) : (
          <span className="modal__option__name">{item.name}</span>
        )}
        <span className="modal__option__price">${unitPrice.toFixed(2)}</span>
        <span className="modal__option__price">{item.quantity}</span>
        <span className="modal__option__price">${item.price_total}</span>
        <span className="modal__option__quantity">
          <RefundQuantity
            name={`${item.name} refund quantity`}
            id={`refunded-${item.line_no}`}
            quantity={item.quantity}
            refunded={refunded}
            increment={evt => handleIncrement(evt, item.line_no)}
            decrement={evt => handleDecrement(evt, item.line_no)}
          />
        </span>
        <span className="modal__option__price">
          {formatDollars(refundedAmount)}
        </span>
      </span>
    </li>
  )
}

RefundItem.displayName = 'RefundItem'
RefundItem.propTypes = {
  item: propTypes.object,
  refunded: propTypes.number,
  increment: propTypes.func,
  decrement: propTypes.func,
  isOption: propTypes.bool
}

const RefundGiftCard = ({ giftCard, refunded, currentRefund, toggle }) => {
  const { gift_card_id, card_number, amount } = giftCard
  const checked = refunded.gift_cards.includes(gift_card_id)
  const disabled = currentRefund.gift_cards.includes(gift_card_id)
  const refundedAmount = checked ? -parseFloat(amount) : 0.0
  return (
    <li>
      <span className="modal__option">
        <span className="modal__option__name__wide">{card_number}</span>
        <span className="modal__option__price">${amount}</span>
        <span className="modal__option__quantity">
          <CheckboxSimple
            id={`giftCard-${gift_card_id}`}
            checked={checked}
            disabled={disabled}
            handler={() => toggle(gift_card_id)}
          />
        </span>
        <span className="modal__option__price">
          {formatDollars(refundedAmount)}
        </span>
      </span>
    </li>
  )
}

RefundGiftCard.displayName = 'RefundGiftCard'
RefundGiftCard.propTypes = {
  giftCard: propTypes.object,
  refunded: propTypes.object,
  currentRefund: propTypes.object,
  toggle: propTypes.func
}

const RefundSurcharge = ({ surcharge, refunded, currentRefund, toggle }) => {
  const { surcharge_id, name, amount } = surcharge
  const checked = refunded.surcharges.includes(surcharge_id)
  const disabled = currentRefund.surcharges.includes(surcharge_id)
  const refundedAmount = checked ? -parseFloat(amount) : 0.0
  return (
    <li>
      <span className="modal__option">
        <span className="modal__option__name__wide">{name}</span>
        <span className="modal__option__price">${amount}</span>
        <span className="modal__option__quantity">
          <CheckboxSimple
            id={`surcharge-${surcharge_id}`}
            checked={checked}
            disabled={disabled}
            handler={() => toggle(surcharge_id)}
          />
        </span>
        <span className="modal__option__price">
          {formatDollars(refundedAmount)}
        </span>
      </span>
    </li>
  )
}

RefundSurcharge.displayName = 'RefundSurcharge'
RefundSurcharge.propTypes = {
  surcharge: propTypes.object,
  refunded: propTypes.object,
  currentRefund: propTypes.object,
  toggle: propTypes.func
}

const RefundTip = ({ tip, amount, handler }) => {
  const refundedAmount = isNaN(-parseFloat(amount)) ? 0 : -parseFloat(amount)
  return (
    <li>
      <span className="modal__option">
        <span className="modal__option__name__wide">Tip</span>
        <span className="modal__option__price">${tip}</span>
        <span className="modal__option__quantity quantity">
          <label htmlFor="tip" className="label refund-input">
            <input
              id="tip"
              type="number"
              value={amount}
              placeholder="0.00"
              onChange={handler}
              disabled={tip === '0.00'}
            />
          </label>
        </span>
        <span className="modal__option__price">
          {formatDollars(refundedAmount)}
        </span>
      </span>
    </li>
  )
}

RefundTip.displayName = 'RefundTip'
RefundTip.propTypes = {
  tip: propTypes.string,
  amount: propTypes.string,
  handler: propTypes.func
}

const makeTenderName = tender => {
  const tenderType = tender.tender_type.toUpperCase()
  switch (tenderType) {
    case 'CREDIT':
      return tender.card_name
    case 'HOUSE ACCOUNT':
      return `${tender.house_account_name} House Account`
    case 'GIFT CARD':
      return `Gift Card ${tender.gift_card_number}`
    default:
      return tender.tender_type
  }
}

const RefundTender = ({ tender, amount, handler, disabled }) => {
  const name = makeTenderName(tender)
  const refundedAmount = isNaN(-parseFloat(amount)) ? 0 : -parseFloat(amount)
  const tenderAmount = parseFloat(tender.amount)
  const remaining = tenderAmount + refundedAmount
  return (
    <li>
      <span className="modal__option">
        <span className="modal__option__name__wide">{name}</span>
        <span className="modal__option__price">${tender.amount}</span>
        <span className="modal__option__quantity quantity">
          <label htmlFor="tip" className="label refund-input">
            <input
              id="tip"
              type="number"
              value={amount}
              placeholder="0.00"
              onChange={handler}
              disabled={disabled || tenderAmount <= 0}
            />
          </label>
        </span>
        <span className="modal__option__price">{formatDollars(remaining)}</span>
      </span>
    </li>
  )
}

RefundTender.displayName = 'RefundTender'
RefundTender.propTypes = {
  tender: propTypes.object,
  amount: propTypes.string,
  handler: propTypes.func,
  disabled: propTypes.bool
}

const refundAllItems = items => {
  return Object.entries(items).reduce((obj, i) => {
    const [lineNo, qty] = i
    return { ...obj, [lineNo]: { ...qty, refunded: qty.quantity } }
  }, {})
}

const RefundItems = ({
  order,
  nonVoided,
  refund,
  currentRefund,
  tenders,
  submitRefund,
  clearRefund,
  closeModal
}) => {
  const [refunded, setRefunded] = useState(currentRefund)
  const [refunds, setRefunds] = useState(tenders)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => setRefunds(tenders), [tenders])

  const increment = lineNo => {
    setRefunded(current => {
      const item = current.items[lineNo]
      const newCount = Math.min(item.quantity, item.refunded + 1)
      const newItem = { ...item, refunded: newCount }
      const newItems = { ...current.items, [lineNo]: newItem }
      return { ...current, items: newItems }
    })
  }

  const decrement = lineNo => {
    setRefunded(current => {
      const item = current.items[lineNo]
      const newCount = Math.max(0, item.refunded - 1)
      const newItem = { ...item, refunded: newCount }
      const newItems = { ...current.items, [lineNo]: newItem }
      return { ...current, items: newItems }
    })
  }

  const refundAll = evt => {
    evt.preventDefault()
    setRefunded(current => {
      const newItems = refundAllItems(current.items)
      return { ...current, items: newItems }
    })
    evt.target.blur()
  }

  const resetItems = evt => {
    evt.preventDefault()
    setRefunded(current => ({ ...current, items: currentRefund.items }))
    clearRefund()
    evt.target.blur()
  }

  const reset = evt => {
    evt.preventDefault()
    setRefunded(currentRefund)
    clearRefund()
    evt.target.blur()
  }

  const removeRefund = evt => {
    evt.preventDefault()
    clearRefund()
    evt.target.blur()
  }

  const cancel = evt => {
    evt.preventDefault()
    setRefunded(currentRefund)
    clearRefund()
    closeModal()
    evt.target.blur()
  }

  const toggleGiftCard = giftCardId => {
    setRefunded(current => {
      const giftCards = current.gift_cards.includes(giftCardId)
        ? current.gift_cards.filter(i => i !== parseInt(giftCardId))
        : [...current.gift_cards, giftCardId]
      return { ...current, gift_cards: giftCards }
    })
  }

  const toggleSurcharge = surchargeId => {
    setRefunded(current => {
      const surcharges = current.surcharges.includes(surchargeId)
        ? current.surcharges.filter(i => i !== parseInt(surchargeId))
        : [...current.surcharges, surchargeId]
      return { ...current, surcharges: surcharges }
    })
  }

  const handleTip = evt => {
    const { value } = evt.target
    const amount = isNaN(parseFloat(value)) ? '' : value
    setRefunded(current => ({ ...current, tip: amount }))
  }

  // const handleTender = (evt, tender_id) => {
  //   const { value } = evt.target
  //   setRefunded(current => {
  //     const tender = current.tenders.find(i => i.tender_id === tender_id)
  //     const index = current.tenders.map(i => i.tender_id).indexOf(tender_id)
  //     const updated = {
  //       ...tender,
  //       amount: isNaN(parseFloat(value)) ? '' : value
  //     }
  //     const updatedTenders = [...current.tenders]
  //     updatedTenders[index] = updated
  //     return { ...current, tenders: updatedTenders }
  //   })
  // }

  const handleTender = (evt, tender_id) => {
    const { value } = evt.target
    setRefunds(current => {
      const amount = isNaN(parseFloat(value)) ? '' : value
      return { ...current, [tender_id]: amount }
    })
  }

  const refundInFull = evt => {
    evt.preventDefault()
    setRefunded(current => {
      const items = refundAllItems(current.items)
      const gift_cards = order.gift_cards.map(i => i.gift_card_id)
      const surcharges = order.surcharges.map(i => i.surcharge_id)
      const tip = order.tip
      return { items, gift_cards, surcharges, tip }
    })
    evt.target.blur()
  }

  const handleSubmit = (evt, submitType) => {
    evt.preventDefault()
    if (submitType === 'apply') setSubmitting(true)
    const items = Object.entries(refunded.items)
      .reduce((arr, i) => {
        const [lineNo, qty] = i
        return [
          ...arr,
          { line_no: parseInt(lineNo), quantity: qty.refunded, void: false }
        ]
      }, [])
      .sort((a, b) => a.line_no - b.line_no)
    const tip = parseFloat(refunded.tip || 0).toFixed(2)
    const gift_cards = refunded.gift_cards.map(i => ({ gift_card_id: i }))
    const surcharges = refunded.surcharges.map(i => ({ surcharge_id: i }))
    const refund = { items, gift_cards, surcharges, tip }
    if (submitType === 'apply' && refunds) {
      refund.tenders = Object.entries(refunds).reduce((arr, i) => {
        return [...arr, { tender_id: parseInt(i[0]), amount: i[1] }]
      }, [])
    }
    submitRefund(submitType, refund)
    evt.target.blur()
  }

  const subtotal = order.items.reduce((total, i) => {
    const optionsTotal = i.options.reduce((total, o) => {
      const unitPrice = parseFloat(o.price_total) / o.quantity_total
      return (total += refunded.items[o.line_no].quantity * unitPrice)
    }, 0.0)
    const unitPrice =
      i.quantity === 0 ? 0.0 : parseFloat(i.price_total) / i.quantity
    const itemTotal = refunded.items[i.line_no].quantity * unitPrice
    const grandTotal = optionsTotal + itemTotal
    return (total += grandTotal)
  }, 0.0)

  const itemsTotal = order.items.reduce((total, i) => {
    const optionsTotal = i.options.reduce((total, o) => {
      const unitPrice = parseFloat(o.price_total) / o.quantity_total
      return (total += refunded.items[o.line_no].refunded * -unitPrice)
    }, 0.0)
    const unitPrice =
      i.quantity === 0 ? 0.0 : parseFloat(i.price_total) / i.quantity
    const itemTotal = refunded.items[i.line_no].refunded * -unitPrice
    const grandTotal = optionsTotal + itemTotal
    return (total += grandTotal)
  }, 0.0)

  const giftCardsTotal = order.gift_cards.reduce((total, i) => {
    return (total += refunded.gift_cards.includes(i.gift_card_id)
      ? -parseFloat(i.amount)
      : 0.0)
  }, 0.0)

  const surchargesTotal = order.surcharges.reduce((total, i) => {
    return (total += refunded.surcharges.includes(i.surcharge_id)
      ? -parseFloat(i.amount)
      : 0.0)
  }, 0.0)

  const tipTotal = isNaN(parseFloat(refunded.tip))
    ? 0
    : -parseFloat(refunded.tip)

  const totalRefund = itemsTotal + giftCardsTotal + surchargesTotal + tipTotal
  const submitDisabled = parseInt(totalRefund * 100) === 0

  const tendersDisabled = nonVoided.length === 1
  const refundTotal = refund ? parseFloat(refund.total) : 0.0
  const amountRemaining = parseFloat(order.total) + refundTotal
  const tendersTotal = nonVoided.reduce(
    (t, i) => (t += parseFloat(i.amount)),
    0.0
  )
  const refundsTotal = refunds
    ? Object.values(refunds).reduce((t, i) => (t -= parseFloat(i)), 0.0)
    : 0.0
  const tendersRemaining = tendersTotal + refundsTotal
  const refundRemaining = -refundTotal + refundsTotal
  const refundRemainingRounded = Math.round(refundRemaining * 100) / 100
  const applyDisabled = refundRemainingRounded !== 0

  return (
    <div className="modal__refund">
      <form className="modal__groups">
        {!refund ? (
          <>
            <div className="modal__group">
              <ul>
                <li className="modal__refund__header">
                  <span className="modal__option">
                    <span className="modal__option__name">&nbsp;</span>
                    <span className="modal__option__price">Price</span>
                    <span className="modal__option__price">Qty</span>
                    <span className="modal__option__price">Total</span>
                    <span className="modal__option__quantity">Refunded</span>
                    <span className="modal__option__price">Amount</span>
                  </span>
                </li>
                {order.items.map(item => (
                  <React.Fragment key={item.line_no}>
                    <RefundItem
                      key={item.line_no}
                      item={item}
                      refunded={refunded.items[item.line_no].refunded}
                      increment={increment}
                      decrement={decrement}
                    />
                    {item.options.map(option => (
                      <RefundItem
                        key={option.line_no}
                        item={option}
                        refunded={refunded.items[option.line_no].refunded}
                        increment={increment}
                        decrement={decrement}
                        isOption={true}
                      />
                    ))}
                  </React.Fragment>
                ))}
                <li className="modal__refund__footer">
                  <span className="modal__option">
                    <span className="modal__option__name">Items Total</span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__price">
                      {formatDollars(subtotal)}
                    </span>
                    <span className="modal__option__quantity">
                      <button className="btn-link" onClick={resetItems}>
                        reset
                      </button>{' '}
                      |{' '}
                      <button className="btn-link" onClick={refundAll}>
                        all
                      </button>
                    </span>
                    <span className="modal__option__price">
                      {formatDollars(itemsTotal)}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            <div className="modal__group">
              <ul>
                {order.gift_cards.map(giftCard => (
                  <RefundGiftCard
                    key={`giftCard-${giftCard.gift_card_id}`}
                    giftCard={giftCard}
                    refunded={refunded}
                    currentRefund={currentRefund}
                    toggle={toggleGiftCard}
                  />
                ))}
                {order.surcharges.map(surcharge => (
                  <RefundSurcharge
                    key={`surcharge-${surcharge.surcharge_id}`}
                    surcharge={surcharge}
                    refunded={refunded}
                    currentRefund={currentRefund}
                    toggle={toggleSurcharge}
                  />
                ))}
                <RefundTip
                  tip={order.tip}
                  amount={refunded.tip}
                  handler={handleTip}
                />
                <li className="modal__refund__footer">
                  <span className="modal__option">
                    <span className="modal__option__name__wide">
                      Total Refund Before Tax
                    </span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__quantity">
                      <button className="btn-link" onClick={refundInFull}>
                        refund in full
                      </button>
                    </span>
                    <span className="modal__option__price">
                      {formatDollars(totalRefund)}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="modal__group">
              <ul>
                <li>
                  <span className="modal__option">
                    <span className="modal__option__name__wide">
                      Total Refund with Tax
                    </span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__quantity">&nbsp;</span>
                    <span className="modal__option__price">
                      {formatDollars(refund.total)}
                    </span>
                  </span>
                </li>
                <li>
                  <span className="modal__option">
                    <span className="modal__option__name__wide">
                      Amount Remaining on Check
                    </span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__quantity">&nbsp;</span>
                    <span className="modal__option__price">
                      {formatDollars(amountRemaining)}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            {tendersDisabled && (
              <div className="modal__group">
                <p className="bold alert">
                  Please note that refund amounts cannot be adjusted when{' '}
                  {"there's"} only 1 tender.
                </p>
              </div>
            )}
            <div className="modal__group">
              <ul>
                <li className="modal__refund__header">
                  <span className="modal__option">
                    <span className="modal__option__name__wide">Tender</span>
                    <span className="modal__option__price">Amount</span>
                    <span className="modal__option__quantity">Refunded</span>
                    <span className="modal__option__price">Left</span>
                  </span>
                </li>
                {refunds &&
                  nonVoided.map(tender => (
                    <RefundTender
                      key={tender.tender_id}
                      tender={tender}
                      amount={refunds[tender.tender_id]}
                      handler={evt => handleTender(evt, tender.tender_id)}
                      disabled={tendersDisabled}
                    />
                  ))}
                <li className="modal__refund__footer">
                  <span className="modal__option">
                    <span className="modal__option__name__wide">Totals</span>
                    <span className="modal__option__price">
                      {formatDollars(tendersTotal)}
                    </span>
                    <span className="modal__option__quantity">
                      {formatDollars(refundsTotal)}
                    </span>
                    <span className="modal__option__price">
                      {formatDollars(tendersRemaining)}
                    </span>
                  </span>
                </li>
                <li className="modal__refund__footer">
                  <span className="modal__option">
                    <span className="modal__option__name__wide">
                      Amount Left To Refund
                    </span>
                    <span className="modal__option__price">&nbsp;</span>
                    <span className="modal__option__quantity">&nbsp;</span>
                    <span className="modal__option__price">
                      {formatDollars(refundRemainingRounded)}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="form__submit">
          {!refund ? (
            <>
              <button
                className="btn"
                onClick={evt => handleSubmit(evt, 'calculate')}
                disabled={submitDisabled}
              >
                Calculate Refund
              </button>
              <button className="btn btn--secondary" onClick={reset}>
                Reset
              </button>
            </>
          ) : (
            <>
              <button
                className="btn"
                onClick={evt => handleSubmit(evt, 'apply')}
                disabled={applyDisabled || submitting}
              >
                {submitting && (
                  <span style={{ padding: '0 0.5rem 0 0' }}>
                    <ClipLoader size={12} color={'#ffffff'} />
                  </span>
                )}
                Apply Refund
              </button>
              <button
                className="btn btn--secondary"
                onClick={removeRefund}
                disabled={submitting}
              >
                Go Back
              </button>
            </>
          )}
          <button
            className="btn btn--secondary"
            onClick={cancel}
            disabled={submitting}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

RefundItems.displayName = 'RefundItems'
RefundItems.propTypes = {
  order: propTypes.object,
  nonVoided: propTypes.array,
  refund: propTypes.object,
  submitRefund: propTypes.func,
  clearRefund: propTypes.func,
  closeModal: propTypes.func,
  currentRefund: propTypes.object,
  tenders: propTypes.object
}

class Refund extends Component {
  static propTypes = {
    order: propTypes.object,
    refund: propTypes.object,
    submitRefund: propTypes.func,
    clearRefund: propTypes.func,
    closeModal: propTypes.func
  }

  render() {
    const { order, refund } = this.props
    const items = order.items.reduce((obj, i) => {
      const options = i.options.reduce((obj, o) => {
        return { ...obj, [o.line_no]: { quantity: o.quantity, refunded: 0 } }
      }, {})
      return {
        ...obj,
        ...options,
        [i.line_no]: { quantity: i.quantity, refunded: 0 }
      }
    }, {})
    const gift_cards = []
    const surcharges = []
    const tip = ''
    const currentRefund = { items, gift_cards, surcharges, tip }
    let tenders = null
    const nonVoided = order.tenders.filter(
      i => i.tender_status.toUpperCase() === 'PAID'
    )
    if (refund) {
      if (nonVoided.length === 1) {
        const t = nonVoided[0]
        const a = Math.min(-parseFloat(refund.total), parseFloat(t.amount))
        tenders = { [t.tender_id]: a.toFixed(2) }
      } else {
        tenders = nonVoided.reduce((obj, i) => {
          return { ...obj, [i.tender_id]: '0.00' }
        }, {})
      }
    }
    return (
      <RefundItems
        {...this.props}
        nonVoided={nonVoided}
        currentRefund={currentRefund}
        tenders={tenders}
      />
    )
  }
}

Refund.displayName = 'Refund'

export default connect(
  state => ({
    refund: state.receipt.refund
  }),
  { submitRefund, clearRefund, closeModal }
)(Refund)
