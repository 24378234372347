import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { valTypes } from '../../lib/constants'
import Form from '../Form'
import { PageError } from '../PageError'
import { upsertRelationListItem } from '../../reducers/relation'

const fields = [
  {
    label: 'Included Modifiers',
    field: 'included_options',
    type: 'text',
    tooltip:
      'The number of modifiers that are included for free from this modifier group',
    valType: valTypes.int
  },
  {
    label: 'Minimum Number of Modifiers',
    field: 'min_options',
    type: 'text',
    tooltip:
      'The minimum number of modifiers the customer must select from this modifier group',
    valType: valTypes.int
  },
  {
    label: 'Maximum Number of Modifiers',
    field: 'max_options',
    type: 'text',
    tooltip:
      'The maximum number of modifiers the customer can select from this modifier group',
    valType: valTypes.int
  },
  {
    field: 'modifier_group_id',
    type: 'hidden',
    valType: valTypes.int
  },
  {
    field: 'menu_position',
    type: 'hidden',
    valType: valTypes.int
  }
]

class ModifierGroup extends Component {
  static propTypes = {
    item: propTypes.object,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    upsertRelationListItem: propTypes.func
  }

  render() {
    const { item, errors } = this.props
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={false}
          idField="modifier_group_id"
          fields={fields}
          data={item}
          errors={errors || {}}
          upsertItem={this.props.upsertRelationListItem}
          windowRef={this.props.windowRef}
        />
      </>
    )
  }
}

ModifierGroup.displayName = 'ModifierGroup'

export default connect(
  state => ({
    item: state.relation.item,
    errors: state.modal.errors
  }),
  { upsertRelationListItem }
)(ModifierGroup)
