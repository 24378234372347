import propTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'
import { X } from 'react-feather'

// https://stackoverflow.com/questions/37440408/how-to-detect-esc-key-press-in-react-and-how-to-handle-it/46123962
const ModalClose = ({ close }) => {
  const handleEscape = useCallback(
    evt => {
      if (evt.keyCode === 27) close()
    },
    [close]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)
    return () => document.removeEventListener('keydown', handleEscape, false)
  }, [handleEscape])

  return (
    <button className="modal__close" onClick={close}>
      <X size={20} />
    </button>
  )
}

ModalClose.displayName = 'ModalClose'
ModalClose.propTypes = {
  close: propTypes.func
}

export default ModalClose
