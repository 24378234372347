import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addTenderCredit, tenderCredit } from '../../reducers/order'
import { showModalErrors } from '../../reducers/modal'
import Form from '../Form'
import { PageError } from '../PageError'
import * as constants from '../../lib/constants'
import { valTypes } from '../../lib/constants'
import { tendersTotalAndBalance } from '../../lib/helpersOrder'

const makeFields = () => {
  return [
    {
      label: 'Dollar Amount',
      field: 'amount',
      type: 'text',
      valType: valTypes.decimal
    },
    {
      label: 'Card Number',
      field: 'acct',
      type: 'text'
    },
    {
      label: 'Expiration Month',
      type: 'select',
      field: 'exp_month',
      options: constants.months,
      classes: '-half'
    },
    {
      label: 'Expiration Year',
      type: 'select',
      field: 'exp_year',
      options: constants.years,
      classes: '-half -right'
    },
    {
      label: 'CVV',
      field: 'cvv',
      type: 'text',
      classes: '-half'
    },
    {
      label: 'Zip Code',
      field: 'zip',
      type: 'text',
      classes: '-half -right'
    },
    {
      label: 'Save this card for future use',
      field: 'save',
      type: 'checkbox'
    },
    {
      field: 'tenderType',
      type: 'hidden'
    }
  ]
}

class TenderCredit extends Component {
  static propTypes = {
    // state.order.customer
    cards: propTypes.array,
    tenders: propTypes.array,
    orderTotal: propTypes.string,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    addTenderCredit: propTypes.func,
    tenderCredit: propTypes.func,
    showModalErrors: propTypes.func
  }

  handleSaved = evt => {
    evt.preventDefault()
    this.props.tenderCredit()
    evt.target.blur()
  }

  render() {
    const { cards, tenders, orderTotal, errors } = this.props
    const [, remaining] = tendersTotalAndBalance(tenders, orderTotal)
    const data = { amount: remaining, tenderType: 'CREDIT' }
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={true}
          idField="customer_id"
          fields={makeFields()}
          data={data}
          errors={errors || {}}
          upsertItem={this.props.addTenderCredit}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
        {cards.length ? (
          <div className="modal__footer">
            <button
              type="button"
              className="btn-link"
              onClick={this.handleSaved}
            >
              Or click here to choose a saved card
            </button>
          </div>
        ) : null}
      </>
    )
  }
}

TenderCredit.displayName = 'TenderCredit'

export default connect(
  state => ({
    cards: state.order.cards,
    tenders: state.order.tenders,
    orderTotal: state.order.order.total,
    errors: state.modal.errors
  }),
  { addTenderCredit, tenderCredit, showModalErrors }
)(TenderCredit)
