import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import PageHeader from './PageHeader'
import Message from './Message'
import Modal from './Modal'
import Image from './Image'
import { openVideoModal } from '../reducers/modal'
// import Columns from './Columns'
// import { NavLink } from 'react-router-dom'
// import Youtube from './Youtube'
// import ReceiptPrintButton from './ReceiptPrintButton'

const videoLinks = [
  {
    title: 'Managing Stores and Revenue Centers',
    subtitle: 'How locations have changed in the new admin portal',
    youtubeId: 'iIwx40b9z2I'
  },
  {
    title: 'Placing and Editing Orders',
    subtitle: 'Orders can now be managed entirely from the admin portal',
    youtubeId: '1zatY_Iio20'
  },
  {
    title: 'Managing Orders',
    subtitle: 'How to stay on top of upcoming orders',
    youtubeId: 'yawB45lXs4A'
  }
]

// const allItems = ['CONTENT', 'REWARDS', 'DEALS', 'ORDERS']
// const displayed = ['DEALS', 'REWARDS']

class Help extends Component {
  static propTypes = {
    user: propTypes.object,
    brand: propTypes.object,
    openVideoModal: propTypes.func
  }

  handleVideo = (evt, video) => {
    evt.preventDefault()
    const { title, subtitle, youtubeId } = video
    this.props.openVideoModal(title, subtitle, youtubeId)
    evt.target.blur()
  }

  // setDisplayed = items => {
  //   console.log('setting displayed', items)
  // }

  render() {
    document.title = 'Help & Support | Open Tender Admin Portal'
    const { user, brand } = this.props
    const crumbs = [{ name: 'Help' }]
    const title = 'Help & Support'
    const subtitle = 'Use these resources to get unstuck'
    const image = {
      url: 'https://s3.amazonaws.com/betterboh/u/img/prod/2/1579103741_super-helpful_1667x2500.jpg',
      position: 'center top'
    }
    return (
      <div>
        {!user ? (
          <Redirect to="/login" />
        ) : (
          <div className="app app--content">
            <Modal />
            <div className="app__content">
              <Sidebar brand={brand} user={user} />
              <Header crumbs={crumbs} />
              <div className="main page--profile">
                <div className="content">
                  <div className="content__container">
                    <PageHeader title={title} subtitle={subtitle} />
                    {/* <ReceiptPrintButton orderId={1} /> */}
                    {/* <Columns
                      allItems={allItems}
                      displayed={displayed}
                      setDisplayed={this.setDisplayed}
                    /> */}
                    <div className="content__main text">
                      <p>
                        First and foremost, {"there's"} a wealth of information
                        available at the Open Tender help site, which you can
                        find at{' '}
                        <a
                          href="http://help.opentender.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          help.opentender.io
                        </a>
                        .
                      </p>
                      <p>
                        This should always be your first stop since {"we've"}{' '}
                        addressed many of the most common questions, as well as
                        some of the ones that {"aren't"} so common.
                      </p>
                      <p>
                        If {"you're"} still stuck, you can always email us at{' '}
                        <a
                          href="mailto:help@opentender.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          help@opentender.io
                        </a>
                        , and {"we'll"} get back to you as soon as we can.
                      </p>
                      <h2>Open Tender 101</h2>
                      <p>
                        Below are a handful of videos {"we've"} prepared that
                        explain how to use some of the most widely used parts of
                        the admin portal. We encourage you to watch these before
                        you dive into working with each of these sections.
                      </p>
                      <ul>
                        {videoLinks.map(video => (
                          <li key={video.title}>
                            <button
                              className="btn-link"
                              type="button"
                              onClick={evt => this.handleVideo(evt, video)}
                            >
                              {video.title}
                            </button>
                          </li>
                        ))}
                      </ul>
                      {/* <Youtube
                        title="Managing orders in Open Tender"
                        youtubeId="yawB45lXs4A"
                      /> */}
                      {/* <ul>
                        <li>
                          <NavLink to="/help/stores">
                            Managing Stores and Revenue Centers
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/help/placing-orders">
                            Placing and Editing Orders
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/help/managing-orders">
                            Managing Orders
                          </NavLink>
                        </li>
                      </ul> */}
                      <h2>Having issues with your Open Tender website?</h2>
                      <p>
                        Please check our status page at{' '}
                        <a
                          href="http://status.opentender.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          status.opentender.io
                        </a>
                        . This will show you the real-time status of the Open
                        Tender web service itself (including our API), as well
                        as third party dependencies such as Amazon Web Services
                        and our payment processing partners.
                      </p>
                      <p className="bold">
                        We would strongly suggest you{' '}
                        {'"Subscribe for Updates"'} on our status page, since{' '}
                        {"that's"} the best way to be alerted when systems are
                        experiencing difficulties.
                      </p>
                      <p>
                        In the case of emergencies, {"we're"} also reachable by
                        phone at 646-598-2068. However, {"it's"} best to check
                        our status page first since {"it's"} difficult for us to
                        respond to phone calls if one of our systems is
                        temporarily down and all Open Tender team members are
                        troubleshooting (this should happen very rarely).
                      </p>
                    </div>
                  </div>
                </div>
                <Image image={image} />
              </div>
              <Message />
            </div>
          </div>
        )}
      </div>
    )
  }
}

Help.displayName = 'Help'

export default connect(
  state => ({
    user: state.user,
    brand: state.brand
  }),
  { openVideoModal }
)(Help)
