import propTypes from 'prop-types'
import React, { Component, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { editTax, editOpenOrder } from '../../reducers/orderMgmt'
import { Input } from '../FormInputs'

const TaxForm = ({ taxExemptId, submitTax, field }) => {
  const submitButton = useRef()
  const [value, setValue] = useState(taxExemptId)
  const [error, setError] = useState(null)

  const handleInput = evt => {
    setError(null)
    setValue(evt.target.value)
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    const msg = 'A tax exempt ID is required'
    !value.length ? setError(msg) : submitTax(value)
    submitButton.current.blur()
  }

  return (
    <form id="form" onSubmit={handleSubmit}>
      <Input field={field} value={value} error={error} handler={handleInput} />
      <div className="form__submit">
        <button type="submit" className="btn" ref={submitButton}>
          Remove Tax from Order
        </button>
      </div>
    </form>
  )
}

TaxForm.displayName = 'TaxForm'
TaxForm.propTypes = {
  taxExemptId: propTypes.string,
  field: propTypes.object,
  submitTax: propTypes.func
}

class EditTax extends Component {
  static propTypes = {
    orderId: propTypes.number,
    isTaxExempt: propTypes.bool,
    taxExemptId: propTypes.string,
    editTax: propTypes.func,
    editOpenOrder: propTypes.func
  }

  handleSubmit = taxExemptId => {
    const { orderId } = this.props
    this.props.editTax(orderId, taxExemptId)
  }

  handleOrderEdit = (evt, orderId) => {
    evt.preventDefault()
    this.props.editOpenOrder(orderId)
    evt.target.blur()
  }

  render() {
    const { orderId, isTaxExempt, taxExemptId } = this.props
    const field = { label: '', field: 'tax', type: 'input' }
    return isTaxExempt ? (
      <div>
        <p>
          This order is already tax exempt with tax exempt ID {taxExemptId}. If
          you need to add tax back or change the tax exempt ID,{' '}
          <button
            className="btn-link"
            onClick={evt => this.handleOrderEdit(evt, orderId)}
          >
            please click here to perform a full edit
          </button>
          .
        </p>
      </div>
    ) : (
      <TaxForm {...this.props} field={field} submitTax={this.handleSubmit} />
    )
  }
}

EditTax.displayName = 'EditTax'

export default connect(null, { editTax, editOpenOrder })(EditTax)
