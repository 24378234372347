import { request } from '../lib/services'
import { flashMessage } from './messages'
import { handlePageError } from './page'
import { processErrorMessage } from '../lib/errors'

const initState = {
  config: null,
  content: null,
  theme: null,
  errors: {}
}

export const CONFIG_CLEAR = 'CONFIG_CLEAR'
export const CONFIG_LOAD = 'CONFIG_LOAD'
export const CONFIG_ERRORS = 'CONFIG_ERRORS'

export const clearConfig = () => ({ type: CONFIG_CLEAR })
export const loadConfig = data => ({ type: CONFIG_LOAD, payload: data })
export const showConfigErrors = errors => ({
  type: CONFIG_ERRORS,
  payload: errors
})

export const fetchConfig = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { endpoint, type, key } = page.config
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const data = key ? resp[key] : resp
        dispatch(loadConfig({ [type]: data }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

const unflattenData = data => {
  return Object.entries(data).reduce((obj, [key, value]) => {
    const [k1, k2, k3] = key.split('-')
    if (!k2) return { ...obj, [k1]: value }
    if (obj[k1]) {
      obj[k1][k2] = obj[k1][k2]
        ? { ...obj[k1][k2], [k3]: value }
        : { [k3]: value }
    } else {
      obj[k1] = { [k2]: { [k3]: value } }
    }
    return obj
  }, {})
}

export const updateConfig = flatData => {
  return (dispatch, getState) => {
    const { token, brand, page, config } = getState()
    const { endpoint, type, key, section } = page.config
    const data = unflattenData(flatData)
    const newData = { ...config[type], [section]: data }
    const newConfig = key ? { [key]: newData } : newData
    return request(token, brand, endpoint, 'PUT', newConfig)
      .then(resp => {
        const data = key ? resp[key] : resp
        dispatch(loadConfig({ [type]: data }))
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        if (err.params) {
          const fields = Object.entries(err.params).reduce((obj, error) => {
            try {
              const [field, value] = error
              const fieldName = field.split(`$.${section}.`)[1]
              const name = fieldName.split('.').join('-')
              return { ...obj, [name]: processErrorMessage(value) }
            } catch {
              return obj
            }
          }, {})
          const errors = { form: 'There are one or more errors below', fields }
          dispatch(showConfigErrors(errors))
        } else {
          const errors = { form: err.detail || err.message }
          dispatch(showConfigErrors(errors))
        }
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case CONFIG_CLEAR:
      return { ...initState }
    case CONFIG_LOAD:
      return { ...state, ...action.payload, errors: {} }
    case CONFIG_ERRORS:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
