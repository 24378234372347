import { request } from '../lib/services'
import { handlePageError, clearPageError } from './page'
import { openModal, closeModal, loadingModal, showModalErrors } from './modal'
import { flashMessage } from './messages'
import { handleError } from '../lib/errors'
import { weekdaysUpper } from '../lib/constants'
import { sortItems } from '../lib/helpers'
import { timeToMinutes } from '../lib/helpersDatetime'

const initState = {
  loading: true,
  orderTimes: []
}

export const ORDER_TIMES_CLEAR = 'ORDER_TIMES_CLEAR'
export const ORDER_TIMES_LOAD = 'ORDER_TIMES_LOAD'
export const ORDER_TIMES_LOADING_TOGGLE = 'ORDER_TIMES_LOADING_TOGGLE'

export const clearOrderTimes = () => ({ type: ORDER_TIMES_CLEAR })
export const toggleLoading = () => ({ type: ORDER_TIMES_LOADING_TOGGLE })
export const loadOrderTimes = orderTimes => ({
  type: ORDER_TIMES_LOAD,
  payload: orderTimes
})

export const fetchOrderTimes = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearPageError())
    dispatch(clearOrderTimes())
    const endpoint = `/order-times?revenue_center_id=${page.id}`
    return request(token, brand, endpoint, 'GET')
      .then(resp => {
        let orderTimes = resp.data.map(i => ({
          ...i,
          index: `${weekdaysUpper.indexOf(i.weekday)}-${timeToMinutes(
            i.start_time
          )}`
        }))
        orderTimes = sortItems(orderTimes, {
          sortBy: 'index',
          sortType: 'alpha'
        })
        dispatch(loadOrderTimes(orderTimes))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(toggleLoading()))
  }
}

export const addOrderTime = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Add New Order Window',
      subtitle: 'Please choose fill in all of the fields below',
      classes: 'modal--big',
      type: 'orderTimes'
    }
    dispatch(openModal(config))
  }
}

export const insertOrderTime = values => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearPageError())
    const data = [{ ...values, revenue_center_id: parseInt(page.id) }]
    return request(token, brand, '/order-times', 'POST', data)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchOrderTimes())
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const removeOrderTime = index => {
  return (dispatch, getState) => {
    const { token, brand, page, orderTimes } = getState()
    dispatch(clearPageError())
    const values = orderTimes.orderTimes.filter((h, i) => i === index)[0]
    const data = [{ ...values, revenue_center_id: parseInt(page.id) }]
    return request(token, brand, '/order-times', 'DELETE', data)
      .then(() => {
        dispatch(fetchOrderTimes())
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ORDER_TIMES_CLEAR:
      return { ...initState }
    case ORDER_TIMES_LOAD:
      return { ...state, orderTimes: action.payload }
    case ORDER_TIMES_LOADING_TOGGLE:
      return { ...state, loading: !state.loading }
    default:
      return state
  }
}
