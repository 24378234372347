import propTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
// import shiftsData from '../../data/shifts'
import { formatDollars } from '../../lib/helpers'
import { editDeclaredCash } from '../../reducers/report'
import { ReceiptSection } from '../ReceiptSection'

const DeclaredCash = ({ shift, handleEdit }) => {
  const { declared_id, declared, end_time } = shift
  if (!end_time) return `Declared Cash -- still open`
  return (
    <span>
      <span>Declared Cash -- {end_time} -- </span>
      <button
        className="btn-link"
        onClick={evt => handleEdit(evt, declared_id, declared)}
      >
        edit
      </button>
    </span>
  )
}

const makeShift = (s, handleEdit) => {
  const fmt = formatDollars
  return [
    { label: `${s.name} (${s.employee_id}) / ${s.terminal}`, value: ' ' },
    {
      label: `Starting Cash -- ${s.start_time}`,
      value: `${fmt(s.float)}`
    },
    { label: 'Cash Sales', value: `${fmt(s.sale)}` },
    { label: 'Cash Refunds', value: `${fmt(s.refund)}` },
    { label: 'Paid Ins', value: `${fmt(s.paid_in)}` },
    { label: 'Paid Outs', value: `${fmt(s.paid_out)}` },
    { label: 'Calculated Cash', value: `${fmt(s.calculated)}` },
    {
      label: <DeclaredCash shift={s} handleEdit={handleEdit} />,
      value: !s.end_time ? 'n/a' : `${fmt(s.declared)}`
    },
    {
      label: 'Over / Short',
      value: !s.end_time ? 'n/a' : `${fmt(s.over_short)}`
    },
    {
      label: 'Deposit',
      value: !s.end_time ? 'n/a' : `${fmt(s.deposit)}`,
      classes: '-totals'
    }
  ]
}

export const Cash = ({ shifts }) => {
  const dispatch = useDispatch()

  const handleEdit = (evt, cash_transfer_id, amount) => {
    evt.preventDefault()
    dispatch(editDeclaredCash(cash_transfer_id, amount))
    evt.target.blur()
  }

  return (
    <div className="report__table">
      {shifts.length ? (
        shifts.map(i => (
          <ReceiptSection key={i.start_dt} rows={makeShift(i, handleEdit)} />
        ))
      ) : (
        <p className="alert">
          No cashier shifts on the selected date for this store.
        </p>
      )}
    </div>
  )
}

Cash.displayName = 'Cash'
Cash.propTypes = {
  shifts: propTypes.array
}

export default Cash
