import propTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Menu,
  Image,
  DollarSign,
  Tag,
  AlertTriangle,
  Info,
  Percent,
  ShoppingCart,
  Clock,
  Map,
  List
} from 'react-feather'

const icons = {
  Menu: <Menu size={null} />,
  Image: <Image size={null} />,
  DollarSign: <DollarSign size={null} />,
  Percent: <Percent size={null} />,
  Tag: <Tag size={null} />,
  AlertTriangle: <AlertTriangle size={null} />,
  Info: <Info size={null} />,
  ShoppingCart: <ShoppingCart size={null} />,
  Clock: <Clock size={null} />,
  Map: <Map size={null} />,
  List: <List size={null} />
}

const IconLinks = ({ links }) => {
  return links && links.length ? (
    <span className="icon-links">
      {links.map(link => (
        <Link key={link.url} to={link.url} title={link.name}>
          {icons[link.icon]}
        </Link>
      ))}
    </span>
  ) : null
}

IconLinks.displayName = 'IconLinks'
IconLinks.propTypes = {
  links: propTypes.array
}

export default IconLinks
