import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Youtube from '../Youtube'

class YoutubeModal extends Component {
  static propTypes = {
    title: propTypes.string,
    youtubeId: propTypes.string
  }

  render() {
    const { title, youtubeId } = this.props
    return <Youtube title={title} youtubeId={youtubeId} />
  }
}

YoutubeModal.displayName = 'YoutubeModal'

export default connect(null, {})(YoutubeModal)
