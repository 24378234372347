import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateInput, submitResetToken, resetForm } from '../../reducers/auth'
import { PageError } from '../PageError'
import { FormInput } from '../FormInputs'

const fields = [
  { label: 'Email', field: 'email', type: 'email' },
  { label: 'Password', field: 'password', type: 'password' }
]

class Login extends Component {
  constructor(props) {
    super(props)
    this.submitLogin = React.createRef()
  }

  static propTypes = {
    // state.auth
    data: propTypes.object,
    errors: propTypes.object,
    submitting: propTypes.bool,
    // mapdispatchtoprops
    updateInput: propTypes.func,
    submitResetToken: propTypes.func,
    resetForm: propTypes.func
  }

  componentDidMount() {
    this.props.resetForm()
  }

  handleInputChange = evt => {
    const { id, value } = evt.target
    const field = id === 'password' ? id : 'email'
    this.props.updateInput(field, value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    const data = {}
    fields.map(f => {
      let val = this.props.data[f.field]
      if (typeof val !== 'undefined') {
        data[f.field] = val
      }
    })
    this.props.submitResetToken(data)
    this.submitLogin.current.blur()
    window.scrollTo(0, 0)
  }

  render() {
    const { data, errors, submitting } = this.props
    return (
      <>
        <PageError msg={errors.form} />
        <form id="form-login" onSubmit={this.handleSubmit}>
          {fields.map(field => {
            const value = data[field.field] || ''
            return FormInput(field, value, null, this.handleInputChange)
          })}
          <input
            type="submit"
            value={submitting ? 'Submitting...' : 'Submit'}
            ref={this.submitLogin}
            disabled={submitting}
          />
        </form>
      </>
    )
  }
}

Login.displayName = 'Login'

export default connect(
  state => ({
    ...state.auth
  }),
  { updateInput, submitResetToken, resetForm }
)(Login)
