import { request } from '../lib/services'
import { loadBrands, revokeToken, showFormErrors } from './auth'
import { chooseBrand } from './brand'
import { handlePageError } from './page'
import { handleError } from '../lib/errors'
import { clearItemErrors } from './item'
import { flashMessage } from './messages'
import { openModal, loadingModal } from './modal'

const initState = null

const appUrl = process.env.REACT_APP_APP_URL

const USER_LOAD = 'USER_LOAD'
const USER_UPDATE = 'USER_UPDATE'
const USER_SAVE_FILTERS = 'USER_SAVE_FILTERS'
const USER_CLEAR = 'USER_CLEAR'

export const clearUser = () => ({ type: USER_CLEAR })
export const loadUser = user => ({ type: USER_LOAD, payload: user })
export const updateUser = data => ({ type: USER_UPDATE, payload: data })

export const fetchUser = token => {
  return dispatch => {
    // we don't have a brand ID yet so use brand 2 for this
    return request(token, { brand_id: 2 }, '/me')
      .then(data => {
        dispatch(loadUser(data.user))
        if (data.brands.length === 1) {
          const brand = data.brands[0]
          dispatch(chooseBrand(brand))
          const { role, permissions, sections, locations } = brand
          dispatch(updateUser({ role, permissions, sections, locations }))
        } else {
          dispatch(loadBrands(data.brands))
        }
      })
      .catch(err => {
        dispatch(showFormErrors({ form: err.detail || err.message }))
      })
  }
}

export const logoutUser = () => {
  return dispatch => {
    dispatch(revokeToken()).then(() => dispatch({ type: 'CLEAR_STATE' }))
  }
}

export const saveFilters = savedFilters => ({
  type: USER_SAVE_FILTERS,
  payload: savedFilters
})

export const updateSavedFilters = (filters, filter) => {
  return (dispatch, getState) => {
    const { savedFilters = {} } = getState().user
    const updated = filters.find(i => i.field === filter.field)
    if (!updated.savedFilter) return
    let copy = { ...savedFilters }
    if (updated.reset) copy = updated.reset.forEach(i => delete copy[i])
    const newSaved = { ...copy, [updated.savedFilter]: filter.value }
    dispatch(saveFilters(newSaved))
  }
}

export const clearSavedFilters = filters => {
  return (dispatch, getState) => {
    const copy = { ...getState().user.savedFilters }
    const saved = filters.map(i => i.savedFilters)
    const newSaved = saved.forEach(i => delete copy[i])
    dispatch(saveFilters(newSaved))
  }
}

export const sendActivationEmail = () => {
  return (dispatch, getState) => {
    dispatch(clearItemErrors())
    const { token, brand, page } = getState()
    const endpoint = `/users/${page.id}/send-activation-email`
    const data = { link_url: `${appUrl}/reset` }
    request(token, brand, endpoint, 'POST', data)
      .then(() => {
        dispatch(flashMessage('Activation email sent!'))
      })
      .catch(err => {
        const { form, fields } = handleError(err)
        err.detail = fields.link_url || form
        dispatch(handlePageError(err))
      })
  }
}

export const resetSession = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Your session has expired',
      subtitle:
        'Please reenter your email and password to pick up where you left off.',
      type: 'extendSession',
      hideClose: true
    }
    dispatch(openModal(config))
  }
}

export default function (state = initState, action) {
  switch (action.type) {
    case USER_CLEAR:
      return initState
    case USER_LOAD:
      return action.payload
    case USER_UPDATE:
      return { ...state, ...action.payload }
    case USER_SAVE_FILTERS:
      return { ...state, savedFilters: action.payload }
    default:
      return state
  }
}
