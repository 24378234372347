import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from './Table'
import { formatDollars } from '../../lib/helpers'
import {
  tenderTypeOptions,
  cardTypeOptions,
  defaultNetSalesDefinition
} from '../../lib/constants'
import {
  makeStoreLookup,
  makeRevenueCenterLookup,
  makeTaxesLookup,
  makeDeptLookup
} from '../../lib/helpers/reporting'
import { isEmpty } from '../../lib/utils'

const salesFields = [
  {
    name: 'Sales',
    field: 'name',
    format: null
  },
  {
    name: 'Checks',
    field: 'checks',
    format: 'int'
  },
  {
    name: 'Average Check',
    field: 'average',
    format: 'dollars'
  },
  {
    name: 'Subtotal',
    field: 'subtotal',
    format: 'dollars'
  },
  {
    name: 'Discount',
    field: 'discount',
    format: 'dollars'
  },
  {
    name: 'Surcharge',
    field: 'surcharge',
    format: 'dollars'
  },
  {
    name: 'Gift Cards',
    field: 'gift_card',
    format: 'dollars'
  },
  {
    name: 'Taxes',
    field: 'tax',
    format: 'dollars'
  },
  {
    name: 'Tips',
    field: 'tip',
    format: 'dollars'
  },
  {
    name: 'Shipping',
    field: 'shipping',
    format: 'dollars'
  },
  {
    name: 'Total',
    field: 'total',
    format: 'dollars'
  }
]

const netSalesField = {
  name: 'Net Sales',
  field: 'net_sales',
  format: 'dollars'
}

const makeSalesFields = (salesFields, netSalesField, netSalesDef) => {
  const indices = netSalesDef
    .map(i => i.toLowerCase())
    .reduce((arr, i) => [...arr, salesFields.findIndex(x => x.field === i)], [])
  const nextIndex = Math.max(...indices) + 1
  const fields = [
    ...salesFields.slice(0, nextIndex),
    netSalesField,
    ...salesFields.slice(nextIndex)
  ]
  return fields
}

const makeTotal = (key, rcSales) => {
  switch (key) {
    case 'name':
      return 'Store'
    case 'revenue_center_id':
      return null
    case 'checks':
      return rcSales.reduce((t, i) => (t += parseInt(i[key])), 0)
    default:
      return rcSales.reduce((t, i) => (t += parseFloat(i[key])), 0.0).toFixed(2)
  }
}

const formatValue = (format, value) => {
  switch (format) {
    case 'dollars':
      return formatDollars(value, ' ')
    case 'dollarsNo':
      return formatDollars(value, ' ').replace('$', '')
    case 'int':
      return parseInt(value).toFixed(0) + ' '
    default:
      return value ? value.toString() + ' ' : ''
  }
}

const makeArray = length => {
  let arr = []
  for (let i = 0; i < length; i++) {
    arr.push(' ')
  }
  return arr
}

const calcNetSales = (sales, netSalesDef) => {
  return netSalesDef
    .filter(i => i !== 'SUBTOTAL')
    .reduce(
      (t, i) => t + parseFloat(sales[i.toLowerCase()]),
      parseFloat(sales.subtotal)
    )
    .toFixed(2)
}

const makeSalesData = (values, revenueCenters, netSalesDef, spaces = 0) => {
  if (!values || !revenueCenters || isEmpty(revenueCenters)) return []
  const withNames = values
    .filter(i => revenueCenters[i.revenue_center_id])
    .map(i => ({
      ...i,
      name: revenueCenters[i.revenue_center_id].type,
      net_sales: calcNetSales(i, netSalesDef)
    }))
  const totalsKeys = Object.keys(withNames[0]) // .filter(i => i !== 'net_sales')
  const totals = totalsKeys.reduce((obj, key) => {
    const value = makeTotal(key, withNames)
    return { ...obj, [key]: value }
  }, {})
  if (totals.average) {
    totals.average = (parseFloat(totals.subtotal) / totals.checks).toFixed(2)
  }
  const allValues = [...withNames, totals]
  const fields = makeSalesFields(salesFields, netSalesField, netSalesDef)
  const data = fields.map(i => {
    let values = allValues.map(a => formatValue(i.format, a[i.field]))
    if (spaces) {
      const arr = makeArray(spaces)
      values = values.reduce((a, i) => [...a, ...arr, i], [])
    }
    return [i.name, ...values]
  })
  return data
}

const tenderFields = tenderTypeOptions.map(i => ({
  name: i.name,
  field: i.value
}))

const cardFields = cardTypeOptions.map(i => ({
  name: i.name,
  field: i.value
}))

const makeGroupedData = (values, field, fields, revenueCenters, headers) => {
  const grouped = values.reduce((obj, i) => {
    obj[i[field]] = { ...obj[i[field]], [i.revenue_center_id]: i }
    return obj
  }, {})
  const filtered = fields.filter(i => Object.keys(grouped).includes(i.field))
  const data = filtered.map(i => {
    const values = revenueCenters.reduce((arr, r) => {
      const vals = grouped[i.field][r.id]
      const amounts = vals
        ? [
            formatValue('int', vals.checks),
            formatValue('dollars', vals.average),
            formatValue('dollars', vals.amount)
          ]
        : ['0 ', '$0.00 ', '$0.00 ']

      return [...arr, ...amounts]
    }, [])
    const sumFields = ['checks', 'average', 'amount']
    const totals = sumFields.map(field => {
      const total = Object.values(grouped[i.field]).reduce(
        (t, a) => (t += parseFloat(a[field])),
        0.0
      )
      return total
      // const format = field === 'checks' ? 'int' : 'dollars'
      // return formatValue(format, total)
    })
    const adjusted = [
      formatValue('int', totals[0]),
      formatValue('dollars', totals[2] / totals[0]),
      formatValue('dollars', totals[2])
    ]
    return [i.name, ...values, ...adjusted]
  })
  const totals = data.reduce(
    (r, a) =>
      a.map((b, i) => {
        return i ? (r[i] || 0.0) + dollarsToFloat(b) : 0
      }),
    []
  )
  const adjusted = totals.map((n, index) => {
    return index === 0
      ? 'Total'
      : (index + 2) % 3 === 0
      ? formatValue('int', n)
      : (index + 1) % 3 === 0
      ? formatValue('dollars', totals[index + 1] / totals[index - 1])
      : formatValue('dollars', n)
  })
  return [headers, ...data, adjusted]
}

const makeTaxesData = (values, field, fields, revenueCenters, headers) => {
  const grouped = values.reduce((obj, i) => {
    obj[i[field]] = { ...obj[i[field]], [i.revenue_center_id]: i }
    return obj
  }, {})
  const filtered = fields.filter(i => Object.keys(grouped).includes(i.field))
  const data = filtered.map(i => {
    const values = revenueCenters.reduce((arr, r) => {
      const vals = grouped[i.field][r.id]
      const amounts = vals
        ? [' ', ' ', formatValue('dollars', vals.amount)]
        : [' ', ' ', '$0.00 ']

      return [...arr, ...amounts]
    }, [])
    const sumFields = ['amount']
    const totals = sumFields.map(field => {
      const total = Object.values(grouped[i.field]).reduce(
        (t, a) => (t += parseFloat(a[field])),
        0.0
      )
      return total
      // const format = field === 'checks' ? 'int' : 'dollars'
      // return formatValue(format, total)
    })
    const adjusted = [' ', ' ', formatValue('dollars', totals[0])]
    return [i.name, ...values, ...adjusted]
  })
  const totals = data.reduce(
    (r, a) =>
      a.map((b, i) => {
        return i !== ' '
          ? (r[i] || 0.0) + parseFloat(b.replace('$', '').replace(',', ''))
          : ' '
      }),
    []
  )
  const adjusted = totals.map((n, index) => {
    return index === 0
      ? 'Total'
      : index % 3 === 0
      ? formatValue('dollars', n)
      : ' '
  })
  return [headers, ...data, adjusted]
}

const makeLaborData = (labor, departments) => {
  if (!labor || !labor.length) return []
  const deptLookup = makeDeptLookup(departments)
  const rows = labor.map(i => {
    const regHours = parseFloat(i.regular_hours)
    const regDollars = parseFloat(i.regular_dollars)
    const otHours = parseFloat(i.overtime_hours)
    const otDollars = parseFloat(i.overtime_dollars)
    const totalHours = regHours + otHours
    const totalDollars = regDollars + otDollars
    const name = deptLookup[i.department_id]
    return {
      name,
      regHours,
      regDollars,
      otHours,
      otDollars,
      totalHours,
      totalDollars
    }
  })
  const totals = Object.keys(rows[0]).reduce((obj, key) => {
    if (key === 'name') return { ...obj, name: 'Totals' }
    return { ...obj, [key]: rows.reduce((t, i) => t + i[key], 0.0) }
  }, {})
  const withTotals = rows.concat([totals])
  const headers = [
    [' ', 'Reg ', 'Reg ', 'OT ', 'OT ', 'Total ', 'Total '],
    ['Labor', 'Hours ', 'Dollars ', 'Hours ', 'Dollars ', 'Hours ', 'Dollars ']
  ]
  const values = withTotals.map((n, i) => {
    return [
      n.name,
      n.regHours.toFixed(2) + ' ',
      formatValue(i === 0 ? 'dollars' : 'dollars', n.regDollars),
      n.otHours.toFixed(2) + ' ',
      formatValue(i === 0 ? 'dollars' : 'dollars', n.otDollars),
      n.totalHours.toFixed(2) + ' ',
      formatValue(i === 0 ? 'dollars' : 'dollars', n.totalDollars)
    ]
  })
  const withHeaders = [...headers, ...values]
  return withHeaders
}

const dollarsToFloat = str => {
  return parseFloat(str.replace('$', '').replace(',', ''))
}

const makeLaborEfficiency = (salesData, laborData) => {
  const netSalesEntry = salesData.find(i => i[0] === 'Net Sales') || []
  const netSales = netSalesEntry.length
    ? netSalesEntry[netSalesEntry.length - 1]
    : '$0.00'
  const laborTotals = laborData.find(i => i[0] === 'Totals') || []
  const laborHours = laborTotals.length
    ? laborTotals[laborTotals.length - 2]
    : '0.00'
  const laborDollars = laborTotals.length
    ? laborTotals[laborTotals.length - 1]
    : '$0.00'
  const netSalesFloat = dollarsToFloat(netSales)
  const laborDollarsFloat = dollarsToFloat(laborDollars)
  const efficiencyRatio = netSalesFloat ? laborDollarsFloat / netSalesFloat : 0
  const efficiency = (efficiencyRatio * 100).toFixed(1) + '% '
  return [
    ['Labor Efficiency', ' '],
    ['Labor Hours', laborHours],
    ['Labor Dollars', laborDollars],
    ['Net Sales', netSales],
    ['Labor as a % of Sales', efficiency]
  ]
}

class Sales extends Component {
  static propTypes = {
    data: propTypes.array,
    storeId: propTypes.number,
    stores: propTypes.array,
    revenueCenters: propTypes.object,
    taxes: propTypes.array,
    departments: propTypes.array,
    netSalesDef: propTypes.array
  }

  render() {
    const {
      data,
      storeId,
      stores,
      revenueCenters,
      taxes,
      departments,
      netSalesDef
    } = this.props
    if (!storeId)
      return <p className="alert">Please choose a store for this report.</p>
    const storeLookup = makeStoreLookup(stores)
    const storeName = storeLookup[storeId]
    const [sales, tenders, cards, tax, labor] = data || []
    if (!sales || !sales.length)
      return (
        <div className="report__table">
          <p className="alert">
            No sales data for this store during this time period.
          </p>
        </div>
      )
    const rcLookup = makeRevenueCenterLookup(revenueCenters)
    const salesData = makeSalesData(sales, rcLookup, netSalesDef, 2)
    const salesRcs = sales.map(i => ({
      id: i.revenue_center_id,
      type: rcLookup[i.revenue_center_id].type
    }))
    const headers = [...salesData[0].slice(1)]
    const tenderData = makeGroupedData(
      tenders,
      'tender_type',
      tenderFields,
      salesRcs,
      ['Tenders', ...headers]
    )
    const cardData = makeGroupedData(cards, 'card_type', cardFields, salesRcs, [
      'Credit Cards',
      ...headers
    ])
    const taxesLookup = makeTaxesLookup(taxes)
    const taxesFields = Object.entries(taxesLookup).map(([field, name]) => ({
      field,
      name
    }))
    const taxesData = makeTaxesData(tax, 'tax_id', taxesFields, salesRcs, [
      'Taxes',
      ...headers
    ])
    const laborData = makeLaborData(labor, departments)
    const laborEfficiency = makeLaborEfficiency(salesData, laborData)
    return storeId ? (
      <div className="report__table">
        <div className="report__header">
          <h2>{storeName}</h2>
        </div>
        <Table data={laborEfficiency} classes="table--sales table--labor" />
        <Table data={salesData} classes="table--sales" />
        <Table data={tenderData} classes="table--sales" />
        <Table data={cardData} classes="table--sales" />
        <Table data={taxesData} classes="table--sales" />
        <Table data={laborData} classes="table--sales" headerRows={2} />
      </div>
    ) : null
  }
}

Sales.displayName = 'Sales'

export default connect(
  state => ({
    stores: state.options.stores,
    revenueCenters: state.options['revenue-centers'],
    taxes: state.options.taxes,
    departments: state.options.departments,
    netSalesDef: state.options['general-settings']
      ? state.options['general-settings'].net_sales_definition
      : defaultNetSalesDefinition
  }),
  {}
)(Sales)
