import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../reducers/modal'

const displayPrice = price => {
  return parseFloat(price).toFixed(2)
}

const Option = ({ option }) => {
  return (
    <li key={option.id}>
      <span className="modal__option">
        <span className="modal__option__name">
          {option.name} ({option.id})
        </span>
        <span className="modal__option__price">
          ${displayPrice(option.price)}
        </span>
      </span>
    </li>
  )
}

Option.displayName = 'Option'
Option.propTypes = {
  option: propTypes.object
}

class MenuItem extends Component {
  static propTypes = {
    groups: propTypes.array,
    closeModal: propTypes.func
  }

  handleClose = evt => {
    evt.preventDefault()
    this.props.closeModal()
    evt.target.blur()
  }

  render() {
    const { groups } = this.props
    return (
      <div className="modal__item -preview">
        <form className="modal__groups">
          {groups.map(group => (
            <div key={group.id} className="modal__group">
              <div className="modal__group__header">
                <h3 className="modal__group__name">{group.name}</h3>
                <p className="modal__group__settings">
                  <span>
                    {group.min} min, {group.max} max, {group.inc} included
                  </span>
                </p>
              </div>
              <div className="modal__options">
                <ul>
                  {group.options.map(option => (
                    <Option
                      key={`${group.id}-${option.id}`}
                      group={group}
                      option={option}
                    />
                  ))}
                </ul>
              </div>
            </div>
          ))}
          <div className="modal__submit">
            <button className="btn" onClick={this.handleClose}>
              Close Modal
            </button>
          </div>
        </form>
      </div>
    )
  }
}

MenuItem.displayName = 'MenuItem'

export default connect(
  state => ({
    ...state.menu.currentItem
  }),
  { closeModal }
)(MenuItem)
