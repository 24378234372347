import propTypes from 'prop-types'
import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { filteredRoutes } from '../lib/routes'
import { pathStart } from '../lib/helpers'
import NavUser from '../components/NavUser'
import ClipLoader from 'react-spinners/ClipLoader'
import { LogOut } from 'react-feather'
import underscore from '../assets/open-tender-favicon_underscore_125x125.png'
// import logo from '../assets/open-tender-logo-white.png'

const isActiveParent = (path, children, pathname) => {
  if (path === pathname) return true
  if (!children) {
    return pathname.startsWith(path) ? true : false
  }
  let childRoutes = [],
    thirdRoutes = []
  children.map(i => {
    childRoutes.push(i.path)
    if (i.children) {
      thirdRoutes = thirdRoutes.concat(i.children.map(t => t.path))
    }
  })
  if (thirdRoutes.length && thirdRoutes.includes(pathStart(pathname)))
    return true
  return childRoutes.includes(pathStart(pathname))
    ? true
    : pathname.startsWith(path)
    ? true
    : false
}

const isActiveChild = (child, currentPath) => {
  if (child.path === currentPath) return true
  const childPaths = child.children.map(i => i.path)
  return childPaths.includes(pathStart(currentPath))
}

const NavItem = ({ name, path, icon, klass, exact, children, location }) => {
  const pathname = location.pathname
  return (
    <li className={klass}>
      <NavLink
        to={path}
        exact={exact}
        isActive={() => isActiveParent(path, children, pathname)}
        activeClassName="active"
      >
        <span className="nav__section">
          <span className="nav__icon">{icon}</span>
          <span className="nav__name">{name}</span>
        </span>
      </NavLink>
      {children && children.length ? (
        <ul>
          {children.map(child => (
            <li key={child.path}>
              {child.children ? (
                <NavLink
                  to={child.path}
                  activeClassName="active"
                  isActive={() => isActiveChild(child, pathname)}
                >
                  {child.name}
                </NavLink>
              ) : (
                <NavLink to={child.path} activeClassName="active">
                  {child.name}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

NavItem.displayName = 'NavItem'
NavItem.propTypes = {
  name: propTypes.string,
  path: propTypes.string,
  icon: propTypes.object,
  klass: propTypes.string,
  exact: propTypes.bool,
  children: propTypes.array,
  location: propTypes.object
}

const NavItemWithRouter = withRouter(props => <NavItem {...props} />)
NavItemWithRouter.displayName = 'NavItemWithRouter'

class Sidebar extends Component {
  static propTypes = {
    brand: propTypes.object,
    user: propTypes.object
  }

  render() {
    const routes = filteredRoutes(this.props.user)
    return (
      <div className="sidebar">
        <div className="sidebar__container">
          <div className="sidebar__title">
            <div className="container">
              <p>
                <Link to="/">
                  <span className="sidebar__brand-icon">
                    <img src={underscore} alt="Open Tender Underscore" />
                  </span>
                  <span className="sidebar__brand-name">
                    {this.props.brand
                      ? this.props.brand.short_name
                      : 'Open Tender'}
                  </span>
                </Link>
              </p>
            </div>
          </div>
          <nav className="sidebar__nav">
            {routes ? (
              <ul>
                {routes.map((route, index) => (
                  <NavItemWithRouter key={index} {...route} />
                ))}
              </ul>
            ) : (
              <div className="sidebar__loader">
                <ClipLoader size={18} color={'#fff'} />
              </div>
            )}
          </nav>
          <div className="sidebar__footer">
            {/* <ul>
              <li>
                <NavLink to="/login" exact={true}>
                  <span className="nav__section">
                    <span className="nav__icon">
                      <LogOut size={null} />
                    </span>
                    <span className="nav__name">Logout</span>
                  </span>
                </NavLink>
              </li>
            </ul> */}
            <div className="container">
              <NavUser user={this.props.user} />
              <div className="sidebar__logout">
                <NavLink to="/login">
                  <LogOut size={null} />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Sidebar.displayName = 'Sidebar'

export default Sidebar
