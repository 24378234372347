import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// ======================================
// THIS IS NOT CURRENTLY BEING USED!!!!!!
// ======================================

class NotFound extends Component {
  render() {
    const fileUrl =
      'http://s3.amazonaws.com/betterboh/u/img/local/2/1572714521_man-with-compass_1400x2356.jpg'
    return (
      <div className="main">
        <article className="content content--narrow">
          <div className="content__header">
            <h1>Page Not Found</h1>
            <p>Whoops. Nothing seems to exist here.</p>
          </div>
          <div className="content__main text">
            <p>
              <Link to="/">Click here</Link> to head back to the homepage
            </p>
            <p>{this.fileUrl}</p>
          </div>
        </article>
        <aside
          className="image"
          style={{
            backgroundImage: `url(${fileUrl})`,
            backgroundPosition: `center bottom`
          }}
        />
      </div>
    )
  }
}

NotFound.displayName = 'NotFound'

export default NotFound
