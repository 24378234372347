import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { NavTabs } from './Tabs'
import {
  showEditOrder,
  editOrder,
  reorder,
  refundOrder,
  retryCharge,
  markPaid,
  markNotPaid,
  markVoid,
  updateQuery,
  clearQuery,
  clearQueryError,
  findOrder
} from '../reducers/receipt'
import { toggleTabs } from '../reducers/page'
import { X } from 'react-feather'
import { SearchInput } from './Search'
import Button from './Button'

const DownloadReceipt = ({ receiptId }) => {
  const endpoint = `/orders/${receiptId}/invoice`
  const filename = `order-${receiptId}-receipt.pdf`
  return (
    <li>
      <Button
        text="Download PDF"
        endpoint={endpoint}
        fileType="application/pdf"
        filename={filename}
      />
    </li>
  )
}

DownloadReceipt.displayName = 'DownloadReceipt'
DownloadReceipt.propTypes = {
  receiptId: propTypes.number
}

const ReceiptButton = ({ text, handler, disabled = false }) => (
  <li>
    <button className="btn" onClick={handler} disabled={disabled}>
      {text}
    </button>
  </li>
)

ReceiptButton.displayName = 'Cart'
ReceiptButton.propTypes = {
  text: propTypes.string,
  handler: propTypes.func,
  disabled: propTypes.bool
}

class ReceiptTabs extends Component {
  static propTypes = {
    // props
    pathname: propTypes.string,
    route: propTypes.string,
    id: propTypes.string,
    item: propTypes.object,
    query: propTypes.string,
    loadingResults: propTypes.bool,
    searchError: propTypes.string,
    // state.receipt
    data: propTypes.object,
    page: propTypes.object,
    // mapDispatchToProps
    showEditOrder: propTypes.func,
    editOrder: propTypes.func,
    reorder: propTypes.func,
    refundOrder: propTypes.func,
    retryCharge: propTypes.func,
    markPaid: propTypes.func,
    markNotPaid: propTypes.func,
    markVoid: propTypes.func,
    updateQuery: propTypes.func,
    clearQuery: propTypes.func,
    clearQueryError: propTypes.func,
    findOrder: propTypes.func,
    toggleTabs: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    this.props.toggleTabs()
    evt.target.blur()
  }

  handleEdit = evt => {
    evt.preventDefault()
    this.props.showEditOrder()
    evt.target.blur()
  }

  handleReorder = evt => {
    evt.preventDefault()
    this.props.reorder()
    evt.target.blur()
  }

  handleRefund = evt => {
    evt.preventDefault()
    this.props.refundOrder()
    evt.target.blur()
  }

  handleRetryCharge = evt => {
    evt.preventDefault()
    const orderId = this.props.data.receipt_id
    this.props.retryCharge(orderId)
    evt.target.blur()
  }

  handleMarkPaid = evt => {
    evt.preventDefault()
    const orderId = this.props.data.receipt_id
    this.props.markPaid(orderId)
    evt.target.blur()
  }

  handleMarkNotPaid = evt => {
    evt.preventDefault()
    const orderId = this.props.data.receipt_id
    this.props.markNotPaid(orderId)
    evt.target.blur()
  }

  handleMarkVoid = evt => {
    evt.preventDefault()
    const orderId = this.props.data.receipt_id
    this.props.markVoid(orderId)
    evt.target.blur()
  }

  handleInput = evt => {
    this.props.clearQueryError()
    this.props.updateQuery(evt.target.value)
  }

  handleEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.props.findOrder()
    }
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearQueryError()
    this.props.clearQuery()
  }

  render() {
    const { route, id, item, query, loadingResults, searchError, data, page } =
      this.props
    const isRefund = data ? data.receipt_type === 'refund' : false
    const isEditable = data
      ? ['Open', 'Held'].includes(data.receipt_status)
      : false
    // const isPos = data ? data.channel_type === 'POS' : false
    const isClosed = data ? data.receipt_status === 'Closed' : false
    const isAuthorized = data ? data.tender_status === 'Authorized' : false
    const isPaid = data ? data.tender_status === 'Paid' : false
    const isRefunded = data ? data.tender_status === 'Refunded' : false
    const isFailed = data ? data.tender_status === 'Capture Failed' : false
    const is3rdParty = data ? data.channel_type === '3rd Party' : false
    const isParent = data ? data.is_parent : false
    // const isReviewed = isEditable && data.order_properties.isReviewed
    // const isNotReviewed = isEditable && !data.order_properties.isReviewed
    const refunds = data ? data.refund_receipt_ids || [] : []
    const itemTabs =
      refunds.length && !isRefund
        ? [...item.tabs, { name: 'Refunds', path: 'refunds' }]
        : null
    const itemWithTabs = itemTabs ? { ...item, tabs: itemTabs } : { ...item }
    const isRefundable = isClosed && !is3rdParty && !isRefund && !isParent
    return (
      <>
        <div className="nav tabs">
          <div className="nav__container">
            <div className="nav__title">
              <div className="container" />
            </div>
            <nav className="nav__nav">
              <NavTabs item={itemWithTabs} id={id} route={route} />
            </nav>
            <div className="nav__actions">
              <div className="container">
                <ul>
                  {isEditable && (
                    <ReceiptButton
                      text="Edit Order"
                      handler={this.handleEdit}
                    />
                  )}
                  {!isRefund && (
                    <ReceiptButton
                      text="Order Again"
                      handler={this.handleReorder}
                    />
                  )}
                  {isRefundable && (
                    <ReceiptButton
                      text="Refund Order"
                      handler={this.handleRefund}
                      disabled={isRefunded}
                      // disabled={isPos || isRefunded}
                    />
                  )}
                  <DownloadReceipt receiptId={parseInt(id)} />
                  {isClosed &&
                    (isAuthorized ? (
                      <>
                        <ReceiptButton
                          text="Retry Charge"
                          handler={this.handleRetryCharge}
                        />
                        <ReceiptButton
                          text="Mark Paid"
                          handler={this.handleMarkPaid}
                        />
                        <ReceiptButton
                          text="Mark Void"
                          handler={this.handleMarkVoid}
                        />
                      </>
                    ) : isPaid ? (
                      <ReceiptButton
                        text="Mark Not Paid"
                        handler={this.handleMarkNotPaid}
                      />
                    ) : isFailed ? (
                      <>
                        <ReceiptButton
                          text="Mark Paid"
                          handler={this.handleMarkPaid}
                        />
                        <ReceiptButton
                          text="Mark Void"
                          handler={this.handleMarkVoid}
                        />
                      </>
                    ) : null)}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="nav siblings">
          <div className="nav__cover">
            <div className="nav__overlay" />
          </div>
          {page.tabsOpen && (
            <div className="nav__close">
              <button className="btn-link" onClick={this.handleClick}>
                <X size={null} />
              </button>
            </div>
          )}
          <div className="nav__container">
            <div className="nav__title">
              <div className="container">
                <SearchInput
                  label={null}
                  query={query || ''}
                  placeholder="find order by ID"
                  clearSize={14}
                  handleInput={this.handleInput}
                  handleEnter={this.handleEnter}
                  handleClear={this.handleClear}
                />
              </div>
            </div>
            <div className="nav__search">
              {searchError && searchError.length ? (
                <p className="nav__search__error">{searchError}</p>
              ) : (
                loadingResults && (
                  <div className="nav__search__loading">
                    <ClipLoader size={20} color={'#fff'} />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

ReceiptTabs.displayName = 'ReceiptTabs'

export default connect(
  state => ({
    ...state.receipt,
    page: state.page
  }),
  {
    showEditOrder,
    editOrder,
    reorder,
    refundOrder,
    retryCharge,
    markPaid,
    markNotPaid,
    markVoid,
    updateQuery,
    clearQuery,
    clearQueryError,
    findOrder,
    toggleTabs
  }
)(ReceiptTabs)
