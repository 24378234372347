import propTypes from 'prop-types'
import React from 'react'
import { sortItems } from '../lib/helpers'
import DatePicker from 'react-datepicker'
import { NavLink } from 'react-router-dom'

const Select = ({ filter, handler }) => {
  let options = Array.isArray(filter.options) ? filter.options : []
  if (filter.sort) {
    const noneSelected = options.filter(i => i.name === 'none selected')
    const notAssigned = options.filter(i => i.name === 'Not Assigned')
    options = options.filter(
      i => i.name !== 'none selected' && i.name !== 'Not Assigned'
    )
    options = sortItems(options, { sortBy: 'name', sortType: 'alpha' })
    if (noneSelected) options = noneSelected.concat(options)
    if (notAssigned.length) options = options.concat(notAssigned)
  }
  return (
    <div className="table__filter">
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        htmlFor={filter.field}
        className={`label select ${filter.classes || ''}`}
      >
        <span>{filter.label}</span>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select id={filter.field} value={filter.value} onChange={handler}>
          {options.map((option, index) => (
            <option
              key={`{option.value}-${index}`}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.name}
            </option>
          ))}
        </select>
        <span className="select__arrow" />
      </label>
    </div>
  )
}

Select.displayName = 'Select'
Select.propTypes = {
  filter: propTypes.object,
  handler: propTypes.func
}

export const DatePickerInput = ({ filter, handler }) => {
  let selected = filter.value ? new Date(filter.value) : null
  selected = selected && isNaN(selected.getTime()) ? null : selected
  return (
    <div className="table__filter">
      <label htmlFor={filter.field} className={`label ${filter.classes || ''}`}>
        {filter.label && <span>{filter.label}</span>}
        <DatePicker
          showPopperArrow={false}
          dateFormat="yyyy-MM-dd"
          placeholderText="All Time"
          isClearable={!filter.noClear}
          selected={selected}
          onChange={date =>
            handler({
              target: { id: filter.field, type: 'datepicker', value: date }
            })
          }
        />
      </label>
    </div>
  )
}

DatePickerInput.displayName = 'DatePickerInput'
DatePickerInput.propTypes = {
  filter: propTypes.object,
  handler: propTypes.func
}

export const LinkButton = ({ filter }) => {
  return (
    <div className="table__filter">
      <label className={`label ${filter.classes || ''}`}>
        {filter.label && <span>{filter.label}</span>}
        <NavLink className="btn-filter" exact to={filter.value}>
          {filter.field}
        </NavLink>
      </label>
    </div>
  )
}

LinkButton.displayName = 'LinkButton'
LinkButton.propTypes = {
  filter: propTypes.object
}

const Filter = (filter, handler) => {
  switch (filter.type) {
    case 'select':
      return (
        <Select
          key={`filter-${filter.field}`}
          filter={filter}
          handler={handler}
        />
      )
    case 'datePicker':
      return (
        <DatePickerInput
          key={`filter-${filter.field}`}
          filter={filter}
          handler={handler}
        />
      )
    case 'link':
      return <LinkButton key={`filter-${filter.field}`} filter={filter} />
    default:
      return (
        <Select
          key={`filter-${filter.field}`}
          filter={filter}
          handler={handler}
        />
      )
  }
}

Filter.displayName = 'Filter'
Filter.propTypes = {
  field: propTypes.object,
  handler: propTypes.func
}

export { Filter }
