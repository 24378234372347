import propTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const Message = ({ message }) => (
  <TransitionGroup>
    {message ? (
      <CSSTransition key="message" classNames="flash" timeout={500}>
        <div className="message">
          <p>{message}</p>
        </div>
      </CSSTransition>
    ) : null}
  </TransitionGroup>
)

Message.displayName = 'Message'
Message.propTypes = {
  message: propTypes.string
}

export default connect(state => ({ message: state.message }))(Message)
