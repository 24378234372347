import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  { name: 'Created', field: ['charged_at'], format: 'datetime' },
  { name: 'Email Address', field: ['email'] },
  { name: 'Card Type', field: ['card_type'], format: 'enum' },
  { name: 'Masked', field: ['masked'] },
  { name: 'Amount', field: ['amount'], format: 'dollars' }
]

class Donations extends Component {
  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, handlers } = this.props
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/donations/csv" />
            </div>
          </div>
        </div>
        <div className="report__table">
          <Table fields={fields} items={data} />
        </div>
      </>
    )
  }
}

Donations.displayName = 'Donations'

export default connect(null, {})(Donations)
