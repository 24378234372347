import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Filter } from './Filters'
import ItemAvails from './ItemAvails'
import { updateFilter, updateAvails } from '../reducers/itemAvails'
import { resetFilters } from '../reducers/page'

class Avails extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    // state.page
    checkboxClass: propTypes.string,
    // ...state
    // state.itemAvails
    filters: propTypes.array,
    rowObj: propTypes.object,
    colObj: propTypes.object,
    rows: propTypes.array,
    items: propTypes.array,
    error: propTypes.string,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    updateAvails: propTypes.func
  }

  handleFilter = evt => {
    const { id, value } = evt.target
    const filter = { field: id, value: value }
    this.props.updateFilter(filter)
    evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.resetFilters()
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateAvails()
    this.submitButton.current.blur()
  }

  render() {
    const { filters, rowObj, colObj, checkboxClass } = this.props
    const showAvails = rowObj && colObj
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        {showAvails ? (
          <div className="content__main availabilities">
            <ItemAvails checkboxClass={checkboxClass} />
          </div>
        ) : null}
      </div>
    )
  }
}

Avails.displayName = 'Avails'

export default connect(
  state => ({
    checkboxClass: state.page.avails.checkboxClass,
    ...state.itemAvails
  }),
  { updateFilter, resetFilters, updateAvails }
)(Avails)
