import propTypes from 'prop-types'
import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { makeFlags } from './TableRow'
import TableCell from './TableCell'
import { Menu } from 'react-feather'
import TableCellLabel from './TableCellLabel'

// react-beautiful-dnd nested list example:
// https://codesandbox.io/s/j4yvnr7n83?from-embed

const DraggableListChildren = ({
  item,
  id,
  fields,
  listEndpoint,
  parentDragging,
  lookup
}) => {
  return (
    <Droppable droppableId={`droppable${item[id]}`} type={`${item[id]}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={`${snapshot.isDraggingOver ? `-dragging-over` : ''}`}
        >
          {item.children.map((child, index) => (
            <Draggable
              key={child[id]}
              draggableId={`${child[id]}`}
              index={index}
            >
              {(provided, snapshot) => {
                const dragging = snapshot.isDragging
                  ? '-dragging'
                  : parentDragging
                const rowIndex = lookup[child[id]]
                const evenOdd = rowIndex % 2 === 0 ? '-even' : '-odd'
                const flags = makeFlags(item, fields)
                const rowClasses = `tr tr--child ${evenOdd} ${dragging} ${flags}`
                return (
                  <div
                    className={dragging}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ul className={rowClasses}>
                      {fields.map(field => {
                        const fieldKey = field.field.join('-')
                        const key = `${child[id]}-${fieldKey}`
                        return (
                          <li key={key} className="td">
                            <TableCellLabel {...field} />
                            <TableCell
                              item={child}
                              endpoint={listEndpoint}
                              {...field}
                            />
                          </li>
                        )
                      })}
                      <li className="td gripper">
                        <Menu size={14} />
                      </li>
                    </ul>
                  </div>
                )
              }}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

DraggableListChildren.displayName = 'Table'
DraggableListChildren.propTypes = {
  item: propTypes.object,
  rowIndex: propTypes.number,
  id: propTypes.string,
  fields: propTypes.array,
  listEndpoint: propTypes.string,
  parentDragging: propTypes.string
}

export default DraggableListChildren
