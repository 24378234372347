import propTypes from 'prop-types'
import React from 'react'

const OrderTypeFilter = ({ orderTypes, orderType, handler }) => (
  <div className="order-filter order-filter--types toggle-group">
    {orderTypes.map(i => (
      <button
        key={i.name}
        className="btn"
        disabled={orderType === i.value}
        onClick={evt => handler(evt, i.value)}
      >
        {i.name === 'Main Menu' ? 'OLO' : i.name}
      </button>
    ))}
  </div>
)

OrderTypeFilter.displayName = 'OrderTypeFilter'
OrderTypeFilter.propTypes = {
  orderTypes: propTypes.array,
  orderType: propTypes.string,
  handler: propTypes.func
}

export default OrderTypeFilter
