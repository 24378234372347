import propTypes from 'prop-types'
import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
// import ButtonCSV from './ButtonCSV'
import { ResponsiveArea } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Toggle from './Toggle'
import Table from '../Table'

const makeChannels = data => {
  if (!data || !data.length) return []
  const channels = data.reduce((arr, i) => {
    const names = i.data.map(t => t.name)
    return names.length > arr.length ? names : arr
  }, [])
  return channels
}

const makeSalesData = (data, channels, asPercent) => {
  if (!data || !data.length) return []
  return data.map(i => {
    const values = channels.reduce((obj, name) => {
      const channel = i.data.find(d => d.name === name)
      if (!channel) return { ...obj, [name]: 0.0 }
      const amount = parseFloat(channel.net_sales)
      if (asPercent) {
        const total = i.data.reduce(
          (t, d) => (t += parseFloat(d.net_sales)),
          0.0
        )
        return { ...obj, [name]: (amount / total) * 100 }
      } else {
        return { ...obj, [name]: amount }
      }
    }, {})
    return {
      name: i.date,
      ...values
    }
  })
}

const makeTableData = data => {
  return data.map(i => {
    const amounts = Object.entries(i)
      .filter(([n]) => n !== 'name')
      .map(([, v]) => v)
    const total = amounts.reduce((t, v) => (t += v), 0.0)
    return { ...i, Total: total }
  })
}

const makeFields = (channels, format) => {
  const fields = channels.map(i => ({
    name: i,
    field: [i],
    format: format
  }))
  const name = { name: 'Date', field: ['name'] }
  const total = { name: 'Total', field: ['Total'], format }
  return [name, ...fields, total]
}

// const makeSalesMax = data => {
//   if (!data || !data.length) return 0
//   const totals = data.map(i => {
//     const total = i.data.reduce((t, d) => (t += parseFloat(d.net_sales)), 0.0)
//     return total
//   })
//   const max = Math.max(...totals)
//   return (Math.floor(max / 1000) + 2) * 1000
// }

const makeChecksData = (data, channels) => {
  if (!data || !data.length) return []
  return data.map(i => {
    const values = channels.reduce((obj, name) => {
      const channel = i.data.find(d => d.name === name)
      const value = channel ? parseInt(channel.checks) : 0.0
      return { ...obj, [name]: value }
    }, {})
    return {
      name: i.date,
      ...values
    }
  })
}

const displayTypes = [
  { name: 'Stacked', value: 'STACKED' },
  { name: 'Unstacked', value: 'DEFAULT' },
  { name: 'Percent', value: 'PERCENT' }
]

const checkNoData = data => {
  if (!data || !data.length || data.length === 1) return true
  // if (data.length > 1) return false
  // const total = data[0].data.reduce((t, i) => (t += parseInt(i.checks)), 0)
  // return total === 0 ? true : false
}

const SalesOverTime = ({ data, handlers }) => {
  const [showData, setShowData] = useState(false)
  const [display, setDisplay] = useState('STACKED')
  const asPercent = display === 'PERCENT' && !showData

  const toggleDisplay = evt => {
    evt.preventDefault()
    setShowData(!showData)
    evt.target.blur()
  }

  const toggleDisplayType = (evt, displayType) => {
    evt.preventDefault()
    setDisplay(displayType)
    evt.target.blur()
  }

  const noData = checkNoData(data)
  const channels = makeChannels(data)
  const salesData = makeSalesData(data, channels, asPercent)
  // const salesMax = makeSalesMax(data)
  const salesDataTable = makeTableData(salesData)
  const salesFields = makeFields(channels, 'currency')
  const checksData = makeChecksData(data, channels, asPercent)
  const checksDataTable = makeTableData(checksData)
  const checksFields = makeFields(channels, 'quantity')

  if (noData)
    return (
      <div className="report__table">
        <p className="alert">
          No sales data for the selected time period. Please try adjusting your
          filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <Toggle
              values={displayTypes}
              value={display}
              handler={toggleDisplayType}
            />
            <ToggleDisplay showData={showData} handler={toggleDisplay} />
            {/* <ButtonCSV endpoint="/reporting/sales-by-period/csv" /> */}
          </div>
        </div>
      </div>
      <div className="reports">
        <div className="report -full">
          <div className="report__header" style={{ marginBottom: '4rem' }}>
            <h2>Sales Over Time</h2>
          </div>
          <TransitionGroup component={null}>
            {showData && (
              <CSSTransition {...cssTransitionFade}>
                <div className="report__table">
                  <Table fields={salesFields} items={salesDataTable} />
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
          {!showData && (
            <ResponsiveArea
              height={500}
              data={salesData}
              dataKeys={channels}
              display={display}
              showLegend={true}
              yAxis={{
                width: 80,
                format: display === 'PERCENT' ? 'percentage' : 'dollars',
                domain: display === 'PERCENT' ? [0, 100] : ['auto', 'auto']
              }}
            />
          )}
        </div>
        <div className="report -full">
          <div className="report__header" style={{ marginBottom: '4rem' }}>
            <h2>Checks Over Time</h2>
          </div>
          <TransitionGroup component={null}>
            {showData && (
              <CSSTransition {...cssTransitionFade}>
                <div className="report__table">
                  <Table fields={checksFields} items={checksDataTable} />
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
          {!showData && (
            <ResponsiveArea
              height={500}
              data={checksData}
              dataKeys={channels}
              display={display}
              showLegend={true}
              yAxis={{
                width: 80,
                format: display === 'PERCENT' ? 'percentage' : 'quantity',
                domain: display === 'PERCENT' ? [0, 100] : ['auto', 'auto']
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

SalesOverTime.displayName = 'SalesOverTime'
SalesOverTime.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default SalesOverTime
