import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import Table from '../Table'

const makeFields = creditFields => {
  return [
    {
      name: 'Store',
      field: ['store']
    },
    ...creditFields,
    {
      name: 'Tender Total',
      field: ['tenderTotal'],
      format: 'dollars'
    },
    {
      name: 'Difference',
      field: ['diff'],
      format: 'dollars'
    }
  ]
}

const makeTotals = (fields, data) => {
  if (!data || !data.length) return null
  const totals = fields
    .filter(i => i.format === 'dollars')
    .reduce((obj, field) => {
      const key = field.field[0]
      const total = data.reduce((t, i) => {
        t += parseFloat(i[key])
        return t
      }, 0.0)
      return { ...obj, [key]: total }
    }, {})
  return { store: 'Totals', ...totals }
}

const makeCreditFields = cards => {
  return cards.map(t => {
    const key = `card${t.card_type_id === null ? 'Total' : t.card_type_id}`
    return {
      name: t.name,
      field: [key],
      format: 'dollars'
    }
  })
}

const makeSummary = data => {
  if (!data || !data.length) return []
  const values = data.reduce((arr, i) => {
    const item = { store: i.store, tenderTotal: i.tender_total, diff: i.diff }
    i.cards.map(t => {
      const key = `card${t.card_type_id === null ? 'Total' : t.card_type_id}`
      item[key] = t.amount
    })
    // const diff = parseFloat(i.credit) - parseFloat(item.creditTotal)
    arr.push({ ...item })
    return arr
  }, [])
  return values
}

const Credit = ({ data, handlers }) => {
  // const [showData, setShowData] = useState(true)
  const showData = true

  // const toggleDisplay = evt => {
  //   evt.preventDefault()
  //   setShowData(!showData)
  //   evt.target.blur()
  // }

  const creditFields =
    data && data.length ? makeCreditFields(data[0].cards) : []
  const fields = makeFields(creditFields)
  const tableData = makeSummary(data)
  const totals = makeTotals(fields, tableData)
  const allData = [...tableData, totals]
  if (!tableData.length)
    return (
      <div className="report__table">
        <p className="alert">
          No credit tenders for the selected time period. Please try adjusting
          your filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={tableData} {...handlers} />
          </div>
          <div className="report__actions__right">
            {/* <ToggleDisplay showData={showData} handler={toggleDisplay} /> */}
            <ButtonCSV endpoint="/reporting/credit-totals/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="report__table">
              <Table
                fields={fields}
                items={allData}
                classes="table--tax-summary"
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

Credit.displayName = 'Credit'
Credit.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default Credit
