import propTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutUser } from '../reducers/user'
import packageJson from '../../package.json'

// const routes = [{ name: 'Profile', path: '/profile' }]

const NavUserLink = ({ name, path, exact }) => (
  <li>
    <NavLink to={path} exact={exact} activeClassName="active">
      {name}
    </NavLink>
  </li>
)

NavUserLink.displayName = 'NavUserLink'
NavUserLink.propTypes = {
  name: propTypes.string,
  path: propTypes.string,
  exact: propTypes.bool
}

class NavUser extends Component {
  static propTypes = {
    user: propTypes.object,
    crumbs: propTypes.array,
    title: propTypes.string,
    indent: propTypes.bool,
    logoutUser: propTypes.func
  }
  render() {
    const user = this.props.user || {}
    return (
      <nav className="sidebar__user">
        <ul>
          <li>
            <NavLink to="/profile" exact={true} activeClassName="active">
              Hi, {user.first_name}
            </NavLink>
          </li>
          {/* {routes.map(route => (
            <NavUserLink key={route.path} {...route} />
          ))} */}
          <li>
            <button className="btn-link" onClick={this.props.logoutUser}>
              Logout
            </button>
          </li>
          <li className="sidebar__version">v{packageJson.version}</li>
        </ul>
      </nav>
    )
  }
}

NavUser.displayName = 'NavUser'

export default connect(null, { logoutUser })(NavUser)
