import propTypes from 'prop-types'
import React from 'react'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList
} from 'recharts'
import {
  formatCurrency,
  formatQuantity,
  formatPercentage
} from '../../lib/helpers'

// add padding to Legend: https://github.com/recharts/recharts/issues/807

export const colors = {
  black: '#000000',
  purple: '#5a5aff',
  purple2: '#A6A6FF',
  purpleLight: '#edeaff',
  blue: '#1ae2de'
}

// const shadesBlue = ['#1AE2DE', '#0C6362', '#61E8E5', '#2A6362', '#15B0AD']
// const shadesGreen = ['#30BF67', '#104022', '#6FCA92', '#23402E', '#238C4B']
// const shadesRed = ['#CC1E48', '#4D0B1B', '#D5607B', '#4D222C', '#991735']
// const shadesGold = ['#FFE159', '#80702D', '#FFEFA6', '#CCB447', '#807753']
// const shadesBlue = ['#17A4E6', '#0A4966', '#5EBDEA', '#1280B3', '#1C4E66']
// const shadesPurple0 = ['#5a5aff', '#A6A6FF', '#2D2D80', '#535380', '#4747CC']
// const shadesPurpleDark = ['#2D2D80', '#4747CC', '#161640', '#31318C', '#242466']
// const shadesPurplePale = ['#535380', '#8585CC', '#292940', '#5B5B8C', '#424266']

// const shadesGreen = ['#2DCCA4', '#114D3E', '#6FD5BB', '#284D43', '#22997B']
// const shadesBlue3 = ['#65AFFF', '#1B2845', '#335C81', '#5899E2', '#274060']
// const shadesPurple = ['#5a5aff', '#161640', '#4343BF', '#2D2D80', '#5050E6']
// const shadesPurpleLight = [
//   '#A6A6FF',
//   '#535380',
//   '#292940',
//   '#7C7CBF',
//   '#9595E6'
// ]

// const shadesSingle = shadesPurple
//   .map((a, i) => [a, shadesPurpleLight[i], shadesGreen[i], shadesBlue3[i]])
//   .flat()
// const shades = [...shadesSingle, ...shadesSingle]

// http://recharts.org/en-US/examples/CustomizedLabelLineChart
// this can be added to a Bar component or similar
export const Label = ({ x, y, stroke = colors.black, value }) => {
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  )
}

Label.displayName = 'Label'
Label.propTypes = {
  x: propTypes.number,
  y: propTypes.number,
  stroke: propTypes.string,
  value: propTypes.string
}

// http://recharts.org/en-US/examples/CustomizedLabelLineChart
// this can be added to an Axis component
export const AxisTick = ({ x, y, payload, rotate = -35 }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        transform={`rotate(${rotate})`}
        // fill={colors.black}
        // fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  )
}

AxisTick.displayName = 'AxisTick'
AxisTick.propTypes = {
  x: propTypes.number,
  y: propTypes.number,
  payload: propTypes.object,
  rotate: propTypes.number
}

const cartesian = {
  strokeDasharray: '3 3',
  stroke: colors.purpleLight,
  vertical: false
}

const legend = {
  iconType: 'square',
  align: 'left',
  wrapperStyle: { paddingTop: '15px' }
}

const makeFormatter = format => {
  switch (format) {
    case 'dollars':
      return n => formatCurrency(n, 0)
    case 'quantity':
      return n => formatQuantity(n, 0)
    case 'percentage':
      return n => formatPercentage(n, 0)
    default:
      return null
  }
}

const makeTooltip = (formatter = null) => {
  return {
    cursor: { fill: colors.purpleLight },
    contentStyle: { borderColor: colors.purpleLight },
    formatter: formatter
  }
}

const EmptyReport = () => (
  <div className="report__empty" style={{ width: '100%', height: '400px' }}>
    <p>There is no data for this time period</p>
  </div>
)

// https://stackoverflow.com/questions/40619476/javascript-generate-different-shades-of-the-same-color
// export const makeShades = (color, length) => {
//   const [r, g, b] = color
//   const max = Math.max(r, Math.max(g, b))
//   const step = 255 / (max * 10)
//   let shades = [`rgb(${color.join(',')})`]
//   for (let i = 1; i <= length; i++) {
//     const x = i % 2 !== 0 ? i + 10 : i + 1
//     const shade = [r * step * x, g * step * x, b * step * x]
//     shades.push(`rgb(${shade.join(',')})`)
//   }
//   return shades
// }

const axisProps = { stroke: colors.purpleLight }

const barShades = [
  { purple: '#5a5aff' },
  { seaGreenCrayola: '#00F0B5' },
  { yellowSelect: '#FFBA08' },
  { red: '#EF476F' },
  { blueGreen: '#43BCCD' },
  { blueSpaceCadet: '#1B264F' },
  // { blackCoffee: '#38302E' },
  // { gunMetal: '#122C34' },
  { purpleViolet: '#9B287B' },
  { purpleLight: '#A6A6FF' },
  { yellowLime: '#E2EF70' },
  { purplePansy: '#7E1946' },
  { mediumTurquoise: '#44CFCB' },
  { cafeAuLat: '#AD8350' },
  { purpleMiddle: '#DE89BE' },
  // { richBlack: '#070707' },
  { auburn: '#9E2B25' },
  { blueUraniaum: '#ADD9F4' },
  { orangeBright: '#FC814A' },
  { brownLight: '#7E6551' },
  { greenMedium: '#C5D5E4' },
  // { greenDark: '#253031' },
  { pinkChina: '#E072A4' },
  // { purpleDark: '#240115' },
  { blueElectric: '#70E4EF' },
  { tangerine: '#F29559' },
  { redBright: '#D00000' },
  { greenLight: '#BAFFDF' },
  { green: '#06D6A0' }
]

const allShades = [...barShades, ...barShades, ...barShades].reduce(
  (arr, i) => [...arr, Object.values(i)[0]],
  []
)

export const ResponsiveBar = ({
  height,
  data,
  bars,
  maxBarSize = null,
  format,
  stackId = null,
  layout = 'vertical',
  showLegend = true,
  showLabel = true,
  xAxis = { orientation: 'bottom' },
  yAxis = { orientation: 'left', width: 100 },
  labelPosition = 'insideRight',
  barColors,
  barCategoryGap = null,
  margin = {}
}) => {
  const tickFormatter = makeFormatter(format)
  const xAxisProps = { ...xAxis, ...axisProps, tickFormatter }
  const yAxisProps = { ...yAxis, ...axisProps }
  const tooltipProps = makeTooltip(tickFormatter)
  return !data || !data.length ? (
    <EmptyReport />
  ) : (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        layout={layout}
        barGap={0}
        maxBarSize={maxBarSize} // barGap of 0 doesn't work when this is set
        barCategoryGap={barCategoryGap}
        margin={margin}
      >
        <CartesianGrid {...cartesian} />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        {showLegend && <Legend {...legend} />}
        <Tooltip {...tooltipProps} />
        {bars.map((bar, index) => {
          const fill = barColors ? barColors[index] : allShades[index]
          return (
            <Bar key={bar} dataKey={bar} stackId={stackId} fill={fill}>
              {showLabel ? (
                <LabelList
                  dataKey={bar}
                  position={labelPosition}
                  formatter={tickFormatter}
                />
              ) : null}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

ResponsiveBar.displayName = 'ResponsiveBar'
ResponsiveBar.propTypes = {
  height: propTypes.number,
  data: propTypes.array,
  bars: propTypes.array,
  maxBarSize: propTypes.number,
  format: propTypes.string,
  stackId: propTypes.string,
  layout: propTypes.string,
  xAxis: propTypes.object,
  yAxis: propTypes.object,
  showLegend: propTypes.bool,
  showLabel: propTypes.bool,
  labelPosition: propTypes.string,
  barColors: propTypes.array,
  margin: propTypes.object,
  barCategoryGap: propTypes.number
}

const areaColors = {
  purple: '#5A5AFF',
  purpleLight: '#A6A6FF',
  green: '#30D9AE',
  greenDark: '#27B390',
  purplePink: '#AE4DFF',
  blue: '#4DAAFF',
  purpleLessDark: '#2D2D80',
  purplePure: '#7246E8',
  purpleDark: '#161640',
  pink: '#F755FF',
  blueLight: '#59C7FF',
  red: '#D64550',
  navy: '#031926',
  yellow: '#FFC857',
  maroon: '#4A1942'
}

const makeRandomColor = () => Math.floor(Math.random() * 16777215).toString(16)

const makeRandomColors = count => {
  return [...Array(count)].reduce((obj, i, idx) => {
    return { ...obj, [`color${idx}`]: `#${makeRandomColor()}` }
  }, {})
}

const viewTypes = view => {
  switch (view) {
    case 'STACKED':
    case 'PERCENT':
      return '1'
    default:
      return null
  }
}

export const ResponsiveArea = ({
  height,
  data,
  dataKeys,
  display = 'STACKED',
  xAxis = {},
  yAxis = {},
  showLegend = true,
  showLabel = false,
  labelPosition = 'insideRight',
  chartColors,
  useRandomColors = false
}) => {
  const randomColors = useRandomColors
    ? makeRandomColors(dataKeys.length)
    : null
  const dataColors = chartColors || randomColors || areaColors
  const stackId = viewTypes(display)
  const formatter = makeFormatter(yAxis.format)
  const tooltipProps = makeTooltip(formatter)
  return !data || !data.length ? (
    <EmptyReport />
  ) : (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={data}>
        <defs>
          {Object.entries(dataColors).map(([name, color]) => (
            <linearGradient key={name} id={name} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        {/* <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} /> */}
        <XAxis dataKey="name" stroke={colors.purpleLight} {...xAxis} />
        <YAxis
          stroke={colors.purpleLight}
          type="number"
          tickCount={4}
          tickFormatter={makeFormatter(yAxis.format)}
          {...yAxis}
        />
        {showLegend && <Legend {...legend} align="center" />}
        <CartesianGrid {...cartesian} />
        <Tooltip {...tooltipProps} />
        {dataKeys.map((dataKey, index) => {
          const [name, color] = Object.entries(dataColors).find(
            (i, idx) => idx === index
          )
          return (
            <Area
              key={dataKey}
              stackId={stackId}
              type="monotone"
              dataKey={dataKey}
              stroke={color}
              fillOpacity={1}
              // fill={color}
              fill={`url(#${name})`}
            >
              {showLabel ? (
                <LabelList
                  dataKey={dataKey}
                  position={labelPosition}
                  formatter={formatter}
                />
              ) : null}
            </Area>
          )
        })}
      </AreaChart>
    </ResponsiveContainer>
  )
}

ResponsiveArea.displayName = 'ResponsiveArea'
ResponsiveArea.propTypes = {
  height: propTypes.number,
  data: propTypes.array,
  dataKeys: propTypes.array,
  display: propTypes.string,
  xAxis: propTypes.object,
  yAxis: propTypes.object,
  showLegend: propTypes.bool,
  showLabel: propTypes.bool,
  labelPosition: propTypes.string,
  chartColors: propTypes.object
}
