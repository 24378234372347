import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
// import TableData from './TableData'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import { ResponsiveBar } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Table from '../Table'
// import throughputData from '../../data/throughput'

const fields = [
  {
    name: 'Start',
    field: ['start']
  },
  {
    name: 'End',
    field: ['end']
  },
  {
    name: 'Checks',
    field: ['tenders'],
    format: 'int'
  },
  {
    name: 'Average',
    field: ['avg_tender'],
    format: 'dollars'
  },
  {
    name: 'Net Sales',
    field: ['net_sales'],
    format: 'dollars'
  },
  {
    name: '% of day',
    field: ['frac_sales'],
    format: 'percentage1'
  }
]

const makeFields = (fields, itemTypes) => {
  const itemFields = itemTypes.map(i => {
    return {
      name: i.item_type_name,
      field: [i.item_type_name],
      format: 'int'
    }
  })
  return [...fields, ...itemFields]
}

const makeChecksData = data => {
  if (!data || !data.length) return []
  return data.map(i => ({
    name: i.start,
    checks: parseInt(i.tenders)
  }))
}

const makeSalesData = data => {
  if (!data || !data.length) return []
  return data.map(i => ({
    name: i.start,
    netSales: parseFloat(i.net_sales)
  }))
}

const makeTableData = data => {
  return data.reduce((arr, i) => {
    const itemCounts = i.counts_by_item_type.reduce((obj, c) => {
      return { ...obj, [c.item_type_name]: c.item_type_count }
    }, {})
    return [...arr, { ...i, ...itemCounts }]
  }, [])
}

const makeTotals = (data, itemTypes) => {
  if (!data || !data.length) return null
  const itemTotals = itemTypes.reduce((obj, itemType) => {
    return {
      ...obj,
      [itemType.item_type_name]: data.reduce(
        (t, i) => (t += parseFloat(i[itemType.item_type_name] || 0)),
        0.0
      )
    }
  }, {})
  const totalChecks = data.reduce((t, i) => (t += parseFloat(i.tenders)), 0.0)
  const totalSales = data.reduce((t, i) => (t += parseFloat(i.net_sales)), 0.0)
  const avgCheck = totalChecks ? totalSales / totalChecks : 0.0
  return {
    start: 'Totals',
    end: ' ',
    tenders: parseInt(totalChecks),
    avg_tender: avgCheck,
    net_sales: totalSales,
    frac_sales: 100,
    ...itemTotals
  }
}

class Throughput extends Component {
  constructor(props) {
    super(props)
    this.state = { showData: true }
  }

  static propTypes = {
    data: propTypes.array,
    storeId: propTypes.number,
    handlers: propTypes.object
  }

  toggleDisplay = evt => {
    evt.preventDefault()
    this.setState({ showData: !this.state.showData })
    evt.target.blur()
  }

  render() {
    const { data, handlers } = this.props
    const checksData = makeChecksData(data)
    const salesData = makeSalesData(data)
    const { showData } = this.state
    let itemNames = []
    data.forEach(i => {
      itemNames =
        i.counts_by_item_type.length > itemNames.length
          ? i.counts_by_item_type
          : itemNames
    })
    const fieldWithItems = makeFields(fields, itemNames)
    const dataWithItems = makeTableData(data)
    const totals = makeTotals(dataWithItems, itemNames)
    const tableData = totals ? [...dataWithItems, totals] : [...dataWithItems]
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ToggleDisplay showData={showData} handler={this.toggleDisplay} />
              <ButtonCSV endpoint="/reporting/throughput/csv" />
            </div>
          </div>
        </div>
        <TransitionGroup component={null}>
          {showData && (
            <CSSTransition {...cssTransitionFade}>
              <div className="reports">
                <div className="report report--throughput report--totals">
                  <div className="report__header">
                    <h2>Checks & Net Sales</h2>
                  </div>
                  <Table fields={fieldWithItems} items={tableData} />
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {!showData && (
          <div className="reports">
            <div className="report">
              <div className="report__header">
                <h2>Checks</h2>
              </div>
              <ResponsiveBar
                height={checksData.length * 35 + 50}
                data={checksData}
                bars={['checks']}
                showLegend={false}
                format="quantity"
                xAxis={{ orientation: 'bottom', type: 'number' }}
                yAxis={{
                  orientation: 'left',
                  dataKey: 'name',
                  type: 'category',
                  interval: 0,
                  width: 70
                }}
              />
            </div>
            <div className="report">
              <div className="report__header">
                <h2>Net Sales</h2>
              </div>
              <ResponsiveBar
                height={salesData.length * 35 + 50}
                data={salesData}
                bars={['netSales']}
                showLegend={false}
                format="dollars"
                xAxis={{ orientation: 'bottom', type: 'number' }}
                yAxis={{
                  orientation: 'left',
                  dataKey: 'name',
                  type: 'category',
                  interval: 0,
                  width: 70
                }}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

Throughput.displayName = 'Throughput'

export default connect(null, {})(Throughput)
