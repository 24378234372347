import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { capitalize } from '../lib/helpers'

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceItems = columns[source.droppableId]
    const destItems = columns[destination.droppableId]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: sourceItems,
      [destination.droppableId]: destItems
    })
  } else {
    const items = columns[source.droppableId]
    const copiedItems = [...items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: copiedItems
    })
  }
}

const Columns = ({ allItems, displayed, setDisplayed }) => {
  const [columns, setColumns] = useState({ HIDDEN: [], DISPLAYED: [] })
  const stringified = JSON.stringify(columns.DISPLAYED)

  useEffect(() => {
    if (displayed) {
      const newColumns = {
        HIDDEN: allItems.filter(i => !displayed.includes(i)),
        DISPLAYED: [...displayed]
      }
      setColumns(newColumns)
    }
  }, [allItems, displayed])

  useEffect(() => {
    setDisplayed(columns.DISPLAYED)
  }, [stringified])

  return (
    <div className="input-columns">
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, items], index) => {
          return (
            <div className="input-column" key={columnId}>
              <h2>{capitalize(columnId)}</h2>
              <Droppable droppableId={columnId} key={columnId}>
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`input-column-items ${
                        snapshot.isDraggingOver ? '-active' : ''
                      }`}
                      // style={{
                      //   background: snapshot.isDraggingOver
                      //     ? 'lightblue'
                      //     : 'lightgrey',
                      //   padding: 4,
                      //   width: 250,
                      //   minHeight: 500
                      // }}
                    >
                      {items.map((item, index) => {
                        return (
                          <Draggable
                            key={item}
                            draggableId={item}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`input-column-item ${
                                    snapshot.isDragging ? '-active' : ''
                                  }`}
                                  style={{
                                    ...provided.draggableProps.style
                                  }}
                                >
                                  {capitalize(item.replace('_', ' '))}
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )
                }}
              </Droppable>
            </div>
          )
        })}
      </DragDropContext>
    </div>
  )
}

export default Columns
