import propTypes from 'prop-types'
import React from 'react'

const Image = ({ image }) => (
  <aside
    className="image"
    style={{
      backgroundImage: `url(${image && image.url})`,
      backgroundPosition: `${image && image.position}`
    }}
  />
)

Image.displayName = 'Image'
Image.propTypes = {
  image: propTypes.object
}

export default Image
