import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import PageLoading from './PageLoading'
import { ReceiptSection, formatPrice } from './ReceiptSection'
import { Label } from './FormInputs'
import { updateCredit, addCredit } from '../reducers/reward'
import { fmtDate } from '../lib/helpersDatetime'
import { capitalize, formatQuantity, formatCurrency } from '../lib/helpers'

const makeReward = data => {
  if (!data) return {}
  const c = data.customer
  const customer = [
    { label: 'Customer', value: ' ' },
    {
      label: 'Name',
      value: [c.first_name, c.last_name].join(' '),
      path: '/customers/:id',
      id: c.customer_id
    },
    { label: 'Email', value: c.email },
    { label: 'Phone', value: c.phone },
    { label: 'Company', value: c.company }
  ]
  const lp = data.loyalty_program
  const isPoints = lp.spend_type === 'POINTS'
  const header = [
    { label: 'Loyalty Program Summary', value: ' ' },
    {
      label: 'Loyalty Program',
      value: lp.name,
      path: '/loyalty-programs/:id',
      id: lp.loyalty_program_id
    },
    {
      label: 'Program Type',
      value: capitalize(lp.loyalty_type)
    }
  ]
  const details = isPoints
    ? [
        {
          label: 'Spend All-time',
          value: formatPrice(data.spend_total)
        },
        {
          label: 'Points Balance',
          value: formatQuantity(data.points)
        }
      ]
    : [
        { label: 'Threshold', value: formatPrice(lp.threshold) },
        {
          label: 'Current Spend',
          value: formatPrice(data.spend_current)
        },
        {
          label: 'Progress',
          value: `${(
            (parseFloat(data.spend_current) / parseFloat(lp.threshold)) *
            100
          ).toFixed(0)}%`
        },
        {
          label: 'Spend All-time',
          value: formatPrice(data.spend_total)
        },
        // { label: 'Total Orders', value: data.orders_total },
        {
          label: 'Current Credit',
          value: formatPrice(data.credit_balance)
        },
        {
          label: 'Credit All-time',
          value: formatPrice(data.credit_total)
        }
      ]
  const t = data.tier
  const days = lp.tiers_expire_days
  const tier = isPoints
    ? [
        {
          label: days ? `Points in last ${days} days` : 'Points All-time',
          value: formatQuantity(data.progress)
        },
        {
          label: 'Status Tier',
          value: t ? `${t.name} (${formatQuantity(t.amount)})` : 'n/a'
        }
      ]
    : [
        {
          label: days ? `Spend in last ${days} days` : 'Spend All-time',
          value: formatPrice(data.progress)
        },
        {
          label: 'Status Tier',
          value: t ? `${t.name} (${formatCurrency(t.amount)})` : 'n/a'
        }
      ]
  const footer = [
    { label: 'Last Activity', value: fmtDate(data.updated_at) },
    { label: 'Established', value: fmtDate(data.created_at) }
  ]
  const summary = [...header, ...details, ...tier, ...footer]
  return { customer, summary }
}

class Reward extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    loading: propTypes.bool,
    data: propTypes.object,
    amount: propTypes.string,
    error: propTypes.string,
    updateCredit: propTypes.func,
    addCredit: propTypes.func
  }

  handleInput = evt => {
    this.props.updateCredit(evt.target.value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.addCredit()
    this.submitButton.current.blur()
    window.scroll(0, 0)
  }

  render() {
    const { loading, data, amount } = this.props
    const { customer, summary } = makeReward(data)
    return (
      <div className="content__main">
        <PageLoading loading={loading} />
        <TransitionGroup>
          {!loading && data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="receipt">
                <ReceiptSection rows={customer} />
                <ReceiptSection rows={summary} />
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="amount" className="label">
                    <Label label="Add credit to customer's account" />
                    <input
                      id="amount"
                      type="text"
                      value={amount || ''}
                      placeholder="0.00"
                      onChange={this.handleInput}
                    />
                  </label>
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value="Add Credit"
                      ref={this.submitButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Reward.displayName = 'Reward'

export default connect(
  state => ({
    ...state.reward
  }),
  { updateCredit, addCredit }
)(Reward)
