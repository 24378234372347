/* eslint-disable jsx-a11y/no-static-element-interactions */
import propTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { UploadCloud, Trash2, File } from 'react-feather'
import ClipLoader from 'react-spinners/ClipLoader'
import { slugify } from '../lib/helpers'

const DeleteFile = ({ deleteFile, fileId }) => {
  const handleDeleteFile = evt => {
    evt.preventDefault()
    evt.stopPropagation()
    deleteFile(fileId)
  }
  return (
    <button className="dz-delete btn" onClick={handleDeleteFile}>
      <span className="dz-delete-text">Delete</span>
      <span className="dz-delete-icon">
        <Trash2 size={16} />
      </span>
    </button>
  )
}

DeleteFile.displayName = 'DeleteFile'
DeleteFile.propTypes = {
  deleteFile: propTypes.func,
  fileId: propTypes.number
}

const Image = ({ name, file, deleteFile, progState, progPercent }) => {
  const fileId = file && file.file_id ? parseInt(file.file_id) : null
  return (
    <>
      <p className="dz-filetype">{name}</p>
      <div className={`dz-upload ${progState}`}>
        <span className="dz-icon">
          <UploadCloud size={null} />
        </span>
        <span className="dz-icon-msg">
          Drag & drop a file or click to browse your computer
        </span>
      </div>
      <div
        className={`dz-progress ${progState}`}
        style={{ width: progPercent + '%' }}
      >
        <span className="dz-processing-icon">
          <ClipLoader size={44} color={'#ffffff'} />
        </span>
        <span>Processing...</span>
      </div>
      {file ? (
        <>
          {fileId && <DeleteFile deleteFile={deleteFile} fileId={fileId} />}
          <img alt={file.file_type} className="dz-image" src={file.file_url} />
        </>
      ) : null}
    </>
  )
}

Image.displayName = 'Image'
Image.propTypes = {
  name: propTypes.string,
  file: propTypes.object,
  deleteFile: propTypes.func,
  progState: propTypes.string,
  progPercent: propTypes.number
}

const PDF = ({ name, file, deleteFile, progState, progPercent }) => {
  const filename = file
    ? file.file_url.split('/')[file.file_url.split('/').length - 1]
    : ''
  return (
    <>
      {file ? (
        <div className="dz-filename">
          <span className="dz-icon">
            <File size={18} />
          </span>
          <span>{filename}</span>
        </div>
      ) : null}
      <div className={`dz-upload ${progState}`}>
        <span className="dz-icon">
          <UploadCloud size={20} />
        </span>
        <span>Click to upload a {name}</span>
      </div>
      <div
        className={`dz-progress ${progState}`}
        style={{ width: progPercent + '%' }}
      >
        <span className="dz-processing-icon">
          <ClipLoader size={20} color={'#ffffff'} />
        </span>
        <span>Processing...</span>
      </div>
      {file ? (
        <DeleteFile deleteFile={deleteFile} fileId={file.file_id} />
      ) : null}
    </>
  )
}

PDF.displayName = 'PDF'
PDF.propTypes = {
  name: propTypes.string,
  file: propTypes.object,
  deleteFile: propTypes.func,
  progState: propTypes.string,
  progPercent: propTypes.number
}

const Raw = ({ progState, progPercent }) => {
  return (
    <>
      <div className={`dz-upload ${progState}`}>
        <span className="dz-icon">
          <UploadCloud size={20} />
        </span>
        <span>
          Click to upload an original high resolution image to populate the
          images below
        </span>
      </div>
      <div
        className={`dz-progress ${progState}`}
        style={{ width: progPercent + '%' }}
      >
        <span className="dz-processing-icon">
          <ClipLoader size={20} color={'#ffffff'} />
        </span>
        <span>Processing...</span>
      </div>
    </>
  )
}

Raw.displayName = 'Raw'
Raw.propTypes = {
  progState: propTypes.string,
  progPercent: propTypes.number
}

const Dropzone = props => {
  const fileInputRef = useRef()
  const [highlight, setHighlight] = useState(false)

  const openFileDialog = () => {
    if (props.disabled) return
    fileInputRef.current.click()
  }

  const onFilesAdded = evt => {
    if (props.disabled) return
    const files = evt.target.files
    if (props.onFilesAdded) {
      props.onFilesAdded(files.item(0))
    }
  }

  const onDragOver = evt => {
    evt.preventDefault()
    if (props.disabled) return
    setHighlight(true)
  }

  const onDragLeave = () => {
    setHighlight(false)
  }

  const onDrop = evt => {
    evt.preventDefault()
    if (props.disabled) return
    const files = evt.dataTransfer.files
    if (props.onFilesAdded) {
      props.onFilesAdded(files.item(0))
    }
    setHighlight(false)
  }

  const {
    progress: { state: progState, percentage: progPercent }
  } = props
  const hasFile = props.file ? 'has-file' : ''
  const fileClass = `dz-${slugify(props.name)}`

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`dz-dropzone ${
        highlight ? 'dz-highlight' : ''
      } ${progState} ${hasFile} ${fileClass}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props.disabled ? 'default' : 'pointer' }}
    >
      <input
        ref={fileInputRef}
        className="dz-file-input"
        type="file"
        multiple
        onChange={onFilesAdded}
      />
      {props.name === 'PDF' ? (
        <PDF {...props} progState={progState} progPercent={progPercent} />
      ) : props.name === 'Raw Image' ? (
        <Raw {...props} progState={progState} progPercent={progPercent} />
      ) : (
        <Image {...props} progState={progState} progPercent={progPercent} />
      )}
    </div>
  )
}

Dropzone.displayName = 'Dropzone'
Dropzone.propTypes = {
  disabled: propTypes.bool,
  onFilesAdded: propTypes.func,
  file: propTypes.object,
  deleteFile: propTypes.func,
  progress: propTypes.object,
  name: propTypes.string
}

export default Dropzone
