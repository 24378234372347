const listSettings = {
  regions: {
    title: 'Regions',
    endpoint: '/regions',
    id: 'region_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  stores: {
    title: 'Stores',
    endpoint: '/stores',
    id: 'store_id',
    field: 'full_name',
    sorting: { sortBy: 'full_name', sortType: 'alpha' }
  },
  revenueCenters: {
    title: 'Revenue Centers',
    endpoint: '/revenue-centers',
    id: 'revenue_center_id',
    params: [{ field: 'expand', value: true }],
    field: ['full_name', 'revenue_center_type'],
    separator: ' -- ',
    sorting: { sortBy: 'full_name', sortType: 'alpha' }
  },
  menus: {
    title: 'Menus',
    endpoint: '/menus',
    id: 'menu_id',
    field: 'short_name',
    sorting: { sortBy: 'short_name', sortType: 'alpha' }
  },
  categories: {
    title: 'Categories',
    endpoint: '/categories',
    id: 'category_id',
    field: 'short_name',
    sorting: { sortBy: 'menu_position', sortType: 'order' }
  },
  items: {
    title: 'Items',
    // endpoint: '/categories/:id/items',
    endpoint: '/items',
    id: 'item_id',
    field: 'short_name',
    sorting: { sortBy: 'menu_position', sortType: 'order' }
  },
  modifierGroups: {
    title: 'Modifier Groups',
    endpoint: '/modifier-groups',
    id: 'modifier_group_id',
    field: 'short_name',
    sorting: { sortBy: 'short_name', sortType: 'alpha' }
  },
  allergens: {
    title: 'Allergens',
    endpoint: '/allergens',
    id: 'allergen_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  tags: {
    title: 'Tags',
    endpoint: '/tags',
    id: 'tag_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  customers: {
    title: 'Customers',
    endpoint: '/customers',
    search: {
      label: 'Or search by name',
      endpoint: 'customers',
      placeholder: 'find customer',
      resultId: 'customer_id',
      resultName: ['first_name', 'last_name'],
      resultParens: 'email'
    }
  },
  houseAccounts: {
    title: 'House Accounts',
    endpoint: '/house-accounts',
    search: {
      label: 'Or search by name',
      endpoint: 'house-accounts',
      placeholder: 'find account',
      resultId: 'house_account_id',
      resultName: ['name'],
      resultParens: 'house_account_id'
    }
  },
  loyaltyPrograms: {
    title: 'Loyalty Programs',
    endpoint: '/loyalty-programs',
    id: 'loyalty_program_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  discounts: {
    title: 'Discounts',
    endpoint: '/discounts',
    id: 'discount_id',
    field: 'name',
    sorting: { sortBy: 'display_order', sortType: 'order' }
  },
  // promoCodes: {
  //   title: 'Promo Codes',
  //   endpoint: '/discounts',
  //   backEndpoint: '/promo-codes',
  //   id: 'discount_id',
  //   field: 'name',
  //   sorting: { sortBy: 'name', sortType: 'alpha' }
  // },
  promoCodes: {
    title: 'Promo Codes',
    endpoint: '/discounts',
    backEndpoint: '/promo-codes',
    search: {
      label: 'Or search by name',
      endpoint: 'discounts',
      placeholder: 'find promo code',
      resultId: 'discount_id',
      resultName: ['name'],
      resultParens: 'discount_id'
    }
  },
  deals: {
    title: 'Deals',
    endpoint: '/discounts',
    backEndpoint: '/deals',
    id: 'discount_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  rewards: {
    title: 'Rewards',
    endpoint: '/discounts',
    backEndpoint: '/rewards',
    id: 'discount_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  taxes: {
    title: 'Taxes',
    endpoint: '/taxes',
    id: 'tax_id',
    field: 'name',
    sorting: { sortBy: 'display_order', sortType: 'order' }
  },
  posts: {
    title: 'Posts',
    endpoint: '/posts',
    id: 'post_id',
    field: 'title',
    sorting: { sortBy: 'display_order', sortType: 'order' }
  },
  announcements: {
    endpoint: '/announcements',
    title: 'Announcements',
    id: 'announcement_id',
    field: 'title',
    sorting: { sortBy: 'title', sortType: 'alpha' }
  },
  announcementPages: {
    endpoint: '/announcement-pages',
    title: 'Announcement Pages',
    id: 'announcement_page_id',
    field: 'page',
    sorting: { sortBy: 'page', sortType: 'alpha' }
  },
  clients: {
    title: 'Clients',
    endpoint: '/oauth2-clients',
    backEndpoint: '/clients',
    id: 'oauth2_client_id',
    field: 'client_name',
    sorting: { sortBy: 'client_name', sortType: 'alpha' }
  },
  kdsTerminals: {
    title: 'KDS Terminals',
    endpoint: '/kds-terminals',
    id: 'kds_terminal_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  prepStations: {
    title: 'Prep Stations',
    endpoint: '/prep-stations',
    id: 'prep_station_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  posPrinters: {
    title: 'POS Printers',
    endpoint: '/pos-printers',
    id: 'pos_printer_id',
    field: 'name',
    sorting: { sortBy: 'name', sortType: 'alpha' }
  }
}

export default listSettings
