import { isString } from '../lib/utils'
import { request } from '../lib/services'
import { makeQueryParams, sortItems } from '../lib/helpers'
import { makeEntityName, getLastAttr } from '../lib/helpersPage'

const isLocal = process.env.NODE_ENV === 'development'

const initState = {
  templates: null,
  post_categories: null,
  post_tags: null,
  departments: null,
  batches: null,
  stores: null,
  'store-groups': null,
  regions: null,
  'revenue-centers': null,
  'loyalty-programs': null,
  'gift-card-batches': null,
  dayparts: null,
  menus: null,
  categories: null,
  'modifier-groups': null,
  items: null,
  allergens: null,
  tags: null,
  'kds-groups': null,
  surcharges: null,
  discounts: null,
  'loyalty-discounts': null,
  'promo-codes': null,
  deals: null,
  rewards: null,
  taxes: null,
  'pickup-wait-times': null,
  'delivery-wait-times': null,
  'pos-pages': null,
  'pos-sections': null,
  'pos-buttons': null,
  'pos-servers': null,
  'pos-terminals': null,
  'pos-printers': null,
  'general-settings': null,
  'item-types': null,
  announcements: null
}

export const LOAD_OPTIONS = 'LOAD_OPTIONS'
export const CLEAR_OPTIONS = 'CLEAR_OPTIONS'
export const RESET_OPTIONS = 'RESET_OPTIONS'

export const loadOptions = (endpoint, options) => ({
  type: LOAD_OPTIONS,
  endpoint: endpoint,
  payload: options
})

export const clearOptions = endpoint => ({
  type: CLEAR_OPTIONS,
  endpoint: endpoint
})

export const resetOptions = () => {
  // eslint-disable-next-line no-console
  if (isLocal) console.log('resetting options', new Date())
  return { type: RESET_OPTIONS }
}

const groupedSettings = {
  categories: {
    params: [
      { field: 'is_active', value: true },
      { field: 'expand', value: true },
      { field: 'with_related', value: true }
    ]
  },
  'modifier-groups': {
    params: [
      { field: 'is_active', value: true },
      { field: 'expand', value: true },
      { field: 'with_related', value: true }
    ]
  },
  templates: {
    mapBy: {
      key: 'type',
      name: 'name',
      id: 'template_id'
    }
  },
  'revenue-centers': {
    mapBy: {
      key: 'revenue_center_type',
      name: 'full_name',
      id: 'revenue_center_id'
    }
  },
  // discounts are a special case because they are split across 3
  // separate types of options: discounts, loyalty-discoutns, and promo-codes
  discounts: {
    params: [
      { field: 'is_promo_code', value: false },
      { field: 'is_deal', value: false },
      { field: 'is_reward', value: false }
    ],
    filterBy: { field: 'discount_type', values: ['DOLLAR', 'PERCENTAGE'] },
    // sorting: { sortBy: 'display_order', sortType: 'order' },
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  'loyalty-discounts': {
    params: [
      { field: 'is_promo_code', value: false },
      { field: 'is_deal', value: false },
      { field: 'is_reward', value: false }
    ],
    filterBy: { field: 'discount_type', values: ['LOYALTY'] },
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  'promo-codes': {
    params: [
      { field: 'is_promo_code', value: true },
      { field: 'is_deal', value: false },
      { field: 'is_reward', value: false }
    ],
    filterBy: {
      field: 'discount_type',
      values: ['DOLLAR', 'PERCENTAGE']
    },
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  deals: {
    params: [
      { field: 'is_promo_code', value: false },
      { field: 'is_deal', value: true },
      { field: 'is_reward', value: false }
    ],
    filterBy: {
      field: 'discount_type',
      values: ['DOLLAR', 'PERCENTAGE']
    },
    sorting: { sortBy: 'name', sortType: 'alpha' }
  },
  rewards: {
    params: [
      { field: 'is_promo_code', value: false },
      { field: 'is_deal', value: false },
      { field: 'is_reward', value: true }
    ],
    filterBy: {
      field: 'discount_type',
      values: ['DOLLAR', 'PERCENTAGE']
    },
    sorting: { sortBy: 'name', sortType: 'alpha' }
  }
}

export const makeCategories = (categories, disableNotEditable = false) => {
  let menus = {}
  categories.forEach(i => {
    i.menus.forEach(m => {
      if (m.menu.is_active) {
        const c = { ...i, menu_position: m.menu_position }
        menus[m.menu.name] = [...(menus[m.menu.name] || []), c]
      }
    })
  })
  let sorted = []
  const sorting = { sortBy: 'menu_position', sortType: 'order' }
  Object.entries(menus).map(([menu, cats]) => {
    sorted.push({
      value: '',
      name: `:: ${menu.toUpperCase()} ::`,
      isDisabled: true
    })
    const parents = sortItems(
      cats.filter(i => !i.parent_category_id),
      sorting
    )
    const children = cats
      .filter(i => i.parent_category_id)
      .reduce((obj, i) => {
        const child = { ...i, name: `-- ${i.short_name || i.full_name}` }
        const c = [...(obj[i.parent_category_id] || []), child]
        const sortedC = sortItems(c, sorting)
        obj[i.parent_category_id] = sortedC
        return obj
      }, {})
    parents.map(i => {
      const parent = {
        ...i,
        value: i.category_id,
        name: i.short_name || i.full_name,
        isDisabled: disableNotEditable && !i.is_editable
      }
      sorted.push(parent)
      if (children[i.category_id]) {
        children[i.category_id].map(c => {
          const child = {
            ...c,
            value: c.category_id,
            name: c.name,
            isDisabled: disableNotEditable && !i.is_editable
          }
          sorted.push(child)
        })
      }
    })
  })
  return sorted
}

export const makeGroups = (groups, disableNotEditable = false) => {
  let menus = { Global: [] }
  groups.forEach(i => {
    i.menus.length
      ? i.menus.forEach(m => {
          if (m.menu.is_active) {
            menus[m.menu.name] = [...(menus[m.menu.name] || []), i]
          }
        })
      : menus.Global.push(i)
  })
  let sorted = []
  Object.entries(menus).map(([menu, cats]) => {
    sorted.push({
      value: '',
      name: `:: ${menu.toUpperCase()} ::`,
      isDisabled: true
    })
    const withNames = cats.map(i => ({
      ...i,
      name: i.short_name || i.full_name
    }))
    const sorting = { sortBy: 'name', sortType: 'alpha' }
    const parents = sortItems(withNames, sorting)
    parents.map(i => {
      sorted.push({
        ...i,
        value: i.modifier_group_id,
        name: i.short_name || i.full_name,
        isDisabled: disableNotEditable && !i.is_editable
      })
    })
  })
  return sorted
}

const makeMappedOptions = (items, { key, name, id }) => {
  let lookup = {}
  items.map(i => {
    const optionName = makeEntityName(name, i)
    const nameAttr = getLastAttr(name)
    const option = { [id]: i[id], [nameAttr]: optionName, sortBy: optionName }
    const options = [...(lookup[i[key]] || []), option]
    const sorting = { sortBy: nameAttr, sortType: 'alpha' }
    lookup[i[key]] = sortItems(options, sorting)
  })
  return lookup
}

const makeFilteredOptions = (items, filterBy, sorting) => {
  const { field, values } = filterBy
  const filteredItems = items.filter(i => values.includes(i[field]))
  return sortItems(filteredItems, sorting)
}

const makeEntityEndpoint = endpoint => {
  switch (endpoint) {
    case 'promo-codes':
    case 'deals':
    case 'rewards':
    case 'loyalty-discounts':
      return 'discounts'
    default:
      return endpoint
  }
}

export const fetchOptions = (endpoints, isReload) => {
  return (dispatch, getState) => {
    const stateOptions = {}
    return new Promise((resolve, reject) => {
      if (!endpoints) return resolve(stateOptions)
      const { token, brand, options } = getState()
      const requests = endpoints.map(endpoint => {
        let optEndpoint = isString(endpoint) ? endpoint : endpoint.endpoint
        if (!isReload && options[optEndpoint]) {
          stateOptions[optEndpoint] = options[optEndpoint]
          return
        }
        const settings = groupedSettings[optEndpoint] || {}
        const queryParams = settings.params
          ? `${makeQueryParams(settings.params)}&`
          : ''
        const entityEndpoint = makeEntityEndpoint(optEndpoint)
        const reqEndpoint = `/${entityEndpoint}?${queryParams}limit=1000`
        return request(token, brand, reqEndpoint)
          .then(resp => {
            let items = resp.data || resp
            if (optEndpoint === 'categories') {
              items = makeCategories(items)
            } else if (optEndpoint === 'modifier-groups') {
              items = makeGroups(items)
            } else if (settings.mapBy) {
              items = makeMappedOptions(items, settings.mapBy)
            } else if (settings.filterBy) {
              items = makeFilteredOptions(
                items,
                settings.filterBy,
                settings.sorting
              )
            } else if (settings.emptyValue) {
              items = items.length ? items : settings.emptyValue
            }
            stateOptions[optEndpoint] = items
            dispatch(loadOptions(optEndpoint, items))
          })
          .catch(err => {
            const errJson = JSON.stringify(err)
            throw new Error(errJson)
          })
      })
      Promise.all(requests)
        .then(() => {
          return resolve(stateOptions)
        })
        .catch(err => {
          const error = JSON.parse(err.message)
          return reject(error)
        })
    })
  }
}

export const reloadOptions = endpoint => {
  return dispatch => {
    let entities = endpoint
      .split('/')
      .filter(i => i.length && i !== ':id' && i !== ':idd')
      .filter(i => Object.keys(initState).includes(i))
    if (entities.length) {
      // discounts are a special case because they are split across 3
      // separate types of options: discounts, loyalty-discounts, and promo-codes
      if (entities.includes('discounts')) {
        entities = [
          ...entities,
          'loyalty-discounts',
          'promo-codes',
          'deals',
          'rewards'
        ]
      }
      dispatch(fetchOptions(entities, true))
    }
  }
}

// export const clearAllOptions = () => {
//   return dispatch => {
//     return new Promise(resolve => {
//       dispatch(resetOptions())
//       return resolve(true)
//     })
//   }
// }

export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_OPTIONS:
      return { ...state, [action.endpoint]: action.payload }
    case CLEAR_OPTIONS:
      return { ...state, [action.endpoint]: null }
    case RESET_OPTIONS:
      return { ...initState }
    default:
      return state
  }
}
