import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'
// import paidInsOutsData from '../../data/paidInsOuts'

const fields = [
  { name: 'Date & Time', field: ['transfer_time_str'] },
  { name: 'POS Terminal', field: ['terminal'] },
  { name: 'Employee', field: ['employee'] },
  { name: 'Type', field: ['transfer_type_str'] },
  {
    name: 'Amount',
    field: ['amount'],
    format: 'dollars'
  },
  { name: 'Reason', field: ['reason'] },
  { name: 'Notes', field: ['notes'] }
]

class PaidInsOuts extends Component {
  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, handlers } = this.props
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/paid-ins-outs/csv" />
            </div>
          </div>
        </div>
        <div className="report__table">
          <Table fields={fields} items={data} />
        </div>
      </>
    )
  }
}

PaidInsOuts.displayName = 'PaidInsOuts'

export default connect(null, {})(PaidInsOuts)
