import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addSurcharge } from '../../reducers/order'

class Surcharges extends Component {
  static propTypes = {
    // state.options.surcharges
    surcharges: propTypes.array,
    // state.order.surchargeIds
    surchargeIds: propTypes.array,
    // mapdispatchtoprops
    addSurcharge: propTypes.func
  }

  handleSurcharge = evt => {
    evt.preventDefault()
    this.props.addSurcharge(parseInt(evt.target.id))
    evt.target.blur()
  }

  render() {
    const { surcharges, surchargeIds } = this.props
    const activeSurchargees = surcharges.filter(i => i.is_active)
    return (
      <div className="surcharges btn-group">
        {activeSurchargees.length ? (
          activeSurchargees.map((s, i, a) => {
            const lastOdd = i === a.length - 1 && i % 2 === 0 ? '-last-odd' : ''
            const lastEven =
              i === a.length - 1 && i % 2 === 1 ? '-last-even' : ''
            const last2ndOdd =
              i === a.length - 2 && i % 2 === 0 ? '-last-2nd-odd' : ''
            const classes = [lastOdd, lastEven, last2ndOdd].join(' ')
            return (
              <button
                key={s.surcharge_id}
                id={s.surcharge_id}
                className={`btn-group__btn ${classes}`}
                onClick={this.handleSurcharge}
                disabled={surchargeIds.includes(s.surcharge_id)}
              >
                <span>{s.name}</span>
              </button>
            )
          })
        ) : (
          <p>Looks like you {"don't"} currently have any active surcharges.</p>
        )}
      </div>
    )
  }
}

Surcharges.displayName = 'Surcharges'

export default connect(
  state => ({
    surcharges: state.options.surcharges,
    surchargeIds: state.order.surchargeIds
  }),
  { addSurcharge }
)(Surcharges)
