import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
// import ButtonCSV from './ButtonCSV'
import { ResponsiveBar } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Table from '../Table'

const fields = [
  {
    name: 'Store',
    field: ['name']
  },
  {
    name: 'Total',
    field: ['total'],
    format: 'int'
  },
  {
    name: 'Early',
    field: ['early'],
    format: 'int'
  },
  {
    name: ' ',
    field: ['earlyPer'],
    format: 'percentage1'
  },
  {
    name: 'On Time',
    field: ['onTime'],
    format: 'int'
  },
  {
    name: ' ',
    field: ['onTimePer'],
    format: 'percentage1'
  },
  {
    name: 'Late',
    field: ['late'],
    format: 'int'
  },
  {
    name: ' ',
    field: ['latePer'],
    format: 'percentage1'
  },
  {
    name: 'Unknown',
    field: ['unknown'],
    format: 'int'
  },
  {
    name: ' ',
    field: ['unknownPer'],
    format: 'percentage1'
  }
]

const makePercent = (count, total) => {
  return count ? (count / total) * 100 : 0.0
}

const makeTableData = data => {
  if (!data || !data.length) return []
  return data.reduce((arr, s) => {
    const total =
      s.before_alert + s.before_warning + s.after_warning + s.unknown
    const counts = {
      name: s.store_name,
      early: s.before_alert,
      earlyPer: makePercent(s.before_alert, total),
      onTime: s.before_warning,
      onTimePer: makePercent(s.before_warning, total),
      late: s.after_warning,
      latePer: makePercent(s.after_warning, total),
      unknown: s.unknown,
      unknownPer: makePercent(s.unknown, total),
      total
    }
    return [...arr, counts]
  }, [])
}

const makeGraphData = tableData => {
  return tableData.map(i => ({
    name: i.name,
    total: i.total,
    Early: i.early,
    'On Time': i.onTime,
    Late: i.late,
    Unknown: i.unknown
  }))
}

const makeGraphPercent = tableData => {
  return tableData.map(i => ({
    name: i.name,
    total: 100,
    Early: makePercent(i.early, i.total),
    'On Time': makePercent(i.onTime, i.total),
    Late: makePercent(i.late, i.total),
    Unknown: makePercent(i.unknown, i.total)
  }))
}

const makeTotals = data => {
  if (!data || !data.length) return null
  const total = data.reduce((t, i) => (t += parseFloat(i.total)), 0)
  const early = data.reduce((t, i) => (t += parseFloat(i.early)), 0)
  const onTime = data.reduce((t, i) => (t += parseFloat(i.onTime)), 0)
  const late = data.reduce((t, i) => (t += parseFloat(i.late)), 0)
  const unknown = data.reduce((t, i) => (t += parseFloat(i.unknown)), 0)
  const earlyPer = total ? (early / total) * 100.0 : 0.0
  const onTimePer = total ? (onTime / total) * 100.0 : 0.0
  const latePer = total ? (late / total) * 100.0 : 0.0
  const unknownPer = total ? (unknown / total) * 100.0 : 0.0
  return {
    name: 'Totals',
    total,
    early,
    earlyPer,
    onTime,
    onTimePer,
    late,
    latePer,
    unknown,
    unknownPer
  }
}

class OrderPrepPerformance extends Component {
  constructor(props) {
    super(props)
    this.state = { showData: false }
  }

  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  toggleDisplay = evt => {
    evt.preventDefault()
    this.setState({ showData: !this.state.showData })
    evt.target.blur()
  }

  render() {
    const { data, handlers } = this.props
    const { showData } = this.state
    const tableData = makeTableData(data)
    const graphBars = ['Early', 'On Time', 'Late', 'Unknown']
    const graphData = makeGraphData(tableData)
    const graphPercent = makeGraphPercent(tableData)
    const totals = makeTotals(tableData)
    const withTotals = totals ? [...tableData, totals] : [...tableData]
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ToggleDisplay showData={showData} handler={this.toggleDisplay} />
              {/* <ButtonCSV endpoint="/reporting/throughput/csv" /> */}
            </div>
          </div>
        </div>
        <TransitionGroup component={null}>
          {showData && (
            <CSSTransition {...cssTransitionFade}>
              <div className="reports">
                <div className="report report--order-prep report--totals">
                  <div className="report__header">
                    <h2>Performance Breakdown</h2>
                  </div>
                  <Table fields={fields} items={withTotals} />
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {!showData && (
          <div className="reports">
            <div className="report">
              <div className="report__header">
                <h2>Order Counts</h2>
              </div>
              <ResponsiveBar
                height={graphData.length * 50 + 50}
                data={graphData}
                bars={graphBars}
                format="quantity"
                stackId="a"
                xAxis={{ orientation: 'top', type: 'number' }}
                yAxis={{
                  orientation: 'left',
                  dataKey: 'name',
                  type: 'category',
                  interval: 0,
                  width: 100
                }}
                showLabel={false}
                barColors={['#2DCCA4', '#f8e405', '#eb2d5a', '#5a5aff']}
              />
            </div>
            <div className="report">
              <div className="report__header">
                <h2>Percentage Breakdown</h2>
              </div>
              <ResponsiveBar
                height={graphPercent.length * 50 + 50}
                data={graphPercent}
                bars={graphBars}
                format="quantity"
                stackId="a"
                xAxis={{
                  orientation: 'top',
                  type: 'number',
                  domain: [0, 'dataMax']
                }}
                yAxis={{
                  orientation: 'left',
                  dataKey: 'name',
                  type: 'category',
                  interval: 0,
                  width: 100
                }}
                showLabel={false}
                barColors={['#2DCCA4', '#f8e405', '#eb2d5a', '#5a5aff']}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

OrderPrepPerformance.displayName = 'OrderPrepPerformance'

export default connect(null, {})(OrderPrepPerformance)
