import propTypes from 'prop-types'
import React from 'react'

const Toggle = ({ values, value, handler, disabled = false }) => (
  <div className="toggle-group">
    {values.map(i => (
      <button
        key={i.value}
        className="btn"
        disabled={disabled || value === i.value}
        onClick={evt => handler(evt, i.value)}
      >
        {i.name}
      </button>
    ))}
  </div>
)

Toggle.displayName = 'Toggle'
Toggle.propTypes = {
  values: propTypes.array,
  value: propTypes.string,
  handler: propTypes.func,
  disabled: propTypes.bool
}

export default Toggle
