import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { fetchConfig, updateConfig } from '../reducers/config'
import { showPageError } from '../reducers/page'
import { resetSession } from '../reducers/user'
import { cssTransitionFade } from '../lib/helpersPage'
import Form from './Form'
import ClipLoader from 'react-spinners/ClipLoader'
import { PageError } from './PageError'
import Upload from './Upload'
import { isObject } from '../lib/utils'

const makeFlatData = data => {
  if (!data) return {}
  const flat = Object.entries(data).reduce((obj, entry) => {
    const [k1, v1] = entry
    if (!isObject(v1) || Array.isArray(v1)) {
      return { ...obj, [k1]: v1 }
    }
    let inner = {}
    Object.entries(v1).forEach(([k2, v2]) => {
      Object.entries(v2).forEach(([k3, v3]) => {
        inner[`${k1}-${k2}-${k3}`] = v3
      })
    })
    return { ...obj, ...inner }
  }, {})
  return flat
}

const makeFiles = (data, section) => {
  if (!data) return null
  if (data.background) {
    let files = [
      {
        name: 'Background',
        endpoint: '/order-app-images',
        file_url: data.background,
        file_type: `${section}.background`
      }
    ]
    if (data.mobile) {
      files = [
        ...files,
        {
          name: 'Mobile Background',
          endpoint: '/order-app-images',
          file_url: data.mobile,
          file_type: `${section}.mobile`
        }
      ]
    }
    return files
  } else if (data.icons) {
    return Object.entries(data.icons).map(([key, icon]) => {
      return {
        name: key === 'locationSelected' ? 'Selected Location' : key,
        endpoint: '/order-app-icons',
        file_url: icon.url,
        file_type: key,
        classes: 'dz-marker'
      }
    })
  }
  return null
}

class Config extends Component {
  static propTypes = {
    token: propTypes.string,
    brandId: propTypes.number,
    fields: propTypes.array,
    type: propTypes.string,
    section: propTypes.string,
    config: propTypes.object,
    fetchConfig: propTypes.func,
    updateConfig: propTypes.func,
    resetSession: propTypes.func,
    showPageError: propTypes.func
  }

  render() {
    const {
      fields,
      type,
      section,
      config,
      token,
      brandId,
      fetchConfig,
      updateConfig,
      resetSession,
      showPageError
    } = this.props
    const data = config && config[type] ? config[type][section] : null
    const flatData = makeFlatData(data)
    const files = makeFiles(data, section)
    return (
      <>
        <PageError msg={config.errors.form} />
        {!data && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="content__main">
                {files && (
                  <div className="content__files">
                    {files.map(file => (
                      <Upload
                        key={file.file_type}
                        name={file.name}
                        classes={file.classes || ''}
                        fileType={file.file_type}
                        fileObj={file}
                        endpoint={file.endpoint}
                        token={token}
                        brandId={brandId}
                        fetchFiles={fetchConfig}
                        deleteFile={null}
                        showFileError={showPageError}
                        resetSession={resetSession}
                      />
                    ))}
                  </div>
                )}
                <Form
                  fields={fields}
                  data={flatData}
                  errors={config.errors}
                  upsertItem={updateConfig}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>
    )
  }
}

Config.displayName = 'Config'

export default connect(
  state => ({
    ...state.page.config,
    config: state.config,
    token: state.token,
    brandId: state.brand.brand_id
  }),
  { fetchConfig, updateConfig, showPageError, resetSession }
)(Config)
