import { request } from '../lib/services'
import { convertFromAPI, makeQueryParams, sortItems } from '../lib/helpers'
import { timezoneMap, serviceType, weekdayOptions } from '../lib/constants'
// import { handleError } from '../lib/errors'
import { loadItem } from './item'
import { handlePageError } from './page'
import { flashMessage } from './messages'
import { makeOrderItem } from './order'
import { getDateForWeekday } from '../lib/helpersDatetime'
import { zonedTimeToUtc } from 'date-fns-tz'
import { openModal } from './modal'

const initState = {
  loading: true,
  dayparts: null,
  serviceTypes: null,
  weekdays: null,
  revenueCenter: null,
  timezone: null,
  menu: null,
  currentItem: null,
  error: ''
}

export const MENU_CLEAR = 'MENU_CLEAR'
export const MENU_FILTERS_UPDATE = 'MENU_FILTERS_UPDATE'
export const MENU_LOADING = 'MENU_LOADING'
export const MENU_LOADING_CLEAR = 'MENU_LOADING_CLEAR'
export const MENU_LOAD = 'MENU_LOAD'
export const MENU_SET_REVENUE_CENTER = 'MENU_SET_REVENUE_CENTER'
export const MENU_ERROR = 'MENU_ERROR'
export const MENU_UPDATE = 'MENU_UPDATE'

export const clearMenu = () => ({ type: MENU_CLEAR })
export const updateFilters = filter => ({
  type: MENU_FILTERS_UPDATE,
  payload: filter
})
export const loadingMenu = () => ({ type: MENU_LOADING })
export const clearLoading = () => ({ type: MENU_LOADING_CLEAR })
export const setRevenueCenter = id => ({
  type: MENU_SET_REVENUE_CENTER,
  payload: id
})
export const loadMenu = menu => ({ type: MENU_LOAD, payload: menu })
export const showMenuError = msg => ({ type: MENU_ERROR, payload: msg })
export const updateMenu = data => ({ type: MENU_UPDATE, payload: data })

const makeFilters = (allDayparts, store) => {
  // make dayparts filter
  const daypartsObj = allDayparts.reduce((obj, i) => {
    obj[i.daypart.full_name] = i.daypart.start_time.padStart(8, '0')
    return obj
  }, {})
  let dayparts = []
  for (let [name, value] of Object.entries(daypartsObj)) {
    dayparts.push({ name: name, value: value })
  }
  dayparts = sortItems(dayparts, { sortBy: 'value', sortType: 'alpha' })
  const daypartFilter = {
    label: 'Daypart',
    type: 'select',
    field: 'dayparts',
    value: dayparts.length ? dayparts[0].value : '',
    options: dayparts
  }
  // make serviceTypes filter
  const serviceTypesList = [...new Set(allDayparts.map(i => i.service_type))]
  let serviceTypes = serviceType.filter(i => serviceTypesList.includes(i.value))
  if (serviceTypesList.includes('DELIVERY')) {
    serviceTypes = serviceTypes.concat([{ value: 'PORTAL', name: 'Portal' }])
  }
  const serviceTypeFilter = {
    label: 'Service Type',
    type: 'select',
    field: 'serviceTypes',
    value: serviceTypes.length ? serviceTypes[0].value : '',
    options: serviceTypes
  }
  // make weekdays filter
  const weekdayList = [...new Set(allDayparts.map(i => i.weekday))]
  const weekdays = weekdayOptions.filter(i => weekdayList.includes(i.value))
  const weekdaysFilter = {
    label: 'Weekday',
    type: 'select',
    field: 'weekdays',
    value: weekdays.length ? weekdays[0].value : '',
    options: weekdays
  }
  return {
    dayparts: daypartFilter,
    serviceTypes: serviceTypeFilter,
    weekdays: weekdaysFilter,
    timezone: timezoneMap[store.timezone]
  }
}

export const fetchMenuFilters = () => {
  return (dispatch, getState) => {
    dispatch(clearMenu())
    const { token, brand, page, menu } = getState()
    const { id, item } = page
    if (
      menu.weekdays &&
      menu.dayparts &&
      menu.serviceTypes &&
      menu.revenueCenter === parseInt(id)
    )
      return
    dispatch(setRevenueCenter(parseInt(id)))
    const params = 'expand=true&with_related=dayparts'
    const endpoint = `${item.endpoint}/${id}?${params}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const data = convertFromAPI(page.route, 'item', resp)
        dispatch(loadItem(data))
        if (!data.dayparts.length)
          throw new Error(
            "This revenue center doesn't have any dayparts. Please add some."
          )
        const filters = makeFilters(data.dayparts, data.store)
        dispatch(updateFilters(filters))
        const requestedAt = makeMenuRequestedAt(
          filters.dayparts.value,
          filters.weekdays.value,
          filters.timezone
        )
        const args = {
          requestedAt: requestedAt,
          serviceType: filters.serviceTypes.value
        }
        dispatch(fetchMenu(args))
      })
      .catch(err => {
        dispatch(clearMenu())
        dispatch(clearLoading())
        dispatch(handlePageError(err))
      })
  }
}

export const updateFilter = (field, value) => {
  return (dispatch, getState) => {
    const { menu } = getState()
    const updatedFilter = { ...menu[field], value: value }
    dispatch(updateFilters({ [field]: updatedFilter }))
    dispatch(fetchMenu())
  }
}

export const makeMenuRequestedAt = (startTime, weekday, tz) => {
  const weekdayDate = getDateForWeekday(weekday, tz)
  const dateStr = `${weekdayDate} ${startTime}`
  const reqeuestedAt = zonedTimeToUtc(dateStr, tz).toISOString()
  return reqeuestedAt
}

export const fetchMenu = args => {
  return (dispatch, getState) => {
    dispatch(loadingMenu())
    const { token, brand, page, menu } = getState()
    args = args || {}
    if (!args.requestedAt) {
      args.requestedAt = makeMenuRequestedAt(
        menu.dayparts.value,
        menu.weekdays.value,
        menu.timezone
      )
    }
    if (!args.serviceType) {
      args.serviceType = menu.serviceTypes.value
    }
    const params = [
      { field: 'revenue_center_id', value: page.id },
      { field: 'service_type', value: args.serviceType },
      { field: 'requested_at', value: args.requestedAt }
    ]
    const queryParams = makeQueryParams(params)
    const endpoint = `/built-menus?${queryParams}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // console.log(JSON.stringify(resp.menu, null, 2))
        dispatch(loadMenu(resp))
        dispatch(flashMessage('Menu successfully retrieved!'))
      })
      .catch(err => {
        dispatch(clearLoading())
        dispatch(handlePageError(err))
      })
  }
}

export const refreshMenu = () => {
  return dispatch => {
    dispatch(fetchMenu())
  }
}

export const viewItem = item => {
  return dispatch => {
    const orderItem = makeOrderItem(item)
    dispatch(updateMenu({ currentItem: orderItem }))
    const config = {
      title: item.name,
      subtitle: item.description,
      classes: 'modal--big modal--item',
      type: 'menuItem'
    }
    dispatch(openModal(config))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case MENU_CLEAR:
      return { ...initState }
    case MENU_LOADING:
      return { ...state, menu: null, loading: true }
    case MENU_LOADING_CLEAR:
      return { ...state, loading: false }
    case MENU_SET_REVENUE_CENTER:
      return { ...state, revenueCenter: action.payload }
    case MENU_FILTERS_UPDATE:
      return { ...state, ...action.payload }
    case MENU_LOAD:
      return { ...state, menu: action.payload, error: '', loading: false }
    case MENU_ERROR:
      return { ...state, error: action.payload, loading: false }
    case MENU_UPDATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
