import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import PrintOrder from '../PrintOrder'
import { Printer } from 'react-feather'

// https://github.com/gregnb/react-to-print/blob/master/README.md

// other print solutions
// https://leerob.io/blog/how-to-print-in-react-using-iframes
// https://stackoverflow.com/questions/30135387/how-to-print-react-component-on-click-of-a-button
// https://www.smashingmagazine.com/2011/11/how-to-set-up-a-print-style-sheet/

class OrderDetails extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = { isPrint: false }
  // }

  static propTypes = {
    order: propTypes.object,
    printerType: propTypes.string,
    groupModsOlo: propTypes.bool,
    groupModsCatering: propTypes.bool
  }

  // handleBeforeGetContent = () => {
  //   return new Promise(resolve => {
  //     this.setState({ isPrint: true }, () => resolve())
  //   })
  // }

  render() {
    const { order, printerType, groupModsOlo, groupModsCatering } = this.props
    const groupMods = order.is_catering ? groupModsCatering : groupModsOlo
    return (
      <div className="print-order__wrapper">
        <ReactToPrint
          trigger={() => (
            <button className="btn-link print-order__btn">
              <Printer size={16} />
              <span>Print Order</span>
            </button>
          )}
          content={() => this.componentRef}
          // onBeforeGetContent={() => this.handleBeforeGetContent()}
          // copyStyles={false}
        />
        <PrintOrder
          order={order}
          printerType={printerType}
          groupMods={groupMods}
          // isPrint={this.state.isPrint}
          ref={el => (this.componentRef = el)}
        />
      </div>
    )
  }
}

OrderDetails.displayName = 'OrderDetails'

export default connect(
  state => ({
    errors: state.modal.errors,
    groupModsOlo: state.brand.group_options,
    groupModsCatering: state.brand.group_options_catering
  }),
  {}
)(OrderDetails)
