import { request } from '../lib/services'
import { makeQueryParams, sortItems } from '../lib/helpers'
import { flashMessage } from './messages'
import {
  updatePage,
  makeNewFilters,
  makeResetFilters,
  handlePageError,
  clearPageError
} from './page'
import { parseCursor } from '../reducers/list'
import { handleError } from '../lib/errors'
import { isString } from '../lib/utils'
import { showWorkingModal, closeModal } from './modal'

const initState = {
  items: [],
  item: null,
  links: {},
  loading: true,
  error: ''
}

export const ITEM_LIST_CLEAR = 'ITEM_LIST_CLEAR'
export const ITEM_LIST_LOADING = 'ITEM_LIST_LOADING'
export const ITEM_LIST_LOAD = 'ITEM_LIST_LOAD'
export const ITEM_LIST_RETRIEVE_ITEM = 'ITEM_LIST_RETRIEVE_ITEM'
export const ITEM_LIST_UPDATE = 'ITEM_LIST_UPDATE'
export const ITEM_LIST_ERROR = 'ITEM_LIST_ERROR'

export const clearItemList = () => ({ type: ITEM_LIST_CLEAR })
export const loadingItemList = () => ({ type: ITEM_LIST_LOADING })
export const loadItemList = items => ({ type: ITEM_LIST_LOAD, payload: items })
export const updateItemList = items => ({
  type: ITEM_LIST_UPDATE,
  payload: items
})
export const showItemListError = msg => ({
  type: ITEM_LIST_ERROR,
  payload: msg
})

const maybeReplaceId = (value, id) => {
  if (!isString(value)) return value
  return value.replace(':id', id)
}

export const fetchItemList = cursor => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, itemList } = page
    dispatch(clearPageError())
    dispatch(clearItemList())
    dispatch(loadingItemList())
    const params = (itemList.params || []).map(i => {
      return { ...i, value: maybeReplaceId(i.value, id) }
    })
    const filters = [...(itemList.filters || []), ...params]
    const queryParams = makeQueryParams(filters)
    let endpoint = itemList.endpoint.replace(':id', id)
    if (queryParams) endpoint += `?${queryParams}`
    if (cursor) endpoint += queryParams ? `&${cursor}` : `?${cursor}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const items = sortItems(resp.data || resp, page.itemList.sorting)
        dispatch(loadItemList({ items: items, links: resp.links }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showItemListError(''))
      })
  }
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    const { itemList } = getState().page
    const newFilters = makeNewFilters(itemList.filters, filter)
    const newItemList = { ...itemList, filters: newFilters }
    dispatch(updatePage({ itemList: newItemList }))
    dispatch(fetchItemList())
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    const { itemList } = getState().page
    const newFilters = makeResetFilters(itemList.filters)
    const newItemList = { ...itemList, filters: newFilters }
    dispatch(updatePage({ itemList: newItemList }))
    dispatch(fetchItemList())
  }
}

export const fetchLink = link => {
  return dispatch => {
    dispatch(fetchItemList(parseCursor(link)))
  }
}

export const refreshItems = () => {
  return dispatch => dispatch(fetchItemList())
}

export const selectItem = (itemId, isSelected) => {
  return (dispatch, getState) => {
    const { itemList, page } = getState()
    const { id } = page.itemList
    const newItems = itemList.items.map(i => {
      if (i[id] === itemId) i.isSelected = isSelected
      return i
    })
    dispatch(updateItemList(newItems))
  }
}

export const retrieveItem = (field, itemId) => {
  return (dispatch, getState) => {
    const { itemList } = getState()
    const item = itemList.items.filter(i => i[field] === itemId)[0]
    dispatch({ type: ITEM_LIST_RETRIEVE_ITEM, payload: item })
  }
}

export const createInvoice = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(showWorkingModal())
    const { token, brand, page, itemList } = getState()
    const orderIds = itemList.items
      .filter(i => i.isSelected)
      .map(i => i[page.itemList.id])
    if (!orderIds.length) return
    const data = {
      house_account_id: parseInt(page.id),
      is_paid: false,
      order_ids: orderIds
    }
    request(token, brand, '/invoices', 'POST', data)
      .then(resp => {
        dispatch(fetchItemList())
        const msg = `Invoice ${resp.invoice_id} successfully created!`
        return dispatch(flashMessage(msg))
      })
      .catch(err => {
        const errors = handleError(err)
        const { form, fields } = errors
        err.detail = fields
          ? Object.entries(fields)
              .map(i => i[1])
              .join(' ')
          : err.detail || form
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(closeModal()))
  }
}

export const itemListActions = actionType => {
  return dispatch => {
    switch (actionType) {
      case 'createInvoice':
        return dispatch(createInvoice())
      default:
        return
    }
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ITEM_LIST_CLEAR:
      return { ...initState }
    case ITEM_LIST_LOADING:
      return { ...state, loading: true }
    case ITEM_LIST_LOAD:
      return { ...state, ...action.payload, loading: false, error: '' }
    case ITEM_LIST_RETRIEVE_ITEM:
      return { ...state, item: action.payload }
    case ITEM_LIST_UPDATE:
      return { ...state, items: action.payload }
    case ITEM_LIST_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
