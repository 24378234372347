import { request } from '../lib/services'
import { handlePageError } from './page'
import { flashMessage } from './messages'
import { pageType } from '../lib/constants'
import { makeFilterLookup } from '../lib/helpersPage'

const initState = {
  rowId: null,
  rows: null,
  colId: null,
  cols: null,
  items: null,
  disabled: null,
  error: '',
  loading: false
}

export const ATTRIBUTES_CLEAR = 'ATTRIBUTES_CLEAR'
export const ATTRIBUTES_LOAD = 'ATTRIBUTES_LOAD'
export const ATTRIBUTES_ERRORS = 'ATTRIBUTES_ERRORS'
export const ATTRIBUTES_UPDATE = 'ATTRIBUTES_UPDATE'
export const ATTRIBUTES_LOADING = 'ATTRIBUTES_LOADING'

export const clearAttributes = () => ({ type: ATTRIBUTES_CLEAR })
export const loadAttributes = data => ({ type: ATTRIBUTES_LOAD, payload: data })
export const showAttributesErrors = msg => ({
  type: ATTRIBUTES_ERRORS,
  payload: msg
})

const getRevenueCenter = (token, brand, endpoint) => {
  return request(token, brand, endpoint).then(resp => {
    const revenueCenter = {
      name: resp.full_name,
      value: `rc_${resp.revenue_center_id}`
    }
    if (!resp.region) return [revenueCenter]
    const region = {
      name: resp.region.name,
      value: `region_${resp.region.region_id}`
    }
    return [region, revenueCenter]
  })
}

const getRequest = (token, brand, endpoint, name, value) => {
  return request(token, brand, endpoint).then(resp => {
    let items = resp.data || resp
    if (endpoint.includes('/items/')) {
      const categories = items.categories.map(i => {
        return {
          name: i.category[name],
          value: `cat_${i.category.category_id}`
        }
      })
      const modifierGroups = items.modifier_groups.map(i => {
        return {
          name: i.modifier_group[name],
          value: `mod_${i.modifier_group.modifier_group_id}`
        }
      })
      return categories.concat(modifierGroups)
    }
    return !name
      ? items
      : items.map(i => {
          return { name: i[name], value: i[value] }
        })
  })
}

export const fetchAttributes = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const filterLookup = makeFilterLookup(page.attributes.filters)
    const attrTypeFilter = filterLookup.attribute_type
    let itemParams, rowArgs, colArgs, colsRequest
    dispatch(clearAttributes())
    if (page.type === pageType.itemAttributes) {
      itemParams = `item_id=${page.id}`
      rowArgs = [
        `/items/${page.id}?with_related=true`,
        'short_name',
        'group_id'
      ]
    } else {
      const itemFilter =
        filterLookup.category_id || filterLookup.modifier_group_id
      if (!itemFilter || !attrTypeFilter) return
      itemParams = `${itemFilter.field}=${itemFilter.value}`
      rowArgs = [`/items?${itemParams}&limit=1000`, 'short_name', 'item_id']
    }
    const rowsRequest = getRequest(token, brand, ...rowArgs)
    dispatch({ type: ATTRIBUTES_LOADING })
    if (page.type === pageType.revenueCenterAttributes) {
      colArgs = [`/revenue-centers/${page.id}?expand=true`, 'name', 'place_id']
      colsRequest = getRevenueCenter(token, brand, colArgs[0])
    } else {
      colArgs = ['/regions?is_active=true', 'name', 'region_id']
      colsRequest = getRequest(token, brand, ...colArgs)
    }
    // attributes request
    const attrParams = `${attrTypeFilter.field}=${attrTypeFilter.value}`
    const endpoint = `${page.attributes.endpoint}?${itemParams}&${attrParams}&limit=1000`
    const itemsRequest = getRequest(token, brand, endpoint)
    const promises = [rowsRequest, colsRequest, itemsRequest]
    Promise.all(promises)
      .then(values => {
        let [rows, cols, items] = values
        // if we're editing pricing by region, we need to remove
        // any attributes that were set at the revenue center level
        if (page.type !== pageType.revenueCenterAttributes) {
          items = items.filter(i => !i.revenue_center_id)
        } else {
          items = items.map(i => {
            i.place_id = !i.revenue_center_id
              ? i.region_id
                ? `region_${i.region_id}`
                : i.region_id
              : `rc_${i.revenue_center_id}`
            return i
          })
        }
        if (page.type === pageType.itemAttributes) {
          items = items.map(i => {
            i.group_id = i.category_id
              ? `cat_${i.category_id}`
              : `mod_${i.modifier_group_id}`
            return i
          })
        }
        if (!items.length) dispatch(clearAttributes())
        const newItemAttributes = {
          rowId: rowArgs[2],
          rows: rows,
          colId: colArgs[2],
          cols: [{ name: 'Default', value: null }, ...cols],
          items: items
        }
        dispatch(loadAttributes(newItemAttributes))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const copyDown = (regionId, serviceType) => {
  return (dispatch, getState) => {
    const {
      attributes: { items, rows, rowId, colId },
      page
    } = getState()
    const colItems = items.filter(
      i => i[colId] === regionId && i.service_type === serviceType
    )
    if (!colItems.length) return
    const otherItems = items.filter(
      i => i[colId] !== regionId || i.service_type !== serviceType
    )
    const firstItem = colItems.find(i => i[rowId] === rows[0].value)
    const filterLookup = makeFilterLookup(page.attributes.filters)
    const attrTypeFilter = filterLookup.attribute_type
    let values = {
      attribute_type: attrTypeFilter.value,
      attribute_value: firstItem ? firstItem.attribute_value : '',
      service_type: serviceType,
      region_id: regionId,
      revenue_center_id: null
    }
    if (page.type === pageType.revenueCenterAttributes) {
      if (!firstItem.place_id) {
        values.region_id = null
        values.revenue_center_id = null
        values.place_id = null
      } else {
        const [placeType, placeId] = regionId.split('_')
        if (placeType === 'region') {
          values.region_id = parseInt(placeId)
          values.revenue_center_id = null
          values.place_id = `region_${placeId}`
        } else {
          values.region_id = null
          values.revenue_center_id = parseInt(placeId)
          values.place_id = `rc_${placeId}`
        }
      }
    }
    let newItems
    if (page.type === pageType.itemAttributes) {
      values.item_id = parseInt(page.id)
      newItems = rows.map(i => {
        const [groupType, groupId] = i.value.split('_')
        if (groupType === 'cat') {
          return {
            ...values,
            category_id: parseInt(groupId),
            modifier_group_id: null,
            group_id: `cat_${groupId}`
          }
        } else {
          return {
            ...values,
            category_id: null,
            modifier_group_id: parseInt(groupId),
            group_id: `mod_${groupId}`
          }
        }
      })
    } else {
      if (filterLookup.category_id) {
        values.category_id = parseInt(filterLookup.category_id.value)
        values.modifier_group_id = null
      } else if (filterLookup.modifier_group_id) {
        values.category_id = null
        values.modifier_group_id = parseInt(
          filterLookup.modifier_group_id.value
        )
      }
      newItems = rows.map(i => ({ ...values, [rowId]: i.value }))
    }
    const allItems = [...otherItems, ...newItems]
    dispatch({ type: ATTRIBUTES_UPDATE, payload: allItems })
  }
}

export const updateAttribute = attribute => {
  return (dispatch, getState) => {
    const {
      attributes: { items, rowId, colId },
      page
    } = getState()
    const item = items.filter(
      i =>
        i[rowId] === attribute[rowId] &&
        i[colId] === attribute[colId] &&
        i.service_type === attribute.service_type
    )
    let newItem
    if (item.length) {
      newItem = { ...item[0], ...attribute }
    } else {
      if (items.length) {
        newItem = { ...items[0], ...attribute }
      } else {
        const filterLookup = makeFilterLookup(page.attributes.filters)
        const attrTypeFilter = filterLookup.attribute_type
        let values = {
          attribute_type: attrTypeFilter.value,
          revenue_center_id: null
        }
        if (page.type === pageType.itemAttributes) {
          values.item_id = parseInt(page.id)
        } else {
          if (filterLookup.category_id) {
            values.category_id = parseInt(filterLookup.category_id.value)
            values.modifier_group_id = null
          } else {
            values.category_id = null
            values.modifier_group_id = parseInt(
              filterLookup.modifier_group_id.value
            )
          }
        }
        newItem = { ...values, ...attribute }
      }
      if (page.type === pageType.itemAttributes) {
        const [groupType, groupId] = newItem.group_id.split('_')
        if (groupType === 'cat') {
          newItem.category_id = parseInt(groupId)
          newItem.modifier_group_id = null
        } else {
          newItem.category_id = null
          newItem.modifier_group_id = parseInt(groupId)
        }
      }
      if (page.type === pageType.revenueCenterAttributes) {
        if (!newItem.place_id) {
          newItem.region_id = null
          newItem.revenue_center_id = null
        } else {
          const [placeType, placeId] = newItem.place_id.split('_')
          if (placeType === 'region') {
            newItem.region_id = parseInt(placeId)
            newItem.revenue_center_id = null
          } else {
            newItem.region_id = null
            newItem.revenue_center_id = parseInt(placeId)
          }
        }
      }
    }
    if (newItem.error) delete newItem.error
    const otherItems = items.filter(
      i =>
        i[rowId] !== attribute[rowId] ||
        i[colId] !== attribute[colId] ||
        i.service_type !== attribute.service_type
    )
    const newItems = [...otherItems, newItem]
    dispatch({ type: ATTRIBUTES_UPDATE, payload: newItems })
  }
}

const validateValues = updated => {
  return updated.map(i => {
    let value
    if (i.attribute_type === 'SERVING_SIZE') {
      value = Math.abs(parseFloat(i.attribute_value)).toFixed(2)
    } else if (i.attribute_type === 'PRICE') {
      value = parseFloat(i.attribute_value).toFixed(2)
    } else {
      value = Math.abs(parseInt(i.attribute_value)).toFixed(0)
    }
    return value === 'NaN'
      ? { ...i, error: 'Invalid' }
      : { ...i, attribute_value: value }
  })
}

const cleanAttributes = attributes => {
  if (!attributes.length) return []
  return attributes.reduce((arr, i) => {
    let newItem = { ...i }
    delete newItem.group_id
    delete newItem.place_id
    arr.push(newItem)
    return arr
  }, [])
}

export const updateAttributes = () => {
  return (dispatch, getState) => {
    const { token, brand, attributes, page } = getState()
    const endpoint = page.attributes.endpoint
    const deleted = attributes.items
      .filter(i => !i.attribute_value.length)
      .map(i => ({ ...i, attribute_value: '0.00' }))
    const updated = attributes.items.filter(i => i.attribute_value.length)
    const validated = validateValues(updated)
    dispatch({ type: ATTRIBUTES_UPDATE, payload: validated })
    const errors = validated.filter(i => i.error)
    if (errors.length) {
      const msg = 'One or more values is invalid. Please see below.'
      return dispatch(showAttributesErrors(msg))
    }
    let postRequest = null
    if (validated.length) {
      const cleanValidated = cleanAttributes(validated)
      postRequest = request(token, brand, endpoint, 'POST', cleanValidated)
    }
    let deleteRequest = null
    if (deleted.length) {
      const cleanDeleted = cleanAttributes(deleted)
      deleteRequest = request(token, brand, endpoint, 'DELETE', cleanDeleted)
    }
    Promise.all([postRequest, deleteRequest])
      .then(() => {
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ATTRIBUTES_CLEAR:
      return { ...initState }
    case ATTRIBUTES_LOADING:
      return { ...state, loading: true }
    case ATTRIBUTES_LOAD:
      return { ...state, ...action.payload, error: '', loading: false }
    case ATTRIBUTES_UPDATE:
      return { ...state, items: action.payload, error: '', loading: false }
    case ATTRIBUTES_ERRORS:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
