import { request } from '../lib/services'
import { flashMessage } from './messages'
import { clearPageError, handlePageError } from './page'
import { errMessages } from '../lib/errors'

const initState = {
  loading: false,
  data: null,
  amount: '',
  error: ''
}

export const REWARD_CLEAR = 'REWARD_CLEAR'
export const REWARD_LOADING = 'REWARD_LOADING'
export const REWARD_LOAD = 'REWARD_LOAD'
export const REWARD_ERROR = 'REWARD_ERROR'
export const REWARD_CREDIT = 'REWARD_CREDIT'

export const clearReward = () => ({ type: REWARD_CLEAR })
export const loadingReward = () => ({ type: REWARD_LOADING })
export const loadReward = data => ({ type: REWARD_LOAD, payload: data })
export const updateCredit = value => ({ type: REWARD_CREDIT, payload: value })
export const showRewardError = msg => ({ type: REWARD_ERROR, payload: msg })

export const fetchReward = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearPageError())
    dispatch(loadingReward())
    const endpoint = `/loyalty-customers/${page.id}?expand=true`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadReward(resp))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const addCredit = () => {
  return (dispatch, getState) => {
    const { token, brand, page, reward } = getState()
    const endpoint = `/loyalty-customers/${page.id}/credit`
    const values = { amount: reward.amount }
    return request(token, brand, endpoint, 'POST', values)
      .then(() => {
        dispatch(updateCredit(''))
        dispatch(fetchReward())
        dispatch(flashMessage('Credit updated!'))
      })
      .catch(err => {
        err.detail = errMessages.invalidAmount
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case REWARD_CLEAR:
      return { ...initState }
    case REWARD_LOADING:
      return { ...state, loading: true }
    case REWARD_LOAD:
      return { ...state, data: action.payload, loading: false, error: '' }
    case REWARD_ERROR:
      return { ...state, error: action.payload, loading: false }
    case REWARD_CREDIT:
      return { ...state, amount: action.payload }
    default:
      return state
  }
}
