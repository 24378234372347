import propTypes from 'prop-types'
import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { TableRow, TableRowDraggable } from './TableRow'
import ClipLoader from 'react-spinners/ClipLoader'
import { useLocation } from 'react-router-dom'
import TableRefresh from './TableRefresh'

export const reorder = (
  list,
  startIndex,
  endIndex,
  droppableId,
  sortBy,
  id,
  fields
) => {
  const [, parentId] = droppableId.split('droppable')
  const newList = parentId.length
    ? list.filter(i => i[id] === parseInt(parentId))[0].children
    : list.map(item => ({ ...item }))
  const [removed] = newList.splice(startIndex, 1)
  newList.splice(endIndex, 0, removed)
  const newOrder = newList.map((item, index) => {
    const otherFields = fields
      ? fields.reduce((obj, field) => {
          obj[field] = item[field]
          return obj
        }, {})
      : {}
    return { ...otherFields, [id]: item[id], [sortBy]: index + 1 }
  })
  return newOrder
}

const Table = ({
  classes,
  fields,
  items,
  sorting,
  id,
  loading,
  listEndpoint,
  reorderList,
  updateItem,
  selectItem,
  isRelation,
  selectAll,
  refreshNeeded,
  refreshItems,
  csvEndpoint
}) => {
  const isDraggable = sorting && sorting.sortType === 'order' && !sorting.noDrag
  const { pathname } = useLocation()
  const hasItems = items.length && !refreshNeeded ? true : false

  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) return
    // dropped in the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    const newItems = reorder(
      items,
      source.index,
      destination.index,
      source.droppableId,
      sorting.sortBy,
      id,
      sorting.fields
    )
    reorderList(listEndpoint, newItems)
  }

  const handleChange = evt => {
    if (evt.target.type === 'checkbox') {
      const itemId = parseInt(evt.target.id) || evt.target.id
      const isSelected = evt.target.checked
      selectItem(itemId, isSelected)
    } else {
      const [id, field] = evt.target.id.split('-')
      const value = evt.target.value
      updateItem(parseInt(id) || id, field, value)
    }
    evt.target.blur()
  }

  const selectAllCheckboxes = evt => {
    evt.preventDefault()
    selectAll()
    evt.target.blur()
  }

  return (
    <div className="table__wrapper">
      <table className={`${classes || ''}`}>
        <thead>
          <tr>
            {fields &&
              fields.map((field, index) => {
                return field.selectAll ? (
                  <th key={`${field.field}-${index}`}>
                    <button
                      className="btn-link"
                      type="button"
                      onClick={selectAllCheckboxes}
                    >
                      {field.name}
                    </button>
                  </th>
                ) : (
                  <th key={`${field.field}-${index}`}>{field.name}</th>
                )
              })}
            {isDraggable && <th>&nbsp;</th>}
          </tr>
        </thead>
        {hasItems ? (
          isDraggable ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <tbody
                    ref={provided.innerRef}
                    className={`${
                      snapshot.isDraggingOver ? `-dragging-over` : ''
                    }`}
                  >
                    {items.map((item, index) => (
                      <TableRowDraggable
                        key={`${id}-${index}-${item[id]}`}
                        index={index}
                        item={item}
                        fields={fields}
                        endpoint={listEndpoint}
                        pathname={pathname}
                        id={id}
                        handler={handleChange}
                      />
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <tbody>
              {items.map((item, index) => {
                return (
                  <TableRow
                    key={`${id}-${index}-${item[id]}`}
                    index={index}
                    item={item}
                    fields={fields || []}
                    endpoint={listEndpoint}
                    pathname={pathname}
                    id={id}
                    handler={handleChange}
                  />
                )
              })}
            </tbody>
          )
        ) : !refreshNeeded ? (
          <tbody>
            {!loading ? (
              <tr className="empty-table">
                <td colSpan={fields.length}>
                  There {"aren't"} any items matching the applied filters.
                </td>
              </tr>
            ) : null}
          </tbody>
        ) : null}
      </table>
      {loading ? (
        isRelation ? (
          <div className="table__loading -relations">
            <ClipLoader size={24} color={'#5a5aff'} />
          </div>
        ) : (
          <div className="table__loading">
            <ClipLoader size={36} color={'#5a5aff'} />
            <span>Retrieving items...</span>
          </div>
        )
      ) : null}
      {refreshNeeded && (
        <TableRefresh refreshItems={refreshItems} csvEndpoint={csvEndpoint} />
      )}
    </div>
  )
}

Table.displayName = 'Table'
Table.propTypes = {
  classes: propTypes.string,
  fields: propTypes.array,
  items: propTypes.array,
  sorting: propTypes.object,
  id: propTypes.string,
  loading: propTypes.bool,
  listEndpoint: propTypes.string,
  reorderList: propTypes.func,
  updateItem: propTypes.func,
  selectItem: propTypes.func,
  selectAll: propTypes.func,
  isRelation: propTypes.bool
}

export default Table
