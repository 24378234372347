import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import { weekdays, weekdaysLower, weekdaysShort } from '../lib/constants'
import {
  updateFilter,
  updateRestriction,
  updateItem,
  updateWeekday,
  updateAll,
  updateRestrictions,
  regenerateMenus
} from '../reducers/dailyItems'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'
import { CheckboxSimple } from './FormInputs'

const makeRestricted = restrictions => {
  if (!restrictions) return {}
  return restrictions.reduce((obj, i) => {
    const days = weekdaysLower.filter(weekday => i[weekday])
    obj[i.item_id] = days
    return obj
  }, {})
}

const makeDailyItems = (items, restricted, disabled) => {
  if (!items) return []
  return items.map(item => {
    return {
      name: item.short_name,
      value: item.item_id,
      cols: weekdaysLower.map(weekday => {
        const restrictedDays = restricted[item.item_id] || []
        return {
          id: `${item.item_id}-${weekday}`,
          checked: restrictedDays.indexOf(weekday) === -1,
          disabled: disabled.indexOf(item.item_id) >= 0
        }
      })
    }
  })
}

class DailyItems extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
    this.regenerateButton = React.createRef()
  }

  static propTypes = {
    // state.page.dailyItems
    filters: propTypes.array,
    // state.dailyItems
    items: propTypes.array,
    restrictions: propTypes.array,
    disabled: propTypes.array,
    error: propTypes.string,
    loading: propTypes.bool,
    hasChanges: propTypes.bool,
    hasUpdates: propTypes.bool,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    updateRestriction: propTypes.func,
    updateItem: propTypes.func,
    updateWeekday: propTypes.func,
    updateAll: propTypes.func,
    updateRestrictions: propTypes.func,
    regenerateMenus: propTypes.func
  }

  handleFilter = evt => {
    const { id, value } = evt.target
    const filter = { field: id, value: value }
    this.props.updateFilter(filter)
    evt.target.blur()
  }

  handleCheckbox = evt => {
    const { target } = evt
    const [itemId, weekday] = target.id.split('-')
    this.props.updateRestriction(parseInt(itemId), weekday, target.checked)
    evt.target.blur()
  }

  handleRow = evt => {
    evt.preventDefault()
    this.props.updateItem(parseInt(evt.target.id))
    evt.target.blur()
  }

  handleColumn = evt => {
    evt.preventDefault()
    this.props.updateWeekday(evt.target.id)
    evt.target.blur()
  }

  handleAll = evt => {
    evt.preventDefault()
    this.props.updateAll()
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateRestrictions()
    this.submitButton.current.blur()
  }

  handleRegenerate = evt => {
    evt.preventDefault()
    this.props.regenerateMenus()
    this.regenerateButton.current.blur()
  }

  render() {
    const {
      filters,
      loading,
      items,
      disabled,
      restrictions,
      hasChanges,
      hasUpdates
    } = this.props
    const restricted = makeRestricted(restrictions)
    const rows = makeDailyItems(items, restricted, disabled)
    // console.log(JSON.stringify(restricted, null, 2))
    // console.log(JSON.stringify(rows, null, 2))
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        {loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        {items && !items.length ? (
          <p>
            There {"aren't"} any items in this group. Please adjust your
            filters.
          </p>
        ) : null}
        <TransitionGroup>
          {items && items.length && rows ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="avails daily-items">
                {hasUpdates && (
                  <p className="content__note -alert">
                    Changes have been made. When {"you're"} done with all of
                    your updates, please regenerate your menus via the button at
                    the bottom of the page.
                  </p>
                )}
                <form id="form" className="form" onSubmit={this.handleSubmit}>
                  <div className="table-scroll">
                    <table className="table--avails table--light">
                      <thead>
                        <tr>
                          <th>
                            <button onClick={this.handleAll}>Select All</button>
                          </th>
                          {weekdays.map(weekday => (
                            <th key={weekday}>
                              <button
                                id={weekday.toLowerCase()}
                                onClick={this.handleColumn}
                              >
                                {weekdaysShort[weekday.toUpperCase()]}
                              </button>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map(row => (
                          <tr key={`${row.name}-${row.value}`}>
                            <td>
                              <button
                                id={`${row.value}`}
                                onClick={this.handleRow}
                              >
                                {row.name}
                              </button>
                            </td>
                            {row.cols.map(col => (
                              <td key={col.id}>
                                <CheckboxSimple
                                  id={col.id}
                                  checked={col.checked}
                                  disabled={col.disabled}
                                  handler={this.handleCheckbox}
                                  klass="checkbox__removed"
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value="Update Availabilities"
                      disabled={!hasChanges}
                      ref={this.submitButton}
                    />
                    <input
                      className="btn btn--secondary"
                      type="button"
                      value="Regenerate Menus"
                      onClick={this.handleRegenerate}
                      disabled={!hasUpdates}
                      ref={this.regenerateButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

DailyItems.displayName = 'DailyItems'

export default connect(
  state => ({
    ...state.dailyItems
  }),
  {
    updateFilter,
    updateRestriction,
    updateItem,
    updateWeekday,
    updateAll,
    updateRestrictions,
    regenerateMenus
  }
)(DailyItems)
