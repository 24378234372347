import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteItem } from '../../reducers/listOfItems'

class ListOfItemsDelete extends Component {
  static propTypes = {
    index: propTypes.number,
    deleteItem: propTypes.func
  }

  handleDelete = evt => {
    evt.preventDefault()
    this.props.deleteItem(this.props.index)
    evt.target.blur()
  }

  render() {
    return (
      <button className="btn" onClick={this.handleDelete}>
        Delete
      </button>
    )
  }
}

ListOfItemsDelete.displayName = 'ListOfItemsDelete'

export default connect(null, { deleteItem })(ListOfItemsDelete)
