/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import PageLoading from './PageLoading'
import {
  ReceiptSection,
  ReceiptItem,
  ReceiptItemRow,
  formatPrice
} from './ReceiptSection'
import {
  addSurcharges,
  removeSurcharge,
  addDiscounts,
  removeDiscount,
  addPromoCodes,
  removePromoCode,
  editTip,
  resetCheckout,
  toggleEatingUtensils,
  toggleServingUtensils,
  toggleTaxExempt,
  updateTaxExemptId,
  updatePersonCount,
  updateNotes,
  updateNotesInternal,
  addTender,
  removeTender,
  submitOrder,
  submittingOrder
} from '../reducers/order'
import { Checkbox, Input, Textarea } from './FormInputs'
import { tenderTypeOptions, tenderTypesMap } from '../lib/constants'
import { tendersTotalAndBalance } from '../lib/helpersOrder'
// import { XCircle } from 'react-feather'

const makeTotals = (
  order,
  tenders,
  tipSettings,
  handleSurcharge,
  handleDiscount,
  handlePromoCode,
  handleTender,
  handleTip
) => {
  const surcharges = order.surcharges.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent',
    id: i.surcharge_id,
    handler: handleSurcharge
  }))
  const discounts = order.discounts.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent',
    id: i.discount_id,
    handler: i.is_promo_code ? handlePromoCode : handleDiscount
  }))
  const taxes = order.taxes.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent'
  }))
  const pendingTenders = tenders.map((i, index) => ({
    label: i.cardName || tenderTypesMap[i.tenderType],
    value: formatPrice(i.amount),
    classes: '-indent',
    id: index,
    handler: handleTender
  }))
  const [tendersTotal, balance] = tendersTotalAndBalance(tenders, order.total)
  const preTaxTotal = [order.subtotal, order.surcharge, order.discount]
    .reduce((t, i) => t + parseFloat(i), 0.0)
    .toFixed(2)
  const hasTip = tipSettings && tipSettings.has_gratuity
  const tipLabel = hasTip
    ? 'Tip'
    : 'Tip -- disabled, see revenue center settings'
  const tipHandler = hasTip ? handleTip : null
  const totals = [
    { label: 'Order Summary', value: ' ' },
    { label: 'Subtotal', value: formatPrice(order.subtotal) },
    ...surcharges,
    { label: 'Surcharge', value: formatPrice(order.surcharge) },
    ...discounts,
    { label: 'Discount', value: formatPrice(order.discount) },
    { label: 'Total before Tax & Tip', value: `$${preTaxTotal}` },
    // { label: 'Gift Cards', value: formatPrice(order.gift_card || '0.00) },
    ...taxes,
    {
      label: 'Tax',
      value: formatPrice(order.tax),
      classes: taxes.length ? '' : ''
    },
    {
      label: tipLabel,
      value: formatPrice(order.tip),
      edit: tipHandler,
      classes: hasTip ? '' : '-disabled'
    },
    // { label: 'Shipping', value: formatPrice(order.shipping) },
    { label: 'Total after Tax & Tip', value: formatPrice(order.total) },
    ...pendingTenders,
    { label: 'Total Tendered', value: formatPrice(tendersTotal) },
    { label: 'Remaining Balance', value: formatPrice(balance) }
  ]
  return { totals }
}

const CheckoutCart = ({ order }) => {
  return (
    <div className="receipt__section">
      {order.cart ? (
        <>
          <div className="receipt__row">
            <ReceiptItem
              name="Order Items"
              price="Price"
              quantity="Qty"
              price_total="Total"
              isHeader={true}
            />
          </div>
          {order.cart.map((item, index) => (
            <ReceiptItemRow
              key={`${index + 1}-${item.id}`}
              item={item}
              itemNo={index + 1}
            />
          ))}
          <div className="receipt__row -totals">
            <ReceiptItem name="Cart Total" price_total={order.subtotal} />
          </div>
        </>
      ) : null}
    </div>
  )
}

CheckoutCart.displayName = 'CheckoutCart'
CheckoutCart.propTypes = {
  order: propTypes.object
}

const formFields = {
  eatingUtensils: {
    field: 'eatingUtensils',
    type: 'checkbox',
    label: 'Eating Utensils'
  },
  servingUtensils: {
    field: 'servingUtensils',
    type: 'checkbox',
    label: 'Serving Utensils'
  },
  isTaxExempt: {
    field: 'isTaxExempt',
    type: 'checkbox',
    label: 'Tax Exempt'
  },
  taxExemptId: {
    field: 'taxExemptId',
    type: 'text',
    label: 'Tax Exempt ID'
  },
  personCount: {
    field: 'count',
    type: 'text',
    label: 'Person Count',
    placeholder: '0'
  },
  notes: {
    field: 'notes',
    type: 'textarea',
    label: 'Order Notes'
  },
  notesInternal: {
    field: 'notes_internal',
    type: 'textarea',
    label: 'Internal Notes'
  }
}

const TenderTypes = ({ tenderTypes, handler, disabled }) => {
  if (!tenderTypes) return null
  const notValid = ['GIFT_CARD', 'APPLE_PAY', 'GOOGLE_PAY']
  const filtered = tenderTypeOptions
    .filter(i => tenderTypes.includes(i.value))
    .filter(i => !notValid.includes(i.value))
  return (
    <ul className="btn-list -center">
      {filtered.map(i => (
        <li key={i.value}>
          <button
            className="btn"
            onClick={evt => handler(evt, i.value)}
            disabled={disabled}
          >
            <span>Add {i.name} Tender</span>
          </button>
        </li>
      ))}
    </ul>
  )
}

TenderTypes.displayName = 'TenderTypes'
TenderTypes.propTypes = {
  tenderTypes: propTypes.array,
  handler: propTypes.func,
  disabled: propTypes.bool
}

class Checkout extends Component {
  constructor(props) {
    super(props)
    this.downloadLink = React.createRef()
  }

  static propTypes = {
    // state.order
    loading: propTypes.bool,
    loadingQuote: propTypes.bool,
    timezone: propTypes.string,
    order: propTypes.object,
    tipSettings: propTypes.object,
    eatingUtensils: propTypes.bool,
    servingUtensils: propTypes.bool,
    isTaxExempt: propTypes.bool,
    taxExemptId: propTypes.string,
    personCount: propTypes.number,
    notes: propTypes.string,
    notesInternal: propTypes.string,
    tenderTypes: propTypes.array,
    tenders: propTypes.array,
    tendersApplied: propTypes.array,
    errors: propTypes.object,
    // mapdispatchtoprops
    addSurcharges: propTypes.func,
    removeSurcharge: propTypes.func,
    addDiscounts: propTypes.func,
    removeDiscount: propTypes.func,
    addPromoCodes: propTypes.func,
    removePromoCode: propTypes.func,
    editTip: propTypes.func,
    resetCheckout: propTypes.func,
    toggleEatingUtensils: propTypes.func,
    toggleServingUtensils: propTypes.func,
    toggleTaxExempt: propTypes.func,
    updateTaxExemptId: propTypes.func,
    updatePersonCount: propTypes.func,
    updateNotes: propTypes.func,
    updateNotesInternal: propTypes.func,
    addTender: propTypes.func,
    removeTender: propTypes.func,
    submitOrder: propTypes.func,
    submittingOrder: propTypes.func
  }

  handleSurcharges = evt => {
    evt.preventDefault()
    this.props.addSurcharges()
    evt.target.blur()
  }

  removeSurcharge = (evt, surchargeId) => {
    evt.preventDefault()
    this.props.removeSurcharge(surchargeId)
    evt.target.blur()
  }

  handleDiscounts = evt => {
    evt.preventDefault()
    this.props.addDiscounts()
    evt.target.blur()
  }

  removeDiscount = (evt, discountId) => {
    evt.preventDefault()
    this.props.removeDiscount(discountId)
    evt.target.blur()
  }

  handlePromoCodes = evt => {
    evt.preventDefault()
    this.props.addPromoCodes()
    evt.target.blur()
  }

  handleTip = evt => {
    evt.preventDefault()
    this.props.editTip()
    evt.target.blur()
  }

  removePromoCode = (evt, discountId) => {
    evt.preventDefault()
    const promoCode = this.props.order.discounts.filter(
      i => i.discount_id === parseInt(discountId)
    )[0]
    this.props.removePromoCode(promoCode.name)
    evt.target.blur()
  }

  handleReset = evt => {
    evt.preventDefault()
    this.props.resetCheckout()
    evt.target.blur()
  }

  handleEatingUtensils = evt => {
    this.props.toggleEatingUtensils()
    evt.target.blur()
  }

  handleServingUtensils = evt => {
    this.props.toggleServingUtensils()
    evt.target.blur()
  }

  handleTaxExempt = evt => {
    this.props.toggleTaxExempt()
    evt.target.blur()
  }

  handleTaxExemptId = evt => {
    const { value } = evt.target
    this.props.updateTaxExemptId(value)
  }

  handlePersonCount = evt => {
    const { value } = evt.target
    const count = isNaN(parseInt(value)) ? '' : parseInt(value)
    this.props.updatePersonCount(count)
  }

  handleNotes = evt => {
    this.props.updateNotes(evt.target.value)
  }

  handleNotesInternal = evt => {
    this.props.updateNotesInternal(evt.target.value)
  }

  handleTenders = (evt, tenderType) => {
    evt.preventDefault()
    this.props.addTender(tenderType)
    evt.target.blur()
  }

  removeTender = (evt, index) => {
    evt.preventDefault()
    this.props.removeTender(index)
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.submitOrder(true)
    evt.target.blur()
  }

  handleSave = evt => {
    evt.preventDefault()
    this.props.submitOrder(false, true)
    evt.target.blur()
  }

  // testSubmitting = evt => {
  //   evt.preventDefault()
  //   this.props.submittingOrder()
  //   evt.target.blur()
  // }

  handleQuote = evt => {
    evt.preventDefault()
    this.props.submitOrder(false, false, this.downloadLink)
    evt.target.blur()
  }

  render() {
    const {
      order,
      tenders,
      tipSettings,
      loading,
      loadingQuote,
      eatingUtensils,
      servingUtensils,
      isTaxExempt,
      taxExemptId,
      personCount,
      notes,
      notesInternal,
      tenderTypes
    } = this.props
    const { totals } = makeTotals(
      order,
      tenders,
      tipSettings,
      this.removeSurcharge,
      this.removeDiscount,
      this.removePromoCode,
      this.removeTender,
      this.handleTip
    )
    const [, balance] = tendersTotalAndBalance(tenders, order.total)
    const tendersDisabled = parseFloat(balance) <= 0
    return (
      <div className="checkout">
        <div className="checkout__main">
          <PageLoading loading={loading} />
          <TransitionGroup>
            {!loading && order ? (
              <CSSTransition {...cssTransitionFade}>
                <div className="checkout__summary">
                  <ReceiptSection rows={totals} isDollars={true} />
                  <CheckoutCart order={order} />
                </div>
              </CSSTransition>
            ) : null}
          </TransitionGroup>
        </div>
        <div className="checkout__sidebar">
          <div className="checkout__charges">
            <ul className="btn-list -center">
              <li>
                <button className="btn" onClick={this.handleSurcharges}>
                  <span>Add Surcharges</span>
                </button>
              </li>
              <li>
                <button className="btn" onClick={this.handleDiscounts}>
                  <span>Add Discounts</span>
                </button>
              </li>
              <li>
                <button className="btn" onClick={this.handlePromoCodes}>
                  <span>Add a Promo Code</span>
                </button>
              </li>
              <li>
                <button className="btn" onClick={this.handleReset}>
                  <span>Reset Surcharges & Discounts</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="checkout__form">
            <Checkbox
              field={formFields.eatingUtensils}
              value={eatingUtensils}
              handler={this.handleEatingUtensils}
            />
            <Checkbox
              field={formFields.servingUtensils}
              value={servingUtensils}
              handler={this.handleServingUtensils}
            />
            <Checkbox
              field={formFields.isTaxExempt}
              value={isTaxExempt}
              handler={this.handleTaxExempt}
            />
            <Input
              field={formFields.taxExemptId}
              value={taxExemptId}
              handler={this.handleTaxExemptId}
            />
            <Input
              field={formFields.personCount}
              value={personCount}
              handler={this.handlePersonCount}
            />
            <Textarea
              field={formFields.notes}
              value={notes}
              handler={this.handleNotes}
            />
            <Textarea
              field={formFields.notesInternal}
              value={notesInternal}
              handler={this.handleNotesInternal}
            />
          </div>
          <div className="checkout__tenders">
            <TenderTypes
              tenderTypes={tenderTypes}
              handler={this.handleTenders}
              disabled={tendersDisabled}
            />
          </div>
          <div className="checkout__submit">
            <ul className="btn-list -center -dark">
              {/* <li>
                <button className="btn" onClick={this.testSubmitting}>
                  Submiting Order
                </button>
              </li> */}
              <li>
                <button
                  className="btn"
                  onClick={this.handleSubmit}
                  disabled={parseFloat(balance) !== 0}
                >
                  Submit Order
                </button>
              </li>
              <li>
                <button className="btn" onClick={this.handleSave}>
                  Save Order
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={this.handleQuote}
                  disabled={loadingQuote}
                >
                  {loadingQuote ? 'Generating Quote...' : 'Create Quote'}
                </button>
              </li>
            </ul>
            <a ref={this.downloadLink} href="#" target="_blank" />
          </div>
        </div>
      </div>
    )
  }
}

Checkout.displayName = 'Checkout'

export default connect(
  state => ({
    ...state.order
  }),
  {
    addSurcharges,
    removeSurcharge,
    addDiscounts,
    removeDiscount,
    addPromoCodes,
    removePromoCode,
    editTip,
    resetCheckout,
    toggleEatingUtensils,
    toggleServingUtensils,
    toggleTaxExempt,
    updateTaxExemptId,
    updatePersonCount,
    updateNotes,
    updateNotesInternal,
    addTender,
    removeTender,
    submitOrder,
    submittingOrder
  }
)(Checkout)
