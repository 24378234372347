import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import PageLoading from './PageLoading'
import { formatPrice, ReceiptSection, ReceiptItems } from './ReceiptSection'
import { cleanPhone } from '../lib/helpers'
import { timezoneMap } from '../lib/constants'
import { zonedDateStrToDateStr } from '../lib/helpersDatetime'
import BarLoader from 'react-spinners/BarLoader'
import StickyNav from './StickyNav'
import { Star } from 'react-feather'

const makeLocalOrderNo = order => {
  if (order.daily_id) return order.daily_id
  if (!order.order_id) return null
  return `${order.pos_server_id}--${order.order_id}`
}

const Stars = ({ rating }) => {
  const arr = [...Array(rating).keys()]
  return (
    <span className="receipt-stars">
      {arr.map(key => (
        <span key={key}>
          <Star size={null} />
        </span>
      ))}
    </span>
  )
}

Stars.displayName = 'Stars'
Stars.propTypes = {
  rating: propTypes.number
}

export const makeReceipt = order => {
  if (!order) return {}
  const tz = timezoneMap[order.timezone]
  const fmtDt = zonedDateStrToDateStr
  const typeClass = `-${order.receipt_type.toLowerCase()}`
  const statusClass = `-${order.receipt_status.toLowerCase()}`
  const tenderStatusClass = `-${order.tender_status.toLowerCase()}`
  const receiptType = order.receipt_type.toUpperCase()
  const p = order.order_properties
  let orderIds = [
    { label: 'Order ID', value: order.receipt_id },
    { label: 'Order UUID', value: order.receipt_uuid },
    {
      label: 'Transaction Type',
      value: receiptType,
      classes: typeClass
    }
  ]
  const parentLabel =
    receiptType === 'REFUND' ? 'Original Order ID of Sale' : 'Parent Order ID'
  if (order.parent_receipt_id) {
    const parentReceiptId = {
      label: parentLabel,
      value: order.parent_receipt_id,
      path: '/orders/:id',
      id: order.parent_receipt_id
    }
    orderIds.push(parentReceiptId)
  }
  let summary = [
    { label: 'Summary', value: ' ' },
    ...orderIds,
    { label: 'Order Type', value: order.order_type },
    {
      label: 'Revenue Center',
      value: order.location.name,
      path: '/manage-orders/:id',
      id: order.store_id
    },
    { label: 'Service Type', value: order.service_type_name },
    { label: 'Requested At', value: fmtDt(order.requested_at, tz) },
    { label: 'Order Total', value: formatPrice(order.total) },
    {
      label: 'Order Status',
      value: order.receipt_status.toUpperCase(),
      classes: statusClass
    },
    {
      label: 'Payment Status',
      value: order.tender_status.toUpperCase(),
      classes: tenderStatusClass
    },
    { label: 'POS Terminal', value: order.pos_terminal },
    { label: 'POS Order Id', value: makeLocalOrderNo(order) },
    { label: 'Created At', value: fmtDt(order.created_at, tz) },
    {
      label: 'Business Date',
      value: fmtDt(order.requested_at, tz, 'yyyy-MM-dd')
    },
    { label: 'Weekday', value: order.weekday },
    { label: 'Source', value: order.order_source },
    { label: 'Source Order ID', value: order.external_id },
    { label: 'Channel', value: order.channel_type },
    { label: 'Device', value: order.device_type }
  ]
  if (p) {
    const channelInfo = [
      { label: '3rd Party', value: p.channel_name },
      { label: '3rd Party Order ID', value: p.channel_ext_id }
    ]
    summary = [...summary, ...channelInfo]
  }
  const c = order.customer
  const customer = c
    ? [
        { label: 'Customer', value: ' ' },
        {
          label: 'Name',
          value: [c.first_name, c.last_name].join(' '),
          path: '/customers/:id',
          id: order.customer_id
        },
        { label: 'Email', value: c.email },
        { label: 'Phone', value: cleanPhone(c.phone) },
        { label: 'Company', value: c.company }
      ]
    : [
        { label: 'Customer', value: ' ' },
        { label: 'no customer associated with this order', value: ' ' }
      ]
  const a = order.address
  const address = a
    ? [
        { label: 'Address', value: ' ' },
        {
          label: 'Street & Unit',
          value: `${a.street}${a.unit && ', ' + a.unit}`
        },
        {
          label: 'City, State & Zip',
          value: `${a.city}, ${a.state} ${a.postal_code}`
        },
        { label: 'Company', value: a.company },
        {
          label: 'Contact & Phone',
          value: `${a.contact}${a.phone && ' @ ' + cleanPhone(a.phone)}`
        }
      ]
    : [
        { label: 'Address', value: ' ' },
        { label: 'no address associated with this order', value: ' ' }
      ]
  const notes = p
    ? [
        { label: 'Order Details', value: ' ' },
        {
          label: 'Eating Utensils',
          value: p.eating_utensils ? 'Yes' : 'No'
        },
        {
          label: 'Serving Utensils',
          value: p.serving_utensils ? 'Yes' : 'No'
        },
        {
          label: 'Group Order',
          value: p.is_group ? 'Yes' : 'No'
        },
        { label: 'No. of People', value: p.person_count },
        { label: 'Notes', value: p.notes, classes: '-full-width' },
        {
          label: 'Internal Notes',
          value: p.notes_internal,
          classes: '-full-width'
        }
      ]
    : null
  const stars = order.rating ? <Stars rating={order.rating.rating} /> : null
  const rating = order.rating
    ? [
        { label: 'Rating', value: ' ' },
        { label: 'Rating', value: stars },
        {
          label: 'Comments',
          value: order.rating.comments,
          classes: '-full-width'
        }
      ]
    : [
        { label: 'Rating', value: ' ' },
        { label: 'No rating for this order', value: ' ' }
      ]
  const surcharges = order.surcharges.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent'
  }))
  const discounts = order.discounts.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent'
  }))
  const giftCards = order.gift_cards.map(i => ({
    label: i.card_number,
    value: formatPrice(i.amount),
    labelPath: '/gift-cards/:id',
    id: i.gift_card_id,
    classes: '-indent'
  }))
  const taxes = order.taxes.map(i => ({
    label: i.name,
    value: formatPrice(i.amount),
    classes: '-indent'
  }))
  const totals = [
    { label: 'Totals', value: ' ', classes: '-first' },
    { label: 'Subtotal', value: formatPrice(order.subtotal) },
    ...surcharges,
    { label: 'Surcharge', value: formatPrice(order.surcharge) },
    ...discounts,
    { label: 'Discount', value: formatPrice(order.discount) },
    {
      label: 'Total before Tax & Tip',
      value: formatPrice(order.total_with_discount),
      classes: '-subtotals'
    },
    ...giftCards,
    { label: 'Gift Cards', value: formatPrice(order.gift_card || '0.00') },
    ...taxes,
    { label: 'Tax', value: formatPrice(order.tax) },
    { label: 'Tip', value: formatPrice(order.tip) },
    { label: 'Shipping', value: formatPrice(order.shipping) },
    {
      label: 'Total after Tax & Tip',
      value: formatPrice(order.total),
      classes: '-totals'
    }
  ]
  return { summary, customer, address, notes, totals, rating }
}

const makeTender = (tender, orderTz) => {
  const tz = timezoneMap[orderTz]
  const tenderStatus =
    tender.tender_status || (tender.is_void ? 'Void' : 'Refunded')
  const statusClass = `-${tenderStatus.toLowerCase()}`
  const tenderType =
    tender.tender_type_id === 5 ? 'GIFT CARD' : tender.tender_type.toUpperCase()
  const fmtDt = zonedDateStrToDateStr
  const title = 'Tender Type'
  let tenderTypeRow = [{ label: 'Tender ID', value: tender.tender_id }]
  if (tender.refund_receipt_id) {
    const refundReceiptRows = [
      {
        label: `Refund Receipt ID`,
        value: tender.refund_receipt_id,
        path: '/orders/:id',
        id: tender.refund_receipt_id
      }
    ]
    tenderTypeRow = [...tenderTypeRow, ...refundReceiptRows]
  }
  let rows = [
    ...tenderTypeRow,
    { label: title, value: tenderType },
    { label: 'Amount', value: formatPrice(tender.amount) },
    {
      label: 'Status',
      value: tenderStatus,
      classes: statusClass
    },
    { label: 'Created At', value: fmtDt(tender.created_at, tz) }
  ]
  let extraRows = []
  if (tenderType === 'CASH') {
    extraRows = [
      { label: 'Cash Back', value: formatPrice(-parseFloat(tender.cash_back)) }
    ]
  } else if (tenderType === 'CREDIT') {
    extraRows = [
      { label: 'Merchant ID', value: tender.merchant_id },
      { label: 'Card Type / Last 4', value: tender.card_name },
      { label: 'Masked', value: tender.masked },
      { label: 'Authorization Code', value: tender.authcode },
      { label: 'Retrieval Reference', value: tender.retref },
      {
        label: 'Charged At',
        value: tender.charged_at ? fmtDt(tender.charged_at, tz) : 'n/a'
      },
      { label: 'Batch ID', value: tender.batchid || 'n/a' }
    ]
  } else if (['LEVELUP', 'GRUBHUB'].includes(tenderType)) {
    extraRows = [
      { label: 'UUID', value: tender.completed_uuid || 'n/a' },
      {
        label: 'Discount Applied',
        value: formatPrice(-parseFloat(tender.discount))
      }
    ]
  } else if (tenderType === 'COMO') {
    extraRows = [
      { label: 'Payment Method', value: tender.proposed_uuid },
      { label: 'UUID', value: tender.completed_uuid || 'n/a' }
    ]
  } else if (tenderType === 'HOUSE ACCOUNT') {
    extraRows = [
      { label: 'House Account No.', value: tender.house_account_id },
      {
        label: 'House Account Name',
        value: tender.house_account_name,
        path: '/house-accounts/:id/orders',
        id: tender.house_account_id
      }
    ]
  } else if (tenderType === 'GIFT CARD') {
    extraRows = [
      {
        label: 'Gift Card Number',
        value: tender.gift_card_number,
        path: '/gift-cards/:id',
        id: tender.gift_card_id
      }
    ]
  }
  rows = [...rows, ...extraRows]
  return rows
}

const makeTenders = (title, tenders, orderTz, parentReceiptId) => {
  let rows = [{ label: title, value: ' ' }]
  if (parentReceiptId) {
    rows.push({
      label: `Please see the original receipt for refund transactions`,
      value: 'review refunds',
      path: '/orders/:id',
      id: parentReceiptId
    })
    return rows
  }
  tenders.length
    ? tenders.forEach((tender, index) => {
        const tenderRows = makeTender(tender, orderTz)
        if (index) {
          const spacers = [
            { label: '', value: '' },
            { label: '', value: '' }
          ]
          rows = rows.concat(spacers)
        }
        rows = rows.concat(tenderRows)
      })
    : rows.push({
        label: `No ${title.toLowerCase()} for this order`,
        value: ' '
      })
  return rows
}

const ReceiptUpdating = () => {
  return (
    <div className="receipt__updating">
      <div className="receipt__updating__loader">
        <BarLoader size={44} color={'#5a5aff'} />
        <span>Working...</span>
      </div>
    </div>
  )
}

ReceiptUpdating.displayName = 'ReceiptUpdating'

const navItems = [
  'Summary',
  'Customer',
  'Rating',
  'Address',
  'Order Details',
  'Items',
  'Totals',
  'Tenders',
  'Refunds',
  'Voids'
]

class Receipt extends Component {
  static propTypes = {
    loading: propTypes.bool,
    updating: propTypes.bool,
    data: propTypes.object,
    error: propTypes.string
  }

  render() {
    const { loading, data = {}, updating } = this.props
    const { summary, customer, address, notes, totals, rating } =
      makeReceipt(data)
    const tenders = data
      ? makeTenders('Tenders', data.tenders, data.timezone)
      : []
    let refunds = data ? data.refunds.filter(i => !i.is_void) : null
    refunds = refunds
      ? makeTenders('Refunds', refunds, data.timezone, data.parent_receipt_id)
      : []
    let voids = data ? data.refunds.filter(i => i.is_void) : null
    voids = voids ? makeTenders('Voids', voids, data.timezone) : []
    return (
      <div className="content__main">
        <PageLoading loading={loading} />
        <TransitionGroup>
          {!loading && data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className={`receipt ${updating ? '-updating' : ''}`}>
                <StickyNav items={navItems} />
                {updating && <ReceiptUpdating />}
                <ReceiptSection rows={summary} />
                <ReceiptSection rows={customer} />
                <ReceiptSection rows={rating} />
                <ReceiptSection rows={address} />
                <ReceiptSection rows={notes} />
                <ReceiptItems order={data} />
                <ReceiptSection rows={totals} />
                <ReceiptSection rows={tenders} />
                <ReceiptSection rows={refunds} />
                <ReceiptSection rows={voids} />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Receipt.displayName = 'Receipt'

export default connect(
  state => ({
    ...state.receipt
  }),
  {}
)(Receipt)
