import propTypes from 'prop-types'
import React from 'react'
import DatePicker from 'react-datepicker'
import { Printer } from 'react-feather'
import { SearchInput } from '../Search'
import { dateStrToLocalDate } from '../../lib/helpersDatetime'

const DateFilter = ({ businessDate, handler }) => {
  const value = businessDate ? dateStrToLocalDate(businessDate) : null
  return (
    <label htmlFor="datepicker">
      <DatePicker
        showPopperArrow={false}
        dateFormat="yyyy-MM-dd"
        placeholderText="choose date"
        isClearable={true}
        selected={value}
        onChange={date => handler(date)}
      />
    </label>
  )
}

DateFilter.displayName = 'DateFilter'
DateFilter.propTypes = {
  businessDate: propTypes.string,
  handler: propTypes.func
}

const OrderInputs = ({
  print,
  preview,
  businessDate,
  handleDate,
  query,
  queryInput,
  queryEnter,
  queryClear
}) => (
  <div className="order-filters__group">
    <div className="order-filter order-filter--print">
      <button className="btn-link" onClick={print}>
        <span className="order-filter__icon">
          <Printer size={14} />
        </span>
        <span>Print</span>
      </button>{' '}
      or{' '}
      <button className="btn-link" onClick={preview}>
        <span>Preview</span>
      </button>
    </div>
    <div className="order-filter order-filter--inputs">
      <DateFilter businessDate={businessDate} handler={handleDate} />
      <SearchInput
        label={null}
        query={query}
        placeholder="find order"
        clearSize={14}
        handleInput={queryInput}
        handleEnter={queryEnter}
        handleClear={queryClear}
      />
    </div>
  </div>
)

OrderInputs.displayName = 'OrderInputs'
OrderInputs.propTypes = {
  print: propTypes.func,
  preview: propTypes.func,
  businessDate: propTypes.string,
  handleDate: propTypes.func,
  query: propTypes.string,
  queryInput: propTypes.func,
  queryEnter: propTypes.func,
  queryClear: propTypes.func
}

export default OrderInputs
