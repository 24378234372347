/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { closeModal } from '../reducers/modal'
import { deleteRelationListItem } from '../reducers/relation'
import ItemAvails from './ItemAvails'
import ClipLoader from 'react-spinners/ClipLoader'
import ModalClose from './modals/ModalClose'
import ModifierGroup from './modals/ModifierGroup'
import HouseAccountCustomer from './modals/HouseAccountCustomer'
import LoyaltyProgramCustomer from './modals/LoyaltyProgramCustomer'
import GiftCardCustomer from './modals/GiftCardCustomer'
import GroupItem from './modals/GroupItem'
import ItemModifierGroup from './modals/ItemModifierGroup'
import OrderType from './modals/OrderType'
import RevenueCenter from './modals/OrderRevenueCenter'
import Customer from './modals/OrderCustomer'
import Address from './modals/OrderAddress'
import MenuItem from './modals/MenuItem'
import OrderItem from './modals/OrderItem'
import Surcharges from './modals/Surcharges'
import Discounts from './modals/Discounts'
import PromoCodes from './modals/PromoCodes'
import OrderTip from './modals/OrderTip'
import TenderOther from './modals/OrderTenderOther'
import TenderCredit from './modals/OrderTenderCredit'
import TenderCreditNew from './modals/OrderTenderCreditNew'
import MissingItems from './modals/MissingItems'
import Punch from './modals/Punch'
import PunchNew from './modals/PunchNew'
import Submitting from './modals/Submitting'
import DeleteRelation from './modals/DeleteRelation'
import ChangePassword from './modals/ChangePassword'
import Login from './modals/Login'
import BlockedHours from './modals/BlockedHours'
import ApiRequest from './modals/ApiRequest'
import ImportError from './modals/ImportError'
import OrderDetails from './modals/OrderDetails'
import OrderRouter from './modals/OrderRouter'
import WaitTimes from './modals/WaitTimes'
import YoutubeModal from './modals/Youtube'
import Refund from './modals/Refund'
import OrderEdit from './modals/OrderEdit'
import OrderClose from './modals/OrderClose'
import OrderCancel from './modals/OrderCancel'
import OrderClosed from './modals/OrderClosed'
import EditRevenueCenter from './modals/EditRevenueCenter'
import EditServiceType from './modals/EditServiceType'
import EditRequestedAt from './modals/EditRequestedAt'
import EditPrepTime from './modals/EditPrepTime'
import EditTip from './modals/EditTip'
import EditTax from './modals/EditTax'
import EditNotesInternal from './modals/EditNotesInternal'
import ListOfItemsEdit from './modals/ListOfItemsEdit'
import ListOfItemsDelete from './modals/ListOfItemsDelete'
import ItemCategory from './modals/ItemCategory'
import DeleteRelated from './modals/DeleteRelated'
import MarkPaid from './modals/MarkPaid'
import DeletePunch from './modals/DeletePunch'
import MenuExtPayload from './modals/MenuExtPayload'
import OrderTimes from './modals/OrderTimes'
import DiscountCustomer from './modals/DiscountCustomer'
import RecommendedItem from './modals/RecommendedItem'
import DeclaredCash from './modals/DeclaredCash'
import UpdatePrepStatus from './modals/UpdatePrepStatus'

const makeModalType = (type, args, windowRef) => {
  switch (type) {
    case 'deleteRelation':
      return <DeleteRelation deleteId={args[0]} />
    case 'deleteRelated':
      return <DeleteRelated {...args} />
    case 'markPaid':
      return <MarkPaid {...args} />
    case 'apiRequest':
      return <ApiRequest requestId={args[0]} />
    case 'importError':
      return <ImportError errorId={args[0]} />
    case 'menuPayload':
      return <MenuExtPayload menuExtId={args[0]} />
    case 'houseAccountCustomer':
      return <HouseAccountCustomer />
    case 'loyaltyProgramCustomer':
      return <LoyaltyProgramCustomer />
    case 'discountCustomer':
      return <DiscountCustomer />
    case 'giftCardCustomer':
      return <GiftCardCustomer />
    case 'groupItem':
      return <GroupItem {...args} />
    case 'itemCategory':
      return <ItemCategory {...args} />
    case 'itemModifierGroup':
      return <ItemModifierGroup />
    case 'modifierGroup':
      return <ModifierGroup windowRef={windowRef} />
    case 'recommendedItem':
      return <RecommendedItem {...args} />
    case 'itemAvails':
      return <ItemAvails isModal={true} />
    case 'menuItem':
      return <MenuItem />
    case 'orderItem':
      return <OrderItem />
    case 'surcharges':
      return <Surcharges />
    case 'discounts':
      return <Discounts />
    case 'promoCodes':
      return <PromoCodes />
    case 'customer':
      return <Customer windowRef={windowRef} />
    case 'address':
      return <Address windowRef={windowRef} addresses={args[0]} />
    case 'orderType':
      return <OrderType />
    case 'orderTip':
      return <OrderTip windowRef={windowRef} />
    case 'revenueCenter':
      return <RevenueCenter windowRef={windowRef} revenueCenters={args[0]} />
    case 'tenderOther':
      return <TenderOther windowRef={windowRef} tenderType={args[0]} />
    case 'tenderCredit':
      return <TenderCredit windowRef={windowRef} />
    case 'tenderCreditNew':
      return <TenderCreditNew windowRef={windowRef} />
    case 'missingItems':
      return <MissingItems items={args} />
    case 'declaredCash':
      return <DeclaredCash windowRef={windowRef} data={args} />
    case 'punch':
      return <Punch windowRef={windowRef} data={args} />
    case 'deletePunch':
      return <DeletePunch {...args} />
    case 'punchNew':
      return <PunchNew windowRef={windowRef} />
    case 'blockedHours':
      return <BlockedHours windowRef={windowRef} />
    case 'orderTimes':
      return <OrderTimes windowRef={windowRef} />
    case 'orderDetails':
      return <OrderDetails order={args[0]} printerType={args[1]} />
    case 'orderRouter':
      return <OrderRouter args={args} />
    case 'orderEdit':
      return <OrderEdit {...args} />
    case 'orderClose':
      return <OrderClose {...args} />
    case 'orderCancel':
      return <OrderCancel {...args} />
    case 'orderClosed':
      return <OrderClosed {...args} />
    case 'editRevenueCenter':
      return <EditRevenueCenter windowRef={windowRef} {...args} />
    case 'editServiceType':
      return <EditServiceType windowRef={windowRef} {...args} />
    case 'editRequestedAt':
      return <EditRequestedAt windowRef={windowRef} {...args} />
    case 'editPrepTime':
      return <EditPrepTime windowRef={windowRef} {...args} />
    case 'updatePrepStatus':
      return <UpdatePrepStatus windowRef={windowRef} {...args} />
    case 'editTip':
      return <EditTip {...args} />
    case 'editTax':
      return <EditTax {...args} />
    case 'editNotesInternal':
      return <EditNotesInternal {...args} />
    case 'waitTimes':
      return <WaitTimes windowRef={windowRef} {...args} />
    case 'listOfItemsEdit':
      return <ListOfItemsEdit windowRef={windowRef} {...args} />
    case 'listOfItemsDelete':
      return <ListOfItemsDelete {...args} />
    case 'youtube':
      return <YoutubeModal {...args} />
    case 'refund':
      return <Refund {...args} />
    case 'submitting':
      return <Submitting />
    case 'changePassword':
      return <ChangePassword />
    case 'extendSession':
      return <Login />
    default:
      return null
  }
}

class Modal extends Component {
  constructor(props) {
    super(props)
    this.windowRef = React.createRef()
    this.state = { active: true, elements: [] }
  }

  static propTypes = {
    loading: propTypes.bool,
    hideClose: propTypes.bool,
    title: propTypes.string,
    subtitle: propTypes.string,
    errorMsg: propTypes.string,
    type: propTypes.string,
    args: propTypes.oneOfType([propTypes.array, propTypes.object]),
    classes: propTypes.string,
    content: propTypes.element,
    deleteItemId: propTypes.number,
    errors: propTypes.object,
    closeModal: propTypes.func,
    deleteRelationListItem: propTypes.func,
    close: propTypes.func
  }

  handleDelete = evt => {
    evt.preventDefault()
    this.props.deleteRelationListItem(this.props.deleteItemId)
    evt.target.blur()
  }

  handleClose = evt => {
    if (evt.target.id === 'modal-container') {
      this.props.closeModal()
    }
  }

  handleExit = () => {
    try {
      if (this.state.active) this.state.active.focus()
    } catch (err) {
      // console.log(err)
    }
  }

  handleFocus = () => {
    this.setState({ active: document.activeElement })
    const allElements = this.windowRef.current.querySelectorAll(
      'a[href], button, input, select, textarea'
    )
    this.setState({ elements: allElements })
    const inputs = this.windowRef.current.querySelectorAll(
      'input, select, textarea'
    )
    const firstElement = inputs.length
      ? inputs[0]
      : allElements
      ? allElements[0]
      : null
    if (firstElement) firstElement.focus()
  }

  handleTabKey = evt => {
    if (
      evt.keyCode === 9 &&
      this.windowRef.current &&
      this.state.elements.length
    ) {
      const activeElements = Array.from(this.state.elements).filter(
        i => !i.disabled
      )
      const firstElement = activeElements[0]
      const lastElement = activeElements[activeElements.length - 1]

      if (!evt.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        evt.preventDefault()
      }

      if (evt.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        evt.preventDefault()
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleTabKey, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleTabKey, false)
  }

  render() {
    const {
      title,
      subtitle,
      errorMsg,
      classes,
      content,
      type,
      args,
      deleteItemId,
      hideClose
    } = this.props
    const hasModal = title || type
    const modalType = type ? makeModalType(type, args, this.windowRef) : null
    return (
      <>
        <TransitionGroup component={null}>
          {this.props.loading || hasModal ? (
            <CSSTransition
              key="overlay"
              classNames="md"
              timeout={{ enter: 250, exit: 250 }}
            >
              <div className="modal__overlay" />
            </CSSTransition>
          ) : null}
        </TransitionGroup>
        <TransitionGroup component={null}>
          {this.props.loading ? (
            <CSSTransition
              key="loader"
              classNames="md"
              timeout={{ enter: 250, exit: 250 }}
            >
              <div
                id="modal-container"
                className="modal__container"
                onClick={this.handleClose}
              >
                <ClipLoader size={44} color={'#ffffff'} />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
        <TransitionGroup component={null}>
          {hasModal ? (
            <CSSTransition
              key="modal"
              classNames="md"
              timeout={{ enter: 250, exit: 250 }}
              onEntered={this.handleFocus}
              onExited={this.handleExit}
            >
              <div
                ref={this.windowRef}
                id="modal-container"
                className={`modal__container ${classes || ''}`}
                onClick={this.handleClose}
              >
                <div className="modal">
                  {!hideClose && <ModalClose close={this.props.closeModal} />}
                  {title === 'custom' ? (
                    modalType
                  ) : (
                    <div className="modal__content">
                      {(title || subtitle) && (
                        <div className="modal__header">
                          {title && <h2>{title}</h2>}
                          {subtitle && <p>{subtitle}</p>}
                        </div>
                      )}
                      <div className="modal__body">
                        {errorMsg ? (
                          <p>{errorMsg}</p>
                        ) : content ? (
                          content
                        ) : type ? (
                          modalType
                        ) : deleteItemId ? (
                          <button className="btn" onClick={this.handleDelete}>
                            Delete
                          </button>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>
    )
  }
}

Modal.displayName = 'Modal'

export default connect(
  state => ({
    ...state.modal
  }),
  { closeModal, deleteRelationListItem }
)(Modal)
