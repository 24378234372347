import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../reducers/modal'

class OrderEdit extends Component {
  static propTypes = {
    order: propTypes.object,
    openModal: propTypes.func
  }

  handleClick = evt => {
    const { receipt_id: orderId } = this.props.order
    const config = {
      title: 'custom',
      type: 'orderEdit',
      args: { orderId }
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    const { order } = this.props
    const isOpen =
      order.receipt_status === 'OPEN' || order.receipt_status === 'HELD'
    return isOpen ? (
      <button className="btn-link" onClick={this.handleClick}>
        edit
      </button>
    ) : (
      <span>--</span>
    )
  }
}

OrderEdit.displayName = 'OrderEdit'

export default connect(null, {
  openModal
})(OrderEdit)
