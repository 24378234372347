import { fetchUser } from './user'
import { loadToken, clearToken, resetToken } from './token'
import { serialize } from '../lib/helpers'
import { handleError } from '../lib/errors'
import { clearPageError } from './page'

const initState = {
  data: {},
  errors: {},
  brands: [],
  reset: false,
  resetSent: false,
  submitting: false,
}

const authUrl = process.env.REACT_APP_AUTH_URL
const apiUrl = process.env.REACT_APP_API_URL
const appUrl = process.env.REACT_APP_APP_URL
const clientId = process.env.REACT_APP_CLIENT_ID

export const CLEAR_AUTH = 'CLEAR_AUTH'
export const LOAD_FORM = 'LOAD_FORM'
export const LOAD_BRANDS = 'LOAD_BRANDS'
export const TOGGLE_SUBMITTING = 'TOGGLE_SUBMITTING'
export const TOGGLE_RESET = 'TOGGLE_RESET'
export const TOGGLE_RESET_SENT = 'TOGGLE_RESET_SENT'
export const RESET_TOGGLES = 'RESET_TOGGLES'
export const SHOW_ERRORS = 'SHOW_ERRORS'

export const loadForm = (data) => ({ type: LOAD_FORM, payload: data })
export const loadBrands = (brands) => ({ type: LOAD_BRANDS, payload: brands })
export const showFormErrors = (errors) => ({
  type: SHOW_ERRORS,
  payload: errors,
})
export const clearAuth = () => ({ type: CLEAR_AUTH })
export const toggleReset = () => ({ type: TOGGLE_RESET })
export const toggleResetSent = () => ({ type: TOGGLE_RESET_SENT })
export const resetToggles = () => ({ type: RESET_TOGGLES })
export const toggleSubmitting = () => ({ type: TOGGLE_SUBMITTING })

export const updateInput = (field, value) => {
  return (dispatch, getState) => {
    const { data } = getState().auth
    dispatch(loadForm({ ...data, [field]: value }))
  }
}

export const revokeToken = () => {
  return (dispatch, getState) => {
    const data = { client_id: clientId, token: getState().token }
    return fetch(`${authUrl}/oauth2/revoke`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: serialize(data),
    }).then(dispatch(clearToken()))
  }
}

const makeOptions = (data) => {
  const auth = {
    grant_type: 'password',
    client_id: clientId,
    username: data.email,
    password: data.password,
  }
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: serialize(auth),
  }
}

export const submitForm = (data) => {
  return (dispatch) => {
    dispatch(toggleSubmitting())
    const options = makeOptions(data)
    fetch(`${authUrl}/oauth2/token`, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) throw new Error(data.error_description)
        dispatch(clearAuth())
        dispatch(loadToken(data.access_token))
        dispatch(fetchUser(data.access_token))
      })
      .catch((err) => {
        dispatch(showFormErrors({ form: err.message }))
      })
      .finally(() => dispatch(toggleSubmitting()))
  }
}

export const submitResetToken = (data) => {
  return (dispatch) => {
    dispatch(toggleSubmitting())
    const options = makeOptions(data)
    fetch(`${authUrl}/oauth2/token`, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) throw new Error(data.error_description)
        dispatch(clearAuth())
        dispatch(clearPageError())
        dispatch(resetToken(data.access_token))
      })
      .catch((err) => {
        dispatch(showFormErrors({ form: err.message }))
      })
      .finally(() => dispatch(toggleSubmitting()))
  }
}

export const sendResetEmail = (email) => {
  return (dispatch) => {
    dispatch(toggleSubmitting())
    const link_url = `${appUrl}/reset`
    const data = { email, link_url }
    fetch(`${apiUrl}/reset-password/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 202 ? null : res.json()
      })
      .then((err) => {
        if (err) {
          const { form, fields } = handleError(err)
          throw new Error(fields.email || fields.link_url || form)
        }
        dispatch(toggleResetSent())
      })
      .catch((err) => {
        dispatch(showFormErrors({ form: err.message }))
      })
      .finally(() => dispatch(toggleSubmitting()))
  }
}

export const resetForm = () => {
  return (dispatch) => {
    dispatch(resetToggles())
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_FORM:
      return { ...state, data: action.payload, errors: {} }
    case SHOW_ERRORS:
      return { ...state, errors: action.payload }
    case LOAD_BRANDS:
      return { ...state, data: {}, errors: {}, brands: action.payload }
    case CLEAR_AUTH:
      return { ...state, data: {}, errors: {}, brands: [] }
    case TOGGLE_RESET:
      return { ...state, reset: !state.reset, errors: {} }
    case TOGGLE_RESET_SENT:
      return { ...state, resetSent: !state.resetSent, errors: {} }
    case RESET_TOGGLES:
      return {
        ...state,
        reset: false,
        resetSent: false,
        submiting: false,
        errors: {},
      }
    case TOGGLE_SUBMITTING:
      return { ...state, submitting: !state.submitting }
    default:
      return state
  }
}
