import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { prepStatus } from '../../lib/constants'
import { updatePrepStatus } from '../../reducers/orderMgmt'

class UpdatePrepStatus extends Component {
  static propTypes = {
    orderId: propTypes.number,
    permissions: propTypes.array,
    updatePrepStatus: propTypes.func
  }

  handlePrepStatusUpdate = (evt, orderId, newPrepStatus) => {
    evt.preventDefault()
    this.props.updatePrepStatus(orderId, newPrepStatus)
    evt.target.blur()
  }

  render() {
    const { orderId, currentStatus } = this.props
    const currentIndex = prepStatus.findIndex(i => i.value === currentStatus)
    return (
      <div className="modal__content">
        <div className="modal__header">
          <h2>Update Prep Status for #{orderId}</h2>
          <p>Choose one of the options below</p>
        </div>
        <div className="modal__body">
          <div className="order-edit-options">
            <ul className="btn-list -center">
              {prepStatus.map((i, index) => (
                <li key={i.value}>
                  <button
                    className="btn"
                    disabled={index <= currentIndex}
                    onClick={evt =>
                      this.handlePrepStatusUpdate(evt, orderId, i.value)
                    }
                  >
                    <span>{i.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

UpdatePrepStatus.displayName = 'UpdatePrepStatus'

export default connect(
  state => ({
    permissions: state.user.permissions
  }),
  { updatePrepStatus }
)(UpdatePrepStatus)
