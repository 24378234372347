import propTypes from 'prop-types'
import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import { ResponsiveArea } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Toggle from './Toggle'
import Table from '../Table'

const fields = [
  {
    name: 'Month',
    field: ['month']
  },
  {
    name: 'Stores',
    field: ['stores']
  },
  {
    name: 'Orders',
    field: ['orders'],
    format: 'quantity'
  },
  {
    name: 'Orders per Store',
    field: ['orders_per_store'],
    format: 'quantity'
  },
  {
    name: 'Sales',
    field: ['sales'],
    format: 'currency'
  },
  {
    name: 'Sales per Store',
    field: ['sales_per_store'],
    format: 'currency'
  }
]

const makeBrandName = brand => {
  return brand.replace('&', '').replace("'", '').replace(' ', '')
}

const makeBrands = data => {
  if (!data || !data.length) return []
  return data.reduce((arr, i) => {
    const brandName = makeBrandName(i.brand)
    const existing = arr.find(brand => brand === brandName)
    return existing ? arr : [...arr, brandName]
  }, [])
}

const makeData = (data, metric = 'sales', asPercent = false) => {
  const byMonth = data.reduce((obj, i) => {
    const name = i.month.toString()
    const existing = obj[name]
    const brandName = makeBrandName(i.brand)
    const month = existing
      ? { ...existing, [brandName]: parseFloat(i[metric]) }
      : { [brandName]: parseFloat(i[metric]) }
    return { ...obj, [name]: month }
  }, {})
  let months = Object.entries(byMonth).map(([name, brands]) => {
    return { name, ...brands }
  })
  if (asPercent) {
    months = months.map(i => {
      const values = Object.entries(i)
        .filter(([key]) => key !== 'name')
        .map(([, value]) => value)
      const total = values.reduce((t, v) => (t += v), 0.0)
      const updated = Object.entries(i).reduce((obj, [key, value]) => {
        return { ...obj, [key]: key === 'name' ? value : (value / total) * 100 }
      }, {})
      return updated
    })
  }
  return months.sort((a, b) => parseInt(a.name) - parseInt(b.name))
}

const makeTableData = data => {
  const byBrand = data.reduce((obj, i) => {
    const current = obj[i.brand] || []
    const brandData = [...current, i].sort(
      (a, b) => parseInt(a.month) - parseInt(b.month)
    )
    return { ...obj, [i.brand]: brandData }
  }, {})
  return Object.entries(byBrand).map(([brand, data]) => ({ brand, data }))
}

const displayTypes = [
  { name: 'Stacked', value: 'STACKED' },
  { name: 'Unstacked', value: 'DEFAULT' },
  { name: 'Percent', value: 'PERCENT' }
]

const checkNoData = data => {
  if (!data || !data.length || data.length === 1) return true
}

const SalesOverTime = ({ data, handlers }) => {
  const [showData, setShowData] = useState(false)
  const [display, setDisplay] = useState('STACKED')
  const asPercent = display === 'PERCENT' && !showData

  const toggleDisplay = evt => {
    evt.preventDefault()
    setShowData(!showData)
    evt.target.blur()
  }

  const toggleDisplayType = (evt, displayType) => {
    evt.preventDefault()
    setDisplay(displayType)
    evt.target.blur()
  }

  const noData = checkNoData(data)
  const brands = makeBrands(data)
  const tableData = makeTableData(data)
  const salesData = makeData(data, 'sales', asPercent)
  const checksData = makeData(data, 'orders', asPercent)
  const storesData = makeData(data, 'stores', asPercent)
  const chartsData = [
    { title: 'Sales by Month', data: salesData, format: 'dollars' },
    { title: 'Checks by Month', data: checksData, format: 'quantity' },
    { title: 'Stores by Month', data: storesData, format: 'quantity' }
  ]

  if (noData)
    return (
      <div className="report__table">
        <p className="alert">
          No sales data for the selected time period. Please try adjusting your
          filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <Toggle
              values={displayTypes}
              value={display}
              handler={toggleDisplayType}
            />
            <ToggleDisplay showData={showData} handler={toggleDisplay} />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="reports">
              {tableData.map(brand => (
                <div key={brand.brand} className="report -full">
                  <div
                    className="report__header"
                    style={{ marginBottom: '4rem' }}
                  >
                    <h2>{brand.brand}</h2>
                  </div>
                  <div className="report__table">
                    <Table fields={fields} items={brand.data} />
                  </div>
                </div>
              ))}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {!showData &&
        chartsData.map(chart => (
          <div key={chart.title} className="reports">
            <div className="report -full">
              <div className="report__header" style={{ marginBottom: '4rem' }}>
                <h2>{chart.title}</h2>
              </div>
              <ResponsiveArea
                height={1000}
                data={chart.data}
                dataKeys={brands}
                display={display}
                showLegend={true}
                useRandomColors={true}
                yAxis={{
                  width: 80,
                  format: display === 'PERCENT' ? 'percentage' : chart.format,
                  domain: display === 'PERCENT' ? [0, 100] : ['auto', 'auto']
                }}
              />
            </div>
          </div>
        ))}
    </>
  )
}

SalesOverTime.displayName = 'SalesOverTime'
SalesOverTime.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default SalesOverTime
