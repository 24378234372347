import { uniqBy } from 'lodash'
import { request } from '../lib/services'
import { handleError } from '../lib/errors'
import { makeOptionKey } from '../lib/helpersPage'
import { flashMessage } from './messages'
import { updateItems, removeItem, groupItems, refreshItems } from './list'
import { redirectPage, handlePageError, clearPageError } from './page'
import { reloadOptions } from './options'
import { loadRelations, clearRelations } from './relations'
import { loadRelation, clearRelation, fetchRelationList } from './relation'
import { sortItems, makeQueryParams, convertFromAPI } from '../lib/helpers'
import { makeRelations } from '../lib/helpersPage'
import { closeModal, showModalErrors } from './modal'
import { updateBrandSettings } from './brand'

const initState = {
  data: null,
  loading: false,
  errors: {}
}

export const ITEM_CLEAR = 'ITEM_CLEAR'
export const ITEM_LOADING = 'ITEM_LOADING'
export const ITEM_LOAD = 'ITEM_LOAD'
export const ITEM_ERRORS = 'ITEM_ERRORS'
export const ITEM_ERRORS_CLEAR = 'ITEM_ERRORS_CLEAR'
export const FIELD_UPDATE = 'FIELD_UPDATE'

export const clearItem = () => ({ type: ITEM_CLEAR })
export const loadItem = data => ({ type: ITEM_LOAD, payload: data })
export const showItemErrors = errors => ({ type: ITEM_ERRORS, payload: errors })
export const clearItemErrors = () => ({ type: ITEM_ERRORS_CLEAR })

export const fetchItem = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, idd, item, relations, relation } = page
    // dispatch(clearItem())
    dispatch({ type: ITEM_LOADING })
    if (relations) dispatch(clearRelations())
    if (relation) dispatch(clearRelation())
    if (item.id && (!id || !item)) return
    if (id === 'new') return dispatch(loadItem({}))
    let endpoint = !item.id ? item.endpoint : `${item.endpoint}/${id}`
    let params = item.params ? [...item.params] : []
    if (relations)
      params.push({ field: 'with_related', value: relations.related || true })
    if (params.length)
      endpoint += `?${makeQueryParams(uniqBy(params, 'field'))}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const data = convertFromAPI(page.route, 'item', resp)
        dispatch(loadItem(data))
        if (relations) {
          let items = makeRelations(
            resp,
            relations.endpoint,
            relations.entity,
            relations.relation
          )
          items = sortItems(items, relations.sorting)
          if (relations.groupBy)
            items = groupItems(items, relations.groupBy, relations.sorting)
          dispatch(loadRelations(items))
          if (relation) {
            const relationData =
              idd === 'new'
                ? {}
                : items.filter(i => i[relation.id] === parseInt(idd))[0]
            dispatch(loadRelation(relationData))
            dispatch(fetchRelationList())
          }
        }
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showItemErrors({}))
        // const msg = `Uh oh. Something went wrong. [${err.message}]`
        // relations
        //   ? dispatch(showRelationsError(msg))
        //   : dispatch(showItemErrors({ form: msg }))
      })
  }
}

export const updateInput = target => {
  return (dispatch, getState) => {
    const { data } = getState().item
    const value = target.type === 'checkbox' ? target.checked : target.value
    data[target.id] = value
    const newData = { ...data }
    dispatch(loadItem(newData))
  }
}

// const wait = async () => {
//   return null
// }

export const upsertItem = data => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const { token, brand, page, options, list } = getState()
    const { id, item } = page
    const isNew = id === 'new'
    const method = isNew ? 'POST' : 'PUT'
    const endpoint =
      isNew || !item.id ? item.endpoint : `${item.endpoint}/${id}`
    return request(token, brand, endpoint, method, data)
      .then(data => {
        if (endpoint === '/general-settings') {
          dispatch(updateBrandSettings(data))
        }
        const key = makeOptionKey(item.endpoint)
        if (key in options) dispatch(reloadOptions(key))
        const newData = convertFromAPI(page.route, 'item', data)
        dispatch(updateItems(newData))
        if (isNew) {
          const newId = data[item.id]
          const newRoute = `${page.route.replace(':id', newId)}`
          const redirect = item.redirect || newRoute
          if (item.defaults && list.reorderEndpoint) {
            const values = { [item.id]: newId, ...item.defaults }
            const listEndpoint = list.reorderEndpoint.replace('/reorder', '')
            request(token, brand, listEndpoint, 'POST', [values])
              .catch(err => dispatch(handlePageError(err)))
              .finally(() => {
                dispatch(redirectPage(redirect))
                dispatch(flashMessage('Successfully created!'))
              })
          } else {
            dispatch(redirectPage(redirect))
            dispatch(flashMessage('Successfully created!'))
          }
        } else {
          item.params ? dispatch(fetchItem()) : dispatch(loadItem(newData))
          dispatch(fetchItem())
          dispatch(flashMessage('Successfully updated!'))
        }
      })
      .catch(err => {
        const errors = handleError(err)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

export const deleteItem = () => {
  return (dispatch, getState) => {
    const { token, brand, page, options } = getState()
    const { id, item } = page
    request(token, brand, `${item.endpoint}/${id}`, 'DELETE')
      .then(() => {
        // TODO: we don't really need to do this since we always
        // update list pages when we arrive on them
        const key = makeOptionKey(item.endpoint)
        if (key in options) dispatch(reloadOptions(key))
        dispatch(removeItem(id))
        dispatch(redirectPage(`${item.endpoint}`))
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const addItemToRelated = (item, relatedId, endpoint) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const relationsEndpoint = endpoint.replace(':id', relatedId)
    request(token, brand, relationsEndpoint, 'POST', [item])
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchItem())
        dispatch(reloadOptions(endpoint))
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const deleteRelated = (item, relatedId, endpoint) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const relationsEndpoint = endpoint.replace(':id', relatedId)
    request(token, brand, relationsEndpoint, 'DELETE', [item])
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchItem())
        dispatch(reloadOptions(endpoint))
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const replaceItem = data => {
  return (dispatch, getState) => {
    const { token, brand, page, options } = getState()
    const { id, item } = page
    return request(token, brand, `${item.endpoint}/${id}`, 'DELETE')
      .then(() => {
        request(token, brand, item.endpoint, 'POST', data).then(data => {
          const key = makeOptionKey(item.endpoint)
          if (key in options) dispatch(reloadOptions(key))
          dispatch(refreshItems())
          const redirect =
            item.redirect || `${page.route.replace(':id', data[item.id])}`
          dispatch(redirectPage(redirect))
          dispatch(flashMessage('Successfully updated!'))
        })
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const moveItem = (itemId, relatedId, endpoint, newRelatedId) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const relationsEndpoint = endpoint
      .replace(':idd', itemId)
      .replace(':id', relatedId)
    const data = { new_category_id: parseInt(newRelatedId) }
    request(token, brand, relationsEndpoint, 'POST', data)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchItem())
        dispatch(reloadOptions(endpoint))
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

const itemReducer = (state = initState, action) => {
  switch (action.type) {
    case ITEM_CLEAR:
      return { ...initState }
    case ITEM_LOADING:
      return { ...state, loading: true }
    case ITEM_LOAD:
      return { ...state, data: action.payload, loading: false, errors: {} }
    case ITEM_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    case ITEM_ERRORS_CLEAR:
      return { ...state, errors: {} }
    default:
      return state
  }
}

export default itemReducer
