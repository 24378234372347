import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  {
    name: 'Order ID',
    field: ['receipt_id'],
    link: 'receipt_id',
    linkEndpoint: '/orders'
  },
  { name: 'Order Time', field: ['requested_datetime'] },
  {
    name: 'Customer',
    field: ['customer'],
    link: 'customer_id',
    linkEndpoint: '/customers'
  },
  { name: 'Location', field: ['location'] },
  { name: 'Order Type', field: ['order_type'] },
  { name: 'Service Type', field: ['service_type'] },
  { name: 'Rating', field: ['rating'] },
  {
    name: 'Comments',
    field: ['comments'],
    format: 'tooltip',
    classes: '-tooltip'
  },
  {
    name: 'Total',
    field: ['total'],
    format: 'dollars'
  }
]

const Ratings = ({ data, handlers }) => {
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/ratings/csv" />
          </div>
        </div>
      </div>
      <div className="report__table">
        <Table fields={fields} items={data} />
      </div>
    </>
  )
}

Ratings.displayName = 'Ratings'
Ratings.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default Ratings
