import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  addCustomer,
  updateCustomer,
  removeCustomer
} from '../../reducers/order'
import { showModalErrors } from '../../reducers/modal'
import { fields } from '../../lib/pages'
import Form from '../Form'
import { PageError } from '../PageError'
import Search from '../Search'
import { NavLink } from 'react-router-dom'

class Customer extends Component {
  static propTypes = {
    // state.order.customer
    customer: propTypes.object,
    currentOrder: propTypes.object,
    results: propTypes.array,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    addCustomer: propTypes.func,
    updateCustomer: propTypes.func,
    removeCustomer: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { customer, currentOrder, errors } = this.props
    return customer ? (
      <>
        <PageError msg={errors ? errors.form : ''} />
        {!currentOrder ? (
          <p>
            <button className="btn-link" onClick={this.props.removeCustomer}>
              Or click here to remove this customer and add a new one
            </button>
          </p>
        ) : (
          <p>Customers cannot be removed on the Checkout page</p>
        )}
        <br />
        <Form
          isNew={customer ? false : true}
          idField="customer_id"
          fields={fields.customer}
          data={customer || {}}
          errors={errors || {}}
          upsertItem={this.props.updateCustomer}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </>
    ) : (
      <>
        <Search
          endpoint="customers"
          resultId="customer_id"
          resultName={['first_name', 'last_name']}
          resultParens="email"
          placeholder="enter customer name or email"
          resultAction={this.props.addCustomer}
        />
        <div className="modal__footer">
          <p>
            <NavLink to="customers/new">
              Or click here to add a new customer
            </NavLink>
          </p>
        </div>
      </>
    )
  }
}

Customer.displayName = 'Customer'

export default connect(
  state => ({
    customer: state.order.customer,
    currentOrder: state.order.order,
    results: state.search.results,
    errors: state.modal.errors
  }),
  { addCustomer, updateCustomer, showModalErrors, removeCustomer }
)(Customer)
