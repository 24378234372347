import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BarLoader } from 'react-spinners'
import { isEmpty } from '../lib/utils'
import {
  updateFilter,
  refreshReport,
  resetFilters,
  clearReport,
  updateDateRange,
  updateRefresh
} from '../reducers/report'
import { Filter } from './Filters'
import TableRefresh from './TableRefresh'
import Accounting from './reporting/Accounting'
import Sales from './reporting/Sales'
import Discounts from './reporting/Discounts'
import Cash from './reporting/Cash'
import Throughput from './reporting/Throughput'
import PaidInsOuts from './reporting/PaidInsOuts'
import Voids from './reporting/Voids'
import Refunds from './reporting/Refunds'
import DateRange from './reporting/DateRange'
import PromoCodes from './reporting/PromoCodes'
import Ratings from './reporting/Ratings'
import ProductMix from './reporting/ProductMix'
import Subscribers from './reporting/Subscribers'
import Dashboard from './reporting/Dashboard'
import Donations from './reporting/Donations'
import SalesByPeriod from './reporting/SalesByPeriod'
import TaxSummary from './reporting/TaxSummary'
import TenderSummary from './reporting/TenderSummary'
import TendersDiff from './reporting/TendersDiff'
import Credit from './reporting/Credit'
import ItemCountsByStore from './reporting/ItemCountsByStore'
import OrderPrepPerformance from './reporting/OrderPrepPerformance'
import LevelUpConnections from './reporting/LevelUpConnections'
import SalesOverTime from './reporting/SalesOverTime'
import GiftCardCredits from './reporting/GiftCardCredits'
import SalesByMonth from './reporting/SalesByMonth'

const renderReport = (reportType, data, storeId, handlers) => {
  switch (reportType) {
    case 'sales':
      return <Sales data={data} storeId={storeId} />
    case 'accounting':
      return <Accounting data={data} storeId={storeId} handlers={handlers} />
    case 'salesByPeriod':
      return <SalesByPeriod data={data[0]} handlers={handlers} />
    case 'salesOverTime':
      return <SalesOverTime data={data[0]} handlers={handlers} />
    case 'taxes':
      return <TaxSummary data={data[0]} handlers={handlers} />
    case 'tenders':
      return <TenderSummary data={data[0]} handlers={handlers} />
    case 'tendersDiff':
      return <TendersDiff data={data[0]} handlers={handlers} />
    case 'credit':
      return <Credit data={data[0]} handlers={handlers} />
    case 'donations':
      return <Donations data={data[0]} handlers={handlers} />
    case 'giftCardCredits':
      return <GiftCardCredits data={data[0]} handlers={handlers} />
    case 'discounts':
      return <Discounts data={data[0]} handlers={handlers} />
    case 'promoCodes':
      return <PromoCodes data={data} handlers={handlers} />
    case 'levelupConnections':
      return <LevelUpConnections data={data[0]} handlers={handlers} />
    case 'subscribers':
      return <Subscribers data={data[0]} handlers={handlers} />
    case 'productMix':
      return <ProductMix data={data[0]} handlers={handlers} />
    case 'dashboard':
      return <Dashboard data={data[0]} handlers={handlers} />
    case 'ratings':
      return <Ratings data={data[0]} handlers={handlers} />
    case 'cash':
      return <Cash shifts={data[0]} />
    case 'throughput':
      return <Throughput data={data[0]} handlers={handlers} />
    case 'itemCountsByStore':
      return <ItemCountsByStore data={data[0]} handlers={handlers} />
    case 'orderPrepPerformance':
      return <OrderPrepPerformance data={data[0]} handlers={handlers} />
    case 'paidInsOuts':
      return <PaidInsOuts data={data[0]} handlers={handlers} />
    case 'voids':
      return <Voids data={data[0]} handlers={handlers} />
    case 'refunds':
      return <Refunds data={data[0]} handlers={handlers} />
    case 'salesByMonth':
      return <SalesByMonth data={data[0]} handlers={handlers} />
    default:
      return null
  }
}

class Report extends Component {
  static propTypes = {
    reportType: propTypes.string,
    filters: propTypes.array,
    loading: propTypes.bool,
    data: propTypes.array,
    range: propTypes.string,
    dateFilters: propTypes.bool,
    autoRefresh: propTypes.bool,
    csvEndpoint: propTypes.string,
    options: propTypes.array,
    stores: propTypes.array,
    revenueCenters: propTypes.object,
    taxes: propTypes.array,
    updateFilter: propTypes.func,
    refreshReport: propTypes.func,
    resetFilters: propTypes.func,
    clearReport: propTypes.func,
    updateDateRange: propTypes.func
  }

  handleFilter = evt => {
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    const autoRefresh =
      this.props.autoRefresh === undefined ? true : this.props.autoRefresh
    this.props.updateFilter(filter, autoRefresh)
    if (!autoRefresh) this.props.updateRefresh(true)
    if (type !== 'datepicker') evt.target.blur()
  }

  handleReset = evt => {
    evt.preventDefault()
    const autoRefresh =
      this.props.autoRefresh === undefined ? true : this.props.autoRefresh
    this.props.resetFilters(autoRefresh)
    if (!autoRefresh) this.props.updateRefresh(true)
    evt.target.blur()
  }

  handleDateRange = (evt, range) => {
    evt.preventDefault()
    this.props.updateDateRange(range)
    // const autoRefresh =
    //   this.props.autoRefresh === undefined ? true : this.props.autoRefresh
    // this.props.updateDateRange(range, autoRefresh)
    // if (!autoRefresh) this.props.updateRefresh(true)
    evt.target.blur()
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.refreshReport()
    evt.target.blur()
  }

  render() {
    const {
      filters,
      reportType,
      loading,
      data,
      range,
      dateFilters,
      refreshNeeded
    } = this.props
    const hasFilters = !isEmpty(filters)
    const sf = filters.find(({ field }) => field === 'store_id')
    const storeId = sf && sf.value ? parseInt(sf.value) : null
    const storeRequired = sf && sf.required && !storeId
    const handlers = {
      refresh: this.handleRefresh,
      next: null,
      reset: this.handleReset
    }
    return (
      <div className="content__main">
        {hasFilters ? (
          <div className="table__filters">
            {filters.map(filter => Filter(filter, this.handleFilter))}
          </div>
        ) : null}
        {dateFilters && (
          <DateRange value={range || ''} handler={this.handleDateRange} />
        )}
        {loading ? (
          <div className="reports__loading">
            <BarLoader size={100} color={'#000000'} />
            <p>Tabulating data. Please hang tight.</p>
          </div>
        ) : refreshNeeded ? (
          <div className="report__table">
            <TableRefresh
              refreshItems={this.handleRefresh}
              reportCsvEndpoint={this.props.csvEndpoint}
            />
          </div>
        ) : data ? (
          renderReport(reportType, data, storeId, handlers)
        ) : storeRequired ? (
          <div className="report__table">
            <p className="alert">
              Please choose a store to retrieve results for this report.
            </p>
          </div>
        ) : null}
      </div>
    )
  }
}

Report.displayName = 'Report'

export default connect(
  state => ({
    ...state.report,
    ...state.page.report
  }),
  {
    updateFilter,
    refreshReport,
    resetFilters,
    clearReport,
    updateDateRange,
    updateRefresh
  }
)(Report)
