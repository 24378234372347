import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ClipLoader from 'react-spinners/ClipLoader'
import { orderType as orderTypes, timezoneMap } from '../lib/constants'
import {
  makeZonedDateStr,
  makeZonedDate,
  isoToDateStr,
  getCurrentISO,
  makeMakeTime,
  dateStrToLocalDateStr
} from '../lib/helpersDatetime'
import { displayOrderType } from '../lib/helpersOrder'
import { sortItems } from '../lib/helpers'
import {
  reloadOrders,
  refreshOrders,
  updateOrderType,
  toggleOrderStatus,
  toggleDisplay,
  markReviewed,
  orderAction,
  showEditOptions,
  viewDetails,
  printOrder,
  printRouter,
  previewRouter,
  updateBusinessDate,
  clearSearch,
  updateSearch,
  findOrder,
  setMuted,
  toggleMuted,
  showUpdatePrepStatus
} from '../reducers/orderMgmt'
import { redirectPage } from '../reducers/page'
import { Checkbox } from './FormInputs'
import { RefreshCw, Volume, VolumeX } from 'react-feather'
import { PageError } from './PageError'
import OrderCard from './OrderCard'
import PrintOrderWrapper from './PrintOrderWrapper'
import PrintOrderRouter from './PrintOrderRouter'
import { errMessages } from '../lib/errors'
// import Alarm from './Alarm'
// import Player from './Player'
// import PlayerSimple from './PlayerSimple'
import AlarmSimple from './AlarmSimple'
import OrderTypeFilter from './orderMgmt/OrderTypeFilter'
import OrderInputs from './orderMgmt/OrderInputs'

const fields = [
  {
    type: 'checkbox',
    label: 'Include Closed',
    field: 'CLOSED'
  },
  {
    type: 'checkbox',
    label: 'Include Cancelled',
    field: 'VOID'
  }
]

const displayFields = [
  {
    type: 'checkbox',
    label: 'Group by Date',
    field: 'BY_DATE'
  },
  {
    type: 'checkbox',
    label: 'Compact',
    field: 'COMPACT'
  }
]

const makePrinterType = (orderPrint, orders) => {
  if (!orderPrint) return 'DESKTOP'
  const orderId = parseInt(orderPrint.receipt_id)
  const order = orders.find(i => i.receipt_id === orderId)
  return order ? order.revenue_center.printer_type : 'DESKTOP'
}

const getPastDueOrders = (hrs, tz, orders) => {
  const currentLocalDate = makeZonedDate(tz)
  const pastDue = hrs
    ? orders
        .filter(i => i.order_type === 'MAIN_MENU')
        .filter(i => i.receipt_status === 'OPEN')
        .filter(i => {
          // use pickup wait time for walkin orders
          const serviceType =
            i.service_type === 'WALKIN' ? 'PICKUP' : i.service_type
          const makeTime = makeMakeTime(
            i.requested_at,
            tz,
            hrs[serviceType] ? hrs[serviceType].currentWait : 0
          )
          return currentLocalDate > makeTime
        })
    : []
  return pastDue
}

const AlarmIcon = ({ muted }) => {
  return (
    <span className={`order-alarm__icon ${muted ? '-muted' : ''}`}>
      {muted ? <VolumeX size={null} /> : <Volume size={null} />}
    </span>
  )
}

AlarmIcon.displayName = 'AlarmIcon'
AlarmIcon.propTypes = {
  muted: propTypes.bool
}

const makeRcCounts = rcs => {
  if (!rcs || !rcs.length) return {}
  const counts = rcs.reduce((obj, i) => {
    const count = obj[i.revenue_center_type] || 0
    obj[i.revenue_center_type] = count + 1
    return obj
  }, {})
  return counts
}

class OrderMgmt extends Component {
  constructor(props) {
    super(props)
    // this.muteButton = React.createRef()
    this.state = { initialLoad: true }
  }

  static propTypes = {
    // ...state.orderMgmt
    refreshing: propTypes.bool,
    loading: propTypes.bool,
    orders: propTypes.array,
    orderId: propTypes.string,
    orderPrint: propTypes.object,
    orderRouter: propTypes.bool,
    orderType: propTypes.string,
    orderStatus: propTypes.array,
    displaySettings: propTypes.array,
    businessDate: propTypes.string,
    lastUpdated: propTypes.string,
    search: propTypes.object,
    muted: propTypes.bool,
    // mapstatetoprops
    pageError: propTypes.string,
    store: propTypes.object,
    hours: propTypes.object,
    permissions: propTypes.array,
    // mapdispatchtoprops
    reloadOrders: propTypes.func,
    refreshOrders: propTypes.func,
    updateOrderType: propTypes.func,
    toggleOrderStatus: propTypes.func,
    toggleDisplay: propTypes.func,
    markReviewed: propTypes.func,
    orderAction: propTypes.func,
    showEditOptions: propTypes.func,
    viewDetails: propTypes.func,
    printOrder: propTypes.func,
    updateBusinessDate: propTypes.func,
    clearSearch: propTypes.func,
    updateSearch: propTypes.func,
    findOrder: propTypes.func,
    printRouter: propTypes.func,
    previewRouter: propTypes.func,
    redirectPage: propTypes.func,
    setMuted: propTypes.func,
    toggleMuted: propTypes.func
  }

  // https: //stackoverflow.com/questions/39426083/update-react-component-every-second
  componentDidMount() {
    window.scroll(0, 0)
    this.interval = setInterval(() => this.props.refreshOrders(), 15000)
    this.props.setMuted(true)
  }

  componentDidUpdate() {
    const { store, orders, hours } = this.props
    const hrs = hours ? hours.hours : null
    const tz = store ? timezoneMap[store.timezone] : 'America/New_York'
    const storeName = store ? store.full_name : 'Manage Orders'
    const pastDue = getPastDueOrders(hrs, tz, orders)
    document.title = `(${pastDue.length}) ${storeName} | Open Tender Admin Portal`
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleRefresh = evt => {
    evt.preventDefault()
    // this.props.refreshOrders()
    this.props.reloadOrders()
    evt.target.blur()
  }

  handleOrderType = (evt, orderType) => {
    evt.preventDefault()
    this.props.updateOrderType(orderType)
    evt.target.blur()
  }

  handleOrderStatus = (evt, orderStatus) => {
    this.props.toggleOrderStatus(orderStatus)
    evt.target.blur()
  }

  handleDisplay = (evt, displaySetting) => {
    this.props.toggleDisplay(displaySetting)
    evt.target.blur()
  }

  markReviewed = (evt, orderId) => {
    evt.preventDefault()
    this.props.markReviewed(orderId)
    evt.target.blur()
  }

  resendEmail = (evt, orderId) => {
    evt.preventDefault()
    const msg = 'Email sent successfully!'
    this.props.orderAction(orderId, 'resend-email', msg)
    evt.target.blur()
  }

  resendToPos = (evt, orderId) => {
    evt.preventDefault()
    const msg = 'Order sent successfully!'
    this.props.orderAction(orderId, 'send-to-pos', msg, true)
    evt.target.blur()
  }

  closeOrder = (evt, orderId, confirmCharge) => {
    evt.preventDefault()
    const action = confirmCharge ? 'confirmClose' : 'close'
    const msg = 'Order closed successfully!'
    this.props.orderAction(orderId, action, msg, true)
    evt.target.blur()
  }

  cancelOrder = (evt, orderId) => {
    evt.preventDefault()
    this.props.orderAction(orderId, 'confirmCancel')
    evt.target.blur()
  }

  editOrder = (evt, orderId) => {
    evt.preventDefault()
    this.props.showEditOptions(orderId)
    evt.target.blur()
  }

  updatePrepStatus = (evt, orderId, currentStatus) => {
    evt.preventDefault()
    this.props.showUpdatePrepStatus(orderId, currentStatus)
    evt.target.blur()
  }

  refundOrder = (evt, orderId) => {
    evt.preventDefault()
    this.props.redirectPage(`/orders/${orderId}`)
    evt.target.blur()
  }

  viewDetails = (evt, orderId) => {
    evt.preventDefault()
    this.props.viewDetails(orderId)
    evt.target.blur()
  }

  printOrder = (evt, orderId) => {
    evt.preventDefault()
    this.props.printOrder(orderId)
    evt.target.blur()
  }

  printRouter = evt => {
    evt.preventDefault()
    this.props.printRouter()
    evt.target.blur()
  }

  previewRouter = evt => {
    evt.preventDefault()
    this.props.previewRouter()
    evt.target.blur()
  }

  handleDatepicker = date => {
    const businessDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
    this.props.updateBusinessDate(businessDate)
  }

  handleSearchInput = evt => {
    this.props.updateSearch({ query: evt.target.value, error: null })
  }

  handleSearchEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.props.findOrder()
    }
  }

  handleSearchClear = evt => {
    evt.preventDefault()
    this.props.clearSearch()
  }

  toggleMuted = evt => {
    evt.preventDefault()
    this.props.toggleMuted()
    evt.target.blur()
  }

  handleMuted = isMuted => {
    this.props.setMuted(isMuted)
    this.setState({ initialLoad: false })
  }

  render() {
    const {
      pageError,
      store,
      refreshing,
      loading,
      orders,
      orderType,
      orderStatus,
      displaySettings,
      lastUpdated,
      businessDate,
      search,
      orderPrint,
      orderRouter,
      hours,
      permissions: perms = {},
      muted
    } = this.props
    let filtered, grouped
    const groupBy = displaySettings.includes('BY_DATE')
    if (search.result) {
      filtered = [search.result]
      grouped = { all: filtered }
    } else if (search.error) {
      filtered = []
      grouped = { all: filtered }
    } else {
      filtered = orders.filter(i => orderStatus.includes(i.receipt_status))
      filtered = orderType.length
        ? filtered.filter(i => i.order_type === orderType)
        : filtered
      grouped = groupBy
        ? filtered.reduce((obj, i) => {
            obj[i.business_date] = [...(obj[i.business_date] || []), i]
            return obj
          }, {})
        : { all: filtered }
    }
    grouped = Object.entries(grouped).map(i => ({ day: i[0], orders: i[1] }))
    grouped = sortItems(grouped, { sortBy: 'day', sortType: 'alpha' })
    const tz = store ? timezoneMap[store.timezone] : 'America/New_York'
    const rcCounts = store ? makeRcCounts(store.revenue_centers) : {}
    const today = makeZonedDateStr(tz, 0, 'EEE, MMM d')
    const tomorrow = makeZonedDateStr(tz, 1, 'EEE, MMM d')
    const currentBusinessDate = makeZonedDateStr(tz)
    const handlers = {
      markReviewed: this.markReviewed,
      resendEmail: perms.includes('resend_email') ? this.resendEmail : null,
      resendToPos: this.resendToPos,
      close: this.closeOrder,
      cancel: perms.includes('void_orders') ? this.cancelOrder : null,
      edit: perms.includes('edit_orders_quick') ? this.editOrder : null,
      refund: perms.includes('refunds') ? this.refundOrder : null,
      viewDetails: this.viewDetails,
      print: this.printOrder,
      updatePrepStatus: this.updatePrepStatus
    }
    // const countMsg = `There are ${filtered.length} orders that match your current filters.`
    const fmt = 'EEE, MMM d @ h:mm:ss a'
    const lastRefreshed = isoToDateStr(lastUpdated || getCurrentISO(), fmt)
    const refreshMsg = `Last refreshed ${lastRefreshed}`
    const hrs = hours ? hours.hours : null
    const pastDue = getPastDueOrders(hrs, tz, orders)
    const printerType = makePrinterType(orderPrint, filtered)
    // orders.length ? console.log(JSON.stringify(orders[0], null, 2)) : null
    return (
      <>
        {!loading && (
          <div className="content__header order-mgmt__header">
            <h1>
              {store
                ? store.internal_name || store.full_name
                : 'Upcoming Orders'}
              {' - '}
              {orderType ? displayOrderType(orderType) : 'All'} Orders
            </h1>
            {pastDue.length ? (
              <div className="order-mgmt__alert">
                <div className="order-mgmt__alert__msg">
                  You have {pastDue.length} unconfirmed orders
                  <span className="alarm-status">{muted ? '[muted]' : ''}</span>
                </div>
              </div>
            ) : null}
          </div>
        )}
        <PageError msg={pageError} />
        <div className="content__main">
          {orderPrint && (
            <PrintOrderWrapper order={orderPrint} printerType={printerType} />
          )}
          {orderRouter && (
            <PrintOrderRouter
              args={{ filtered, orderType, orderStatus, businessDate, store }}
            />
          )}
          <AlarmSimple
            playing={pastDue.length ? true : false}
            muted={muted}
            setMuted={this.handleMuted}
          />
          {loading ? (
            <div className="table__loading">
              <ClipLoader size={36} color={'#5a5aff'} />
              <span>Retrieving orders...</span>
            </div>
          ) : (
            <>
              <div className="order-filters">
                <OrderTypeFilter
                  orderTypes={orderTypes}
                  orderType={orderType}
                  handler={this.handleOrderType}
                />
                <OrderInputs
                  print={this.printRouter}
                  preview={this.previewRouter}
                  businessDate={businessDate}
                  handleDate={this.handleDatepicker}
                  query={search.query}
                  queryInput={this.handleSearchInput}
                  queryEnter={this.handleSearchEnter}
                  queryClear={this.handleSearchClear}
                />
              </div>
              <div className="order-secondary">
                <div className="order-checkboxes">
                  {fields.map(field => (
                    <Checkbox
                      key={`order-status-${field.field}`}
                      field={field}
                      value={orderStatus.includes(field.field)}
                      handler={evt => this.handleOrderStatus(evt, field.field)}
                    />
                  ))}
                  {displayFields.map(field => (
                    <Checkbox
                      key={`display-${field.field}`}
                      field={field}
                      value={displaySettings.includes(field.field)}
                      handler={evt => this.handleDisplay(evt, field.field)}
                    />
                  ))}
                </div>
                <div className="order-alarm-refresh">
                  {/* <div className="order-alarm">
                    <PlayerSimple />
                  </div> */}
                  {!this.state.initialLoad && (
                    <div className="order-alarm">
                      <button className="btn-link" onClick={this.toggleMuted}>
                        <span className="order-alarm__text">
                          {muted ? 'Unmute' : 'Mute'}
                        </span>
                        <AlarmIcon muted={muted} />
                      </button>
                    </div>
                  )}
                  <div
                    className={`order-refresh ${
                      refreshing ? '-refreshing' : ''
                    }`}
                  >
                    <p>
                      <button className="btn-link" onClick={this.handleRefresh}>
                        <RefreshCw size={14} />
                      </button>
                      {refreshMsg}
                    </p>
                  </div>
                </div>
              </div>
              {search.error ? (
                <div className="order-message">
                  <p className="alert">
                    {search.error.includes('does not exist')
                      ? errMessages.orderNotFound
                      : search.error}
                  </p>
                </div>
              ) : search.result ? (
                <div className="order-message">
                  <p>
                    Order found! Please see below and{' '}
                    <button
                      className="btn-link"
                      onClick={this.handleSearchClear}
                    >
                      click here to clear this search
                    </button>
                    .
                  </p>
                </div>
              ) : null}
              {!grouped.length ? (
                <div className="order-group">
                  <div className="order-group__header">
                    <h2>No orders to display based on your current filters</h2>
                  </div>
                </div>
              ) : null}
              <TransitionGroup component={null}>
                {grouped.map(group => {
                  const count = group.orders.length
                  const dateStr =
                    group.day === 'all'
                      ? 'Displaying All Dates'
                      : group.day === currentBusinessDate
                      ? 'Today'
                      : dateStrToLocalDateStr(group.day, 'EEEE, MMMM d')
                  return (
                    <CSSTransition
                      timeout={{ enter: 500, exit: 500 }}
                      classNames="fade-up"
                      key={group.day}
                    >
                      <div className="order-group">
                        {!search.result && (
                          <div className="order-group__header">
                            <h2>
                              {dateStr} &mdash; {count} orders
                            </h2>
                          </div>
                        )}
                        <div className="order-cards">
                          <TransitionGroup component={null}>
                            {group.orders.map(order => (
                              <CSSTransition
                                timeout={{ enter: 500, exit: 500 }}
                                classNames="fade-up"
                                key={order.receipt_id}
                              >
                                <OrderCard
                                  order={order}
                                  dates={{ today, tomorrow }}
                                  hours={hrs}
                                  rcCounts={rcCounts}
                                  compact={displaySettings.includes('COMPACT')}
                                  handlers={handlers}
                                />
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </div>
                      </div>
                    </CSSTransition>
                  )
                })}
              </TransitionGroup>
            </>
          )}
        </div>
      </>
    )
  }
}

OrderMgmt.displayName = 'OrderMgmt'

export default connect(
  state => ({
    ...state.orderMgmt,
    pageError: state.page.error,
    store: state.item.data,
    deliveryWaitTimes: state.options['delivery-wait-times'],
    pickupWaitTimes: state.options['pickup-wait-times'],
    permissions: state.user.permissions
  }),
  {
    reloadOrders,
    refreshOrders,
    updateOrderType,
    toggleOrderStatus,
    toggleDisplay,
    markReviewed,
    orderAction,
    showEditOptions,
    viewDetails,
    printOrder,
    printRouter,
    previewRouter,
    updateBusinessDate,
    clearSearch,
    updateSearch,
    setMuted,
    toggleMuted,
    findOrder,
    redirectPage,
    showUpdatePrepStatus
  }
)(OrderMgmt)
