import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isObject } from '../lib/utils'
import { NavLink } from 'react-router-dom'
import { formatString, sortItems } from '../lib/helpers'
import { makeFieldValue } from '../lib/helpersPage'
import { objectTypes, orderTypeRevenueCenterMap } from '../lib/constants'
import {
  editRelationListItem,
  confirmDeleteRelationListItem,
  editRelationListItemRelations
} from '../reducers/relation'
import { openModal } from '../reducers/modal'
import { Select, Checkbox } from './TableInputs'
import { Download } from 'react-feather'
import { makeRequestedAt } from '../lib/helpersDatetime'
import IconLinks from './IconLinks'
import Drilldown from './buttons/Drilldown'
import OrderEdit from './buttons/OrderEdit'
import RemoveRelation from './buttons/RemoveRelation'
import MarkPaid from './buttons/MarkPaid'
import OrderEditFull from './buttons/OrderEditFull'
import OrderCancel from './buttons/OrderCancel'

const makeObjectTypeLookup = objectType => {
  return objectTypes[objectType].reduce((obj, i) => {
    obj[i.value] = i.name
    return obj
  }, {})
}

const makeSurchargeType = (type, val) => {
  const rounded = parseFloat(val).toFixed(2)
  switch (type) {
    case 'DOLLAR':
    case 'LOYALTY':
      return `$${rounded}`
    case 'PERCENTAGE':
      return `${rounded}%`
    case 'DISTANCE':
      return `$${rounded} / mile`
    case 'TRAVEL_TIME':
      return `$${rounded} / hour`
    default:
      return rounded
  }
}

const makeObjectValue = (item, field, format) => {
  const val = makeFieldValue(field[0], item)
  switch (format.type) {
    case 'surchargeType':
      return makeSurchargeType(item[format.field], val)
    default:
      return val
  }
}

const makeArrayValue = (items, entity, name, link, linkEndpoint) => {
  if (!items || !items.length) return ''
  const values = items.map(i => {
    const val = entity === 'null' ? i[name] : i[entity][name]
    if (!link) return { val: val }
    const id = entity === 'null' ? i[link] : i[entity][link]
    const itemLink = linkEndpoint.replace(':id', id)
    return { val: val, link: itemLink }
  })
  const sorted = sortItems(values, { sortBy: 'val', sortType: 'alpha' })
  if (!link) return sorted.map(i => i.val).join(', ')
  return sorted
}

const Swatch = ({ color }) => {
  const border = color === 'ffffff' ? '0.1rem solid #edeaff' : null
  return (
    <span
      style={{ backgroundColor: `#${color}`, border }}
      className="table__swatch"
      alt={`Color Swatch #${color}`}
    >
      &nbsp;
    </span>
  )
}

Swatch.displayName = 'Swatch'
Swatch.propTypes = {
  color: propTypes.string
}

export const makeVal = ({
  item,
  field,
  format,
  tz,
  string,
  separator = ' ',
  link,
  linkEndpoint
}) => {
  let val
  if (['links', 'button'].includes(format)) {
    val = ''
  } else if (['modal', 'path'].includes(format)) {
    val = field[0]
  } else if (format === 'hex_code') {
    val = `#${item[field[0]]}`
  } else if (format === 'orderTypeMap') {
    val = orderTypeRevenueCenterMap[item[field[0]]]
  } else if (format === 'task') {
    val = item.status === 'FAILED' ? 'n/a' : item[field[0]] || 'pending'
  } else if (format === 'swatch') {
    val = <Swatch color={item[field[0]]} />
  } else if (format === 'regenErrors') {
    val = item[field[0]] ? 'review errors' : 'n/a'
  } else if (isObject(format)) {
    val = makeObjectValue(item, field, format)
  } else if (string) {
    val = string.replace('{}', item[field[0]])
  } else if (format === 'address') {
    // val = field.map(f => item[f])
    val = field.map(f => makeFieldValue(f, item))
  } else if (format === 'array') {
    const [attr, entity, name] = field[0].split('.')
    val = item[attr]
      ? makeArrayValue(item[attr], entity, name, link, linkEndpoint)
      : ''
  } else if (format === 'object') {
    const obj = item[field[0]]
    const names = makeObjectTypeLookup(field[0])
    val = []
    for (var key in obj) {
      val.push({ name: names[key], id: obj[key] })
    }
  } else if (field.length === 1) {
    val = makeFieldValue(field[0], item)
  } else {
    val = field.map(f => makeFieldValue(f, item)).join(separator)
    val = val === ' ' ? '' : val
  }
  tz = tz ? item[tz] : null
  val = val === '--' ? '' : val
  val = format ? formatString(val, format, tz) : val || ''
  return val
}

const RegenErrors = ({ errors }) => {
  return (
    <div className="modal__errors">
      {errors.error && (
        <div className="modal__errors__section">
          <div className="modal__errors__subsection">
            <p>{errors.error}</p>
          </div>
        </div>
      )}
      {errors.categories ? (
        <div className="modal__errors__section">
          <h3>Menu Item Mapping Errors</h3>
          {errors.categories.map(category => (
            <div key={category.id} className="modal__errors__subsection">
              <p>
                {category.name} ({category.id})
              </p>
              <ul>
                {category.items.map(item => (
                  <li key={item.id}>
                    {item.name} ({item.id})
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
      {errors.groups ? (
        <div className="modal__errors__section">
          <h3>Modifier Mapping Errors</h3>
          {errors.categories.map(category => (
            <div key={category.id} className="modal__errors__subsection">
              <p>
                {category.name} ({category.id})
              </p>
              <ul>
                {category.items.map(item => (
                  <li key={item.id}>
                    {item.name} ({item.id})
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

RegenErrors.displayName = 'RegenErrors'
RegenErrors.propTypes = {
  errors: propTypes.object
}

const DownloadQRCode = ({ url }) => {
  return url ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="download-link download-qrcode"
    >
      <img src={url} alt="" />
    </a>
  ) : (
    'n/a'
  )
}

DownloadQRCode.displayName = 'DownloadQRCode'
DownloadQRCode.propTypes = {
  url: propTypes.string
}

const DownloadLinks = ({ urls }) => {
  const links = typeof urls === 'string' ? [urls] : urls
  return links.length ? (
    <>
      {links.map(href => (
        <a
          key={href}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link"
        >
          <span className="download-icon">
            <Download size={14} />
          </span>{' '}
          Download File
        </a>
      ))}
    </>
  ) : (
    'n/a'
  )
}

DownloadLinks.displayName = 'DownloadLinks'
DownloadLinks.propTypes = {
  urls: propTypes.oneOfType([propTypes.string, propTypes.array])
}

const renderButton = (type, item, idField, settings) => {
  if (settings && settings.type === 'drilldown') {
    return <Drilldown item={item} {...settings} />
  }
  switch (type) {
    case 'orderEdit':
      return <OrderEdit order={item} />
    case 'orderEditFull':
      return <OrderEditFull order={item} />
    case 'orderCancel':
      return <OrderCancel order={item} />
    case 'markPaid':
      return <MarkPaid invoice={item} />
    case 'remove':
      return <RemoveRelation itemId={item[idField]} />
    default:
      return ''
  }
}

class TableCell extends Component {
  static propTypes = {
    item: propTypes.object,
    endpoint: propTypes.string,
    relation: propTypes.string,
    field: propTypes.array,
    link: propTypes.string,
    linkEndpoint: propTypes.string,
    linkPath: propTypes.bool,
    pathname: propTypes.string,
    button: propTypes.string,
    name: propTypes.string,
    id: propTypes.string,
    options: propTypes.array,
    settings: propTypes.object,
    format: propTypes.oneOfType([propTypes.string, propTypes.object]),
    tz: propTypes.string,
    separator: propTypes.string,
    handler: propTypes.func,
    openModal: propTypes.func,
    editRelationListItem: propTypes.func,
    confirmDeleteRelationListItem: propTypes.func,
    editRelationListItemRelations: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    const { button, item, settings } = this.props
    if (settings.type) {
      const config = { ...settings, args: [item[button]] }
      this.props.openModal(config)
    } else {
      settings.items
        ? this.props.editRelationListItemRelations(item[button], settings)
        : settings.delete
        ? this.props.confirmDeleteRelationListItem(item[button], settings)
        : this.props.editRelationListItem(item[button], settings)
    }
    evt.target.blur()
  }

  handleRegenErrors = evt => {
    evt.preventDefault()
    const { errors, errorsType } = this.props.item
    const subtitle =
      errorsType === 'Mapping'
        ? 'The items below cannot be mapped'
        : errorsType === 'Price'
        ? 'The items below are missing prices'
        : 'The errors below are preventing your menus from generating'
    const config = {
      title: `${errorsType} Errors`,
      subtitle: subtitle,
      classes: 'modal--big',
      content: <RegenErrors errors={errors[0]} /> || null
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    const val = makeVal(this.props)
    const {
      item,
      endpoint,
      handler,
      button,
      field,
      format,
      link,
      linkEndpoint,
      linkPath,
      pathname,
      name,
      id,
      options,
      tz,
      settings
    } = this.props
    if (format === 'button') {
      return renderButton(field[0], item, id, settings)
    } else if (format === 'iconLinks') {
      return item[field] ? <IconLinks links={item[field]} /> : ''
    } else if (format === 'tooltip') {
      return val === '' ? (
        val
      ) : (
        <span className="table-tooltip">
          <span className="table-tooltip__trigger">View Notes</span>
          <span className="table-tooltip__content">{val}</span>
        </span>
      )
    } else if (format === 'qrcode') {
      return item[field] ? <DownloadQRCode url={item[field]} /> : 'pending'
    } else if (format === 'download') {
      return item[field] ? <DownloadLinks urls={item[field]} /> : 'pending'
    } else if (format === 'regenErrors') {
      if (!item[field[0]]) return val
      return (
        <button className="btn-link" onClick={this.handleRegenErrors}>
          {val}
        </button>
      )
    } else if (format === 'select') {
      return (
        <Select
          item={item}
          name={name}
          field={field}
          id={id}
          options={options}
          handler={handler}
        />
      )
    } else if (format === 'checkbox') {
      let checkboxItem = { ...item }
      if (name === 'Invoice' && item.tender_status !== 'AUTHORIZED') {
        checkboxItem = { ...item, isDisabled: true }
      }
      return (
        <Checkbox item={checkboxItem} id={id} name={name} handler={handler} />
      )
    } else if (format === 'modal') {
      return (
        <button className="btn-link" onClick={this.handleClick}>
          {val}
        </button>
      )
    } else if (button) {
      return (
        <button className="btn-link" onClick={this.handleClick}>
          {val}
        </button>
      )
    } else if (link) {
      if (linkPath) {
        return <NavLink to={`${pathname}/${item[link]}`}>{val}</NavLink>
      }
      if (format === 'array') {
        if (val === '') return val
        return (
          <>
            {val &&
              val.map((v, index) => (
                <span key={v.link}>
                  <NavLink to={v.link}>{v.val}</NavLink>
                  {index + 1 !== val.length ? ', ' : ''}
                </span>
              ))}
          </>
        )
      }
      const path = format === 'path' ? `/${field[1]}` : ''
      return (
        <NavLink
          to={`${linkEndpoint || endpoint}/${makeFieldValue(
            link,
            item
          )}${path}`}
        >
          {val}
        </NavLink>
      )
    } else if (format === 'object') {
      // console.log(val)
      return (
        <>
          {val &&
            val.map((prop, index) => (
              <>
                <NavLink key={prop.id} to={`${field}/${prop.id}`}>
                  {prop.name}
                </NavLink>
                {index + 1 !== val.length ? ', ' : ''}
              </>
            ))}
        </>
      )
    } else if (format === 'closed') {
      switch (val) {
        case true:
          return <span className="-alert">Closed</span>
        case 2:
          return <span className="">Inactive</span>
        default:
          return <span className="">Open</span>
      }
    } else if (format === 'timeRange') {
      const minutes =
        item.service_type === 'PICKUP'
          ? item.revenue_center.pickup_time_range
          : item.revenue_center.delivery_time_range
      return makeRequestedAt(val, item[tz], minutes, 'MMM d @ h:mma')
    } else if (format === 'enum') {
      return (
        <span className={`-${val.toLowerCase().replace(' ', '-')}`}>
          {val}
          {/* {val === 'Main Menu' ? 'OLO' : val || '--'} */}
        </span>
      )
    }
    if (format === 'bool' && val === 'No') {
      return <span className="alert-error">{val}</span>
    }
    return val
  }
}

TableCell.displayName = 'TableCell'

export default connect(null, {
  openModal,
  editRelationListItem,
  confirmDeleteRelationListItem,
  editRelationListItemRelations
})(TableCell)
