import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addNewRelationListItem } from '../../reducers/relation'
import Search from '../Search'

const defaults = {
  min_options: 0,
  max_options: 0,
  included_options: 0,
  menu_position: 0
}

class ItemModifierGroup extends Component {
  static propTypes = {
    addNewRelationListItem: propTypes.func
  }

  render() {
    return (
      <Search
        endpoint="modifier-groups"
        resultId="modifier_group_id"
        resultName="full_name"
        resultParens="short_name"
        placeholder="enter name of modifier group"
        resultAction={this.props.addNewRelationListItem}
        defaults={defaults}
      />
    )
  }
}

ItemModifierGroup.displayName = 'ItemModifierGroup'

export default connect(
  null,
  { addNewRelationListItem }
)(ItemModifierGroup)
