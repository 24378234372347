import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { items, itemLists, itemListItems } from './lib/pages'
import Auth from './components/Auth'
import Reset from './components/Reset'
import Page from './components/Page'
import Help from './components/Help'
import Profile from './components/Profile'
import NotFound from './components/NotFound'
import './App.scss'

// console.log('React version = ', React.version)

class App extends React.Component {
  componentDidMount() {
    document.getElementById('loading').remove()
  }

  render() {
    return (
      <Router>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path="/login" component={Auth} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/profile" component={Profile} />
          {/* <Route path="/print/:id" render={props => <Print {...props} />} /> */}
          {itemListItems.map(i => (
            <Route
              key={`${i.parent}-${i.child}-item`}
              path={`/${i.parent}/:id/${i.child}/:idd`}
              render={props => <Page {...props} />}
            />
          ))}
          {itemLists.map(i => (
            <Route
              key={`${i.parent}-${i.child}`}
              path={`/${i.parent}/:id/${i.child}`}
              render={props => <Page {...props} />}
            />
          ))}
          {items.map(entity => (
            <Route
              key={entity}
              path={`/${entity}/:id`}
              render={props => <Page {...props} />}
            />
          ))}
          <Route render={props => <Page {...props} />} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

// export default hot(module)(App)
export default App
