import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addTenderCredit, tenderCreditNew } from '../../reducers/order'
import { showModalErrors } from '../../reducers/modal'
import Form from '../Form'
import { PageError } from '../PageError'
import { valTypes, cardTypes } from '../../lib/constants'
import { tendersTotalAndBalance } from '../../lib/helpersOrder'

const makeFields = cards => {
  let cardOptions = cards.map(i => {
    const name = `${cardTypes[i.card_type_id]} ending in ${i.last4}`
    return { value: i.customer_card_id, name: name }
  })
  cardOptions = [...cardOptions]
  return [
    {
      label: 'Dollar Amount',
      field: 'amount',
      type: 'text',
      valType: valTypes.decimal
    },
    {
      label: 'Select A Saved Card',
      type: 'select',
      field: 'customer_card_id',
      options: cardOptions
    },
    {
      field: 'tenderType',
      type: 'hidden'
    }
  ]
}

class TenderCreditNew extends Component {
  static propTypes = {
    // state.order.customer
    cards: propTypes.array,
    tenders: propTypes.array,
    orderTotal: propTypes.string,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    addTenderCredit: propTypes.func,
    tenderCreditNew: propTypes.func,
    showModalErrors: propTypes.func
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.tenderCreditNew()
    evt.target.blur()
  }

  render() {
    const { cards, tenders, orderTotal, errors } = this.props
    const [, remaining] = tendersTotalAndBalance(tenders, orderTotal)
    const data = { amount: remaining, tenderType: 'CREDIT' }
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={true}
          idField="customer_id"
          fields={makeFields(cards)}
          data={data}
          errors={errors || {}}
          upsertItem={this.props.addTenderCredit}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
        <div className="modal__footer">
          <button type="button" className="btn-link" onClick={this.handleNew}>
            Or click here to add a new card
          </button>
        </div>
      </>
    )
  }
}

TenderCreditNew.displayName = 'TenderCreditNew'

export default connect(
  state => ({
    cards: state.order.cards,
    tenders: state.order.tenders,
    orderTotal: state.order.order.total,
    errors: state.modal.errors
  }),
  { addTenderCredit, tenderCreditNew, showModalErrors }
)(TenderCreditNew)
