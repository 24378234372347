import { request } from '../lib/services'
import { flashMessage } from './messages'
import { handleError } from '../lib/errors'
import { openModal, closeModal, loadingModal } from './modal'
import { handlePageError } from './page'

const initState = {
  loading: false,
  data: null,
  isActive: false,
  expiration: null,
  customer: null,
  amount: '',
  error: ''
}

export const GIFT_CARD_CLEAR = 'GIFT_CARD_CLEAR'
export const GIFT_CARD_LOADING = 'GIFT_CARD_LOADING'
export const GIFT_CARD_LOAD = 'GIFT_CARD_LOAD'
export const GIFT_CARD_ERROR = 'GIFT_CARD_ERROR'
export const GIFT_CARD_UPDATE = 'GIFT_CARD_UPDATE'
export const GIFT_CARD_CREDIT = 'GIFT_CARD_CREDIT'

export const clearGiftCard = () => ({ type: GIFT_CARD_CLEAR })
export const loadingGiftCard = () => ({ type: GIFT_CARD_LOADING })
export const loadGiftCard = data => ({ type: GIFT_CARD_LOAD, payload: data })
export const updateGiftCard = data => ({
  type: GIFT_CARD_UPDATE,
  payload: data
})
export const updateCredit = value => ({
  type: GIFT_CARD_CREDIT,
  payload: value
})
export const showGiftCardError = msg => ({
  type: GIFT_CARD_ERROR,
  payload: msg
})

export const fetchCustomer = customerId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/customers/${customerId}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(updateGiftCard({ customer: resp }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchGiftCard = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(loadingGiftCard())
    const endpoint = `/gift-cards/${page.id}?expand=true`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadGiftCard(resp))
        if (resp.customer_id) dispatch(fetchCustomer(resp.customer_id))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

const makeUpdate = (data, values) => {
  const current = {
    customer_id: data.customer_id || null,
    employee_id: data.employee_id || null,
    expiration: data.expiration,
    is_active: data.is_active,
    pin: data.pin,
    store_id: data.store_id || null
  }
  return { ...current, ...values }
}

export const upsertGiftCard = values => {
  return (dispatch, getState) => {
    const { token, brand, page, giftCard } = getState()
    const data = makeUpdate(giftCard.data, values)
    const endpoint = `/gift-cards/${page.id}`
    request(token, brand, endpoint, 'PUT', data)
      .then(resp => {
        dispatch(loadGiftCard(resp))
        if (resp.customer_id) dispatch(fetchCustomer(resp.customer_id))
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        const errors = handleError(err)
        const msg = errors.fields
          ? Object.values(errors.fields)[0]
          : errors.form
        err.detail = msg || err.detail || err.message
        dispatch(handlePageError(err))
      })
  }
}

export const updateStatus = () => {
  return (dispatch, getState) => {
    const { giftCard } = getState()
    const values = { is_active: !giftCard.data.is_active }
    dispatch(upsertGiftCard(values))
  }
}

export const updateExpiration = expiration => {
  return dispatch => {
    const values = { expiration: expiration }
    dispatch(upsertGiftCard(values))
  }
}

export const addCustomer = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Customer',
      subtitle: 'Add or update customer',
      classes: 'modal--big',
      type: 'giftCardCustomer'
    }
    dispatch(openModal(config))
  }
}

export const updateCustomer = customerId => {
  return dispatch => {
    dispatch(closeModal())
    const values = { customer_id: customerId }
    dispatch(upsertGiftCard(values))
  }
}

export const removeCustomer = () => {
  return dispatch => {
    const values = { customer_id: null }
    dispatch(upsertGiftCard(values))
  }
}

export const addCredit = () => {
  return (dispatch, getState) => {
    const { token, brand, page, giftCard } = getState()
    const endpoint = `/gift-cards/${page.id}/credit`
    const values = { amount: giftCard.amount }
    return request(token, brand, endpoint, 'POST', values)
      .then(() => {
        dispatch(updateCredit(''))
        dispatch(fetchGiftCard())
        dispatch(flashMessage('Credit updated!'))
      })
      .catch(err => {
        err.detail = 'Invalid amount. Please try again'
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case GIFT_CARD_CLEAR:
      return { ...initState }
    case GIFT_CARD_LOADING:
      return { ...state, loading: true }
    case GIFT_CARD_LOAD:
      return { ...state, data: action.payload, loading: false, error: '' }
    case GIFT_CARD_ERROR:
      return { ...state, error: action.payload, loading: false }
    case GIFT_CARD_CREDIT:
      return { ...state, amount: action.payload }
    case GIFT_CARD_UPDATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
