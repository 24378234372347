import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { isEmpty } from '../lib/utils'
import { errMessages } from '../lib/errors'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import { PageError } from './PageError'
import { HoursInput } from './Hours'
import { Input } from './TableInputs'
import {
  updateHolidayTime,
  updateField,
  updateHolidays,
  addNewHoliday,
  cancelNewHoliday,
  removeHoliday,
  addHolidayToAll
} from '../reducers/hours'
import { MoreHorizontal } from 'react-feather'

const hoursFields = [
  ['PICKUP', 'open'],
  ['PICKUP', 'close'],
  ['DELIVERY', 'open'],
  ['DELIVERY', 'close']
]

class Holidays extends Component {
  constructor(props) {
    super(props)
    this.state = { index: null }
  }

  static propTypes = {
    loading: propTypes.bool,
    holidays: propTypes.array,
    errors: propTypes.object,
    updateHolidayTime: propTypes.func,
    updateField: propTypes.func,
    updateHolidays: propTypes.func,
    addNewHoliday: propTypes.func,
    cancelNewHoliday: propTypes.func,
    removeHoliday: propTypes.func,
    addHolidayToAll: propTypes.func
  }

  handleInput = evt => {
    const [index, field] = evt.target.id.split('-')
    this.props.updateField(parseInt(index), field, evt.target.value)
  }

  handleHoursInput = evt => {
    const [index, serviceType, hourType] = evt.target.id.split('-')
    this.props.updateHolidayTime(
      parseInt(index),
      serviceType,
      hourType,
      evt.target.value
    )
  }

  handleMore = (evt, index) => {
    evt.preventDefault()
    this.setState({ index })
    evt.target.blur()
  }

  handleRemove = (evt, index) => {
    evt.preventDefault()
    this.props.removeHoliday(index)
    this.setState({ index: null })
    evt.target.blur()
  }

  handleRemoveAll = (evt, index) => {
    evt.preventDefault()
    this.props.removeHoliday(index, true)
    this.setState({ index: null })
    evt.target.blur()
  }

  handleAddAll = (evt, index) => {
    evt.preventDefault()
    this.props.addHolidayToAll(index)
    this.setState({ index: null })
    evt.target.blur()
  }

  handleCancel = evt => {
    evt.preventDefault()
    this.props.cancelNewHoliday()
    this.setState({ index: null })
    evt.target.blur()
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.addNewHoliday()
    this.setState({ index: null })
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateHolidays()
    this.setState({ index: null })
    evt.target.blur()
  }

  render() {
    const { loading, holidays, errors } = this.props
    const errMsg = !isEmpty(errors) ? errMessages.invalidHours : ''
    return (
      <div className="content__main">
        <PageError msg={errMsg} />
        {loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!loading && holidays ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="table__wrapper">
                <table className="table--holidays table--light">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th colSpan="2">Pickup</th>
                      <th colSpan="2">Delivery</th>
                      <th>&nbsp;</th>
                    </tr>
                    <tr>
                      <th>Weekday</th>
                      <th>Date</th>
                      <th>
                        <span>Open</span>
                      </th>
                      <th>
                        <span>Close</span>
                      </th>
                      <th>
                        <span>Open</span>
                      </th>
                      <th>
                        <span>Close</span>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holidays.map((weekday, index) => {
                      const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
                      const active = index === this.state.index ? '-active' : ''
                      return (
                        <tr key={index} className={evenOdd}>
                          <td>
                            <Input
                              field={`${index}-name`}
                              value={weekday.name}
                              placeholder="holiday name"
                              error={errors[`${index}-name`] || ''}
                              handler={this.handleInput}
                            />
                          </td>
                          <td>
                            <Input
                              field={`${index}-date`}
                              value={weekday.date}
                              placeholder="MM/DD"
                              error={errors[`${index}-date`] || ''}
                              handler={this.handleInput}
                            />
                          </td>
                          {hoursFields.map(field => {
                            const [serviceType, hourType] = field
                            const key = `${index}-${serviceType}-${hourType}`
                            return (
                              <HoursInput
                                key={key}
                                weekday={weekday}
                                name={index}
                                serviceType={serviceType}
                                hourType={hourType}
                                handler={this.handleHoursInput}
                                error={errors[key]}
                              />
                            )
                          })}
                          <td>
                            <span className="more">
                              <button
                                className={`btn-link btn-more ${active}`}
                                onClick={evt => this.handleMore(evt, index)}
                              >
                                <MoreHorizontal size={14} />
                              </button>
                              <span className="more__buttons">
                                {weekday.isNew ? (
                                  <button
                                    className="btn-link more__button -remove"
                                    onClick={evt =>
                                      this.handleCancel(evt, index)
                                    }
                                  >
                                    Cancel
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="btn-link more__button -remove"
                                      onClick={evt =>
                                        this.handleRemove(evt, index)
                                      }
                                    >
                                      Remove
                                    </button>
                                    <button
                                      className="btn-link more__button -remove"
                                      onClick={evt =>
                                        this.handleRemoveAll(evt, index)
                                      }
                                    >
                                      Remove From All
                                    </button>
                                    <button
                                      className="btn-link more__button"
                                      onClick={evt =>
                                        this.handleAddAll(evt, index)
                                      }
                                    >
                                      Add To All
                                    </button>
                                  </>
                                )}
                              </span>
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="form__submit">
                  <button className="btn" onClick={this.handleSubmit}>
                    Update
                  </button>
                  <button
                    className="btn btn--secondary"
                    onClick={this.handleNew}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Holidays.displayName = 'Holidays'

export default connect(
  state => ({
    ...state.hours
  }),
  {
    updateHolidayTime,
    updateField,
    updateHolidays,
    addNewHoliday,
    cancelNewHoliday,
    removeHoliday,
    addHolidayToAll
  }
)(Holidays)
