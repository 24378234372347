import propTypes from 'prop-types'
import React from 'react'
import Button from './Button'
import ButtonCSV from './reporting/ButtonCSV'

const TableRefresh = ({ refreshItems, csvEndpoint, reportCsvEndpoint }) => {
  return (
    <div className="table__refresh">
      <button className="btn" onClick={refreshItems}>
        Load Results
      </button>
      {reportCsvEndpoint ? (
        <ButtonCSV endpoint={reportCsvEndpoint} />
      ) : csvEndpoint ? (
        <Button text="Download CSV" endpoint={csvEndpoint} />
      ) : null}
    </div>
  )
}

TableRefresh.displayName = 'Table'
TableRefresh.propTypes = {
  refreshItems: propTypes.func,
  downloadCSV: propTypes.func
}

export default TableRefresh
