import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../reducers/modal'
import { orderAction } from '../../reducers/orderMgmt'

class OrderClose extends Component {
  static propTypes = {
    orderId: propTypes.number,
    orderAction: propTypes.func,
    closeModal: propTypes.func
  }

  handleClose = evt => {
    evt.preventDefault()
    const msg = 'Order closed successfully!'
    this.props.orderAction(this.props.orderId, 'close', msg, true)
    evt.target.blur()
  }

  handleCloseModal = evt => {
    evt.preventDefault()
    this.props.closeModal()
    evt.target.blur()
  }

  render() {
    const { orderId } = this.props
    return (
      <div className="order-close text">
        <p>
          Are you sure {"you're"} ready to close order #{orderId}?
        </p>
        <p>
          This will charge the customer and prevent you from making any
          additional adjustments, including changes to the tip.
        </p>
        <div className="btn-group">
          <button
            className="btn-group__btn -last-2nd-odd"
            onClick={this.handleClose}
          >
            <span>Yes, Close Order</span>
          </button>
          <button
            className="btn-group__btn -last-even"
            onClick={this.handleCloseModal}
          >
            <span>Nevermind, Not Now</span>
          </button>
        </div>
      </div>
    )
  }
}

OrderClose.displayName = 'OrderClose'

export default connect(null, { orderAction, closeModal })(OrderClose)
