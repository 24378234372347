import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateOrderType } from '../../reducers/order'

class OrderType extends Component {
  static propTypes = {
    updateOrderType: propTypes.func
  }

  handleOrderType = evt => {
    evt.preventDefault()
    this.props.updateOrderType(evt.target.id)
    evt.target.blur()
  }

  render() {
    return (
      <div className="surcharges btn-group">
        <button
          id="MAIN_MENU"
          className="btn-group__btn -last-2nd-odd"
          onClick={this.handleOrderType}
        >
          <span>Online Order</span>
        </button>
        <button
          id="CATERING"
          className="btn-group__btn -last-even"
          onClick={this.handleOrderType}
        >
          <span>Catering Order</span>
        </button>
      </div>
    )
  }
}

OrderType.displayName = 'OrderType'

export default connect(
  null,
  { updateOrderType }
)(OrderType)
