import { request } from '../lib/services'
import { makeQueryParams } from '../lib/helpers'
import { makeDateValues } from '../lib/helpersDatetime'
import { flashMessage } from './messages'
import {
  clearPageError,
  handlePageError,
  updatePage,
  makeNewFilters,
  makeResetFilters,
  redirectPage
} from './page'
import { closeModal, loadingModal, openModal, showModalErrors } from './modal'
import { handleError } from '../lib/errors'
// import sales from '../data/salesSummary02.json'
// import sales from '../data/salesByMonth.json'

const initState = {
  loading: false,
  data: null,
  range: null,
  filters: null,
  refreshNeeded: false
}

export const REPORT_CLEAR = 'REPORT_CLEAR'
export const REPORT_LOADING = 'REPORT_LOADING'
export const REPORT_LOAD = 'REPORT_LOAD'
export const REPORT_FILTERS = 'REPORT_FILTERS'
export const REPORT_RANGE = 'REPORT_RANGE'
export const REPORT_REFRESH_NEEDED = 'REPORT_REFRESH_NEEDED'

export const clearReport = () => ({ type: REPORT_CLEAR })
export const loadingReport = () => ({ type: REPORT_LOADING })
export const loadReport = data => ({ type: REPORT_LOAD, payload: data })
export const updateRange = range => ({ type: REPORT_RANGE, payload: range })
export const updateFilters = filters => ({
  type: REPORT_FILTERS,
  payload: filters
})
export const updateRefresh = bool => ({
  type: REPORT_REFRESH_NEEDED,
  payload: bool
})

export const fetchReport = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { endpoints, filters, pathParam } = page.report
    const missingFilters = filters.filter(i => i.required && !i.value)
    if (missingFilters.length) return dispatch(clearReport())
    dispatch(clearPageError())
    dispatch(loadingReport())
    let params = makeQueryParams(filters || [])
    if (pathParam) {
      params += `${params ? '&' : ''}${pathParam}=${page.id}`
    }
    const requests = endpoints.map(i => {
      const endpoint = i + (params ? `?${params}` : '')
      return request(token, brand, endpoint, 'GET')
    })
    Promise.all(requests)
      .then(resp => {
        // resp[0].map(i => console.log(i.store))
        // console.log(JSON.stringify(resp, null, 2))
        dispatch(loadReport(resp))
        dispatch(flashMessage('Report retrieved!'))
      })
      .catch(err => {
        dispatch(clearReport())
        dispatch(handlePageError(err))
      })
  }
}

export const refreshReport = () => {
  return dispatch => {
    dispatch(fetchReport())
  }
}

const mapFilters = filters => {
  return filters.reduce((obj, i) => {
    obj[i.field] = i.value
    return obj
  }, {})
}

// make sure end date never precedes the start date and
// set the end date equal to the start date if the start date
// changes in order to avoid unintentional long date ranges
export const updateFilter = (filter, autoRefresh = true) => {
  return (dispatch, getState) => {
    dispatch(updateRange(null))
    const { report } = getState().page
    let newFilters = makeNewFilters(report.filters, filter)
    const mapped = mapFilters(newFilters)
    const isStart = filter.field === 'start_date'
    let isIllegalEnd = false
    if (filter.field === 'end_date') {
      const { start_date, end_date } = mapped
      isIllegalEnd =
        start_date && end_date && end_date < start_date ? true : false
    }
    if (isStart || isIllegalEnd) {
      const endFilter = report.filters.find(i => i.field === 'end_date')
      const endValue = isStart ? filter.value : mapped.start_date
      const newEndFilter = { ...endFilter, value: endValue }
      newFilters = makeNewFilters(newFilters, newEndFilter)
    }
    const newReport = { ...report, filters: newFilters }
    dispatch(updatePage({ report: newReport }))
    if (autoRefresh) dispatch(fetchReport())
  }
}

export const resetFilters = (autoRefresh = true) => {
  return (dispatch, getState) => {
    const { report } = getState().page
    const newFilters = makeResetFilters(report.filters)
    const newReport = { ...report, filters: newFilters }
    dispatch(updatePage({ report: newReport }))
    if (autoRefresh) dispatch(fetchReport())
  }
}

export const downloadCsv = endpoint => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { filters } = page.report
    dispatch(clearPageError())
    const params = makeQueryParams(filters || [])
    const csvEndpoint = endpoint + (params ? `?${params}` : '')
    request(token, brand, csvEndpoint, 'POST')
      .then(() => dispatch(redirectPage('/downloads')))
      .catch(err => dispatch(handlePageError(err)))
  }
}

export const updateDateRange = (range, autoRefresh = true) => {
  return (dispatch, getState) => {
    dispatch(updateRange(range))
    const { brand, page } = getState()
    const { start, end } = makeDateValues(range, brand.start_day)
    // need to do it this way to avoid mutating the existing filters
    let newFilters = []
    page.report.filters.map(i => {
      if (i.field === 'start_date') {
        newFilters.push({ ...i, value: start })
      } else if (i.field === 'end_date') {
        newFilters.push({ ...i, value: end })
      } else {
        newFilters.push({ ...i })
      }
    })
    // console.log(newFilters)
    // console.log(newFilters.map(i => console.log(i.value)))
    const newReport = { ...page.report, filters: newFilters }
    dispatch(updatePage({ report: newReport }))
    if (autoRefresh) dispatch(fetchReport())
  }
}

export const drilldown = (storeId, redirect) => {
  return (dispatch, getState) => {
    const { page } = getState()
    const { filters } = page.report
    const mappedFilters = filters.reduce(
      (obj, i) => ({ ...obj, [i.field]: i.value }),
      {}
    )
    mappedFilters.store_id = storeId
    dispatch(updateFilters(mappedFilters))
    dispatch(redirectPage(redirect))
  }
}

export const editDeclaredCash = (cash_transfer_id, amount) => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Update declared cash',
      type: 'declaredCash',
      args: { cash_transfer_id, amount }
    }
    dispatch(openModal(config))
  }
}

export const updateDeclaredCash = values => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(clearPageError())
    const { cash_transfer_id, amount } = values
    const endpoint = `/cash-transfers/${cash_transfer_id}`
    const data = { amount }
    return request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        dispatch(fetchReport())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case REPORT_CLEAR:
      return { ...initState }
    case REPORT_LOADING:
      return { ...state, loading: true }
    case REPORT_LOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        refreshNeeded: false
      }
    case REPORT_RANGE:
      return { ...state, range: action.payload }
    case REPORT_FILTERS:
      return { ...state, filters: action.payload }
    case REPORT_REFRESH_NEEDED:
      return {
        ...state,
        refreshNeeded: action.payload,
        data: action.payload === true ? null : state.data
      }
    default:
      return state
  }
}
