import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updatePunch } from '../../reducers/punches'
import { showModalErrors } from '../../reducers/modal'
import { punchInTypes, punchOutTypes } from '../../lib/constants'
import Form from '../Form'
import { PageError } from '../PageError'

const fields = punchType => {
  const isPunchIn = ['PUNCH_IN', 'BREAK_IN'].includes(punchType)
  const punchTypes = isPunchIn ? punchInTypes : punchOutTypes
  let formFields = [
    {
      label: 'Punch Type',
      field: 'punch_type',
      type: 'select',
      options: punchTypes
    },
    {
      label: 'Punch Time',
      field: 'punch_time',
      type: 'text'
    },
    {
      field: 'time_punch_id',
      type: 'hidden'
    }
  ]
  if (!isPunchIn) {
    const tipsField = {
      label: 'Tip Amount',
      field: 'tips_amount',
      type: 'text'
    }
    formFields = [...formFields, tipsField]
  }
  return formFields
}

class Punch extends Component {
  static propTypes = {
    // props
    data: propTypes.object,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    updatePunch: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { data, errors } = this.props
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={false}
          idField="time_punch_id"
          fields={fields(data.punch_type)}
          data={data || {}}
          errors={errors || {}}
          upsertItem={this.props.updatePunch}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </>
    )
  }
}

Punch.displayName = 'Punch'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { updatePunch, showModalErrors }
)(Punch)
