import { request, fetchAll } from '../lib/services'
import { handlePageError, clearPageError } from './page'
import { flashMessage } from './messages'
import { makeFilterLookup } from '../lib/helpersPage'
import { weekdaysLower, revenueCenters } from '../lib/constants'
import { makeCategories, makeGroups } from './options'

const initState = {
  loading: false,
  filters: [],
  items: null,
  restrictions: null,
  disabled: null,
  hasChanges: false,
  hasUpdates: false,
  error: ''
}

export const DAILY_ITEMS_CLEAR = 'DAILY_ITEMS_CLEAR'
export const DAILY_ITEMS_FILTERS = 'DAILY_ITEMS_FILTERS'
export const DAILY_ITEMS_LOADING = 'DAILY_ITEMS_LOADING'
export const DAILY_ITEMS_LOADING_STOP = 'DAILY_ITEMS_LOADING_STOP'
export const DAILY_ITEMS_LOAD = 'DAILY_ITEMS_LOAD'
export const DAILY_ITEMS_ERRORS = 'DAILY_ITEMS_ERRORS'
export const DAILY_ITEMS_UPDATE = 'DAILY_ITEMS_UPDATE'
export const DAILY_ITEMS_CHANGED = 'DAILY_ITEMS_CHANGED'
export const DAILY_ITEMS_UPDATED = 'DAILY_ITEMS_UPDATED'

export const clearDailyItems = () => ({ type: DAILY_ITEMS_CLEAR })
export const updateFilters = filters => ({
  type: DAILY_ITEMS_FILTERS,
  payload: filters
})
export const loadingDailyItems = () => ({ type: DAILY_ITEMS_LOADING })
export const stopLoading = () => ({ type: DAILY_ITEMS_LOADING_STOP })
export const loadDailyItems = data => ({
  type: DAILY_ITEMS_LOAD,
  payload: data
})
export const showDailyItemsError = msg => ({
  type: DAILY_ITEMS_ERRORS,
  payload: msg
})

export const dailyItemsChanged = bool => ({
  type: DAILY_ITEMS_CHANGED,
  payload: bool
})
export const dailyItemsUpdated = bool => ({
  type: DAILY_ITEMS_UPDATED,
  payload: bool
})

const makeFilters = (options, rcType = 'OLO') => {
  const rcTypeFilter = {
    label: 'Revenue Center Type',
    type: 'select',
    field: 'revenue_center_type',
    value: rcType,
    options: [...revenueCenters].slice(1)
  }
  const rcsOfType = options['revenue-centers'][rcType]
  if (!rcsOfType || !rcsOfType.length) {
    const rcFilter = {
      label: 'Revenue Center',
      type: 'select',
      field: 'revenue_center_id',
      value: '',
      options: [{ value: '', name: 'none of this type' }]
    }
    return [rcTypeFilter, rcFilter]
  }
  const rcs = options['revenue-centers'][rcType].map(i => ({
    value: i.revenue_center_id,
    name: i.full_name
  }))
  const rcFilter = {
    label: 'Revenue Center',
    type: 'select',
    field: 'revenue_center_id',
    value: rcs.length ? rcs[0].value : '',
    options: rcs
  }
  return [rcTypeFilter, rcFilter]
}

const matchMenu = (menus, menuId) => {
  const menuIds = menus.map(i => i.menu.menu_id)
  return menuIds.length ? menuIds.includes(menuId) : true
}

const fetchMenuFilters = revenueCenterId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/revenue-centers/${revenueCenterId}?with_related=menus`
    return request(token, brand, endpoint)
      .then(resp => {
        const menuId = resp.menus.length ? resp.menus[0].menu.menu_id : null
        if (menuId) {
          const catEndpoint = `/categories?menu_id=${menuId}&is_active=true&with_related=menus&limit=1000`
          const catRequest = request(token, brand, catEndpoint)
          const modEndpoint = `/modifier-groups?is_active=true&with_related=menus&limit=1000`
          const modRequest = request(token, brand, modEndpoint)
          return Promise.all([catRequest, modRequest])
            .then(([catResp, modResp]) => {
              let categories = catResp.data.map(i => {
                const menus = i.menus.filter(m => m.menu.menu_id === menuId)
                return { ...i, menus }
              })
              const catsByMenu = makeCategories(categories, true)
              categories = [{ value: '', name: 'none selected' }, ...catsByMenu]
              const firstCat = categories.find(i => !i.isDisabled && i.value)
              const categoryFilter = {
                label: 'Category',
                type: 'select',
                field: 'category_id',
                value: firstCat ? firstCat.value : '',
                options: categories
              }
              let groups = modResp.data.filter(i => matchMenu(i.menus, menuId))
              // groups = groups.filter(i => i.is_editable)
              // groups = groups.map(i => ({
              //   value: i.modifier_group_id,
              //   name: i.short_name || i.full_name
              // }))
              // groups = sortItems(groups, { sortBy: 'name', sortType: 'alpha' })
              // groups = [{ value: '', name: 'none selected' }, ...groups]
              const modsByMenu = makeGroups(groups, true)
              groups = [{ value: '', name: 'none selected' }, ...modsByMenu]
              // console.log(groups)
              const groupsFilter = {
                label: 'Modifier Group',
                type: 'select',
                field: 'modifier_group_id',
                value: '',
                options: groups
              }
              return [categoryFilter, groupsFilter]
            })
            .catch(err => {
              dispatch(handlePageError(err))
              dispatch(showDailyItemsError(''))
            })
        }
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showDailyItemsError(''))
      })
  }
}

const fetchDailyItemAvails = filters => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const lookup = makeFilterLookup(filters)
    const itemFilter = lookup.category_id || lookup.modifier_group_id
    if (!itemFilter) return dispatch(stopLoading())
    dispatch(loadingDailyItems())
    const params = `${itemFilter.field}=${itemFilter.value}`
    const itemsPromise = request(token, brand, `/items?${params}&limit=1000`)
    const revenueCenterId = lookup.revenue_center_id.value
    const endpoint = `/daily-items?revenue_center_id=${revenueCenterId}&${params}&limit=1000`
    const dailyItemsPromise = fetchAll(token, brand, endpoint)
    const restrictedEndpoint = endpoint.replace('daily', 'restricted')
    const restrictedPromise = fetchAll(token, brand, restrictedEndpoint)
    Promise.all([itemsPromise, dailyItemsPromise, restrictedPromise])
      .then(values => {
        let [itemsResp, restrictedResp, disabledResp] = values
        const items = itemsResp.data
        const restrictions = restrictedResp
        const disabled = disabledResp.map(i => i.item_id)
        dispatch(loadDailyItems({ items, restrictions, disabled }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showDailyItemsError(''))
      })
  }
}

export const fetchDailyItems = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(clearDailyItems())
    const { options } = getState()
    const filters = makeFilters(options)
    dispatch(updateFilters(filters))
    const revenueCenterId = filters[1].value
    if (!revenueCenterId) {
      dispatch(updateFilters(filters))
      const err = new Error(
        'This user does not have access to any revenue centers of this type'
      )
      dispatch(handlePageError(err))
      return dispatch(showDailyItemsError(''))
    }
    dispatch(fetchMenuFilters(revenueCenterId)).then(menuFilters => {
      if (filters && menuFilters) {
        const allFilters = [...filters, ...menuFilters]
        dispatch(updateFilters(allFilters))
        dispatch(fetchDailyItemAvails(allFilters))
      }
    })
  }
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(dailyItemsChanged(false))
    const { filters } = getState().dailyItems
    dispatch(loadingDailyItems())
    let newFilters
    if (filter.field === 'category_id') {
      newFilters = filters.map(i => {
        switch (i.field) {
          case 'category_id':
            return { ...i, value: parseInt(filter.value) || '' }
          case 'modifier_group_id':
            return { ...i, value: '' }
          default:
            return i
        }
      })
      dispatch(updateFilters(newFilters))
      dispatch(fetchDailyItemAvails(newFilters))
    } else if (filter.field === 'modifier_group_id') {
      newFilters = filters.map(i => {
        switch (i.field) {
          case 'category_id':
            return { ...i, value: '' }
          case 'modifier_group_id':
            return { ...i, value: parseInt(filter.value) || '' }
          default:
            return i
        }
      })
      dispatch(updateFilters(newFilters))
      dispatch(fetchDailyItemAvails(newFilters))
    } else if (filter.field === 'revenue_center_id') {
      const revenueCenterId = parseInt(filter.value)
      newFilters = filters
        .map(i => {
          switch (i.field) {
            case 'revenue_center_id':
              return { ...i, value: revenueCenterId }
            default:
              return i
          }
        })
        .slice(0, 2)
      dispatch(fetchMenuFilters(revenueCenterId)).then(menuFilters => {
        if (newFilters && menuFilters) {
          const allFilters = [...newFilters, ...menuFilters]
          dispatch(updateFilters(allFilters))
          dispatch(fetchDailyItemAvails(allFilters))
        }
      })
    } else if (filter.field === 'revenue_center_type') {
      newFilters = makeFilters(getState().options, filter.value)
      const revenueCenterId = newFilters[1].value
      if (!revenueCenterId) {
        dispatch(updateFilters(newFilters))
        const err = new Error(
          'This user does not have access to any revenue centers of this type'
        )
        dispatch(handlePageError(err))
        return dispatch(showDailyItemsError(''))
      }
      dispatch(fetchMenuFilters(revenueCenterId)).then(menuFilters => {
        if (newFilters && menuFilters) {
          const allFilters = [...newFilters, ...menuFilters]
          dispatch(updateFilters(allFilters))
          dispatch(fetchDailyItemAvails(allFilters))
        }
      })
    }
  }
}

const defaultWeekdays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false
}

const restrictedWeekdays = {
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true
}

const makeNewRestrction = (itemId, revenueCenterId, weekday, active) => {
  return {
    ...defaultWeekdays,
    [weekday]: !active,
    item_id: itemId,
    revenue_center_id: revenueCenterId
  }
}

export const updateRestriction = (itemId, weekday, active) => {
  return (dispatch, getState) => {
    const { dailyItems } = getState()
    const lookup = makeFilterLookup(dailyItems.filters)
    const revenueCenterId = parseInt(lookup.revenue_center_id.value)
    const restriction = dailyItems.restrictions.filter(
      i => i.item_id === itemId
    )
    const newRestriction = restriction.length
      ? { ...restriction[0], [weekday]: !active }
      : makeNewRestrction(itemId, revenueCenterId, weekday, active)
    const other = dailyItems.restrictions.filter(i => i.item_id !== itemId)
    const newRestrictions = [...other, newRestriction]
    const update = { restrictions: newRestrictions, hasChanges: true }
    dispatch(loadDailyItems(update))
  }
}

const makeItem = (restriction, itemId, revenueCenterId) => {
  const count = restriction.length
    ? weekdaysLower.filter(i => restriction[0][i]).length
    : 0
  const newWeekdays = count < 7 ? restrictedWeekdays : defaultWeekdays
  return {
    ...newWeekdays,
    item_id: itemId,
    revenue_center_id: revenueCenterId
  }
}

export const updateItem = itemId => {
  return (dispatch, getState) => {
    const { dailyItems } = getState()
    const lookup = makeFilterLookup(dailyItems.filters)
    const revenueCenterId = parseInt(lookup.revenue_center_id.value)
    const restriction = dailyItems.restrictions.filter(
      i => i.item_id === itemId
    )
    const newRestriction = makeItem(restriction, itemId, revenueCenterId)
    const other = dailyItems.restrictions.filter(i => i.item_id !== itemId)
    const newRestrictions = [...other, newRestriction]
    const update = { restrictions: newRestrictions, hasChanges: true }
    dispatch(loadDailyItems(update))
  }
}

export const updateWeekday = weekday => {
  return (dispatch, getState) => {
    const { dailyItems } = getState()
    const lookup = makeFilterLookup(dailyItems.filters)
    const revenueCenterId = parseInt(lookup.revenue_center_id.value)
    const { items, restrictions, disabled } = dailyItems
    const activeItems = items.filter(i => !disabled.includes(i.item_id))
    const removedCount = restrictions.filter(i => i[weekday])
    const removed = removedCount < activeItems.length ? true : false
    const newRestrictions = activeItems.map(i => {
      const restriction = restrictions.filter(r => r.item_id === i.item_id)
      if (!restriction.length) {
        return {
          ...defaultWeekdays,
          [weekday]: removed,
          item_id: i.item_id,
          revenue_center_id: revenueCenterId
        }
      }
      return { ...restriction[0], [weekday]: removed }
    })
    const update = { restrictions: newRestrictions, hasChanges: true }
    dispatch(loadDailyItems(update))
  }
}

const anyActive = restrictions => {
  const count = restrictions.reduce(
    (t, i) => (t += weekdaysLower.filter(w => !i[w]).length),
    0
  )
  return count > 0 ? true : false
}

export const updateAll = () => {
  return (dispatch, getState) => {
    const { dailyItems } = getState()
    const lookup = makeFilterLookup(dailyItems.filters)
    const revenueCenterId = parseInt(lookup.revenue_center_id.value)
    const { items, restrictions, disabled } = dailyItems
    const activeItems = items.filter(i => !disabled.includes(i.item_id))
    const notRestricted =
      activeItems.length > restrictions.length || anyActive(restrictions)
    const newWeekdays = notRestricted ? restrictedWeekdays : defaultWeekdays
    const newRestrictions = activeItems.map(i => ({
      ...newWeekdays,
      item_id: i.item_id,
      revenue_center_id: revenueCenterId
    }))
    const update = { restrictions: newRestrictions, hasChanges: true }
    dispatch(loadDailyItems(update))
  }
}

export const updateRestrictions = () => {
  return (dispatch, getState) => {
    const { token, brand, dailyItems } = getState()
    const data = dailyItems.restrictions
    request(token, brand, '/daily-items', 'POST', data)
      .then(() => {
        dispatch(flashMessage('Successfully updated!'))
        dispatch(dailyItemsUpdated(true))
        dispatch(dailyItemsChanged(false))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const regenerateMenus = () => {
  return (dispatch, getState) => {
    const { token, brand, dailyItems } = getState()
    const revenueCenter = dailyItems.filters.find(
      i => i.field === 'revenue_center_id'
    )
    const revenueCenterIds = [
      { revenue_center_id: parseInt(revenueCenter.value) }
    ]
    request(token, brand, '/regenerate-menus', 'POST', revenueCenterIds)
      .then(() => {
        dispatch(flashMessage('Successfully regenerated!'))
        dispatch(dailyItemsUpdated(false))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case DAILY_ITEMS_CLEAR:
      return { ...initState }
    case DAILY_ITEMS_FILTERS:
      return { ...state, filters: action.payload }
    case DAILY_ITEMS_LOADING:
      return {
        ...state,
        items: null,
        restrictions: null,
        disabled: null,
        loading: true
      }
    case DAILY_ITEMS_LOADING_STOP:
      return { ...state, loading: false }
    case DAILY_ITEMS_LOAD:
      return { ...state, ...action.payload, error: '', loading: false }
    case DAILY_ITEMS_UPDATE:
      return {
        ...state,
        restrictions: action.payload,
        error: '',
        loading: false
      }
    case DAILY_ITEMS_ERRORS:
      return { ...state, error: action.payload, loading: false }
    case DAILY_ITEMS_CHANGED:
      return { ...state, hasChanges: action.payload }
    case DAILY_ITEMS_UPDATED:
      return { ...state, hasUpdates: action.payload }
    default:
      return state
  }
}
