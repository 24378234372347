import propTypes from 'prop-types'
import React from 'react'

const OrderRevenueCenters = ({ revenueCenters, handler }) => {
  return revenueCenters.length ? (
    <ul className="btn-list">
      {revenueCenters.map(i => (
        <li key={i.revenue_center_id}>
          <button
            className="btn"
            id={i.revenue_center_id}
            onClick={evt => handler(evt, i)}
          >
            <span>{i.full_name}</span>
            <span>
              {i.inZone && i.priority ? (
                <span className="-is-priority">priority {i.priority}</span>
              ) : null}
              {i.inZone && <span className="-in-zone">in zone</span>}
              {(i.distance || i.distance === 0) && (
                <span>{i.distance.toFixed(2)} miles</span>
              )}
            </span>
          </button>
        </li>
      ))}
    </ul>
  ) : null
}

OrderRevenueCenters.displayName = 'OrderRevenueCenters'
OrderRevenueCenters.propTypes = {
  revenueCenters: propTypes.array,
  handler: propTypes.func
}

export default OrderRevenueCenters
