import propTypes from 'prop-types'
import React, { useState } from 'react'
import { last } from '../lib/utils'
import { processErrorMessage } from '../lib/errors'
import Dropzone from './Dropzone'

const baseUrl = process.env.REACT_APP_API_URL

// https://malcoded.com/posts/react-file-upload/#implementing-the-upload-route
// https://github.com/LukasMarx/react-file-upload
// https://malcoded.com/posts/react-dropzone/
const Upload = ({
  fileType,
  fileObj,
  name,
  classes,
  endpoint,
  token,
  brandId,
  fetchFiles,
  deleteFile,
  showFileError,
  resetSession,
}) => {
  const [uploading, setUplaoding] = useState(false)
  const [progress, setProgress] = useState({ state: 'inactive', percentage: 0 })

  const sendRequest = (file) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          setProgress({
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          })
        }
      })

      req.upload.addEventListener('load', () => {
        setProgress({ state: 'processing', percentage: 100 })
        resolve(req.response)
      })

      req.upload.addEventListener('error', () => {
        setProgress({ state: 'upload-error', percentage: 0 })
        reject(req.response)
      })

      // Call a function when the state changes.
      req.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          if (this.status === 204) {
            fetchFiles()
          } else if (this.status === 401) {
            resetSession()
          } else {
            const error = JSON.parse(req.responseText)
            let msg
            try {
              msg = error.params.file || error.detail
            } catch (e) {
              msg = error.detail
            }
            showFileError(processErrorMessage(msg))
            window.scroll(0, 0)
          }
          setUplaoding(false)
          setProgress({ state: 'inactive', percentage: 0 })
          // window.scrollTo(0, 0)
        }
      }

      req.open('POST', `${baseUrl}${endpoint}`)
      req.setRequestHeader('Authorization', `Bearer ${token}`)
      req.setRequestHeader('brand-id', `${brandId}`)
      req.setRequestHeader('file-type', fileType)
      req.setRequestHeader('file-name', file.name)
      req.send(file)
    })
  }

  const uploadFile = async (file) => {
    setUplaoding(true)
    try {
      await sendRequest(file)
    } catch (e) {
      setUplaoding(false)
      setProgress({ state: 'inactive', percentage: 0 })
    }
  }

  const filename = fileObj ? last(fileObj.file_url.split('/')) : ''

  return (
    <div className={`dz-wrapper ${classes}`}>
      <Dropzone
        name={name}
        onFilesAdded={uploadFile}
        disabled={uploading}
        file={fileObj}
        deleteFile={deleteFile}
        progress={progress}
      />
      {fileObj && (
        <p className="dz-wrapper__url">
          <a href={fileObj.file_url} target="_blank" rel="noopener noreferrer">
            {filename}
          </a>
        </p>
      )}
    </div>
  )
}

Upload.displayName = 'Upload'
Upload.propTypes = {
  fileType: propTypes.string,
  fileObj: propTypes.object,
  name: propTypes.string,
  classes: propTypes.string,
  endpoint: propTypes.string,
  token: propTypes.string,
  brandId: propTypes.number,
  fetchFiles: propTypes.func,
  deleteFile: propTypes.func,
  showFileError: propTypes.func,
  resetSession: propTypes.func,
}

export default Upload
