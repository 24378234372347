import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import { Label, Error } from './FormInputs'

const makeMenuNames = menus => {
  return menus && menus.length
    ? menus.map(i => i.menu.name).join(', ')
    : 'Unassigned'
}

const ItemSelection = ({
  label,
  tooltip,
  field,
  menu,
  selected,
  handler,
  error = null,
  size = '10'
}) => {
  const selectedList = selected
    ? selected.map(i => `${i.category_id}-${i.item_id}`)
    : []
  const selectedNames = menu
    ? menu
        .reduce((arr, c) => {
          const itemNames = c.items
            .map(i => {
              const key = `${c.category_id}-${i.item.item_id}`
              if (selectedList.includes(key)) return i.item.short_name
            })
            .filter(i => typeof i !== 'undefined')
          arr = [...arr, ...itemNames]
          return arr
        }, [])
        .join(', ')
    : ''
  return (
    <div className="selection">
      {!menu && (
        <div className="content__loading">
          <ClipLoader size={44} color={'#5a5aff'} />
        </div>
      )}
      <TransitionGroup>
        {menu ? (
          <CSSTransition {...cssTransitionFade}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor={field} className={`label select-multiple`}>
              <Label label={label} tooltip={tooltip} />
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select id={field} multiple size={size} onChange={handler}>
                {menu.map(category => (
                  <>
                    <option
                      key={`category-${category.category_id}`}
                      value={`category-${category.category_id}`}
                      disabled={true}
                    >
                      {`-- ${category.full_name} (${makeMenuNames(
                        category.menus
                      )})`}
                    </option>
                    {category.items.map(item => {
                      const key = `${category.category_id}-${item.item.item_id}`
                      const selected = selectedList.includes(key)
                      return (
                        <option key={key} value={key} selected={selected}>
                          {`${item.item.short_name}`}
                        </option>
                      )
                    })}
                  </>
                ))}
              </select>
              {error && error.length ? <Error error={error} /> : null}
              <span className="selection__selected">
                <span className="selection__selected__label">
                  Currently Selected: &nbsp;
                </span>
                {selectedNames}
              </span>
            </label>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </div>
  )
}

ItemSelection.displayName = 'ItemSelection'
ItemSelection.propTypes = {
  label: propTypes.string,
  field: propTypes.string,
  tooltip: propTypes.string,
  menu: propTypes.array,
  selected: propTypes.array,
  handler: propTypes.func,
  error: propTypes.string,
  size: propTypes.string
}

export default ItemSelection
