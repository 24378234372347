import { request } from '../lib/services'
import { makeQueryParams } from '../lib/helpers'
import { clearItemsOnly } from './list'
import { handlePageError } from './page'

const initState = {
  query: '',
  results: null,
  loading: false,
  error: ''
}

export const QUERY_CLEAR_ALL = 'QUERY_CLEAR_ALL'
export const QUERY_CLEAR_RESULTS = 'QUERY_CLEAR_RESULTS'
export const QUERY_CLEAR = 'QUERY_CLEAR'
export const QUERY_UPDATE = 'QUERY_UPDATE'
export const QUERY_LOADING = 'QUERY_LOADING'
export const QUERY_RESULTS = 'QUERY_RESULTS'
export const QUERY_ERROR = 'QUERY_ERROR'

export const updateQuery = text => ({ type: QUERY_UPDATE, payload: text })
export const clearQuery = () => ({ type: QUERY_CLEAR })
export const loadingQuery = () => ({ type: QUERY_LOADING })
export const showSearchResults = results => ({
  type: QUERY_RESULTS,
  payload: results
})
export const clearResultsOnly = () => {
  return dispatch => {
    dispatch({ type: QUERY_CLEAR_RESULTS })
  }
}
export const clearSearchResults = () => {
  return dispatch => {
    dispatch({ type: QUERY_CLEAR_ALL })
  }
}
export const showSearchError = error => ({ type: QUERY_ERROR, payload: error })

const makeName = (item, resultText) => {
  if (!resultText) return ''
  return Array.isArray(resultText)
    ? resultText.map(attr => item[attr]).join(' ')
    : item[resultText].toString()
}

const makeResultText = (item, resultName, resultParens) => {
  const name = makeName(item, resultName)
  let parens = makeName(item, resultParens)
  parens = parens.length ? ` (${parens})` : ''
  return `${name}${parens}`
}

export const retrieveResults = props => {
  const { endpoint, resultId, resultName, resultParens } = props
  return (dispatch, getState) => {
    const { token, brand, search, page } = getState()
    if (!search.query || !search.query.length) return
    dispatch(loadingQuery())
    const sep = endpoint.includes('?') ? '&' : '?'
    let searchEndpoint = `/${endpoint}${sep}search=${search.query}`
    if (page.list) {
      if (page.list.endpoint === `/${endpoint}`) {
        dispatch(clearItemsOnly())
        const queryParams = makeQueryParams(page.list.params)
        if (queryParams) searchEndpoint += `&${queryParams}`
      }
    }
    request(token, brand, searchEndpoint, 'GET')
      .then(resp => {
        const items = resp.data || resp
        const results = resultId
          ? items.map(i => ({
              name: makeResultText(i, resultName, resultParens),
              id: i[resultId]
            }))
          : items
        dispatch(showSearchResults(results))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const msg = `${err.detail || err.message}`
        dispatch(showSearchError(msg))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case QUERY_CLEAR_ALL:
      return { ...initState }
    case QUERY_CLEAR:
      return { ...initState, query: '', results: null }
    case QUERY_CLEAR_RESULTS:
      return { ...state, results: null }
    case QUERY_UPDATE:
      return { ...state, query: action.payload }
    case QUERY_LOADING:
      return { ...state, loading: true, results: null }
    case QUERY_RESULTS:
      return { ...state, results: action.payload, loading: false, error: '' }
    case QUERY_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
