import { request } from '../lib/services'
import { sortItems } from '../lib/helpers'
import { flashMessage } from './messages'
import { clearPageError, handlePageError } from './page'
import { loadingModal, openModal, showModalErrors, closeModal } from './modal'

const initState = {
  loading: true,
  items: [],
  error: ''
}

export const LIST_OF_ITEMS_CLEAR = 'LIST_OF_ITEMS_CLEAR'
export const LIST_OF_ITEMS_LOADING = 'LIST_OF_ITEMS_LOADING'
export const LIST_OF_ITEMS_LOAD = 'LIST_OF_ITEMS_LOAD'
export const LIST_OF_ITEMS_UPDATE = 'LIST_OF_ITEMS_UPDATE'
export const LIST_OF_ITEMS_ERROR = 'LIST_OF_ITEMS_ERROR'

export const clearListOfItems = () => ({ type: LIST_OF_ITEMS_CLEAR })
export const loadingListOfItems = () => ({ type: LIST_OF_ITEMS_LOADING })
export const loadListOfItems = items => ({
  type: LIST_OF_ITEMS_LOAD,
  payload: items
})
export const updateListOfItems = items => ({
  type: LIST_OF_ITEMS_UPDATE,
  payload: items
})
export const showListOfItemsError = msg => ({
  type: LIST_OF_ITEMS_ERROR,
  payload: msg
})

export const fetchListOfItems = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(clearListOfItems())
    const { token, brand, page } = getState()
    const endpoint = page.listOfItems.endpoint
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const items = sortItems(resp, page.listOfItems.sorting)
        dispatch(loadListOfItems(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showListOfItemsError(''))
      })
  }
}

export const addItem = () => {
  return (dispatch, getState) => {
    const { page } = getState()
    const { fields } = page.listOfItems
    dispatch(loadingModal())
    const config = {
      title: 'Add a New Item',
      subtitle: 'Please fill in the fields below',
      type: 'listOfItemsEdit',
      args: { fields }
    }
    dispatch(openModal(config))
  }
}

export const editItem = index => {
  return (dispatch, getState) => {
    const { page, listOfItems } = getState()
    const { fields } = page.listOfItems
    const indexedItem = listOfItems.items[index]
    const item = { ...indexedItem, index: index }
    dispatch(loadingModal())
    const config = {
      title: `Update ${page.title} Item`,
      subtitle: 'Please make your updates below',
      type: 'listOfItemsEdit',
      args: { fields, item, index }
    }
    dispatch(openModal(config))
  }
}

export const upsertItem = item => {
  return (dispatch, getState) => {
    const { token, brand, page, listOfItems } = getState()
    const index = item.index
    const isNew = isNaN(parseInt(index))
    delete item.index
    let data = [...listOfItems.items]
    if (isNew) {
      data = [...data, item]
    } else {
      data[index] = item
    }
    const endpoint = page.listOfItems.endpoint
    return request(token, brand, endpoint, 'PUT', data)
      .then(resp => {
        dispatch(closeModal())
        const msg = `Successfully ${index ? 'updated!' : 'created!'}`
        dispatch(flashMessage(msg))
        const items = sortItems(resp, page.listOfItems.sorting)
        dispatch(loadListOfItems(items))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = { form: 'All fields are required' }
        dispatch(showModalErrors(errors))
      })
  }
}

export const confirmDeleteItem = index => {
  return (dispatch, getState) => {
    const { page } = getState()
    dispatch(loadingModal())
    const config = {
      title: `Delete ${page.title} Item`,
      subtitle: 'Are you sure you want to delete this item?',
      type: 'listOfItemsDelete',
      args: { index }
    }
    dispatch(openModal(config))
  }
}

export const deleteItem = index => {
  return (dispatch, getState) => {
    const { token, brand, page, listOfItems } = getState()
    let data = [...listOfItems.items]
    data.splice(index, 1)
    const endpoint = page.listOfItems.endpoint
    return request(token, brand, endpoint, 'PUT', data)
      .then(resp => {
        dispatch(flashMessage('Successfully deleted'))
        const items = sortItems(resp, page.listOfItems.sorting)
        dispatch(loadListOfItems(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(closeModal()))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case LIST_OF_ITEMS_CLEAR:
      return { ...initState }
    case LIST_OF_ITEMS_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case LIST_OF_ITEMS_UPDATE:
      return { ...state, items: action.payload }
    case LIST_OF_ITEMS_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
