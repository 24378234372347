import React from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import ErrorPage from './components/ErrorPage'
import packageJson from '../package.json'

const isProd = process.env.NODE_ENV === 'production'
// const isLocal = process.env.NODE_ENV === 'development'

class DefaultErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, info: null, eventId: null }
  }

  static propTypes = {
    children: propTypes.node.isRequired,
    user: propTypes.object,
  }

  // static getDerivedStateFromError(error) {
  //   // update state so the next render will show the fallback UI.
  //   return { hasError: true, error }
  // }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info })
    if (isProd) {
      const user = {
        ...this.props.user,
        version: packageJson.version,
      }
      Sentry.withScope((scope) => {
        scope.setExtras(info)
        scope.setUser(user)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    const { hasError, error, info } = this.state
    const { children } = this.props
    return hasError ? <ErrorPage error={error} info={info} /> : children
  }
}

DefaultErrorBoundary.displayName = 'DefaultErrorBoundary'

export default connect(
  (state) => ({ user: state.user }),
  {}
)(DefaultErrorBoundary)
