import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { minutesToTime } from '../../lib/helpersDatetime'
import { editPrepTime } from '../../reducers/orderMgmt'
import { Select } from '../FormInputs'

function* range(start, end, step) {
  for (let i = start; i <= end; i += step) {
    yield i
  }
}

const times15 = [...range(0, 1410, 15)].map(minutes => {
  const timeStr = minutesToTime(minutes)
  return { value: timeStr, name: timeStr }
})

class EditPrepTime extends Component {
  static propTypes = {
    orderId: propTypes.number,
    prepTime: propTypes.string,
    windowRef: propTypes.shape({ current: propTypes.any }),
    errors: propTypes.object,
    editPrepTime: propTypes.func
  }

  handlePrepTime = evt => {
    const { value } = evt.target
    const prepTime = value.length ? value : null
    const { orderId } = this.props
    this.props.editPrepTime(orderId, prepTime)
  }

  render() {
    const { prepTime } = this.props
    const field = { label: '', field: 'prepTime', type: 'select' }
    const times = [{ value: '', name: 'None' }, ...times15]
    return (
      <Select
        field={field}
        value={prepTime || ''}
        options={times}
        handler={this.handlePrepTime}
      />
    )
  }
}

EditPrepTime.displayName = 'EditPrepTime'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { editPrepTime }
)(EditPrepTime)
