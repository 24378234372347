import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
// import { Volume, VolumeX } from 'react-feather'

// https://medium.com/@curtisrobinson/how-to-auto-play-audio-in-safari-with-javascript-21d50b0a2765
// https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
// https://bitmovin.com/play-not-play-new-autoplay-policies-safari-11-chrome-64/
// https://stackoverflow.com/questions/47686345/playing-sound-in-reactjs
// https://stackoverflow.com/questions/3273552/html5-audio-looping
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement

// const defaultUrl =
//   'https://d1oo7s9wj12irg.cloudfront.net/static/pkg/soundManager/mp3/iphone_alarm_short_02.mp3'

const defaultUrl =
  'https://io-opentender.s3.amazonaws.com/audio/iphone_alarm_short_02.mp3'

const AlarmSimple = ({ url = defaultUrl, playing, muted, setMuted }) => {
  const [audio] = useState(new Audio(url))
  const [blocked, setBlocked] = useState(true)

  const unblock = (evt, isMuted) => {
    evt.preventDefault()
    audio.play()
    audio.pause()
    setBlocked(false)
    setMuted(isMuted)
    evt.target.blur()
  }

  useEffect(() => {
    if (!blocked) {
      playing && !muted ? audio.play() : audio.pause()
    }
  }, [playing, muted, blocked, audio])

  useEffect(() => {
    audio.loop = true
    return () => {
      audio.loop = false
      audio.pause()
    }
  }, [audio])

  return blocked ? (
    <div className="alarm-msg">
      <p>The unconfirmed order audio alert has been muted by your browser.</p>
      <button className="btn-link" onClick={evt => unblock(evt, false)}>
        Click To Unmute
      </button>
      <button className="btn-link" onClick={evt => unblock(evt, true)}>
        Leave Muted
      </button>
    </div>
  ) : (
    <span className="alarm-hidden">&nbsp;</span>
  )
}

AlarmSimple.displayName = 'AlarmSimple'
AlarmSimple.propTypes = {
  url: propTypes.string,
  playing: propTypes.bool,
  muted: propTypes.bool,
  setMuted: propTypes.func
}

export default AlarmSimple
