import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResponsiveBar } from './Charts'
import { sortItems } from '../../lib/helpers'
import { makeStoreLookup } from '../../lib/helpers/reporting'
import Results from './Results'
import ButtonCSV from './ButtonCSV'

const makeSalesData = (accounting, stores) => {
  if (!accounting || !accounting.length) return []
  const data = accounting.map(i => ({
    name: stores[i.store_id],
    net_sales: parseFloat(i.net_sales),
    total: parseFloat(i.total)
  }))
  const sorting = { sortBy: 'total', sortType: 'order', desc: true }
  return sortItems(data, sorting)
}

const makeTendersData = (accounting, stores) => {
  if (!accounting || !accounting.length) return []
  const bars = accounting[0].tenders
    .filter(i => i.tender_type_id)
    .map(i => i.name)
  const data = accounting.map(i => {
    const store = {
      name: stores[i.store_id],
      total: Math.max(parseFloat(i.total), 0.0)
    }
    const tenders = i.tenders
      .filter(i => i.tender_type_id)
      .reduce(
        (obj, t) => ({
          ...obj,
          [t.name]: Math.max(parseFloat(t.amount), 0.0)
        }),
        {}
      )
    return { ...store, ...tenders }
  })
  const sorting = { sortBy: 'total', sortType: 'order', desc: true }
  return [sortItems(data, sorting), bars]
}

class Accounting extends Component {
  static propTypes = {
    data: propTypes.array,
    stores: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, stores, handlers } = this.props
    const [accounting] = data || []
    const storeLookup = makeStoreLookup(stores)
    const salesData = makeSalesData(accounting, storeLookup)
    const salesHeight = Math.max((salesData.length + 1) * 50, 125)
    const salesBars = ['total', 'net_sales']
    const [tendersData, tenderBars] = makeTendersData(accounting, storeLookup)
    const tendersHeight = Math.max((salesData.length + 2) * 50, 165)
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/accounting-summary/csv" />
            </div>
          </div>
        </div>
        <div className="reports">
          <div className="report">
            <div className="report__header">
              <h2>Order Total and Net Sales across all Stores</h2>
            </div>
            <ResponsiveBar
              height={salesHeight}
              data={salesData}
              bars={salesBars}
              format="dollars"
              xAxis={{ orientation: 'top', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 100
              }}
            />
          </div>
          <div className="report">
            <div className="report__header">
              <h2>Tender Distribution across all stores</h2>
            </div>
            <ResponsiveBar
              height={tendersHeight}
              data={tendersData}
              bars={tenderBars}
              format="dollars"
              stackId="a"
              xAxis={{ orientation: 'top', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 100
              }}
              showLabel={false}
            />
          </div>
        </div>
      </>
    )
  }
}

Accounting.displayName = 'Accounting'

export default connect(
  state => ({
    stores: state.options.stores
  }),
  {}
)(Accounting)
