// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
export const downloadPDF = (blob, linkRef, fileType, filename) => {
  const newBlob = new Blob([blob], { type: fileType })
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }
  const data = window.URL.createObjectURL(newBlob)
  linkRef.current.href = data
  linkRef.current.download = filename
  linkRef.current.click()
  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => window.URL.revokeObjectURL(data), 100)
  return
}

// https://code-maven.com/create-and-download-csv-with-javascript
// https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
export const downloadData = (data, linkRef, fileType, filename) => {
  const encodedData = `data:${fileType};charset=utf-8,${encodeURI(data)}`
  linkRef.current.href = encodedData
  linkRef.current.download = filename
  linkRef.current.click()
}
