import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select } from './FormInputs'
import { revenueCenters as revenueCenterTypes } from '../lib/constants'
import {
  updateRevenueCenterType,
  updateRevenueCenter
} from '../reducers/mapping'

const fields = {
  revenueCenterType: {
    label: 'Revenue Center Type',
    field: 'revenueCenterType',
    type: 'select',
    classes: '-half'
  },
  revenueCenter: {
    label: 'Revenue Center',
    field: 'revenueCenterId',
    type: 'select',
    classes: '-half -right'
  }
}

class MappingRevenueCenters extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    revenueCenterType: propTypes.string,
    revenueCenterId: propTypes.number,
    revenueCenters: propTypes.object,
    updateRevenueCenterType: propTypes.func,
    updateRevenueCenter: propTypes.func
  }

  handleRevenueCenterType = evt => {
    const { value } = evt.target
    this.props.updateRevenueCenterType(value)
  }

  handleRevenueCenter = evt => {
    const { value } = evt.target
    const revenueCenterId = value.length ? parseInt(value) : null
    this.props.updateRevenueCenter(revenueCenterId)
  }

  render() {
    const { revenueCenterType, revenueCenters, revenueCenterId } = this.props
    let revenueCenterOptions = revenueCenters[revenueCenterType]
      ? revenueCenters[revenueCenterType].map(i => ({
          name: i.full_name,
          value: i.revenue_center_id
        }))
      : []
    revenueCenterOptions = [
      { name: 'please choose a revenue center', value: '' },
      ...revenueCenterOptions
    ]
    return (
      <div className="mapping__revenue-center">
        <Select
          field={fields.revenueCenterType}
          value={revenueCenterType}
          options={[...revenueCenterTypes].slice(1)}
          handler={this.handleRevenueCenterType}
        />
        <Select
          field={fields.revenueCenter}
          value={revenueCenterId || ''}
          options={revenueCenterOptions}
          handler={this.handleRevenueCenter}
        />
      </div>
    )
  }
}

MappingRevenueCenters.displayName = 'MappingRevenueCenters'

export default connect(
  state => ({
    ...state.mapping,
    revenueCenters: state.options['revenue-centers']
  }),
  { updateRevenueCenterType, updateRevenueCenter }
)(MappingRevenueCenters)
