import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../reducers/modal'

class ItemCategory extends Component {
  static propTypes = {
    itemId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    isEmpty: propTypes.bool,
    isMod: propTypes.bool,
    openModal: propTypes.func
  }

  handleClick = evt => {
    const entity = this.props.isMod ? 'modifier group' : 'category'
    const config = {
      title: `Choose a ${entity}`,
      subtitle: `Use the dropdown below to choose a ${entity}`,
      type: 'itemCategory',
      args: { ...this.props }
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    const { isEmpty } = this.props
    const entity = this.props.isMod ? 'modifier group' : 'category'
    return (
      <button className="btn-link" onClick={this.handleClick}>
        Add this item to {isEmpty ? 'a' : 'another'} {entity}
      </button>
    )
  }
}

ItemCategory.displayName = 'ItemCategory'

export default connect(null, {
  openModal
})(ItemCategory)
