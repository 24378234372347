import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../reducers/modal'

class MissingItems extends Component {
  static propTypes = {
    items: propTypes.array,
    closeModal: propTypes.func
  }

  render() {
    return (
      <>
        <div className="text">
          {this.props.items.map((item, index) => (
            <p key={`${index}-${item.id}`}>{item.name}</p>
          ))}
          <div className="modal__footer">
            <div className="modal__footer__buttons">
              <button className="btn" onClick={this.props.closeModal}>
                Got It
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

MissingItems.displayName = 'MissingItems'

export default connect(null, { closeModal })(MissingItems)
