import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { drilldown } from '../../reducers/report'
import { makeVal } from '../TableCell'

class Drilldown extends Component {
  static propTypes = {
    item: propTypes.object,
    endpoint: propTypes.string,
    drilldown: propTypes.func
  }

  handleClick = evt => {
    const { item, endpoint } = this.props
    this.props.drilldown(item.storeId, endpoint)
    evt.target.blur()
  }

  render() {
    const val = makeVal(this.props)
    const isDifference = true
    return isDifference ? (
      <button className="btn-link" onClick={this.handleClick}>
        {val}
      </button>
    ) : (
      <span>--</span>
    )
  }
}

Drilldown.displayName = 'Drilldown'

export default connect(null, { drilldown })(Drilldown)
