import propTypes from 'prop-types'
import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import { ResponsiveBar } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Table from '../Table'
import Totals from './Totals'

const fields = [
  {
    name: 'Business Date',
    field: ['business_date']
  },
  {
    name: 'Days',
    field: ['days'],
    format: 'quantity'
  },
  {
    name: 'Checks',
    field: ['checks'],
    format: 'quantity'
  },
  {
    name: 'Net Sales',
    field: ['net_sales'],
    format: 'currency'
  },
  {
    name: 'Checks per Day',
    field: ['checks_per_day'],
    format: 'quantity'
  },
  {
    name: 'Average Check',
    field: ['average_check'],
    format: 'dollars'
  }
]

const makeChecksData = data => {
  if (!data || !data.length) return []
  return data.map(i => ({
    name: i.business_date,
    checks: parseInt(i.checks)
  }))
}

const makeSalesData = data => {
  if (!data || !data.length) return []
  return data.map(i => ({
    name: i.business_date,
    netSales: parseFloat(i.net_sales)
  }))
}

const makeTotals = data => {
  if (!data || !data.length) return null
  const days = data.reduce((t, i) => (t += parseFloat(i.days)), 0.0)
  const checks = data.reduce((t, i) => (t += parseFloat(i.checks)), 0.0)
  const netSales = data.reduce((t, i) => (t += parseFloat(i.net_sales)), 0.0)
  const checksPerDay = days ? checks / days : 0
  const avgCheck = checks ? netSales / checks : 0.0
  return {
    business_date: 'Totals',
    days: parseInt(days),
    checks: parseInt(checks),
    net_sales: netSales,
    checks_per_day: checksPerDay,
    average_check: avgCheck
  }
}

const SalesByPeriod = ({ data, handlers }) => {
  const [showData, setShowData] = useState(false)

  const toggleDisplay = evt => {
    evt.preventDefault()
    setShowData(!showData)
    evt.target.blur()
  }

  const checksData = makeChecksData(data)
  const salesData = makeSalesData(data)
  const totals = makeTotals(data)
  const noSales = totals && totals.checks === 0
  if (!data.length || noSales)
    return (
      <div className="report__table">
        <p className="alert">
          No sales data for the selected time period. Please try adjusting your
          filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ToggleDisplay showData={showData} handler={toggleDisplay} />
            <ButtonCSV endpoint="/reporting/sales-by-period/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="report__table report--sales-by-period">
              <Totals fields={fields} totals={totals} />
              <Table fields={fields} items={data} />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {!showData && (
        <div className="reports">
          <div className="report">
            <div className="report__header">
              <h2>Checks</h2>
            </div>
            <ResponsiveBar
              height={checksData.length * 35 + 50}
              data={checksData}
              bars={['checks']}
              showLegend={false}
              format="quantity"
              xAxis={{ orientation: 'bottom', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 70
              }}
            />
          </div>
          <div className="report">
            <div className="report__header">
              <h2>Net Sales</h2>
            </div>
            <ResponsiveBar
              height={salesData.length * 35 + 50}
              data={salesData}
              bars={['netSales']}
              showLegend={false}
              format="dollars"
              xAxis={{ orientation: 'bottom', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 70
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

SalesByPeriod.displayName = 'SalesByPeriod'
SalesByPeriod.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default SalesByPeriod
