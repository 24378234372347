import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { valTypes } from '../../lib/constants'
import Form from '../Form'
import { PageError } from '../PageError'
import { updateWaitTimes, resetWaitTimes } from '../../reducers/orderMgmt'

const makeFields = (isThrottled, pickupOptions, deliveryOptions) => {
  const waitTimeFields = isThrottled
    ? [
        {
          label: 'Current Pickup Wait Time',
          field: 'pickup_wait_time_id',
          type: 'select',
          options: pickupOptions,
          valType: valTypes.int,
          readonly: true
        },
        {
          label: 'Current Delivery Wait Time',
          field: 'delivery_wait_time_id',
          type: 'select',
          options: deliveryOptions,
          valType: valTypes.int,
          readonly: true
        }
      ]
    : [
        {
          label: 'Current Pickup Wait Time',
          field: 'pickup_wait_time_id',
          type: 'select',
          options: pickupOptions,
          valType: valTypes.int
        },
        {
          label: 'Current Delivery Wait Time',
          field: 'delivery_wait_time_id',
          type: 'select',
          options: deliveryOptions,
          valType: valTypes.int
        }
      ]
  let fields = [
    {
      label: 'Temporarily Closed',
      field: 'closed',
      type: 'checkbox'
    }
  ]
  fields = fields.concat(waitTimeFields)
  return fields
}

class WaitTimes extends Component {
  static propTypes = {
    values: propTypes.object,
    isThrottled: propTypes.bool,
    pickupWaitTimes: propTypes.array,
    deliveryWaitTimes: propTypes.array,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    updateWaitTimes: propTypes.func,
    resetWaitTimes: propTypes.func
  }

  render() {
    const {
      values,
      isThrottled,
      pickupWaitTimes,
      deliveryWaitTimes,
      errors,
      updateWaitTimes,
      resetWaitTimes,
      windowRef
    } = this.props
    const pickupOptions = pickupWaitTimes.map(i => ({
      value: i.pickup_wait_time_id,
      name: `${i.pickup_wait_time} minutes`
    }))
    const deliveryOptions = deliveryWaitTimes.map(i => ({
      value: i.delivery_wait_time_id,
      name: `${i.delivery_wait_time} minutes`
    }))
    const fields = makeFields(isThrottled, pickupOptions, deliveryOptions)
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        {!isThrottled ? (
          <button
            type="button"
            className="btn-link btn--wait-times"
            onClick={resetWaitTimes}
          >
            Click here to reset to defaults
          </button>
        ) : (
          <div className="modal__note">
            <p className="alert">
              Order throttling is currently turned ON so wait times {"can't"} be
              manually adjusted, but OLO can be closed.
            </p>
          </div>
        )}
        <Form
          isNew={false}
          fields={fields}
          data={values}
          errors={errors || {}}
          upsertItem={updateWaitTimes}
          windowRef={windowRef}
        />
      </>
    )
  }
}

WaitTimes.displayName = 'WaitTimes'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { updateWaitTimes, resetWaitTimes }
)(WaitTimes)
