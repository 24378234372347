import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select, Input } from './FormInputs'
import ClipLoader from 'react-spinners/ClipLoader'
import { updateMappings, upsertMappings } from '../reducers/mapping'
import MappingRevenueCenters from './MappingRevenueCenters'

class Mapping extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    isGlobal: propTypes.bool,
    loading: propTypes.bool,
    submitting: propTypes.bool,
    revenueCenterId: propTypes.number,
    items: propTypes.array,
    extItems: propTypes.array,
    mappings: propTypes.array,
    entity: propTypes.string,
    extEntity: propTypes.string,
    updateMappings: propTypes.func,
    upsertMappings: propTypes.func
  }

  handleMapping = evt => {
    const { id, value: pos_ext_id } = evt.target
    const entity_id = parseInt(id.split('-')[1])
    this.props.updateMappings(entity_id, pos_ext_id)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.upsertMappings()
    evt.target.blur()
  }

  render() {
    const {
      isGlobal,
      loading,
      revenueCenterId,
      items,
      extItems,
      mappings,
      entity,
      extEntity,
      submitting
    } = this.props
    const mappingsMap = mappings.reduce(
      (obj, i) => ({ ...obj, [i.entity_id]: i.pos_ext_id }),
      {}
    )
    let mappingOptions = extItems.map(i => ({
      name: i.display_name,
      value: i.pos_ext_id
    }))
    mappingOptions = mappingOptions.length
      ? [{ name: 'not currently mapped', value: '' }, ...mappingOptions]
      : null
    return (
      <div className="mapping">
        {!isGlobal && <MappingRevenueCenters />}
        {loading ? (
          <div className="mapping__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        ) : (revenueCenterId || isGlobal) && items.length ? (
          <div className="mapping__mappings">
            <div className="mapping__mappings__header">
              <h2>
                Open Tender {entity}::{extEntity}
              </h2>
              <p>
                Use this section to map your Open Tender {entity} to your{' '}
                {extEntity}
              </p>
            </div>
            <form id="form" className="form" onSubmit={this.handleSubmit}>
              {mappingOptions
                ? items.map(i => {
                    const key = `entityId-${i.entity_id}`
                    const field = { label: i.name, field: key, type: 'select' }
                    return (
                      <Select
                        key={key}
                        field={field}
                        value={mappingsMap[i.entity_id]}
                        options={mappingOptions}
                        handler={this.handleMapping}
                      />
                    )
                  })
                : items.map(i => {
                    const key = `entityId-${revenueCenterId}`
                    const field = { label: i.name, field: key, type: 'text' }
                    return (
                      <Input
                        key={key}
                        field={field}
                        value={mappingsMap[revenueCenterId]}
                        handler={this.handleMapping}
                      />
                    )
                  })}
              <div className="form__submit">
                <input
                  className="btn"
                  type="submit"
                  value="Submit Mapping Updates"
                  disabled={submitting}
                  ref={this.submitButton}
                />
              </div>
            </form>
          </div>
        ) : null}
      </div>
    )
  }
}

Mapping.displayName = 'Mapping'

export default connect(
  state => ({
    ...state.mapping,
    ...state.page.mapping
  }),
  { updateMappings, upsertMappings }
)(Mapping)
