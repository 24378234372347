import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addAddress, updateAddress, removeAddress } from '../../reducers/order'
import { closeModal, showModalErrors } from '../../reducers/modal'
import { fields } from '../../lib/pages'
import Form from '../Form'
import withGecoder from '../withGeoder'
import { NavLink } from 'react-router-dom'

class Address extends Component {
  static propTypes = {
    // state.order.customer
    customer: propTypes.object,
    address: propTypes.object,
    addresses: propTypes.array,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    addAddress: propTypes.func,
    updateAddress: propTypes.func,
    removeAddress: propTypes.func,
    showModalErrors: propTypes.func,
    closeModal: propTypes.func
  }

  handleChoose = (evt, address) => {
    evt.preventDefault()
    this.props.addAddress(address)
    evt.target.blur()
  }

  render() {
    const { customer, address, addresses, errors } = this.props
    const newAddressPath = customer
      ? `/customers/${customer.customer_id}/addresses/new`
      : ''
    const AddressForm = withGecoder(Form)
    return !customer ? (
      <div className="text">
        <p className="-alert">Please add a customer before adding an address</p>
        <p>
          <button className="btn" onClick={this.props.closeModal}>
            Close Window
          </button>
        </p>
      </div>
    ) : address ? (
      <div className="text">
        <p>
          <button className="btn-link" onClick={this.props.removeAddress}>
            Click here to remove this address and add a new one
          </button>
        </p>
        <AddressForm
          isNew={address ? false : true}
          idField="customer_address_id"
          fields={fields.customerAddress}
          data={address || {}}
          errors={errors || {}}
          upsertItem={this.props.updateAddress}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </div>
    ) : addresses && addresses.length ? (
      <>
        <p>
          <NavLink className="btn" to={newAddressPath}>
            Add a New Address
          </NavLink>
        </p>
        <ul className="results__list">
          {addresses.map(i => (
            <li key={i.customer_address_id}>
              <button
                className="btn-link"
                onClick={evt => this.handleChoose(evt, i)}
              >
                {i.street}
                {i.unit ? `, ${i.unit}` : ''}, {i.city}, {i.state}{' '}
                {i.postal_code}
              </button>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <div className="text">
        <p>
          This customer {"doesn't"} have any ACTIVE addresses associatd with
          their account
        </p>
        <p>
          <NavLink to={newAddressPath}>Click here to add one</NavLink>
        </p>
      </div>
    )
  }
}

Address.displayName = 'Address'

export default connect(
  state => ({
    customer: state.order.customer,
    address: state.order.address,
    errors: state.modal.errors
  }),
  { addAddress, updateAddress, removeAddress, closeModal, showModalErrors }
)(Address)
