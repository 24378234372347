import propTypes from 'prop-types'
import React from 'react'
import { formatDollars } from '../../lib/helpers'

const QuMenuItemName = ({ item }) => (
  <span>
    {item.Title} -- {formatDollars(item.Price)} --{' '}
    <span className="err">MenuItemID = {item.MenuItemID}</span>
  </span>
)

QuMenuItemName.displayName = 'QuMenuItemName'
QuMenuItemName.propTypes = {
  item: propTypes.object
}

const QuPortion = ({ item }) => (
  <li>
    {item.Title} -- {formatDollars(item.Price)} --{' '}
    <span className="err">ID = {item.ID}</span>
  </li>
)

QuPortion.displayName = 'QuPortion'
QuPortion.propTypes = {
  item: propTypes.object
}

const QuModifier = ({ item }) => (
  <li>
    {item.Title} -- {formatDollars(item.Price)} --{' '}
    <span className="err">ID = {item.ID}</span>
    {item.IngredientModifierGroups && item.IngredientModifierGroups.length
      ? item.IngredientModifierGroups.map(g => (
          <ul key={g.ID} className="mx__menu__modifier-group">
            <li>
              {g.Title} -- <span className="err">ID = {g.ID}</span>
            </li>
            <ul className="mx__menu__modifier-options">
              {g.Modifiers.map(o => (
                <QuModifier key={o.ID} item={o} />
              ))}
            </ul>
          </ul>
        ))
      : null}
  </li>
)

QuModifier.displayName = 'QuModifier'
QuModifier.propTypes = {
  item: propTypes.object
}

const QuMenuItem = ({ menuItem: i }) => {
  return (
    <li>
      <QuMenuItemName item={i} />
      {i.Portions && i.Portions.length ? (
        <ul className="mx__menu__modifier-group">
          <li>Portions</li>
          <ul className="mx__menu__modifier-options">
            {i.Portions.map(v => (
              <QuPortion key={v.id} item={v} />
            ))}
          </ul>
        </ul>
      ) : null}
      {i.ModifierGroups && i.ModifierGroups.length ? (
        <ul className="mx__menu__modifier-group">
          <li>Modifier Groups</li>
          {i.ModifierGroups.map(g => (
            <ul key={g.ID} className="mx__menu__modifier-group">
              <li>
                {g.Title} -- <span className="err">ID = {g.ID}</span>
              </li>
              <ul className="mx__menu__modifier-options">
                {g.Modifiers.map(o => (
                  <QuModifier key={o.ID} item={o} />
                ))}
              </ul>
            </ul>
          ))}
        </ul>
      ) : null}
      {i.IngredientGroups && i.IngredientGroups.length ? (
        <ul className="mx__menu__modifier-group">
          <li>Ingredient Groups</li>
          {i.IngredientGroups.map(g => (
            <ul key={g.ID} className="mx__menu__modifier-group">
              <li>
                {g.Title} -- <span className="err">ID = {g.ID}</span>
              </li>
              <ul className="mx__menu__modifier-options">
                {g.Ingredients.map(o => (
                  <QuModifier key={o.ID} item={o} />
                ))}
              </ul>
            </ul>
          ))}
        </ul>
      ) : null}
    </li>
  )
}

QuMenuItem.displayName = 'QuMenuItem'
QuMenuItem.propTypes = {
  menuItem: propTypes.object
}

export const QuMenu = ({ categories }) => {
  return (
    <div className="mx">
      <div className="mx__links">
        {categories.map(category => (
          <li key={category.ID}>
            <a href={`#cat-${category.ID}`}>{category.Title}</a>
          </li>
        ))}
      </div>
      <div className="mx__menus">
        <div className="mx__menu">
          {categories.map(category => (
            <div
              key={category.ID}
              id={`cat-${category.ID}`}
              className="mx__menu__group"
            >
              <h3>{category.Title}</h3>
              <ul className="mx__menu__items">
                {category.MenuItems.map(menuItem => (
                  <QuMenuItem key={menuItem.ID} menuItem={menuItem} />
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

QuMenu.displayName = 'QuMenu'
QuMenu.propTypes = {
  categories: propTypes.array
}
