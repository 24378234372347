import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { orderTypeRevenueCenterMap } from '../../lib/constants'
import { closeModal } from '../../reducers/modal'
import { updateRevenueCenter, changeOrderType } from '../../reducers/order'
import OrderRevenueCenters from './OrderRevenueCenters'

class RevenueCenter extends Component {
  static propTypes = {
    // state.order.customer
    orderType: propTypes.string,
    revenueCenters: propTypes.array,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    updateRevenueCenter: propTypes.func,
    changeOrderType: propTypes.func,
    closeModal: propTypes.func
  }

  handleOrderType = evt => {
    evt.preventDefault()
    this.props.closeModal()
    setTimeout(() => {
      this.props.changeOrderType()
    }, 300)
    evt.target.blur()
  }

  handleChoose = (evt, revenueCenter) => {
    evt.preventDefault()
    this.props.updateRevenueCenter(revenueCenter)
    evt.target.blur()
  }

  render() {
    const { revenueCenters } = this.props
    return this.props.orderType ? (
      <OrderRevenueCenters
        revenueCenters={revenueCenters}
        handler={this.handleChoose}
      />
    ) : (
      <p>
        <button className="btn-link" onClick={this.handleOrderType}>
          Please choose an order type before selecting a revenue center
        </button>
      </p>
    )
  }
}

RevenueCenter.displayName = 'RevenueCenter'

export default connect(
  state => ({
    orderType: state.order.orderType,
    errors: state.modal.errors
  }),
  { updateRevenueCenter, changeOrderType, closeModal }
)(RevenueCenter)
