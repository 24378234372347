import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { capitalize } from '../lib/helpers'
import { addBlocked, removeBlocked } from '../reducers/blockedHours'

const Results = ({ hours, loading, handler }) => {
  return (
    <div className="table__results">
      <div className="table__results__message">
        <p>
          {loading
            ? 'Loading...'
            : hours.length
            ? `Displaying ${hours.length} blocked times.`
            : 'No blocked times found for this revenue center '}
        </p>
      </div>
      <div className="table__results__buttons">
        <button className="btn table__new" onClick={handler}>
          Add New
        </button>
      </div>
    </div>
  )
}

Results.displayName = 'Results'
Results.propTypes = {
  hours: propTypes.array,
  loading: propTypes.bool,
  handler: propTypes.func
}

class BlockedHours extends Component {
  static propTypes = {
    hours: propTypes.array,
    loading: propTypes.bool,
    pageError: propTypes.string,
    addBlocked: propTypes.func,
    removeBlocked: propTypes.func
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.addBlocked()
    evt.target.blur()
  }

  handleRemove = (evt, index) => {
    evt.preventDefault()
    this.props.removeBlocked(parseInt(index))
    evt.target.blur()
  }

  render() {
    const { hours, loading } = this.props
    return (
      <div className="content__main">
        <Results hours={hours} loading={loading} handler={this.handleNew} />
        <table className="table--blocked-hours">
          <thead>
            <tr>
              <th>Weekday</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {hours.map((hour, index) => {
              const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
              const key = `${hour.weekday}-${hour.start_time}`
              return (
                <tr key={key} className={evenOdd}>
                  <td>{capitalize(hour.weekday)}</td>
                  <td>{hour.start_time}</td>
                  <td>{hour.end_time}</td>
                  <td>
                    <button
                      className="btn-link"
                      onClick={evt => this.handleRemove(evt, index)}
                    >
                      remove
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

BlockedHours.displayName = 'BlockedHours'

export default connect(
  state => ({
    ...state.blockedHours
  }),
  { addBlocked, removeBlocked }
)(BlockedHours)
