import tabs from './tabs'

const itemSettings = {
  regions: {
    endpoint: '/regions',
    id: 'region_id',
    title: 'name',
    tabs: tabs.regions
  },
  stores: {
    endpoint: '/stores',
    id: 'store_id',
    title: 'full_name',
    tabs: tabs.stores
  },
  revenueCenters: {
    endpoint: '/revenue-centers',
    id: 'revenue_center_id',
    params: [{ field: 'expand', value: true }],
    title: 'full_name',
    tabs: tabs.revenueCenters
  },
  menus: {
    endpoint: '/menus',
    id: 'menu_id',
    title: 'short_name',
    tabs: tabs.menus
  },
  categories: {
    endpoint: '/categories',
    id: 'category_id',
    title: 'full_name',
    tabs: tabs.categories
  },
  items: {
    endpoint: '/items',
    id: 'item_id',
    title: 'full_name',
    tabs: tabs.items
  },
  modifierGroups: {
    endpoint: '/modifier-groups',
    id: 'modifier_group_id',
    title: 'full_name',
    tabs: tabs.modifierGroups
  },
  allergens: {
    endpoint: '/allergens',
    id: 'allergen_id',
    title: 'name',
    hasDelete: true,
    tabs: tabs.allergens
  },
  tags: {
    endpoint: '/tags',
    id: 'tag_id',
    title: 'name',
    hasDelete: true,
    tabs: tabs.tags
  },
  customers: {
    endpoint: '/customers',
    id: 'customer_id',
    title: ['first_name', 'last_name'],
    tabs: tabs.customers
  },
  houseAccounts: {
    endpoint: '/house-accounts',
    id: 'house_account_id',
    title: 'name',
    tabs: tabs.houseAccounts
  },
  loyaltyPrograms: {
    endpoint: '/loyalty-programs',
    id: 'loyalty_program_id',
    title: 'name',
    params: [{ field: 'with_related', value: true }],
    tabs: tabs.loyaltyPrograms
  },
  taxes: {
    endpoint: '/taxes',
    id: 'tax_id',
    title: 'name',
    params: [{ field: 'with_related', value: 'revenue-centers' }],
    tabs: tabs.taxes
  },
  orders: {
    endpoint: '/orders',
    id: 'receipt_id',
    title: 'receipt_id',
    tabs: [
      { name: 'Summary', path: '' },
      { name: 'API Requests', path: 'requests' }
    ]
  },
  toast: {
    endpoint: '/toast',
    title: 'Toast',
    tabs: tabs.toast
  },
  salido: {
    endpoint: '/salido',
    title: 'Salido',
    tabs: tabs.salido
  },
  qu: {
    endpoint: '/qu',
    title: 'Qu',
    tabs: tabs.qu
  },
  revel: {
    endpoint: '/revel',
    title: 'Revel',
    tabs: tabs.revel
  },
  rpower: {
    endpoint: '/rpower',
    title: '',
    tabs: [{ name: 'API Credentials', path: '' }]
  },
  chowlyToos: {
    endpoint: '/chowly-toos',
    title: 'Chowly TOOS',
    tabs: tabs.chowlyToos
  },
  webhook: {
    endpoint: '/webhook',
    title: '',
    tabs: [{ name: 'Settings', path: '' }]
  },
  brightloom: {
    endpoint: '/brightloom',
    // title: 'Brightloom',
    tabs: tabs.brightloom
  },
  chowly: {
    endpoint: '/chowly',
    title: 'Chowly',
    tabs: tabs.chowly
  },
  ctuit: {
    endpoint: '/ctuit',
    title: 'CTUIT',
    tabs: tabs.ctuit
  },
  levelup: {
    endpoint: '/levelup',
    title: 'LevelUp',
    tabs: tabs.levelup
  },
  thanx: {
    endpoint: '/thanx',
    title: 'Thanx',
    tabs: tabs.thanx
  },
  googleMaps: {
    endpoint: '/google-maps',
    tabs: tabs.googleMaps
  },
  intercom: {
    endpoint: '/intercom',
    tabs: tabs.intercom
  },
  posts: {
    endpoint: '/posts',
    id: 'post_id',
    title: 'title',
    tabs: tabs.posts,
    fields: []
  },
  announcements: {
    endpoint: '/announcements',
    id: 'announcement_id',
    title: 'title',
    tabs: tabs.announcements,
    fields: []
  },
  announcementPages: {
    endpoint: '/announcement-pages',
    id: 'announcement_page_id',
    title: 'page',
    tabs: tabs.announcementPages,
    params: [{ field: 'with_related', value: true }],
    fields: []
  },
  configContent: {
    endpoint: '/config-content',
    tabs: tabs.configContent
  },
  configStyles: {
    endpoint: '/config-styles',
    tabs: tabs.configStyles
  },
  configSettings: {
    endpoint: '/config-settings',
    tabs: tabs.configSettings
  },
  kdsTerminals: {
    endpoint: '/kds-terminals',
    id: 'kds_terminal_id',
    title: 'name',
    tabs: tabs.kdsTerminals,
    fields: []
  },
  prepStations: {
    endpoint: '/prep-stations',
    id: 'prep_station_id',
    title: 'name',
    tabs: tabs.prepStations,
    fields: []
  },
  posPrinters: {
    endpoint: '/pos-printers',
    id: 'pos_printer_id',
    title: 'name',
    tabs: tabs.posPrinters,
    fields: []
  }
}

export default itemSettings
