import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PageError } from './PageError'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import { updateQuery, clearQuery, retrieveResults } from '../reducers/search'
import { XCircle } from 'react-feather'

export const SearchInput = ({
  label,
  query,
  placeholder,
  classes,
  clearSize,
  handleInput,
  handleEnter,
  handleClear
}) => {
  return (
    <label htmlFor="query" className={`label label-search ${classes || ''}`}>
      {label && <span>{label}</span>}
      <input
        id="query"
        type="search"
        className="search__input"
        name="query"
        autoComplete="off"
        value={query}
        placeholder={placeholder || ''}
        onChange={handleInput}
        onKeyPress={handleEnter}
      />
      {query.length ? (
        <button
          className="search__clear btn-link"
          onClick={handleClear}
          tabIndex="-1"
        >
          <XCircle size={clearSize || 20} />
        </button>
      ) : null}
    </label>
  )
}

SearchInput.displayName = 'SearchInput'
SearchInput.propTypes = {
  label: propTypes.string,
  query: propTypes.string,
  placeholder: propTypes.string,
  classes: propTypes.string,
  clearSize: propTypes.number,
  handleInput: propTypes.func,
  handleEnter: propTypes.func,
  handleClear: propTypes.func
}

class Search extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    // props
    endpoint: propTypes.string,
    resultId: propTypes.string,
    resultName: propTypes.oneOfType([propTypes.string, propTypes.array]),
    resultParens: propTypes.oneOfType([propTypes.string, propTypes.array]),
    classes: propTypes.string,
    placeholder: propTypes.string,
    resultAction: propTypes.func,
    defaults: propTypes.object,
    // state.search
    query: propTypes.string,
    results: propTypes.array,
    loading: propTypes.bool,
    error: propTypes.string,
    // mapDispatchToProps
    updateQuery: propTypes.func,
    clearQuery: propTypes.func,
    retrieveResults: propTypes.func
  }

  handleInput = evt => {
    this.props.updateQuery(evt.target.value)
  }

  handleEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.props.retrieveResults(this.props)
    }
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearQuery()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.retrieveResults(this.props)
    this.submitButton.current.blur()
  }

  handleAction = id => {
    const { resultId, defaults } = this.props
    this.props.resultAction(id, resultId, defaults)
  }

  render() {
    const { classes, placeholder, query, results, error, loading } = this.props
    return (
      <div className="search">
        <form id="form" className="form" onSubmit={this.handleSubmit}>
          <PageError msg={error} />
          <SearchInput
            query={query}
            placeholder={placeholder}
            classes={classes}
            handleInput={this.handleInput}
            handleEnter={this.handleEnter}
            handleClear={this.handleClear}
          />
          <div className="form__submit">
            <input
              className="btn"
              type="submit"
              value="Retrieve Results"
              ref={this.submitButton}
            />
          </div>
          <div className="results">
            {loading && (
              <div className="results__loading">
                <ClipLoader size={32} color={'#5a5aff'} />
              </div>
            )}
            <TransitionGroup>
              {results ? (
                <CSSTransition {...cssTransitionFade}>
                  <ul className="results__list">
                    {results.length ? (
                      results.map(result => (
                        <li key={result.id}>
                          <button
                            className="btn-link"
                            onClick={() => this.handleAction(result.id)}
                          >
                            {result.name}
                          </button>
                        </li>
                      ))
                    ) : (
                      <li>No results found</li>
                    )}
                  </ul>
                </CSSTransition>
              ) : null}
            </TransitionGroup>
          </div>
        </form>
      </div>
    )
  }
}

Search.displayName = 'Search'

export default connect(
  state => ({
    ...state.search
  }),
  { updateQuery, clearQuery, retrieveResults }
)(Search)
