import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from '../lib/utils'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  upsertRelation,
  deleteRelation,
  upsertItem,
  deleteItem,
  showItemErrors,
  reorderRelationList
} from '../reducers/relation'
import { reorderRecommendations } from '../reducers/recommendedItems'
import {
  makeStateOptions,
  makeRelationsEntity,
  makeRelationListEndpoint,
  cssTransitionFade
} from '../lib/helpersPage'
// import { PageError } from './PageError'
import Form from './Form'
import Table from './Table'
// import { TableResultsSimple } from './TableResults'
import RelationsResults from './RelationsResults'
import ClipLoader from 'react-spinners/ClipLoader'
import BackLink from './BackLink'
import withGecoder from './withGeoder'
import RecommendedItemsResults from './RecommendedItemsResults'
import DraggableList from './DraggableList'

class Relation extends Component {
  static propTypes = {
    // from props
    header: propTypes.object,
    endpoint: propTypes.string,
    id: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    validation: propTypes.object,
    isItem: propTypes.bool,
    list: propTypes.object,
    hasGeocoder: propTypes.bool,
    // from relation state object
    data: propTypes.object,
    loading: propTypes.bool,
    items: propTypes.array,
    errors: propTypes.object,
    // from relation reducer
    upsertRelation: propTypes.func,
    deleteRelation: propTypes.func,
    upsertItem: propTypes.func,
    deleteItem: propTypes.func,
    showItemErrors: propTypes.func,
    reorderRelationList: propTypes.func,
    // other state items
    page: propTypes.object,
    item: propTypes.object,
    stateOptions: propTypes.object
  }

  render() {
    const {
      endpoint,
      id,
      isItem,
      list,
      item,
      page,
      stateOptions,
      recommendedItems,
      recItems
    } = this.props
    const { id: pageId, idd: pageIdd, options } = page
    const formOptions = options ? makeStateOptions(options, stateOptions) : null
    const relationEndpoint = endpoint.replace(':id', pageId)
    const isNew = isEmpty(this.props.data)
    const upsertFunc = isItem
      ? this.props.upsertItem
      : this.props.upsertRelation
    const deleteFunc = isItem
      ? this.props.deleteItem
      : this.props.deleteRelation
    if (isNew) window.scrollTo(0, 0)
    const relationListEndpoint = list
      ? makeRelationListEndpoint(endpoint, list.path, pageId, pageIdd)
      : ''
    const relationListEntity = list
      ? makeRelationsEntity(relationListEndpoint)
      : ''
    const upsellItems = recItems.items
      .filter(i => i.recommendation_type === 'UPSELL')
      .map((i, idx) => ({ ...i, key: `UPSELL-${i[id]}-${idx}` }))
    const similarItems = recItems.items
      .filter(i => i.recommendation_type === 'SIMILAR')
      .map((i, idx) => ({ ...i, key: `SIMILAR-${i[id]}-${idx}` }))
    const RelationForm = this.props.hasGeocoder ? withGecoder(Form) : Form

    return (
      <div className="content__main">
        {this.props.loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!this.props.loading ? (
            <CSSTransition {...cssTransitionFade}>
              <>
                <div className="content__main__form">
                  <RelationForm
                    isNew={isNew}
                    endpoint={relationEndpoint}
                    idField={id}
                    fields={this.props.fields}
                    data={this.props.data}
                    errors={this.props.errors}
                    options={formOptions}
                    upsertItem={upsertFunc}
                    deleteItem={deleteFunc}
                    hasDelete={this.props.hasDelete}
                    validation={this.props.validation}
                    showErrors={this.props.showItemErrors}
                  />
                </div>
                {!isNew && (
                  <>
                    {list && (
                      <div
                        id={relationListEntity}
                        className="content__main__table"
                      >
                        <div className="content__main__table__header">
                          <h2>{this.props.list.title}</h2>
                        </div>
                        <RelationsResults
                          items={this.props.items}
                          endpoint={relationListEndpoint}
                          search={list.search}
                        />
                        <Table
                          classes={list.classes}
                          fields={list.fields}
                          items={this.props.items}
                          sorting={list.sorting}
                          id={list.id}
                          loading={null}
                          listEndpoint={relationListEndpoint}
                          reorderList={this.props.reorderRelationList}
                          isRelation={false}
                        />
                      </div>
                    )}
                    {recommendedItems && (
                      <>
                        <div className="item-list">
                          <h2>Upsell Items</h2>
                          <RecommendedItemsResults
                            items={upsellItems}
                            recommendationType="UPSELL"
                          />
                          <DraggableList
                            listEndpoint={recommendedItems.endpoint}
                            id={recommendedItems.id}
                            sorting={recommendedItems.sorting}
                            classes={recommendedItems.classes}
                            fields={recommendedItems.fields}
                            items={upsellItems}
                            loading={recItems.loading}
                            reorderList={this.props.reorderRecommendations}
                          />
                        </div>
                        <div className="item-list">
                          <h2>Similar Items</h2>
                          <RecommendedItemsResults
                            items={similarItems}
                            recommendationType="SIMILAR"
                          />
                          <DraggableList
                            listEndpoint={recommendedItems.endpoint}
                            id={recommendedItems.id}
                            sorting={recommendedItems.sorting}
                            classes={recommendedItems.classes}
                            fields={recommendedItems.fields}
                            items={similarItems}
                            loading={recItems.loading}
                            reorderList={this.props.reorderRecommendations}
                          />
                        </div>
                      </>
                    )}
                    <BackLink
                      page={page}
                      item={item}
                      relation={{ data: this.props.data }}
                      classes="form-back"
                    />
                  </>
                )}
              </>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Relation.displayName = 'Relation'

export default connect(
  state => ({
    ...state.relation,
    page: state.page,
    item: state.item,
    recItems: state.recommendedItems,
    stateOptions: state.options
  }),
  {
    upsertRelation,
    deleteRelation,
    upsertItem,
    deleteItem,
    showItemErrors,
    reorderRelationList,
    reorderRecommendations
  }
)(Relation)
