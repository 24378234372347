/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request, requestFile } from '../lib/services'
import { downloadData, downloadPDF } from '../lib/helpersFiles'
import { pageType } from '../lib/constants'
import { redirectPage, showPageError } from '../reducers/page'
import { makeQueryParams } from '../lib/helpers'

const makeParams = ({ filters, params }) => {
  const invalidFields = ['expand', 'limit']
  const allFilters = [...(filters || []), ...(params || [])].filter(
    i => !invalidFields.includes(i.field)
  )
  return makeQueryParams(allFilters)
}

const makeCsvEndpoint = (endpoint, page) => {
  switch (page.type) {
    case pageType.list:
      return `${endpoint}?${makeParams(page.list)}`
    case pageType.itemList:
      return `${endpoint}?${makeParams(page.itemList)}`
    default:
      return endpoint
  }
}

class Button extends Component {
  constructor(props) {
    super(props)
    this.linkRef = React.createRef()
  }

  static propTypes = {
    text: propTypes.string,
    token: propTypes.string,
    brand: propTypes.object,
    page: propTypes.object,
    endpoint: propTypes.string,
    filename: propTypes.string,
    type: propTypes.string,
    fileType: propTypes.string,
    redirectEndpoint: propTypes.string,
    showPageError: propTypes.func,
    redirectPage: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    const {
      token,
      brand,
      page,
      endpoint,
      fileType,
      filename,
      showPageError,
      redirectEndpoint
    } = this.props
    const e = page.id ? endpoint.replace(':id', page.id) : endpoint
    if (fileType) {
      const f = page.id ? filename.replace(':id', page.id) : filename
      const download =
        fileType === 'application/pdf' ? downloadPDF : downloadData
      requestFile(token, brand, e, fileType)
        .then(data => download(data, this.linkRef, fileType, f))
        .catch(err => showPageError(err.detail))
    } else {
      const csvEndpoint = makeCsvEndpoint(e, page)
      request(token, brand, csvEndpoint, 'POST')
        .then(() => this.props.redirectPage(redirectEndpoint || '/downloads'))
        .catch(err => showPageError(err.detail))
    }
    evt.target.blur()
  }

  render() {
    return (
      <>
        <button className="btn" onClick={this.handleClick}>
          {this.props.text}
        </button>
        <a ref={this.linkRef} href="#" target="_blank" />
      </>
    )
  }
}

Button.displayName = 'Button'

export default connect(
  state => ({
    token: state.token,
    brand: state.brand,
    page: state.page
  }),
  { redirectPage, showPageError }
)(Button)
