import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from '../lib/utils'
import Table from './Table'
import { Filter } from './Filters'
import { TableResults } from './TableResults'
import {
  updateFilter,
  resetFilters,
  fetchLink,
  refreshItems,
  itemListActions,
  selectItem
} from '../reducers/itemList'

class ItemList extends Component {
  static propTypes = {
    // ...state.page.itemList
    endpoint: propTypes.string,
    id: propTypes.string,
    classes: propTypes.string,
    sorting: propTypes.object,
    filters: propTypes.array,
    fields: propTypes.array,
    newButtonText: propTypes.string,
    hideNew: propTypes.bool,
    actions: propTypes.array,
    // ...state.itemList
    items: propTypes.array,
    links: propTypes.object,
    loading: propTypes.bool,
    error: propTypes.string,
    // stage.page.id
    pageId: propTypes.string,
    // stage.user
    user: propTypes.object,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    fetchLink: propTypes.func,
    refreshItems: propTypes.func,
    itemListActions: propTypes.func,
    selectItem: propTypes.func
  }

  handleFilter = evt => {
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    this.props.updateFilter(filter)
    if (type !== 'datepicker') evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.resetFilters()
    evt.target.blur()
  }

  getNext = evt => {
    evt.preventDefault()
    this.props.fetchLink(evt.target.href)
  }

  render() {
    const { endpoint, filters, pageId } = this.props
    const itemListEndpoint = `${endpoint.replace(':id', pageId)}`
    const hasFilters = !isEmpty(filters)
    return (
      <div className="content__main">
        {hasFilters ? (
          <div className="table__filters">
            {filters.map(filter => Filter(filter, this.handleFilter))}
          </div>
        ) : null}
        <TableResults
          user={this.props.user}
          items={this.props.items}
          links={this.props.links}
          endpoint={itemListEndpoint}
          next={this.getNext}
          reset={this.handleResetFilters}
          hasFilters={hasFilters}
          hideNew={this.props.hideNew}
          buttonText={this.props.newButtonText}
          actions={this.props.actions}
          listActions={this.props.itemListActions}
          refreshItems={this.props.refreshItems}
        />
        <Table
          classes={this.props.classes}
          fields={this.props.fields}
          items={this.props.items}
          sorting={this.props.sorting}
          id={this.props.id}
          loading={this.props.loading}
          listEndpoint={itemListEndpoint}
          selectItem={this.props.selectItem}
        />
      </div>
    )
  }
}

ItemList.displayName = 'ItemList'

export default connect(
  state => ({
    ...state.itemList,
    ...state.page.itemList,
    pageId: state.page.id,
    user: state.user
  }),
  {
    updateFilter,
    resetFilters,
    fetchLink,
    refreshItems,
    itemListActions,
    selectItem
  }
)(ItemList)
