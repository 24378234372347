import { request } from '../lib/services'
import { sortItems } from '../lib/helpers'
import { openModal, closeModal } from './modal'
import { clearPageError, handlePageError } from './page'

const initState = {
  items: [],
  categories: [],
  selected: [],
  loading: true,
  error: ''
}

export const RECITEMS_CLEAR = 'RECITEMS_CLEAR'
export const RECITEMS_LOADING = 'RECITEMS_LOADING'
export const RECITEMS_LOAD = 'RECITEMS_LOAD'
export const RECITEMS_LOAD_CATEGORIES = 'RECITEMS_LOAD_CATEGORIES'
export const RECITEMS_UPDATE_SELECTED = 'RECITEMS_UPDATE_SELECTED'
export const RECITEMS_ERROR = 'RECITEMS_ERROR'
export const RECITEMS_UP = 'RECITEMS_ERROR'

export const clearRecItems = () => ({ type: RECITEMS_CLEAR })
export const loadingRecItems = () => ({ type: RECITEMS_LOADING })
export const loadRecItems = items => ({ type: RECITEMS_LOAD, payload: items })
export const loadRecItemsCategories = categories => ({
  type: RECITEMS_LOAD_CATEGORIES,
  payload: categories
})
export const showRecItemsError = msg => ({
  type: RECITEMS_ERROR,
  payload: msg
})
export const updateSelected = selected => ({
  type: RECITEMS_UPDATE_SELECTED,
  payload: selected
})

export const fetchRecommendedItems = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { endpoint, sorting } =
      page.recommendedItems || page.relation.recommendedItems
    dispatch(clearPageError())
    dispatch(loadingRecItems())
    dispatch(fetchCategories())
    const endpt =
      endpoint.replace(':id', page.id).replace(':idd', page.idd) +
      '?expand=true'
    request(token, brand, endpt, 'GET')
      .then(items => {
        items = sortItems(items, sorting)
        dispatch(loadRecItems(items))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchCategories = () => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/categories?is_active=true&with_related=true&limit=1000`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadRecItemsCategories(resp.data))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const addNewRecommendedItem = config => {
  return dispatch => {
    dispatch(openModal(config))
  }
}

export const updateRecItems = recommendation_type => {
  return (dispatch, getState) => {
    const { token, brand, page, recommendedItems } = getState()
    const { items, selected } = recommendedItems
    dispatch(clearPageError())
    const other = items
      .filter(i => i.recommendation_type !== recommendation_type)
      .map(({ category_id, item_id, display_order, recommendation_type }) => ({
        category_id,
        item_id,
        display_order,
        recommendation_type
      }))
    const current = items
      .filter(i => i.recommendation_type === recommendation_type)
      .filter(o =>
        selected.some(
          ({ category_id, item_id }) =>
            o.category_id === category_id && o.item_id === item_id
        )
      )
      .map(({ category_id, item_id, display_order, recommendation_type }) => ({
        category_id,
        item_id,
        display_order,
        recommendation_type
      }))
    const displayOrders = current.map(i => i.display_order)
    const nextDisplayOrder = displayOrders.length
      ? Math.max(...displayOrders) + 1
      : 1
    const added = selected
      .filter(
        o =>
          !current.some(
            ({ category_id, item_id }) =>
              o.category_id === category_id && o.item_id === item_id
          )
      )
      .map((i, idx) => ({
        ...i,
        recommendation_type,
        display_order: nextDisplayOrder + idx
      }))
    const data = [...other, ...current, ...added]
    const { endpoint } = page.recommendedItems || page.relation.recommendedItems
    const endpt = endpoint.replace(':id', page.id).replace(':idd', page.idd)
    if (!data.length) {
      const deleted = items.map(
        ({ category_id, item_id, recommendation_type }) => ({
          category_id,
          item_id,
          recommendation_type
        })
      )
      request(token, brand, endpt, 'DELETE', deleted)
        .then(() => {
          dispatch(fetchRecommendedItems())
          dispatch(closeModal())
        })
        .catch(err => {
          dispatch(handlePageError(err))
        })
    } else {
      request(token, brand, endpt, 'PUT', data)
        .then(() => {
          dispatch(fetchRecommendedItems())
          dispatch(closeModal())
        })
        .catch(err => {
          dispatch(handlePageError(err))
        })
    }
  }
}

export const reorderRecommendations = (_, items) => {
  return (dispatch, getState) => {
    if (!items.length) return null
    const { token, brand, page, recommendedItems } = getState()
    const { endpoint } = page.recommendedItems || page.relation.recommendedItems
    const endpt = endpoint.replace(':id', page.id).replace(':idd', page.idd)
    const { recommendation_type } = items[0]
    const other = recommendedItems.items
      .filter(i => i.recommendation_type !== recommendation_type)
      .map(({ category_id, item_id, display_order, recommendation_type }) => ({
        category_id,
        item_id,
        display_order,
        recommendation_type
      }))
    const data = [...other, ...items]
    request(token, brand, endpt, 'PUT', data)
      .then(() => {
        dispatch(fetchRecommendedItems())
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

const relationsReducer = (state = initState, action) => {
  switch (action.type) {
    case RECITEMS_CLEAR:
      return { ...initState }
    case RECITEMS_LOADING:
      return { ...state, loading: true }
    case RECITEMS_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case RECITEMS_LOAD_CATEGORIES:
      return { ...state, categories: action.payload }
    case RECITEMS_UPDATE_SELECTED:
      return { ...state, selected: action.payload }
    case RECITEMS_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

export default relationsReducer
