import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from '@reduxjs/toolkit'
import throttle from 'lodash/throttle'
import { loadState, saveState } from './lib/localStorage'
import authReducer from './reducers/auth'
import resetReducer from './reducers/reset'
import tokenReducer from './reducers/token'
import userReducer from './reducers/user'
import pageReducer from './reducers/page'
import listReducer from './reducers/list'
import itemReducer from './reducers/item'
import itemListReducer from './reducers/itemList'
import itemListsReducer from './reducers/itemLists'
import relationsReducer from './reducers/relations'
import relationReducer from './reducers/relation'
import settingReducer from './reducers/setting'
import changePasswordReducer from './reducers/changePassword'
import brandReducer from './reducers/brand'
import messageReducer from './reducers/messages'
import optionsReducer from './reducers/options'
import filesReducer from './reducers/files'
import availsReducer from './reducers/dayparts'
import itemAvailsReducer from './reducers/itemAvails'
import dailyItemsReducer from './reducers/dailyItems'
import inventoryReducer from './reducers/inventory'
import attributesReducer from './reducers/attributes'
import deliveryZoneReducer from './reducers/deliveryZone'
import menuReducer from './reducers/menu'
import rewardReducer from './reducers/reward'
import giftCardReducer from './reducers/giftCard'
import hoursReducer from './reducers/hours'
import blockedHoursReducer from './reducers/blockedHours'
import orderTimesReducer from './reducers/orderTimes'
import receiptReducer from './reducers/receipt'
import orderReducer from './reducers/order'
import ordersReducer from './reducers/orders'
import orderMgmtReducer from './reducers/orderMgmt'
import orderMgmtStoresReducer from './reducers/orderMgmtStores'
import punchesReducer from './reducers/punches'
import hoursForWeekReducer from './reducers/hoursForWeek'
import discountItemsReducer from './reducers/discountItems'
import mappingReducer from './reducers/mapping'
import syncReducer from './reducers/sync'
import listOfItemsReducer from './reducers/listOfItems'
import reportReducer from './reducers/report'
import searchReducer from './reducers/search'
import modalReducer from './reducers/modal'
import configReducer from './reducers/config'
import recommendedItemsReducer from './reducers/recommendedItems'
import customerReducer from './reducers/customer'

const reducer = combineReducers({
  auth: authReducer,
  reset: resetReducer,
  token: tokenReducer,
  user: userReducer,
  changePassword: changePasswordReducer,
  brand: brandReducer,
  message: messageReducer,
  options: optionsReducer,
  page: pageReducer,
  list: listReducer,
  item: itemReducer,
  itemList: itemListReducer,
  itemLists: itemListsReducer,
  relations: relationsReducer,
  relation: relationReducer,
  setting: settingReducer,
  files: filesReducer,
  avails: availsReducer,
  itemAvails: itemAvailsReducer,
  dailyItems: dailyItemsReducer,
  inventory: inventoryReducer,
  attributes: attributesReducer,
  deliveryZone: deliveryZoneReducer,
  menu: menuReducer,
  reward: rewardReducer,
  giftCard: giftCardReducer,
  hours: hoursReducer,
  blockedHours: blockedHoursReducer,
  orderTimes: orderTimesReducer,
  receipt: receiptReducer,
  order: orderReducer,
  orders: ordersReducer,
  orderMgmt: orderMgmtReducer,
  orderMgmtStores: orderMgmtStoresReducer,
  punches: punchesReducer,
  hoursForWeek: hoursForWeekReducer,
  discountItems: discountItemsReducer,
  mapping: mappingReducer,
  sync: syncReducer,
  listOfItems: listOfItemsReducer,
  report: reportReducer,
  search: searchReducer,
  modal: modalReducer,
  config: configReducer,
  recommendedItems: recommendedItemsReducer,
  customer: customerReducer
})

// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    state = undefined
    saveState(state)
  }
  return reducer(state, action)
}

// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
// https://github.com/gaearon/todos/blob/03-persisting-state-to-local-storage/src/index.js
// https://stackoverflow.com/questions/36580963/can-you-or-should-you-use-localstorage-in-reduxs-initial-state
const persistedState = loadState()

const customizedMiddleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false
})

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: customizedMiddleware
})

store.subscribe(
  throttle(() => {
    saveState(store.getState())
  }, 3000)
)

export default store
