import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { punchInTypes, punchOutTypes } from '../../lib/constants'
import { fetchEmployee, upsertPunch } from '../../reducers/punches'
import { showModalErrors } from '../../reducers/modal'
import Search from '../Search'
import Form from '../Form'
import { PageError } from '../PageError'
import { isEmpty } from '../../lib/utils'
import { makeDateRange } from '../../lib/helpersDatetime'

const makeDepartmentOptions = departments => {
  return departments.map(d => {
    const dept = d.department
    return { value: dept.department_id, name: dept.name }
  })
}

const makeDateOptions = (startDate, endDate) => {
  const dateRange = makeDateRange(startDate, endDate)
  return dateRange.map(i => ({ value: i, name: i }))
}

const makeFields = (departments, startDate, endDate) => {
  const formFields = [
    {
      label: 'Department',
      field: 'department_id',
      type: 'select',
      options: makeDepartmentOptions(departments)
    },
    {
      label: 'Business Date',
      field: 'business_date',
      type: 'select',
      options: makeDateOptions(startDate, endDate)
    },
    {
      label: 'Punch In Type',
      field: 'punch_in_type',
      type: 'select',
      options: punchInTypes
    },
    {
      label: 'Punch In Time',
      field: 'punch_in_time',
      type: 'text'
    },
    {
      label: 'Punch Out Type',
      field: 'punch_out_type',
      type: 'select',
      options: punchOutTypes
    },
    {
      label: 'Punch Out Time',
      field: 'punch_out_time',
      type: 'text'
    },
    {
      field: 'employee_id',
      type: 'hidden'
    },
    {
      label: 'Tip Amount',
      field: 'tips_amount',
      type: 'text'
    }
  ]
  return formFields
}

class PunchNew extends Component {
  static propTypes = {
    employee: propTypes.object,
    startDate: propTypes.string,
    endDate: propTypes.string,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    fetchEmployee: propTypes.func,
    upsertPunch: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { employee, startDate, endDate, errors } = this.props
    if (!isEmpty(errors)) this.props.windowRef.current.scrollTop = 0
    return (
      <>
        {employee ? (
          <div className="punch">
            <div className="punch__employee">
              <h3>
                {employee.first_name} {employee.last_name} (
                {employee.timeclock_ext_id})
              </h3>
            </div>
            <PageError msg={errors ? errors.form : ''} />
            <Form
              isNew={false}
              idField="time_punch_id"
              fields={makeFields(employee.departments, startDate, endDate)}
              data={employee || {}}
              errors={errors || {}}
              upsertItem={this.props.upsertPunch}
              showErrors={this.props.showModalErrors}
              windowRef={this.props.windowRef}
            />
          </div>
        ) : (
          <Search
            endpoint="employees"
            resultId="employee_id"
            resultName={['first_name', 'last_name']}
            resultParens="timeclock_ext_id"
            placeholder="enter employee name or ID"
            resultAction={this.props.fetchEmployee}
          />
        )}
      </>
    )
  }
}

PunchNew.displayName = 'PunchNew'

export default connect(
  state => ({
    employee: state.punches.employee,
    startDate: state.punches.startDate,
    endDate: state.punches.endDate,
    errors: state.modal.errors
  }),
  { fetchEmployee, showModalErrors, upsertPunch }
)(PunchNew)
