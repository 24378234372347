import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const PageError = ({ msg, klass = '' }) => (
  <TransitionGroup>
    {msg && msg.length ? (
      <CSSTransition
        key="error"
        classNames="fade"
        timeout={{ enter: 250, exit: 0 }}
      >
        <div className={`error error--top ${klass}`}>
          <p>{msg}</p>
        </div>
      </CSSTransition>
    ) : null}
  </TransitionGroup>
)

PageError.displayName = 'PageError'
PageError.propTypes = {
  msg: propTypes.string,
  klass: propTypes.string
}

export const PageErrors = ({ errors, noField }) => (
  <>
    <PageError msg={errors.form || ''} />
    {errors.fields &&
      Object.entries(errors.fields).map((error, index) => {
        const [key, value] = error
        const msg = noField ? value : `${value} for ${key}`
        return <PageError key={`${key}-${index}`} msg={msg} klass="-fields" />
      })}
  </>
)

PageErrors.displayName = 'PageErrors'
PageErrors.propTypes = {
  errors: propTypes.object,
  noField: propTypes.bool
}
