import pages from '../lib/pages'
import { makePageFilters } from '../lib/helpersPage'
import { pageType } from '../lib/constants'
import { errMessages, processErrorMessage, logError } from '../lib/errors'
import { fetchOptions } from './options'
import { fetchItems, clearItems } from './list'
import { fetchItem } from './item'
import { fetchRelation, fetchRelatedItem } from './relation'
import { fetchSetting } from './setting'
import { fetchFiles } from './files'
import { fetchAvails } from './dayparts'
import { fetchItemAvails } from './itemAvails'
import { fetchAttributes } from './attributes'
import { clearSearchResults } from './search'
import { openModal, closeModal } from './modal'
import { fetchZone } from './deliveryZone'
import { fetchMenuFilters } from './menu'
import { fetchReceipt } from './receipt'
import { fetchDiscountedItems } from './discountItems'
import { fetchHours, fetchHolidays } from './hours'
import { fetchItemList, clearItemList } from './itemList'
import { fetchPunches } from './punches'
import { fetchItemLists } from './itemLists'
import { fetchDailyItems } from './dailyItems'
import { fetchInventory } from './inventory'
import { fetchReward } from './reward'
import { fetchGiftCard } from './giftCard'
import { fetchOrders } from './orders'
import { fetchOrderMgmt } from './orderMgmt'
import { resetSession, updateSavedFilters, clearSavedFilters } from './user'
import { fetchBlocked } from './blockedHours'
import { fetchOrderTimes } from './orderTimes'
import { fetchOrderMgmtStores } from './orderMgmtStores'
import { fetchMapping, fetchMissing } from './mapping'
import { fetchReport, clearReport } from './report'
import { isEmpty } from '../lib/utils'
import { fetchListOfItems } from './listOfItems'
import { fetchHoursForWeek } from './hoursForWeek'
import { fetchConfig } from './config'
import { fetchRecommendedItems } from './recommendedItems'

const initState = {
  loading: false,
  title: '',
  subtitle: '',
  content: '',
  classes: '',
  sections: [],
  options: [],
  redirect: '',
  error: '',
  id: null,
  idd: null,
  tabsOpen: false,
  sidebarOpen: false,
  image: {},
  files: {},
  list: {},
  item: {},
  itemLists: {},
  itemList: {},
  relations: {},
  relation: {},
  relatedItems: {},
  relatedItem: {},
  setting: {},
  punches: {},
  hoursForWeek: {},
  avails: {},
  attributes: {},
  dailyItems: {},
  inventory: {},
  deliveryZone: {},
  orders: {},
  iframe: {},
  config: {}
}

export const PAGE_LOADING = 'PAGE_LOADING'
export const PAGE_LOAD = 'PAGE_LOAD'
export const PAGE_UPDATE = 'PAGE_UPDATE'
export const PAGE_SAVE_FILTERS = 'PAGE_SAVE_FILTERS'
export const PAGE_REDIRECT = 'PAGE_REDIRECT'
export const PAGE_ERROR = 'PAGE_ERROR'
export const PAGE_ERROR_CLEAR = 'PAGE_ERROR_CLEAR'
export const PAGE_CLEAR = 'PAGE_CLEAR'
export const UPDATE_LIST = 'UPDATE_LIST'
export const UPDATE_AVAILS = 'UPDATE_AVAILS'
export const UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES'
export const TOGGLE_TABS = 'TOGGLE_TABS'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const updatePage = data => ({ type: PAGE_UPDATE, payload: data })
export const redirectPage = redirect => ({
  type: PAGE_REDIRECT,
  payload: redirect
})
export const clearPage = () => ({ type: PAGE_CLEAR })
export const toggleTabs = () => ({ type: TOGGLE_TABS })
export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR })

const shouldUpdateList = (page, prevPage) => {
  // if we're on a list page, always refresh the list
  if (page.type === pageType.list) return true
  // don't fetch if we're using the sidebar for search
  if (page.list && page.list.search) return false
  // otherwise only fetch if it's a new list endpoint
  const newEndpoint = page.list ? page.list.endpoint : null
  const currentEndpoint = prevPage.list ? prevPage.list.endpoint : null
  return newEndpoint !== currentEndpoint
}

const shouldUpdateItem = (page, prevPage) => {
  if (page.type === pageType.item) return true
  if (page.type === pageType.itemList && !page.itemList.listOnly) return true
  if (page.type === pageType.itemLists) return true
  if (page.type === pageType.itemFiles) return true
  if (page.type === pageType.itemSetting) return true
  if (page.type === pageType.itemRelations) return true
  if (page.type === pageType.recommendedItems) return true
  if (page.type === pageType.relatedItem) return true
  if (page.type === pageType.hours) return true
  if (page.type === pageType.holidays) return true
  if (page.type === pageType.blockedHours) return true
  if (page.type === pageType.inventory) return true
  if (page.type === pageType.iframe) return true
  if (page.type === pageType.deliveryZone) return false
  if (page.type === pageType.menu) return false
  if (page.type === pageType.receipt) return false
  if (page.type === pageType.customer) return true
  if (page.type === pageType.itemAttributes && page.id !== prevPage.id)
    return true
  if (page.type === pageType.revenueCenterAttributes && page.id !== prevPage.id)
    return true
  if (
    page.type === pageType.itemRelation &&
    page.idd !== prevPage.idd &&
    (prevPage.idd === 'new' || !prevPage.idd)
  )
    return true
  return false
}

export const loadingPage = () => {
  return dispatch => {
    const config = {
      title: 'Loading Page...',
      subtitle: 'This will be done in just a sec',
      classes: 'modal--loading',
      type: 'submitting'
    }
    dispatch(openModal(config))
  }
}

const makePageType = pageType => {
  switch (pageType) {
    case 'itemAttributes':
    case 'revenueCenterAttributes':
      return 'attributes'
    default:
      return pageType
  }
}

const makeFilters = (page, options, savedFilters) => {
  const pageType = makePageType(page.type)
  const filters = page[pageType].filters
  if (!filters) return page
  page[pageType].filters = makePageFilters(
    filters,
    page.options,
    options,
    savedFilters
  )
  return page
}

const makeReportFilters = (page, options, prevPage, report) => {
  if (!page.report.filters) return page
  const samePage =
    prevPage.report && prevPage.report.reportType === page.report.reportType
  if (samePage) {
    page.report.filters = prevPage.report.filters.map(i => {
      const value = i.type === 'datePicker' ? new Date(i.value) : i.value
      return { ...i, value: value }
    })
  } else if (report.filters) {
    page.report.filters = prevPage.report.filters.map(i => {
      return { ...i, value: report.filters[i.field] }
    })
  } else {
    page.report.filters = makePageFilters(
      page.report.filters,
      page.options,
      options
    )
  }
  return page
}

const makeListFilters = (page, options, savedFilters, prevPage, report) => {
  if (page.list.isDrilldown && prevPage.type === 'report') {
    try {
      page.list.filters = prevPage.report.filters.map(i => {
        return { ...i, value: report.filters[i.field] }
      })
      return page
    } catch (e) {
      return makeFilters(page, options, savedFilters)
    }
  }
  return makeFilters(page, options, savedFilters)
}

const withFilters = (page, options, savedFilters, prevPage, report) => {
  switch (page.type) {
    case 'report':
      return makeReportFilters(page, options, prevPage, report)
    case 'list':
      return makeListFilters(page, options, savedFilters, prevPage, report)
    case 'itemList':
    case 'avails':
    case 'attributes':
    case 'itemAttributes':
    case 'revenueCenterAttributes':
    case 'dailyItems':
    case 'inventory':
    case 'punches':
    case 'hoursForWeek':
    case 'orders':
      return makeFilters(page, options, savedFilters)
    default:
      return page
  }
}

const checkEmptyOptions = (pageOptions, stateOptions) => {
  if (!pageOptions) return false
  const empty = pageOptions.filter(i => !stateOptions[i])
  return empty.length ? true : false
}

const maybeClearState = (dispatch, pathname, page, prevPage) => {
  if (pathname !== prevPage.route) {
    switch (page.type) {
      case 'list':
        return dispatch(clearItems())
      default:
        break
    }
  }
}

export const loadPage = (pathname, { id, idd }) => {
  return (dispatch, getState) => {
    const { page: prevPage, user, options, report } = getState()
    dispatch(closeModal())
    dispatch(clearItemList())
    dispatch(clearReport())
    let page = pages[pathname] || pages['/not_found']
    page.route = pathname
    maybeClearState(dispatch, pathname, page, prevPage)
    const emptyOptions = checkEmptyOptions(page.options, options)
    if (emptyOptions) dispatch(loadingPage())
    dispatch(fetchOptions(page.options))
      .then(options => {
        page = { ...page, id: id, idd: idd }
        const savedFilters = user ? user.savedFilters : {}
        page = withFilters(page, options, savedFilters, prevPage, report)
        dispatch({ type: PAGE_LOAD, payload: page })
        if (emptyOptions) dispatch(closeModal())
        if (shouldUpdateItem(page, prevPage)) {
          dispatch(fetchItem())
        }
        if (page.type === pageType.list && !page.search) {
          dispatch(clearSearchResults())
        }
        if (shouldUpdateList(page, prevPage)) {
          dispatch(fetchItems())
        }
        if (
          page.type === pageType.itemRelation &&
          page.relation.recommendedItems
        ) {
          dispatch(fetchRecommendedItems())
        }
        switch (page.type) {
          case pageType.itemList:
          case pageType.receiptList:
            return dispatch(fetchItemList())
          case pageType.itemLists:
            return dispatch(fetchItemLists())
          case pageType.itemFiles:
            return dispatch(fetchFiles())
          case pageType.itemRelation:
            return dispatch(fetchRelation())
          case pageType.relatedItem:
            return dispatch(fetchRelatedItem())
          case pageType.itemSetting:
            return dispatch(fetchSetting())
          case pageType.dayparts:
            return dispatch(fetchAvails())
          case pageType.avails:
            return dispatch(fetchItemAvails())
          case pageType.dailyItems:
            return dispatch(fetchDailyItems())
          case pageType.inventory:
            return dispatch(fetchInventory())
          case pageType.attributes:
          case pageType.itemAttributes:
          case pageType.revenueCenterAttributes:
            return dispatch(fetchAttributes())
          case pageType.deliveryZone:
            return dispatch(fetchZone())
          case pageType.menu:
            return dispatch(fetchMenuFilters())
          case pageType.reward:
            return dispatch(fetchReward())
          case pageType.giftCard:
            return dispatch(fetchGiftCard())
          case pageType.receipt:
            return dispatch(fetchReceipt())
          case pageType.discountItems:
            return dispatch(fetchDiscountedItems())
          case pageType.hours:
            return dispatch(fetchHours())
          case pageType.holidays:
            return dispatch(fetchHolidays())
          case pageType.blockedHours:
            return dispatch(fetchBlocked())
          case pageType.orderTimes:
            return dispatch(fetchOrderTimes())
          case pageType.punches:
            return dispatch(fetchPunches())
          case pageType.hoursForWeek:
            return dispatch(fetchHoursForWeek())
          case pageType.orders:
            return dispatch(fetchOrders())
          case pageType.orderMgmt:
            return dispatch(fetchOrderMgmt())
          case pageType.orderMgmtStores:
            return dispatch(fetchOrderMgmtStores())
          case pageType.mapping:
            return dispatch(fetchMapping())
          case pageType.menuExtUpdate:
            return dispatch(fetchMissing())
          case pageType.report:
            return dispatch(fetchReport())
          case pageType.setting:
            return dispatch(fetchSetting())
          case pageType.listOfItems:
            return dispatch(fetchListOfItems())
          case pageType.config:
            return dispatch(fetchConfig())
          case pageType.recommendedItems:
            return dispatch(fetchRecommendedItems())
          default:
            break
        }
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

const errAws403 = 'Unexpected token < in JSON at position 0'

export const handlePageError = (err, hide) => {
  return (dispatch, getState) => {
    if (err.status === 401) {
      dispatch(clearPageError())
      dispatch(resetSession())
    } else if (err.status === 403) {
      dispatch(showPageError(errMessages.forbidden))
    } else if (err.message && err.message === errAws403) {
      dispatch(showPageError(errMessages.forbidden))
    } else if (!hide) {
      if (err.message) {
        const user = getState().user || {}
        logError(err, user)
      }
      const msg =
        err.params && !isEmpty(err.params)
          ? Object.values(err.params)[0]
          : `${err.detail || err.message}`
      const errMsg = processErrorMessage(msg)
      dispatch(closeModal())
      window.scroll(0, 0)
      dispatch(showPageError(errMsg))
    }
  }
}

export const showPageError = msg => ({ type: PAGE_ERROR, payload: msg })
export const clearPageError = () => ({ type: PAGE_ERROR_CLEAR })

export const makeNewFilters = (filters, updated) => {
  return filters.map(f => {
    if (f.field === updated.field) {
      f.value = isNaN(parseInt(updated.value))
        ? updated.value
        : parseInt(updated.value)
    } else if (f.reset && f.reset.indexOf(updated.field) !== -1) {
      f.value = ''
    }
    return f
  })
}

export const makeResetFilters = filters => {
  return filters.map(f => {
    if (f.type === 'datePicker') {
      f.value = f.noClear ? new Date() : null
      return f
    }
    const nonDisabled = f.includeNone
      ? f.options
      : f.options.filter(i => !i.isDisabled)
    const value = nonDisabled.length ? nonDisabled[0].value : ''
    f.value = isNaN(parseInt(value)) ? value : parseInt(value)
    return f
  })
}

const filterUpdateFunction = entity => {
  switch (entity) {
    case 'attributes':
      return fetchAttributes
    case 'avails':
      return fetchItemAvails
    default:
      return fetchItems
  }
}

export const updateFilter = (filter, autoRefresh = true) => {
  return (dispatch, getState) => {
    const { page } = getState()
    const pageType = makePageType(page.type)
    const entity = page[pageType]
    dispatch(updateSavedFilters(entity.filters, filter))
    const newFilters = makeNewFilters(entity.filters, filter)
    const newEntity = { ...entity, filters: newFilters }
    dispatch(updatePage({ [pageType]: newEntity }))
    if (autoRefresh) dispatch(filterUpdateFunction(pageType)())
  }
}

export const resetFilters = (autoRefresh = true) => {
  return (dispatch, getState) => {
    const { page } = getState()
    const pageType = makePageType(page.type)
    const entity = page[pageType]
    dispatch(clearSavedFilters(entity.filters))
    const newFilters = makeResetFilters(entity.filters)
    const newEntity = { ...entity, filters: newFilters }
    dispatch(updatePage({ [pageType]: newEntity }))
    if (autoRefresh) dispatch(filterUpdateFunction(pageType)())
  }
}

const pageReducer = (state = initState, action) => {
  switch (action.type) {
    case PAGE_CLEAR:
      return { ...initState }
    case PAGE_LOADING:
      return { ...state, loading: true }
    case PAGE_LOAD:
      return { ...action.payload, redirect: '', loading: false }
    case PAGE_UPDATE:
      return { ...state, ...action.payload }
    case PAGE_REDIRECT:
      return { ...state, redirect: action.payload }
    case PAGE_ERROR:
      return { ...state, error: action.payload }
    case PAGE_ERROR_CLEAR:
      return { ...state, error: '' }
    case TOGGLE_TABS:
      return { ...state, tabsOpen: !state.tabsOpen }
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarOpen: !state.sidebarOpen }
    default:
      return state
  }
}

export default pageReducer
