import propTypes from 'prop-types'
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// https://github.com/zenoamaro/react-quill
// https://quilljs.com/docs/configuration/

class QuillEditor extends React.Component {
  constructor(props) {
    super(props)
    // this.state = { text: '' } // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this)
  }

  static propTypes = {
    id: propTypes.string,
    value: propTypes.string,
    handler: propTypes.func
  }

  handleChange(value) {
    // this.setState({ text: value })
    const evt = {
      type: 'change',
      target: { id: this.props.id, value: value }
    }
    this.props.handler(evt)
  }

  render() {
    const toolbarOptions = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'link', 'image', 'blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      [{ color: [] }, 'clean']
    ]
    const modules = {
      toolbar: toolbarOptions,
      clipboard: {
        matchVisual: false
      }
    }
    return (
      <ReactQuill
        value={this.props.value}
        onChange={this.handleChange}
        modules={modules}
      />
    )
  }
}

export default QuillEditor
