import { parseISO, differenceInDays } from 'date-fns'
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { add, sub } from 'date-fns'
import { weekdaysUpper, timezoneMap } from './constants'

const DATE = 'yyyy-MM-dd'
const DATETIME = 'yyyy-MM-dd h:mm a'

export const maybeConvertTz = tz => {
  return tz in timezoneMap ? timezoneMap[tz] : tz
}

export const maybeMakeDateStr = value => {
  return value instanceof Date ? format(value, DATE) : value
}

// https://stackoverflow.com/questions/54555491/how-to-guess-users-timezone-using-date-fns-in-a-vuejs-app
export const getUserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (err) {
    return 'America/Los_Angeles'
  }
}

export const makeLocalDate = dateStr => {
  const tz = getUserTimezone()
  return toDate(dateStr, { timezone: tz })
}

// returns a date string in the user's local time
export const makeLocalDateStr = (days = 0, fmt = DATE) => {
  return format(add(new Date(), { days: days }), fmt)
}

export const fmtLocalDate = (dateStr, fmt = DATE) => {
  if (!dateStr) return ''
  return format(makeLocalDate(dateStr), fmt)
}

export const dateStrToLocalDate = dateStr => {
  return utcToZonedTime(new Date(dateStr), 'GMT')
}

export const dateStrToLocalDateStr = (dateStr, fmt = 'MMM d, yyyy') => {
  return format(utcToZonedTime(new Date(dateStr), 'GMT'), fmt)
}

export const dateToIso = (date, tz) => {
  return zonedTimeToUtc(date, tz).toISOString()
}

// translate a local time in a certain timezone to the user's local time
// that is, if the local time in New York is 8pm EST and the user is
// in Chicago, return 8pm CST (instead of 7pm CST)
export const makeZonedDate = (tz, days = 0, dateStr) => {
  let date = dateStr ? new Date(dateStr) : new Date()
  date = add(date, { days: days })
  return utcToZonedTime(date, tz)
}

export const makeZonedDateStr = (tz, days = 0, fmt = DATE, dateStr) => {
  const zonedDate = makeZonedDate(tz, days, dateStr)
  return format(zonedDate, fmt)
}

// just take a date or datetime string and format it
export const formatDateStr = (dateStr, tz, fmt = DATETIME) => {
  const date = tz ? makeZonedDate(tz, 0, dateStr) : new Date(dateStr)
  return format(date, fmt)
}

export const fmtDate = (dateStr, tz) => {
  return formatDateStr(dateStr, tz, DATE)
}

export const formatDateStrWithTz = (dateStr, tz) => {
  const date = makeZonedDate(tz, 0, dateStr)
  return format(date, `${DATETIME} zzz`, { timeZone: tz })
}

export const formatReport = dateStr => {
  return dateStr.replace(' AM', 'am').replace(' PM', 'pm')
}

export const makeWeekDates = (startWeekday, offset = 0) => {
  const startIndex = weekdaysUpper.indexOf(startWeekday)
  const current = new Date()
  const endIndex = current.getDay()
  let diff = endIndex - startIndex
  diff = diff < 0 ? diff + 7 : diff
  const start = sub(current, { days: diff + offset * 7 })
  const end = add(start, { days: 6 })
  return [start, end]
}

// https://stackoverflow.com/questions/13571700/get-first-and-last-date-of-current-month-with-javascript-or-jquery/13572682
export const makeMonthDates = (offset = 0) => {
  const date = sub(new Date(), { months: offset })
  const start = new Date(date.getFullYear(), date.getMonth(), 1)
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return [start, end]
}

// WEEKDAY MANIPULATION

export const getWeekdayDifference = (start, end) => {
  const startIndex = weekdaysUpper.indexOf(start)
  const endIndex = weekdaysUpper.indexOf(end)
  let diff = endIndex - startIndex
  return diff < 0 ? diff + 7 : diff
}

export const getDateForWeekday = (weekday, tz) => {
  const currentWeekday = makeZonedDateStr(tz, 0, 'EEEE').toUpperCase()
  const weekdayDiff = getWeekdayDifference(currentWeekday, weekday)
  return makeZonedDateStr(tz, weekdayDiff)
}

export const hasTzOffset = dateStr => {
  const parts = dateStr.split('T')
  if (parts.length !== 2) return false
  return parts[1].includes('+') || parts[1].includes('-')
}

// convert '2020-01-31T20:00:00-05:00' (local time of order)
// to '2020-01-31T20:00:00-06:00' (local time of user)
// same local time, different timezone
export const zonedDateStrToDate = (dateStr, tz) => {
  const parsedTz = maybeConvertTz(tz)
  const zonedDate = toDate(dateStr)
  return utcToZonedTime(zonedDate, parsedTz)
}

// see comments on zonedDateStrToDate
export const zonedDateStrToDateStr = (dateStr, tz, fmt = DATETIME) => {
  return format(zonedDateStrToDate(dateStr, tz), fmt)
}

export const getCurrentISO = () => {
  return new Date().toISOString().split('.')[0] + 'Z'
}

export const isoToDateStr = (iso, fmt = DATETIME) => {
  return format(parseISO(iso), fmt)
}

export const isoToZonedDate = (iso, tz) => {
  return utcToZonedTime(iso, tz)
}

export const isoToZonedDateStr = (iso, tz, fmt = DATETIME) => {
  return format(isoToZonedDate(iso, tz), fmt)
}

export const offsetIso = iso => {
  return sub(parseISO(iso), { seconds: 60 }).toISOString().split('.')[0] + 'Z'
}

export const makeRequestedAt = (
  dateStr,
  tz,
  minutes,
  fmt = 'EEE, MMM d @ h:mma'
) => {
  const startDate = zonedDateStrToDate(dateStr, tz)
  if (!minutes) return format(startDate, fmt)
  const startAt = format(startDate, fmt)
  const endDate = add(startDate, {
    minutes: minutes
  })
  const endAt = format(endDate, 'h:mma')
  return `${startAt}-${endAt}`
}

export const makeMakeTime = (requestedAt, tz, currentWait) => {
  const date = zonedDateStrToDate(requestedAt, tz)
  return sub(date, { minutes: currentWait })
}

// accepts startDate and
export const makeDateRange = (startDate, endDate) => {
  const start = makeLocalDate(startDate)
  const end = makeLocalDate(endDate)
  const days = differenceInDays(end, start)
  let dates = [fmtDate(start)]
  for (let i = 1; i <= days; i++) {
    const newDate = add(start, { days: i })
    dates.push(fmtDate(newDate))
  }
  return dates
}

export const makeDateRangeDays = days => {
  const start = fmtDate(new Date())
  const end = fmtDate(add(new Date(), { days: days }))
  return makeDateRange(start, end)
}

// TIME CONVERSIONS

// convert a HH:MM AM|PM time to HH:MM:SS time (2:30 PM => 14:30:00)
export const humanTimeToDbTime = timeStr => {
  let [time, ampm] = timeStr.split(' ')
  if (time.includes('PM')) {
    ampm = 'PM'
  } else if (time.includes('AM')) {
    ampm = 'AM'
  }
  const [hours, minutes] = time.split(':').map(i => parseInt(i))
  const militaryHours =
    ampm === 'PM'
      ? hours === 12
        ? hours
        : hours + 12
      : hours === 12
      ? 0
      : hours
  const hoursStr = militaryHours.toString().padStart(2, '0')
  const minutesStr = minutes ? minutes.toString().padStart(2, '0') : '00'
  return `${hoursStr}:${minutesStr}:00`
}

export const hoursMinutesToTime = (h, m) => {
  return `${h > 12 ? h - 12 : h || 12}:${m.toString().padStart(2, '0')} ${
    h >= 12 ? 'PM' : 'AM'
  }`
}

export const minutesToTime = minutes => {
  if (!minutes && minutes !== 0) return null
  const h = Math.floor(minutes / 60)
  const m = (minutes % 60).toString().padStart(2, '0')
  return hoursMinutesToTime(h, m)
}

export const isValidTimeAmPm = timeStr => {
  // prettier-ignore
  let match = String(timeStr).match(/\b((1[0-2]|0?[0-9]):([0-5][0-9]) ([AaPp][Mm]))/g)
  if (!match) return false
  match = match[0]
  if (['0:00 AM', '0:00 PM', '00:00 AM', '00:00 PM'].includes(match))
    return false
  return true
}

export const isValidDate = (month, day) => {
  if (!month || !day) return false
  const currentYear = format(new Date(), 'yyyy')
  const currentMonth = month.padStart(2, '0')
  const currentDay = day.padStart(2, '0')
  const dateStr = `${currentYear}-${currentMonth}-${currentDay}`
  const dateObj = new Date(dateStr)
  return isNaN(dateObj.getTime()) ? false : true
}

export const timeToMinutes = timeStr => {
  if (!timeStr) return null
  const [time, ampm] = timeStr.split(' ')
  let [hours, minutes] = time.split(':').map(i => parseInt(i))
  if (ampm.toUpperCase() === 'PM') {
    hours = hours < 12 ? hours + 12 : hours
  } else {
    hours = hours === 12 ? 0 : hours
  }
  const newMinutes = hours * 60 + minutes
  return newMinutes
}

export const reformatMilitaryTime = timeStr => {
  const [hours, minutes] = timeStr.split(':').map(i => parseInt(i))
  if (typeof minutes === 'undefined') return null
  return hoursMinutesToTime(hours, minutes)
}

export const makeDateValues = (range, startWeekday) => {
  const today = new Date()
  switch (range) {
    case 'today':
      return { start: today, end: today }
    case 'yesterday': {
      const yesterday = sub(today, { days: 1 })
      return { start: yesterday, end: yesterday }
    }
    case 'thisWeek': {
      const [start, end] = makeWeekDates(startWeekday)
      return { start: start, end: end }
    }
    case 'lastWeek': {
      const [start, end] = makeWeekDates(startWeekday, 1)
      return { start: start, end: end }
    }
    case 'thisMonth': {
      const [start, end] = makeMonthDates()
      return { start: start, end: end }
    }
    case 'lastMonth': {
      const [start, end] = makeMonthDates(1)
      return { start: start, end: end }
    }
    default:
      return { start: today, end: today }
  }
}
