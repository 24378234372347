import propTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  serviceType as serviceTypeOptions,
  orderTypeRevenueCenterMap
} from '../lib/constants'
import { makeDatepickerArgs } from '../lib/helpersOrder'
import DatePicker from 'react-datepicker'
import {
  editItemInCart,
  removeItemFromCart,
  submitOrder,
  removeOrder,
  clearOrder,
  editCustomer,
  editAddress,
  changeOrderType,
  changeRevenueCenter,
  updateServiceType,
  updateRequestedAt,
  removeOrderAlert,
  toggleCart
} from '../reducers/order'
import { XCircle, X } from 'react-feather'
import { isoToZonedDate, dateToIso } from '../lib/helpersDatetime'

export const ServiceType = ({ value, handler }) => {
  let options = [...serviceTypeOptions].slice(1)
  return (
    <div className="order__row">
      <div className="order__label">Service Type</div>
      <div className="order__value">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor="service-type" className="label select">
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            id="service-type"
            value={value}
            aria-label="Service Type"
            onChange={handler}
          >
            {options.map((option, index) => (
              <option
                key={`${option.value}-${index}`}
                value={option.value}
                disabled={option.isDisabled}
              >
                {option.name}
              </option>
            ))}
          </select>
          <span className="select__arrow" />
        </label>
      </div>
    </div>
  )
}

ServiceType.displayName = 'ServiceType'
ServiceType.propTypes = {
  value: propTypes.string,
  handler: propTypes.func,
  address: propTypes.object
}

const makeCustomerSection = (customer, handler) => {
  if (!customer) {
    return [{ label: 'Customer', value: 'add a customer', handler: handler }]
  }
  return [
    { label: 'Customer', value: ' ' },
    {
      label: 'Name',
      value: [customer.first_name, customer.last_name].join(' '),
      handler: handler
    },
    { label: 'Email', value: customer.email },
    { label: 'Phone', value: customer.phone },
    { label: 'Company', value: customer.company }
  ]
}

const makeAddressSection = (customer, address, outsideZone, handler) => {
  if (!customer) {
    return [
      {
        label: 'Address',
        value: <span className="-lower">requires a customer</span>
      }
    ]
  }
  if (!address) {
    return [{ label: 'Address', value: 'add an address', handler: handler }]
  }
  return [
    {
      label: 'Address',
      value: outsideZone ? <span className="-alert">Outside Zone</span> : ' '
    },
    {
      label: 'Street & Unit',
      value: [address.street, address.unit]
        .filter(i => i)
        .filter(i => i.length)
        .join(', '),
      id: address.customer_address_id,
      handler: handler
    },
    {
      label: 'City, State & Zip',
      value: [address.city, address.state, address.postal_code]
        .filter(i => i)
        .filter(i => i.length)
        .join(', ')
    },
    { label: 'Company', value: address.company },
    { label: 'Contact', value: address.contact },
    { label: 'Phone', value: address.phone }
  ]
}

const RowValue = ({ row }) => {
  if (row.handler) {
    return (
      <button className="btn-link" onClick={row.handler}>
        {row.value}
      </button>
    )
  }
  let link
  if (row.path) {
    if (row.idd) link = row.path.replace(':idd', row.idd)
    link = row.path.replace(':id', row.id)
  }
  return link ? <a href={`${link}`}>{row.value}</a> : row.value || '--'
}

RowValue.displayName = 'RowValue'
RowValue.propTypes = {
  row: propTypes.object
}

const OrderSection = ({ rows }) => {
  return (
    <div className="order__section">
      {rows.map(row => (
        <div key={row.label} className="order__row">
          <div className="order__label">{row.label}</div>
          <div className="order__value">
            <RowValue row={row} />
          </div>
        </div>
      ))}
    </div>
  )
}

OrderSection.displayName = 'OrderSection'
OrderSection.propTypes = {
  rows: propTypes.array
}

const Cart = ({ cart, editItem, removeItem }) => {
  const cartTotal = cart.reduce((t, i) => t + i.totalPrice, 0.0).toFixed(2)
  return (
    <div className="order__section order__cart">
      {!cart.length ? (
        <div className="order__row">
          <div className="order__label">Cart</div>
          <div className="order__value">Cart Is Empty</div>
        </div>
      ) : (
        <>
          <div className="order__row">
            <div className="order__label">Cart</div>
            <div className="order__value" />
          </div>
          {cart.map((item, index) => {
            const price = parseFloat(item.totalPrice).toFixed(2)
            return (
              <div key={`${item.id}-${index}`} className="order__row">
                <div className="order__label">
                  <button
                    className="btn-link"
                    onClick={evt => editItem(evt, index)}
                  >
                    {item.name} x {item.quantity}
                  </button>
                  <div className="order__remove">
                    <button
                      className="btn-link"
                      onClick={evt => removeItem(evt, index)}
                    >
                      <XCircle size={13} />
                    </button>
                  </div>
                </div>
                <div className="order__value">${price}</div>
              </div>
            )
          })}
          <div className="order__row -total">
            <div className="order__label">Cart Total</div>
            <div className="order__value">${cartTotal}</div>
          </div>
        </>
      )}
    </div>
  )
}

Cart.displayName = 'Cart'
Cart.propTypes = {
  cart: propTypes.array,
  editItem: propTypes.func,
  removeItem: propTypes.func
}

class CustomInput extends Component {
  static propTypes = {
    value: propTypes.string,
    onClick: propTypes.func
  }
  render() {
    const { value, onClick } = this.props
    return (
      <button className="btn-link" onClick={onClick}>
        {value}
      </button>
    )
  }
}

const DatePickerButton = ({
  requestedDate,
  interval = 15,
  weekdayTimes = {},
  excludedTimes = {},
  holidays = [],
  handler
}) => {
  const [startDate, setStartDate] = useState(requestedDate)
  const { excludeTimes, isClosed } = makeDatepickerArgs(
    startDate,
    weekdayTimes,
    excludedTimes
  )
  return (
    <DatePicker
      showPopperArrow={false}
      showTimeSelect
      timeCaption="Time"
      timeFormat="h:mm aa"
      dateFormat="yyyy-MM-dd h:mm aa"
      timeIntervals={interval}
      excludeDates={holidays}
      excludeTimes={excludeTimes}
      filterDate={isClosed}
      selected={startDate}
      popperPlacement="bottom-end"
      onChange={date => setStartDate(date)}
      onCalendarClose={() => handler(startDate)}
      customInput={<CustomInput />}
    />
  )
}

DatePickerButton.displayName = 'DatePickerButton'
DatePickerButton.propTypes = {
  requestedDate: propTypes.instanceOf(Date),
  interval: propTypes.number,
  weekdayTimes: propTypes.object,
  excludedTimes: propTypes.object,
  holidays: propTypes.array,
  handler: propTypes.func
}

const OrderAlerts = ({ alerts, handler }) => {
  return (
    <div className="order__alerts">
      <TransitionGroup component="ul">
        {alerts.map((alert, index) => (
          <CSSTransition
            timeout={250}
            classNames="slide"
            key={`${alert.substring(0, 5)}-${index}`}
          >
            <li>
              {alert}
              <button
                className="btn-link order__alerts__remove"
                onClick={evt => handler(evt, index)}
              >
                <X size={16} />
              </button>
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

OrderAlerts.displayName = 'OrderAlerts'
OrderAlerts.propTypes = {
  alerts: propTypes.array,
  handler: propTypes.func
}

class OrderSidebar extends Component {
  static propTypes = {
    // state.order
    loading: propTypes.bool,
    orderId: propTypes.number,
    cart: propTypes.array,
    customer: propTypes.object,
    address: propTypes.object,
    inZone: propTypes.bool,
    orderType: propTypes.string,
    revenueCenter: propTypes.object,
    serviceType: propTypes.string,
    requestedAt: propTypes.string,
    weekdayTimes: propTypes.object,
    excludedTimes: propTypes.object,
    holidays: propTypes.array,
    orderTimes: propTypes.object,
    interval: propTypes.number,
    timezone: propTypes.string,
    order: propTypes.object,
    alerts: propTypes.array,
    cartOpen: propTypes.bool,
    // mapdispatchtoprops
    editItemInCart: propTypes.func,
    removeItemFromCart: propTypes.func,
    submitOrder: propTypes.func,
    removeOrder: propTypes.func,
    clearOrder: propTypes.func,
    editCustomer: propTypes.func,
    editAddress: propTypes.func,
    changeOrderType: propTypes.func,
    changeRevenueCenter: propTypes.func,
    updateServiceType: propTypes.func,
    updateRequestedAt: propTypes.func,
    removeOrderAlert: propTypes.func,
    toggleCart: propTypes.func
  }

  handleOrderType = evt => {
    evt.preventDefault()
    this.props.changeOrderType()
    evt.target.blur()
  }

  handleRevenuCenter = evt => {
    evt.preventDefault()
    this.props.changeRevenueCenter()
    evt.target.blur()
  }

  handleServiceType = evt => {
    this.props.updateServiceType(evt.target.value)
    evt.target.blur()
  }

  handleRequestedAt = date => {
    const localISOString = dateToIso(date, this.props.timezone)
    this.props.updateRequestedAt(localISOString)
  }

  handleCustomer = evt => {
    evt.preventDefault()
    this.props.editCustomer()
    evt.target.blur()
  }

  handleAddress = evt => {
    evt.preventDefault()
    this.props.editAddress()
    evt.target.blur()
  }

  handleEditItem = (evt, index) => {
    evt.preventDefault()
    this.props.editItemInCart(index)
    evt.target.blur()
  }

  handleRemoveItem = (evt, index) => {
    evt.preventDefault()
    this.props.removeItemFromCart(index)
    evt.target.blur()
  }

  handleCheckout = evt => {
    evt.preventDefault()
    this.props.submitOrder()
    evt.target.blur()
  }

  handleBack = evt => {
    window.scroll(0, 0)
    evt.preventDefault()
    this.props.removeOrder()
    evt.target.blur()
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearOrder()
    evt.target.blur()
  }

  handleRemoveAlert = (evt, index) => {
    evt.preventDefault()
    this.props.removeOrderAlert(index)
    evt.target.blur()
  }

  handleClose = evt => {
    evt.preventDefault()
    this.props.toggleCart()
    evt.target.blur()
  }

  render() {
    const {
      orderId,
      cart,
      customer,
      address,
      inZone,
      orderType,
      revenueCenter,
      serviceType,
      requestedAt,
      interval,
      weekdayTimes,
      excludedTimes,
      holidays,
      orderTimes,
      timezone,
      order,
      alerts,
      cartOpen
    } = this.props
    // console.log(requestedAt, timezone)
    const requestedDate = requestedAt
      ? isoToZonedDate(requestedAt, timezone)
      : null
    const outsideZone = revenueCenter && serviceType === 'DELIVERY' && !inZone
    return (
      <div className={`order-sidebar ${cartOpen ? '-open' : ''}`}>
        <div className="order__container">
          <div className="order__close">
            <button className="btn-link" onClick={this.handleClose}>
              <span>
                <X size={null} />
              </span>{' '}
              <span>Close</span>
            </button>
          </div>
          <div className="order__submit">
            {order ? (
              <button className="btn" onClick={this.handleBack}>
                Back To Menu
              </button>
            ) : (
              <button
                className="btn"
                onClick={this.handleCheckout}
                disabled={!cart.length || !customer}
              >
                Proceed To Checkout
              </button>
            )}
            <button className="btn btn--delete" onClick={this.handleClear}>
              {orderId ? 'Cancel Changes' : 'Cancel & Start Over'}
            </button>
          </div>
          <div className="order__section">
            <div className="order__row">
              <div className="order__label">Order Type</div>
              <div className="order__value">
                {!cart.length ? (
                  <button className="btn-link" onClick={this.handleOrderType}>
                    {orderTypeRevenueCenterMap[orderType] ||
                      'choose order type'}
                  </button>
                ) : (
                  orderTypeRevenueCenterMap[orderType]
                )}
              </div>
            </div>
            <div className="order__row">
              <div className="order__label">Revenue Center</div>
              <div className="order__value" />
              {orderType ? (
                <button className="btn-link" onClick={this.handleRevenuCenter}>
                  {revenueCenter ? revenueCenter.name : 'choose revenue center'}
                </button>
              ) : (
                'requires an order type'
              )}
            </div>
            <ServiceType value={serviceType} handler={this.handleServiceType} />
            <div className="order__row">
              <div className="order__label">Requested At</div>
              <div className="order__value">
                {requestedDate ? (
                  orderTimes ? (
                    requestedAt
                  ) : (
                    <DatePickerButton
                      key={requestedAt}
                      requestedDate={requestedDate}
                      interval={interval}
                      weekdayTimes={weekdayTimes}
                      excludedTimes={excludedTimes}
                      holidays={holidays}
                      handler={this.handleRequestedAt}
                    />
                  )
                ) : (
                  'requires an order type'
                )}
              </div>
            </div>
          </div>
          <Cart
            cart={cart}
            editItem={this.handleEditItem}
            removeItem={this.handleRemoveItem}
          />
          <OrderSection
            rows={makeCustomerSection(customer, this.handleCustomer)}
          />
          <OrderSection
            rows={makeAddressSection(
              customer,
              address,
              outsideZone,
              this.handleAddress
            )}
          />
          <OrderAlerts alerts={alerts} handler={this.handleRemoveAlert} />
        </div>
      </div>
    )
  }
}

OrderSidebar.displayName = 'OrderSidebar'

export default connect(
  state => ({
    ...state.order
  }),
  {
    editItemInCart,
    removeItemFromCart,
    submitOrder,
    removeOrder,
    clearOrder,
    editCustomer,
    editAddress,
    changeOrderType,
    changeRevenueCenter,
    updateServiceType,
    updateRequestedAt,
    removeOrderAlert,
    toggleCart
  }
)(OrderSidebar)
