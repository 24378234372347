import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import Table from '../Table'

const makeFields = tenderFields => {
  return [
    {
      name: 'Store',
      field: ['store']
    },
    ...tenderFields,
    {
      name: '3rd Party',
      field: ['portal'],
      format: 'dollars'
    },
    {
      name: 'Tender Total',
      field: ['tenderTotal'],
      format: 'button',
      settings: {
        type: 'drilldown',
        field: ['tenderTotal'],
        format: 'dollars',
        endpoint: '/reporting/tenders'
      }
    },
    {
      name: 'Order Total',
      field: ['total'],
      format: 'button',
      settings: {
        type: 'drilldown',
        field: ['total'],
        format: 'dollars',
        endpoint: '/reporting/receipts'
      }
    },
    {
      name: 'Difference',
      field: ['diff'],
      format: 'button',
      settings: {
        type: 'drilldown',
        field: ['diff'],
        format: 'dollars',
        endpoint: '/reporting/tenders-diff'
      }
    }
  ]
}

const makeTotals = (fields, data) => {
  if (!data || !data.length) return null
  const fieldsFlat = fields.map(i => {
    return i.format === 'button' ? { ...i, ...i.settings } : { ...i }
  })
  const totals = fieldsFlat
    .filter(i => i.format === 'dollars')
    .reduce((obj, field) => {
      const key = field.field[0]
      const total = data.reduce((t, i) => {
        t += parseFloat(i[key])
        return t
      }, 0.0)
      return { ...obj, [key]: total }
    }, {})
  return { store: 'Totals', ...totals }
}

const makeTenderFields = tenders => {
  return tenders
    .filter(i => i.category === 'primary' && i.tender_type_id !== null)
    .map(t => {
      return {
        name: t.name,
        field: [`tender${t.tender_type_id}`],
        format: 'dollars'
      }
    })
}

const makeSummary = data => {
  if (!data || !data.length) return []
  const values = data.reduce((arr, i) => {
    const item = {
      store: i.store,
      storeId: i.store_id,
      total: i.total,
      refund: i.refund,
      portal: 0.0
    }
    i.tenders.map(t => {
      if (t.tender_type_id === null) {
        item.tenderTotal = t.amount
      } else if (t.category === 'secondary') {
        item.portal += parseFloat(t.amount)
      } else {
        item[`tender${t.tender_type_id}`] = t.amount
      }
    })
    const diff = parseFloat(i.total) - parseFloat(item.tenderTotal)
    arr.push({ ...item, diff })
    return arr
  }, [])
  return values.filter(i => !isNaN(i.diff))
}

const TenderSummary = ({ data, handlers }) => {
  // const [showData, setShowData] = useState(true)
  const showData = true

  // const toggleDisplay = evt => {
  //   evt.preventDefault()
  //   setShowData(!showData)
  //   evt.target.blur()
  // }

  const tenderFields =
    data && data.length ? makeTenderFields(data[0].tenders) : []
  const fields = makeFields(tenderFields)
  const tableData = makeSummary(data)
  const totals = makeTotals(fields, tableData)
  const allData = [...tableData, totals]
  if (!tableData.length)
    return (
      <div className="report__table">
        <p className="alert">
          No tender data for the selected time period. Please try adjusting your
          filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={tableData} {...handlers} />
          </div>
          <div className="report__actions__right">
            {/* <ToggleDisplay showData={showData} handler={toggleDisplay} /> */}
            <ButtonCSV endpoint="/reporting/tender-totals/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="report__table">
              <Table
                fields={fields}
                items={allData}
                classes="table--tender-summary"
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

TenderSummary.displayName = 'TenderSummary'
TenderSummary.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default TenderSummary
