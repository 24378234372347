import propTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  updateInput,
  submitForm,
  toggleReset,
  sendResetEmail,
  resetForm
} from '../reducers/auth'
import { FormInput } from '../components/FormInputs'
import { chooseBrand } from '../reducers/brand'
import { logoutUser } from '../reducers/user'
import logo from '../assets/open-tender-logo-white_1280x172.png'
import backgroundImage from '../assets/open-tender-landing.jpg'

const fields = [
  { label: 'Email', field: 'email', type: 'email' },
  { label: 'Password', field: 'password', type: 'password' }
]

const fieldsReset = [
  { label: 'Email', id: 'reset-email', field: 'email', type: 'email' }
]

export const LandingImage = () => {
  return (
    <div className="landing__image">
      {/* <div className="landing__overlay" /> */}
      <div className="landing__container">
        <div className="landing__image__content">
          <img className="landing__logo" src={logo} alt="logo" />
          <div className="landing__message">
            <p>an open platform for restaurant innovation</p>
          </div>
        </div>
      </div>
    </div>
  )
}

LandingImage.displayName = 'LandingImage'

export const FormError = ({ error }) => {
  return (
    <TransitionGroup>
      {error ? (
        <CSSTransition
          key="error"
          classNames="fade"
          timeout={{ enter: 250, exit: 0 }}
        >
          <div className="error error--top">
            <p>{error}</p>
          </div>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

FormError.displayName = 'FormError'
FormError.propTypes = {
  error: propTypes.string
}

class Auth extends Component {
  constructor(props) {
    super(props)
    this.submitLogin = React.createRef()
    this.submitReset = React.createRef()
  }

  static propTypes = {
    data: propTypes.object,
    errors: propTypes.object,
    brands: propTypes.array,
    reset: propTypes.bool,
    resetSent: propTypes.bool,
    submitting: propTypes.bool,
    user: propTypes.object,
    brand: propTypes.object,
    updateInput: propTypes.func,
    submitForm: propTypes.func,
    chooseBrand: propTypes.func,
    toggleReset: propTypes.func,
    sendResetEmail: propTypes.func,
    resetForm: propTypes.func,
    logoutUser: propTypes.func
  }

  componentDidMount() {
    this.props.resetForm()
    if (this.props.user) this.props.logoutUser()
  }

  handleInputChange = evt => {
    const { id, value } = evt.target
    const field = id === 'password' ? id : 'email'
    this.props.updateInput(field, value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    const data = {}
    fields.map(f => {
      let val = this.props.data[f.field]
      if (typeof val !== 'undefined') {
        data[f.field] = val
      }
    })
    this.props.submitForm(data)
    this.submitLogin.current.blur()
    window.scrollTo(0, 0)
  }

  handleReset = evt => {
    evt.preventDefault()
    const email = this.props.data.email || ''
    this.props.sendResetEmail(email)
    if (this.submitReset.current) this.submitReset.current.blur()
    window.scrollTo(0, 0)
  }

  handleToggle = evt => {
    evt.preventDefault()
    this.props.toggleReset()
    evt.target.blur()
  }

  handleResetToggle = evt => {
    evt.preventDefault()
    this.props.resetForm()
    evt.target.blur()
  }

  submitBrand = brand => {
    this.props.chooseBrand(brand)
  }

  render() {
    document.title = 'Open Tender Admin Portal'
    const { reset, resetSent, submitting } = this.props
    const fileUrl = backgroundImage
    return (
      <div>
        {this.props.user && this.props.brand ? (
          <Redirect to="/" />
        ) : (
          <div
            className="landing"
            style={{
              backgroundImage: `url(${fileUrl})`,
              backgroundPosition: `center center`
            }}
          >
            <div className="landing__overlay"></div>
            <LandingImage />
            <div className="landing__content">
              {this.props.brands.length ? (
                <div className="landing__container--brands">
                  <div className="landing__form">
                    <div className="landing__header">
                      <p>Please choose a brand.</p>
                    </div>
                    <div className="landing__brands">
                      {this.props.brands.map(brand => (
                        <button
                          key={brand.brand_id}
                          className="btn"
                          onClick={() => this.submitBrand(brand)}
                        >
                          <span>
                            {brand.full_name} <span>{brand.brand_id}</span>
                          </span>
                          <span>{brand.domain_name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="landing__container">
                  <div className="landing__form">
                    <div
                      className={`landing__form__login ${reset ? '-hide' : ''}`}
                    >
                      <div className="landing__header">
                        <p>Please log in to get started.</p>
                      </div>
                      <FormError error={this.props.errors.form} />
                      <form id="form-login" onSubmit={this.handleSubmit}>
                        {fields.map(field => {
                          const value = this.props.data[field.field] || ''
                          return FormInput(
                            field,
                            value,
                            null,
                            this.handleInputChange
                          )
                        })}
                        <input
                          type="submit"
                          value={submitting ? 'Submitting...' : 'Submit'}
                          ref={this.submitLogin}
                          // disabled={submitting}
                        />
                        <div className="landing__form__footer">
                          <p>
                            <button
                              className="btn-link"
                              onClick={this.handleToggle}
                            >
                              Forget your password?
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                    <div
                      className={`landing__form__reset ${reset ? '-show' : ''}`}
                    >
                      <div className="landing__header">
                        <p>Enter your email address to reset your password.</p>
                      </div>
                      <FormError error={this.props.errors.form} />
                      <form id="form-reset" onSubmit={this.handleReset}>
                        {fieldsReset.map(field => {
                          const value = this.props.data[field.field] || ''
                          return FormInput(
                            field,
                            value,
                            null,
                            this.handleInputChange
                          )
                        })}
                        {!resetSent && (
                          <input
                            type="submit"
                            value={submitting ? 'Submitting...' : 'Submit'}
                            ref={this.submitReset}
                            disabled={submitting}
                          />
                        )}
                      </form>
                      {!resetSent ? (
                        <div className="landing__form__footer">
                          <p>
                            <button
                              className="btn-link"
                              onClick={this.handleToggle}
                            >
                              Nevermind, I remembered it.
                            </button>
                          </p>
                        </div>
                      ) : (
                        <div className="landing__form__footer">
                          <p className="landing__success">
                            Thanks! Please check your inbox.
                          </p>
                          <p>
                            <button
                              className="btn-link"
                              onClick={this.handleResetToggle}
                            >
                              Head back to the login form.
                            </button>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Auth.displayName = 'Auth'

export default connect(
  state => ({
    ...state.auth,
    user: state.user,
    brand: state.brand
  }),
  {
    updateInput,
    submitForm,
    chooseBrand,
    toggleReset,
    sendResetEmail,
    resetForm,
    logoutUser
  }
)(Auth)
