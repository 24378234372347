import propTypes from 'prop-types'
import React, { Component } from 'react'
import { makeReceipt } from './Receipt'
import { ReceiptSection, ReceiptItems } from './ReceiptSection'

const Customer = ({ customer }) => (
  <ReceiptSection rows={customer} classes="-compact" />
)

Customer.displayName = 'Customer'
Customer.propTypes = {
  customer: propTypes.array
}

const Address = ({ address }) => (
  <ReceiptSection rows={address} classes="-compact" />
)

Address.displayName = 'Address'
Address.propTypes = {
  address: propTypes.array
}

const Totals = ({ totals }) => (
  <ReceiptSection rows={totals} classes="print-order__totals -compact" />
)

Totals.displayName = 'Totals'
Totals.propTypes = {
  totals: propTypes.array
}

const Details = ({ details }) => (
  <ReceiptSection
    rows={details}
    classes="print-order__details__details -compact"
  />
)

Details.displayName = 'Details'
Details.propTypes = {
  details: propTypes.array
}

const Notes = ({ notes }) => (
  <ReceiptSection rows={notes} classes="print-order__details -compact" />
)

Notes.displayName = 'Notes'
Notes.propTypes = {
  notes: propTypes.array
}

const Shipment = ({ shipment }) => {
  const rows = [
    { label: 'Shipping Details', value: ' ' },
    { label: 'Carrier', value: shipment.carrier },
    {
      label: 'Shipping Label',
      value: 'click here to print',
      path: shipment.label_url,
      newWindow: true
    }
  ]
  return <ReceiptSection rows={rows} classes="print-order__details -compact" />
}

Shipment.displayName = 'Shipment'
Shipment.propTypes = {
  shipment: propTypes.array
}

const Items = ({ order, groupMods }) => (
  <ReceiptItems
    order={order}
    printOut={true}
    groupMods={groupMods}
    classes="print-order__details__items"
  />
)

Items.displayName = 'Items'
Items.propTypes = {
  order: propTypes.object,
  groupMods: propTypes.bool
}

const Signature = () => (
  <div className="print-order__section -signature -print-only">
    <div className="print-order__underline">Additional Tip</div>
    <div className="print-order__underline">Print Name</div>
    <div className="print-order__underline">Signature</div>
  </div>
)

Signature.displayName = 'Signature'

const PackedBy = () => (
  <div className="print-order__section -packing -print-only">
    <div className="print-order__underline">Packed By</div>
    <div className="print-order__underline">Date</div>
  </div>
)

PackedBy.displayName = 'PackedBy'

const makeFulfillment = ff => {
  if (!ff) return null
  const arrival = ff.arrival_info ? ` @ ${ff.arrival_info}` : ''
  return `${ff.vehicle_type || ''} ${ff.vehicle_color || ''} ${ff.vehicle_id ||
    ''}${arrival}`
}

class PrintOrder extends Component {
  static propTypes = {
    order: propTypes.object,
    printerType: propTypes.string,
    groupMods: propTypes.bool
  }
  render() {
    const { order, printerType, groupMods } = this.props
    const orderType =
      order.order_type === 'Main Menu' ? 'OLO' : order.order_type
    const isCatering = orderType.toUpperCase() === 'CATERING'
    const isMerch = orderType.toUpperCase() === 'MERCH'
    const isDelivery = order.service_type_name.toLowerCase() === 'delivery'
    let { customer, address, totals } = makeReceipt(order)
    customer = [{ label: 'Order Placed By', value: ' ' }, ...customer.slice(1)]
    address = [
      { label: 'Delivery Address & Contact Info', value: ' ' },
      ...address.slice(1)
    ]
    let pickupMsg = [{ label: 'Pickup order - address not needed', value: ' ' }]
    const fulfillment = makeFulfillment(order.order_fulfillment)
    if (fulfillment) {
      pickupMsg = [...pickupMsg, { label: 'Curbside', value: fulfillment }]
    }
    const addr = isDelivery ? address : pickupMsg
    const tenderTypes = order.tenders.length
      ? order.tenders.map(i => i.tender_type).join(', ')
      : 'none'
    totals = [...totals, { label: 'Payment Type(s)', value: tenderTypes }]
    // const notes = [n[0], n[1], n[3], n[2], n[4], n[5], n[6]]
    const p = order.order_properties
    let details = p
      ? [
          { label: 'Order Details', value: ' ' },
          {
            label: 'Eating Utensils',
            value: p.eating_utensils ? 'Yes' : 'No'
          },
          {
            label: 'Group Order',
            value: p.is_group ? 'Yes' : 'No'
          },
          {
            label: 'Serving Utensils',
            value: p.serving_utensils ? 'Yes' : 'No'
          },
          { label: 'No. of People', value: p.person_count }
        ]
      : null
    if (details && p.dispatched) {
      const deliveryDetails = [
        {
          label: 'Delivered By',
          value: p.delivery_service
        },
        {
          label: 'Delivery ID',
          value: p.delivery_id,
          path: p.delivery_url,
          newWindow: true
        }
      ]
      details = details.concat(deliveryDetails)
    }
    const customerNotes = p
      ? [
          { label: 'Customer Notes', value: ' ' },
          {
            label: '',
            value: p.notes || 'none specified',
            classes: '-full-width'
          }
        ]
      : null
    const internalNotes = p
      ? [
          { label: 'Internal Notes', value: ' ' },
          {
            label: '',
            value: p.notes_internal || 'none specified',
            classes: '-full-width'
          }
        ]
      : null
    const desktopClass = printerType === 'RECEIPT' ? '-print-hide' : ''
    const receiptClass = printerType === 'RECEIPT' ? '-print-show' : ''
    return (
      <div className={`print-order -${printerType.toLowerCase()}`}>
        <div className="print-order__header">
          <div className="print-order__header__left">
            <p>{order.location.name}</p>
            <h2>
              <span>
                {orderType} Order #{order.receipt_id}
              </span>{' '}
              <span>--</span> <span>{order.service_type_name}</span>{' '}
              <span>--</span> <span>{order.requested_dt_str}</span>
            </h2>
          </div>
        </div>
        <div className={`print-order__details -desktop ${desktopClass}`}>
          <div className="print-order__details__left">
            <Customer customer={customer} />
            <Address address={addr} />
            <Totals totals={totals} />
            {isCatering && <Signature />}
          </div>
          <div className="print-order__details__right">
            {isMerch && order.shipment && (
              <Shipment shipment={order.shipment} />
            )}
            <Details details={details} />
            <Notes notes={customerNotes} />
            <Notes notes={internalNotes} />
            {isCatering && <PackedBy />}
            <Items order={order} groupMods={groupMods} />
          </div>
        </div>
        <div className={`print-order__details -receipt ${receiptClass}`}>
          <div className="print-order__details__left">
            <Customer customer={customer} />
            <Items order={order} groupMods={groupMods} />
            <Details details={details} />
            <Notes notes={customerNotes} />
            <Notes notes={internalNotes} />
            {isCatering && <PackedBy />}
            <Address address={addr} />
            <Totals totals={totals} />
            {isCatering && <Signature />}
          </div>
        </div>
      </div>
    )
  }
}

PrintOrder.displayName = 'PrintOrder'

export default PrintOrder

// const statusClass = `-${order.receipt_status.toLowerCase()}`
// const summary = [
//   { label: 'Order Summary', value: ' ' },
//   {
//     label: 'Revenue Center',
//     value: order.location.name,
//     path: '/revenue-centers/:id',
//     id: order.location_id
//   },
//   { label: 'Order Type', value: order.order_type },
//   { label: 'Service Type', value: order.service_type_name },
//   { label: 'Requested At', value: order.requested_dt_str },
//   {
//     label: 'Order Status',
//     value: order.receipt_status.toUpperCase(),
//     classes: statusClass
//   },
//   { label: 'Payment Status', value: order.tender_status },
//   { label: 'Payment Type(s)', value: tenderTypes }
// ]
