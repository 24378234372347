import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { NavLink } from 'react-router-dom'
import { updateBusinessDate, importOrders } from '../reducers/mapping'
import { dateStrToLocalDate } from '../lib/helpersDatetime'
import DatePicker from 'react-datepicker'
import MappingRevenueCenters from './MappingRevenueCenters'

export const DateFilter = ({ businessDate, handler }) => {
  const value = businessDate ? dateStrToLocalDate(businessDate) : new Date()
  return (
    <label htmlFor="datepicker" className="-half">
      <DatePicker
        showPopperArrow={false}
        dateFormat="yyyy-MM-dd"
        placeholderText="choose date"
        // isClearable={true}
        selected={value}
        onChange={date => handler(date)}
      />
    </label>
  )
}

DateFilter.displayName = 'DateFilter'
DateFilter.propTypes = {
  businessDate: propTypes.string,
  handler: propTypes.func
}

class Import extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
    this.reviewButton = React.createRef()
  }

  static propTypes = {
    loading: propTypes.bool,
    submitting: propTypes.bool,
    revenueCenterId: propTypes.number,
    businessDate: propTypes.string,
    importResults: propTypes.object,
    importErrors: propTypes.array,
    updateBusinessDate: propTypes.func,
    importOrders: propTypes.func,
    retrieveErrors: propTypes.func
  }

  handleDatepicker = date => {
    const businessDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
    this.props.updateBusinessDate(businessDate)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.importOrders()
    evt.target.blur()
  }

  render() {
    const {
      businessDate,
      submitting,
      loading,
      revenueCenterId,
      importResults: results
    } = this.props
    const submitDisabled = submitting || loading || !revenueCenterId
    return (
      <div className="mapping">
        <MappingRevenueCenters />
        <div className="mapping__mappings">
          <DateFilter
            businessDate={businessDate}
            handler={this.handleDatepicker}
          />
          <div className="mapping__import">
            <div className="mapping__import__header">
              <h2>Import Orders from Brightloom</h2>
              <p>
                Import orders for this revenue center for the selected business
                date
              </p>
              {!revenueCenterId && (
                <p className="err">
                  Please choose a revenue center before importing.
                </p>
              )}
            </div>
            <form
              id="form-import"
              className="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form__submit">
                <input
                  className="btn"
                  type="submit"
                  value={submitting ? 'Importing...' : 'Import Orders'}
                  disabled={submitDisabled}
                  ref={this.submitButton}
                />
              </div>
            </form>
            {results && (
              <div className="mapping__import__results">
                <p className={results.errors ? 'err' : 'success'}>
                  {results.retrieved} orders retrieved, {results.imported}{' '}
                  imported with {results.errors} errors.
                </p>
                <NavLink to="/brightloom/import-errors">
                  Please click here to review these errors.
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Import.displayName = 'Import'

export default connect(
  state => ({
    ...state.mapping,
    ...state.page.mapping
  }),
  { updateBusinessDate, importOrders }
)(Import)
