import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select, Checkbox } from './FormInputs'
import { revenueCenters as revenueCenterTypes } from '../lib/constants'
import {
  updateRevenueCenterType,
  selectRevenueCenter,
  updateMenuMappings
} from '../reducers/mapping'
import ClipLoader from 'react-spinners/ClipLoader'
import { isEmpty } from '../lib/utils'
import { slugify, capitalize } from '../lib/helpers'
import { BackToTop } from './BackToTop'

const fields = {
  revenueCenterType: {
    label: 'Revenue Center Type',
    field: 'revenueCenterType',
    type: 'select'
  }
}

const MissingMappingsGroup = ({ missingItems, isOptions }) => {
  return isEmpty(missingItems) ? null : (
    <>
      {isOptions ? (
        <h4>Options not currently mapped (by option group)</h4>
      ) : (
        <h4>Menu Items not currently mapped (by category)</h4>
      )}
      {Object.entries(missingItems).map(([category, items]) => (
        <div key={category} className="mapping__missing__group">
          <p>{category}</p>
          <ul>
            {items.map(item => (
              <li key={item.id}>
                {item.name} ({item.id})
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}

MissingMappingsGroup.displayName = 'MissingMappingsGroup'
MissingMappingsGroup.propTypes = {
  missingItems: propTypes.object,
  isOptions: propTypes.bool
}

const MissingMappings = ({ missing }) => {
  return missing.map(i => (
    <div
      key={i.revenueCenter}
      id={slugify(i.revenueCenter)}
      className="mapping__missing__rc"
    >
      <h3>{i.revenueCenter}</h3>
      <MissingMappingsGroup missingItems={i.items} />
      <MissingMappingsGroup missingItems={i.options} isOptions={true} />
    </div>
  ))
}

MissingMappings.displayName = 'MissingMappings'
MissingMappings.propTypes = {
  missing: propTypes.array
}

class MenuExtUpdate extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    loading: propTypes.bool,
    submitting: propTypes.bool,
    revenueCenterType: propTypes.string,
    revenueCenters: propTypes.array,
    toUpdate: propTypes.array,
    missing: propTypes.array,
    pos: propTypes.string,
    updateRevenueCenterType: propTypes.func,
    selectRevenueCenter: propTypes.func,
    updateMenuMappings: propTypes.func
  }

  handleRevenueCenterType = evt => {
    const { value } = evt.target
    this.props.updateRevenueCenterType(value)
  }

  handleUpdate = evt => {
    const revenueCenterId = parseInt(evt.target.id.split('-')[1])
    this.props.selectRevenueCenter(revenueCenterId)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateMenuMappings()
    evt.target.blur()
  }

  render() {
    const {
      loading,
      submitting,
      revenueCenterType,
      revenueCenters,
      toUpdate = [],
      missing,
      pos
    } = this.props
    const missingNames = missing.map(i => i.revenueCenter)
    return (
      <div className="mapping">
        <BackToTop />
        <div className="mapping__revenue-center">
          <Select
            field={fields.revenueCenterType}
            value={revenueCenterType}
            options={[...revenueCenterTypes].slice(1)}
            handler={this.handleRevenueCenterType}
          />
        </div>
        {loading ? (
          <div className="mapping__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        ) : revenueCenters.length ? (
          <div className="mapping__mappings">
            {pos.includes('TOAST', 'SALIDO') ? (
              <div className="mapping__mappings__header">
                <h2>Update your Open Tender menus from {capitalize(pos)}</h2>
                <p>Choose which revenue centers {"you'd"} like to update</p>
              </div>
            ) : pos.includes('REVEL', 'BRIGHTLOOM') ? (
              <div className="mapping__mappings__header">
                <h2>
                  Update your mappings between Open Tender and {capitalize(pos)}
                </h2>
                <p>Choose which revenue centers {"you'd"} like to update</p>
              </div>
            ) : null}
            <div className="mapping__revenue-centers">
              <form id="form" className="form" onSubmit={this.handleSubmit}>
                {revenueCenters.map(i => {
                  const checked = toUpdate.includes(i.id)
                  const field = {
                    ...i,
                    label: (
                      <span>
                        {i.label} ({i.menus}){' '}
                        {missingNames.includes(i.label) && (
                          <span className="mapping__missing__link">
                            &mdash;{' '}
                            <a href={`#${slugify(i.label)}`}>
                              missing mappings
                            </a>
                          </span>
                        )}
                      </span>
                    )
                  }
                  return (
                    <Checkbox
                      key={i.field}
                      field={field}
                      value={checked}
                      handler={this.handleUpdate}
                    />
                  )
                })}
                <div className="form__submit">
                  <input
                    className="btn"
                    type="submit"
                    value={submitting ? 'Working...' : 'Update Menus'}
                    disabled={submitting}
                    ref={this.submitButton}
                  />
                </div>
              </form>
            </div>
            <div className="mapping__missing">
              {missing.length ? <MissingMappings missing={missing} /> : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

MenuExtUpdate.displayName = 'MenuExtUpdate'

export default connect(
  state => ({
    ...state.mapping,
    pos: state.page.menuExtUpdate.pos
  }),
  { updateRevenueCenterType, selectRevenueCenter, updateMenuMappings }
)(MenuExtUpdate)
