import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  resetZone,
  updateCoords,
  upsertZone,
  showZoneErrors
} from '../reducers/deliveryZone'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import Form from './Form'
import GoogleMap from './GoogleMap'

class DeliveryZone extends Component {
  static propTypes = {
    // state.deliveyZone
    loading: propTypes.bool,
    address: propTypes.string,
    delivery_zone_id: propTypes.number,
    coordinates: propTypes.array,
    description: propTypes.string,
    priority: propTypes.number,
    errors: propTypes.object,
    // state.page.deliveryZone
    fields: propTypes.array,
    // mapdispatchtoprops
    resetZone: propTypes.func,
    updateCoords: propTypes.func,
    upsertZone: propTypes.func,
    showZoneErrors: propTypes.func
  }

  render() {
    const { address, coordinates, description, priority, errors } = this.props
    const data = { description, priority }
    const isNew = !description ? true : false
    let formErrors = errors ? { ...errors } : {}
    delete formErrors.form
    return (
      <div className="content__main">
        {this.props.loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!this.props.loading && address ? (
            <CSSTransition {...cssTransitionFade}>
              <>
                <GoogleMap
                  // lat={lat}
                  // lng={lng}
                  address={address}
                  coordinates={coordinates}
                  resetZone={this.props.resetZone}
                  updateCoords={this.props.updateCoords}
                  showError={this.props.showZoneErrors}
                  error={errors ? errors.coordinates : ''}
                />
                <Form
                  isNew={isNew}
                  fields={this.props.fields}
                  data={data}
                  errors={formErrors}
                  upsertItem={this.props.upsertZone}
                  showErrors={this.props.showZoneErrors}
                />
              </>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

DeliveryZone.displayName = 'DeliveryZone'

export default connect(
  state => ({
    ...state.deliveryZone,
    ...state.page.deliveryZone
  }),
  { resetZone, updateCoords, upsertZone, showZoneErrors }
)(DeliveryZone)
