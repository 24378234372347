import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import PrintOrder from './PrintOrder'
import { clearPrint } from '../reducers/orderMgmt'

// https://github.com/gregnb/react-to-print/blob/master/README.md

class PrintOrderWrapper extends Component {
  constructor(props) {
    super(props)
    this.printButton = React.createRef()
  }

  static propTypes = {
    order: propTypes.object,
    printerType: propTypes.string,
    clearPrint: propTypes.func,
    groupModsOlo: propTypes.bool,
    groupModsCatering: propTypes.bool
  }

  componentDidMount() {
    this.printButton.current.click()
  }

  render() {
    const { order, printerType, groupModsOlo, groupModsCatering } = this.props
    const groupMods = order.is_catering ? groupModsCatering : groupModsOlo
    return (
      <div style={{ display: 'none' }}>
        <ReactToPrint
          trigger={() => (
            <button className="btn" ref={this.printButton}>
              Print Order
            </button>
          )}
          content={() => this.componentRef}
          onAfterPrint={() => this.props.clearPrint()}
          // copyStyles={false}
        />
        <PrintOrder
          order={order}
          printerType={printerType}
          groupMods={groupMods}
          ref={el => (this.componentRef = el)}
        />
      </div>
    )
  }
}

PrintOrderWrapper.displayName = 'PrintOrderWrapper'

export default connect(
  state => ({
    groupModsOlo: state.brand.group_options,
    groupModsCatering: state.brand.group_options_catering
  }),
  { clearPrint }
)(PrintOrderWrapper)
