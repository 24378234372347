import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import Table from '../Table'

const makeFields = taxFields => {
  return [
    {
      name: 'Store',
      field: ['store']
    },
    ...taxFields,
    {
      name: 'Orders Tax',
      field: ['tax'],
      format: 'dollars'
    },
    {
      name: 'Difference',
      field: ['diff'],
      format: 'dollars'
    },
    {
      name: 'Net Sales',
      field: ['netSales'],
      format: 'dollars'
    }
  ]
}

const makeTotals = (fields, data) => {
  if (!data || !data.length) return null
  const totals = fields
    .filter(i => i.format === 'dollars')
    .reduce((obj, field) => {
      const key = field.field[0]
      const total = data.reduce((t, i) => {
        t += parseFloat(i[key])
        return t
      }, 0.0)
      return { ...obj, [key]: total }
    }, {})
  return { store: 'Totals', ...totals }
}

const makeTaxFields = taxes => {
  return taxes.map(t => {
    const key = `tax${t.tax_id === null ? 'Total' : t.tax_id}`
    return {
      name: t.name,
      field: [key],
      format: 'dollars'
    }
  })
}

const makeSummary = data => {
  if (!data || !data.length) return []
  const values = data.reduce((arr, i) => {
    const item = { store: i.store, tax: i.tax, netSales: i.net_sales }
    i.taxes.map(t => {
      const key = `tax${t.tax_id === null ? 'Total' : t.tax_id}`
      item[key] = t.amount
    })
    const diff = parseFloat(i.tax) - parseFloat(item.taxTotal)
    arr.push({ ...item, diff })
    return arr
  }, [])
  return values.filter(i => !isNaN(i.diff))
}

const TaxSummary = ({ data, handlers }) => {
  // const [showData, setShowData] = useState(true)
  const showData = true

  // const toggleDisplay = evt => {
  //   evt.preventDefault()
  //   setShowData(!showData)
  //   evt.target.blur()
  // }

  const taxFields = data && data.length ? makeTaxFields(data[0].taxes) : []
  const fields = makeFields(taxFields)
  const tableData = makeSummary(data)
  const totals = makeTotals(fields, tableData)
  const allData = [...tableData, totals]
  if (!tableData.length)
    return (
      <div className="report__table">
        <p className="alert">
          No tax data for the selected time period. Please try adjusting your
          filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={tableData} {...handlers} />
          </div>
          <div className="report__actions__right">
            {/* <ToggleDisplay showData={showData} handler={toggleDisplay} /> */}
            <ButtonCSV endpoint="/reporting/tax-totals/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="report__table">
              <Table
                fields={fields}
                items={allData}
                classes="table--tax-summary"
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

TaxSummary.displayName = 'Taxes'
TaxSummary.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default TaxSummary
