import propTypes from 'prop-types'
import React from 'react'
import TableCell from '../TableCell'

const Totals = ({ fields, totals, classes = '' }) => {
  const klass = `table--totals ${classes}`
  return totals ? (
    <div className="report__totals">
      <table className={klass}>
        <tbody>
          <tr>
            {fields.map(field => (
              <td key={field.field}>
                <TableCell item={totals} {...field} />
                {/* {totals[i.field]} */}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  ) : null
}

Totals.displayName = 'Totals'
Totals.propTypes = {
  fields: propTypes.array,
  totals: propTypes.object,
  classes: propTypes.string
}

export default Totals
