import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { addItem, editItem, confirmDeleteItem } from '../reducers/listOfItems'

const Results = ({ items, handleNew }) => {
  return (
    <div className="table__results">
      <p>Displaying {items.length} items.</p>
      <button className="btn" onClick={handleNew}>
        Add New
      </button>
    </div>
  )
}

Results.displayName = 'Results'
Results.propTypes = {
  items: propTypes.array,
  handleNew: propTypes.func
}

const Table = ({ loading, id, fields, items, editItem, deleteItem }) => {
  const handleEdit = (evt, index) => {
    evt.preventDefault()
    editItem(index)
    evt.target.blur()
  }

  const handleDelete = (evt, index) => {
    evt.preventDefault()
    deleteItem(index)
    evt.target.blur()
  }

  return (
    <div className="table__wrapper">
      <table>
        <thead>
          <tr>
            {fields.map(i => (
              <th key={i.label}>{i.label}</th>
            ))}
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {items.length ? (
            items.map((item, index) => {
              const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
              const key = `${id}-${item[id]}-${index}`
              return (
                <tr key={key} className={evenOdd}>
                  {fields.map(field => (
                    <td key={`${item[id]}-${field.label}`}>
                      {item[field.field]}
                    </td>
                  ))}
                  <td>
                    <button
                      className="btn-link"
                      onClick={evt => handleEdit(evt, index)}
                    >
                      edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn-link"
                      onClick={evt => handleDelete(evt, index)}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              )
            })
          ) : !loading ? (
            <tr className="empty-table">
              <td colSpan={fields.length}>There {"aren't"} any items yet.</td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {loading && (
        <div className="table__loading -relations">
          <ClipLoader size={24} color={'#5a5aff'} />
        </div>
      )}
    </div>
  )
}

Table.displayName = 'Table'
Table.propTypes = {
  loading: propTypes.bool,
  id: propTypes.string,
  fields: propTypes.array,
  items: propTypes.array,
  editItem: propTypes.func,
  deleteItem: propTypes.func
}

class ListOfItems extends Component {
  static propTypes = {
    // state.listOfItems
    loading: propTypes.bool,
    items: propTypes.array,
    // state.page.listOfItems
    id: propTypes.string,
    fields: propTypes.array,
    // listOfItems action creators
    addItem: propTypes.func,
    editItem: propTypes.func,
    confirmDeleteItem: propTypes.func
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.addItem()
    evt.target.blur()
  }

  render() {
    const { loading, items, fields, id, editItem, confirmDeleteItem } =
      this.props
    return (
      <div className="mapping">
        {loading ? (
          <div className="mapping__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        ) : (
          <div className="mapping__list">
            <Results items={items} handleNew={this.handleNew} />
            <Table
              loading={loading}
              id={id}
              fields={fields}
              items={items}
              editItem={editItem}
              deleteItem={confirmDeleteItem}
            />
          </div>
        )}
      </div>
    )
  }
}

ListOfItems.displayName = 'ListOfItems'

export default connect(
  state => ({
    ...state.listOfItems,
    ...state.page.listOfItems
  }),
  { addItem, editItem, confirmDeleteItem }
)(ListOfItems)
