const tabs = {
  employees: [
    { name: 'Settings', path: '' },
    { name: 'Departments', path: 'departments' }
  ],
  regions: [
    { name: 'Settings', path: '' },
    { name: 'Revenue Centers', path: 'revenue-centers' }
  ],
  stores: [
    { name: 'Settings', path: '' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'Images', path: 'files' },
    { name: 'Address', path: 'address' },
    { name: 'Gratuity', path: 'gratuity' },
    { name: 'Emails', path: 'email' },
    { name: 'Inventory', path: 'inventory' },
    { name: 'Order Throttling', path: 'throttle' },
    { name: 'Payment Processing', path: 'credit' },
    { name: 'Integrations', path: 'integrations' },
    { name: 'POS Integration', path: 'external-pos' }
  ],
  revenueCenters: [
    { name: 'Settings *', path: '' },
    { name: 'Menus *', path: 'menus' },
    { name: 'Tender Types *', path: 'tender-types' },
    { name: 'Delivery Zone *', path: 'delivery-zone' },
    { name: 'Dayparts *', path: 'dayparts' },
    { name: 'Hours *', path: 'hours' },
    { name: 'Order Windows *', path: 'order-times' },
    { name: 'Holidays', path: 'holidays' },
    { name: 'Blocked Hours', path: 'blocked-hours' },
    { name: 'Images', path: 'files' },
    { name: 'Address', path: 'address' },
    { name: 'Menu Preview', path: 'menu-preview' },
    { name: 'Surcharges', path: 'surcharges' },
    { name: 'Gratuity', path: 'gratuity' },
    { name: 'Taxes', path: 'taxes' },
    { name: 'Emails', path: 'email' },
    { name: 'Order Throttling', path: 'throttle' },
    { name: 'Prices', path: 'attributes' },
    { name: 'Payment Processing', path: 'credit' },
    { name: 'Delivery Integration', path: 'delivery' },
    { name: 'CRM Integration', path: 'crm' },
    {
      name: 'POS Integration',
      path: 'external-pos',
      disabled: { field: 'revenue_center_type', values: ['POS', 'MERCH'] }
    },
    {
      name: 'POS Settings',
      path: 'pos',
      disabled: {
        field: 'revenue_center_type',
        values: ['OLO', 'CATERING', 'MERCH']
      }
    },
    {
      name: 'POS Devices',
      path: 'devices',
      disabled: {
        field: 'revenue_center_type',
        values: ['OLO', 'CATERING', 'MERCH']
      }
    }
  ],
  menus: [
    { name: 'Settings', path: '' },
    { name: 'Categories', path: 'categories' }
  ],
  categories: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' },
    { name: 'Menus', path: 'menus' },
    { name: 'Dayparts', path: 'dayparts' },
    { name: 'Menu Items', path: 'items' },
    { name: 'Recommended Items', path: 'recommended-items' }
  ],
  items: [
    { name: 'Settings', path: '' },
    { name: 'Prices', path: 'attributes' },
    { name: 'Images', path: 'files' },
    { name: 'Taxes', path: 'taxes' },
    { name: 'Tags', path: 'tags' },
    { name: 'Allergens', path: 'allergens' },
    { name: 'Nutritional Info', path: 'nutritional-info' }
  ],
  modifierGroups: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' },
    { name: 'Menus', path: 'menus' },
    { name: 'Modifiers', path: 'items' }
  ],
  allergens: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' }
  ],
  tags: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' }
  ],
  customers: [
    { name: 'Settings', path: '' },
    { name: 'Addresses', path: 'addresses' },
    { name: 'Orders', path: 'orders' },
    { name: 'Loyalty Programs', path: 'loyalty-programs' },
    { name: 'Block / Unblock', path: 'block' }
  ],
  houseAccounts: [
    { name: 'Settings', path: '' },
    { name: 'Customers', path: 'customers' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'Orders', path: 'orders' },
    { name: 'Invoices', path: 'invoices' }
  ],
  loyaltyPrograms: [
    { name: 'Settings', path: '' },
    { name: 'Status Tiers', path: 'loyalty-tiers' },
    { name: 'Points Thresholds', path: 'loyalty-thresholds' },
    { name: 'Bonus Periods', path: 'loyalty-bonus' },
    { name: 'Participating Customers', path: 'customers' },
    { name: 'Approved Emails', path: 'emails' }
  ],
  discounts: [
    { name: 'Settings', path: '' },
    { name: 'Items', path: 'items' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'QR Codes', path: 'qrcodes' }
  ],
  promoCodes: [
    { name: 'Settings', path: '' },
    { name: 'Redemptions', path: 'redemptions' },
    { name: 'Images', path: 'files' },
    { name: 'Items', path: 'items' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'Dayparts', path: 'dayparts' },
    { name: 'Approved Emails', path: 'emails' },
    { name: 'QR Codes', path: 'qrcodes' }
  ],
  deals: [
    { name: 'Settings', path: '' },
    { name: 'Redemptions', path: 'redemptions' },
    { name: 'Images', path: 'files' },
    { name: 'Items', path: 'items' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'Dayparts', path: 'dayparts' },
    { name: 'QR Codes', path: 'qrcodes' }
  ],
  rewards: [
    { name: 'Settings', path: '' },
    { name: 'Redemptions', path: 'redemptions' },
    { name: 'Images', path: 'files' },
    { name: 'Items', path: 'items' },
    { name: 'Revenue Centers', path: 'revenue-centers' },
    { name: 'Dayparts', path: 'dayparts' },
    { name: 'QR Codes', path: 'qrcodes' },
    { name: 'Customers', path: 'customers' }
  ],
  taxes: [
    { name: 'Settings', path: '' },
    { name: 'Revenue Centers', path: 'revenue-centers' }
  ],
  users: [
    { name: 'Settings', path: '' },
    { name: 'Sections', path: 'sections' },
    { name: 'Stores', path: 'stores' }
  ],
  chowlyToos: [
    { name: 'Review Menus', path: 'menus' },
    { name: 'Update Menus', path: 'update-menus' }
  ],
  toast: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Dining Options', path: 'dining-options' },
    { name: 'Payment Types', path: 'payment-types' },
    { name: 'Discounts', path: 'discounts' },
    { name: 'Surcharges', path: 'surcharges' },
    { name: 'Review Menus', path: 'menus' },
    { name: 'Update Menus', path: 'update-menus' }
  ],
  salido: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Review Menus', path: 'menus' },
    { name: 'Update Menus', path: 'update-menus' }
  ],
  qu: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Order Types', path: 'order-types' },
    { name: 'Payment Types', path: 'payment-types' },
    { name: 'ID Map', path: 'id-map' },
    { name: 'Review Menus', path: 'menus' }
  ],
  revel: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Dining Options', path: 'dining-options' },
    { name: 'Payment Types', path: 'payment-types' },
    { name: 'Discounts', path: 'discounts' },
    { name: 'Retrieve IDs', path: 'entities' },
    { name: 'Update Mappings', path: 'update-mappings' }
  ],
  brightloom: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Dayparts', path: 'dayparts' },
    { name: 'Review Menus', path: 'menus' },
    { name: 'Update Mappings', path: 'update-mappings' },
    { name: 'Import Orders', path: 'import-orders' },
    { name: 'Import Errors', path: 'import-errors' },
    { name: 'POS Errors', path: 'pos-errors' }
  ],
  chowly: [
    { name: 'API Keys', path: 'credentials' },
    { name: 'Menu Updates', path: 'menu-updates' },
    { name: 'Import Errors', path: 'import-errors' }
  ],
  ctuit: [
    { name: 'API Credentials', path: 'credentials' },
    { name: 'Manual Sync', path: 'sync' }
  ],
  levelup: [
    { name: 'Online Creds', path: 'credentials' },
    { name: 'Production POS Creds', path: 'production' },
    { name: 'Sandbox POS Creds', path: 'sandbox' }
  ],
  thanx: [
    { name: 'Production Creds', path: 'production' },
    { name: 'Sandbox Creds', path: 'sandbox' }
  ],
  googleMaps: [
    { name: 'Settings', path: '' },
    { name: 'Map Marker', path: 'files' }
  ],
  intercom: [
    { name: 'Settings', path: '' },
    { name: 'Launcher Icon', path: 'files' }
  ],
  posts: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' },
    { name: 'Tags', path: 'post_tags' }
  ],
  announcements: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' }
  ],
  announcementPages: [
    { name: 'Settings', path: '' },
    { name: 'Announcements', path: 'announcements' }
  ],
  configContent: [
    { name: 'Guest', path: 'guest' },
    { name: 'Account', path: 'account' },
    { name: 'Order Type', path: 'order-type' },
    { name: 'Locations', path: 'revenue-centers' },
    { name: 'Catering', path: 'catering' },
    { name: 'Menu', path: 'menu' },
    { name: 'Upsells', path: 'upsells' },
    { name: 'Checkout Flow', path: 'checkout-flow' },
    { name: 'Checkout', path: 'checkout' },
    { name: 'Confirmation', path: 'confirmation' },
    { name: 'Curbside Pickup', path: 'fulfillment' },
    { name: 'Orders', path: 'orders' },
    { name: 'Favorites', path: 'favorites' },
    { name: 'Rewards', path: 'rewards' },
    { name: 'Deals', path: 'deals' },
    { name: 'Account Settings', path: 'account-settings' },
    { name: 'Profile', path: 'profile' },
    { name: 'Allergens', path: 'allergens' },
    { name: 'Communication Prefs', path: 'communication-preferences' },
    { name: 'Gift Cards', path: 'gift-cards-account' },
    { name: 'Credit Cards', path: 'credit-cards' },
    { name: 'Addresses', path: 'addresses' },
    { name: 'House Accounts', path: 'house-accounts' },
    { name: 'Reset Password', path: 'reset-password' },
    { name: 'Sign Up', path: 'sign-up' },
    { name: 'Group Orders', path: 'group-orders' },
    { name: 'Gift Cards Purchase', path: 'gift-cards' },
    { name: 'Donations', path: 'donations' },
    { name: 'Accessibility Policy', path: 'accessibility' },
    { name: 'Refund Policy', path: 'refunds' },
    { name: 'Not Found', path: 'not-found' },
    { name: 'Error', path: 'error' },
    { name: 'Home', path: 'home' },
    { name: 'About', path: 'about' },
    { name: 'Menu Site', path: 'menu-site' },
    { name: 'Catering Site', path: 'catering-site' }
  ],
  configStyles: [
    { name: 'Global', path: 'global' },
    { name: 'Fonts', path: 'fonts' },
    { name: 'Colors', path: 'colors' },
    { name: 'Links', path: 'links' },
    { name: 'Button Styles', path: 'button-styles' },
    { name: 'Button Colors', path: 'button-colors' },
    { name: 'Inputs', path: 'inputs' },
    { name: 'Cards', path: 'cards' },
    { name: 'Counts', path: 'counts' },
    { name: 'Welcome', path: 'welcome' },
    { name: 'Categories', path: 'categories' },
    { name: 'Menu Items', path: 'items' },
    { name: 'Menu Item', path: 'item' }
  ],
  configSettings: [
    { name: 'Order Types', path: 'order-types' },
    { name: 'Display Settings', path: 'display' },
    { name: 'Checkout Settings', path: 'checkout' },
    { name: 'Location Settings', path: 'locations' },
    { name: 'Google Maps', path: 'maps' },
    { name: 'Google Recaptcha', path: 'recaptcha' }
  ],
  kdsTerminals: [
    { name: 'Settings', path: '' },
    { name: 'Item Types', path: 'item-types' }
  ],
  prepStations: [
    { name: 'Settings', path: '' },
    { name: 'Item Types', path: 'item-types' }
  ],
  posPrinters: [
    { name: 'Settings', path: '' },
    { name: 'Item Types', path: 'item-types' }
  ],
  emailDesign: [
    { name: 'Settings', path: '' },
    { name: 'Images', path: 'files' },
    { name: 'Confirmation Preview', path: 'preview' },
    { name: 'Loyalty Reward Preview', path: 'loyalty-reward-preview' },
    { name: 'Promo Code Preview', path: 'promo-code-preview' },
    { name: 'Order Rating Preview', path: 'order-rating-preview' },
    { name: 'Order Rated Preview', path: 'order-rated-preview' },
    { name: 'Gift Card Preview', path: 'gift-card-preview' },
    { name: 'Gift Recipient Preview', path: 'gift-card-recipient-preview' },
    { name: 'Donation Preview', path: 'donation-preview' },
    { name: 'Group Order Preview', path: 'group-order-preview' },
    { name: 'Order Update Preview', path: 'order-update-preview' },
    { name: 'Password Reset Preview', path: 'password-reset-preview' },
    { name: 'Support Email Preview', path: 'support-preview' },
    { name: 'Contact Email Preview', path: 'contact-preview' }
  ]
}

export default tabs
