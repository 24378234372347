import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addNewRelation } from '../../reducers/relations'
import Search from '../Search'

class LoyaltyProgramCustomer extends Component {
  static propTypes = {
    addNewRelation: propTypes.func
  }

  render() {
    return (
      <Search
        endpoint="customers"
        resultId="customer_id"
        resultName={['first_name', 'last_name']}
        resultParens="email"
        placeholder="enter customer name or email"
        resultAction={this.props.addNewRelation}
        // defaults={{}}
      />
    )
  }
}

LoyaltyProgramCustomer.displayName = 'LoyaltyProgramCustomer'

export default connect(null, { addNewRelation })(LoyaltyProgramCustomer)
