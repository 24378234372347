import propTypes from 'prop-types'
import React from 'react'
import { LogOut } from 'react-feather'
import { NavLink } from 'react-router-dom'
import NavUser from './NavUser'

const Footer = ({ user }) => (
  <div className="footer">
    <div className="footer__logout">
      <NavUser user={user} />
      <div className="sidebar__logout">
        <NavLink to="/login">
          <LogOut size={null} />
        </NavLink>
      </div>
    </div>
    <div className="footer__copyright">
      <p>&copy; 2020 Brandibble Co. All rights reserved.</p>
    </div>
  </div>
)

Footer.displayName = 'Footer'
Footer.propTypes = {
  user: propTypes.object
}

export default Footer
