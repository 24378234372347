import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { blockCustomer, unblockCustomer } from '../reducers/customer'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'

class Customer extends Component {
  static propTypes = {
    // state.page.item
    endpoint: propTypes.string,
    setting: propTypes.string,
    id: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    validation: propTypes.object,
    actions: propTypes.array,
    hasGeocoder: propTypes.bool,
    isReplace: propTypes.bool,
    related: propTypes.string,
    // state.item
    data: propTypes.object,
    loading: propTypes.bool,
    errors: propTypes.object,
    // state.page.id
    pageId: propTypes.string,
    // mapdispatchtoprops
    blockCustomer: propTypes.func,
    unblockCustomer: propTypes.func,
    // state.customer
    customerLoading: propTypes.bool
  }

  handleBlock = evt => {
    evt.preventDefault()
    this.props.blockCustomer()
    evt.target.blur()
  }

  handleUnblock = evt => {
    evt.preventDefault()
    this.props.unblockCustomer()
    evt.target.blur()
  }

  render() {
    const { data, loading, customerLoading } = this.props
    const isLoading = loading || customerLoading ? true : false

    return (
      <div className="content__main">
        {isLoading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!isLoading && this.props.data ? (
            <CSSTransition {...cssTransitionFade}>
              <div>
                {data.is_blocked ? (
                  <>
                    <p>This customer is currently blocked.</p>
                    <br />
                    <p>
                      <button className="btn" onClick={this.handleUnblock}>
                        Unblock Customer
                      </button>
                    </p>
                  </>
                ) : (
                  <>
                    <p>This customer is NOT currently blocked.</p>
                    <br />
                    <p>
                      <button className="btn" onClick={this.handleBlock}>
                        Block Customer
                      </button>
                    </p>
                  </>
                )}
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Customer.displayName = 'Customer'

export default connect(
  state => ({
    ...state.page.item,
    ...state.item,
    pageId: state.page.id,
    customerLoading: state.customer.loading
  }),
  { blockCustomer, unblockCustomer }
)(Customer)
