import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { request } from '../../lib/services'
import { handlePageError } from '../../reducers/page'
import { showWorkingModal, closeModal } from '../../reducers/modal'
import { fetchItem } from '../../reducers/item'
import { flashMessage } from '../../reducers/messages'

class MarkPaid extends Component {
  static propTypes = {
    token: propTypes.string,
    brand: propTypes.object,
    invoice: propTypes.object,
    fetchItem: propTypes.func,
    showWorkingModal: propTypes.func,
    closeModal: propTypes.func,
    handlePageError: propTypes.func,
    flashMessage: propTypes.func
  }

  handleUpdate = evt => {
    evt.preventDefault()
    evt.target.blur()
    this.props.showWorkingModal()
    const { invoice, token, brand } = this.props
    const { house_account_id, order_ids, invoice_id } = invoice
    const data = { house_account_id, order_ids, is_paid: true }
    const endpoint = `/invoices/${invoice_id}`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        this.props.fetchItem()
        const msg = `Invoice ${invoice_id} successfully paid!`
        this.props.flashMessage(msg)
      })
      .catch(err => this.props.handlePageError(err))
      .finally(() => this.props.closeModal())
  }

  render() {
    const { order_ids, invoice_id } = this.props.invoice
    return (
      <div className="text">
        <p>
          Invoice #{invoice_id} includes orders {order_ids.join(', ')}.
        </p>
        <p>Are you sure you want to mark it as paid?</p>
        <p>This action cannot be undone.</p>
        <br />
        <button className="btn" onClick={this.handleUpdate}>
          Mark Paid
        </button>
      </div>
    )
  }
}

MarkPaid.displayName = 'MarkPaid'

export default connect(
  state => ({
    token: state.token,
    brand: state.brand
  }),
  { fetchItem, showWorkingModal, closeModal, handlePageError, flashMessage }
)(MarkPaid)
