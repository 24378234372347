import React from 'react'
import BarLoader from 'react-spinners/BarLoader'

const Submitting = () => {
  return (
    <div className="modal__submitting">
      <BarLoader size={36} color={'#5a5aff'} />
    </div>
  )
}

export default Submitting
