import propTypes from 'prop-types'
import React from 'react'
import { makeFieldValue } from '../lib/helpersPage'
import { Error } from './FormInputs'

export const Select = ({ item, name, field, id, options, handler }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={item[id]} className={`label select ${field.classes || ''}`}>
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        id={`${item[id]}-${field}`}
        value={makeFieldValue(field, item)}
        aria-label={name}
        onChange={handler}
      >
        {options ? (
          options.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.name}
            </option>
          ))
        ) : (
          <option>loading...</option>
        )}
      </select>
      <span className="select__arrow" />
    </label>
  )
}

Select.displayName = 'Select'
Select.propTypes = {
  item: propTypes.object,
  name: propTypes.string,
  field: propTypes.array,
  id: propTypes.string,
  options: propTypes.array,
  handler: propTypes.func
}

export const Checkbox = ({ item, id, name, handler }) => {
  return (
    <label htmlFor={item[id]} className="label checkbox checkbox--simple">
      <input
        id={item[id]}
        type="checkbox"
        className="checkbox__input"
        aria-label={name}
        checked={!!item.isSelected}
        disabled={!!item.isDisabled}
        onChange={handler}
      />
      <span
        className={`checkbox__custom ${item.isDisabled ? '-disabled' : ''}`}
      />
    </label>
  )
}

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  item: propTypes.object,
  id: propTypes.string,
  name: propTypes.string,
  field: propTypes.array,
  checked: propTypes.bool,
  disabled: propTypes.bool,
  handler: propTypes.func
}

export const Input = ({
  field,
  classes,
  value,
  placeholder,
  error,
  handler
}) => {
  return (
    <label htmlFor={field} className={`label ${classes || ''}`}>
      <input
        id={field}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handler}
      />
      {error.length ? <Error error={error} /> : null}
    </label>
  )
}

Input.displayName = 'Input'
Input.propTypes = {
  field: propTypes.string,
  classes: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  placeholder: propTypes.string,
  error: propTypes.string,
  handler: propTypes.func
}
