import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  updateQuery,
  clearQuery,
  clearQueryError,
  findOrder
} from '../reducers/receipt'
import { SearchInput } from './Search'

class OrderSearch extends Component {
  static propTypes = {
    // props
    query: propTypes.string,
    loadingResults: propTypes.bool,
    searchError: propTypes.string,
    // mapDispatchToProps
    updateQuery: propTypes.func,
    clearQuery: propTypes.func,
    clearQueryError: propTypes.func,
    findOrder: propTypes.func
  }

  handleInput = evt => {
    this.props.clearQueryError()
    this.props.updateQuery(evt.target.value)
  }

  handleEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.props.findOrder()
    }
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.findOrder()
    evt.target.blur()
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearQueryError()
    this.props.clearQuery()
  }

  render() {
    const { query, loadingResults, searchError } = this.props
    return (
      <div className="sections__wrapper -search">
        <ul className="sections">
          <li className="section">
            <span className="section__search">
              <span className="section__btn">
                <span className="section__line"></span>
                <span className="section__title">Find an order</span>
              </span>
              <span className="section__desc">
                <span className="content__search__input">
                  <SearchInput
                    label={null}
                    query={query || ''}
                    placeholder="enter order ID"
                    clearSize={14}
                    handleInput={this.handleInput}
                    handleEnter={this.handleEnter}
                    handleClear={this.handleClear}
                  />
                  <button
                    className="btn btn--small"
                    onClick={this.handleSubmit}
                  >
                    Go
                  </button>
                </span>
              </span>
            </span>
          </li>
          <li className="section">
            <span className="section__search">
              <span className="section__btn">
                <span className="section__title">&nbsp;</span>
              </span>
              <span className="section__desc">
                <span className="content__search__message">
                  {searchError && searchError.length ? (
                    <span className="error-text">{searchError}</span>
                  ) : loadingResults ? (
                    <ClipLoader size={20} color={'#5a5aff'} />
                  ) : null}
                </span>
              </span>
            </span>
          </li>
        </ul>
      </div>
    )
  }
}

OrderSearch.displayName = 'OrderSearch'

export default connect(
  state => ({
    ...state.receipt
  }),
  { updateQuery, clearQuery, clearQueryError, findOrder }
)(OrderSearch)
