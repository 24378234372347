import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateTip } from '../../reducers/order'
import { showModalErrors } from '../../reducers/modal'
import Form from '../Form'
import { PageError } from '../PageError'
import { valTypes } from '../../lib/constants'

const makefields = tipSettings => {
  let tipOptions = tipSettings.tip_options.map(i => ({
    value: i[0],
    name: i[1]
  }))
  tipOptions = [{ value: '', name: 'enter a custom amount' }, ...tipOptions]
  return [
    {
      label: 'Choose a tip amount',
      type: 'select',
      field: 'standard_tip',
      options: tipOptions,
      valType: valTypes.decimal,
      resetFields: ['custom_tip']
    },
    {
      label: 'Or enter a custom amount',
      type: 'text',
      field: 'custom_tip',
      valType: valTypes.decimal
    }
  ]
}

class TenderOther extends Component {
  static propTypes = {
    // state.order.customer
    tip: propTypes.string,
    tipSettings: propTypes.object,
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    // mapdispatchtoprops
    updateTip: propTypes.func,
    showModalErrors: propTypes.func
  }

  render() {
    const { tip, tipSettings, errors } = this.props
    const fields = makefields(tipSettings)
    const tipAmounts = tipSettings.tip_options.map(i => i[0])
    const standardTip = tipAmounts.includes(tip) ? tip : ''
    const customTip = !standardTip ? tip : ''
    const data = { standard_tip: standardTip, custom_tip: customTip }
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={true}
          idField="customer_id"
          fields={fields}
          data={data}
          errors={errors || {}}
          upsertItem={this.props.updateTip}
          showErrors={this.props.showModalErrors}
          windowRef={this.props.windowRef}
        />
      </>
    )
  }
}

TenderOther.displayName = 'TenderOther'

export default connect(
  state => ({
    tip: state.order.tip,
    tipSettings: state.order.tipSettings,
    errors: state.modal.errors
  }),
  { updateTip, showModalErrors }
)(TenderOther)
