import propTypes from 'prop-types'
import React from 'react'
import { capitalize, formatDollars, formatPercentage } from '../../lib/helpers'

const ChowlyToosMenuItemName = ({ item, isOption = false }) => {
  const suspendUntil = item.suspend_until
    ? new Date(item.suspend_until * 1000).toLocaleDateString('en-US')
    : 'n/a'
  const taxRate =
    item.tax_rate !== undefined ? formatPercentage(item.tax_rate) : null
  const itemId = isOption ? item.customization_option_id : item.item_id
  return (
    <span>
      {item.title} -- {formatDollars(item.price)} --{' '}
      <span className="err">{suspendUntil}</span> -- {itemId}
      {!isOption && <span className="err"> -- {item.external_id}</span>}
      {taxRate && ` -- ${taxRate}`}
    </span>
  )
}

ChowlyToosMenuItemName.displayName = 'ChowlyToosMenuItemName'
ChowlyToosMenuItemName.propTypes = {
  item: propTypes.object
}

const ChowlyToosModifierGroup = ({ modifierGroup }) => (
  <li>
    {modifierGroup.title} --{' '}
    <span className="success">
      min {modifierGroup.min_permitted}, max {modifierGroup.max_permitted}
    </span>{' '}
    -- {modifierGroup.external_id}
  </li>
)

ChowlyToosModifierGroup.displayName = 'ChowlyToosModifierGroup'
ChowlyToosModifierGroup.propTypes = {
  modifierGroup: propTypes.object
}

const ChowlyToosMenuItem = ({ menuItem: i }) => (
  <li>
    <ChowlyToosMenuItemName item={i} />
    {i.customizations.map(g => (
      <ul key={g.customization_id} className="mx__menu__modifier-group">
        <ChowlyToosModifierGroup modifierGroup={g} />
        <ul className="mx__menu__modifier-options">
          {g.customization_options.map(o => (
            <li key={o.customization_option_id}>
              <ChowlyToosMenuItemName item={o} isOption={true} />
            </li>
          ))}
        </ul>
      </ul>
    ))}
  </li>
)

ChowlyToosMenuItem.displayName = 'ChowlyToosMenuItem'
ChowlyToosMenuItem.propTypes = {
  menuItem: propTypes.object
}

export const ChowlyToosMenu = ({ menus, mappedMenus }) => (
  <div className="mx">
    <div className="mx__links">
      {menus.map(i => (
        <li key={i.section_id}>
          <a href={`#${i.section_id}`}>{i.title}</a>
        </li>
      ))}
    </div>
    <div className="mx__menus">
      {menus.map(i => (
        <div className="mx__menu" key={i.section_id} id={i.section_id}>
          <h2>{i.title}</h2>
          <h4>{i.section_id}</h4>
          <div className="mx__menu__hours">
            <ul className="mx__menu__modifier-options">
              {i.service_availability.map(sa => (
                <li key={sa.day_of_week} className="mx__menu__group">
                  {capitalize(sa.day_of_week)} --{' '}
                  {sa.time_periods
                    .map(t => `${t.start_time}-${t.end_time}`)
                    .join(', ')}
                </li>
              ))}
            </ul>
          </div>
          {i.subsections.map(subsection => (
            <div key={subsection.subsection_id} className="mx__menu__group">
              <h3>{subsection.title}</h3>
              <ul className="mx__menu__items">
                {subsection.items.map(item => (
                  <ChowlyToosMenuItem key={item.item_id} menuItem={item} />
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
)

ChowlyToosMenu.displayName = 'ChowlyToosMenu'
ChowlyToosMenu.propTypes = {
  menus: propTypes.array,
  mappedMenus: propTypes.array
}
