const baseUrl = process.env.REACT_APP_API_URL

export const request = (
  token,
  brand,
  endpoint,
  method = 'GET',
  data = null,
  timeout = null,
  contentType = 'application/json'
) => {
  let didTimeOut = false
  return new Promise((resolve, reject) => {
    let timer
    if (timeout) {
      timer = setTimeout(() => {
        didTimeOut = true
        reject(new Error('Request timed out'))
      }, timeout)
    }
    if (!brand) reject(new Error('Missing brand'))
    let options = {
      method: method,
      headers: {
        Accept: contentType,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'brand-id': `${brand.brand_id}`,
      },
    }
    if (data) options.body = JSON.stringify(data)
    // fetch('http://httpbin.org/status/502')
    fetch(`${baseUrl}${endpoint}`, options)
      .then((response) => parseJSON(response, contentType))
      .then((response) => {
        // console.log(response)
        if (didTimeOut) return
        if (response.ok) {
          // delay response for loading testing
          // return setTimeout(() => {
          //   resolve(response.json)
          // }, 5000)
          return resolve(response.json)
        }
        return reject(response.json)
      })
      .catch((err) => {
        if (didTimeOut) return
        if (err.message && err.message.includes('Failed to fetch')) {
          reject(errors.internalServerError)
        } else {
          reject(err)
        }
      })
      .finally(() => {
        if (timeout) clearTimeout(timer)
      })
  })
}

const fetchResults = async (token, brand, endpoint) => {
  return request(token, brand, endpoint).then((resp) => {
    const next =
      resp.links && resp.links.next
        ? resp.links.next.split('admin-api')[1]
        : null
    return { data: resp.data, next }
  })
}

export const fetchAll = async (token, brand, endpoint) => {
  const { data, next } = await fetchResults(token, brand, endpoint)
  if (next) {
    return data.concat(await fetchAll(token, brand, next))
  } else {
    return data
  }
}

export const requestFile = (token, brand, endpoint, fileType) => {
  return request(token, brand, endpoint, 'GET', null, null, fileType).then(
    (resp) => {
      return resp
    }
  )
}

export const uploadFile = (token, brand, endpoint, file) => {
  let options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'brand-id': `${brand.brand_id}`,
    },
    body: file,
  }
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}${endpoint}`, options)
      .then((response) => parseJSON(response, 'application/json'))
      .then((response) => {
        if (response.ok) {
          return resolve(response.json)
        }
        return reject(response.json)
      })
      .catch((err) => {
        if (err.message && err.message.includes('Failed to fetch')) {
          reject(errors.internalServerError)
        } else {
          reject(err)
        }
      })
  })
}

const parseResponse = (response, contentType) => {
  if (response.status >= 500) return response.text()
  if (response.status >= 400) return response.json()
  switch (contentType) {
    case 'text/csv':
    case 'text/plain':
    case 'text/html':
    case 'text/xml':
      return response.text()
    case 'application/pdf':
      return response.blob()
    default:
      return response.json()
  }
}

const parseJSON = (response, contentType) => {
  if (response.status === 204) {
    return new Promise((resolve) =>
      resolve({
        status: response.status,
        ok: response.ok,
        json: '',
      })
    )
  }
  const parseFunc = () => parseResponse(response, contentType)
  return new Promise((resolve, reject) =>
    parseFunc()
      .then((json) => {
        if (response.status < 500) {
          return resolve({
            status: response.status,
            ok: response.ok,
            json,
          })
        }
        throw new Error(json)
      })
      .catch((err) => {
        switch (response.status) {
          case 401:
            return reject(errors.notAuthorized)
          case 405:
            return reject(errors.methodNotAllowed)
          case 500:
            return reject(errors.internalServerError)
          case 501:
            return reject(errors.notImplemented)
          case 502:
            return reject(errors.badGateway)
          case 503:
            return reject(errors.tempUnavailable)
          case 504:
            return reject(errors.gatewayTimeout)
          default:
            return reject(err)
        }
      })
  )
}

export const errors = {
  notAuthorized: {
    code: 'not_authorized',
    status: 401,
    title: 'Not Authorized',
    detail: 'Your session has expired. Please login again.',
  },
  methodNotAllowed: {
    code: 'method_not_allowed',
    status: 405,
    title: 'Method Not Allowed',
    detail: 'The method is not allowed for the requested URL.',
  },
  parseError: {
    code: 'parse_error',
    status: 422,
    title: 'Cannot Read File',
    detail:
      'There was an error reading this file. Please contact Open Tender Support.',
  },
  internalServerError: {
    code: 'internal_server_error',
    status: 500,
    title: 'Internal Server Error',
    detail: 'Internal server error. Please contact Open Tender Support.',
  },
  notImplemented: {
    code: 'not_implemented',
    status: 501,
    title: 'Servers Overloaded',
    detail:
      'Servers temporarily overloaded. Please retry your request. If this issue persists, please contact Open Tender Support.',
  },
  badGateway: {
    code: 'bad_gateway',
    status: 502,
    title: 'Bad Gateway',
    detail:
      'Temporary gateway error. Please retry your request. If this issue persists, please contact Open Tender Support.',
  },
  tempUnavailable: {
    code: 'temporarily_unavailable',
    status: 503,
    title: 'Service Temporarily Unavailable',
    detail:
      'Service Temporarily Unavailable. Please retry your request. If this issue persists, please contact Open Tender Support.',
  },
  gatewayTimeout: {
    code: 'gateway_timeout',
    status: 504,
    title: 'Gateway Timeout',
    detail: 'Gateway Timeout. Please contact Open Tender Support.',
  },
}
