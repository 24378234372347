import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteRelation } from '../../reducers/relations'

class DeleteRelation extends Component {
  static propTypes = {
    deleteId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    deleteRelation: propTypes.func
  }

  handleDelete = evt => {
    evt.preventDefault()
    const { deleteId } = this.props
    const itemId = isNaN(parseInt(deleteId)) ? deleteId : parseInt(deleteId)
    this.props.deleteRelation(itemId)
    evt.target.blur()
  }

  render() {
    // console.log(this.props.deleteId)
    return (
      <button className="btn" onClick={this.handleDelete}>
        Remove
      </button>
    )
  }
}

DeleteRelation.displayName = 'DeleteRelation'

export default connect(null, { deleteRelation })(DeleteRelation)
