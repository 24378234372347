import { request } from '../lib/services'
import { isEmpty } from '../lib/utils'
import { weekdays } from '../lib/constants'
import { sortItems } from '../lib/helpers'
import { handleError } from '../lib/errors'
import {
  minutesToTime,
  timeToMinutes,
  isValidTimeAmPm,
  isValidDate
} from '../lib/helpersDatetime'
import { flashMessage } from './messages'
import { handlePageError } from './page'

const initState = {
  loading: false,
  hours: null,
  holidays: null,
  errors: {}
}

export const HOURS_CLEAR_ALL = 'HOURS_CLEAR_ALL'
export const HOURS_CLEAR = 'HOURS_CLEAR'
export const HOURS_LOADING = 'HOURS_LOADING'
export const HOURS_LOAD = 'HOURS_LOAD'
export const HOURS_ERRORS = 'HOURS_ERRORS'

export const clearHoursAll = () => ({ type: HOURS_CLEAR_ALL })
export const loadingHours = () => ({ type: HOURS_LOADING })
export const loadHours = data => ({ type: HOURS_LOAD, payload: data })

export const showHoursErrors = errors => {
  return dispatch => {
    window.scroll(0, 0)
    dispatch({ type: HOURS_ERRORS, payload: errors })
  }
}

const makeHours = items => {
  const hoursMap = items
    .filter(i => i.weekday !== 'HOLIDAY')
    .reduce((obj, i) => {
      obj[i.weekday]
        ? (obj[i.weekday][i.service_type] = {
            open: minutesToTime(i.start_min),
            close: minutesToTime(i.end_min)
          })
        : (obj[i.weekday] = {
            [i.service_type]: {
              open: minutesToTime(i.start_min),
              close: minutesToTime(i.end_min)
            }
          })
      return obj
    }, {})
  const hours = weekdays.map(w => {
    const weekday = w.toUpperCase()
    return { name: w, weekday: weekday, ...hoursMap[weekday] }
  })
  return hours
}

export const fetchHours = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearHoursAll())
    dispatch(loadingHours())
    const endpoint = `/hours?revenue_center_id=${page.id}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // console.log(JSON.stringify(resp.data, null, 2))
        const hours = makeHours(resp.data)
        dispatch(loadHours({ hours }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const updateTime = (weekday, serviceType, hourType, value) => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const currentWeekday = hours.hours.filter(i => i.name === weekday)[0]
    const currentServiceType = currentWeekday[serviceType]
    const newServiceType = { ...currentServiceType, [hourType]: value }
    const newWeekday = { ...currentWeekday, [serviceType]: newServiceType }
    const updatedHours = hours.hours.map(weekday => {
      return weekday.name === newWeekday.name ? newWeekday : weekday
    })
    dispatch(loadHours({ hours: updatedHours }))
  }
}

const makeEntry = (weekday, serviceType, revenueCenterId) => {
  let entry = null,
    entryErrors = {}
  if (!weekday[serviceType]) {
    entry = {
      day: 0,
      end_min: null,
      month: 0,
      name: '',
      revenue_center_id: parseInt(revenueCenterId),
      service_type: serviceType,
      start_min: null,
      weekday: weekday.name.toUpperCase()
    }
    return [entry, entryErrors]
  }
  let { open, close } = weekday[serviceType]
  open = !open ? null : open
  close = !close ? null : close
  if (open && !isValidTimeAmPm(open)) {
    entryErrors[`${weekday.name}-${serviceType}-open`] = 'Invalid'
  }
  if (close && !isValidTimeAmPm(close)) {
    entryErrors[`${weekday.name}-${serviceType}-close`] = 'Invalid'
  }
  if (!open && close) {
    entryErrors[`${weekday.name}-${serviceType}-open`] = 'Invalid'
  }
  if (open && !close) {
    entryErrors[`${weekday.name}-${serviceType}-close`] = 'Invalid'
  }
  if (isEmpty(entryErrors)) {
    const startMin = timeToMinutes(open)
    const endMin = timeToMinutes(close)
    if (startMin && endMin && startMin > endMin) {
      entryErrors[`${weekday.name}-${serviceType}-open`] = 'Invalid'
    } else {
      entry = {
        day: 0,
        end_min: endMin,
        month: 0,
        name: '',
        revenue_center_id: parseInt(revenueCenterId),
        service_type: serviceType,
        start_min: startMin,
        weekday: weekday.name.toUpperCase()
      }
    }
  }
  return [entry, entryErrors]
}

export const updateHours = () => {
  return (dispatch, getState) => {
    const { token, brand, page, hours } = getState()
    let newHours = [],
      errors = {}
    hours.hours.map(weekday => {
      return ['PICKUP', 'DELIVERY'].map(serviceType => {
        const [entry, entryErrors] = makeEntry(weekday, serviceType, page.id)
        if (entry) {
          newHours = [...newHours, entry]
        } else if (!isEmpty(entryErrors)) {
          errors = { ...errors, ...entryErrors }
        }
      })
    })
    if (!isEmpty(errors)) {
      return dispatch(showHoursErrors(errors))
    }
    const updated = newHours.filter(i => i.start_min !== null)
    const deleted = newHours.filter(i => i.start_min === null)
    let postRequest = null
    if (updated.length) {
      postRequest = request(token, brand, '/hours', 'POST', updated)
    }
    let deleteRequest = null
    if (deleted.length) {
      deleteRequest = request(token, brand, '/hours', 'DELETE', deleted)
    }
    Promise.all([postRequest, deleteRequest])
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchHours())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        const errMsg = errors.fields
          ? Object.values(errors.fields).join(' ')
          : errors.form
        dispatch(showHoursErrors({ form: errMsg }))
      })
  }
}

export const clearHours = () => {
  return dispatch => {
    const hours = weekdays.map(w => {
      return { name: w }
    })
    dispatch({ type: HOURS_CLEAR, payload: hours })
  }
}

export const resetHours = () => {
  return dispatch => {
    dispatch(fetchHours())
  }
}

export const copyDown = (serviceType, hourType) => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const sunday = hours.hours.filter(weekday => weekday.name === 'Sunday')[0]
    const sundayTime = sunday[serviceType]
      ? sunday[serviceType][hourType]
      : null
    const newHours = hours.hours.map(weekday => {
      weekday[serviceType] = {
        ...(weekday[serviceType] || {}),
        [hourType]: sundayTime
      }
      return weekday
    })
    dispatch(loadHours({ hours: newHours }))
  }
}

const makeHolidays = items => {
  const holidaysMap = items
    .filter(i => i.weekday === 'HOLIDAY')
    .reduce((obj, i) => {
      const date = `${i.month}/${i.day}`
      if (obj[date]) {
        if (i.start_min > 0 || i.start_min === 0) {
          obj[date][i.service_type] = {
            open: minutesToTime(i.start_min),
            close: minutesToTime(i.end_min)
          }
        }
      } else {
        obj[date] = {
          name: i.name,
          date: date,
          weekday: i.weekday,
          sort: i.month * 30 + i.day
        }
        if (i.start_min > 0 || i.start_min === 0) {
          obj[date][i.service_type] = {
            open: minutesToTime(i.start_min),
            close: minutesToTime(i.end_min)
          }
        }
      }
      return obj
    }, {})
  const sorting = { sortBy: 'sort', sortType: 'order' }
  const holidays = sortItems(Object.values(holidaysMap), sorting)
  return holidays
}

export const fetchHolidays = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearHoursAll())
    dispatch(loadingHours())
    const endpoint = `/hours?revenue_center_id=${page.id}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // const holidaysOnly = resp.data.filter(i => i.weekday === 'HOLIDAY')
        // console.log(JSON.stringify(holidaysOnly, null, 2))
        const holidays = makeHolidays(resp.data)
        dispatch(loadHours({ holidays }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const updateHolidayTime = (index, serviceType, hourType, value) => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const currentWeekday = hours.holidays.filter((i, idx) => idx === index)[0]
    const currentServiceType = currentWeekday[serviceType]
    const newServiceType = { ...currentServiceType, [hourType]: value }
    const newWeekday = { ...currentWeekday, [serviceType]: newServiceType }
    const updatedHolidays = hours.holidays.map((weekday, idx) => {
      return idx === index ? newWeekday : weekday
    })
    dispatch(loadHours({ holidays: updatedHolidays }))
  }
}

export const updateField = (index, field, value) => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const currentWeekday = hours.holidays.filter((i, idx) => idx === index)[0]
    const newWeekday = { ...currentWeekday, [field]: value }
    const updatedHolidays = hours.holidays.map((weekday, idx) => {
      return idx === index ? newWeekday : weekday
    })
    dispatch(loadHours({ holidays: updatedHolidays }))
  }
}

export const addNewHoliday = () => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const newHoliday = {
      name: '',
      weekday: 'HOLIDAY',
      date: '',
      isNew: true
    }
    const updatedHolidays = [...hours.holidays, newHoliday]
    dispatch(loadHours({ holidays: updatedHolidays }))
  }
}

export const cancelNewHoliday = () => {
  return (dispatch, getState) => {
    const { hours } = getState()
    const updatedHolidays = hours.holidays.filter(i => !i.isNew)
    dispatch(loadHours({ holidays: updatedHolidays }))
  }
}

const validateHours = (key, open, close) => {
  let errors = {}
  if (open && open.length) {
    if (!isValidTimeAmPm(open)) {
      errors[`${key}-open`] = 'Invalid'
    } else if (!close || !close.length) {
      errors[`${key}-close`] = 'Invalid'
    }
  }
  if (close && close.length) {
    if (!isValidTimeAmPm(close)) {
      errors[`${key}-close`] = 'Invalid'
    } else if (!open || !open.length) {
      errors[`${key}-open`] = 'Invalid'
    }
  }
  if (isEmpty(errors)) {
    const startMin = open ? timeToMinutes(open) : null
    const endMin = close ? timeToMinutes(close) : null
    if (startMin && endMin && startMin > endMin) {
      errors[`${key}-open`] = 'Invalid'
    }
  }
  return errors
}

const makeHolidayEntry = (weekday, index, serviceType, revenueCenterId) => {
  let entry = null,
    entryErrors = {}
  if (!weekday.name.length) {
    entryErrors[`${index}-name`] = 'Invalid'
  }
  const [month, day] = weekday.date.split('/')
  if (!isValidDate(month, day)) {
    entryErrors[`${index}-date`] = 'Invalid'
  }
  if (!weekday[serviceType]) {
    if (!isEmpty(entryErrors)) {
      return [entry, entryErrors]
    }
    entry = {
      day: parseInt(day),
      end_min: null,
      month: parseInt(month),
      name: weekday.name,
      revenue_center_id: parseInt(revenueCenterId),
      service_type: serviceType,
      start_min: null,
      weekday: 'HOLIDAY'
    }
    return [entry, entryErrors]
  }
  const key = `${index}-${serviceType}`
  const { open, close } = weekday[serviceType]
  const hoursErrors = validateHours(key, open, close)
  entryErrors = { ...entryErrors, ...hoursErrors }
  if (!isEmpty(entryErrors)) {
    return [entry, entryErrors]
  }
  const startMin = timeToMinutes(open)
  const endMin = timeToMinutes(close)
  entry = {
    day: parseInt(day),
    end_min: endMin,
    month: parseInt(month),
    name: weekday.name,
    revenue_center_id: parseInt(revenueCenterId),
    service_type: serviceType,
    start_min: startMin,
    weekday: 'HOLIDAY'
  }
  return [entry, entryErrors]
}

const makeHolidayEntries = (holidays, revenueCenterId) => {
  let entries = [],
    errors = {}
  holidays.map((weekday, index) => {
    return ['PICKUP', 'DELIVERY'].map(serviceType => {
      const [entry, entryErrors] = makeHolidayEntry(
        weekday,
        index,
        serviceType,
        revenueCenterId
      )
      if (entry) {
        entries = [...entries, entry]
      } else if (!isEmpty(entryErrors)) {
        errors = { ...errors, ...entryErrors }
      }
    })
  })
  return [entries, errors]
}

export const updateHolidays = () => {
  return (dispatch, getState) => {
    const { token, brand, page, hours } = getState()
    let [entries, errors] = makeHolidayEntries(hours.holidays, page.id)
    if (!isEmpty(errors)) {
      return dispatch(showHoursErrors(errors))
    }
    // console.log(JSON.stringify(holidays, null, 2))
    request(token, brand, '/hours', 'POST', entries)
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchHolidays())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const removeHoliday = (index, fromAll) => {
  return (dispatch, getState) => {
    const { token, brand, page, hours, options, item } = getState()
    const holidays = hours.holidays.filter((i, idx) => idx === index)
    let [entries, errors] = makeHolidayEntries(holidays, page.id)
    if (!isEmpty(errors)) {
      return dispatch(showHoursErrors(errors))
    }
    if (fromAll) {
      const revenueCenters =
        options['revenue-centers'][item.data.revenue_center_type]
      const holidayEntries = [...entries]
      revenueCenters.map(i => {
        holidayEntries.map(e => {
          if (i.revenue_center_id !== e.revenue_center_id) {
            const newEntry = { ...e, revenue_center_id: i.revenue_center_id }
            entries = [...entries, newEntry]
          }
        })
      })
    }
    // console.log(JSON.stringify(entries, null, 2))
    request(token, brand, '/hours', 'DELETE', entries)
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchHolidays())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const addHolidayToAll = index => {
  return (dispatch, getState) => {
    const { token, brand, page, hours, options, item } = getState()
    const holidays = hours.holidays.filter((i, idx) => idx === index)
    let [entries, errors] = makeHolidayEntries(holidays, page.id)
    if (!isEmpty(errors)) {
      return dispatch(showHoursErrors(errors))
    }
    const revenueCenters =
      options['revenue-centers'][item.data.revenue_center_type]
    const holidayEntries = [...entries]
    revenueCenters.map(i => {
      holidayEntries.map(e => {
        if (i.revenue_center_id !== e.revenue_center_id) {
          const newEntry = { ...e, revenue_center_id: i.revenue_center_id }
          entries = [...entries, newEntry]
        }
      })
    })
    // console.log(JSON.stringify(entries, null, 2))
    request(token, brand, '/hours', 'POST', entries)
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchHolidays())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case HOURS_CLEAR_ALL:
      return { ...initState }
    case HOURS_CLEAR:
      return { ...state, hours: action.payload }
    case HOURS_LOADING:
      return { ...state, loading: true }
    case HOURS_LOAD:
      return { ...state, ...action.payload, errors: {}, loading: false }
    case HOURS_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    default:
      return state
  }
}
