import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  updateInput,
  submitForm,
  toggleShow
} from '../../reducers/changePassword'
import PassWordInput from '../PasswordInput'
import { PageError } from '../PageError'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.submit = React.createRef()
  }

  static propTypes = {
    // state.changePassword
    data: propTypes.object,
    errors: propTypes.object,
    show: propTypes.bool,
    submitting: propTypes.bool,
    // mapdispatchtoprops
    updateInput: propTypes.func,
    submitForm: propTypes.func,
    toggleShow: propTypes.func
  }

  handleInput = evt => {
    const { id, value } = evt.target
    this.props.updateInput(id, value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.submitForm()
    this.submit.current.blur()
  }

  handleToggle = evt => {
    evt.preventDefault()
    this.props.toggleShow()
    evt.target.blur()
  }

  render() {
    const { data, errors, show, submitting } = this.props
    const fields = errors.fields || {}
    return (
      <form id="form" onSubmit={this.handleSubmit}>
        <PageError msg={errors.form} />
        <PassWordInput
          label="Current Password"
          id="current_password"
          value={data.current_password || ''}
          error={fields.current_password || ''}
          show={show}
          handleInput={this.handleInput}
          handleToggle={this.handleToggle}
        />
        <PassWordInput
          label="New Password"
          id="new_password"
          value={data.new_password || ''}
          error={fields.new_password || ''}
          show={show}
          handleInput={this.handleInput}
          handleToggle={this.handleToggle}
        />
        <input
          type="submit"
          value={submitting ? 'Submitting...' : 'Submit'}
          ref={this.submit}
          disabled={submitting}
        />
      </form>
    )
  }
}

ChangePassword.displayName = 'ChangePassword'

export default connect(
  state => ({
    ...state.changePassword
  }),
  { updateInput, submitForm, toggleShow }
)(ChangePassword)
