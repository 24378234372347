import propTypes from 'prop-types'
import React from 'react'
import { RefreshCw } from 'react-feather'

const Reset = ({ title, reset }) => (
  <button className="btn-link" onClick={reset}>
    {title}
  </button>
)

Reset.displayName = 'Reset'
Reset.propTypes = {
  title: propTypes.string,
  reset: propTypes.func
}

const Next = ({ links, next }) => (
  <span>
    <a href={links.next} onClick={next}>
      See next results
    </a>
  </span>
)

Next.displayName = 'Next'
Next.propTypes = {
  links: propTypes.object,
  next: propTypes.func
}

const Results = ({ data, links, refresh, next, reset }) => {
  const resetTitle = links ? 'clear all filters' : 'Clear all filters'
  return (
    <div className="report__results">
      <span className="report__results__refresh">
        <button className="btn-link" onClick={refresh}>
          <RefreshCw size={16} />
        </button>
      </span>
      <p>
        Displaying {data.length} results. &nbsp;
        {data.length === 500 &&
          'Download CSV to see all results or adjust your filters.'}{' '}
        &nbsp;
        {links && <Next links={links} next={next} />}
        {links && reset && <span> or </span>}
        {reset && <Reset title={resetTitle} reset={reset} />}
      </p>
    </div>
  )
}

Results.displayName = 'Results'
Results.propTypes = {
  data: propTypes.array,
  links: propTypes.object,
  refresh: propTypes.func,
  next: propTypes.func,
  reset: propTypes.func
}

export default Results
