import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../reducers/modal'

class MarkPaid extends Component {
  static propTypes = {
    invoice: propTypes.object,
    openModal: propTypes.func
  }

  handleClick = evt => {
    const { invoice } = this.props
    const config = {
      title: 'Are you sure?',
      subtitle: '',
      type: 'markPaid',
      args: { invoice }
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    return !this.props.invoice.is_paid ? (
      <button className="btn-link" onClick={this.handleClick}>
        mark paid
      </button>
    ) : (
      <span>Yes</span>
    )
  }
}

MarkPaid.displayName = 'MarkPaid'

export default connect(null, {
  openModal
})(MarkPaid)
