import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addNewRelation } from '../../reducers/relations'
import Search from '../Search'

class GroupItem extends Component {
  static propTypes = {
    endpoint: propTypes.string,
    resultId: propTypes.string,
    resultName: propTypes.string,
    resultParens: propTypes.string,
    placeholder: propTypes.string,
    defaults: propTypes.object,
    addNewRelation: propTypes.func
  }

  render() {
    const {
      endpoint,
      resultId,
      resultName,
      resultParens,
      placeholder,
      defaults
    } = this.props
    return (
      <Search
        endpoint={endpoint}
        resultId={resultId}
        resultName={resultName}
        resultParens={resultParens}
        placeholder={placeholder}
        resultAction={this.props.addNewRelation}
        defaults={defaults}
      />
    )
  }
}

GroupItem.displayName = 'GroupItem'

export default connect(null, { addNewRelation })(GroupItem)
