import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../reducers/modal'

class ItemCategoryMove extends Component {
  static propTypes = {
    itemId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    relatedId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    endpoint: propTypes.string,
    openModal: propTypes.func
  }

  handleClick = evt => {
    const config = {
      title: `Choose a category`,
      subtitle: `Use the dropdown below to choose a category`,
      type: 'itemCategory',
      args: { ...this.props }
    }
    this.props.openModal(config)
    evt.target.blur()
  }

  render() {
    return (
      <button className="btn-link" onClick={this.handleClick}>
        move
      </button>
    )
  }
}

ItemCategoryMove.displayName = 'ItemCategoryMove'

export default connect(null, {
  openModal
})(ItemCategoryMove)
