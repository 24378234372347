import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  {
    name: 'Name / Code',
    field: ['discount_name'],
    link: 'discount_id',
    linkEndpoint: '/discounts'
  },
  { name: 'Is Promo', field: ['is_promo_code'], format: 'bool' },
  {
    name: 'Order ID',
    field: ['order_id'],
    link: 'order_id',
    linkEndpoint: '/orders'
  },
  {
    name: 'Requested At',
    field: ['requested_at'],
    format: 'datetimeZoned',
    tz: 'timezone'
  },
  {
    name: 'Store',
    field: ['store_name']
  },
  { name: 'Order Type', field: ['order_type'], format: 'enum' },
  { name: 'Service Type', field: ['service_type'], format: 'enum' },
  {
    name: 'Subtotal',
    field: ['subtotal'],
    format: 'dollars'
  },
  {
    name: 'Discount',
    field: ['discount_amount'],
    format: 'dollars'
  },
  {
    name: 'Total',
    field: ['total'],
    format: 'dollars'
  }
]

class Discounts extends Component {
  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, handlers } = this.props
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/discounts/csv" />
            </div>
          </div>
        </div>
        <div className="report__table">
          <Table fields={fields} items={data} />
        </div>
      </>
    )
  }
}

Discounts.displayName = 'Discounts'

export default connect(null, {})(Discounts)
