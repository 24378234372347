import { request } from '../lib/services'
import { timezoneMap, orderTypeRevenueCenterMap } from '../lib/constants'
import { isEmpty } from '../lib/utils'
import { sortItems } from '../lib/helpers'
import {
  makeLocalDateStr,
  getCurrentISO,
  offsetIso,
  makeZonedDate,
  zonedDateStrToDate,
  minutesToTime,
  makeLocalDate
} from '../lib/helpersDatetime'
import {
  getLatLng,
  sortRevenueCenters,
  makeWeekdaysExcluded
} from '../lib/helpersOrder'
import { flashMessage } from './messages'
import { handlePageError, clearPageError } from './page'
import { loadItem } from './item'
import { editOrder, updateReceipt } from './receipt'
import { loadingModal, openModal, closeModal, showModalErrors } from './modal'
import { makeWaitTimes } from './orderMgmtStores'
import { errMessages, processErrorMessage, handleError } from '../lib/errors'
import { parseISO, format } from 'date-fns'
import { updateOrderOrders, getOrderOrders } from './orders'

const initState = {
  refreshing: false,
  loading: true,
  orders: [],
  orderPrint: null,
  orderRouter: false,
  orderType: '',
  orderStatus: ['OPEN'],
  displaySettings: ['BY_DATE'],
  muted: false,
  todayOnly: false,
  businessDate: null,
  lastUpdated: null,
  search: {
    query: '',
    result: null,
    error: null
  },
  hours: null
}

export const ORDER_MGMT_CLEAR = 'ORDER_MGMT_CLEAR'
export const ORDER_MGMT_CLEAR_ORDERS = 'ORDER_MGMT_CLEAR_ORDERS'
export const ORDER_MGMT_REFRESH = 'ORDER_MGMT_REFRESH'
export const ORDER_MGMT_LOAD = 'ORDER_MGMT_LOAD'
export const ORDER_MGMT_TYPE = 'ORDER_MGMT_TYPE'
export const ORDER_MGMT_STATUS = 'ORDER_MGMT_STATUS'
export const ORDER_MGMT_DISPLAY = 'ORDER_MGMT_DISPLAY'
export const ORDER_MGMT_UPDATED = 'ORDER_MGMT_UPDATED'
export const ORDER_MGMT_SEARCHED = 'ORDER_MGMT_SEARCHED'
export const ORDER_MGMT_BUSINESS_DATE = 'ORDER_MGMT_BUSINESS_DATE'
export const ORDER_MGMT_PRINT_CLEAR = 'ORDER_MGMT_PRINT_CLEAR'
export const ORDER_MGMT_PRINT_LOAD = 'ORDER_MGMT_PRINT_LOAD'
export const ORDER_MGMT_PRINT_ROUTER = 'ORDER_MGMT_PRINT_ROUTER'
export const ORDER_MGMT_SEARCH_UPDATE = 'ORDER_MGMT_SEARCH_UPDATE'
export const ORDER_MGMT_SEARCH_CLEAR = 'ORDER_MGMT_SEARCH_CLEAR'
export const ORDER_MGMT_HOURS = 'ORDER_MGMT_HOURS'
export const ORDER_MGMT_MUTE = 'ORDER_MGMT_MUTE'
export const ORDER_MGMT_MUTE_TOGGLE = 'ORDER_MGMT_MUTE_TOGGLE'

export const clearOrderMgmt = () => ({ type: ORDER_MGMT_CLEAR })
export const clearOrders = () => ({ type: ORDER_MGMT_CLEAR_ORDERS })
export const toggleRefreshing = bool => ({
  type: ORDER_MGMT_REFRESH,
  payload: bool
})
export const loadOrders = items => ({ type: ORDER_MGMT_LOAD, payload: items })

export const setMuted = bool => ({ type: ORDER_MGMT_MUTE, payload: bool })
export const toggleMuted = () => ({ type: ORDER_MGMT_MUTE_TOGGLE })

export const updateOrderType = orderType => ({
  type: ORDER_MGMT_TYPE,
  payload: orderType
})

export const updateOrderStatus = orderStatus => ({
  type: ORDER_MGMT_STATUS,
  payload: orderStatus
})

export const toggleOrderStatus = orderStatus => {
  return (dispatch, getState) => {
    const current = getState().orderMgmt.orderStatus
    let newStatus
    if (current.includes(orderStatus)) {
      newStatus = current.filter(i => i !== orderStatus)
    } else {
      newStatus = [...current, orderStatus]
    }
    dispatch(updateOrderStatus(newStatus))
  }
}

export const updateDisplay = displaySettings => ({
  type: ORDER_MGMT_DISPLAY,
  payload: displaySettings
})

export const toggleDisplay = displaySetting => {
  return (dispatch, getState) => {
    const current = getState().orderMgmt.displaySettings
    let newDisplay
    if (current.includes(displaySetting)) {
      newDisplay = current.filter(i => i !== displaySetting)
    } else {
      newDisplay = [...current, displaySetting]
    }
    dispatch(updateDisplay(newDisplay))
  }
}

export const updateLastUpdated = () => {
  return dispatch => {
    dispatch({ type: ORDER_MGMT_UPDATED, payload: getCurrentISO() })
  }
}

export const updateBusinessDate = businessDate => {
  return dispatch => {
    dispatch({ type: ORDER_MGMT_BUSINESS_DATE, payload: businessDate })
    const newStatus = businessDate ? ['OPEN', 'CLOSED', 'VOID'] : ['OPEN']
    dispatch(updateOrderStatus(newStatus))
    dispatch(fetchOrderMgmt(businessDate))
  }
}

export const fetchOrderMgmt = businessDate => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(clearOrders())
    const { token, brand, page, orderMgmt } = getState()
    businessDate = businessDate || orderMgmt.businessDate
    const storesEndpoint = `/stores/${page.id}?with_related=revenue_centers`
    const storeRequest = request(token, brand, storesEndpoint, 'GET')
    let params = '&exclude_in_store_orders=true&receipt_type=SALE'
    params += '&expand=true&with_related=tenders'
    params += '&sort-by=requested_at&sort-direction=ASC&limit=1000'
    params += '&replica=false'
    const endpoint = `/bulk-orders?store_id=${page.id}${params}`
    let futureRequest, openRequest
    if (businessDate) {
      openRequest = new Promise(resolve => resolve({ data: [] }))
      const futureEndpoint = `${endpoint}&start_date=${businessDate}&end_date=${businessDate}`
      futureRequest = request(token, brand, futureEndpoint, 'GET')
    } else {
      const today = makeLocalDateStr()
      const yesterday = makeLocalDateStr(-1)
      const openEndpoint = `${endpoint}&receipt_status=OPEN&end_date=${yesterday}`
      openRequest = request(token, brand, openEndpoint, 'GET')
      const futureEndpoint = `${endpoint}&start_date=${today}`
      futureRequest = request(token, brand, futureEndpoint, 'GET')
    }
    dispatch(updateLastUpdated())
    Promise.all([openRequest, futureRequest, storeRequest])
      .then(([open, future, store]) => {
        dispatch(loadItem(store))
        const allOrders = open.data.concat(future.data)
        dispatch(loadOrders(allOrders))
        const olo = store.revenue_centers.filter(
          i => i.revenue_center_type === 'OLO'
        )
        const nonOutpost = olo.filter(i => !i.is_outpost)
        const locId = nonOutpost.length ? nonOutpost[0] : olo[0]
        if (olo.length) dispatch(fetchHours(locId, store.timezone))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const reloadOrders = () => {
  return dispatch => {
    dispatch(fetchOrderMgmt())
  }
}

const makeOrdersLookup = orders => {
  return orders.reduce(
    (obj, i) => ({ ...obj, [i.receipt_id]: i.updated_at }),
    {}
  )
}

// only fetch orders that have been updated since
// the last time the orders were retrieved
export const refreshOrders = () => {
  return (dispatch, getState) => {
    const { token, brand, page, orderMgmt } = getState()
    const { orders, lastUpdated, businessDate, search } = orderMgmt
    if (businessDate || search.result || search.error) {
      const secondsSince = (new Date() - parseISO(lastUpdated)) / 1000
      if (secondsSince < 180) return
      dispatch(clearSearch())
      return dispatch(updateBusinessDate(null))
    }
    const updatedAfter = offsetIso(lastUpdated)
    let params = `&updated_after=${updatedAfter}`
    params += '&exclude_in_store_orders=true&receipt_type=SALE'
    params += '&expand=true&with_related=tenders'
    params += '&sort-by=requested_at&sort-direction=ASC'
    params += '&replica=false'
    const endpoint = `/bulk-orders?store_id=${page.id}${params}`
    dispatch(toggleRefreshing(true))
    dispatch(updateLastUpdated())
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // dispatch(updateOrders(resp.data))
        const lookup = makeOrdersLookup(orders)
        if (resp.data.length) {
          const updated = resp.data.filter(
            i => lookup[i.receipt_id.toString()] !== i.updated_at
          )
          dispatch(updateOrders(updated))
        }
        dispatch(clearPageError())
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(toggleRefreshing(false))
        }, 500)
      })
  }
}

export const updateOrders = updated => {
  return (dispatch, getState) => {
    if (!updated.length) return
    const { orders } = getState().orderMgmt
    const updatedIds = updated.map(i => i.receipt_id)
    const other = orders.filter(i => !updatedIds.includes(i.receipt_id))
    const allOrders = [...other, ...updated]
    const sorting = { sortBy: 'requested_at', sortType: 'datetime' }
    const sorted = sortItems(allOrders, sorting)
    dispatch(loadOrders(sorted))
  }
}

export const fetchOrder = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    const endpoint = `/bulk-orders/${orderId}?expand=true&with_related=tenders`
    return request(token, brand, endpoint, 'GET')
      .then(order => {
        dispatch(updateOrders([order]))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(enableOrder(orderId)))
  }
}

export const disableOrder = orderId => {
  return (dispatch, getState) => {
    const { orders } = getState().orderMgmt
    const updatedOrders = orders.map(i => {
      if (i.receipt_id === orderId) i.disabled = true
      return i
    })
    dispatch(loadOrders(updatedOrders))
  }
}

export const enableOrder = orderId => {
  return (dispatch, getState) => {
    const { orders } = getState().orderMgmt
    const updatedOrders = orders.map(i => {
      if (i.receipt_id === orderId) delete i.disabled
      return i
    })
    dispatch(loadOrders(updatedOrders))
  }
}

export const markReviewed = orderId => {
  return (dispatch, getState) => {
    const { token, brand, orderMgmt } = getState()
    const { orders } = orderMgmt
    const updatedOrders = orders.map(i => {
      if (i.receipt_id === orderId) i.is_reviewed = true
      return i
    })
    request(token, brand, `/orders/${orderId}/mark-reviewed`, 'POST')
      .then(() => {
        dispatch(loadOrders(updatedOrders))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const confirmClose = orderId => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: `Confirm Close`,
      // title: `Order #${orderId} Confirm Close`,
      // subtitle: `Please make sure you're ready to close order #${orderId}`,
      type: 'orderClose',
      args: { orderId }
    }
    dispatch(openModal(config))
  }
}

export const confirmCancel = orderId => {
  return dispatch => {
    dispatch(loadingModal())
    const msg = 'Order cancelled successfully!'
    const cancel = () => dispatch(orderAction(orderId, 'cancel', msg, true))
    const config = {
      title: `Confirm Cancel`,
      type: 'orderCancel',
      args: { orderId, cancel }
    }
    dispatch(openModal(config))
  }
}

export const orderAction = (orderId, action, msg, update) => {
  return (dispatch, getState) => {
    if (action === 'confirmClose') return dispatch(confirmClose(orderId))
    if (action === 'confirmCancel') return dispatch(confirmCancel(orderId))
    if (['close', 'cancel'].includes(action)) dispatch(closeModal())
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    request(token, brand, `/orders/${orderId}/${action}`, 'POST')
      .then(() => {
        if (update) {
          dispatch(fetchOrder(orderId)).catch(err => {
            throw new Error(err.detail || err.message)
          })
        }
        if (msg) dispatch(flashMessage(msg))
      })
      .catch(err => {
        // if the order is being closed and we hit an error with a 3rd party
        // that doesn't prevent the order from being closed, fetch the order
        // so closed orders leave the page immediately vs. waiting for the
        // the next page refresh
        if (action === 'close') {
          dispatch(fetchOrder(orderId)).finally(() => {
            dispatch(handlePageError(err))
          })
        } else {
          dispatch(handlePageError(err))
        }
      })
      .finally(() => dispatch(enableOrder(orderId)))
  }
}

export const editOpenOrder = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    request(token, brand, `/orders/${orderId}`, 'GET')
      .then(order => dispatch(editOrder(order)))
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const showEditOptions = orderId => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'custom',
      type: 'orderEdit',
      args: { orderId }
    }
    dispatch(openModal(config))
  }
}

export const showUpdatePrepStatus = (orderId, currentStatus) => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'custom',
      type: 'updatePrepStatus',
      args: { orderId, currentStatus }
    }
    dispatch(openModal(config))
  }
}

export const updatePrepStatus = (orderId, newPrepStatus) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    const data = { prep_status: newPrepStatus }
    request(token, brand, `/orders/${orderId}/update-prep-status`, 'PUT', data)
      .then(() => {
        dispatch(fetchOrder(orderId)).catch(err => {
          throw new Error(err.detail || err.message)
        })
        dispatch(flashMessage('Prep status updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => {
        dispatch(enableOrder(orderId))
        dispatch(closeModal())
      })
  }
}

export const viewDetails = orderId => {
  return (dispatch, getState) => {
    const { token, brand, orderMgmt } = getState()
    dispatch(loadingModal())
    const order = getOrder(orderMgmt.orders, orderId)
    const printerType = order ? order.revenue_center.printer_type : 'DESKTOP'
    request(token, brand, `/orders/${orderId}`, 'GET')
      .then(order => {
        const config = {
          classes: 'modal--order',
          type: 'orderDetails',
          args: [order, printerType]
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const clearPrint = () => ({ type: ORDER_MGMT_PRINT_CLEAR })

export const laodPrintOrder = order => ({
  type: ORDER_MGMT_PRINT_LOAD,
  payload: order
})

export const printOrder = orderId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    request(token, brand, `/orders/${orderId}`, 'GET')
      .then(order => dispatch(laodPrintOrder(order)))
      .catch(err => dispatch(handlePageError(err)))
      .finally(() => dispatch(enableOrder(orderId)))
  }
}

export const laodPrintRouter = () => ({
  type: ORDER_MGMT_PRINT_ROUTER
})

export const printRouter = () => {
  return dispatch => {
    dispatch(laodPrintRouter())
  }
}

export const updateSearch = search => ({
  type: ORDER_MGMT_SEARCH_UPDATE,
  payload: search
})

export const clearSearch = () => ({
  type: ORDER_MGMT_SEARCH_CLEAR
})

export const findOrder = () => {
  return (dispatch, getState) => {
    const { token, brand, orderMgmt } = getState()
    const orderId = orderMgmt.search.query
    if (!orderId.length) return
    const endpoint = `/bulk-orders/${orderId}?expand=true`
    return request(token, brand, endpoint, 'GET')
      .then(order => {
        dispatch(updateSearch({ result: order, error: null }))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const msg =
          err.status >= 500
            ? 'Order not found. Please enter an order ID as an integer.'
            : processErrorMessage(err.detail || err.message)
        dispatch(updateSearch({ result: null, error: msg }))
      })
  }
}

export const previewRouter = () => {
  return (dispatch, getState) => {
    const { orderMgmt, item } = getState()
    const { orders, orderStatus, orderType, businessDate } = orderMgmt
    // const tz = timezoneMap[item.data.timezone]
    const store = item.data
    let filtered = orders.filter(i => orderStatus.includes(i.receipt_status))
    filtered = orderType.length
      ? filtered.filter(i => i.order_type === orderType)
      : filtered
    dispatch(loadingModal())
    const config = {
      classes: 'modal--order',
      type: 'orderRouter',
      args: { filtered, orderType, orderStatus, businessDate, store }
    }
    dispatch(openModal(config))
  }
}

export const updateHours = hours => ({ type: ORDER_MGMT_HOURS, payload: hours })

export const fetchHours = (rc, timezone) => {
  return (dispatch, getState) => {
    const { token, brand, options } = getState()
    const waitTimes = makeWaitTimes(
      options['delivery-wait-times'],
      options['pickup-wait-times']
    )
    const tempPickupId =
      rc.temporary_pickup_wait_time_id || rc.pickup_wait_time_id
    const tempDeliveryId =
      rc.temporary_delivery_wait_time_id || rc.delivery_wait_time_id
    const settings = {
      PICKUP: {
        defaultWaitId: rc.pickup_wait_time_id,
        defaultWait: waitTimes.PICKUP[rc.pickup_wait_time_id],
        currentWaitId: rc.temporary_pickup_wait_time_id,
        currentWait: waitTimes.PICKUP[tempPickupId]
      },
      DELIVERY: {
        defaultWaitId: rc.delivery_wait_time_id,
        defaultWait: waitTimes.DELIVERY[rc.delivery_wait_time_id],
        currentWaitId: rc.temporary_delivery_wait_time_id,
        currentWait: waitTimes.DELIVERY[tempDeliveryId]
      },
      status: { isClosed: rc.closed, isTempClosed: rc.is_temporarily_closed }
    }
    const tz = timezoneMap[timezone]
    const currentDate = makeZonedDate(tz)
    const weekday = format(currentDate, 'EEEE').toUpperCase()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const endpoint = `/hours?revenue_center_id=${rc.revenue_center_id}`
    const hoursRequest = request(token, brand, endpoint, 'GET')
    const throttleEndpoint = `/revenue-centers/${rc.revenue_center_id}/settings/throttle`
    const throttleRequest = request(token, brand, throttleEndpoint, 'GET')
    Promise.all([hoursRequest, throttleRequest])
      .then(([hoursResp, throttleResp]) => {
        const isThrottled = throttleResp ? throttleResp.data.is_active : false
        const regularHours = hoursResp.data.filter(i => i.weekday === weekday)
        const holidayHours = hoursResp.data.filter(
          i => i.month === month && i.day === day
        )
        const hours = holidayHours.length ? holidayHours : regularHours
        const lookup = hours.reduce((obj, i) => {
          obj[i.service_type] = {
            open: minutesToTime(i.start_min),
            close: minutesToTime(i.end_min)
          }
          return obj
        }, {})
        const displayHours = {
          PICKUP: {
            open: lookup.PICKUP ? lookup.PICKUP.open : null,
            close: lookup.PICKUP ? lookup.PICKUP.close : null,
            ...settings.PICKUP
          },
          DELIVERY: {
            open: lookup.DELIVERY ? lookup.DELIVERY.open : null,
            close: lookup.DELIVERY ? lookup.DELIVERY.close : null,
            ...settings.DELIVERY
          }
        }
        const storeHours = {
          revenueCenterId: rc.revenue_center_id,
          hours: displayHours,
          isThrottled,
          ...settings.status
        }
        dispatch(updateHours(storeHours))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const editWaitTimes = () => {
  return (dispatch, getState) => {
    dispatch(loadingModal())
    const { options, orderMgmt } = getState()
    const { hours, isTempClosed, isThrottled } = orderMgmt.hours
    const pickupWaitTimes = options['pickup-wait-times']
    const deliveryWaitTimes = options['delivery-wait-times']
    const pickupId =
      hours && hours.PICKUP
        ? hours.PICKUP.currentWaitId || hours.PICKUP.defaultWaitId
        : 0
    const deliveryId =
      hours && hours.DELIVERY
        ? hours.DELIVERY.currentWaitId || hours.DELIVERY.defaultWaitId
        : 0
    const values = {
      closed: isTempClosed,
      pickup_wait_time_id: pickupId,
      delivery_wait_time_id: deliveryId
    }
    const args = { pickupWaitTimes, deliveryWaitTimes, values, isThrottled }
    const config = {
      title: 'Update Wait Times',
      subtitle:
        'Update wait times or open / closed status for the OLO revenue center associated with this store',
      type: 'waitTimes',
      args: args
    }
    dispatch(openModal(config))
  }
}

export const updateWaitTimes = values => {
  return (dispatch, getState) => {
    const { token, brand, orderMgmt } = getState()
    const { revenueCenterId } = orderMgmt.hours
    const endpoint = `/revenue-centers/${revenueCenterId}/settings/overrides`
    return request(token, brand, endpoint, 'PUT', values)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchOrderMgmt())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const resetWaitTimes = () => {
  return (dispatch, getState) => {
    const { token, brand, orderMgmt } = getState()
    const { revenueCenterId } = orderMgmt.hours
    const endpoint = `/revenue-centers/${revenueCenterId}/settings/overrides`
    return request(token, brand, endpoint, 'DELETE')
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchOrderMgmt())
        dispatch(flashMessage('Successfully reset!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

const getOrder = (orders, orderId) => {
  return orders.find(i => parseInt(orderId) === i.receipt_id)
}

export const fetchBulkOrder = (orderId, action) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/bulk-orders/${orderId}?expand=true`
    request(token, brand, endpoint, 'GET')
      .then(order => dispatch(showOrderAction(order, action)))
      .catch(err => dispatch(handlePageError(err)))
  }
}

export const editOrderAction = (orderId, action) => {
  return (dispatch, getState) => {
    const { page, orderMgmt, orders } = getState()
    let order
    switch (page.type) {
      case 'orders':
        order = getOrderOrders(orders.items, orderId)
        break
      case 'receipt':
        return dispatch(fetchBulkOrder(orderId, action))
      default:
        order = getOrder(orderMgmt.orders, orderId)
    }
    dispatch(showOrderAction(order, action))
  }
}

export const showOrderClosed = order => {
  return dispatch => {
    const config = {
      title: 'custom',
      type: 'orderClosed',
      args: { order }
    }
    dispatch(openModal(config))
  }
}

export const showOrderAction = (order, action) => {
  return dispatch => {
    dispatch(closeModal())
    dispatch(loadingModal())
    setTimeout(() => {
      if (order.receipt_status !== 'OPEN') {
        return dispatch(showOrderClosed(order))
      }
      switch (action) {
        case 'revenueCenter':
          return dispatch(showEditRevenueCenter(order))
        case 'serviceType':
          return dispatch(showEditServiceType(order))
        case 'requestedAt':
          return dispatch(showEditRequestedAt(order))
        case 'prepTime':
          return dispatch(showEditPrepTime(order))
        case 'tip':
          return dispatch(showEditTip(order))
        case 'tax':
          return dispatch(showEditTax(order))
        case 'notesInternal':
          return dispatch(showEditNotesInternal(order))
        default:
          return dispatch(editOpenOrder(order.receipt_id))
      }
    }, 250)
  }
}

export const updateOrder = (orderId, data) => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    switch (getState().page.type) {
      case 'orders':
        return dispatch(updateOrderOrders(orderId, data))
      case 'receipt':
        return dispatch(updateReceipt(orderId, data))
      default:
        return dispatch(updateOrderManage(orderId, data))
    }
  }
}

export const updateOrderManage = (orderId, data) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(disableOrder(orderId))
    const endpoint = `/orders/${orderId}/edit`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        dispatch(fetchOrderMgmt())
        dispatch(flashMessage(`Order ${orderId} successfully updated!`))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(enableOrder(orderId)))
  }
}

export const showEditRevenueCenter = order => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const { order_type: orderType, customer_address: address } = order
    const orderId = order.receipt_id
    const rcType = orderTypeRevenueCenterMap[orderType]
    const endpoint = `/revenue-centers?revenue_center_type=${rcType}&with_related=delivery_zones&expand=store&with_settings=address`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const latLng = getLatLng(address)
        const revenueCenters = sortRevenueCenters(resp.data, latLng)
        const config = {
          title: 'Choose a new Revenue Center',
          classes: 'modal--big modal--wide modal--rc',
          type: 'editRevenueCenter',
          args: { orderId, revenueCenters }
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const editRevenueCenter = (orderId, revenueCenter) => {
  return dispatch => {
    const data = { revenue_center_id: revenueCenter.revenue_center_id }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditServiceType = order => {
  return dispatch => {
    const { service_type: serviceType } = order
    const orderId = order.receipt_id
    const config = {
      title: 'Change Service Type',
      type: 'editServiceType',
      args: { orderId, serviceType }
    }
    dispatch(openModal(config))
  }
}

export const editServiceType = (orderId, serviceType) => {
  return dispatch => {
    const data = { service_type: serviceType }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditRequestedAt = order => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const { service_type: serviceType, requested_at, timezone } = order
    const revenueCenterId = order.revenue_center.revenue_center_id
    const orderId = order.receipt_id
    const tz = timezoneMap[timezone]
    const requestedAt = zonedDateStrToDate(requested_at, tz)
    // TODO: this is copied from order.js - refactor to remove duplication
    const endpoint = `/revenue-centers/${revenueCenterId}/valid-times`
    return request(token, brand, endpoint, 'GET')
      .then(resp => {
        if (isEmpty(resp.first_times)) {
          throw new Error(errMessages.locationClosed)
        } else if (!resp.first_times[serviceType]) {
          throw new Error(errMessages.serviceTypeNotAvailable)
        }
        const validTimes = resp.valid_times[serviceType]
        const interval = resp.first_times[serviceType].interval
        const holidays = resp.holidays[serviceType].map(i => makeLocalDate(i))
        const weekdayTimes = makeWeekdaysExcluded(validTimes)
        const excludedTimes = resp.excluded_times[serviceType]
        const args = {
          orderId,
          requestedAt,
          tz,
          interval,
          holidays,
          weekdayTimes,
          excludedTimes
        }
        const config = {
          title: 'Change Requested Date and/or Time',
          type: 'editRequestedAt',
          args: args
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const editRequestedAt = (orderId, requestedAt) => {
  return dispatch => {
    const data = { requested_at: requestedAt }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditPrepTime = order => {
  return dispatch => {
    const { order_prep_time: prepTime, receipt_id: orderId } = order
    const config = {
      title: 'Adjust Order Prep Time',
      type: 'editPrepTime',
      args: { orderId, prepTime }
    }
    dispatch(openModal(config))
  }
}

export const editPrepTime = (orderId, prepTime) => {
  return dispatch => {
    const data = { order_prep_time: prepTime }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditTip = order => {
  return dispatch => {
    const { tip, receipt_id: orderId } = order
    const config = {
      title: 'Adjust Tip',
      subtitle: 'Please enter a new tip value and click submit',
      type: 'editTip',
      args: { orderId, tip }
    }
    dispatch(openModal(config))
  }
}

export const editTip = (orderId, tip) => {
  return dispatch => {
    const data = { tip: tip }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditTax = order => {
  return dispatch => {
    const { is_tax_exempt: isTaxExempt, tax_exempt_id: taxExemptId } = order
    const orderId = order.receipt_id
    const subtitle = isTaxExempt
      ? ''
      : 'Please enter a tax exempt Id and click the button below to make this order tax exempt'
    const config = {
      title: 'Adjust Tax Exempt Status',
      subtitle: subtitle,
      type: 'editTax',
      args: { orderId, isTaxExempt, taxExemptId }
    }
    dispatch(openModal(config))
  }
}

export const editTax = (orderId, taxExemptId) => {
  return dispatch => {
    const data = { is_tax_exempt: true, tax_exempt_id: taxExemptId }
    dispatch(updateOrder(orderId, data))
  }
}

export const showEditNotesInternal = order => {
  return dispatch => {
    const { notes_internal: notesInternal, receipt_id: orderId } = order
    const config = {
      title: 'Adjust Internal Notes',
      type: 'editNotesInternal',
      args: { orderId, notesInternal }
    }
    dispatch(openModal(config))
  }
}

export const editNotesInternal = (orderId, notesInternal) => {
  return dispatch => {
    const data = { notes_internal: notesInternal }
    dispatch(updateOrder(orderId, data))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ORDER_MGMT_CLEAR:
      return { ...initState }
    case ORDER_MGMT_CLEAR_ORDERS:
      return { ...state, orders: [], loading: true }
    case ORDER_MGMT_LOAD:
      return { ...state, orders: action.payload, loading: false }
    case ORDER_MGMT_REFRESH:
      return { ...state, refreshing: action.payload }
    case ORDER_MGMT_TYPE:
      return { ...state, orderType: action.payload }
    case ORDER_MGMT_STATUS:
      return { ...state, orderStatus: action.payload }
    case ORDER_MGMT_DISPLAY:
      return { ...state, displaySettings: action.payload }
    case ORDER_MGMT_UPDATED:
      return { ...state, lastUpdated: action.payload }
    case ORDER_MGMT_BUSINESS_DATE:
      return { ...state, businessDate: action.payload }
    case ORDER_MGMT_PRINT_LOAD:
      return { ...state, orderPrint: action.payload }
    case ORDER_MGMT_PRINT_ROUTER:
      return { ...state, orderRouter: true }
    case ORDER_MGMT_PRINT_CLEAR:
      return { ...state, orderPrint: null, orderRouter: false }
    case ORDER_MGMT_SEARCH_CLEAR:
      return { ...state, search: { query: '', result: null, error: null } }
    case ORDER_MGMT_SEARCH_UPDATE:
      return { ...state, search: { ...state.search, ...action.payload } }
    case ORDER_MGMT_HOURS:
      return { ...state, hours: action.payload }
    case ORDER_MGMT_MUTE:
      return { ...state, muted: action.payload }
    case ORDER_MGMT_MUTE_TOGGLE:
      return { ...state, muted: !state.muted }
    default:
      return state
  }
}
