import propTypes from 'prop-types'
import React, { Component, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { editTip } from '../../reducers/orderMgmt'
import { Input } from '../FormInputs'

const TipForm = ({ tip, submitTip, field }) => {
  const submitButton = useRef()
  const [value, setValue] = useState(tip)
  const [error, setError] = useState(null)

  const handleInput = evt => {
    setError(null)
    setValue(evt.target.value)
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    const newTip = parseFloat(value)
    const msg = 'Please enter a dollar amount'
    isNaN(newTip) ? setError(msg) : submitTip(newTip.toFixed(2))
    submitButton.current.blur()
  }

  return (
    <form id="form" onSubmit={handleSubmit}>
      <Input field={field} value={value} error={error} handler={handleInput} />
      <div className="form__submit">
        <button type="submit" className="btn" ref={submitButton}>
          Submit
        </button>
      </div>
    </form>
  )
}

TipForm.displayName = 'TipForm'
TipForm.propTypes = {
  tip: propTypes.string,
  field: propTypes.object,
  submitTip: propTypes.func
}

class EditTip extends Component {
  static propTypes = {
    orderId: propTypes.number,
    tip: propTypes.string,
    editTip: propTypes.func
  }

  handleSubmit = tip => {
    const { orderId } = this.props
    this.props.editTip(orderId, tip)
  }

  render() {
    const field = { label: '', field: 'tip', type: 'input' }
    return (
      <TipForm {...this.props} field={field} submitTip={this.handleSubmit} />
    )
  }
}

EditTip.displayName = 'EditTip'

export default connect(null, { editTip })(EditTip)
