/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import propTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeRelationsEntity } from '../lib/helpersPage'
import { RefreshCw } from 'react-feather'
import Button from './Button'

const ButtonResults = ({ type, text, endpoint, actions }) => {
  const handleClick = evt => {
    evt.preventDefault()
    actions(type)
    evt.target.blur()
  }
  return type === 'link' ? (
    <NavLink className="btn btn--secondary" exact to={endpoint}>
      {text}
    </NavLink>
  ) : (
    <button className="btn" onClick={handleClick}>
      {text}
    </button>
  )
}

ButtonResults.displayName = 'ButtonResults'
ButtonResults.propTypes = {
  type: propTypes.string,
  text: propTypes.string,
  filename: propTypes.string,
  endpoint: propTypes.string,
  actions: propTypes.func
}

export const TableResults = ({
  items,
  links = {},
  endpoint,
  newEndpoint,
  next,
  reset,
  hasFilters,
  hideNew,
  buttonText,
  actions,
  listActions,
  refreshItems
}) => {
  const handleRefresh = evt => {
    evt.preventDefault()
    refreshItems()
    evt.target.blur()
  }
  return (
    <div className="table__results">
      <div className="table__results__message">
        <span className="table__results__refresh">
          <button className="btn-link" onClick={handleRefresh}>
            <RefreshCw size={16} />
          </button>
        </span>
        <p>
          Displaying {items.length} results. &nbsp;
          {links.next || hasFilters ? (
            <span className="table__results__next">
              {links.next ? (
                <a href={links.next} onClick={next}>
                  See next results
                </a>
              ) : null}
              {links.next && hasFilters ? <span> or </span> : null}
              {hasFilters ? (
                <button className="btn-link" onClick={reset}>
                  {`${links.next ? 'clear' : 'Clear'}`} all filters
                </button>
              ) : null}
              .
            </span>
          ) : null}
        </p>
      </div>
      <div className="table__results__buttons">
        {actions
          ? actions.map(action =>
              action.type ? (
                <ButtonResults
                  key={action.text}
                  {...action}
                  actions={listActions}
                />
              ) : (
                <Button key={action.text} {...action} />
              )
            )
          : null}
        {!hideNew && (
          <NavLink
            className="btn table__new"
            exact
            to={`${newEndpoint || endpoint}/new`}
          >
            {buttonText || 'Add New'}
          </NavLink>
        )}
      </div>
    </div>
  )
}

TableResults.displayName = 'TableResults'
TableResults.propTypes = {
  user: propTypes.object,
  items: propTypes.array,
  links: propTypes.object,
  endpoint: propTypes.string,
  newEndpoint: propTypes.string,
  next: propTypes.func,
  reset: propTypes.func,
  hasFilters: propTypes.bool,
  buttonText: propTypes.string,
  listActions: propTypes.func,
  hideNew: propTypes.bool,
  actions: propTypes.array,
  refreshItems: propTypes.func
}

export const TableResultsSimple = ({ items, endpoint, search }) => {
  const entity = makeRelationsEntity(endpoint).replace('-', ' ')
  const handleClick = evt => {
    evt.preventDefault()
    search.func(search)
  }
  return (
    <div className="table__results">
      {items ? (
        items.length ? (
          <p>
            Displaying {items.length} {entity}.
          </p>
        ) : (
          <p>No {entity} found.</p>
        )
      ) : (
        <p>Loading...</p>
      )}
      {search ? (
        <button className="btn table__new" onClick={handleClick}>
          Add New
        </button>
      ) : (
        <NavLink className="btn table__new" exact to={`${endpoint}/new`}>
          Add New
        </NavLink>
      )}
    </div>
  )
}

TableResultsSimple.displayName = 'TableResultsSimple'
TableResultsSimple.propTypes = {
  items: propTypes.array,
  endpoint: propTypes.string,
  id: propTypes.string,
  search: propTypes.object
}
