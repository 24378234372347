import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Form from '../Form'
import { PageError } from '../PageError'
import { upsertItem } from '../../reducers/listOfItems'
import { isEmpty } from '../../lib/utils'

class ListOfItemsEdit extends Component {
  static propTypes = {
    windowRef: propTypes.shape({ current: propTypes.any }),
    index: propTypes.number,
    fields: propTypes.array,
    item: propTypes.object,
    errors: propTypes.object,
    upsertItem: propTypes.func
  }

  render() {
    const { windowRef, fields, index, item, upsertItem, errors } = this.props
    if (!isEmpty(errors) && windowRef.current) windowRef.current.scrollTop = 0
    const withIndex = [...fields, { field: 'index', type: 'hidden' }]
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={index ? false : true}
          fields={withIndex}
          data={item || {}}
          errors={errors || {}}
          upsertItem={upsertItem}
          windowRef={windowRef}
        />
      </>
    )
  }
}

ListOfItemsEdit.displayName = 'ListOfItemsEdit'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { upsertItem }
)(ListOfItemsEdit)
