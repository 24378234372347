import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import { Label, Error } from './FormInputs'
import ItemSelection from './ItemSelection'
import {
  updateMenu,
  updateField,
  updateDiscountItems,
  removeDiscountItems
} from '../reducers/discountItems'

export const SelectMenu = ({ menu_id, menus, handler, error }) => {
  const options = menus
    ? menus.map(i => ({ name: i.name, value: i.menu_id }))
    : null
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor="menu_id" className={`label select`}>
      <Label label="Menu" />
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select id="menu_id" value={menu_id} onChange={handler}>
        {options ? (
          options.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.name}
            </option>
          ))
        ) : (
          <option>loading...</option>
        )}
      </select>
      <span className="select__arrow" />
      {error ? <Error error={error} /> : null}
    </label>
  )
}

SelectMenu.displayName = 'SelectMenu'
SelectMenu.propTypes = {
  menu_id: propTypes.number,
  menus: propTypes.array,
  handler: propTypes.func,
  error: propTypes.string
}

const Input = ({ label, field, value, error, handler }) => {
  return (
    <label htmlFor={field} className={`label`}>
      <Label label={label} />
      <input
        id={field}
        type="text"
        value={value}
        placeholder="0"
        onChange={handler}
      />
      {error ? <Error error={error} /> : null}
    </label>
  )
}

Input.displayName = 'Input'
Input.propTypes = {
  label: propTypes.string,
  field: propTypes.string,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  error: propTypes.string,
  handler: propTypes.func
}

const makeQuanity = quantity => {
  if (quantity === 0) return quantity
  return quantity || ''
}

class DiscountItems extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
    this.deleteButton = React.createRef()
  }

  static propTypes = {
    // mapStateToProps
    loading: propTypes.bool,
    menu_id: propTypes.number,
    discounted_quantity: propTypes.oneOfType([
      propTypes.number,
      propTypes.string
    ]),
    discounted_items: propTypes.array,
    required_quantity: propTypes.oneOfType([
      propTypes.number,
      propTypes.string
    ]),
    required_items: propTypes.array,
    menus: propTypes.array,
    menu: propTypes.array,
    data: propTypes.object,
    errors: propTypes.object,
    // mapDispatchToProps
    updateMenu: propTypes.func,
    updateField: propTypes.func,
    updateDiscountItems: propTypes.func,
    removeDiscountItems: propTypes.func
  }

  handleMenuChange = evt => {
    this.props.updateMenu(parseInt(evt.target.value))
    evt.target.blur()
  }

  handleQuantity = evt => {
    const { id, value } = evt.target
    const quantity = isNaN(parseInt(value)) ? '' : parseInt(value)
    this.props.updateField(id, quantity)
  }

  handleSelectedItems = evt => {
    const values = [...evt.target.options]
      .filter(i => i.selected)
      .map(i => {
        const [categoryId, itemId] = i.value.split('-').map(i => parseInt(i))
        return { category_id: categoryId, item_id: itemId }
      })
    this.props.updateField(evt.target.id, values)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateDiscountItems()
    this.submitButton.current.blur()
  }

  handleDelete = evt => {
    evt.preventDefault()
    this.props.removeDiscountItems()
    this.deleteButton.current.blur()
  }

  render() {
    const { loading, menu, data, errors } = this.props
    const { fields = {} } = errors
    return (
      <div className="discount-items">
        {loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!loading && data ? (
            <CSSTransition {...cssTransitionFade}>
              <form id="form" className="form" onSubmit={this.handleSubmit}>
                {/* <SelectMenu
                  menu_id={menu_id}
                  menus={menus}
                  handler={this.handleMenuChange}
                  error={fields.menu_id}
                /> */}
                <div className="discount-items__content">
                  <div className="discount-items__left">
                    <Input
                      label="Discounted Quantity"
                      field="discounted_quantity"
                      value={makeQuanity(this.props.discounted_quantity)}
                      error={fields.discounted_quantity}
                      handler={this.handleQuantity}
                    />
                    <ItemSelection
                      label="Discounted Items"
                      field="discounted_items"
                      menu={menu}
                      selected={this.props.discounted_items}
                      handler={this.handleSelectedItems}
                      error={fields.discounted_items}
                      size="20"
                    />
                  </div>
                  <div className="discount-items__right">
                    <Input
                      label="Required Quantity"
                      field="required_quantity"
                      value={makeQuanity(this.props.required_quantity)}
                      error={fields.required_quantity}
                      handler={this.handleQuantity}
                    />
                    <ItemSelection
                      label="Required Items"
                      field="required_items"
                      menu={menu}
                      selected={this.props.required_items}
                      handler={this.handleSelectedItems}
                      error={fields.required_items}
                      size="20"
                    />
                  </div>
                </div>
                <div className="form__submit">
                  <input
                    className="btn"
                    type="submit"
                    value="Update"
                    ref={this.submitButton}
                  />
                  <button
                    className="btn btn--secondary"
                    onClick={this.handleDelete}
                    ref={this.deleteButton}
                  >
                    Delete
                  </button>
                </div>
              </form>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

DiscountItems.displayName = 'DiscountItems'

export default connect(
  state => ({
    ...state.discountItems
  }),
  { updateMenu, updateField, updateDiscountItems, removeDiscountItems }
)(DiscountItems)
