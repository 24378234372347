import propTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { isObject } from '../lib/utils'
import { ArrowLeft } from 'react-feather'
// import ClipLoader from 'react-spinners/ClipLoader'

const PageHeader = ({ title, subtitle }) => {
  return (
    <div className="content__header">
      {isObject(title) ? (
        <div className="content__header__title">
          {title.listLink ? (
            <>
              <div className="back-link">
                <Link to={title.listLink}>
                  <ArrowLeft size={18} />
                  <span>Back to all {title.listTitle}</span>
                </Link>
              </div>
              <h1>{title.itemTitle}</h1>
            </>
          ) : (
            <>
              <div className="back-link">
                <Link to={title.itemLink}>
                  <ArrowLeft size={18} />
                  <span>
                    Back to all {title.relationsTitle} for {title.itemTitle}
                  </span>
                </Link>
              </div>
              <h1>{title.relationTitle}</h1>
            </>
          )}
        </div>
      ) : title && title.length ? (
        <h1>{title}</h1>
      ) : (
        <h1>&nbsp;</h1>
      )}
      {subtitle.length ? <p>{subtitle}</p> : null}
    </div>
  )
}

PageHeader.displayName = 'PageHeader'
PageHeader.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  subtitle: propTypes.string
}

export default PageHeader
