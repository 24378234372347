import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editOpenOrder } from '../../reducers/orderMgmt'

class OrderEditFull extends Component {
  static propTypes = {
    order: propTypes.object,
    editOpenOrder: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    this.props.editOpenOrder(this.props.order.receipt_id)
    evt.target.blur()
  }

  render() {
    const { order } = this.props
    const isEditable =
      order.receipt_status === 'OPEN' || order.receipt_status === 'HELD'
    return isEditable ? (
      <button className="btn-link" onClick={this.handleClick}>
        edit
      </button>
    ) : (
      <span>--</span>
    )
  }
}

OrderEditFull.displayName = 'OrderEditFull'

export default connect(null, {
  editOpenOrder
})(OrderEditFull)
