import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import { makeDateRangeDays } from '../lib/helpersDatetime'
import {
  updateFilter,
  resetFilters,
  updateReset,
  updateCount,
  updateInventory
} from '../reducers/inventory'
import { PageError } from './PageError'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'
import { CheckboxSimple } from './FormInputs'
import { isEmpty } from '../lib/utils'
import { errMessages } from '../lib/errors'

const makeCountMap = counts => {
  if (!counts) return {}
  return counts.reduce((obj, i) => {
    obj[i.item_id] = i
    return obj
  }, {})
}

const makeForecastMap = forecast => {
  if (!forecast) return {}
  return forecast.reduce((obj, i) => {
    obj[`${i.item_id}-${i.business_date}`] = i.day_remaining
    return obj
  }, {})
}

class Inventory extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    // state.page.dailyItems
    filters: propTypes.array,
    // state.dailyItems
    loading: propTypes.bool,
    items: propTypes.array,
    counts: propTypes.array,
    forecast: propTypes.array,
    disabled: propTypes.array,
    errors: propTypes.object,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    updateReset: propTypes.func,
    updateCount: propTypes.func,
    updateInventory: propTypes.func
  }

  handleFilter = evt => {
    const { id, value } = evt.target
    const filter = { field: id, value: value }
    this.props.updateFilter(filter)
    evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.resetFilters()
    evt.target.blur()
  }

  handleCheckbox = evt => {
    const { id, checked } = evt.target
    const itemId = parseInt(id.split('-')[1])
    this.props.updateReset(itemId, checked)
    evt.target.blur()
  }

  handleInput = evt => {
    const { id, value } = evt.target
    const itemId = parseInt(id.split('-')[1])
    this.props.updateCount(itemId, value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateInventory()
    this.submitButton.current.blur()
  }

  render() {
    const {
      filters,
      loading,
      items,
      counts,
      forecast,
      disabled,
      errors
    } = this.props
    const countMap = makeCountMap(counts)
    const forecastMap = makeForecastMap(forecast)
    const dates = makeDateRangeDays(6)
    const errMsg = !isEmpty(errors) ? errMessages.errorsBelow : ''
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        <div className="table__results">
          {items ? (
            <p>
              <button className="btn-link" onClick={this.handleResetFilters}>
                Clear all filters.
              </button>
            </p>
          ) : loading ? null : (
            <p className="content__note -alert">
              Please select either a category or modifier group and an attribute
              type.
            </p>
          )}
        </div>
        <PageError msg={errMsg} />
        {loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        {items && !items.length ? (
          <p>
            There {"aren't"} any items in this group. Please adjust your
            filters.
          </p>
        ) : null}
        <TransitionGroup>
          {items && items.length ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="table__wrapper inventory">
                <form id="form" className="form" onSubmit={this.handleSubmit}>
                  <div className="table-scroll">
                    <table className="table--inventory table--light">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Item ID</th>
                          <th>Resets Daily</th>

                          <th>Count</th>
                          {dates.map(d => (
                            <th key={d}>{d}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {items.map(i => {
                          const count = countMap[i.item_id] || {}
                          const reset =
                            count.reset_daily !== undefined
                              ? count.reset_daily
                              : true
                          return (
                            <tr key={i.item_id}>
                              <td>{i.short_name}</td>
                              <td>{i.item_id}</td>
                              <td>
                                {disabled.includes(i.item_id) ? (
                                  'n/a'
                                ) : (
                                  <CheckboxSimple
                                    id={`reset-${i.item_id}`}
                                    checked={reset}
                                    disabled={disabled.includes(i.item_id)}
                                    handler={this.handleCheckbox}
                                  />
                                )}
                              </td>
                              <td>
                                {disabled.includes(i.item_id) ? (
                                  'n/a'
                                ) : (
                                  <label
                                    htmlFor={`count-${i.item_id}`}
                                    className={`label ${
                                      errors[`${i.item_id}`] ? '-error' : ''
                                    }`}
                                  >
                                    <input
                                      id={`count-${i.item_id}`}
                                      type="text"
                                      value={count.starting_quantity}
                                      placeholder="0"
                                      disabled={disabled.includes(i.item_id)}
                                      onChange={this.handleInput}
                                    />
                                  </label>
                                )}
                              </td>
                              {dates.map(d => {
                                const key = `${i.item_id}-${d}`
                                return (
                                  <td key={key}>
                                    {forecastMap[key] === 0
                                      ? 'Sold Out'
                                      : forecastMap[key] || 'n/a'}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value="Update Inventory"
                      ref={this.submitButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Inventory.displayName = 'Inventory'

export default connect(
  state => ({
    ...state.page.inventory,
    ...state.inventory
  }),
  {
    updateFilter,
    resetFilters,
    updateReset,
    updateCount,
    updateInventory
  }
)(Inventory)
