import { request } from '../lib/services'
import { handlePageError, clearPageError } from './page'
import { isString } from '../lib/utils'
import { flashMessage } from './messages'
import { sortItems } from '../lib/helpers'

const initState = {
  loading: false,
  submitting: false,
  revenueCenterType: 'OLO',
  revenueCenterId: null,
  items: [],
  extItems: [],
  mappings: [],
  mappedMenus: [],
  revenueCenters: [],
  toUpdate: [],
  missing: [],
  businessDate: null,
  importResults: null,
  importErrors: null,
  errorDetail: null,
  menuUpdates: [],
  menuExtPayload: null,
}

export const MAPPING_CLEAR = 'MAPPING_CLEAR'
export const MAPPING_LOADING = 'MAPPING_LOADING'
export const MAPPING_LOAD = 'MAPPING_LOAD'
export const MAPPING_UPDATE = 'MAPPING_UPDATE'
export const MAPPING_ERRORS = 'MAPPING_ERRORS'
export const MAPPING_ERROR_DETAIL = 'MAPPING_ERROR_DETAIL'
export const MAPPING_MENU_UPDATE = 'MAPPING_MENU_UPDATE'

export const clearMapping = () => ({ type: MAPPING_CLEAR })
export const loadingMapping = () => ({ type: MAPPING_LOADING })
export const loadMapping = (data) => ({ type: MAPPING_LOAD, payload: data })
export const updateMapping = (data) => ({ type: MAPPING_UPDATE, payload: data })

export const updateRevenueCenterType = (revenueCenterType) => {
  return (dispatch, getState) => {
    const update = {
      revenueCenterType,
      items: [],
      extItems: [],
      mappings: [],
      revenueCenterId: null,
    }
    dispatch(updateMapping(update))
    const { page } = getState()
    if (page.menuExtUpdate) dispatch(fetchMissing())
  }
}

export const updateRevenueCenter = (revenueCenterId) => {
  return (dispatch, getState) => {
    const { page } = getState()
    dispatch(
      updateMapping({ revenueCenterId, loading: false, submitting: false })
    )
    if (page.menuExt) {
      dispatch(fetchMappedMenus())
    } else if (page.mapping) {
      dispatch(fetchMapping())
    } else if (page.menuExtUpdates) {
      dispatch(fetchMenuUpdates())
    }
  }
}

export const fetchMappedMenus = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const { token, brand, mapping } = getState()
    let { revenueCenterId } = mapping
    if (!revenueCenterId) return
    const endpoint = `/revenue-centers/${revenueCenterId}/settings/external-pos`
    request(token, brand, endpoint, 'GET')
      .then((resp) => {
        const mappedMenus = resp.data
          ? resp.data.pos_user_id.replace(/\s+/g, '').split(',')
          : []
        dispatch(updateMapping({ mappedMenus }))
      })
      .catch((err) => dispatch(handlePageError(err)))
      .finally(() => dispatch(updateMapping({ loading: false })))
  }
}

export const fetchMapping = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const { token, brand, page, mapping } = getState()
    const { name, field, endpoint, extEndpoint, mappingEndpoint, isGlobal } =
      page.mapping
    let { revenueCenterId } = mapping
    if (isGlobal) revenueCenterId = 0
    if (!revenueCenterId && !isGlobal) return
    dispatch(loadingMapping())
    const itemsReq = isString(endpoint)
      ? request(token, brand, endpoint, 'GET')
      : new Promise((resolve) => resolve(endpoint))
    let extItemsReq
    if (isString(extEndpoint)) {
      const extEnd = extEndpoint.replace(':id', revenueCenterId)
      extItemsReq = request(token, brand, extEnd, 'GET')
    } else {
      extItemsReq = new Promise((resolve) => resolve(extEndpoint))
    }
    const mappingEnd = mappingEndpoint.replace(':id', revenueCenterId)
    const mappingReq = request(token, brand, mappingEnd, 'GET')
    Promise.all([itemsReq, extItemsReq, mappingReq])
      .then(([resp, extItems, mappings]) => {
        const items = (resp.data || resp).map((i) => ({
          name: i[name],
          entity_id: i[field],
        }))
        dispatch(loadMapping({ items, extItems, mappings }))
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(updateMapping({ loading: false })))
  }
}

export const fetchMissing = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(loadingMapping())
    const { token, brand, page, mapping } = getState()
    const { revenueCenterType } = mapping
    const { endpoint } = page.menuExtUpdate
    const rcEndpoint = `/revenue-centers?revenue_center_type=${revenueCenterType}&with_related=menus&expand=store`
    const rcRequest = request(token, brand, rcEndpoint, 'GET')
    const missingRequest = request(token, brand, endpoint, 'GET')
    Promise.all([rcRequest, missingRequest])
      .then(([resp, missingMappings]) => {
        let revenueCenters = resp.data
          .filter((i) => !i.store.is_master)
          .map((i) => ({
            type: 'checkbox',
            id: i.revenue_center_id,
            label: i.full_name,
            menus: i.menus.map((m) => m.menu.name).join(', '),
            field: `rc-${i.revenue_center_id}`,
          }))
        const sorting = { sortBy: 'label', sortType: 'alpha' }
        revenueCenters = sortItems(revenueCenters, sorting)
        let missing = Object.entries(missingMappings)
          .filter(([, m]) => m.revenue_center_type === revenueCenterType)
          .map(([r, m]) => {
            return { revenueCenter: r, items: m.items, options: m.options }
          })
        const sortMissing = { sortBy: 'revenueCenter', sortType: 'alpha' }
        missing = sortItems(missing, sortMissing)
        dispatch(
          updateMapping({
            revenueCenters,
            missing,
            toUpdate: [],
            submitting: false,
          })
        )
      })
      .catch((err) => dispatch(handlePageError(err)))
      .finally(() => dispatch(updateMapping({ loading: false })))
  }
}

export const updateMappings = (entity_id, pos_ext_id) => {
  return (dispatch, getState) => {
    const { mapping } = getState()
    const other = mapping.mappings.filter((i) => i.entity_id !== entity_id)
    const mappings = pos_ext_id.length
      ? [...other, { entity_id, pos_ext_id }]
      : other
    dispatch(updateMapping({ mappings }))
  }
}

export const upsertMappings = () => {
  return (dispatch, getState) => {
    dispatch(updateMapping({ submitting: true }))
    const { token, brand, page, mapping } = getState()
    const { mappingEndpoint } = page.mapping
    const { mappings, revenueCenterId } = mapping
    const endpoint = mappingEndpoint.replace(':id', revenueCenterId)
    request(token, brand, endpoint, 'PUT', mappings)
      .then(() => {
        dispatch(flashMessage('Successfully updated!'))
        dispatch(clearPageError())
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() => {
        dispatch(updateMapping({ submitting: false }))
        window.scroll(0, 0)
      })
  }
}

export const selectRevenueCenter = (revenueCenterId) => {
  return (dispatch, getState) => {
    const { toUpdate } = getState().mapping
    const newToUpdate = toUpdate.includes(revenueCenterId)
      ? toUpdate.filter((i) => i !== revenueCenterId)
      : [...toUpdate, revenueCenterId]
    dispatch(updateMapping({ toUpdate: newToUpdate }))
  }
}

export const updateMenuMappings = () => {
  return (dispatch, getState) => {
    dispatch(updateMapping({ submitting: true }))
    const { token, brand, page, mapping } = getState()
    const { updateEndpoint: endpoint } = page.menuExtUpdate
    const revenueCenterIds = mapping.toUpdate
    request(token, brand, endpoint, 'POST', revenueCenterIds)
      .then(() => {
        dispatch(flashMessage('Successfully updated!'))
        dispatch(clearPageError())
        dispatch(fetchMissing())
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() =>
        dispatch(updateMapping({ submitting: false, toUpdate: [] }))
      )
  }
}

export const updateBusinessDate = (businessDate) => {
  return (dispatch) => {
    dispatch(updateMapping({ businessDate, importResults: null }))
  }
}

export const importOrders = () => {
  return (dispatch, getState) => {
    dispatch(updateMapping({ submitting: true, importErrors: null }))
    const { token, brand, page, mapping } = getState()
    const { endpoint } = page.import
    const { revenueCenterId, businessDate } = mapping
    const data = {
      business_date: businessDate,
      revenue_center_id: revenueCenterId,
    }
    request(token, brand, endpoint, 'POST', data)
      .then((importResults) => {
        dispatch(flashMessage('Successfully imported'))
        dispatch(clearPageError())
        dispatch(updateMapping({ importResults }))
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(updateMapping({ submitting: false })))
  }
}

export const retrieveErrors = () => {
  return (dispatch, getState) => {
    dispatch(updateMapping({ loading: true, importResults: null }))
    const { token, brand, page, mapping } = getState()
    const { endpoint } = page.importErrors
    const { revenueCenterId, businessDate } = mapping
    const endpt = `${endpoint}?revenue_center_id=${revenueCenterId}&business_date=${businessDate}`
    request(token, brand, endpt, 'GET')
      .then((resp) => {
        const importErrors = resp.map((i) => {
          const errMsg = i.errors.length ? i.errors[0].message : ''
          return { ...i, errMsg }
        })
        dispatch(flashMessage('Successfully retrieved!'))
        dispatch(clearPageError())
        dispatch(updateMapping({ importErrors }))
      })
      .catch((err) => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(updateMapping({ loading: false })))
  }
}

export const retrieveError = (field, itemId) => {
  return (dispatch, getState) => {
    const { mapping } = getState()
    const error = mapping.importErrors.filter((i) => i[field] === itemId)[0]
    dispatch({ type: MAPPING_ERROR_DETAIL, payload: error })
  }
}

export const fetchMenuUpdates = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const { token, brand, mapping } = getState()
    let { revenueCenterId } = mapping
    if (!revenueCenterId) return
    const endpoint = `/external-menus?revenue_center_id=${revenueCenterId}&expand=user`
    request(token, brand, endpoint, 'GET')
      .then((resp) => {
        const items = resp.data.reduce((arr, i) => {
          delete i.menu
          return [...arr, i]
        }, [])
        const sorting = {
          sortBy: 'created_at',
          sortType: 'datetime',
          desc: true,
        }
        const menuUpdates = sortItems(items, sorting)
        dispatch(updateMapping({ menuUpdates }))
      })
      .catch((err) => dispatch(handlePageError(err)))
      .finally(() => dispatch(updateMapping({ loading: false })))
  }
}

export const retrieveMenuExtPayload = (field, menuExtId) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/external-menus/${menuExtId}`
    request(token, brand, endpoint, 'GET')
      .then((resp) => {
        dispatch({ type: MAPPING_MENU_UPDATE, payload: resp })
      })
      .catch((err) => dispatch(handlePageError(err)))
  }
}

// export const retrieveMenuExtPayload = (field, itemId) => {
//   return (dispatch, getState) => {
//     const { mapping } = getState()
//     const payload = mapping.menuUpdates.filter(i => i[field] === itemId)[0]
//     dispatch({ type: MAPPING_MENU_UPDATE, payload: payload })
//   }
// }

export default (state = initState, action) => {
  switch (action.type) {
    case MAPPING_CLEAR:
      return { ...initState }
    case MAPPING_LOADING:
      return { ...state, loading: true }
    case MAPPING_LOAD:
      return { ...state, ...action.payload, loading: false, errors: {} }
    case MAPPING_UPDATE:
      return { ...state, ...action.payload }
    case MAPPING_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    case MAPPING_ERROR_DETAIL:
      return { ...state, errorDetail: action.payload }
    case MAPPING_MENU_UPDATE:
      return { ...state, menuExtPayload: action.payload }
    default:
      return state
  }
}
