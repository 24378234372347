import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from '../lib/utils'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  upsertItem,
  deleteItem,
  replaceItem,
  showItemErrors
} from '../reducers/item'
import { showPageError } from '../reducers/page'
import { makeStateOptions, cssTransitionFade } from '../lib/helpersPage'
import Form from './Form'
import ClipLoader from 'react-spinners/ClipLoader'
import Button from './Button'
import withGecoder from './withGeoder'
import ItemCategories from './ItemCategories'

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
//   whyDidYouRender(React)
// }

class Item extends Component {
  // static whyDidYouRender = true
  static propTypes = {
    // state.page.item
    endpoint: propTypes.string,
    setting: propTypes.string,
    id: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    validation: propTypes.object,
    actions: propTypes.array,
    hasGeocoder: propTypes.bool,
    isReplace: propTypes.bool,
    related: propTypes.string,
    // state.item
    data: propTypes.object,
    loading: propTypes.bool,
    errors: propTypes.object,
    // state.page.id
    pageId: propTypes.string,
    // state.page.options
    options: propTypes.array,
    // state.options
    stateOptions: propTypes.object,
    // mapdispatchtoprops
    upsertItem: propTypes.func,
    deleteItem: propTypes.func,
    replaceItem: propTypes.func,
    showItemErrors: propTypes.func,
    showPageError: propTypes.func
  }

  render() {
    const { data, id } = this.props
    const options = this.props.options
      ? makeStateOptions(this.props.options, this.props.stateOptions)
      : null
    const { endpoint, pageId, setting, actions } = this.props
    const itemEndpoint = setting ? `${endpoint}/${pageId}/${setting}` : endpoint
    const isNew = isEmpty(this.props.data)
    const ItemForm = this.props.hasGeocoder ? withGecoder(Form) : Form
    if (isNew) window.scrollTo(0, 0)
    const upsertFunc =
      this.props.isReplace && !isNew
        ? this.props.replaceItem
        : this.props.upsertItem
    return (
      <div className="content__main">
        {this.props.loading && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {!this.props.loading && this.props.data ? (
            <CSSTransition {...cssTransitionFade}>
              <div>
                {data.categories && (
                  <ItemCategories
                    categories={data.categories}
                    itemId={data[id]}
                  />
                )}
                {data.modifier_groups && (
                  <ItemCategories
                    categories={data.modifier_groups}
                    itemId={data[id]}
                    isMod={true}
                  />
                )}
                <ItemForm
                  isNew={isNew}
                  endpoint={itemEndpoint}
                  idField={this.props.id}
                  fields={this.props.fields}
                  data={this.props.data}
                  errors={this.props.errors}
                  options={options}
                  actions={actions}
                  upsertItem={upsertFunc}
                  deleteItem={this.props.deleteItem}
                  hasDelete={this.props.hasDelete}
                  validation={this.props.validation}
                  showErrors={this.props.showItemErrors}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
        {!isNew && actions && actions.length ? (
          <div className="content__actions">
            {actions.map(action => (
              <Button key={action.endpoint} {...action} />
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

Item.displayName = 'Item'

export default connect(
  state => ({
    ...state.page.item,
    ...state.item,
    pageId: state.page.id,
    options: state.page.options,
    stateOptions: state.options
  }),
  { upsertItem, deleteItem, replaceItem, showItemErrors, showPageError }
)(Item)
