import propTypes from 'prop-types'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import TableCell from './TableCell'
import TableCellLabel from './TableCellLabel'
import { Menu } from 'react-feather'

export const makeFlags = (item, fields) => {
  const fieldNames = fields.map(field => field.field[0])
  const activeFlag = !item.hasOwnProperty('is_active')
    ? ''
    : item.is_active
    ? '-active'
    : '-inactive'
  const refundedFlag = !fieldNames.includes('receipt_type')
    ? ''
    : item.receipt_type === 'REFUND'
    ? '-refund'
    : ''
  return `${activeFlag} ${refundedFlag}`
}

export const TableRow = ({
  index,
  item,
  fields,
  endpoint,
  id,
  handler,
  pathname
}) => {
  const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
  const flags = makeFlags(item, fields)
  return (
    <tr className={`${evenOdd} ${flags}`}>
      {fields.map((field, fieldIndex) => (
        <td
          key={`${item[id]}-${field.field.join('-')}-${fieldIndex}`}
          className={field.classes}
        >
          <TableCellLabel {...field} />
          <TableCell
            item={item}
            endpoint={endpoint}
            pathname={pathname}
            handler={handler}
            {...field}
          />
        </td>
      ))}
    </tr>
  )
}

TableRow.displayName = 'TableRow'
TableRow.propTypes = {
  index: propTypes.number,
  item: propTypes.object,
  fields: propTypes.array,
  endpoint: propTypes.string,
  id: propTypes.string,
  handler: propTypes.func,
  pathname: propTypes.string
}

export const TableRowDraggable = ({
  index,
  item,
  fields,
  endpoint,
  id,
  handler,
  pathname
}) => {
  return item[id] ? (
    <Draggable
      key={`${item[id]}-${index}`}
      draggableId={`${item[id]}`}
      index={index}
    >
      {(provided, snapshot) => {
        const dragging = snapshot.isDragging ? '-dragging' : ''
        const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
        const flags = makeFlags(item, fields)
        return (
          <tr
            className={`${evenOdd} ${dragging} ${flags}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {fields.map((field, fieldIndex) => (
              <td
                key={`${item[id]}-${field.field.join('-')}-${fieldIndex}`}
                className={field.classes}
              >
                <TableCellLabel {...field} />
                <TableCell
                  item={item}
                  endpoint={endpoint}
                  pathname={pathname}
                  handler={handler}
                  {...field}
                />
              </td>
            ))}
            <td className="gripper">
              <Menu size={14} />
            </td>
          </tr>
        )
      }}
    </Draggable>
  ) : null
}

TableRowDraggable.displayName = 'TableRowDraggable'
TableRowDraggable.propTypes = {
  index: propTypes.number,
  item: propTypes.object,
  fields: propTypes.array,
  endpoint: propTypes.string,
  id: propTypes.string,
  handler: propTypes.func,
  pathname: propTypes.string
}
