import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isEmpty } from '../lib/utils'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  upsertSetting,
  deleteSetting,
  showSettingErrors
} from '../reducers/setting'
import { makeStateOptions, cssTransitionFade } from '../lib/helpersPage'
import Form from './Form'
import ClipLoader from 'react-spinners/ClipLoader'
import { PageError } from './PageError'
import withGecoder from './withGeoder'

class Setting extends Component {
  static propTypes = {
    // from props
    header: propTypes.object,
    // from page.setting state object
    endpoint: propTypes.string,
    id: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    hasGeocoder: propTypes.bool,
    validation: propTypes.object,
    // from setting state object
    data: propTypes.object,
    errors: propTypes.object,
    parent: propTypes.object,
    isOverriding: propTypes.bool,
    // from setting reducer
    upsertSetting: propTypes.func,
    deleteSetting: propTypes.func,
    showSettingErrors: propTypes.func,
    // other state items
    pageId: propTypes.string,
    pageParent: propTypes.object,
    options: propTypes.array,
    stateOptions: propTypes.object,
    itemData: propTypes.object
  }

  render() {
    const { endpoint, id, options, stateOptions, itemData, parent } = this.props
    const formOptions = options ? makeStateOptions(options, stateOptions) : null
    const settingEndpoint = endpoint.replace(':id', this.props.pageId)
    const isNew = isEmpty(this.props.data)
    if (isNew) window.scrollTo(0, 0)
    let parentUrl = ''
    if (parent && !isEmpty(itemData)) {
      parentUrl = parent.endpoint.replace(':id', itemData[parent.id])
    }
    const SettingForm = this.props.hasGeocoder ? withGecoder(Form) : Form
    return (
      <>
        <PageError msg={this.props.errors.form} />
        {!this.props.data && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {this.props.data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="content__main">
                {parentUrl.length > 0 && (
                  <p className="content__note">
                    These settings are currently being inherited from this{' '}
                    {`${parent.child}'s `}
                    <NavLink to={parentUrl}>
                      parent {`${parent.entity}`}
                    </NavLink>
                    .
                  </p>
                )}
                {this.props.isOverriding && (
                  <p className="content__note -alert">
                    The settings below are currently overriding the parent store
                    settings. You can delete them if you want to use the store
                    settings instead.
                  </p>
                )}
                {this.props.pageParent &&
                  !parent &&
                  isEmpty(this.props.data) && (
                    <p className="content__note -alert">
                      These settings have not yet been set at the store level.
                    </p>
                  )}
                <SettingForm
                  isNew={isNew}
                  endpoint={settingEndpoint}
                  idField={id}
                  fields={this.props.fields}
                  data={this.props.data}
                  errors={this.props.errors}
                  options={formOptions}
                  upsertItem={this.props.upsertSetting}
                  deleteItem={this.props.deleteSetting}
                  hasDelete={this.props.hasDelete}
                  validation={this.props.validation}
                  showErrors={this.props.showSettingErrors}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>
    )
  }
}

Setting.displayName = 'Setting'

export default connect(
  state => ({
    ...state.page.setting,
    ...state.setting,
    pageId: state.page.id,
    pageParent: state.page.setting.parent,
    options: state.page.options,
    stateOptions: state.options,
    itemData: state.item.data
  }),
  { upsertSetting, deleteSetting, showSettingErrors }
)(Setting)
