import { request } from '../lib/services'
import { flashMessage } from './messages'
import { convertFromAPI, makeAddress } from '../lib/helpers'
import { handleError, errMessages } from '../lib/errors'
import { loadItem } from './item'
import { handlePageError } from './page'

const initState = {
  loading: false,
  delivery_zone_id: null,
  coordinates: [],
  description: undefined,
  priority: undefined,
  address: null,
  errors: null
}

export const ZONE_CLEAR = 'ZONE_CLEAR'
export const ZONE_RESET = 'ZONE_RESET'
export const ZONE_LOADING = 'ZONE_LOADING'
export const ZONE_LOAD = 'ZONE_LOAD'
export const ZONE_UPDATE = 'ZONE_UPDATE'
export const ZONE_ERROR = 'ZONE_ERROR'

export const clearZone = () => ({ type: ZONE_CLEAR })
export const resetZone = () => ({ type: ZONE_RESET })
export const loadingZone = bool => ({ type: ZONE_LOADING, payload: bool })
export const loadZone = data => ({ type: ZONE_LOAD, payload: data })
export const updateZone = data => ({ type: ZONE_UPDATE, payload: data })
export const showZoneErrors = errors => ({ type: ZONE_ERROR, payload: errors })

const parseZone = (zones, address) => {
  if (!zones.length) return { address: address }
  let zone = zones[0].delivery_zone
  if (zone.coordinates && zone.coordinates.length) {
    zone.coordinates = JSON.parse(zone.coordinates)
  }
  zone.address = address
  return zone
}

export const fetchZone = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, item } = page
    const params =
      'expand=true&with_related=delivery_zones&with_settings=address'
    const endpoint = `${item.endpoint}/${id}?${params}`
    dispatch(clearZone())
    dispatch(loadingZone(true))
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const data = convertFromAPI(page.route, 'item', resp)
        dispatch(loadItem(data))
        if (!data.address) {
          throw new Error(errMessages.missingAddressDeliveryZone)
        }
        const address = makeAddress(data.address)
        const zone = parseZone(data.delivery_zones, address)
        dispatch(loadZone(zone))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(loadingZone(false))
      })
  }
}

export const updateCoords = (lat, lng) => {
  return (dispatch, getState) => {
    const { deliveryZone } = getState()
    const newZone = [...deliveryZone.coordinates, [lat, lng]]
    dispatch(updateZone(newZone))
  }
}

export const upsertZone = data => {
  return (dispatch, getState) => {
    const { token, brand, page, deliveryZone } = getState()
    let { coordinates, delivery_zone_id } = deliveryZone
    if (coordinates.length < 3) {
      const error = { form: 'Please select at least 3 points on the map' }
      return dispatch(showZoneErrors(error))
    }
    // complete the coordinates if necessary
    const first = coordinates[0]
    const last = coordinates[coordinates.length - 1]
    if (first[0] !== last[0] || first[1] !== last[1]) {
      coordinates = [...coordinates, coordinates[0]]
    }
    coordinates = JSON.stringify(coordinates)
    const values = {
      ...data,
      coordinates,
      revenue_center_id: parseInt(page.id)
    }
    const method = delivery_zone_id ? 'PUT' : 'POST'
    const endpoint =
      page.deliveryZone.endpoint +
      (delivery_zone_id ? `/${delivery_zone_id}` : '')
    return request(token, brand, endpoint, method, values)
      .then(() => {
        dispatch(fetchZone())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        const errors = handleError(err)
        dispatch(showZoneErrors(errors))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ZONE_CLEAR:
      return { ...initState }
    case ZONE_LOADING:
      return { ...state, loading: action.payload }
    case ZONE_RESET:
      return { ...state, coordinates: [] }
    case ZONE_UPDATE:
      return { ...state, coordinates: action.payload }
    case ZONE_LOAD:
      return { ...state, ...action.payload, errors: null, loading: false }
    case ZONE_ERROR:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
