import propTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  updateInput,
  submitForm,
  resetForm,
  toggleShow
} from '../reducers/reset'
import { LandingImage, FormError } from './Auth'
import backgroundImage from '../assets/open-tender-landing.jpg'
import PassWordInput from './PasswordInput'

const ResetForm = ({
  data,
  reset,
  submitting,
  show,
  submitRef,
  handleInput,
  handleSubmit,
  handleToggle
}) => {
  return (
    <form id="form" onSubmit={handleSubmit}>
      <PassWordInput
        label="Password"
        id="password"
        value={data.password}
        show={show}
        reset={reset}
        handleInput={handleInput}
        handleToggle={handleToggle}
      />
      <PassWordInput
        label="Confirm"
        id="confirm"
        value={data.confirm}
        show={show}
        reset={reset}
        handleInput={handleInput}
        handleToggle={handleToggle}
      />
      {!reset && (
        <input
          type="submit"
          value={submitting ? 'Submitting...' : 'Submit'}
          ref={submitRef}
          disabled={submitting}
        />
      )}
    </form>
  )
}

ResetForm.displayName = 'ResetForm'
ResetForm.propTypes = {
  data: propTypes.object,
  reset: propTypes.bool,
  submitting: propTypes.bool,
  show: propTypes.bool,
  submitRef: propTypes.shape({ current: propTypes.any }),
  handleInput: propTypes.func,
  handleSubmit: propTypes.func,
  handleToggle: propTypes.func
}

class Reset extends Component {
  constructor(props) {
    super(props)
    this.submit = React.createRef()
  }

  static propTypes = {
    location: propTypes.object,
    data: propTypes.object,
    errors: propTypes.object,
    reset: propTypes.bool,
    submitting: propTypes.bool,
    show: propTypes.bool,
    user: propTypes.object,
    brand: propTypes.object,
    updateInput: propTypes.func,
    submitForm: propTypes.func,
    resetForm: propTypes.func,
    toggleShow: propTypes.func
  }

  componentDidMount() {
    this.props.resetForm()
    const { hash } = this.props.location
    const token = hash.includes('#') ? hash.split('#')[1] : ''
    this.props.updateInput('token', token)
  }

  handleInputChange = evt => {
    const { id, value } = evt.target
    this.props.updateInput(id, value)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.submitForm()
    this.submit.current.blur()
    window.scrollTo(0, 0)
  }

  handleToggleShow = evt => {
    evt.preventDefault()
    this.props.toggleShow()
    evt.target.blur()
  }

  render() {
    document.title = 'Reset Password | Open Tender Admin Portal'
    const { data, errors, reset, submitting, show } = this.props
    const fileUrl = backgroundImage
    return (
      <div>
        {this.props.user && this.props.brand ? (
          <Redirect to="/" />
        ) : (
          <div
            className="landing landing--reset"
            style={{
              backgroundImage: `url(${fileUrl})`,
              backgroundPosition: `center center`
            }}
          >
            <div className="landing__overlay"></div>
            <LandingImage />
            <div className="landing__content">
              <div className="landing__container">
                <div className="landing__form landing__form--reset">
                  {!reset ? (
                    <div className="landing__form__login">
                      <div className="landing__header">
                        <p>Please enter and confirm a new password.</p>
                      </div>
                      <FormError error={errors.form} />
                      <ResetForm
                        key="reset-form"
                        data={data}
                        reset={reset}
                        submitting={submitting}
                        show={show}
                        submitRef={this.submit}
                        handleInput={this.handleInputChange}
                        handleSubmit={this.handleSubmit}
                        handleToggle={this.handleToggleShow}
                      />
                      <div className="landing__form__footer">
                        <p>Please note that passwords...</p>
                        <ul>
                          <li>Are case sensitive.</li>
                          <li>
                            Must be at least 8 characters and contain at least 1
                            letter and 1 number.
                          </li>
                          <li>
                            May contain any of these special characters:
                            !@#$%^&_-+={'<>'}.
                          </li>
                          <li>
                            Must be different than any of your last 4 passwords.
                          </li>
                        </ul>
                        <p>
                          Remember your password?{' '}
                          <NavLink to="/login">
                            Head back to the login form.
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="landing__form__footer">
                      <p className="landing__success">
                        Your password has been reset!
                      </p>
                      <p>
                        <NavLink to="/login">
                          Head back to the login form.
                        </NavLink>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Reset.displayName = 'Reset'

export default connect(
  state => ({
    ...state.reset,
    user: state.user,
    brand: state.brand
  }),
  {
    updateInput,
    submitForm,
    resetForm,
    toggleShow
  }
)(Reset)
