import { request } from '../lib/services'
import { handleError } from '../lib/errors'
import { updatePage, makeNewFilters, handlePageError } from './page'
import { openModal, closeModal, loadingModal, showModalErrors } from './modal'
import { flashMessage } from './messages'
import { fmtDate } from '../lib/helpersDatetime'
import { add } from 'date-fns'

const initState = {
  loading: false,
  storeId: null,
  startDate: null,
  endDate: null,
  items: [],
  employee: null,
  error: ''
}

export const PUNCHES_CLEAR = 'PUNCHES_CLEAR'
export const PUNCHES_LOAD = 'PUNCHES_LOAD'
export const PUNCHES_UPDATE = 'PUNCHES_UPDATE'
export const PUNCHES_ERROR = 'PUNCHES_ERROR'
export const PUNCHES_EMPLOYEE_CLEAR = 'PUNCHES_EMPLOYEE_CLEAR'
export const PUNCHES_EMPLOYEE_LOAD = 'PUNCHES_EMPLOYEE_LOAD'

export const clearPunches = () => ({ type: PUNCHES_CLEAR })
export const loadPunches = punches => ({ type: PUNCHES_LOAD, payload: punches })
export const showPunchesError = msg => ({ type: PUNCHES_ERROR, payload: msg })
export const updatePunches = update => ({
  type: PUNCHES_UPDATE,
  payload: update
})

const mapFilters = filters => {
  return filters.reduce((obj, i) => {
    obj[i.field] = i.value
    return obj
  }, {})
}

const makeDates = (start, end) => {
  if (start.getDay() === 0 || end < start) {
    end = start
  } else {
    const maxDiff = 7 - start.getDay()
    const daysDiff = Math.abs(end - start) / (1000 * 60 * 60 * 24)
    if (daysDiff > maxDiff) {
      end = add(start, { days: maxDiff })
    }
  }
  return { start, end }
}

export const fetchPunches = () => {
  return (dispatch, getState) => {
    const { token, brand, page, punches } = getState()
    const args = mapFilters(page.punches.filters)
    const { storeId, startDate, endDate } = punches
    // handle the store filter
    if (storeId) {
      const newStoreId = parseInt(storeId)
      const currentStoreId = args.store_id ? parseInt(args.store_id) : null
      if (newStoreId !== currentStoreId) {
        const filter = { field: 'store_id', value: newStoreId }
        return dispatch(updateFilter(filter))
      }
    } else if (args.store_id) {
      dispatch(updatePunches({ storeId: args.store_id }))
    }
    // handle start date filter
    if (startDate) {
      const startFilter = args.start ? fmtDate(args.start) : null
      if (startDate !== startFilter) {
        const newStart = new Date(`${startDate} 00:00:00`)
        const filter = { field: 'start', value: newStart }
        return dispatch(updateFilter(filter))
      }
    } else if (args.start) {
      dispatch(updatePunches({ startDate: fmtDate(args.start) }))
    }
    // handle end date filter
    if (endDate) {
      const endFilter = args.end ? fmtDate(args.end) : null
      if (endDate !== endFilter) {
        const newEnd = new Date(`${endDate} 00:00:00`)
        const filter = { field: 'end', value: newEnd }
        return dispatch(updateFilter(filter))
      }
    } else if (args.end) {
      dispatch(updatePunches({ endDate: fmtDate(args.end) }))
    }
    if (!args.store_id || !args.start || !args.end) return
    const { start, end } = makeDates(args.start, args.end)
    if (end && end !== args.end) {
      return dispatch(updateFilter({ field: 'end', value: end }))
    }
    const id = args.store_id
    const startDateStr = fmtDate(start)
    const endDateStr = fmtDate(end)
    const queryParams = `start=${startDateStr}&end=${endDateStr}`
    const endpoint = `/time-punches/reports/${id}?${queryParams}`
    request(token, brand, endpoint, 'GET')
      .then(punches => {
        dispatch(loadPunches(punches))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const refreshPunches = () => {
  return dispatch => {
    dispatch(updatePunches({ startDate: null, endDate: null }))
    dispatch(fetchPunches())
  }
}

const translateField = field => {
  const fields = {
    store_id: 'storeId',
    start: 'startDate',
    end: 'endDate'
  }
  return fields[field]
}

const makeFilterValue = filter => {
  if (filter.field === 'store_id') {
    return filter.value ? parseInt(filter.value) : null
  }
  return fmtDate(filter.value)
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    const { page, punches } = getState()
    if (filter.field === 'start' || filter.field === 'end') {
      if (!filter.value) filter.value = new Date()
    }
    const updated = { [translateField(filter.field)]: makeFilterValue(filter) }
    dispatch(updatePunches(updated))
    let newFilters = makeNewFilters(page.punches.filters, filter)
    if (filter.field === 'start') {
      const args = mapFilters(newFilters)
      const currentEnd = new Date(`${punches.endDate} 00:00:00`)
      const { end } = makeDates(args.start, currentEnd)
      if (end !== args.end) {
        const endFilter = { field: 'end', value: end }
        dispatch(updatePunches({ endDate: makeFilterValue(endFilter) }))
        newFilters = makeNewFilters(newFilters, endFilter)
      }
    }
    const newPunches = { ...page.punches, filters: newFilters }
    dispatch(updatePage({ punches: newPunches }))
    dispatch(fetchPunches())
  }
}

export const editPunch = (punchId, punchType, punchTime, tipsAmount) => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Update this time punch',
      type: 'punch',
      args: {
        time_punch_id: punchId,
        punch_type: punchType,
        punch_time: punchTime,
        tips_amount: tipsAmount || null
      }
    }
    dispatch(openModal(config))
  }
}

export const updatePunch = values => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/time-punches/${values.time_punch_id}`
    if (!values.tips_amount) values.tips_amount = '0.00'
    delete values.time_punch_id
    return request(token, brand, endpoint, 'PUT', values)
      .then(() => {
        dispatch(fetchPunches())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export const confirmDeletePunch = (punchInId, punchOutId) => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Are you sure?',
      subtitle: 'Time punch deletions CANNOT be undone',
      type: 'deletePunch',
      args: { punchInId, punchOutId }
    }
    dispatch(openModal(config))
  }
}

export const deletePunch = (punchInId, punchOutId) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const punches = [punchInId, punchOutId].filter(i => i)
    const requests = punches.map(id => {
      return request(token, brand, `/time-punches/${id}`, 'DELETE')
    })
    Promise.all(requests)
      .then(() => {
        dispatch(fetchPunches())
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(closeModal()))
  }
}

export const clearEmployee = () => ({ type: PUNCHES_EMPLOYEE_CLEAR })

export const loadEmployee = employee => ({
  type: PUNCHES_EMPLOYEE_LOAD,
  payload: employee
})

export const addPunch = () => {
  return dispatch => {
    dispatch(clearEmployee())
    dispatch(loadingModal())
    const config = {
      title: 'Add new time punch',
      type: 'punchNew',
      classes: 'modal--big'
    }
    dispatch(openModal(config))
  }
}

export const fetchEmployee = employeeId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/employees/${employeeId}?with_related=departments`
    return request(token, brand, endpoint)
      .then(resp => {
        dispatch(loadEmployee(resp))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export const upsertPunch = values => {
  return (dispatch, getState) => {
    const { token, brand, punches } = getState()
    values = {
      ...values,
      department_id: parseInt(values.department_id),
      tips_amount: values.tips_amount || '0.00',
      store_id: parseInt(punches.storeId)
    }
    return request(token, brand, `/time-punches`, 'POST', values)
      .then(() => {
        dispatch(fetchPunches())
        dispatch(closeModal())
        dispatch(flashMessage('Successfully created!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case PUNCHES_CLEAR:
      return { ...initState }
    case PUNCHES_LOAD:
      return { ...state, items: action.payload, loading: false, error: '' }
    case PUNCHES_UPDATE:
      return { ...state, ...action.payload }
    case PUNCHES_ERROR:
      return { ...state, error: action.payload, loading: false }
    case PUNCHES_EMPLOYEE_CLEAR:
      return { ...state, employee: null }
    case PUNCHES_EMPLOYEE_LOAD:
      return { ...state, employee: action.payload }

    default:
      return state
  }
}
