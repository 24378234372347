import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import Table from '../Table'

const fields = [
  {
    name: 'Order ID',
    field: ['receipt_id'],
    link: 'receipt_id',
    linkEndpoint: '/orders'
  },
  {
    name: 'Requested At',
    field: ['requested_at'],
    format: 'datetimeZoned',
    tz: 'timezone'
  },
  {
    name: 'Store',
    field: ['store']
    // link: 'revenue_center.store_id',
    // linkEndpoint: '/tender-reconciliation?store_id'
  },
  { name: 'Sale Type', field: ['receipt_type'], format: 'enum' },
  { name: 'Channel Type', field: ['channel_type'], format: 'enum' },
  { name: 'Order Type', field: ['order_type'], format: 'enum' },
  { name: 'Service Type', field: ['service_type'], format: 'enum' },
  {
    name: 'Order Status',
    field: ['receipt_status'],
    format: 'enum'
  },
  {
    name: 'Tender Status',
    field: ['tender_status'],
    format: 'enum'
  },
  {
    name: 'Tender Total',
    field: ['amount'],
    format: 'dollars'
  },
  {
    name: 'Order Total',
    field: ['total'],
    format: 'dollars'
  },
  {
    name: 'Difference',
    field: ['difference'],
    format: 'dollars'
  }
]

const makeTotals = (fields, data) => {
  if (!data || !data.length) return null
  const totals = fields
    .filter(i => i.format === 'dollars')
    .reduce((obj, field) => {
      const key = field.field[0]
      const total = data.reduce((t, i) => {
        t += parseFloat(i[key])
        return t
      }, 0.0)
      return { ...obj, [key]: total }
    }, {})
  return { receipt_id: 'Totals', ...totals }
}

const TendersDiff = ({ data, handlers }) => {
  // const [showData, setShowData] = useState(true)
  const showData = true

  // const toggleDisplay = evt => {
  //   evt.preventDefault()
  //   setShowData(!showData)
  //   evt.target.blur()
  // }

  const totals = makeTotals(fields, data)
  const withTotals = [...data, totals]
  if (!data.length)
    return (
      <div className="report__table">
        <p className="alert">
          No tender reconciliation data for the selected time period. Please try
          adjusting your filters.
        </p>
      </div>
    )
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            {/* <ToggleDisplay showData={showData} handler={toggleDisplay} /> */}
            <ButtonCSV endpoint="/reporting/tenders-diff/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="report__table">
              <Table
                fields={fields}
                items={withTotals}
                classes="table--tender-reconciliation"
              />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

TendersDiff.displayName = 'TendersDiff'
TendersDiff.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default TendersDiff
