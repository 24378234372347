import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  refreshHoursForWeek,
  updateFilter,
  downloadCsv
} from '../reducers/hoursForWeek'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'
import { RefreshCw } from 'react-feather'

const titles = [
  'Name',
  'Payroll ID',
  'Payroll Loc ID',
  'Regular Hours',
  'Regular OT Hours',
  'Dream Team Hours',
  'Dream Team OT Hours',
  'Training Hours',
  'Training OT Hours',
  'Off Site Clock In Hours',
  'Off Site Clock In OT Hours',
  'Delivery Hours',
  'Delivery OT Hours',
  'Tips'
]

class HoursForWeek extends Component {
  static propTypes = {
    // state.hoursForWeek
    loading: propTypes.bool,
    hours: propTypes.array,
    error: propTypes.string,
    // state.page.hoursForWeek
    filters: propTypes.array,
    // mapDispatchToProps
    refreshHoursForWeek: propTypes.func,
    updateFilter: propTypes.func,
    downloadCsv: propTypes.func
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.refreshHoursForWeek()
    evt.target.blur()
  }

  handleFilter = evt => {
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    this.props.updateFilter(filter)
    if (type !== 'datepicker') evt.target.blur()
  }

  handleCsv = evt => {
    evt.preventDefault()
    this.props.downloadCsv()
    evt.target.blur()
  }

  render() {
    const { filters, loading, hours } = this.props
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        <div className="table__results">
          <div className="table__results__message">
            <span className="table__results__refresh">
              <button className="btn-link" onClick={this.handleRefresh}>
                <RefreshCw size={16} />
              </button>
            </span>
            <p>Displaying hours for {hours.length} employees.</p>
          </div>
          <div className="table__results__buttons">
            <button type="button" className="btn" onClick={this.handleCsv}>
              Download CSV
            </button>
          </div>
        </div>
        <div className="table__wrapper">
          <table className="table--hours-for-week">
            <thead>
              <tr>
                {/* {titles.map(title => (
                  <th key={title}>{title}</th>
                ))} */}
                <th>&nbsp;</th>
                <th colSpan="2">Payroll</th>
                <th colSpan="2">Regular</th>
                <th colSpan="2">Dream Team</th>
                <th colSpan="2">Training</th>
                <th colSpan="2">Off Site</th>
                <th colSpan="2">Delivery</th>
                <th>&nbsp;</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Loc ID</th>
                <th>Hours</th>
                <th>OT Hours</th>
                <th>Hours</th>
                <th>OT Hours</th>
                <th>Hours</th>
                <th>OT Hours</th>
                <th>Hours</th>
                <th>OT Hours</th>
                <th>Hours</th>
                <th>OT Hours</th>
                <th>Tips</th>
              </tr>
            </thead>
            <tbody>
              {hours.length
                ? hours.map((employee, index) => {
                    const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
                    return (
                      <tr key={employee['Payroll ID']} className={evenOdd}>
                        {titles.map(title => (
                          <td key={title}>{employee[title]}</td>
                        ))}
                      </tr>
                    )
                  })
                : !loading && (
                    <tr className="empty-table">
                      <td colSpan={titles.length}>
                        There {"aren't"} any items matching the applied filters.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
          {loading ? (
            <div className="table__loading">
              <ClipLoader size={36} color={'#5a5aff'} />
              <span>Retrieving hours...</span>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

HoursForWeek.displayName = 'HoursForWeek'

export default connect(
  state => ({
    ...state.hoursForWeek,
    ...state.page.hoursForWeek
  }),
  { refreshHoursForWeek, updateFilter, downloadCsv }
)(HoursForWeek)
