import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  refreshPunches,
  updateFilter,
  editPunch,
  confirmDeletePunch,
  addPunch
} from '../reducers/punches'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'
import { punchTypes } from '../lib/constants'
import { RefreshCw } from 'react-feather'
import { makeLocalDateStr } from '../lib/helpersDatetime'
import { formatDollars } from '../lib/helpers'

const PunchGroup = ({ employee, index, handleEdit, handleDelete }) => {
  const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
  return (
    <>
      <tr className={`punch--parent ${evenOdd}`}>
        <td>
          {employee.last_name}, {employee.first_name} ({employee.timeclock_id})
        </td>
        <td>&nbsp;</td>
        {/* <td>{formatDollars(employee.avg_pay_rate)}</td> */}
        <td className="-align-right">{employee.time_in}</td>
        <td>&nbsp;</td>
        <td className="-align-right">{employee.time_out}</td>
        <td>&nbsp;</td>
        <td>{employee.total_hours} hrs</td>
        {/* <td>{formatDollars(employee.total_dollars)}</td> */}
        <td>{formatDollars(employee.total_tips)}</td>
      </tr>
      {employee.punches.map((punch, index, arr) => {
        const isLast = index + 1 === arr.length ? '-last' : ''
        return (
          <Punch
            key={punch.punch_in_id}
            punch={punch}
            evenOdd={evenOdd}
            isLast={isLast}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        )
      })}
    </>
  )
}

PunchGroup.displayName = 'PunchGroup'
PunchGroup.propTypes = {
  employee: propTypes.object,
  index: propTypes.number,
  handleEdit: propTypes.func,
  handleDelete: propTypes.func
}

const Punch = ({ punch, evenOdd, isLast, handleEdit, handleDelete }) => {
  const overtime = punch.is_overtime ? '-is-overtime' : ''
  const todayDateStr = makeLocalDateStr(0, 'MM/dd/yyyy')
  return (
    <tr className={`punch--child ${evenOdd} ${isLast} ${overtime}`}>
      <td>
        <span>{punch.punch_in_date}</span>
        {punch.punch_in_id && punch.punch_in_date !== todayDateStr && (
          <button
            className="btn-link"
            onClick={evt =>
              handleDelete(evt, punch.punch_in_id, punch.punch_out_id)
            }
          >
            delete
          </button>
        )}
      </td>
      <td>{punch.dept}</td>
      {/* <td className={overtime}>{formatDollars(punch.pay_rate)}</td> */}
      <td className="-align-right">
        <button
          className="btn-link"
          onClick={evt =>
            handleEdit(
              evt,
              punch.punch_in_id,
              punch.punch_in_type,
              punch.punch_in_time
            )
          }
        >
          {punch.punch_in_time}
        </button>
      </td>
      <td className="-align-left">{punch.punch_in_type_str}</td>
      <td className="-align-right">
        {punch.punch_out_time ? (
          <button
            className="btn-link"
            onClick={evt =>
              handleEdit(
                evt,
                punch.punch_out_id,
                punch.punch_out_type,
                punch.punch_out_time,
                punch.tips_amount
              )
            }
          >
            {punch.punch_out_time}
          </button>
        ) : (
          punch.punch_out_time
        )}
      </td>
      <td className="-align-left">{punch.punch_out_type_str || 'Open'}</td>
      <td className={overtime}>{punch.hours} hrs</td>
      {/* <td className={overtime}>{formatDollars(punch.dollars)}</td> */}
      <td>{formatDollars(punch.tips_amount)}</td>
    </tr>
  )
}

Punch.displayName = 'Punch'
Punch.propTypes = {
  punch: propTypes.object,
  evenOdd: propTypes.string,
  isLast: propTypes.string,
  handleEdit: propTypes.func,
  handleDelete: propTypes.func
}

class Punches extends Component {
  static propTypes = {
    // state.punches
    loading: propTypes.bool,
    items: propTypes.array,
    error: propTypes.string,
    // state.page.punches
    filters: propTypes.array,
    // mapDispatchToProps
    refreshPunches: propTypes.func,
    updateFilter: propTypes.func,
    editPunch: propTypes.func,
    confirmDeletePunch: propTypes.func,
    addPunch: propTypes.func
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.refreshPunches()
    evt.target.blur()
  }

  handleEdit = (evt, punchId, punchTypeId, punchTime, tipsAmount) => {
    evt.preventDefault()
    const punchType = punchTypes[punchTypeId]
    this.props.editPunch(punchId, punchType, punchTime, tipsAmount)
    evt.target.blur()
  }

  handleDelete = (evt, punchInId, punchOutId) => {
    evt.preventDefault()
    this.props.confirmDeletePunch(punchInId, punchOutId)
    evt.target.blur()
  }

  handleNew = evt => {
    evt.preventDefault()
    this.props.addPunch()
    evt.target.blur()
  }

  handleFilter = evt => {
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    this.props.updateFilter(filter)
    if (type !== 'datepicker') evt.target.blur()
  }

  render() {
    const { filters, loading, items } = this.props
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        <div className="table__results">
          <div>
            <span className="table__results__refresh">
              <button className="btn-link" onClick={this.handleRefresh}>
                <RefreshCw size={16} />
              </button>
            </span>
            <p>Displaying time punches for {items.length} employees.</p>
          </div>
          <div className="table__results__buttons">
            <button className="btn" onClick={this.handleNew}>
              Add New
            </button>
          </div>
        </div>
        <div className="table__wrapper punches">
          <table className="table--punches">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Dept</th>
                {/* <th>Payrate</th> */}
                <th colSpan="2">Time In</th>
                <th colSpan="2">Time Out</th>
                <th>Hours</th>
                {/* <th>Dollars</th> */}
                <th>Tips</th>
              </tr>
            </thead>
            <tbody>
              {items && items.length
                ? items.map((e, i) => (
                    <PunchGroup
                      key={e.timeclock_id}
                      employee={e}
                      index={i}
                      handleEdit={this.handleEdit}
                      handleDelete={this.handleDelete}
                    />
                  ))
                : !loading && (
                    <tr className="empty-table">
                      <td colSpan="8">
                        There {"aren't"} any items matching the applied filters.
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
          {loading ? (
            <div className="table__loading">
              <ClipLoader size={36} color={'#5a5aff'} />
              <span>Retrieving items...</span>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

Punches.displayName = 'Punches'

export default connect(
  state => ({
    ...state.punches,
    ...state.page.punches
  }),
  { refreshPunches, updateFilter, editPunch, confirmDeletePunch, addPunch }
)(Punches)
