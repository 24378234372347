import propTypes from 'prop-types'
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { reorder } from './Table'
import { makeFlags } from './TableRow'
import TableCell from './TableCell'
import TableCellLabel from './TableCellLabel'
import DraggableListChildren from './DraggableListChildren'
import { Menu } from 'react-feather'
import ClipLoader from 'react-spinners/ClipLoader'

// https://github.com/atlassian/react-beautiful-dnd
// examples: https://react-beautiful-dnd.netlify.com/?path=/story/single-vertical-list--basic

// react-beautiful-dnd nested list example:
// https://codesandbox.io/s/j4yvnr7n83?from-embed

const makeIndices = (items, id) => {
  let count = 0
  let lookup = {}
  items.forEach(i => {
    count++
    lookup = { ...lookup, [i[id]]: count }
    if (i.children) {
      i.children.forEach(c => {
        count++
        lookup = { ...lookup, [c[id]]: count }
      })
    }
  })
  return lookup
}

const DraggableList = ({
  classes,
  fields,
  items,
  sorting,
  id,
  loading,
  listEndpoint,
  reorderList,
  isRelation
}) => {
  const onDragEnd = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) return
    // dropped in the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    const newItems = reorder(
      items,
      source.index,
      destination.index,
      source.droppableId,
      sorting.sortBy,
      id,
      sorting.fields
    )
    reorderList(listEndpoint, newItems)
  }
  const lookup = makeIndices(items, id)
  return (
    <div className="table__wrapper">
      <div className={`table ${classes || ''}`}>
        <div className="thead">
          <ul className="tr">
            {fields &&
              fields.map(field => (
                <li key={field.field} className="th">
                  {field.name}
                </li>
              ))}
            <li className="th">&nbsp;</li>
          </ul>
        </div>
        {items.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableList">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`tbody ${
                    snapshot.isDraggingOver ? `-dragging-over` : ''
                  }`}
                >
                  {items.map((item, index) => {
                    const key = item.key || item[id]
                    return key ? (
                      <Draggable key={key} draggableId={`${key}`} index={index}>
                        {(provided, snapshot) => {
                          const dragging = snapshot.isDragging
                            ? '-dragging'
                            : ''
                          const rowIndex = lookup[item[id]]
                          const evenOdd = rowIndex % 2 === 0 ? '-even' : '-odd'
                          const childrenLength = item.children
                            ? item.children.length
                            : 0
                          const parent = childrenLength ? 'tr--parent' : ''
                          const flags = makeFlags(item, fields)
                          const rowClasses = `tr ${evenOdd} ${dragging} ${parent} ${flags}`
                          return (
                            <div
                              className={dragging}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ul className={rowClasses}>
                                {fields.map(field => {
                                  const fieldKey = field.field.join('-')
                                  const colKey = `${key}-${fieldKey}`
                                  return (
                                    <li key={colKey} className="td">
                                      <TableCellLabel {...field} />
                                      <TableCell
                                        item={item}
                                        endpoint={listEndpoint}
                                        {...field}
                                      />
                                    </li>
                                  )
                                })}
                                <li className="td gripper">
                                  <Menu size={14} />
                                </li>
                              </ul>
                              {item.children && item.children.length ? (
                                <DraggableListChildren
                                  item={item}
                                  id={id}
                                  fields={fields}
                                  listEndpoint={listEndpoint}
                                  parentDragging={dragging}
                                  lookup={lookup}
                                />
                              ) : null}
                            </div>
                          )
                        }}
                      </Draggable>
                    ) : null
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="tbody">
            {!loading ? (
              <ul className="tr empty-table">
                <li className="td" colSpan={fields.length}>
                  There {"aren't"} any items matching the applied filters.
                </li>
              </ul>
            ) : null}
          </div>
        )}
      </div>
      {loading ? (
        isRelation ? (
          <div className="table__loading -relations">
            <ClipLoader size={24} color={'#5a5aff'} />
          </div>
        ) : (
          <div className="table__loading">
            <ClipLoader size={36} color={'#5a5aff'} />
            <span>Retrieving items...</span>
          </div>
        )
      ) : null}
    </div>
  )
}

DraggableList.displayName = 'DraggableList'
DraggableList.propTypes = {
  classes: propTypes.string,
  fields: propTypes.array,
  items: propTypes.array,
  sorting: propTypes.object,
  id: propTypes.string,
  loading: propTypes.bool,
  listEndpoint: propTypes.string,
  reorderList: propTypes.func,
  isRelation: propTypes.bool
}

export default DraggableList
