import propTypes from 'prop-types'
import React, { useState } from 'react'
import { formatDollars, capitalize } from '../../lib/helpers'

const SalidoMenuItemName = ({ item }) => (
  <span>
    {item.name} -- <span className="err">{item.id}</span>
  </span>
)

SalidoMenuItemName.displayName = 'SalidoMenuItemName'
SalidoMenuItemName.propTypes = {
  item: propTypes.object
}

const SalidoModifierGroup = ({ group }) => {
  let {
    name,
    id,
    min_number_of_modifiers: min,
    max_number_of_modifiers: max
  } = group
  min = min === null ? 'none' : min
  max = max === null ? 'none' : max
  return (
    <li>
      {name} -- <span className="err">{id}</span> --{' '}
      <span className="success">
        Min = {min} / Max = {max}
      </span>
    </li>
  )
}

SalidoModifierGroup.displayName = 'SalidoModifierGroup'
SalidoModifierGroup.propTypes = {
  group: propTypes.object
}

const SalidoModifier = ({ item }) => (
  <li>
    {item.name} -- {formatDollars(item.price)} --{' '}
    <span className="err">{item.id}</span>
  </li>
)

SalidoModifier.displayName = 'SalidoModifier'
SalidoModifier.propTypes = {
  item: propTypes.object
}

const SalidoMenuItem = ({ menuItem: i }) => (
  <li>
    <SalidoMenuItemName item={i} />
    <ul className="mx__menu__modifier-group">
      <li>Variations</li>
      <ul className="mx__menu__modifier-options">
        {i.variations.map(v => (
          <SalidoModifier key={v.id} item={v} />
        ))}
      </ul>
    </ul>
    {i.modifier_groups.map(g => (
      <ul key={g.id} className="mx__menu__modifier-group">
        <SalidoModifierGroup group={g} />
        <ul className="mx__menu__modifier-options">
          {g.modifiers.map(o => (
            <SalidoModifier key={o.id} item={o} />
          ))}
        </ul>
      </ul>
    ))}
  </li>
)

SalidoMenuItem.displayName = 'SalidoMenuItem'
SalidoMenuItem.propTypes = {
  menuItem: propTypes.object
}

const ToggleDisplay = ({ view, handler }) => {
  return (
    <div className="toggle-group">
      {['FORMATTED', 'CODE'].map(i => (
        <button
          key={i}
          className="btn"
          disabled={view === i}
          onClick={evt => handler(evt, i)}
        >
          {capitalize(i)}
        </button>
      ))}
    </div>
  )
}

ToggleDisplay.displayName = 'ToggleDisplay'
ToggleDisplay.propTypes = {
  view: propTypes.string,
  handler: propTypes.func
}

export const SalidoMenu = ({ menus }) => {
  const [view, setView] = useState('FORMATTED')

  const toggleView = (evt, type) => {
    evt.preventDefault()
    setView(type)
    evt.target.blur()
  }

  return (
    <div className="mx">
      <div className="mx__toggle">
        <ToggleDisplay view={view} handler={toggleView} />
      </div>
      <div className="mx__links">
        {Object.keys(menus).map(serviceType => (
          <li key={serviceType}>
            <a href={`#${serviceType}`}>{capitalize(serviceType)}</a>
          </li>
        ))}
      </div>
      <div className="mx__menus">
        {Object.entries(menus).map(([serviceType, menu]) => (
          <div className="mx__menu" key={serviceType} id={serviceType}>
            <h2>{capitalize(serviceType)}</h2>
            {view === 'CODE' ? (
              <div className="mx__code api-request">
                <pre>{JSON.stringify(menu, null, 2)}</pre>
              </div>
            ) : (
              <>
                {Object.entries(menu.categories).map(
                  ([categoryName, menuItems]) => (
                    <div key={categoryName} className="mx__menu__group">
                      <h3>{categoryName}</h3>
                      <ul className="mx__menu__items">
                        {menuItems.map(menuItem => (
                          <SalidoMenuItem
                            key={menuItem.id}
                            menuItem={menuItem}
                          />
                        ))}
                      </ul>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

SalidoMenu.displayName = 'SalidoMenu'
SalidoMenu.propTypes = {
  menus: propTypes.array,
  mappedMenus: propTypes.array
}
