import propTypes from 'prop-types'
import React from 'react'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  {
    name: 'Customer',
    field: ['name'],
    link: 'customer_id',
    linkEndpoint: '/customers'
  },
  {
    name: 'First Name',
    field: ['first_name']
  },
  {
    name: 'Last Name',
    field: ['last_name']
  },
  {
    name: 'Email',
    field: ['email']
  },
  {
    name: 'Phone',
    field: ['phone']
  },
  {
    name: 'Suscribed At',
    field: ['subscribe_at']
  }
]

const Subscribers = ({ data, handlers }) => {
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ButtonCSV endpoint="/reporting/subscribers/csv" />
          </div>
        </div>
      </div>
      <div className="report__table">
        <Table fields={fields} items={data} />
      </div>
    </>
  )
}

Subscribers.displayName = 'Subscribers'
Subscribers.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default Subscribers
