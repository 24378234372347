import React from 'react'
import { ArrowUp } from 'react-feather'

export const BackToTop = () => (
  <div className="back-to-top">
    <a href="#top">
      <ArrowUp size={26} />
    </a>
  </div>
)
