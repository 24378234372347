import propTypes from 'prop-types'
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { request } from '../lib/services'
import MappingRevenueCenters from './MappingRevenueCenters'
import { BackToTop } from './BackToTop'
import { handlePageError } from '../reducers/page'
import { ToastMenu } from './menus/Toast'
import { SalidoMenu } from './menus/Salido'
import { QuMenu } from './menus/Qu'
import { BrightloomMenu } from './menus/Brightloom'
import { ChowlyToosMenu } from './menus/ChowlyToos'

const MenuExtMenu = ({
  token,
  brand,
  pos,
  endpoint,
  revenueCenterId,
  mappedMenus,
  handleError
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [menus, setMenus] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
    if (revenueCenterId) {
      setIsLoading(true)
      const menuEndpoint = endpoint.replace(':id', revenueCenterId)
      request(token, brand, menuEndpoint, 'GET')
        .then(resp => setMenus(resp))
        .catch(err => handleError(err))
        .finally(() => setIsLoading(false))
    }
  }, [revenueCenterId, token, brand, endpoint, handleError])

  return (
    <div className="mapping">
      {isLoading ? (
        <div className="mapping__loading">
          <ClipLoader size={44} color={'#5a5aff'} />
        </div>
      ) : error ? (
        <p className="bold alert">{error}</p>
      ) : pos === 'TOAST' ? (
        <ToastMenu menus={menus} mappedMenus={mappedMenus} />
      ) : pos === 'SALIDO' ? (
        <SalidoMenu menus={menus} />
      ) : pos === 'QU' ? (
        <QuMenu categories={menus} />
      ) : pos === 'BRIGHTLOOM' ? (
        <BrightloomMenu menus={menus} />
      ) : pos === 'CHOWLY_TOOS' ? (
        <ChowlyToosMenu menus={menus} />
      ) : null}
    </div>
  )
}

MenuExtMenu.displayName = 'MenuExtMenu'
MenuExtMenu.propTypes = {
  token: propTypes.string,
  brand: propTypes.object,
  pos: propTypes.string,
  endpoint: propTypes.string,
  revenueCenterId: propTypes.number,
  mappedMenus: propTypes.array,
  handleError: propTypes.func
}

class MenuExt extends Component {
  static propTypes = {
    token: propTypes.string,
    brand: propTypes.object,
    pos: propTypes.string,
    endpoint: propTypes.string,
    revenueCenterId: propTypes.number,
    mappedMenus: propTypes.array,
    handlePageError: propTypes.func
  }

  render() {
    const {
      token,
      brand,
      pos,
      endpoint,
      revenueCenterId,
      mappedMenus,
      handlePageError
    } = this.props
    return (
      <div className="mapping">
        <BackToTop />
        <MappingRevenueCenters />
        <MenuExtMenu
          token={token}
          brand={brand}
          pos={pos}
          endpoint={endpoint}
          revenueCenterId={revenueCenterId}
          mappedMenus={mappedMenus}
          handleError={handlePageError}
        />
      </div>
    )
  }
}

MenuExt.displayName = 'MenuExt'

export default connect(
  state => ({
    token: state.token,
    brand: state.brand,
    ...state.page.menuExt,
    revenueCenterId: state.mapping.revenueCenterId,
    mappedMenus: state.mapping.mappedMenus
  }),
  { handlePageError }
)(MenuExt)
