import { request } from '../lib/services'
import { handlePageError } from './page'

const initState = {
  loading: true,
  lists: [],
  error: ''
}

export const ITEM_LISTS_CLEAR = 'ITEM_LISTS_CLEAR'
export const ITEM_LISTS_LOADING = 'ITEM_LISTS_LOADING'
export const ITEM_LISTS_LOAD = 'ITEM_LISTS_LOAD'
export const ITEM_LISTS_ERROR = 'ITEM_LISTS_ERROR'

export const clearItemLists = () => ({ type: ITEM_LISTS_CLEAR })
export const loadingItemLists = () => ({ type: ITEM_LISTS_LOADING })
export const loadItemLists = lists => ({
  type: ITEM_LISTS_LOAD,
  payload: lists
})
export const showItemListsError = msg => ({
  type: ITEM_LISTS_ERROR,
  payload: msg
})

export const fetchItemLists = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const { id, itemLists } = page
    dispatch(clearItemLists())
    dispatch(clearItemLists())
    const requests = itemLists.map(i => {
      const endpoint = `${i.endpoint}?${i.filter}=${id}`
      return request(token, brand, endpoint, 'GET')
    })
    Promise.all(requests)
      .then(responses => {
        const lists = responses.map(resp => resp.data || resp)
        dispatch(loadItemLists(lists))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ITEM_LISTS_CLEAR:
      return { ...initState }
    case ITEM_LISTS_LOADING:
      return { ...state, loading: true }
    case ITEM_LISTS_LOAD:
      return { ...state, lists: action.payload, loading: false, error: '' }
    case ITEM_LISTS_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
