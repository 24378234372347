import propTypes from 'prop-types'
import React from 'react'
import { formatDollars } from '../../lib/helpers'

const BrightloomMenuItemName = ({ item }) => (
  <span>
    {item.name} &mdash;{' '}
    <span className="success">
      {formatDollars(parseFloat(item.base_price || item.price) / 100.0)}
    </span>{' '}
    &mdash; <span className="err">{item.id}</span>
  </span>
)

BrightloomMenuItemName.displayName = 'BrightloomMenuItemName'
BrightloomMenuItemName.propTypes = {
  item: propTypes.object
}

const BrightloomModifierGroup = ({ modifierGroup }) => (
  <li>
    {modifierGroup.name} &mdash; {modifierGroup.id}
  </li>
)

BrightloomModifierGroup.displayName = 'BrightloomModifierGroup'
BrightloomModifierGroup.propTypes = {
  modifierGroup: propTypes.object
}

const BrightloomMenuItem = ({ item: i }) => (
  <li>
    <BrightloomMenuItemName item={i} />
    {i.modifiers_groups.map(g => (
      <ul key={g.id} className="mx__menu__modifier-group">
        <BrightloomModifierGroup modifierGroup={g} />
        <ul className="mx__menu__modifier-options">
          {g.modifiers.map(o => (
            <li key={o.id}>
              <BrightloomMenuItemName item={o} />
            </li>
          ))}
        </ul>
      </ul>
    ))}
  </li>
)

BrightloomMenuItem.displayName = 'BrightloomMenuItem'
BrightloomMenuItem.propTypes = {
  item: propTypes.object
}

export const BrightloomMenu = ({ menus }) => (
  <div className="mx">
    <div className="mx__links">
      {menus.map(i => (
        <li key={i.id}>
          <a href={`#${i.id}`}>{i.name}</a>
        </li>
      ))}
    </div>
    <div className="mx__menus">
      {menus.map(i => (
        <div className="mx__menu" key={i.id} id={i.id}>
          <div className="mx__menu__header">
            <h2>{i.name}</h2>
            <p>
              {i.description} ({i.id}, {i.start_time} to {i.end_time})
            </p>
          </div>
          <div className="mx__menu__group">
            <ul className="mx__menu__items">
              {i.items.map(item => (
                <BrightloomMenuItem key={item.id} item={item} />
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
)

BrightloomMenu.displayName = 'BrightloomMenu'
BrightloomMenu.propTypes = {
  menus: propTypes.array
}
