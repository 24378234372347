import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Results from './Results'
import Table from '../Table'
import ButtonCSV from './ButtonCSV'

const fields = [
  { name: 'Gift Card ID', field: ['gift_card_id'] },
  { name: 'Card Number', field: ['card_number'] },
  { name: 'Employee ID', field: ['employee_id'] },
  { name: 'User ID', field: ['user_id'] },
  { name: 'Customer ID', field: ['customer_id'] },
  { name: 'Created At', field: ['created_at'], format: 'datetime' },
  { name: 'Amount', field: ['amount'], format: 'dollars' }
]

class GiftCardCredits extends Component {
  static propTypes = {
    data: propTypes.array,
    handlers: propTypes.object
  }

  render() {
    const { data, handlers } = this.props
    return (
      <>
        <div className="report__actions">
          <div className="report__actions__container">
            <div className="report__actions__left">
              <Results data={data} {...handlers} />
            </div>
            <div className="report__actions__right">
              <ButtonCSV endpoint="/reporting/gift-card-credits/csv" />
            </div>
          </div>
        </div>
        <div className="report__table">
          <Table fields={fields} items={data} />
        </div>
      </>
    )
  }
}

GiftCardCredits.displayName = 'GiftCardCredits'

export default connect(null, {})(GiftCardCredits)
