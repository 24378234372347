import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editServiceType } from '../../reducers/orderMgmt'

class EditServiceType extends Component {
  static propTypes = {
    orderId: propTypes.number,
    serviceType: propTypes.string,
    windowRef: propTypes.shape({ current: propTypes.any }),
    errors: propTypes.object,
    editServiceType: propTypes.func
  }

  handleClick = (evt, serviceType) => {
    evt.preventDefault()
    const orderId = this.props.orderId
    this.props.editServiceType(orderId, serviceType)
    evt.target.blur()
  }

  render() {
    const { serviceType } = this.props
    return (
      <div className="btn-group">
        <button
          id="PICKUP"
          className="btn-group__btn -last-2nd-odd"
          disabled={serviceType === 'PICKUP'}
          onClick={evt => this.handleClick(evt, 'PICKUP')}
        >
          <span>Pickup</span>
        </button>
        <button
          id="DELIVERY"
          className="btn-group__btn -last-even"
          disabled={serviceType === 'DELIVERY'}
          onClick={evt => this.handleClick(evt, 'DELIVERY')}
        >
          <span>Delivery</span>
        </button>
      </div>
    )
  }
}

EditServiceType.displayName = 'EditServiceType'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { editServiceType }
)(EditServiceType)
