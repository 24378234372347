import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { confirmCancel } from '../../reducers/orders'

class OrderCancel extends Component {
  static propTypes = {
    order: propTypes.object,
    confirmCancel: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    this.props.confirmCancel(this.props.order.receipt_id)
    evt.target.blur()
  }

  render() {
    const { order } = this.props
    const isCancelable =
      order.receipt_status === 'OPEN' || order.receipt_status === 'HELD'
    return isCancelable ? (
      <button className="btn-link" onClick={this.handleClick}>
        cancel
      </button>
    ) : (
      <span>--</span>
    )
  }
}

OrderCancel.displayName = 'OrderCancel'

export default connect(null, {
  confirmCancel
})(OrderCancel)
