import { request } from '../lib/services'
import { flashMessage } from './messages'
import { handlePageError } from './page'
import { fetchItem } from './item'

const initState = {
  loading: false,
  error: ''
}

export const CUSTOMER_CLEAR = 'CUSTOMER_CLEAR'
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING'
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR'

export const clearCustomer = () => ({ type: CUSTOMER_CLEAR })
export const loadingCustomer = isLoading => ({
  type: CUSTOMER_LOADING,
  payload: isLoading
})
export const showCustomerError = msg => ({
  type: CUSTOMER_ERROR,
  payload: msg
})

export const blockCustomer = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const endpoint = `/customers/${page.id}/block`
    dispatch(loadingCustomer(true))
    return request(token, brand, endpoint, 'POST', {})
      .then(() => {
        dispatch(fetchItem())
        dispatch(flashMessage('Customer blocked!'))
      })
      .catch(err => {
        err.detail = 'Unable to block customer. Please try again.'
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(loadingCustomer(false)))
  }
}

export const unblockCustomer = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const endpoint = `/customers/${page.id}/unblock`
    return request(token, brand, endpoint, 'POST', {})
      .then(() => {
        dispatch(fetchItem())
        dispatch(flashMessage('Customer unblocked!'))
      })
      .catch(err => {
        err.detail = 'Unable to unblock customer. Please try again.'
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(loadingCustomer(false)))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case CUSTOMER_CLEAR:
      return { ...initState }
    case CUSTOMER_LOADING:
      return { ...state, loading: action.payload }
    case CUSTOMER_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
