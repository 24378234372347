import * as constants from './constants'

const filters = {
  limit: {
    label: 'Results per Page',
    type: 'select',
    field: 'limit',
    value: 100,
    options: constants.limit
  },
  businessDate: {
    label: 'Business Date',
    type: 'datePicker',
    field: 'business_date',
    value: 'today'
  },
  startDate: {
    label: 'Start Date',
    type: 'datePicker',
    field: 'start_date',
    value: 'today'
  },
  endDate: {
    label: 'End Date',
    type: 'datePicker',
    field: 'end_date',
    value: 'today'
  },
  store: {
    label: 'Store',
    type: 'select',
    field: 'store_id',
    settings: {
      endpoint: 'stores',
      value: 'store_id',
      label: 'full_name'
    },
    options: null,
    valType: constants.valTypes.int,
    includeNone: true
  },
  revenueCenter: {
    label: 'Revenue Center',
    type: 'select',
    field: 'revenue_center_id',
    settings: {
      endpoint: 'revenue-centers',
      key: 'OLO',
      value: 'revenue_center_id',
      label: 'full_name'
    },
    options: null,
    valType: constants.valTypes.int,
    includeNone: true
  },
  saleType: {
    label: 'Sale Type',
    type: 'select',
    field: 'receipt_type',
    value: constants.receiptType[0].value,
    options: constants.receiptType
  },
  orderSource: {
    label: 'Order Source',
    type: 'select',
    field: 'order_source',
    value: constants.orderSource[0].value,
    options: constants.orderSource
  },
  orderType: {
    label: 'Order Type',
    type: 'select',
    field: 'order_type',
    value: constants.orderType[0].value,
    options: constants.orderType
  },
  serviceType: {
    label: 'Service Type',
    type: 'select',
    field: 'service_type',
    value: constants.serviceType[0].value,
    options: constants.serviceType
  },
  channelType: {
    label: 'Channel Type',
    type: 'select',
    field: 'channel_type',
    value: constants.channelType[0].value,
    options: constants.channelType
  },
  orderStatus: {
    label: 'Order Status',
    type: 'select',
    field: 'receipt_status',
    value: constants.receiptStatus[0].value,
    options: constants.receiptStatus
  },
  tenderType: {
    label: 'Tender Type',
    type: 'select',
    field: 'tender_type',
    value: constants.tenderTypeAll[0].value,
    options: constants.tenderTypeAll
  },
  tenderStatus: {
    label: 'Tender Status',
    type: 'select',
    field: 'tender_status',
    value: constants.tenderStatus[0].value,
    options: constants.tenderStatus
  },
  cardType: {
    label: 'Card Type',
    type: 'select',
    field: 'card_type',
    value: constants.cardTypeFiler[0].value,
    options: constants.cardTypeFiler
  },
  tax: {
    label: 'Tax',
    type: 'select',
    field: 'tax_id',
    settings: {
      endpoint: 'taxes',
      value: 'tax_id',
      label: 'name'
    },
    options: null,
    valType: constants.valTypes.int,
    includeNone: true
  },
  salesMetric: {
    label: 'Group By',
    type: 'select',
    field: 'metric',
    value: constants.salesMetric[0].value,
    options: constants.salesMetric
  },
  redemptionsGroupBy: {
    label: 'Group By',
    type: 'select',
    field: 'group_by',
    value: constants.redemptionsGroupBy[0].value,
    options: constants.redemptionsGroupBy
  },
  timeChunk: {
    label: 'Time Increment',
    type: 'select',
    field: 'time_chunk',
    value: constants.timeChunks[2].value,
    options: constants.timeChunks
  },
  timePeriod: {
    label: 'Time Period',
    type: 'select',
    field: 'time_period',
    value: constants.timePeriods[0].value,
    options: constants.timePeriods
  },
  ratingsGte: {
    label: 'Minimum Rating',
    type: 'select',
    field: 'ratings_gte',
    value: '',
    options: constants.orderRatings
  },
  ratingsLte: {
    label: 'Maximum Rating',
    type: 'select',
    field: 'ratings_lte',
    value: '',
    options: constants.orderRatings
  },
  entityType: {
    label: 'Entity Type',
    type: 'select',
    field: 'entity_type',
    value: constants.entityType[0].value,
    options: constants.entityType
  },
  year: {
    label: 'Year',
    type: 'select',
    field: 'year',
    value: constants.year[0].value,
    options: constants.year
  },
  discounts: {
    label: 'Discount',
    type: 'select',
    field: 'discount_id',
    settings: {
      endpoint: 'discounts',
      value: 'discount_id',
      label: 'name'
    },
    options: null,
    valType: constants.valTypes.int,
    includeNone: true
  },
  promoCodes: {
    label: 'Promo Code',
    type: 'select',
    field: 'discount_id',
    settings: {
      endpoint: 'promo-codes',
      value: 'discount_id',
      label: 'name'
    },
    options: null,
    valType: constants.valTypes.int,
    includeNone: true
  }
}

export default filters
