import propTypes from 'prop-types'
import React from 'react'
import { isEmpty } from '../lib/utils'

const DisplayWaitTime = ({ serviceType, hours, edit }) => {
  if (!hours || !hours.open)
    return (
      <div className="wait-times__hours">
        <span>{serviceType}: Closed</span>
      </div>
    )
  const display = `${hours.open}-${hours.close}`
    .replace(/\s/g, '')
    .toLowerCase()
  const diff = hours.currentWait - hours.defaultWait
  const klass = diff > 0 ? '-alert' : diff < 0 ? '-success' : ''
  return (
    <div className="wait-times__hours">
      <span>
        {serviceType}: {display}
      </span>
      <button className="btn-link" onClick={edit}>
        <span className={klass}>{hours.currentWait} min wait</span>
      </button>
    </div>
  )
}

DisplayWaitTime.displayName = 'DisplayWaitTime'
DisplayWaitTime.propTypes = {
  serviceType: propTypes.string,
  hours: propTypes.object,
  edit: propTypes.func
}

const OrderMgmtHours = ({ hours, edit }) => {
  if (!hours) return null
  const { hours: h, isClosed, isTempClosed } = hours
  if (isClosed || isEmpty(h)) {
    return (
      <div className="wait-times">
        <p>The OLO revenue center for this store is permanently closed</p>
      </div>
    )
  }
  const { PICKUP, DELIVERY } = h
  return (
    <div className="wait-times">
      <button className="btn-link wait-times__status" onClick={edit}>
        {isTempClosed ? (
          <span className="-alert">Closed</span>
        ) : (
          <span className="-success">Open</span>
        )}
      </button>
      <DisplayWaitTime serviceType="Pickup" hours={PICKUP} edit={edit} />
      <DisplayWaitTime serviceType="Delivery" hours={DELIVERY} edit={edit} />
    </div>
  )
}

OrderMgmtHours.displayName = 'OrderMgmtHours'
OrderMgmtHours.propTypes = {
  hours: propTypes.object,
  edit: propTypes.func
}

export default OrderMgmtHours
