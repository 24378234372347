import propTypes from 'prop-types'
import React from 'react'

const labelTypes = ['bool', 'enum', 'dollars', 'checkbox']

const TableCellLabel = ({ label, format, name }) => {
  const hasLabel = label || labelTypes.includes(format) || name === 'ID'
  return hasLabel ? <span className="mobile-label">{name}: </span> : null
}

TableCellLabel.displayName = 'TableCellLabel'
TableCellLabel.propTypes = {
  label: propTypes.bool,
  format: propTypes.oneOfType([propTypes.object, propTypes.string]),
  name: propTypes.string
}

export default TableCellLabel
