import { clearAuth } from './auth'
import { updateUser } from './user'
import { resetOptions } from './options'
import { request } from '../lib/services'

const initState = null

const LOAD_BRAND = 'LOAD_BRAND'
const CLEAR_BRAND = 'CLEAR_BRAND'

export const clearBrand = () => ({ type: CLEAR_BRAND })
export const loadBrand = brand => ({ type: LOAD_BRAND, payload: brand })
export const chooseBrand = brand => {
  return (dispatch, getState) => {
    const { token } = getState()
    request(token, brand, '/general-settings', 'GET').then(settings => {
      const brandWithSettings = { ...brand, ...settings }
      dispatch(loadBrand(brandWithSettings))
      const { role, permissions, sections, locations } = brand
      dispatch(updateUser({ role, permissions, sections, locations }))
      dispatch(clearAuth())
      dispatch(resetOptions())
    })
  }
}

export const updateBrandSettings = settings => {
  return (dispatch, getState) => {
    const brandWithSettings = { ...getState().brand, ...settings }
    dispatch(loadBrand(brandWithSettings))
  }
}

export default function(state = initState, action) {
  switch (action.type) {
    case LOAD_BRAND:
      return action.payload
    case CLEAR_BRAND:
      return initState
    default:
      return state
  }
}
