import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Error } from './FormInputs'
import mapStyles from '../lib/googleMapsStyles'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

class GoogleMap extends Component {
  static propTypes = {
    // lat: propTypes.number,
    // lng: propTypes.number,
    address: propTypes.string,
    coordinates: propTypes.array,
    error: propTypes.string,
    resetZone: propTypes.func,
    updateCoords: propTypes.func,
    showError: propTypes.func
  }

  constructor(props) {
    super(props)
    this.googleMapRef = React.createRef()
    this.colors = {
      purple: '#5a5aff'
    }
    this.editMode = false
    this.events = []
    this.markers = []
  }

  componentDidMount() {
    // https://engineering.universe.com/building-a-google-map-in-react-b103b4ee97f1
    // this.googleMapScript = document.getElementById('google-map-script')
    // if (!this.googleMapScript) {
    this.googleMapScript = document.createElement('script')
    this.googleMapScript.setAttribute('id', 'google-map-script')
    this.googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&v=3.exp&libraries=drawing`
    window.document.body.appendChild(this.googleMapScript)
    // }
    this.googleMapScript.addEventListener('load', () => {
      if (this.googleMapRef.current) {
        this.geocode(this.props.address)
          .then(({ lat, lng }) => {
            this.lat = lat
            this.lng = lng
            this.createGoogleMap()
          })
          .catch(err => this.props.showError({ coordinates: err }))
      }
    })
  }

  componentWillUnmount() {
    this.googleMapScript.remove()
  }

  geocode = address => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const latlng = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
          resolve(latlng)
        } else {
          const msg = `Geocode of the revenue center's address was not successful for the following reason: ${status}. Please check your location address info on the Settings page and try again.`
          reject(msg)
        }
      })
    })
  }

  createGoogleMap = () => {
    var latlng = new window.google.maps.LatLng(this.lat, this.lng)
    this.googleMap = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: 15,
      center: latlng,
      scrollwheel: false,
      draggableCursor: 'crosshair',
      styles: mapStyles
    })
    this.marker = this.createMarker()
    this.drawZone()
    window.google.maps.event.addListener(
      this.googleMap,
      'click',
      this.addLatLng
    )
  }

  createMarker = () => {
    return new window.google.maps.Marker({
      map: this.googleMap,
      position: { lat: this.lat, lng: this.lng }
    })
  }

  drawZone = () => {
    const polyOptions = {
      strokeColor: this.colors.purple,
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: this.colors.purple,
      fillOpacity: 0.25
    }
    const coords = this.props.coordinates.map(latlng => {
      return new window.google.maps.LatLng(latlng[0], latlng[1])
    })
    if (coords.length) {
      polyOptions.path = coords
      const latLngBounds = new window.google.maps.LatLngBounds()
      coords.map(latLng => latLngBounds.extend(latLng))
      this.googleMap.fitBounds(latLngBounds)
    }
    // this.poly = new window.google.maps.Polygon(polyOptions)
    this.poly = new window.google.maps.Polyline(polyOptions)
    this.poly.setMap(this.googleMap)
    if (!coords.length) this.resetMap()
  }

  addLatLng = evt => {
    if (!this.editMode) return
    let path = this.poly.getPath()
    path.push(evt.latLng)
    const marker = new window.google.maps.Marker({
      position: evt.latLng,
      latitude: evt.latLng.lat(),
      longitude: evt.latLng.lng(),
      title: '#' + path.getLength(),
      map: this.googleMap,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 1,
        fillOpacity: 1,
        fillColor: this.colors.purple,
        strokeColor: this.colors.purple
      }
    })
    this.markers.push(marker)
    this.events.push(evt)
    this.props.updateCoords(evt.latLng.lat(), evt.latLng.lng())
  }

  resetMap = () => {
    this.props.resetZone()
    this.poly.getPath().clear()
    this.markers.map(marker => marker.setMap(null))
    this.googleMapRef.current.style.border = '2px solid #5a5aff'
    this.editMode = true
  }

  handleReset = evt => {
    evt.preventDefault()
    this.resetMap()
    evt.target.blur()
  }

  render() {
    const { error } = this.props
    return (
      <div className={`google-map ${error ? '-error' : ''}`}>
        {error ? (
          <label className="label" htmlFor="google-map">
            <Error error={error} />
          </label>
        ) : null}
        <button className="btn google-map__reset" onClick={this.handleReset}>
          Reset Map
        </button>
        <div
          id="google-map"
          className="google-map__map"
          ref={this.googleMapRef}
        />
      </div>
    )
  }
}

GoogleMap.displayName = 'GoogleMap'

// export { GoogleMap }

export default connect()(GoogleMap)
