import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import pageContent from '../lib/pageContent'
import { testModal } from '../reducers/modal'
import OrderSearch from './OrderSearch'
import {
  Settings,
  Map,
  MapPin,
  Chrome,
  Users,
  User,
  ShoppingBag,
  Heart,
  Code,
  Home,
  Clock,
  BarChart,
  HelpCircle,
  Gift,
  CheckSquare,
  Monitor,
  ShoppingCart,
  DollarSign,
  Sliders,
  Award,
  Grid,
  MessageSquare,
  Download
} from 'react-feather'

const icons = {
  Settings: <Settings size={null} />,
  Map: <Map size={null} />,
  MapPin: <MapPin size={null} />,
  Chrome: <Chrome size={null} />,
  Users: <Users size={null} />,
  User: <User size={null} />,
  ShoppingBag: <ShoppingBag size={null} />,
  Heart: <Heart size={null} />,
  Code: <Code size={null} />,
  Home: <Home size={null} />,
  Clock: <Clock size={null} />,
  BarChart: <BarChart size={null} />,
  HelpCircle: <HelpCircle size={null} />,
  Gift: <Gift size={null} />,
  CheckSquare: <CheckSquare size={null} />,
  Monitor: <Monitor size={null} />,
  ShoppingCart: <ShoppingCart size={null} />,
  DollarSign: <DollarSign size={null} />,
  Sliders: <Sliders size={null} />,
  Award: <Award size={null} />,
  Grid: <Grid size={null} />,
  MessageSquare: <MessageSquare size={null} />,
  Download: <Download size={null} />
}

const Section = ({ endpoint, title, description, icon }) => (
  <li className="section">
    <Link to={endpoint}>
      <span className="section__btn">
        <span className="section__line"></span>
        <span className="section__title">{title}</span>
      </span>
      <span className="section__desc">{description}</span>
      <span className="section__icon">{icons[icon]}</span>
    </Link>
  </li>
)

Section.displayName = 'Section'
Section.propTypes = {
  endpoint: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  icon: propTypes.string
}

const renderSearch = type => {
  switch (type) {
    case 'order':
      return <OrderSearch />
    default:
      return null
  }
}

class Content extends Component {
  static propTypes = {
    user: propTypes.object,
    content: propTypes.string,
    title: propTypes.string,
    sections: propTypes.array,
    testModal: propTypes.func,
    search: propTypes.string
  }

  render() {
    // throw new Error('Boom!')
    const { sections, search, user } = this.props
    const filtered =
      !user.sections || !sections
        ? []
        : !user.sections.length
        ? sections
        : sections.map(section => {
            const subsections = section.subsections
              .filter(i => !i.section || user.sections.includes(i.section))
              .filter(
                i => !i.permission || user.permissions.includes(i.permission)
              )
            return { ...section, subsections }
          })
    return (
      <div className="content__main">
        {pageContent(this.props.content)}
        {renderSearch(search)}
        {filtered.length
          ? filtered.map(section => (
              <div key={section.name} className="sections__wrapper">
                <ul className="sections">
                  {section.subsections &&
                    section.subsections.map(subsection => (
                      <Section key={subsection.endpoint} {...subsection} />
                    ))}
                </ul>
              </div>
            ))
          : null}
      </div>
    )
  }
}

Content.displayName = 'Content'

export default connect(
  state => ({
    user: state.user
  }),
  { testModal }
)(Content)
