import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reorderRecommendations } from '../reducers/recommendedItems'
import DraggableList from './DraggableList'
import RecommendedItemsResults from './RecommendedItemsResults'

class RecommendedItems extends Component {
  static propTypes = {
    // state.page.recommendedItems
    endpoint: propTypes.string,
    id: propTypes.string,
    sorting: propTypes.object,
    classes: propTypes.string,
    fields: propTypes.array,
    // ...state.recommendedItems
    items: propTypes.array,
    loading: propTypes.bool,
    error: propTypes.string,
    // stage.page
    pageId: propTypes.string,
    pageRoute: propTypes.string,
    // mapdispatchtoprops
    reorderItems: propTypes.func
  }

  render() {
    const {
      endpoint,
      id,
      sorting,
      classes,
      fields,
      items,
      loading,
      reorderRecommendations,
      error
    } = this.props
    const upsellItems = items
      .filter(i => i.recommendation_type === 'UPSELL')
      .map((i, idx) => ({ ...i, key: `UPSELL-${i[id]}-${idx}` }))
    const similarItems = items
      .filter(i => i.recommendation_type === 'SIMILAR')
      .map((i, idx) => ({ ...i, key: `SIMILAR-${i[id]}-${idx}` }))

    if (error) return null

    return (
      <div className="content__main">
        <div className="item-list">
          <h2>Upsell Items</h2>
          <RecommendedItemsResults
            items={upsellItems}
            recommendationType="UPSELL"
          />
          <DraggableList
            listEndpoint={endpoint}
            id={id}
            sorting={sorting}
            classes={classes}
            fields={fields}
            items={upsellItems}
            loading={loading}
            reorderList={reorderRecommendations}
          />
        </div>
        <div className="item-list">
          <h2>Similar Items</h2>
          <RecommendedItemsResults
            items={similarItems}
            recommendationType="SIMILAR"
          />
          <DraggableList
            listEndpoint={endpoint}
            id={id}
            sorting={sorting}
            classes={classes}
            fields={fields}
            items={similarItems}
            loading={loading}
            reorderList={reorderRecommendations}
          />
        </div>
      </div>
    )
  }
}

RecommendedItems.displayName = 'RecommendedItems'

export default connect(
  state => ({
    ...state.page.recommendedItems,
    ...state.recommendedItems
  }),
  { reorderRecommendations }
)(RecommendedItems)
