import { request } from '../lib/services'
import {
  tenderTypesMap,
  cardTypes,
  timezoneMap,
  orderTypeRevenueCenterMap
} from '../lib/constants'
import { handleError, errMessages, processErrorMessage } from '../lib/errors'
import { makeQueryParams, sortItems, capitalize } from '../lib/helpers'
import {
  greaterIso,
  makeWeekdaysExcluded,
  makeCurrentAt,
  getLatLng,
  sortRevenueCenters,
  inZone,
  parseDeliveryZone
} from '../lib/helpersOrder'
import {
  getUserTimezone,
  makeLocalDate,
  makeLocalDateStr
} from '../lib/helpersDatetime'
import { downloadPDF } from '../lib/helpersFiles'
import { redirectPage, showPageError, handlePageError } from './page'
import { openModal, closeModal, loadingModal, showModalErrors } from './modal'
import { fetchOptions } from './options'
import { flashMessage } from './messages'
import { isEmpty } from '../lib/utils'

const initState = {
  loading: false,
  loadingQuote: false,
  cartOpen: false,
  alerts: [],
  menu: null,
  orderId: null,
  customer: null,
  address: null,
  inZone: false,
  cards: [],
  giftCards: [],
  orderType: null,
  revenueCenter: null,
  serviceType: 'DELIVERY',
  timeUpdated: false,
  requestedAt: null,
  interval: 15,
  weekdayTimes: {},
  excludedTimes: {},
  holidays: [],
  orderTimes: null,
  timezone: null,
  oldCart: null,
  cart: [],
  currentItem: null,
  order: null,
  surchargeIds: null,
  discountIds: null,
  promoCodes: null,
  tip: null,
  tipSettings: {},
  eatingUtensils: null,
  servingUtensils: null,
  isTaxExempt: null,
  taxExemptId: null,
  personCount: null,
  notes: null,
  notesInternal: null,
  tenderTypes: [],
  tenders: [],
  errors: null
}

export const ORDER_CLEAR = 'ORDER_CLEAR'
export const ORDER_RESET = 'ORDER_RESET'
export const ORDER_LOADING = 'ORDER_LOADING'
export const ORDER_UPDATE = 'ORDER_UPDATE'
export const ORDER_ERROR = 'ORDER_ERROR'
export const ORDER_LOADING_QUOTE = 'ORDER_LOADING_QUOTE'
export const ORDER_ALERT_ADD = 'ORDER_ALERT_ADD'
export const ORDER_ALERT_REMOVE = 'ORDER_ALERT_REMOVE'
export const ORDER_CART_TOGGLE = 'ORDER_CART_TOGGLE'

export const toggleCart = () => ({ type: ORDER_CART_TOGGLE })
export const clearOrder = () => ({ type: ORDER_CLEAR })
export const resetOrder = () => ({ type: ORDER_RESET })
export const loadingOrder = () => ({ type: ORDER_LOADING })
export const updateOrder = data => ({ type: ORDER_UPDATE, payload: data })
export const showOrderErrors = errors => ({
  type: ORDER_ERROR,
  payload: errors
})
export const fetchingQuote = bool => ({
  type: ORDER_LOADING_QUOTE,
  payload: bool
})
export const addOrderAlert = msg => ({
  type: ORDER_ALERT_ADD,
  payload: msg
})
export const removeOrderAlert = index => ({
  type: ORDER_ALERT_REMOVE,
  payload: index
})

export const fetchValidTimes = (revenueCenterId, requestedAt) => {
  return (dispatch, getState) => {
    const { token, brand, order } = getState()
    const { revenueCenter, serviceType } = order
    const id = revenueCenterId || revenueCenter.revenue_center_id
    if (!revenueCenter) return new Promise(resolve => resolve(null))
    const endpoint = `/revenue-centers/${id}/valid-times`
    // we may NOT always have a requestedAt on the order since this
    // can't be set until a revenue center is selected
    requestedAt = requestedAt || order.requestedAt
    return request(token, brand, endpoint, 'GET')
      .then(resp => {
        // console.log(resp)
        // console.log(order.timezone)
        // console.log(JSON.stringify(resp, null, 2))
        if (resp.order_times) {
          const update = {
            requestedAt,
            orderTimes: resp.order_times
          }
          dispatch(updateOrder(update))
        } else {
          if (isEmpty(resp.first_times)) {
            throw new Error(errMessages.locationClosed)
          } else if (!resp.first_times[serviceType]) {
            throw new Error(errMessages.serviceTypeNotAvailable)
          }
          const validTimes = resp.valid_times[serviceType]
          const firstRequestedAt = resp.first_times[serviceType].utc
          const newRequestedAt = order.orderId
            ? requestedAt
            : greaterIso(
                requestedAt,
                firstRequestedAt,
                order.timezone,
                validTimes
              )
          if (newRequestedAt !== requestedAt) {
            dispatch(addOrderAlert('Requested time was updated!'))
          }
          const interval = resp.first_times[serviceType].interval
          const holidays = resp.holidays[serviceType].map(i => makeLocalDate(i))
          const weekdayTimes = makeWeekdaysExcluded(validTimes)
          const excludedTimes = resp.excluded_times[serviceType]
          const update = {
            requestedAt: newRequestedAt,
            interval,
            holidays,
            weekdayTimes,
            excludedTimes
          }
          dispatch(updateOrder(update))
        }
      })
      .then(() => {
        dispatch(fetchMenu())
      })
      .catch(err => {
        dispatch(updateOrder({ menu: null }))
        dispatch(handlePageError(err, true))
        const msg = `${err.detail || err.message}`
        dispatch(showOrderErrors({ form: msg }))
      })
  }
}

export const fetchMenu = () => {
  return (dispatch, getState) => {
    dispatch(updateOrder({ errors: null }))
    dispatch({ type: ORDER_LOADING })
    const { token, brand, order } = getState()
    const { revenueCenter, serviceType, requestedAt } = order
    if (!revenueCenter || !requestedAt) {
      return dispatch(updateOrder({ loading: false }))
    }
    const params = [
      { field: 'revenue_center_id', value: revenueCenter.revenue_center_id },
      { field: 'service_type', value: serviceType },
      { field: 'requested_at', value: requestedAt }
    ]
    const queryParams = makeQueryParams(params)
    const endpoint = `/built-menus?${queryParams}`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        // console.log(JSON.stringify(resp.menu, null, 2))
        let update = { menu: resp.menu, loading: false }
        const existingCart = order.oldCart || order.cart
        if (existingCart && existingCart.length) {
          const [newCart, missing] = reloadCart(existingCart, resp.menu)
          // console.log(JSON.stringify(newCart, null, 2))
          update = { ...update, cart: newCart, oldCart: null }
          if (missing.length) {
            const config = {
              title: 'Missing Menu Items',
              subtitle:
                'The items below are not available on the menu at this time for this service type and have been removed from the cart',
              classes: 'modal--big',
              type: 'missingItems',
              args: missing
            }
            dispatch(openModal(config))
          }
        }
        dispatch(updateOrder(update))
        if (order.order) dispatch(submitOrder())
      })
      .catch(err => {
        dispatch(updateOrder({ menu: null }))
        dispatch(handlePageError(err, true))
        const msg = `${err.detail || err.message}`
        dispatch(showOrderErrors({ form: msg }))
      })
  }
}

const makeItemLookup = menu => {
  let itemLookup = {}
  menu.map(category => {
    category.items.map(item => {
      itemLookup[item.id] = item
    })
    category.children.map(child => {
      child.items.map(item => {
        itemLookup[item.id] = item
      })
    })
  })
  return itemLookup
}

const makeGroupsLookup = item => {
  return item.groups.reduce((grpObj, i) => {
    grpObj[i.id] = i.options.reduce((optObj, o) => {
      optObj[o.id] = o.quantity
      return optObj
    }, {})
    return grpObj
  }, {})
}

const reloadItem = (item, oldItem) => {
  let newItem = makeOrderItem(item, true)
  const lookup = makeGroupsLookup(oldItem)
  newItem.groups.map(group => {
    const oldGroup = lookup[group.id.toString()]
    if (oldGroup) {
      group.options.map(option => {
        const quantity = oldGroup[option.id.toString()]
        if (quantity) option.quantity = quantity
      })
    }
  })
  newItem.quantity = oldItem.quantity
  const pricedItem = calcPrices(newItem)
  pricedItem.notes = oldItem.notes
  pricedItem.madeFor = oldItem.made_for
  return pricedItem
}

export const reloadCart = (cart, menu) => {
  const itemLookup = makeItemLookup(menu)
  const newCart = cart
    .filter(i => itemLookup[i.id])
    .map(i => {
      const item = itemLookup[i.id]
      return reloadItem(item, i)
    })
  const missing = cart.filter(i => !itemLookup[i.id])
  return [newCart, missing]
}

export const makeCustomer = customer => {
  if (!customer) return null
  return {
    customer_id: customer.customer_id,
    first_name: customer.first_name,
    last_name: customer.last_name,
    email: customer.email,
    phone: customer.phone,
    company: customer.company
  }
}

export const makeAddress = address => {
  return {
    customer_address_id: address.customer_address_id,
    street: address.street,
    unit: address.unit,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    company: address.company,
    contact: address.contact,
    phone: address.phone,
    latitude: address.latitude,
    longitude: address.longitude
  }
}

export const reorderHouseAccount = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    const endpoint = `/house-accounts/${page.id}?with_related=customers`
    return request(token, brand, endpoint)
      .then(resp => {
        if (!resp.customers.length) {
          const msg =
            'There are no customers associated with this house account. Please add one before starting an order.'
          return dispatch(showPageError(msg))
        }
        const owners = resp.customers.filter(i => i.is_owner)
        const customer = owners.length ? owners[0] : resp.customers[0]
        dispatch(reorderCustomer(customer.customer))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const reorderCustomer = customer => {
  return (dispatch, getState) => {
    dispatch(clearOrder())
    const { token, brand, item, page } = getState()
    const id = customer ? customer.customer_id : page.id
    const endpoint = `/customer-addresses?customer_id=${id}&is_active=true`
    return request(token, brand, endpoint)
      .then(resp => {
        const sorting = {
          sortBy: 'last_used_at',
          sortType: 'datetime',
          desc: true
        }
        const addresses = sortItems(resp.data, sorting)
        const address = addresses.length ? makeAddress(addresses[0]) : null
        const data = {
          customer: makeCustomer(customer || item.data),
          address: address,
          serviceType: address ? 'DELIVERY' : 'PICKUP'
        }
        dispatch(startOrder(data))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const reorderAddress = () => {
  return (dispatch, getState) => {
    dispatch(clearOrder())
    const { item, relation } = getState()
    const data = {
      customer: makeCustomer(item.data),
      address: makeAddress(relation.data),
      serviceType: 'DELIVERY'
    }
    dispatch(startOrder(data))
  }
}

export const startOrder = (data, requestedAt) => {
  return dispatch => {
    dispatch(clearOrder())
    data.requestedAt = requestedAt || makeCurrentAt()
    dispatch(updateOrder({ ...data }))
    if (data.address && data.revenueCenter) {
      dispatch(checkZone(data.address, data.revenueCenter.deliveryZone))
    }
    if (data.revenueCenter) {
      const revenueCenterId = data.revenueCenter.revenue_center_id
      dispatch(fetchValidTimes(revenueCenterId, requestedAt))
    }
    dispatch(redirectPage('/order'))
  }
}

export const startNewOrder = () => {
  return dispatch => {
    dispatch(clearOrder())
    dispatch(redirectPage('/order'))
  }
}

export const resetCheckout = () => {
  return dispatch => {
    dispatch(resetOrder())
    dispatch(submitOrder())
  }
}

const makeOrderItemGroups = (optionGroups, isEdit) => {
  const groups = optionGroups.map(g => {
    const options = g.option_items.map(o => {
      const quantity = o.opt_is_default && !isEdit ? 1 : 0
      const option = {
        id: o.id,
        name: o.name,
        price: parseFloat(o.price),
        quantity: quantity,
        isDefault: o.opt_is_default,
        increment: o.increment,
        maxQuantity: o.max_quantity,
        minQuantity: o.min_quantity
      }
      return option
    })
    const group = {
      id: g.id,
      name: g.name,
      description: g.description,
      max: g.max_options,
      min: g.min_options,
      inc: g.included_options,
      options: options
    }
    return group
  })
  return groups
}

const calcPrices = item => {
  const groups = item.groups.map(g => {
    let groupQuantity = 0
    const options = g.options.map(o => {
      const includedRemaining = Math.max(g.inc - groupQuantity, 0)
      const priceQuantity = Math.max(o.quantity - includedRemaining, 0)
      const option = { ...o, totalPrice: priceQuantity * o.price }
      groupQuantity += o.quantity
      return option
    })
    return { ...g, quantity: groupQuantity, options }
  })
  const optionsPrice = groups.reduce((t, g) => {
    return t + g.options.reduce((ot, o) => ot + o.totalPrice, 0.0)
  }, 0.0)
  const totalPrice = item.quantity * (item.price + optionsPrice)
  return { ...item, totalPrice: totalPrice, groups: groups }
}

export const makeOrderItem = (item, isEdit) => {
  const groups = makeOrderItemGroups(item.option_groups, isEdit)
  const orderItem = {
    id: item.id,
    name: item.name,
    groups: groups,
    quantity: item.min_quantity || 1 * item.increment,
    price: parseFloat(item.price),
    increment: item.increment,
    maxQuantity: item.max_quantity,
    minQuantity: item.min_quantity
  }
  const pricedItem = calcPrices(orderItem)
  return pricedItem
}

export const addNewItem = item => {
  return dispatch => {
    const orderItem = makeOrderItem(item)
    dispatch(updateOrder({ currentItem: orderItem }))
    const config = {
      title: item.name,
      subtitle: item.description,
      classes: 'modal--big modal--item',
      type: 'orderItem'
    }
    dispatch(openModal(config))
  }
}

export const setItemQuantity = quantity => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const orderItem = { ...c, quantity: quantity }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const incrementItem = () => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const newQuantity = c.maxQuantity
      ? Math.min(c.quantity + c.increment, c.maxQuantity)
      : c.quantity + c.increment
    const orderItem = { ...c, quantity: newQuantity }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const decrementItem = () => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const newQuantity = Math.max(c.quantity - c.increment, c.minQuantity)
    const orderItem = { ...c, quantity: newQuantity }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const selectOption = (groupId, optionId) => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const groups = c.groups.map(group => {
      if (group.id === groupId) {
        group.options.map(option => {
          option.quantity = option.id === optionId ? 1 : 0
          return option
        })
      }
      return group
    })
    const orderItem = { ...c, groups: groups }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const setOptionQuantity = (groupId, optionId, quantity) => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const groups = c.groups.map(group => {
      if (group.id === groupId) {
        const count = group.options
          .filter(o => o.id !== optionId)
          .reduce((t, o) => t + o.quantity, 0)
        if (group.max !== 0 && count >= group.max) return group
        group.options.map(option => {
          if (option.id === optionId) {
            if (quantity === '') {
              option.quantity = ''
            } else {
              const quantities = [quantity]
              if (option.maxQuantity !== 0) quantities.push(option.maxQuantity)
              if (group.max !== 0) quantities.push(group.max - count)
              option.quantity = Math.min(...quantities)
            }
          }
          return option
        })
      }
      return group
    })
    const orderItem = { ...c, groups: groups }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const incrementOption = (groupId, optionId) => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const groups = c.groups.map(group => {
      if (group.id === groupId) {
        const count = group.options
          .filter(o => o.id !== optionId)
          .reduce((t, o) => t + o.quantity, 0)
        if (group.max !== 0 && count >= group.max) return group
        group.options.map(option => {
          if (option.id === optionId) {
            const quantity = option.quantity + option.increment
            const quantities = [quantity]
            if (option.maxQuantity !== 0) quantities.push(option.maxQuantity)
            if (group.max !== 0) quantities.push(group.max - count)
            option.quantity = Math.min(...quantities)
          }
          return option
        })
      }
      return group
    })
    const orderItem = { ...c, groups: groups }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const decrementOption = (groupId, optionId) => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const groups = c.groups.map(group => {
      if (group.id === groupId) {
        group.options.map(option => {
          if (option.id === optionId) {
            option.quantity = Math.max(
              option.quantity - option.increment,
              0
              // need to set all option min_quantity to 0 in menu regen
              // option.minQuantity
            )
          }
          return option
        })
      }
      return group
    })
    const orderItem = { ...c, groups: groups }
    const pricedItem = calcPrices(orderItem)
    dispatch(updateOrder({ currentItem: pricedItem }))
  }
}

export const updateMadeFor = madeFor => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const orderItem = { ...c, madeFor: madeFor }
    dispatch(updateOrder({ currentItem: orderItem }))
  }
}

export const updateItemNotes = notes => {
  return (dispatch, getState) => {
    const { currentItem: c } = getState().order
    const orderItem = { ...c, notes: notes }
    dispatch(updateOrder({ currentItem: orderItem }))
  }
}

export const addItemToCart = () => {
  return (dispatch, getState) => {
    const { currentItem, cart, order } = getState().order
    if (typeof currentItem.index === 'undefined') {
      const newItem = { ...currentItem, index: cart.length }
      dispatch(updateOrder({ cart: [...cart, newItem] }))
    } else {
      let updatedCart = [...cart]
      updatedCart[currentItem.index] = currentItem
      dispatch(updateOrder({ cart: updatedCart }))
      if (order) dispatch(submitOrder())
    }
    dispatch(closeModal())
  }
}

export const editItemInCart = index => {
  return (dispatch, getState) => {
    const { cart } = getState().order
    const newItem = { ...cart[index], index: index }
    dispatch(updateOrder({ currentItem: newItem }))
    const config = {
      title: newItem.name,
      subtitle: newItem.description,
      classes: 'modal--big modal--item',
      type: 'orderItem'
    }
    dispatch(openModal(config))
  }
}

export const removeItemFromCart = index => {
  return (dispatch, getState) => {
    const { cart } = getState().order
    const updatedCart = cart.filter((i, itemIndex) => itemIndex !== index)
    dispatch(updateOrder({ cart: updatedCart }))
  }
}

const makeSimpleCart = cart => {
  const simpleCart = cart.map(i => {
    const groups = i.groups.map(g => {
      const options = g.options
        .filter(o => o.quantity !== 0)
        .map(o => ({ id: o.id, quantity: o.quantity }))
      return { id: g.id, options: options }
    })
    return {
      id: i.id,
      quantity: i.quantity,
      groups: groups,
      made_for: i.madeFor || '',
      notes: i.notes || ''
    }
  })
  return simpleCart
}

const isNullOrUndef = value => {
  return value === null || value === undefined ? true : false
}

const setDefault = (orderAttr, respAttr) => {
  return isNullOrUndef(orderAttr) ? respAttr : orderAttr
}

export const fetchQuote = (data, linkRef) => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    dispatch(fetchingQuote(true))
    return request(
      token,
      brand,
      '/orders/quote',
      'POST',
      data,
      null,
      'application/pdf'
    )
      .then(blob => {
        const currentDate = makeLocalDateStr(0, 'yyyy-MM-dd_hh-mm-ss')
        const filename = `${brand.short_name}-order-quote_${currentDate}.pdf`
        downloadPDF(blob, linkRef, 'application/pdf', filename)
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        window.scroll(0, 0)
        const errors = handleError(err)
        dispatch(showOrderErrors(errors))
      })
      .finally(() => dispatch(fetchingQuote(false)))
  }
}

export const submittingOrder = isSave => {
  return dispatch => {
    const config = {
      title: isSave ? 'Saving Order' : 'Submitting Order',
      subtitle: 'Please do not refresh the page or hit the back button',
      classes: 'modal--loading',
      type: 'submitting'
    }
    dispatch(openModal(config))
  }
}

const makeExistingTenders = order => {
  if (!order.tenders_applied) return []
  const applied = order.tenders_applied
  const tendersTotal = applied
    .reduce((t, i) => (t += parseFloat(i.amount)), 0.0)
    .toFixed(2)
  const plug = parseFloat(order.total) - parseFloat(tendersTotal)
  const tenders = applied.map((i, index) => {
    const amount =
      index === 0 ? (parseFloat(i.amount) + plug).toFixed(2) : i.amount
    let tender = { tenderType: i.tender_type.toUpperCase(), amount: amount }
    switch (i.tender_type) {
      case 'credit':
        return {
          ...tender,
          customer_card_id: i.customer_card_id,
          cardName: makeSavedCardName(i)
        }
      default:
        return tender
    }
  })
  return tenders
}

// linkRef is used to create quotes as downloadable PDFs
export const submitOrder = (isSubmit, isSave, linkRef) => {
  return (dispatch, getState) => {
    const { token, brand, order, user } = getState()
    const {
      orderId,
      revenueCenter,
      serviceType,
      requestedAt,
      cart,
      address,
      customer,
      surchargeIds,
      discountIds,
      promoCodes,
      tip,
      eatingUtensils,
      servingUtensils,
      isTaxExempt,
      taxExemptId,
      personCount,
      notes,
      notesInternal,
      tenders
    } = order
    dispatch(updateOrder({ errors: null, cartOpen: false }))
    const data = {
      validate: !isSubmit && !isSave,
      save: isSave,
      user_id: user.user_id,
      device_type: 'desktop', // TODO: need to enable device detection
      location_id: revenueCenter.revenue_center_id,
      service_type: serviceType.toLowerCase(),
      requested_at: requestedAt,
      cart: makeSimpleCart(cart),
      customer: { customer_id: customer.customer_id },
      address: address
    }
    if (orderId) {
      data.order_id = orderId
    }
    if (surchargeIds) {
      data.surcharges = surchargeIds.map(id => ({ surcharge_id: id }))
    }
    if (discountIds) {
      data.discounts = discountIds.map(id => ({ discount_id: id }))
    }
    if (promoCodes) {
      data.promo_codes = promoCodes
    }
    if (tip) {
      data.tip = tip
    }
    if (!isNullOrUndef(eatingUtensils)) {
      data.eating_utensils = eatingUtensils
    }
    if (!isNullOrUndef(servingUtensils)) {
      data.serving_utensils = servingUtensils
    }
    if (!isNullOrUndef(isTaxExempt)) {
      data.is_tax_exempt = isTaxExempt
    }
    if (!isNullOrUndef(taxExemptId)) {
      data.tax_exempt_id = taxExemptId
    }
    if (!isNullOrUndef(personCount)) {
      const count = parseInt(personCount)
      data.person_count = isNaN(count) ? 0 : count
    }
    if (!isNullOrUndef(notes)) {
      data.notes = notes
    }
    if (!isNullOrUndef(notesInternal)) {
      data.notes_internal = notesInternal
    }
    if (linkRef) return dispatch(fetchQuote(data, linkRef))
    if (isSubmit) {
      dispatch(submittingOrder())
      if (tenders && tenders.length) {
        data.tenders = tenders.reduce((arr, t) => {
          const tender = {
            tender_type: t.tenderType.toLowerCase(),
            amount: parseFloat(t.amount).toFixed(2)
          }
          for (const key in t) {
            if (!['tenderType', 'amount', 'cardName'].includes(key)) {
              tender[key] = t[key]
            }
          }
          arr.push(tender)
          return arr
        }, [])
      }
    } else if (isSave) {
      dispatch(submittingOrder(true))
    } else {
      dispatch(loadingOrder())
    }
    // console.log(JSON.stringify(data, null, 2))
    request(token, brand, '/orders', 'POST', data)
      .then(resp => {
        // console.log(resp)
        // console.log(JSON.stringify(resp, null, 2))
        if (isSubmit || isSave) {
          dispatch(closeModal())
          dispatch(clearOrder())
          return dispatch(redirectPage(`/orders/${resp.order_id}`))
        }
        const surchargeIds = resp.surcharges.map(i => i.surcharge_id)
        const discountIds = resp.discounts
          .filter(i => !i.is_promo_code)
          .map(i => i.discount_id)
        const promoCodes = resp.discounts
          .filter(i => i.is_promo_code)
          .map(i => i.name)
        const tenders = makeExistingTenders(resp)
        const payload = {
          order: resp,
          loading: false,
          surchargeIds: surchargeIds,
          discountIds: discountIds,
          promoCodes: promoCodes,
          tip: resp.tip,
          tipSettings: resp.tip_settings,
          isTaxExempt: resp.is_tax_exempt,
          taxExemptId: resp.tax_exempt_id,
          eatingUtensils: setDefault(eatingUtensils, resp.eating_utensils),
          servingUtensils: setDefault(servingUtensils, resp.serving_utensils),
          personCount: setDefault(personCount, resp.person_count),
          notes: setDefault(notes, resp.notes),
          notesInternal: setDefault(notesInternal, resp.notes_internal),
          tenderTypes: resp.tender_types,
          cards: resp.customer.cards || [],
          giftCards: resp.customer.gift_cards || [],
          tenders: tenders
        }
        dispatch(updateOrder(payload))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        dispatch(closeModal())
        window.scroll(0, 0)
        if (data.promo_codes) {
          const validCodes = order.order ? order.order.promo_codes : []
          const promoCodes = order.promoCodes.filter(i =>
            validCodes.includes(i)
          )
          dispatch(updateOrder({ promoCodes: promoCodes }))
        }
        const errors = handleOrderErrors(err, cart)
        dispatch(showOrderErrors(errors))
      })
  }
}

const makeCartLookup = cart => {
  let itemLookup = {}
  cart.map(item => {
    itemLookup[item.id] = item.name
    item.groups.map(group => {
      group.options.map(option => {
        itemLookup[option.id] = option.name
      })
    })
  })
  return itemLookup
}

const processCode = code => {
  switch (code) {
    case 'surcharges.invalid_surcharge':
      return 'Surcharge Error'
    default:
      return `${capitalize(code.split('.')[0])} Error`
  }
}

export const handleOrderErrors = (err, cart) => {
  if (!err.params) {
    return { form: errMessages.serverError, fields: {} }
  }
  let fields = {}
  for (let key in err.params) {
    const error = err.params[key]
    let msg = ''
    if (error.code === 'cart.invalid_cart') {
      const itemIds = error.pointers.map(i => i.source.pointer)
      const lookup = makeCartLookup(cart)
      const items = itemIds.map(id => lookup[id]).join(', ')
      msg = `Invalid cart - certain items are not available and need to be removed: ${items}`
      fields[error.code] = msg
    } else if (error.code) {
      const errMsg = processErrorMessage(error.message)
      msg = `${processCode(error.code)}: ${errMsg}`
      fields[error.code] = msg
    } else {
      // if we don't have an error code, we have a regular API error
      const field = key.split('.')[1]
      fields[field] = `${field.replace('_', ' ')}: ${error}`
    }
  }
  return { form: '', fields: fields }
}

export const removeOrder = () => {
  return dispatch => {
    dispatch(updateOrder({ order: null, cartOpen: false }))
  }
}

export const toggleEatingUtensils = () => {
  return (dispatch, getState) => {
    dispatch(updateOrder({ eatingUtensils: !getState().order.eatingUtensils }))
  }
}

export const toggleServingUtensils = () => {
  return (dispatch, getState) => {
    dispatch(
      updateOrder({ servingUtensils: !getState().order.servingUtensils })
    )
  }
}

export const toggleTaxExempt = () => {
  return (dispatch, getState) => {
    dispatch(updateOrder({ isTaxExempt: !getState().order.isTaxExempt }))
    dispatch(submitOrder())
  }
}

export const updateTaxExemptId = taxExemptId => {
  return dispatch => {
    dispatch(updateOrder({ taxExemptId }))
  }
}

export const updatePersonCount = personCount => {
  return dispatch => {
    dispatch(updateOrder({ personCount }))
  }
}

export const updateNotes = notes => {
  return dispatch => {
    dispatch(updateOrder({ notes }))
  }
}

export const updateNotesInternal = notesInternal => {
  return dispatch => {
    dispatch(updateOrder({ notesInternal }))
  }
}

export const addSurcharges = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Surcharges',
      subtitle: 'Add one or more surchages',
      classes: 'modal--big modal--item',
      type: 'surcharges'
    }
    dispatch(fetchOptions(['surcharges']))
      .then(() => {
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        window.scroll(0, 0)
        const errors = handleError(err)
        dispatch(showOrderErrors(errors))
      })
  }
}

export const addSurcharge = surchargeId => {
  return (dispatch, getState) => {
    const { order } = getState()
    const surchargeIds = [...order.surchargeIds, surchargeId]
    dispatch(closeModal())
    dispatch(updateOrder({ surchargeIds: surchargeIds }))
    dispatch(submitOrder())
  }
}

export const removeSurcharge = surchargeId => {
  return (dispatch, getState) => {
    const { order } = getState()
    const surchargeIds = order.surchargeIds.filter(i => i !== surchargeId)
    dispatch(updateOrder({ surchargeIds: surchargeIds }))
    dispatch(submitOrder())
  }
}

export const addDiscounts = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Discounts',
      subtitle: 'Add one or more discounts',
      classes: 'modal--big modal--item',
      type: 'discounts'
    }
    dispatch(fetchOptions(['discounts']))
      .then(() => {
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        window.scroll(0, 0)
        const errors = handleError(err)
        dispatch(showOrderErrors(errors))
      })
  }
}

export const addDiscount = discountId => {
  return (dispatch, getState) => {
    const { order } = getState()
    const discountIds = [...order.discountIds, discountId]
    dispatch(closeModal())
    dispatch(updateOrder({ discountIds: discountIds }))
    dispatch(submitOrder())
  }
}

export const removeDiscount = discountId => {
  return (dispatch, getState) => {
    const { order } = getState()
    const discountIds = order.discountIds.filter(i => i !== discountId)
    dispatch(updateOrder({ discountIds: discountIds }))
    dispatch(submitOrder())
  }
}

export const addPromoCodes = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Promo Codes',
      subtitle: 'Search for a promo code and apply to order',
      classes: 'modal--big modal--item',
      type: 'promoCodes'
    }
    dispatch(openModal(config))
  }
}

export const addPromoCode = promoCode => {
  return (dispatch, getState) => {
    const { order } = getState()
    const promoCodes = [...(order.promoCodes || []), promoCode]
    dispatch(closeModal())
    dispatch(updateOrder({ promoCodes: promoCodes }))
    dispatch(submitOrder())
  }
}

export const removePromoCode = promoCode => {
  return (dispatch, getState) => {
    const { order } = getState()
    const promoCodes = order.promoCodes.filter(i => i !== promoCode)
    dispatch(updateOrder({ promoCodes: promoCodes }))
    dispatch(submitOrder())
  }
}

export const editTip = () => {
  return (dispatch, getState) => {
    const { tipSettings } = getState().order
    dispatch(loadingModal())
    const config = {
      title: 'Edit tip amount',
      subtitle: `The default tip at this revenue center is ${tipSettings.gratuity}%`,
      type: 'orderTip'
    }
    dispatch(openModal(config))
  }
}

export const updateTip = values => {
  return dispatch => {
    return new Promise(resolve => {
      try {
        const tip = values.custom_tip || values.standard_tip
        const amount = parseFloat(tip).toFixed(2)
        if (isNaN(amount)) {
          const errors = { form: 'Invalid dollar amount' }
          dispatch(showModalErrors(errors))
          resolve(tip)
        } else {
          dispatch(closeModal())
          dispatch(updateOrder({ tip: amount }))
          dispatch(submitOrder())
          resolve(tip)
        }
      } catch (e) {
        const errors = { form: 'Invalid dollar amount' }
        dispatch(showModalErrors(errors))
        resolve(e)
      }
    })
  }
}

export const changeOrderType = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Choose an order type',
      type: 'orderType'
    }
    dispatch(openModal(config))
  }
}

export const updateOrderType = orderType => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    let { requestedAt, timezone } = getState().order
    requestedAt = requestedAt || makeCurrentAt()
    timezone = timezone || getUserTimezone()
    const update = {
      orderType,
      requestedAt,
      timezone,
      revenueCenter: null,
      timeUpdated: true
    }
    dispatch(updateOrder(update))
  }
}

export const checkZone = (address, deliveryZone) => {
  return (dispatch, getState) => {
    const { serviceType } = getState().order
    const latLng = getLatLng(address)
    if (!latLng || !deliveryZone) {
      return dispatch(updateOrder({ inZone: false }))
    }
    const inDeliveryZone = inZone(latLng, deliveryZone)
    if (inDeliveryZone) {
      dispatch(updateOrder({ inZone: true }))
    } else {
      dispatch(updateOrder({ inZone: false }))
      if (serviceType === 'DELIVERY') {
        dispatch(addOrderAlert(errMessages.notInZone))
      }
    }
  }
}

export const changeRevenueCenter = () => {
  return (dispatch, getState) => {
    dispatch(loadingModal())
    const { token, brand, order } = getState()
    const { orderType, address } = order
    const rcType = orderTypeRevenueCenterMap[orderType]
    const endpoint = `/revenue-centers?revenue_center_type=${rcType}&with_related=delivery_zones&expand=store&with_settings=address`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const latLng = getLatLng(address)
        const withAddress = resp.data.filter(i => i.address)
        const revenueCenters = sortRevenueCenters(withAddress, latLng)
        // revenueCenters.map(i =>
        //   console.log(i.full_name, i.distance, i.inZone, i.priority)
        // )
        const config = {
          title: 'Choose a revenue center',
          classes: 'modal--big modal--wide modal--rc',
          type: 'revenueCenter',
          args: [revenueCenters]
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const makeRevenueCenter = data => {
  const [coordinates] = parseDeliveryZone(data.delivery_zones)
  const revenueCenter = {
    revenue_center_id: data.revenue_center_id,
    name: data.short_name || data.full_name,
    street: data.address.street,
    cross_streets: data.address.cross_streets,
    city: data.address.city,
    state: data.address.state,
    postal_code: data.address.postal_code,
    phone: data.address.phone,
    latitude: data.address.latitude,
    longitude: data.address.longitude,
    deliveryZone: coordinates
  }
  return revenueCenter
}

export const updateRevenueCenter = selected => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    const revenueCenter = makeRevenueCenter(selected)
    const timezone = timezoneMap[selected.store.timezone]
    const { address } = getState().order
    dispatch(checkZone(address, revenueCenter.deliveryZone))
    dispatch(updateOrder({ revenueCenter, timezone }))
    const revenueCenterId = selected.revenue_center_id
    dispatch(fetchValidTimes(revenueCenterId))
  }
}

export const updateServiceType = serviceType => {
  return (dispatch, getState) => {
    const { revenueCenter } = getState().order
    dispatch(updateOrder({ menu: null, serviceType: serviceType }))
    if (revenueCenter) {
      const revenueCenterId = revenueCenter.revenue_center_id
      dispatch(fetchValidTimes(revenueCenterId))
    }
  }
}

export const updateTimzeone = timezone => {
  return dispatch => {
    const newTimezone = timezone || getUserTimezone()
    dispatch(updateOrder({ timezone: newTimezone }))
  }
}

export const updateRequestedAt = requestedAt => {
  return (dispatch, getState) => {
    const { order } = getState()
    if (requestedAt !== order.requestedAt) {
      dispatch(updateOrder({ requestedAt: requestedAt, timeUpdated: true }))
      dispatch(fetchMenu())
    }
  }
}

export const editCustomer = () => {
  return (dispatch, getState) => {
    const { order } = getState()
    dispatch(loadingModal())
    const classes = order.customer
      ? 'modal--big modal--wide'
      : 'modal--big modal--item'
    const subtitle = order.customer
      ? "Update this customer's info, including their password, if needed"
      : 'Find an existing customer or create a new one'
    const config = {
      title: 'Customer',
      subtitle: subtitle,
      classes: classes,
      type: 'customer'
    }
    dispatch(openModal(config))
  }
}

export const addCustomer = customerId => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    return request(token, brand, `/customers/${customerId}`)
      .then(data => {
        dispatch(closeModal())
        dispatch(updateOrder({ customer: makeCustomer(data) }))
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export const updateCustomer = values => {
  return (dispatch, getState) => {
    const { token, brand, order } = getState()
    const { customer } = order
    const id = customer ? customer.customer_id : null
    const endpoint = `/customers${id ? `/${id}` : ''}`
    const method = id ? 'PUT' : 'POST'
    return request(token, brand, endpoint, method, values)
      .then(data => {
        dispatch(closeModal())
        dispatch(updateOrder({ customer: makeCustomer(data) }))
        const msg = id ? 'Successfully updated!' : 'Successfully created!'
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export const removeCustomer = () => {
  return dispatch => {
    dispatch(closeModal())
    dispatch(updateOrder({ customer: null }))
  }
}

export const editAddress = () => {
  return (dispatch, getState) => {
    const { token, brand, order } = getState()
    if (!order.customer) {
      return dispatch(flashMessage('Please add a customer first'))
    }
    dispatch(loadingModal())
    const classes = order.address ? 'modal--big modal--wide' : 'modal--big'
    const subtitle = order.address
      ? 'Update the address for this order'
      : "Choose one of this customer's addresses or add a new one"
    const id = order.customer.customer_id
    const endpoint = `/customer-addresses?customer_id=${id}&is_active=true&limit=1000`
    return request(token, brand, endpoint)
      .then(resp => {
        const sorting = {
          sortBy: 'last_used_at',
          sortType: 'datetime',
          desc: true
        }
        const addresses = sortItems(resp.data, sorting)
        const config = {
          title: 'Address',
          subtitle: subtitle,
          classes: classes,
          type: 'address',
          args: [addresses]
        }
        dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const addAddress = selected => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    const address = makeAddress(selected)
    const { revenueCenter } = getState().order
    const deliveryZone = revenueCenter ? revenueCenter.deliveryZone : null
    dispatch(checkZone(address, deliveryZone))
    dispatch(updateOrder({ address: address }))
    // dispatch(flashMessage('Successfully added!'))
  }
}

export const updateAddress = values => {
  return (dispatch, getState) => {
    const { token, brand, order } = getState()
    const { customer, address } = order
    if (!customer) {
      const errors = { form: 'Please add a customer before adding an address' }
      dispatch(showModalErrors(errors))
    }
    values = { customer_id: customer.customer_id, ...values }
    const id = address ? address.customer_address_id : null
    const endpoint = `/customer-addresses${id ? `/${id}` : ''}`
    const method = id ? 'PUT' : 'POST'
    return request(token, brand, endpoint, method, values)
      .then(data => {
        dispatch(closeModal())
        dispatch(updateOrder({ address: data }))
        const msg = id ? 'Successfully updated!' : 'Successfully created!'
        dispatch(flashMessage(msg))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err)
        dispatch(showModalErrors(errors))
      })
  }
}

export const removeAddress = () => {
  return (dispatch, getState) => {
    dispatch(closeModal())
    const { serviceType } = getState().order
    if (serviceType === 'DELIVERY') {
      dispatch(addOrderAlert(errMessages.missingAddress))
    }
    dispatch(updateOrder({ address: null, inZone: false }))
  }
}

export const tenderOther = tenderType => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: `Add ${tenderTypesMap[tenderType]} Tender`,
      subtitle: 'Enter the amount to tender to this type',
      type: 'tenderOther',
      args: [tenderType]
    }
    dispatch(openModal(config))
  }
}

export const addTender = tenderType => {
  return dispatch => {
    switch (tenderType) {
      case 'CREDIT':
        return dispatch(tenderCredit())
      default:
        return dispatch(tenderOther(tenderType))
    }
  }
}

export const addTenderOther = tender => {
  return (dispatch, getState) => {
    const { tenders } = getState().order
    return new Promise(resolve => {
      try {
        const amount = parseFloat(tender.amount).toFixed(2)
        const newTender = { ...tender, amount: amount }
        const newTenders = [...(tenders || []), newTender]
        dispatch(updateOrder({ tenders: newTenders }))
        dispatch(closeModal())
        resolve(tenders)
      } catch (e) {
        const errors = { form: 'Invalid dollar amount' }
        dispatch(showModalErrors(errors))
        resolve(e)
      }
    })
  }
}

export const tenderCredit = () => {
  return (dispatch, getState) => {
    dispatch(loadingModal())
    const { cards } = getState().order
    if (!cards.length) return dispatch(tenderCreditNew())
    const config = {
      title: 'Choose A Saved Card',
      subtitle: 'Enter the amount and choose a saved card',
      classes: 'modal--big',
      type: 'tenderCredit'
    }
    dispatch(openModal(config))
  }
}

export const tenderCreditNew = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Add A New Card',
      subtitle: 'Enter the amount and fill out the form below',
      classes: 'modal--big',
      type: 'tenderCreditNew'
    }
    dispatch(openModal(config))
  }
}

const makeSavedCardName = card => {
  return `Saved ${cardTypes[card.card_type_id]} ending in ${card.last4}`
}

const makeCreditTender = (tender, amount, cards) => {
  if (parseInt(tender.customer_card_id)) {
    const card = cards.filter(
      i => i.customer_card_id === parseInt(tender.customer_card_id)
    )[0]
    return {
      tenderType: tender.tenderType,
      amount: amount,
      customer_card_id: parseInt(tender.customer_card_id),
      cardName: makeSavedCardName(card)
    }
  } else {
    return {
      tenderType: tender.tenderType,
      amount: amount,
      acct: tender.acct,
      exp: `${tender.exp_month}${tender.exp_year}`,
      cvv: tender.cvv,
      zip: tender.zip,
      save: tender.save,
      cardName: `New card ending in ${tender.acct.slice(-4)}`
    }
  }
}

export const addTenderCredit = tender => {
  return (dispatch, getState) => {
    const { tenders, cards } = getState().order
    return new Promise(resolve => {
      try {
        const amount = parseFloat(tender.amount).toFixed(2)
        const newTender = makeCreditTender(tender, amount, cards)
        const newTenders = [...(tenders || []), newTender]
        dispatch(updateOrder({ tenders: newTenders }))
        dispatch(closeModal())
        resolve(tenders)
      } catch (e) {
        const errors = { form: 'Invalid dollar amount' }
        dispatch(showModalErrors(errors))
        resolve(e)
      }
    })
  }
}

export const removeTender = index => {
  return (dispatch, getState) => {
    const { tenders } = getState().order
    const newTenders = tenders.filter((i, idx) => index !== idx)
    dispatch(updateOrder({ tenders: newTenders }))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ORDER_CLEAR:
      return {
        ...initState,
        requestedAt: makeCurrentAt(),
        timezone: getUserTimezone()
      }
    case ORDER_RESET:
      return { ...state, order: null, surchargeIds: null, discountIds: null }
    case ORDER_LOADING:
      return { ...state, loading: true }
    case ORDER_LOADING_QUOTE:
      return { ...state, loadingQuote: action.payload }
    case ORDER_UPDATE:
      return { ...state, ...action.payload, errors: null }
    case ORDER_ERROR:
      return { ...state, errors: action.payload, loading: false }
    case ORDER_ALERT_ADD:
      return { ...state, alerts: [...state.alerts, action.payload] }
    case ORDER_ALERT_REMOVE:
      return {
        ...state,
        alerts: state.alerts.filter((i, index) => index !== action.payload)
      }
    case ORDER_CART_TOGGLE:
      return { ...state, cartOpen: !state.cartOpen }
    default:
      return state
  }
}
