import { request, fetchAll } from '../lib/services'
import { differenceWith, isEqual } from 'lodash'
import { revenueCenters, revenueCenterOrderTypeMap } from '../lib/constants'
import { flashMessage } from './messages'
import { openModal, closeModal } from './modal'
import { handlePageError, clearPageError } from './page'
import { processMenuItems } from './list'
import { makeCategories, makeGroups } from './options'
import { sortItems } from '../lib/helpers'
import { makeFilterLookup } from '../lib/helpersPage'
import { isEmpty } from '../lib/utils'

const initState = {
  loading: false,
  submitting: false,
  filters: [],
  rowId: null,
  rowObj: null,
  rows: null,
  colId: null,
  colObj: null,
  cols: null,
  items: null,
  disabled: null,
  isModifiers: false,
  endpoint: '',
  error: ''
}

export const ITEM_AVAILS_CLEAR = 'ITEM_AVAILS_CLEAR'
export const ITEM_AVAILS_CLEAR_ITEMS = 'ITEM_AVAILS_CLEAR_ITEMS'
export const ITEM_AVAILS_FILTERS = 'ITEM_AVAILS_FILTERS'
export const ITEM_AVAILS_LOADING = 'ITEM_AVAILS_LOADING'
export const ITEM_AVAILS_LOAD = 'ITEM_AVAILS_LOAD'
export const ITEM_AVAILS_SUBMITTING = 'ITEM_AVAILS_SUBMITTING'
export const ITEM_AVAILS_ERRORS = 'ITEM_AVAILS_ERRORS'
export const ITEM_AVAILS_UPDATE = 'ITEM_AVAILS_UPDATE'
export const ITEM_AVAILS_OBJ_UPDATE = 'ITEM_AVAILS_OBJ_UPDATE'

export const clearItemAvails = () => ({ type: ITEM_AVAILS_CLEAR })
export const clearItemAvailsItems = () => ({ type: ITEM_AVAILS_CLEAR_ITEMS })
export const loadingItemAvails = () => ({ type: ITEM_AVAILS_LOADING })
export const submittingItemAvails = () => ({ type: ITEM_AVAILS_SUBMITTING })
export const updateFilters = filters => ({
  type: ITEM_AVAILS_FILTERS,
  payload: filters
})
export const loadAvails = data => ({ type: ITEM_AVAILS_LOAD, payload: data })
export const showAvailsErrors = msg => ({
  type: ITEM_AVAILS_ERRORS,
  payload: msg
})
export const updateAvailsObj = obj => ({
  type: ITEM_AVAILS_OBJ_UPDATE,
  payload: obj
})

const makeModifiersItems = (modifiers, selected) => {
  if (!selected || !selected.length) {
    return modifiers.map(i => ({ item_id: i.value, status: 'DISPLAYED' }))
  } else {
    const displayedMods = selected.map(i => ({
      item_id: i.item_id,
      status: 'DISPLAYED'
    }))
    const defaultMods = selected
      .filter(i => i.is_default)
      .map(i => ({ item_id: i.item_id, status: 'DEFAULT' }))
    return [...displayedMods, ...defaultMods]
  }
}

const makeModifiersData = items => {
  const defaultMap = items
    .filter(i => i.status === 'DEFAULT')
    .reduce((obj, i) => {
      obj[i.item_id] = true
      return obj
    }, {})
  return items
    .filter(i => i.status === 'DISPLAYED')
    .map(i => {
      return {
        item_id: i.item_id,
        is_default: defaultMap[i.item_id] || false
      }
    })
}

const makeAll = (rows, cols, colId) => {
  let all = []
  rows.map(row => {
    cols.map(col => {
      all.push({ item_id: row.value, [colId]: col.value })
    })
  })
  return all
}

const makeRestrictedItems = (rows, cols, items, colId) => {
  const all = makeAll(rows, cols, colId)
  return differenceWith(all, items, isEqual)
}

const makeAvails = (items, { name, value }) => {
  return !name
    ? items
    : items.map(i => {
        return { name: i[name], value: i[value] }
      })
}

const makeRevenueCentersOrStores = (items, { filter, value }) => {
  const store_group_id =
    filter.store_group_id !== 'none' ? parseInt(filter.store_group_id) : null
  if (!filter.revenue_center_type)
    return items
      .filter(store => store.store_group_id === store_group_id)
      .map(store => {
        return {
          name: store.short_name || store.full_name,
          value: store[value]
        }
      })
  const rcs = items
    .filter(store => store.store_group_id === store_group_id)
    .reduce((rcs, store) => {
      const revenueCenters = store.revenue_centers
        .filter(i => i.revenue_center_type === filter.revenue_center_type)
        .map(rc => {
          return { name: rc.short_name || rc.full_name, value: rc[value] }
        })
      return [...rcs, ...revenueCenters]
    }, [])
  return rcs.filter(i => i !== undefined)
}

const makeSortedItems = (items, endpoint) => {
  const [, params] = endpoint.split('?')
  const processed = processMenuItems(items, params)
  const sorting = { sortBy: 'menu_position', sortType: 'order' }
  return sortItems(processed, sorting)
}

const returnData = (token, brand, obj) => {
  if (Array.isArray(obj.endpoint)) return obj.endpoint
  if (obj.endpoint.includes('restricted') || obj.endpoint.includes('expired')) {
    const items = fetchAll(token, brand, obj.endpoint)
    return makeAvails(items, obj)
  }
  return request(token, brand, obj.endpoint).then(resp => {
    let items = resp.data || resp
    if (obj.endpoint.startsWith('/items')) {
      items = makeSortedItems(items, obj.endpoint)
    }
    if (obj.filter) {
      return makeRevenueCentersOrStores(items, obj)
    }
    if (obj.entity) {
      const [entity, attr] = obj.entity.split('.')
      items = items[entity].map(i => ({ ...i[attr] }))
    }
    return makeAvails(items, obj)
  })
}

const makeFilters = (options, locations, isExpired) => {
  let storeGroups = []
  const noGroup = { short_name: 'No Group', store_group_id: 'none' }
  if (locations.length) {
    const storeGroupIds = options.stores.map(i => i.store_group_id)
    storeGroups = options['store-groups'].length
      ? options['store-groups'].filter(i =>
          storeGroupIds.includes(i.store_group_id)
        )
      : [noGroup]
  } else {
    storeGroups = [noGroup, ...options['store-groups']]
  }
  storeGroups = storeGroups.map(i => ({
    value: i.store_group_id,
    name: i.short_name || i.full_name
  }))
  const storeGroupFilter = {
    label: 'Store Group',
    type: 'select',
    field: 'store_group_id',
    value: storeGroups.length ? storeGroups[0].value : '',
    options: storeGroups
  }
  const rcTypeFilter = {
    label: isExpired ? 'Order Type' : 'Revenue Center Type',
    type: 'select',
    field: 'revenue_center_type',
    value: 'OLO',
    options: [...revenueCenters].slice(1)
  }
  return [storeGroupFilter, rcTypeFilter]
}

const makeStoreGroupParams = storeGroupFilter => {
  if (!storeGroupFilter || isEmpty(storeGroupFilter)) return ''
  return storeGroupFilter.value !== 'none'
    ? `&${storeGroupFilter.field}=${storeGroupFilter.value}`
    : ''
}

const fetchMenuFilters = revenueCenterType => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const catEndpoint =
      '/categories?is_active=true&with_related=menus&limit=1000'
    const catRequest = request(token, brand, catEndpoint)
    const modEndpoint =
      '/modifier-groups?is_active=true&with_related=menus&limit=1000'
    const modRequest = request(token, brand, modEndpoint)
    return Promise.all([catRequest, modRequest])
      .then(([catResp, modResp]) => {
        const orderType = revenueCenterOrderTypeMap[revenueCenterType]
        let categories =
          revenueCenterType === 'POS'
            ? catResp.data
            : catResp.data.filter(i => i.order_type === orderType)
        const catsByMenu = makeCategories(categories)
        categories = [{ value: '', name: 'none selected' }, ...catsByMenu]
        const realCats = categories.filter(i => i.value)
        const categoryFilter = {
          label: 'Category',
          type: 'select',
          field: 'category_id',
          value: realCats.length ? realCats[0].value : '',
          options: categories
        }
        let groups = modResp.data
        const modsByMenu = makeGroups(groups)
        groups = [{ value: '', name: 'none selected' }, ...modsByMenu]
        const groupsFilter = {
          label: 'Modifier Group',
          type: 'select',
          field: 'modifier_group_id',
          value: '',
          options: groups
        }
        return [categoryFilter, groupsFilter]
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showAvailsErrors('There was an error retriving this data'))
      })
  }
}

export const fetchItemAvails = () => {
  return (dispatch, getState) => {
    const { options, user, page } = getState()
    const isExpired = page.avails.endpoint.includes('expired')
    const filters = makeFilters(options, user.locations, isExpired)
    dispatch(updateFilters(filters))
    const revenueCenterType = filters[1].value
    dispatch(fetchMenuFilters(revenueCenterType)).then(menuFilters => {
      if (filters && menuFilters) {
        const allFilters = [...filters, ...menuFilters]
        dispatch(updateFilters(allFilters))
        dispatch(makeFetchObjects(allFilters))
      }
    })
  }
}

const makeFetchObjects = filters => {
  return (dispatch, getState) => {
    const { avails } = getState().page
    const lookup = makeFilterLookup(filters)
    const itemFilter = lookup.category_id || lookup.modifier_group_id
    if (!itemFilter || !avails) return dispatch(clearItemAvailsItems())
    dispatch(loadingItemAvails())
    let rowObj = null,
      colObj = null,
      itemParams = null,
      storeGroupParams = null
    itemParams = `${itemFilter.field}=${itemFilter.value}`
    const withRelated =
      itemFilter.field === 'category_id' ? 'categories' : 'modifiers'
    rowObj = {
      endpoint: `/items?${itemParams}&is_active=true&limit=1000&with_related=${withRelated}`,
      name: 'short_name',
      value: 'item_id'
    }
    dispatch(updateAvailsObj({ rowObj: rowObj }))
    const storeGroupFilter = lookup.store_group_id
    storeGroupParams = makeStoreGroupParams(storeGroupFilter)
    if (avails.endpoint.includes('expired')) {
      colObj = {
        endpoint: `/stores?${storeGroupParams}`,
        name: 'short_name', // TODO: this is not being used in makeRevenueCenters
        value: 'store_id',
        filter: {
          store_group_id: storeGroupFilter ? storeGroupFilter.value : 'none'
        }
      }
    } else {
      const rcTypeFilter = lookup.revenue_center_type
      colObj = {
        endpoint: `/stores?with_related=true${storeGroupParams}`,
        name: 'short_name', // TODO: this is not being used in makeRevenueCenters
        value: 'revenue_center_id',
        filter: {
          revenue_center_type: rcTypeFilter.value,
          store_group_id: storeGroupFilter ? storeGroupFilter.value : 'none'
        }
      }
    }
    dispatch(updateAvailsObj({ colObj: colObj }))
    const itemObj = {
      endpoint: `${avails.endpoint}?${itemParams}${storeGroupParams}&limit=1000`
    }
    dispatch(fetchAvails(rowObj, colObj, itemObj))
  }
}

export const fetchAvails = (rowObj, colObj, itemObj, config) => {
  return (dispatch, getState) => {
    const { token, brand, itemAvails } = getState()
    const rowsRequest = returnData(token, brand, rowObj)
    const colsRequest = returnData(token, brand, colObj)
    const itemsRequest = returnData(token, brand, itemObj)
    let disabledRequest = null
    // if (itemObj.endpoint.includes('expired')) {
    //   const endpoint = itemObj.endpoint.replace('expired', 'restricted')
    //   disabledRequest = returnData(token, brand, { endpoint: endpoint })
    // }
    const promises = [rowsRequest, colsRequest, itemsRequest, disabledRequest]
    Promise.all(promises)
      .then(values => {
        let [rows, cols, items, disabled] = values
        // console.log(rows)
        // console.log(cols)
        // console.log('items', items)
        // console.log(disabled)
        if (itemObj.isModifiers) {
          items = makeModifiersItems(rows, items)
        } else {
          items = makeRestrictedItems(rows, cols, items, colObj.value)
        }
        // console.log(JSON.stringify(items, null, 2))
        const newItemAvails = {
          rowId: rowObj.value,
          rowObj: itemAvails.rowObj,
          rows: rows,
          colObj: itemAvails.colObj,
          colId: colObj.value,
          cols: cols,
          items: items,
          disabled: disabled,
          isModifiers: itemObj.isModifiers || false,
          endpoint: itemObj.endpoint
        }
        dispatch(loadAvails(newItemAvails))
        if (config) dispatch(openModal(config))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showAvailsErrors('There was an error retriving this data'))
      })
  }
}

export const fetchItemsOnly = filters => {
  return (dispatch, getState) => {
    dispatch(loadingItemAvails())
    const { token, brand, page, itemAvails } = getState()
    const lookup = makeFilterLookup(filters)
    const itemFilter = lookup.category_id || lookup.modifier_group_id
    if (!itemFilter) return dispatch(clearItemAvailsItems())
    const itemParams = `${itemFilter.field}=${itemFilter.value}`
    const withRelated =
      itemFilter.field === 'category_id' ? 'categories' : 'modifier_groups'
    const rowObj = {
      endpoint: `/items?${itemParams}&is_active=true&limit=1000&with_related=${withRelated}`,
      name: 'short_name',
      value: 'item_id'
    }
    dispatch(updateAvailsObj({ rowObj: rowObj }))
    const rowsRequest = returnData(token, brand, rowObj)
    const storeGroupFilter = lookup.store_group_id
    const storeGroupParams = makeStoreGroupParams(storeGroupFilter)
    const endpoint = page.avails.endpoint
    const itemObj = {
      endpoint: `${endpoint}?${itemParams}${storeGroupParams}&limit=1000`
    }
    const itemsRequest = returnData(token, brand, itemObj)
    let disabledRequest = null
    // if (itemObj.endpoint.includes('expired')) {
    //   const endpoint = itemObj.endpoint.replace('expired', 'restricted')
    //   disabledRequest = returnData(token, brand, { endpoint: endpoint })
    // }
    const promises = [rowsRequest, itemsRequest, disabledRequest]
    Promise.all(promises)
      .then(([rows, items, disabled]) => {
        items = makeRestrictedItems(
          rows,
          itemAvails.cols,
          items,
          itemAvails.colId
        )
        const newItemAvails = {
          rowId: rowObj.value,
          rowObj: rowObj,
          rows: rows,
          items: items,
          disabled: disabled,
          endpoint: itemObj.endpoint
        }
        dispatch(loadAvails(newItemAvails))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showAvailsErrors('There was an error retriving this data'))
      })
  }
}

export const fetchRevenueCentersOnly = filters => {
  return (dispatch, getState) => {
    dispatch(loadingItemAvails())
    const { token, brand, page, itemAvails } = getState()
    const lookup = makeFilterLookup(filters)
    const itemFilter = lookup.category_id || lookup.modifier_group_id
    if (!itemFilter) return dispatch(clearItemAvailsItems())
    const itemParams = `${itemFilter.field}=${itemFilter.value}`
    const storeGroupFilter = lookup.store_group_id
    const storeGroupParams = makeStoreGroupParams(storeGroupFilter)
    let colObj = null
    if (page.avails.endpoint.includes('expired')) {
      colObj = {
        endpoint: `/stores?${storeGroupParams}`,
        name: 'short_name', // TODO: this is not being used in makeRevenueCenters
        value: 'store_id',
        filter: {
          store_group_id: storeGroupFilter ? storeGroupFilter.value : 'none'
        }
      }
    } else {
      const rcTypeFilter = lookup.revenue_center_type
      colObj = {
        endpoint: `/stores?with_related=true${storeGroupParams}`,
        name: 'full_name',
        value: 'revenue_center_id',
        filter: {
          revenue_center_type: rcTypeFilter.value,
          store_group_id: storeGroupFilter.value
        }
      }
    }
    dispatch(updateAvailsObj({ colObj: colObj }))
    const colsRequest = returnData(token, brand, colObj)
    const endpoint = page.avails.endpoint
    const itemObj = {
      endpoint: `${endpoint}?${itemParams}${storeGroupParams}&limit=1000`
    }
    const itemsRequest = returnData(token, brand, itemObj)
    let disabledRequest = null
    // if (itemObj.endpoint.includes('expired')) {
    //   const endpoint = itemObj.endpoint.replace('expired', 'restricted')
    //   disabledRequest = returnData(token, brand, { endpoint: endpoint })
    // }
    const promises = [colsRequest, itemsRequest, disabledRequest]
    Promise.all(promises)
      .then(([cols, items, disabled]) => {
        items = makeRestrictedItems(itemAvails.rows, cols, items, colObj.value)
        const newItemAvails = {
          colObj: itemAvails.colObj,
          colId: colObj.value,
          cols: cols,
          items: items,
          disabled: disabled,
          endpoint: itemObj.endpoint
        }
        dispatch(loadAvails(newItemAvails))
      })
      .catch(err => {
        dispatch(handlePageError(err))
        dispatch(showAvailsErrors('There was an error retriving this data'))
      })
  }
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const { filters } = getState().itemAvails
    // dispatch(loadingItemAvails())
    let newFilters
    if (filter.field === 'category_id') {
      newFilters = filters.map(i => {
        switch (i.field) {
          case 'category_id':
            return { ...i, value: parseInt(filter.value) }
          case 'modifier_group_id':
            return { ...i, value: '' }
          default:
            return i
        }
      })
      dispatch(updateFilters(newFilters))
      dispatch(fetchItemsOnly(newFilters))
    } else if (filter.field === 'modifier_group_id') {
      newFilters = filters.map(i => {
        switch (i.field) {
          case 'category_id':
            return { ...i, value: '' }
          case 'modifier_group_id':
            return { ...i, value: parseInt(filter.value) }
          default:
            return i
        }
      })
      dispatch(updateFilters(newFilters))
      dispatch(fetchItemsOnly(newFilters))
    } else if (filter.field === 'store_group_id') {
      const storeGroupId = parseInt(filter.value) || filter.value
      newFilters = filters.map(i => {
        switch (i.field) {
          case 'store_group_id':
            return { ...i, value: storeGroupId }
          default:
            return i
        }
      })
      dispatch(updateFilters(newFilters))
      dispatch(fetchRevenueCentersOnly(newFilters))
    } else if (filter.field === 'revenue_center_type') {
      const revenueCenterType = filter.value
      newFilters = filters
        .map(i => {
          switch (i.field) {
            case 'revenue_center_type':
              return { ...i, value: revenueCenterType }
            default:
              return i
          }
        })
        .slice(0, 2)
      dispatch(fetchMenuFilters(revenueCenterType)).then(menuFilters => {
        if (newFilters && menuFilters) {
          const allFilters = [...newFilters, ...menuFilters]
          dispatch(updateFilters(allFilters))
          dispatch(makeFetchObjects(allFilters))
        }
      })
    }
  }
}

export const updateAvail = (avail, active) => {
  return (dispatch, getState) => {
    const {
      itemAvails: { items, rowId, colId }
    } = getState()
    const newItems = active
      ? [...items, avail]
      : items.filter(
          i => i[rowId] !== avail[rowId] || i[colId] !== avail[colId]
        )
    dispatch({ type: ITEM_AVAILS_UPDATE, payload: newItems })
  }
}

export const updateRow = rowVal => {
  return (dispatch, getState) => {
    const {
      itemAvails: { items, cols, rowId, colId }
    } = getState()
    const matching = items.filter(i => i[rowId] === rowVal)
    let newData = items.filter(i => i[rowId] !== rowVal)
    if (matching.length < cols.length) {
      const newRow = cols.map(col => {
        return {
          [rowId]: rowVal,
          [colId]: col.value
        }
      })
      newData = [...newData, ...newRow]
    }
    dispatch({ type: ITEM_AVAILS_UPDATE, payload: newData })
  }
}

export const updateColumn = colVal => {
  return (dispatch, getState) => {
    const {
      itemAvails: { items, rows, rowId, colId }
    } = getState()
    const matching = items.filter(i => i[colId] === colVal)
    let newData = items.filter(i => i[colId] !== colVal)
    if (matching.length < rows.length) {
      const newRow = rows.map(row => {
        return {
          [rowId]: row.value,
          [colId]: colVal
        }
      })
      newData = [...newData, ...newRow]
    }
    dispatch({ type: ITEM_AVAILS_UPDATE, payload: newData })
  }
}

export const updateAll = () => {
  return (dispatch, getState) => {
    const {
      itemAvails: { items, rows, cols, rowId, colId }
    } = getState()
    let newData = []
    if (items.length < rows.length * cols.length) {
      rows.map(row => {
        cols.map(col => {
          newData.push({ [rowId]: row.value, [colId]: col.value })
        })
      })
    }
    dispatch({ type: ITEM_AVAILS_UPDATE, payload: newData })
  }
}

export const updateAvails = () => {
  return (dispatch, getState) => {
    const { token, brand, itemAvails } = getState()
    const { items, disabled, rows, cols, colId, endpoint, isModifiers } =
      itemAvails
    dispatch(submittingItemAvails())
    if (isModifiers) {
      const data = makeModifiersData(items)
      request(token, brand, endpoint, 'PUT', data)
        .then(() => {
          dispatch(closeModal())
          setTimeout(() => {
            dispatch(clearItemAvails())
          }, 500)
          dispatch(flashMessage('Successfully updated!'))
        })
        .catch(err => {
          dispatch(clearItemAvails())
          dispatch(handlePageError(err))
          dispatch(showAvailsErrors('There was an error retriving this data'))
        })
    } else {
      const allItems = disabled ? items.concat(disabled) : items
      const restricted = makeRestrictedItems(rows, cols, allItems, colId)
      const removeRequest = restricted.length
        ? request(token, brand, endpoint, 'POST', restricted)
        : null
      const addBackRequest = request(token, brand, endpoint, 'DELETE', allItems)
      Promise.all([removeRequest, addBackRequest])
        .then(() => {
          dispatch(flashMessage('Successfully updated!'))
        })
        .catch(err => {
          dispatch(handlePageError(err))
          dispatch(showAvailsErrors('There was an error retriving this data'))
        })
        .finally(() => dispatch(submittingItemAvails()))
    }
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case ITEM_AVAILS_CLEAR:
      return { ...initState }
    case ITEM_AVAILS_FILTERS:
      return { ...state, filters: action.payload }
    case ITEM_AVAILS_SUBMITTING:
      return { ...state, submitting: !state.submitting }
    case ITEM_AVAILS_LOADING:
      return { ...state, items: null, disabled: null, loading: true }
    case ITEM_AVAILS_CLEAR_ITEMS:
      return {
        ...state,
        rows: [],
        items: null,
        disabled: null,
        loading: true
      }
    case ITEM_AVAILS_LOAD:
      return { ...state, ...action.payload, error: '' }
    case ITEM_AVAILS_UPDATE:
      return { ...state, items: action.payload, error: '' }
    case ITEM_AVAILS_ERRORS:
      return { ...state, error: action.payload }
    case ITEM_AVAILS_OBJ_UPDATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
