import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateCustomer } from '../../reducers/giftCard'
import Search from '../Search'

class GiftCardCustomer extends Component {
  static propTypes = {
    updateCustomer: propTypes.func
  }

  render() {
    return (
      <Search
        endpoint="customers"
        resultId="customer_id"
        resultName={['first_name', 'last_name']}
        resultParens="email"
        placeholder="enter customer name or email"
        resultAction={this.props.updateCustomer}
      />
    )
  }
}

GiftCardCustomer.displayName = 'GiftCardCustomer'

export default connect(
  null,
  { updateCustomer }
)(GiftCardCustomer)
