import propTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  orderTypeRevenueCenterMap,
  prepStatus,
  timezoneMap
} from '../lib/constants'
import { formatDollars, cleanPhone, capitalize } from '../lib/helpers'
import {
  makeRequestedAt,
  makeZonedDate,
  makeMakeTime
} from '../lib/helpersDatetime'
import { isEmpty } from '../lib/utils'
import BarLoader from 'react-spinners/BarLoader'

const OrderAddress = ({ address }) => (
  <ul className="order-card__section">
    <li>{address.company || ' '}</li>
    <li>
      {address.street}
      {address.unit && `, ${address.unit}`}
    </li>
    <li>
      {address.city}, {address.state} {address.postal_code}
    </li>
    {(address.contact || address.phone) && (
      <li>
        {`${address.contact} @ ` || ''}
        {address.phone ? cleanPhone(address.phone) : ''}
      </li>
    )}
  </ul>
)

OrderAddress.displayName = 'OrderAddress'
OrderAddress.propTypes = {
  address: propTypes.object
}

const OrderCustomer = ({ customer }) => (
  <ul className="order-card__section">
    <li className="bold">
      {customer.first_name} {customer.last_name}
    </li>
    <li>{customer.phone ? cleanPhone(customer.phone) : 'no phone provided'}</li>
  </ul>
)

OrderCustomer.displayName = 'OrderCustomer'
OrderCustomer.propTypes = {
  customer: propTypes.object
}

const OrderSent = ({ order }) => {
  if (!order.pos_sent && !order.dispatched) return null
  const pos = order.pos_service ? capitalize(order.pos_service) : 'KDS'
  return (
    <ul className="order-card__section">
      {order.pos_sent && <li>Sent to {pos}</li>}
      {order.dispatched && (
        <li>
          Sent to {capitalize(order.delivery_service)}{' '}
          {order.delivery_id ? (
            <span>
              (#
              {order.delivery_url ? (
                <a
                  href={order.delivery_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.delivery_id}
                </a>
              ) : (
                order.delivery_id
              )}
              )
            </span>
          ) : null}
        </li>
      )}
    </ul>
  )
}

OrderSent.displayName = 'OrderSent'
OrderSent.propTypes = {
  order: propTypes.object
}

const OrderPrepStatus = ({ orderId, currentStatus, updateStatus }) => {
  if (!currentStatus) return null
  const orderPrepStatus = prepStatus.find(i => i.value === currentStatus)
  if (!orderPrepStatus) return null
  const { name } = orderPrepStatus
  const klass = ''
  return (
    <ul className="order-card__section">
      <li>
        Prep Status: <span className={`header-text ${klass}`}>{name}</span>
        &nbsp; &nbsp;
        {currentStatus !== 'FULFILLED' ? (
          <button
            className="btn-link"
            onClick={evt => updateStatus(evt, orderId, currentStatus)}
          >
            edit status
          </button>
        ) : null}
      </li>
    </ul>
  )
}

OrderPrepStatus.displayName = 'OrderPrepStatus'
OrderPrepStatus.propTypes = {
  prep_status: propTypes.string
}

const OrderButton = ({ order, text, classes, handler, disabled = false }) => {
  const confirmCharge = order.revenue_center.confirm_charge
  return (
    <button
      className={`btn ${classes || ''}`}
      onClick={evt => handler(evt, order.receipt_id, confirmCharge)}
      disabled={order.disabled || disabled || !handler}
    >
      <span>{text}</span>
    </button>
  )
}

OrderButton.displayName = 'OrderButton'
OrderButton.propTypes = {
  order: propTypes.object,
  text: propTypes.string,
  classes: propTypes.string,
  handler: propTypes.func,
  disabled: propTypes.bool
}

const OrderDisabled = () => (
  <div className="order-card__disabled">
    <div className="order-card__disabled__working">
      <BarLoader size={36} color={'#5a5aff'} />
      <span>Working...</span>
    </div>
  </div>
)

const formatTender = tender => {
  switch (tender.tender_type) {
    case 'CREDIT':
      return `${tender.card_type} ${tender.last4}`
    default:
      return tender.tender_type
        .split('_')
        .map(i => capitalize(i))
        .join(' ')
  }
}

const makeTenders = tenders => {
  return tenders && tenders.length
    ? tenders.map(i => formatTender(i)).join(', ')
    : ''
}

const makeFulfillment = ff => {
  if (!ff) return null
  const arrival = ff.arrival_info ? ` @ ${ff.arrival_info}` : ''
  return `Curbside: ${ff.vehicle_type || ''} ${ff.vehicle_color || ''} ${
    ff.vehicle_id || ''
  }${arrival}`
}

const OrderCard = ({
  order,
  dates,
  hours,
  rcCounts,
  compact,
  handlers = {}
}) => {
  const c = order.customer
  const a = order.customer_address
  const rc = order.revenue_center
  const fulfillment = makeFulfillment(order.order_fulfillment)
  const orderId = order.receipt_id
  const orderClass = `-${order.order_type.toLowerCase()}`
  const serviceClass = `-${order.service_type.toLowerCase()}`
  const tz = timezoneMap[order.timezone]
  const timeRange =
    order.service_type === 'PICKUP' || order.service_type === 'WALKIN'
      ? rc.pickup_time_range
      : rc.delivery_time_range
  const requestedAt = makeRequestedAt(order.requested_at, tz, timeRange)
  const isToday = requestedAt.startsWith(dates.today)
  const isTomorrow = requestedAt.startsWith(dates.tomorrow)
  const dayClass = isToday ? '-today' : isTomorrow ? '-tomorrow' : ''
  const notReviewed = order.order_type === 'CATERING' && !order.is_reviewed
  const statusClass = `-${order.receipt_status.toLowerCase()}`
  const isLargeOrder =
    parseFloat(rc.large_order_minimum) > 0 &&
    parseFloat(order.subtotal) > parseFloat(rc.large_order_minimum)
  const isOpen = order.receipt_status === 'OPEN'
  const isHeld = order.receipt_status === 'HELD'
  const isClosed = order.receipt_status === 'CLOSED'
  const isRefunded = order.tender_status === 'REFUNDED'
  const rcCount = rcCounts[rc.revenue_center_type]
  const showName = rcCount && rcCount > 1
  const currentLocalDate = makeZonedDate(tz)
  let pastDue = false
  const serviceType =
    order.service_type === 'WALKIN' ? 'PICKUP' : order.service_type
  const hst = hours ? hours[serviceType] : {}
  const currentWait = hst && !isEmpty(hst) ? hst.currentWait : 0
  if (order.order_type === 'MAIN_MENU' && isOpen) {
    const makeTime = makeMakeTime(order.requested_at, tz, currentWait)
    pastDue = currentLocalDate > makeTime
  }
  const newClass = notReviewed || pastDue ? '-order-alert' : ''
  let klass = `order-card ${orderClass} ${serviceClass} ${dayClass} ${statusClass} ${newClass}`
  klass += order.disabled ? ' -order-disabled' : ''
  let orderType = orderTypeRevenueCenterMap[order.order_type]
  orderType = orderType !== 'OLO' ? capitalize(orderType) : orderType
  const tenderType = makeTenders(order.tenders)
  const isEditable = isOpen || isHeld
  const editAction = {
    text: 'Edit Order',
    handler: handlers.edit,
    disabled: !isEditable
  }
  const refundAction = {
    text: 'Refund Order',
    handler: handlers.refund,
    disabled: isRefunded
  }
  const editButton = isClosed ? refundAction : editAction
  const confirm = {
    text: 'Confirm',
    handler: handlers.close,
    disabled: !isOpen
  }
  // console.log(order.receipt_id, order.pos_sent)
  const resendToPos = {
    text: 'Resend to POS',
    handler: handlers.resendToPos,
    disabled: isOpen || order.pos_sent
  }
  const confirmButton = isClosed ? resendToPos : confirm
  const actions = [
    { text: 'View Details', handler: handlers.viewDetails },
    { text: 'Print', handler: handlers.print },
    { text: 'Resend Email', handler: handlers.resendEmail },
    editButton,
    confirmButton,
    { text: 'Cancel', handler: handlers.cancel, disabled: !isEditable }
  ]
  return (
    <div className={klass}>
      <div className="order-card__container">
        {order.disabled && <OrderDisabled />}
        {notReviewed && (
          <div className="order-card__review">
            <button
              className="btn"
              onClick={evt => handlers.markReviewed(evt, orderId)}
              disabled={!handlers.markReviewed}
            >
              Mark Reviewed
            </button>
          </div>
        )}
        {pastDue && (
          <div className="order-card__review">
            <button
              className="btn"
              onClick={evt => handlers.close(evt, orderId)}
            >
              Confirm Order
            </button>
          </div>
        )}
        {/* <div className={`order-card__type ${orderClass}`}>
          {orderType.charAt(0)}
        </div> */}
        <div className={`order-card__alert ${statusClass}`}>
          {order.receipt_status}
        </div>
        {/* {(isToday || isTomorrow) && (
          <div className={`order-card__alert ${dayClass}`}>
            {isToday ? 'Today' : isTomorrow ? 'Tomorrow' : ''}
          </div>
        )} */}
        <div className="order-card__header">
          <h2>
            <NavLink to={`/orders/${orderId}`}>
              {orderType} Order #{orderId}
            </NavLink>
          </h2>
        </div>
        <div className="order-card__content">
          <ul className="order-card__section">
            {showName && (
              <li className="bold">
                <span className="header-text">{rc.full_name}</span>
              </li>
            )}
            <li className="bold">
              <span className="header-text">{order.service_type}</span>
              {isLargeOrder && <span className="-large">Large Order</span>}
            </li>
            {fulfillment && <li className="">{fulfillment}</li>}
            <li className={`bold ${dayClass}`}>
              {requestedAt.replace(dates.today, 'TODAY')}
              {/* {isToday && <span className="-large">Today</span>} */}
            </li>
            <li className="bold">
              {formatDollars(order.total)} &mdash;{' '}
              <span className="header-text">{tenderType}</span>
            </li>
            {order.order_prep_time && !isClosed && (
              <li className="bold alert">Prep Time: {order.order_prep_time}</li>
            )}
            {isRefunded && <li className="bold alert upper">Refunded</li>}
            {/* <li className={`bold`}>
              {formatDollars(order.total)}
              {isLargeOrder && <span className="-large">Large Order</span>}
            </li> */}
          </ul>
          {!compact && (
            <>
              {c && <OrderCustomer customer={c} />}
              {a && <OrderAddress address={a} />}
            </>
          )}
          <OrderSent order={order} />
          <OrderPrepStatus
            orderId={orderId}
            currentStatus={order.prep_status}
            updateStatus={handlers.updatePrepStatus}
          />
          <div className="order-card__footer">
            {actions.map(action => (
              <OrderButton key={action.text} order={order} {...action} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

OrderCard.displayName = 'OrderCard'
OrderCard.propTypes = {
  order: propTypes.object,
  dates: propTypes.object,
  hours: propTypes.object,
  rcCounts: propTypes.object,
  compact: propTypes.bool,
  handlers: propTypes.object
}

export default OrderCard
