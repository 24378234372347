import propTypes from 'prop-types'
import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../../lib/helpersPage'
import Results from './Results'
import ButtonCSV from './ButtonCSV'
import { ResponsiveBar } from './Charts'
import ToggleDisplay from './ToggleDisplay'
import Table from '../Table'
import { sortItems, capitalize } from '../../lib/helpers'
import Totals from './Totals'

const fields = [
  {
    name: 'Item',
    field: ['item_name']
  },
  // {
  //   name: 'Base Price',
  //   field: ['base_price'],
  //   format: 'dollars'
  // },
  // {
  //   name: 'Base Sales',
  //   field: ['base_sales'],
  //   format: 'dollars'
  // },
  {
    name: 'Price',
    field: ['price'],
    format: 'dollars'
  },
  {
    name: 'Quantity',
    field: ['quantity'],
    format: 'quantity'
  },
  {
    name: 'Sales',
    field: ['sales'],
    format: 'dollars'
  }
]

const makeChartData = data => {
  if (!data || !data.length) return []
  return data.map(i => ({
    name: i.item_name,
    quantity: Math.max(parseInt(i.quantity), 0),
    sales: Math.max(parseFloat(i.sales), 0.0)
  }))
}

const makeMix = (data, sortBy) => {
  const sorting = { sortBy: 'sortBy', sortType: 'order', desc: true }
  let menuItems = [...data]
    .filter(i => i.entity_type === 'MENU_ITEM')
    .map(i => ({ ...i, sortBy: parseFloat(i[sortBy]) }))
  menuItems = sortItems(menuItems, sorting)
  let modifiers = [...data]
    .filter(i => i.entity_type === 'MODIFIER')
    .map(i => ({ ...i, sortBy: parseFloat(i[sortBy]) }))
  modifiers = sortItems(modifiers, sorting)
  return { menuItems, modifiers }
}

const makeTotals = data => {
  if (!data || !data.length) return null
  const quantity = data.reduce((t, i) => (t += parseInt(i.quantity)), 0.0)
  const sales = data.reduce((t, i) => (t += parseFloat(i.sales)), 0.0)
  const price = quantity ? sales / quantity : 0.0
  return {
    item_name: 'Totals',
    price,
    quantity,
    sales
  }
}

const ToggleSort = ({ sortBy, handler }) => (
  <div className="toggle-group">
    <button className="btn" disabled={sortBy === 'quantity'} onClick={handler}>
      Quantity
    </button>
    <button className="btn" disabled={sortBy === 'sales'} onClick={handler}>
      Sales
    </button>
  </div>
)

ToggleSort.displayName = 'ToggleSort'
ToggleSort.propTypes = {
  sortBy: propTypes.bool,
  handler: propTypes.func
}

const ProductMix = ({ data, handlers }) => {
  const [showData, setShowData] = useState(false)
  const [sortBy, setSortBy] = useState('quantity')

  const toggleDisplay = evt => {
    evt.preventDefault()
    setShowData(!showData)
    evt.target.blur()
  }

  const toggleSort = evt => {
    evt.preventDefault()
    setSortBy(sortBy === 'quantity' ? 'sales' : 'quantity')
    evt.target.blur()
  }
  const { menuItems, modifiers } = makeMix(data, sortBy)
  const menuItemsTotals = makeTotals(menuItems)
  const modifiersTotals = makeTotals(modifiers)
  const menuItemsChart = makeChartData(menuItems)
  const modifiersChart = makeChartData(modifiers)
  const format = sortBy === 'quantity' ? 'quantity' : 'dollars'
  return (
    <>
      <div className="report__actions">
        <div className="report__actions__container">
          <div className="report__actions__left">
            <Results data={data} {...handlers} />
          </div>
          <div className="report__actions__right">
            <ToggleSort sortBy={sortBy} handler={toggleSort} />
            <ToggleDisplay showData={showData} handler={toggleDisplay} />
            <ButtonCSV
              label="Grouped CSV"
              endpoint="/reporting/product-mix-by-group/csv"
            />
            <ButtonCSV endpoint="/reporting/product-mix/csv" />
          </div>
        </div>
      </div>
      <TransitionGroup component={null}>
        {showData && (
          <CSSTransition {...cssTransitionFade}>
            <div className="reports">
              <div className="report report--product-mix">
                <div className="report__header">
                  <h2>Menu Items</h2>
                </div>
                <Totals fields={fields} totals={menuItemsTotals} />
                <Table fields={fields} items={menuItems} />
              </div>
              <div className="report report--product-mix">
                <div className="report__header">
                  <h2>Modifiers</h2>
                </div>
                <Totals fields={fields} totals={modifiersTotals} />
                <Table fields={fields} items={modifiers} />
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {!showData && (
        <div className="reports">
          <div className="report report--product-mix">
            <div className="report__header">
              <h2>Menu Items {capitalize(sortBy)}</h2>
            </div>
            <ResponsiveBar
              height={menuItemsChart.length * 35 + 50}
              data={menuItemsChart}
              bars={[sortBy]}
              showLegend={false}
              format={format}
              xAxis={{ orientation: 'bottom', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 120
              }}
              labelPosition="right"
            />
          </div>
          <div className="report report--product-mix">
            <div className="report__header">
              <h2>Modifiers {capitalize(sortBy)}</h2>
            </div>
            <ResponsiveBar
              height={modifiersChart.length * 35 + 50}
              data={modifiersChart}
              bars={[sortBy]}
              showLegend={false}
              format={format}
              xAxis={{ orientation: 'bottom', type: 'number' }}
              yAxis={{
                orientation: 'left',
                dataKey: 'name',
                type: 'category',
                interval: 0,
                width: 120
              }}
              labelPosition="right"
            />
          </div>
        </div>
      )}
    </>
  )
}

ProductMix.displayName = 'ProductMix'
ProductMix.propTypes = {
  data: propTypes.array,
  handlers: propTypes.object
}

export default ProductMix
