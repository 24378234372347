import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { serviceTypes, serviceType, weekdays } from '../lib/constants'
import { capitalize } from '../lib/helpers'
import { cssTransitionFade } from '../lib/helpersPage'
import {
  updateAvail,
  updateWeekday,
  updateServiceType,
  updateDaypart,
  updateAvails
} from '../reducers/dayparts'
import { CheckboxSimple } from './FormInputs'
import ClipLoader from 'react-spinners/ClipLoader'

const makeAvailsList = avails => {
  if (!avails) return []
  return avails.map(
    i => `${i.daypart_id}-${i.service_type}-${i.weekday.toUpperCase()}`
  )
}

const makeDayparts = (dayparts, availsList) => {
  if (!dayparts) return []
  return dayparts.map(d => {
    const availsByWeekday = weekdays.map(w => {
      return {
        name: w.toUpperCase(),
        serviceTypes: serviceTypes.map(s => {
          const availId = `${d.daypart_id}-${s}-${w.toUpperCase()}`
          return {
            id: availId,
            checked: availsList.indexOf(availId) >= 0
          }
        })
      }
    })
    return { ...d, weekdays: availsByWeekday }
  })
}

class Dayparts extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    header: propTypes.object,
    dayparts: propTypes.array,
    data: propTypes.array,
    error: propTypes.string,
    updateAvail: propTypes.func,
    updateWeekday: propTypes.func,
    updateServiceType: propTypes.func,
    updateDaypart: propTypes.func,
    updateAvails: propTypes.func
  }

  handleCheckbox = evt => {
    const { target } = evt
    const [daypartId, serviceTypeEnum, weekday] = target.id.split('-')
    const avail = {
      daypart_id: parseInt(daypartId),
      service_type: serviceTypeEnum,
      weekday: weekday
    }
    this.props.updateAvail(avail, target.checked)
    evt.target.blur()
  }

  handleWeekday = evt => {
    evt.preventDefault()
    const { target } = evt
    const [daypartId, weekday] = target.id.split('-')
    this.props.updateWeekday(parseInt(daypartId), weekday)
    evt.target.blur()
  }

  handleServiceType = evt => {
    evt.preventDefault()
    const { target } = evt
    const [daypartId, serviceType] = target.id.split('-')
    this.props.updateServiceType(parseInt(daypartId), serviceType)
    evt.target.blur()
  }

  handleDaypart = evt => {
    evt.preventDefault()
    const { target } = evt
    const daypartId = parseInt(target.id)
    this.props.updateDaypart(daypartId)
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateAvails()
    this.submitButton.current.blur()
    window.scroll(0, 0)
  }

  render() {
    const { dayparts, data } = this.props
    const availsList = makeAvailsList(data)
    const newDayparts = makeDayparts(dayparts, availsList)
    return (
      <div className="content__main">
        {!data && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="avails dayparts">
                <form id="form" className="form" onSubmit={this.handleSubmit}>
                  {newDayparts.map(daypart => (
                    <div key={daypart.daypart_id} className="avail">
                      <table className="table--avails table--light">
                        <thead>
                          <tr>
                            <th>
                              <button
                                id={`${daypart.daypart_id}`}
                                onClick={this.handleDaypart}
                              >
                                {daypart.full_name}
                              </button>
                            </th>
                            {[...serviceType].slice(1).map(s => (
                              <th key={`${daypart.daypart_id}-${s.value}`}>
                                <button
                                  id={`${daypart.daypart_id}-${s.value}`}
                                  onClick={this.handleServiceType}
                                >
                                  <span className="not-mobile">{s.name}</span>
                                  <span className="mobile">
                                    {s.name.charAt(0)}
                                  </span>
                                </button>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {daypart.weekdays.map(weekday => (
                            <tr key={`${daypart.daypart_id}-${weekday.name}`}>
                              <td>
                                <button
                                  id={`${daypart.daypart_id}-${weekday.name}`}
                                  onClick={this.handleWeekday}
                                >
                                  {capitalize(weekday.name)}
                                </button>
                              </td>
                              {weekday.serviceTypes.map(s => (
                                <td key={s.id}>
                                  <CheckboxSimple
                                    id={s.id}
                                    checked={s.checked}
                                    handler={this.handleCheckbox}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value="Update Availabilities"
                      ref={this.submitButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

Dayparts.displayName = 'Dayparts'

export default connect(
  state => ({
    ...state.avails
  }),
  { updateAvail, updateWeekday, updateServiceType, updateDaypart, updateAvails }
)(Dayparts)
