import propTypes from 'prop-types'
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { request } from '../lib/services'
import { Select } from './FormInputs'
import { BackToTop } from './BackToTop'
import { handlePageError } from '../reducers/page'

const RevelGroups = ({ groups }) => {
  return (
    <div className="mx__menus">
      <div className="revel-product -header">
        <span>Name</span>
        <span>Active</span>
        <span>Barcode</span>
        <span>SKU</span>
      </div>
      <div className="mx__menu">
        {groups.map(group => (
          <div key={group.id} className="mx__menu__group revel-group">
            <h3>{group.name}</h3>
            <ul className="mx__menu__items">
              {group.items
                ? group.items.map(item => (
                    <li className="revel-product" key={item.id}>
                      <span>{item.name}</span>
                      <span>{item.active ? 'Yes' : 'No'}</span>
                      <span>{item.barcode}</span>
                      <span>{item.sku}</span>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

RevelGroups.displayName = 'RevelGroups'
RevelGroups.propTypes = {
  groups: propTypes.array
}

const RevelItems = ({ entityName, entityId, items }) => {
  return (
    <div className="mx__menus">
      <div className="revel-product -header">
        <span>Name</span>
        <span>ID</span>
      </div>
      <div className="mx__menu">
        <div className="mx__menu__group revel-group">
          <h3>{entityName}</h3>
          <ul className="mx__menu__items">
            {items.map(item => (
              <li className="revel-product" key={item[entityId]}>
                <span>{item.name}</span>
                <span>{item[entityId]}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

RevelItems.displayName = 'RevelItems'
RevelItems.propTypes = {
  entityName: propTypes.string,
  entityId: propTypes.string,
  items: propTypes.array
}

const renderEntity = (entity, items) => {
  if (!items || !items.length) return null
  switch (entity) {
    case 'products':
    case 'modifiers':
      return <RevelGroups groups={items} />
    case 'users':
      return (
        <RevelItems entityName={'Users'} entityId={'user_id'} items={items} />
      )
    case 'stations':
      return (
        <RevelItems entityName={'Stations'} entityId={'id'} items={items} />
      )
    default:
      return null
  }
}

const EntityExtList = ({
  token,
  brand,
  entity,
  endpoint,
  restaurantId,
  handleError
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
    setItems([])
    if (restaurantId) {
      setIsLoading(true)
      const entityEndpoint = `${endpoint.replace(
        ':id',
        restaurantId
      )}/${entity}`
      request(token, brand, entityEndpoint, 'GET')
        .then(resp => setItems(resp))
        .catch(err => handleError(err))
        .finally(() => setIsLoading(false))
    }
  }, [restaurantId, entity, brand, endpoint, handleError, token])

  return (
    <div className="mapping">
      {isLoading ? (
        <div className="mapping__loading">
          <ClipLoader size={44} color={'#5a5aff'} />
        </div>
      ) : error ? (
        <p className="bold alert">{error}</p>
      ) : (
        renderEntity(entity, items)
      )}
    </div>
  )
}

EntityExtList.displayName = 'EntityExtList'
EntityExtList.propTypes = {
  token: propTypes.string,
  brand: propTypes.object,
  entity: propTypes.string,
  endpoint: propTypes.string,
  restaurantId: propTypes.number,
  handleError: propTypes.func
}

const restaurantField = {
  label: 'Revel Establishment',
  field: 'restaurantId',
  type: 'select'
}

const entityField = {
  label: 'Revel Entity',
  field: 'entity',
  type: 'select'
}

const entities = [
  { value: 'products', name: 'Products' },
  { value: 'modifiers', name: 'Modifiers' },
  { value: 'users', name: 'Users' },
  { value: 'stations', name: 'Stations' }
]

const EntityExtRestaurants = ({
  token,
  brand,
  restEndpoint,
  endpoint,
  handleError
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [restaurants, setRestaurants] = useState([])
  const [restaurantId, setRestaurantId] = useState('')
  const [entity, setEntity] = useState('products')
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
    setIsLoading(true)
    request(token, brand, restEndpoint, 'GET')
      .then(resp => {
        let options = resp.map(i => {
          return { value: i.pos_ext_id, name: i.display_name }
        })
        options = [{ value: '', name: 'select a restaurant' }, ...options]
        setRestaurants(options)
      })
      .catch(err => handleError(err))
      .finally(() => setIsLoading(false))
  }, [brand, restEndpoint, handleError, token])

  const handleRestaurant = evt => {
    setRestaurantId(evt.target.value)
  }

  const handleEntity = evt => {
    setEntity(evt.target.value)
  }

  return (
    <div className="mapping">
      {isLoading ? (
        <div className="mapping__loading">
          <ClipLoader size={44} color={'#5a5aff'} />
        </div>
      ) : error ? (
        <p className="bold alert">{error}</p>
      ) : (
        <>
          <div className="mapping__revenue-center">
            <Select
              field={restaurantField}
              value={restaurantId}
              options={restaurants}
              handler={handleRestaurant}
            />
            <Select
              field={entityField}
              value={entity}
              options={entities}
              handler={handleEntity}
            />
          </div>
          <EntityExtList
            token={token}
            brand={brand}
            endpoint={endpoint}
            entity={entity}
            restaurantId={restaurantId}
            handleError={handlePageError}
          />
        </>
      )}
    </div>
  )
}

EntityExtRestaurants.displayName = 'EntityExtRestaurants'
EntityExtRestaurants.propTypes = {
  token: propTypes.string,
  brand: propTypes.object,
  restEndpoint: propTypes.string,
  endpoint: propTypes.string,
  handleError: propTypes.func
}

class EntityExt extends Component {
  static propTypes = {
    token: propTypes.string,
    brand: propTypes.object,
    restEndpoint: propTypes.string,
    endpoint: propTypes.string,
    handlePageError: propTypes.func
  }

  render() {
    return (
      <div className="mapping">
        <BackToTop />
        <EntityExtRestaurants {...this.props} />
      </div>
    )
  }
}

EntityExt.displayName = 'EntityExt'

export default connect(
  state => ({
    token: state.token,
    brand: state.brand,
    ...state.page.entityExt
  }),
  { handlePageError }
)(EntityExt)
