import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reorderItems } from '../reducers/relations'
import Table from './Table'
import DraggableList from './DraggableList'
import RelationsResults from './RelationsResults'

class Relations extends Component {
  static propTypes = {
    // props
    isList: propTypes.bool,
    endpoint: propTypes.string,
    search: propTypes.object,
    hideNew: propTypes.bool,
    upload: propTypes.object,
    id: propTypes.string,
    sorting: propTypes.object,
    fields: propTypes.array,
    classes: propTypes.string,
    // ...state.relations
    items: propTypes.array,
    loading: propTypes.bool,
    error: propTypes.string,
    // stage.page
    pageId: propTypes.string,
    pageRoute: propTypes.string,
    // mapdispatchtoprops
    reorderItems: propTypes.func
  }

  render() {
    const { endpoint, pageId, pageRoute, search, hideNew, upload } = this.props
    const relationsEndpoint = `${endpoint.replace(':id', pageId)}`
    const newEndpoint = `${pageRoute.replace(':id', pageId)}`
    const tableProps = {
      classes: this.props.classes,
      fields: this.props.fields,
      items: this.props.items,
      sorting: this.props.sorting,
      id: this.props.id,
      loading: this.props.loading,
      listEndpoint: relationsEndpoint,
      reorderList: this.props.reorderItems,
      isRelation: true
    }
    return (
      <div className="content__main">
        {!this.props.error && (
          <>
            <RelationsResults
              items={this.props.items}
              endpoint={newEndpoint}
              search={search}
              hideNew={hideNew}
              upload={upload}
            />
            {this.props.isList ? (
              <DraggableList {...tableProps} />
            ) : (
              <Table {...tableProps} />
            )}
          </>
        )}
      </div>
    )
  }
}

Relations.displayName = 'Relations'

export default connect(
  state => ({
    ...state.relations,
    ...state.page.relations,
    pageId: state.page.id,
    pageRoute: state.page.route
  }),
  { reorderItems }
)(Relations)
