import propTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns-tz'
import { slugify } from '../lib/helpers'
import { makeLocalDate } from '../lib/helpersDatetime'
import QuillEditor from './Editor'
import Columns from './Columns'

const Tooltip = ({ title }) => {
  return (
    <>
      <span className="tooltip__trigger">?</span>
      <span className="tooltip__content">{title}</span>
    </>
  )
}

Tooltip.displayName = 'Tooltip'
Tooltip.propTypes = {
  title: propTypes.string
}

export const Label = ({ label, required, tooltip, klass }) => {
  return (
    <>
      <span className={klass || ''}>
        {label}
        {required ? <span className="required">*</span> : null}
      </span>
      {tooltip && <Tooltip title={tooltip} />}
    </>
  )
}

Label.displayName = 'Label'
Label.propTypes = {
  label: propTypes.string,
  required: propTypes.bool,
  tooltip: propTypes.string,
  klass: propTypes.string
}

export const Error = ({ error }) => {
  return (
    <TransitionGroup>
      {error.length ? (
        <CSSTransition
          key="error"
          classNames="fade"
          timeout={{ enter: 250, exit: 0 }}
        >
          <span className="error error--form">
            <p>{error}</p>
          </span>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

Error.displayName = 'Error'
Error.propTypes = {
  error: propTypes.string
}

export const Break = ({ title }) => {
  return (
    <div className="form__break">
      <p className="form__break__title">{title}</p>
    </div>
  )
}

Break.displayName = 'Break'
Break.propTypes = {
  title: propTypes.string
}

export const Input = ({ field, value, error, handler }) => {
  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <input
        id={field.id || field.field}
        type={field.type}
        value={value}
        placeholder={field.placeholder}
        onChange={handler}
        readOnly={field.readonly === true}
        autoComplete={field.type === 'password' ? slugify(field.field) : null}
      />
      {error && error.length ? <Error error={error} /> : null}
    </label>
  )
}

Input.displayName = 'Input'
Input.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  error: propTypes.string,
  handler: propTypes.func
}

export const InputColor = ({ field, value, error, handler }) => {
  const colorValue = `#${value.replace('#', '')}`

  const onChange = evt => {
    const { id, type, value } = evt.target
    const target = { id, type, value: value.replace('#', '') }
    const event = { type: evt.type, target }
    handler(event)
  }

  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <div className="input-color">
        {value === 'transparent' ? (
          <input
            id={field.id || field.field}
            type="text"
            value=""
            disabled={true}
            className="input-transparent"
          />
        ) : (
          <input
            id={field.id || field.field}
            type="color"
            tabIndex="-1"
            value={colorValue}
            onChange={onChange}
          />
        )}
        <input
          id={field.id || field.field}
          type="text"
          value={value}
          placeholder={field.placeholder}
          onChange={onChange}
        />
      </div>
      {error && error.length ? <Error error={error} /> : null}
    </label>
  )
}

InputColor.displayName = 'InputColor'
InputColor.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  error: propTypes.string,
  handler: propTypes.func
}

export const InputRange = ({ field, value, error, handler }) => {
  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <div className="input-range">
        <div className="input-range__range">
          <div>{field.min}</div>
          <input
            id={field.id || field.field}
            tabIndex="-1"
            type="range"
            value={value}
            onChange={handler}
            min={field.min}
            max={field.max}
            step={field.step || 1}
          />
          <div>{field.max}</div>
        </div>
        <input
          id={field.id || field.field}
          type="number"
          value={value}
          placeholder={field.placeholder}
          onChange={handler}
        />
      </div>
      {error && error.length ? <Error error={error} /> : null}
    </label>
  )
}

InputRange.displayName = 'InputRange'
InputRange.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  error: propTypes.string,
  handler: propTypes.func
}

const Hidden = ({ field, value }) => {
  return <input id={field.field} type={field.type} value={value} />
}

Hidden.displayName = 'Hidden'
Hidden.propTypes = {
  field: propTypes.object,
  value: propTypes.string
}

export const CheckboxSimple = ({ id, checked, disabled, handler, klass }) => {
  return (
    <label htmlFor={id} className="label checkbox checkbox--simple">
      <input
        id={id}
        type="checkbox"
        className="checkbox__input"
        checked={checked}
        disabled={disabled}
        onChange={handler}
      />
      {!klass ? (
        <span className="checkbox__custom" />
      ) : disabled ? (
        <span className="checkbox__expire -disabled">n/a</span>
      ) : checked ? (
        <span className="checkbox__expire">Remove</span>
      ) : (
        <span className="checkbox__expire -removed">Add Back</span>
      )}
    </label>
  )
}

CheckboxSimple.displayName = 'CheckboxSimple'
CheckboxSimple.propTypes = {
  id: propTypes.string,
  checked: propTypes.bool,
  disabled: propTypes.bool,
  handler: propTypes.func,
  klass: propTypes.string
}

export const Checkbox = ({ field, value, error, handler }) => {
  return (
    <label
      htmlFor={field.field}
      className={`label checkbox ${field.classes || ''}`}
    >
      <input
        id={field.field}
        type={field.type}
        className="checkbox__input"
        checked={value}
        onChange={handler}
      />
      <span className="checkbox__custom" />
      {field.label ? (
        <span className="checkbox__desc">{field.label}</span>
      ) : null}
      {error && error.length ? <Error error={error} /> : null}
    </label>
  )
}

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = {
  field: propTypes.object,
  value: propTypes.bool,
  error: propTypes.string,
  handler: propTypes.func
}

export const CheckboxWrapped = ({ field, value, error, handler }) => {
  return (
    <div className="checkbox__wrapper">
      <Checkbox field={field} value={value} error={error} handler={handler} />
      {field.tooltip && <Tooltip title={field.tooltip} />}
    </div>
  )
}

CheckboxWrapped.displayName = 'CheckboxWrapped'
CheckboxWrapped.propTypes = {
  field: propTypes.object,
  value: propTypes.bool,
  error: propTypes.string,
  handler: propTypes.func
}

const useCheckboxGroup = (value, inputHidden) => {
  const [checkboxes, setCheckboxes] = useState(value)
  useEffect(() => setCheckboxes(value), [value])
  const handleCheck = evt => {
    const { target } = evt
    let [, targetId] = target.id.split('--')
    targetId = parseInt(targetId) || targetId
    const newCheckboxes = target.checked
      ? [...checkboxes, targetId]
      : checkboxes.filter(i => i !== targetId)
    setCheckboxes(newCheckboxes)
    inputHidden.current.value = newCheckboxes
    inputHidden.current.click()
  }
  return { checkboxes, handleCheck }
}

const CheckboxGroup = ({ field, value, error, handler, options }) => {
  const inputHidden = useRef()
  // const optionValues = options.map(i => i.value)
  // const adjustedValue = value ? value.filter(i => optionValues.includes(i)) : []
  // const { checkboxes, handleCheck } = useCheckboxGroup(
  //   adjustedValue,
  //   inputHidden
  // )
  const { checkboxes, handleCheck } = useCheckboxGroup(value, inputHidden)
  const newOptions = options.map(option => {
    const key = `${slugify(field.field)}--${option.value}`
    return { ...option, key: key }
  })
  return (
    <div className="checkbox-group">
      <Label {...field} klass="checkbox-group__label" />
      {newOptions.map(option => (
        <label
          key={option.key}
          htmlFor={option.key}
          className={`label checkbox`}
        >
          <input
            id={option.key}
            type="checkbox"
            className="checkbox__input"
            checked={checkboxes.indexOf(option.value) !== -1 ? true : false}
            onChange={handleCheck}
          />
          <span className="checkbox__custom" />
          <span className="checkbox__desc">{option.name}</span>
        </label>
      ))}
      <label className={`label checkbox ${field.classes || ''}`}>
        <input
          ref={inputHidden}
          id={field.field}
          type="hidden"
          value={checkboxes}
          onClick={handler}
        />
        {error.length ? <Error error={error} /> : null}
      </label>
    </div>
  )
}

CheckboxGroup.displayName = 'CheckboxGroup'
CheckboxGroup.propTypes = {
  field: propTypes.object,
  value: propTypes.array,
  error: propTypes.string,
  handler: propTypes.func,
  options: propTypes.array
}

const useSwatches = (value, inputHidden) => {
  const [swatch, setSwatch] = useState(value)
  const handleSwatch = evt => {
    evt.preventDefault()
    const val = value === evt.target.id ? null : evt.target.id
    setSwatch(val)
    inputHidden.current.value = val
    inputHidden.current.click()
    evt.target.blur()
  }
  return { swatch, handleSwatch }
}

const Swatches = ({ field, value, error, handler, options }) => {
  const swatches = options ? [...options.slice(1)] : []
  const inputHidden = useRef()
  const { swatch, handleSwatch } = useSwatches(value, inputHidden)
  return swatches.length ? (
    <label className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <div className="swatches">
        {swatches.map(i => (
          <div
            key={i.value}
            className={`swatch ${i.value === swatch ? '-selected' : ''}`}
          >
            <button
              id={i.value}
              className="btn-swatch"
              style={{ backgroundColor: `#${i.value}` }}
              onClick={handleSwatch}
            />
          </div>
        ))}
      </div>
      <input
        ref={inputHidden}
        id={field.field}
        type="text"
        value={swatch}
        onClick={handler}
        className="hidden"
      />
      {error.length ? <Error error={error} /> : null}
    </label>
  ) : (
    <input
      ref={inputHidden}
      id={field.field}
      type="text"
      value={swatch}
      onClick={handler}
      className="hidden"
    />
  )
}

Swatches.displayName = 'Swatches'
Swatches.propTypes = {
  field: propTypes.object,
  value: propTypes.array,
  error: propTypes.string,
  handler: propTypes.func,
  options: propTypes.array
}

// using makeLocalDate and format maintain a date string in 'yyyy-MM-dd' fomrat
const DatePickerInput = ({ field, value, error, handler }) => {
  const selected = value ? makeLocalDate(value) : null
  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <DatePicker
        showPopperArrow={false}
        dateFormat="yyyy-MM-dd"
        autoComplete="off"
        // readOnly={true}
        name={field.field}
        isClearable
        selected={selected}
        onChange={date =>
          handler({
            target: {
              id: field.field,
              type: 'datepicker',
              value: !date ? null : format(date, 'yyyy-MM-dd')
            }
          })
        }
      />
      {error.length ? <Error error={error} /> : null}
    </label>
  )
}

DatePickerInput.displayName = 'DatePickerInput'
DatePickerInput.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
  error: propTypes.string,
  handler: propTypes.func
}

const ColumnsInput = ({ field, value, error, handler, options }) => {
  const setDisplayed = items => {
    handler({
      target: {
        id: field.field,
        type: 'columns',
        value: items
      }
    })
  }

  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Columns
        allItems={options}
        displayed={value}
        setDisplayed={setDisplayed}
      />
      {error.length ? <Error error={error} /> : null}
    </label>
  )
}

DatePickerInput.displayName = 'DatePickerInput'
DatePickerInput.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
  error: propTypes.string,
  handler: propTypes.func
}

export const Textarea = ({ field, value, error, handler }) => {
  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <textarea id={field.field} value={value} onChange={handler} />
      {error && error.length ? <Error error={error} /> : null}
    </label>
  )
}

Textarea.displayName = 'Textarea'
Textarea.propTypes = {
  field: propTypes.object,
  value: propTypes.string,
  error: propTypes.string,
  handler: propTypes.func
}

const EditorNew = ({ field, value, error, handler }) => {
  return (
    <label htmlFor={field.field} className={`label ${field.classes || ''}`}>
      <Label {...field} />
      <QuillEditor id={field.field} value={value} handler={handler} />
      {error.length ? <Error error={error} /> : null}
    </label>
  )
}

EditorNew.displayName = 'EditorNew'
EditorNew.propTypes = {
  field: propTypes.object,
  value: propTypes.string,
  error: propTypes.string,
  handler: propTypes.func
}

export const Select = ({ field, value, error, handler, options }) => {
  const isDisabled = field.disabled === true || field.readonly === true
  const isReadonly = field.readonly === true
  let errMsg = error && error.length ? error : ''
  errMsg = errMsg === 'Must be an integer' ? 'This field is required' : errMsg
  const hasErr = errMsg.length ? '-error' : ''
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      htmlFor={field.field}
      className={`label select ${hasErr} ${field.classes || ''}`}
    >
      <Label {...field} />
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        id={isReadonly ? `${field.field}-readonly` : field.field}
        value={value}
        onChange={handler}
        disabled={isDisabled}
      >
        {options ? (
          options.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.name}
            </option>
          ))
        ) : (
          <option>loading...</option>
        )}
      </select>
      {isReadonly && <input id={field.field} type="hidden" value={value} />}
      {isDisabled ? null : <span className="select__arrow" />}
      {errMsg.length ? <Error error={errMsg} /> : null}
    </label>
  )
}

Select.displayName = 'Select'
Select.propTypes = {
  field: propTypes.object,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.bool
  ]),
  error: propTypes.string,
  handler: propTypes.func,
  options: propTypes.array
}

export const InputTable = ({ field, value, error, handler }) => {
  const newRow = field.fields.reduce(
    (obj, i) => ({ ...obj, [i.field]: '' }),
    {}
  )
  const [rows, setRows] = useState([...value, newRow])

  const updateInput = evt => {
    const { id, value: inputValue } = evt.target
    const [inputField, index] = id.split('-')
    const row = rows[parseInt(index)]
    const newRow = { ...row, [inputField]: inputValue }
    const newRows = rows.reduce((arr, i, idx) => {
      return [...arr, idx === parseInt(index) ? newRow : i]
    }, [])
    setRows(newRows)
    handler({ type: 'text', target: { id: field.field, value: newRows } })
  }

  const errMsg = error
    ? 'Invalid amount. All values below should be positive dollar amounts and minimums must not equal maximums.'
    : null

  return (
    <label className={`label input-table`}>
      <Label {...field} />
      {errMsg ? <Error error={errMsg} /> : null}
      <table className="table--avails">
        <thead>
          <tr>
            {field.fields.map(f => (
              <th key={f.field}>{f.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((i, index) => (
            <tr key={`${rows[field.fields[0].field]}-${index}`}>
              {field.fields.map(f => (
                <td key={f.field}>
                  <label htmlFor={`${f.field}-${index}`}>
                    <input
                      type="text"
                      id={`${f.field}-${index}`}
                      onChange={updateInput}
                      value={i[f.field]}
                    />
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </label>
  )
}

InputTable.displayName = 'InputTable'
InputTable.propTypes = {
  field: propTypes.object,
  value: propTypes.array,
  error: propTypes.string,
  handler: propTypes.func
}

const FormInput = (field, value, error, handler, options) => {
  const key = `${field.field}-${field.label}`
  switch (field.type) {
    case 'inputTable':
      return (
        <InputTable
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'textarea':
      return (
        <Textarea
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'wysiwyg':
      return (
        <EditorNew
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'datepicker':
      return (
        <DatePickerInput
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'columns':
      return (
        <ColumnsInput
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
          options={options}
        />
      )
    case 'select':
      return (
        <Select
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
          options={options}
        />
      )
    case 'checkbox':
      return (
        <CheckboxWrapped
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'checkboxGroup':
      return (
        <CheckboxGroup
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
          options={options}
        />
      )
    case 'swatches':
      return (
        <Swatches
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
          options={options}
        />
      )
    case 'inputColor':
      return (
        <InputColor
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'inputRange':
      return (
        <InputRange
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
    case 'hidden':
      return (
        <Hidden key={key} field={field} value={value ? value.toString() : ''} />
      )
    case 'break':
      return <Break key={key} title={field.label} />
    default:
      return (
        <Input
          key={key}
          field={field}
          value={value}
          error={error}
          handler={handler}
        />
      )
  }
}

FormInput.displayName = 'FormInput'
FormInput.propTypes = {
  field: propTypes.object,
  value: propTypes.string,
  error: propTypes.string,
  options: propTypes.array
}

export { FormInput }
