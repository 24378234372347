import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import {
  updateAttribute,
  updateAttributes,
  copyDown
} from '../reducers/attributes'
import { updateFilter, resetFilters } from '../reducers/page'
import { Filter } from './Filters'
import ClipLoader from 'react-spinners/ClipLoader'

const serviceTypeNames = ['Pick-Up', 'Delivery', 'Portal']
const serviceTypeValues = ['PICKUP', 'DELIVERY', 'PORTAL']
const serviceTypeLookup = serviceTypeNames.reduce(
  (obj, i, idx) => ({
    ...obj,
    [i]: serviceTypeValues[idx]
  }),
  {}
)

const makeAttributeLookup = (items, rowId, colId) => {
  let lookup = {}
  if (!items) return lookup
  items.map(i => {
    lookup[`${i[rowId]}-${i[colId]}-${i.service_type}`] = i.attribute_value
  })
  return lookup
}

const makeAttributeErrors = (items, rowId, colId) => {
  if (!items) return []
  return items
    .filter(i => i.error)
    .map(i => `${i[rowId]}-${i[colId]}-${i.service_type}`)
}

const makeAttributes = (lookup, errors, { rows, cols }) => {
  if (!rows || !cols) return []
  return rows.map(row => {
    return {
      name: row.name,
      value: row.value,
      cols: cols.map(col => {
        const key = `${row.value}-${col.value}`
        const colServiceTypes = serviceTypeValues.map(st => {
          const lookupKey = `${key}-${st}`
          return {
            id: `${lookupKey}`,
            value: lookup[lookupKey],
            error: errors.indexOf(lookupKey) >= 0
          }
        })
        return {
          key: key,
          serviceTypes: colServiceTypes
        }
      })
    }
  })
}

const CopyButton = ({ colVal, serviceType, handler }) => (
  <button
    className="btn-link"
    onClick={evt => handler(evt, colVal, serviceType)}
  >
    {serviceType}
  </button>
)

CopyButton.displayName = 'CopyButton'
CopyButton.propTypes = {
  colVal: propTypes.number,
  serviceType: propTypes.string,
  handler: propTypes.func
}

class Attributes extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    // state.page.attributes
    filters: propTypes.array,
    // state.attributes
    rowId: propTypes.string,
    colId: propTypes.string,
    rows: propTypes.array,
    cols: propTypes.array,
    items: propTypes.array,
    error: propTypes.string,
    loading: propTypes.bool,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    updateAttribute: propTypes.func,
    updateAttributes: propTypes.func,
    copyDown: propTypes.func
  }

  handleFilter = evt => {
    const { id, value } = evt.target
    const filter = { field: id, value: value }
    this.props.updateFilter(filter)
    evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.resetFilters()
    evt.target.blur()
  }

  handleInput = evt => {
    const { target } = evt
    const [rowVal, colVal, stVal] = target.id.split('-')
    const attribute = {
      [this.props.rowId]: parseInt(rowVal) || rowVal,
      [this.props.colId]: colVal === 'null' ? null : parseInt(colVal) || colVal,
      service_type: stVal,
      attribute_value: target.value
    }
    this.props.updateAttribute(attribute)
  }

  copyDown = (evt, colVal, serviceTypeName) => {
    evt.preventDefault()
    const serviceType = serviceTypeLookup[serviceTypeName]
    this.props.copyDown(colVal, serviceType)
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateAttributes()
    this.submitButton.current.blur()
    window.scroll(0, 0)
  }

  render() {
    const { filters, rows, cols, items, rowId, colId, loading } = this.props
    const lookup = makeAttributeLookup(items, rowId, colId)
    const errors = makeAttributeErrors(items, rowId, colId)
    const attributes = makeAttributes(lookup, errors, this.props)
    // console.log(JSON.stringify(itemsList, null, 2))
    // console.log(JSON.stringify(avails, null, 2))
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        <div className="table__results">
          {items ? (
            <>
              <p>
                <button className="btn-link" onClick={this.handleResetFilters}>
                  Clear all filters.
                </button>
              </p>
            </>
          ) : loading ? null : (
            <p className="content__note -alert">
              Please select either a category or modifier group and an attribute
              type.
            </p>
          )}
        </div>
        {rows && cols ? (
          <>
            {!rows.length ? (
              <p>
                There {"aren't"} any items in this group. Please adjust your
                filters.
              </p>
            ) : (
              <>
                {!items && (
                  <div className="content__loading">
                    <ClipLoader size={44} color={'#5a5aff'} />
                  </div>
                )}
                <TransitionGroup>
                  {items ? (
                    <CSSTransition {...cssTransitionFade}>
                      <div className="avails attributes">
                        <form
                          id="form"
                          className="form"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="table-scroll">
                            <table className="table--avails table--light">
                              <thead>
                                <tr>
                                  <th>&nbsp;</th>
                                  {cols.map(col => (
                                    <th
                                      key={`${col.name}-${col.value}`}
                                      colSpan={serviceTypeNames.length}
                                    >
                                      {col.name}
                                    </th>
                                  ))}
                                </tr>
                                <tr>
                                  <th>&nbsp;</th>
                                  {cols.map(col =>
                                    serviceTypeNames.map(st => (
                                      <th
                                        key={`${col.name}-${col.value}-${st}`}
                                      >
                                        <CopyButton
                                          colVal={col.value}
                                          serviceType={st}
                                          handler={this.copyDown}
                                        />
                                      </th>
                                    ))
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {attributes.map(row => (
                                  <tr key={`${row.name}-${row.value}`}>
                                    <td>{row.name}</td>
                                    {row.cols.map(col =>
                                      col.serviceTypes.map(s => (
                                        <td key={s.id}>
                                          <label
                                            htmlFor={s.id}
                                            className={`label ${
                                              s.error ? '-error' : ''
                                            }`}
                                          >
                                            <input
                                              id={s.id}
                                              type="text"
                                              value={s.value}
                                              placeholder="0.00"
                                              onChange={this.handleInput}
                                              // readOnly={s.readonly === true}
                                            />
                                          </label>
                                        </td>
                                      ))
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="form__submit">
                            <input
                              className="btn"
                              type="submit"
                              value="Update Attributes"
                              ref={this.submitButton}
                            />
                          </div>
                        </form>
                      </div>
                    </CSSTransition>
                  ) : null}
                </TransitionGroup>
              </>
            )}
          </>
        ) : null}
      </div>
    )
  }
}

Attributes.displayName = 'Attributes'

export default connect(
  state => ({
    ...state.page.attributes,
    ...state.attributes
  }),
  { updateFilter, resetFilters, updateAttribute, updateAttributes, copyDown }
)(Attributes)
