import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteRelated } from '../../reducers/item'

class DeleteRelated extends Component {
  static propTypes = {
    item: propTypes.object,
    endpoint: propTypes.string,
    relatedId: propTypes.oneOfType([propTypes.string, propTypes.number]),
    deleteRelated: propTypes.func
  }

  handleDelete = evt => {
    evt.preventDefault()
    const { item, relatedId, endpoint } = this.props
    this.props.deleteRelated(item, relatedId, endpoint)
    evt.target.blur()
  }

  render() {
    return (
      <button className="btn" onClick={this.handleDelete}>
        Remove
      </button>
    )
  }
}

DeleteRelated.displayName = 'DeleteRelated'

export default connect(null, { deleteRelated })(DeleteRelated)
