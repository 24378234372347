import React from 'react'
import { pathStart } from './helpers'
import { makeItemTitle, makeItemEndpoint } from './helpersPage'
import { pageType } from './constants'
import {
  Settings,
  Map,
  Chrome,
  Users,
  User,
  ShoppingBag,
  Code,
  Home,
  Clock,
  BarChart,
  HelpCircle,
  Gift,
  Monitor,
  Download,
  DollarSign,
  ShoppingCart,
  Sliders,
  Award,
  Grid,
  MessageSquare,
  Heart,
  Lock
} from 'react-feather'

export const routes = [
  {
    name: 'Orders',
    path: '/order-mgmt',
    section: 'ORDERS',
    icon: <ShoppingBag size={null} />,
    klass: 'nav__break',
    children: [
      { name: 'Manage Orders', path: '/manage-orders' },
      { name: '86 Items', path: '/expired', permission: 'temp_remove' },
      {
        name: 'Daily Specials',
        path: '/daily-items',
        permission: 'edit_daily_menu'
      },
      { name: 'Upcoming Orders', path: '/upcoming-orders' },
      {
        name: 'Held Orders',
        path: '/held-orders',
        permission: 'edit_orders_full'
      },
      { name: 'Uncharged Orders', path: '/uncharged-orders' },
      {
        name: 'All Orders',
        path: '/orders',
        permission: 'edit_orders_full'
      },
      {
        name: 'Place An Order',
        path: '/order',
        permission: 'edit_orders_full'
      },
      {
        name: 'Find An Order',
        path: '/order-mgmt',
        permission: 'edit_orders_full'
      }
    ]
  },
  {
    name: 'Customers',
    path: '/customers',
    section: 'CUSTOMERS',
    icon: <Users size={null} />,
    children: [
      { name: 'Blocked Customers', path: '/blocked-customers' },
      { name: 'Blocked Emails', path: '/blocked-emails' },
      { name: 'Blocked Phones', path: '/blocked-phones' },
      { name: 'Blocked Cards', path: '/blocked-cards' }
    ]
  },
  {
    name: 'House Accounts',
    path: '/house-accounts',
    section: 'CUSTOMERS',
    icon: <Home size={null} />
  },
  {
    name: 'Loyalty',
    path: '/loyalty-programs',
    section: 'CUSTOMERS',
    icon: <Heart size={null} />,
    children: [{ name: 'Customers', path: '/loyalty-customers' }]
  },
  {
    name: 'Promo Codes',
    path: '/promo-codes',
    section: 'CUSTOMERS',
    icon: <Grid size={null} />
  },
  {
    name: 'Deals',
    path: '/deals',
    section: 'CUSTOMERS',
    icon: <DollarSign size={null} />
  },
  {
    name: 'Rewards',
    path: '/rewards',
    section: 'CUSTOMERS',
    icon: <Award size={null} />
  },
  {
    name: 'Announcements',
    path: '/announcements',
    section: 'CUSTOMERS',
    icon: <MessageSquare size={null} />,
    children: [{ name: 'Pages', path: '/announcement-pages' }]
  },
  {
    name: 'Gift Cards',
    path: '/gift-cards',
    section: 'CUSTOMERS',
    icon: <Gift size={null} />,
    children: [{ name: 'Batches', path: '/gift-card-batches' }]
  },
  // {
  //   name: 'Stores',
  //   path: '/restaurants',
  //   section: 'LOCATIONS',
  //   icon: <MapPin size={null} />,
  //   klass: 'nav__break',
  //   children: [
  //     { name: 'Stores', path: '/stores' },
  //     { name: 'Revenue Centers', path: '/revenue-centers' },
  //     { name: 'Pricing Regions', path: '/regions' },
  //     { name: 'Store Groups', path: '/store-groups' }
  //   ]
  // },
  {
    name: 'Stores',
    path: '/stores',
    section: 'LOCATIONS',
    icon: <ShoppingCart size={null} />,
    klass: 'nav__break',
    children: [{ name: 'Store Groups', path: '/store-groups' }]
  },
  {
    name: 'Revenue Centers',
    path: '/revenue-centers',
    section: 'LOCATIONS',
    icon: <ShoppingBag size={null} />,
    children: [{ name: 'Pricing Regions', path: '/regions' }]
  },
  {
    name: 'Menus',
    path: '/menu-mgmt',
    section: 'MENUS',
    icon: <Map size={null} />,
    children: [
      { name: 'Regenerate', path: '/regenerate-menus' },
      { name: 'Menus', path: '/menus' },
      {
        name: 'Categories',
        path: '/categories',
        children: [{ name: 'Unassigned', path: '/categories-unassigned' }]
      },
      {
        name: 'Modifier Groups',
        path: '/modifier-groups',
        children: [{ name: 'Unassigned', path: '/modifier-groups-unassigned' }]
      },
      {
        name: 'Items',
        path: '/items',
        children: [{ name: 'Unassigned', path: '/items-unassigned' }]
      },
      { name: 'Prices', path: '/attributes' },
      { name: 'Availabilities', path: '/avails' },
      // { name: '86 Items', path: '/expired' },
      { name: 'Dayparts', path: '/dayparts' },
      { name: 'Allergens', path: '/allergens' },
      { name: 'Tags', path: '/tags' }
    ]
  },
  {
    name: 'Point of Sale',
    path: '/point-of-sale',
    section: 'MENUS',
    icon: <Monitor size={null} />,
    children: [
      { name: 'Pages', path: '/pos-pages' },
      { name: 'Sections', path: '/pos-sections' },
      { name: 'Buttons', path: '/pos-buttons' },
      { name: 'Messages', path: '/pos-messages' },
      { name: 'Paid Ins & Outs', path: '/select-options' },
      { name: 'Item Types', path: '/item-types' },
      { name: 'KDS Terminals', path: '/kds-terminals' },
      { name: 'Prep Stations', path: '/prep-stations' },
      { name: 'POS Printers', path: '/pos-printers' }
    ]
  },
  {
    name: 'People',
    path: '/people',
    section: 'LABOR',
    icon: <User size={null} />,
    children: [
      { name: 'Employees', path: '/employees' },
      { name: 'Departments', path: '/departments' },
      { name: 'Payroll Locations', path: '/payroll-locations' },
      { name: 'Cards', path: '/cards' },
      { name: 'Batches', path: '/batches' }
    ]
  },
  {
    name: 'Timeclock',
    path: '/timeclock',
    section: 'LABOR',
    icon: <Clock size={null} />
  },
  {
    name: 'Reporting',
    path: '/reporting',
    section: 'REPORTING',
    icon: <BarChart size={null} />,
    klass: 'nav__break',
    children: [
      { name: 'Dashboard', path: '/reporting/dashboard' },
      { name: 'Sales', path: '/reporting/sales' },
      { name: 'Sales by Period', path: '/reporting/sales-by-period' },
      { name: 'Sales Over Time', path: '/reporting/sales-over-time' },
      { name: 'Throughput', path: '/reporting/throughput' },
      { name: 'Item Counts', path: '/reporting/item-counts-by-store' },
      {
        name: 'Prep Performance',
        path: '/reporting/order-prep-performance'
      },
      { name: 'Prep Details', path: '/reporting/order-prep' },
      { name: 'Product Mix', path: '/reporting/product-mix' },
      { name: 'Discounts', path: '/reporting/discounts' },
      { name: 'Promo Codes', path: '/reporting/promo-codes' },
      { name: 'Order Ratings', path: '/reporting/ratings' },
      { name: 'Subscribers', path: '/reporting/subscribers' },
      { name: 'Donations', path: '/reporting/donations' },
      { name: 'Gift Card Credits', path: '/reporting/gift-card-credits' },
      { name: 'Cash Over / Short', path: '/reporting/cash' },
      { name: 'Paid Ins / Outs', path: '/reporting/paid-ins-outs' },
      { name: 'Voided Orders', path: '/reporting/voids' },
      { name: 'Refunds', path: '/reporting/refunds' },
      { name: 'Accounting', path: '/reporting/accounting' },
      { name: 'Receipts', path: '/reporting/receipts' },
      { name: 'Tenders Summary', path: '/reporting/tenders-summary' },
      { name: 'Tenders', path: '/reporting/tenders' },
      { name: 'Tenders Diff', path: '/reporting/tenders-diff' },
      { name: 'Credit Cards', path: '/reporting/credit-summary' },
      { name: 'Taxes Summary', path: '/reporting/taxes-summary' },
      { name: 'Taxes', path: '/reporting/taxes' },
      { name: 'Hours for Week', path: '/reporting/hours-for-week' },
      { name: 'LevelUp', path: '/reporting/levelup-connections' }
    ]
  },
  {
    name: 'Downloads',
    path: '/downloads',
    section: 'REPORTING',
    icon: <Download size={null} />
  },
  {
    name: 'App Config',
    path: '/config',
    section: 'SETTINGS',
    icon: <Sliders size={null} />,
    klass: 'nav__break',
    children: [
      { name: 'Content', path: '/config-content' },
      { name: 'Styles', path: '/config-styles' },
      { name: 'Settings', path: '/config-settings' },
      { name: 'Clients', path: '/clients' }
    ]
  },
  {
    name: 'Settings',
    path: '/settings',
    section: 'SETTINGS',
    icon: <Settings size={null} />,
    children: [
      { name: 'Surcharges', path: '/surcharges' },
      { name: 'Discounts', path: '/discounts' },
      //  { name: 'Promo Codes', path: '/promo-codes' },
      { name: 'Taxes', path: '/taxes' },
      { name: 'Pickup Times', path: '/pickup-wait-times' },
      { name: 'Delivery Times', path: '/delivery-wait-times' },
      { name: 'Contacts', path: '/contacts' },
      { name: 'Ops Notifications', path: '/email-notifications' },
      { name: 'Confirmations', path: '/confirmation-emails' },
      { name: 'Order Notifications', path: '/notification-settings' },
      { name: 'Email Design', path: '/email-design-settings' },
      { name: 'Image Sizes', path: '/image-sizes' },
      { name: 'Invoices', path: '/invoice-settings' },
      { name: 'Newsletters', path: '/newsletters' },
      { name: 'Curbside Pickup', path: '/order-fulfillment' },
      { name: 'Order Ratings', path: '/order-rating-settings' },
      { name: 'Brand Settings', path: '/website' },
      { name: 'General Settings', path: '/general-settings' }
    ]
  },
  {
    name: 'Integrations',
    path: '/integrations',
    section: 'SETTINGS',
    icon: <Code size={null} />,
    children: [
      {
        name: 'POS',
        path: '/pos',
        children: [
          { name: 'Chowly', path: '/chowly-toos' },
          { name: 'Revel', path: '/revel' },
          { name: 'Salido', path: '/salido' },
          { name: 'Qu', path: '/qu' },
          { name: 'Rpower', path: '/rpower' }
        ]
      },
      { name: 'Payments', path: '/payments' },
      {
        name: 'Loyalty',
        path: '/loyalty',
        children: [
          { name: 'LevelUp', path: '/levelup' },
          { name: 'Thanx', path: '/thanx' },
          { name: 'Como', path: '/como' }
        ]
      },
      {
        name: 'Delivery',
        path: '/delivery',
        children: [
          { name: 'DoorDash', path: '/doordash' },
          { name: 'Postmates', path: '/postmates' },
          { name: 'Relay', path: '/relay' },
          { name: 'Onfleet', path: '/onfleet' }
        ]
      },
      {
        name: 'Maps',
        path: '/maps',
        children: [
          { name: 'Google Maps', path: '/google-maps' },
          { name: 'Mapbox', path: '/mapbox' }
        ]
      },
      {
        name: 'Shipping',
        path: '/shipping',
        children: [{ name: 'EasyPost', path: '/easypost' }]
      },
      {
        name: 'Accounting',
        path: '/accounting',
        children: [
          { name: 'CTUIT', path: '/ctuit' },
          { name: 'Restaurant365', path: '/r365' }
        ]
      },
      {
        name: 'Other',
        path: '/other',
        children: [
          { name: 'Twilio', path: '/twilio' },
          { name: 'Chowly', path: '/chowly' },
          { name: 'Brightloom', path: '/brightloom' },
          { name: 'Tattle', path: '/tattle' },
          { name: 'Intercom', path: '/intercom' },
          { name: 'Mailchimp', path: '/mailchimp' },
          { name: 'Webhook', path: '/webhook' }
        ]
      }
    ]
  },
  {
    name: 'Content',
    path: '/content',
    section: 'WEBSITE',
    icon: <Chrome size={null} />,
    children: [
      { name: 'Pages', path: '/pages' },
      { name: 'Posts', path: '/posts' },
      { name: 'Post Categories', path: '/post_categories' },
      { name: 'Post Tags', path: '/post_tags' },
      { name: 'Forms', path: '/forms' },
      { name: 'Templates', path: '/templates' }
    ]
  },
  {
    name: 'Users',
    path: '/users',
    section: 'USERS',
    icon: <User size={null} />,
    children: [{ name: 'Permissions', path: '/permissions' }]
  },
  { name: 'Help', path: '/help', icon: <HelpCircle size={null} /> },
  {
    name: 'Super',
    path: '/super',
    isSuper: true,
    icon: <Lock size={null} />,
    children: [{ name: 'Sales by Month', path: '/super/sales-by-month' }]
  }
]

export const filteredRoutes = user => {
  if (!user.sections) return null
  if (!user.sections.length) return routes
  const filtered = routes
    .filter(i => !i.section || user.sections.includes(i.section))
    .filter(i => !i.isSuper || user.is_super)
  return filtered.map(i => {
    if (!i.children) return i
    const children = i.children.filter(
      i => !i.permission || user.permissions.includes(i.permission)
    )
    return { ...i, children: children }
  })
}

export const indexedRoutes = []
routes.map(route => {
  if (route.path !== '/') {
    indexedRoutes.push({ name: route.name, path: route.path })
    if (route.children) {
      route.children.map(child => {
        indexedRoutes.push({ name: child.name, path: child.path })
      })
    }
  }
})

export const routeLookup = {}
routes.map(route => {
  if (route.path !== '/') {
    routeLookup[route.path] = [{ name: route.name, path: route.path }]
    if (route.children) {
      route.children.map(child => {
        routeLookup[child.path] = [
          { name: route.name, path: route.path },
          { name: child.name, path: child.path }
        ]
        if (child.children) {
          child.children.map(third => {
            routeLookup[third.path] = [
              { name: route.name, path: route.path },
              { name: child.name, path: child.path },
              { name: third.name, path: third.path }
            ]
          })
        }
      })
    }
  }
})

export const makeCrumbs = (pathname, page, item, relation, receipt, order) => {
  // console.log(pathname)
  const crumbRoutes = routeLookup[pathStart(pathname)]
  // console.log(JSON.stringify(crumbRoutes, null, 2))
  let crumbs = crumbRoutes ? [...crumbRoutes] : []
  if (page.type === pageType.item) {
    const itemTitle = makeItemTitle(page.item.title, item.data, page.title)
    if (!page.item.id) crumbs = crumbs.splice(0, 1)
    crumbs.push({ name: itemTitle })
    // } else if (page.type === pageType.content) {
  } else if (page.type === pageType.receipt) {
    if (receipt.data)
      crumbs.push({ name: `Order #${receipt.data[page.item.title]}` })
  } else if (page.type === pageType.receiptList) {
    if (receipt.data) {
      const name = `Order #${receipt.data[page.item.title]}`
      const endpoint = `/orders/${page.id}`
      crumbs.push({ name: name, path: endpoint })
      crumbs.push({ name: page.title })
    }
  } else if (page.type === pageType.order) {
    crumbs.push({
      name: order && order.orderId ? `Order #${order.orderId}` : 'new order'
    })
  } else if (page.type === pageType.orderMgmt) {
    crumbs.push({ name: item && item.data ? item.data.full_name : page.title })
  } else if (page.type === pageType.setting) {
    // crumbs.push({ name: page.item.title, path: page.item.endpoint })
    const lastCrumb = crumbs[crumbs.length - 1] || {}
    if (page.title !== lastCrumb.name) crumbs.push({ name: page.title })
  } else if (
    page.type === pageType.mapping ||
    page.type === pageType.menuExt ||
    page.type === pageType.menuExtUpdate ||
    page.type === pageType.entityExt ||
    page.type === pageType.import ||
    page.type === pageType.importErrors ||
    page.type === pageType.listOfItems ||
    page.type === pageType.config
  ) {
    crumbs.push({ name: page.title })
  } else if (
    page.type === pageType.itemList ||
    page.type === pageType.itemRelations ||
    page.type === pageType.itemFiles ||
    page.type === pageType.iframe ||
    page.type === pageType.itemSetting ||
    page.type === pageType.itemAttributes ||
    page.type === pageType.revenueCenterAttributes ||
    page.type === pageType.dayparts ||
    page.type === pageType.deliveryZone ||
    page.type === pageType.menu ||
    page.type === pageType.discountItems ||
    page.type === pageType.hours ||
    page.type === pageType.holidays ||
    page.type === pageType.blockedHours ||
    page.type === pageType.inventory ||
    page.type === pageType.report ||
    page.type === pageType.recommendedItems
  ) {
    if (!page.item) {
      crumbs = crumbs.splice(0, 1)
      crumbs.push({ name: page.title })
    } else {
      const itemTitle = makeItemTitle(page.item.title, item.data)
      const itemEndpoint = makeItemEndpoint(page, item)
      if (itemTitle && itemEndpoint) {
        crumbs.push({ name: itemTitle, path: itemEndpoint })
        crumbs.push({ name: page.title })
      } else {
        crumbs.push({ name: page.title })
      }
    }
  } else if (page.type === pageType.itemRelation) {
    const itemTitle = makeItemTitle(page.item.title, item.data)
    const itemEndpoint = `${page.item.backEndpoint || page.item.endpoint}/${
      page.id
    }`
    const relationsEndpoint = page.relations.endpoint.replace(':id', page.id)
    const relationTitle = makeItemTitle(page.relation.title, relation.data)
    if (itemTitle && itemEndpoint && relationsEndpoint) {
      crumbs.push({ name: itemTitle, path: itemEndpoint })
      crumbs.push({
        name: page.relations.title,
        path: relationsEndpoint
      })
      crumbs.push({ name: relationTitle })
    }
  } else if (page.type === pageType.relatedItem) {
    const itemTitle = makeItemTitle(page.item.title, item.data)
    const itemEndpoint = `${page.item.endpoint}/${page.id}`
    const relationsEndpoint = (
      page.relatedItem.backEndpoint || page.relatedItem.endpoint
    ).replace(':id', page.id)
    const relationTitle = makeItemTitle(page.relatedItem.title, relation.data)
    if (itemTitle && itemEndpoint && relationsEndpoint) {
      crumbs.push({ name: itemTitle, path: itemEndpoint })
      crumbs.push({
        name: page.relatedItems.title,
        path: relationsEndpoint
      })
      crumbs.push({ name: relationTitle })
    }
  }
  return crumbs.length ? crumbs : [{ name: 'Welcome' }]
}
