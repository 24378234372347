const images = {
  home:
    'https://s3.amazonaws.com/betterboh/u/img/local/2/1577418446_eat-red-neon_black-white_2400x1600.jpg',
  people:
    'https://s3.amazonaws.com/betterboh/u/img/local/2/1577456730_people-dark-kitchen_black-white_2400x1488.jpg',
  stores:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578494575_restaurant-ok-diner_black-white_1800x2400.jpg',
  pos:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578524297_cash-register-vintage_black-white_1800x1200.jpg',
  posModern:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582421149_pos-square-close-up_1200x1800.jpg',
  integrations:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582420925_colorful-cables_1800x1200.jpg',
  notFound:
    'https://s3.amazonaws.com/betterboh/u/img/local/2/1572714521_man-with-compass_1400x2356.jpg',
  content:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578524481_code-close-up_black-white_1500x2000.jpg',
  menu:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578524099_coffee-menu-on-chair_1500x2250.jpg',
  orders:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578351337_order-tickets_black-white_1800x1200.jpg',
  settings:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1578524635_soundboard-close-up_black-white_1800x1200.jpg',
  reporting:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1581219288_graph-on-table_2400x1600.jpg',
  loyalty:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582486104_thanks-card_1500x2000.jpg',
  delivery:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582481575_delivery-driver-on-bike_1800x1200.jpg',
  shipping:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582484910_shipping-handing-off-box_1500x1875.jpg',
  maps:
    'https://s3.amazonaws.com/betterboh/u/img/prod/2/1582509732_world-map-with-pins_1800x1200.jpg'
}

export const example = {
  image: { url: images.stores, position: 'center center' }
}

export default images
