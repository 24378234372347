import propTypes from 'prop-types'
import React from 'react'

const ToggleDisplay = ({ showData, handler }) => (
  <div className="toggle-group">
    <button className="btn" disabled={!showData} onClick={handler}>
      Charts
    </button>
    <button className="btn" disabled={showData} onClick={handler}>
      Table
    </button>
  </div>
)

ToggleDisplay.displayName = 'ToggleDisplay'
ToggleDisplay.propTypes = {
  showData: propTypes.bool,
  handler: propTypes.func
}

export default ToggleDisplay
