import propTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { isObject } from '../lib/utils'
import { makePageTitle } from '../lib/helpersPage'
import { ArrowLeft } from 'react-feather'

const BackLink = ({ page, item, relation, classes = '' }) => {
  const title = makePageTitle(page, item, relation)
  return (
    <div className={`back-link-wrapper ${classes}`}>
      {isObject(title) ? (
        <div className="back-link">
          <Link to={title.itemLink}>
            <ArrowLeft size={18} />
            <span>
              Back to all {title.relationsTitle} for {title.itemTitle}
            </span>
          </Link>
        </div>
      ) : null}
    </div>
  )
}

BackLink.displayName = 'BackLink'
BackLink.propTypes = {
  page: propTypes.object,
  item: propTypes.object,
  relation: propTypes.object,
  classes: propTypes.string
}

export default BackLink
