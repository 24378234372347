import propTypes from 'prop-types'
import React from 'react'
import { XCircle } from 'react-feather'
import { formatDollars, slugify } from '../lib/helpers'

export const formatPrice = (price, isHeader) => {
  if ((isHeader || !price) && price !== 0) return price
  return formatDollars(price)
}

export const ReceiptSection = ({ rows, classes }) => {
  if (!rows || !rows.length) return null
  const sectionId = slugify(rows[0].label)
  return (
    <div id={sectionId} className={`receipt__section ${classes || ''}`}>
      {rows.map((row, index) => {
        return !row.label && !row.value ? (
          <div key={`spacer-${index}`} className="receipt__spacer">
            <div>&nbsp;</div>
          </div>
        ) : (
          <div
            key={`${row.label}-${row.id || index}`}
            className={`receipt__row ${row.classes || ''}`}
          >
            <div className="receipt__label">
              {row.labelPath ? (
                <a
                  href={`${row.labelPath.replace(':id', row.id)}`}
                  target={row.newWindow ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  {row.label}
                </a>
              ) : (
                row.label
              )}
              {row.handler && (
                <div className="receipt__remove">
                  <button
                    className="btn-link"
                    onClick={evt => row.handler(evt, row.id)}
                  >
                    <XCircle size={13} />
                  </button>
                </div>
              )}
              {row.edit && (
                <span className="receipt__edit">
                  &nbsp; -- &nbsp;
                  <button className="btn-link" onClick={row.edit}>
                    edit
                  </button>
                </span>
              )}
            </div>
            <div className="receipt__value">
              {row.path ? (
                <a
                  href={`${row.path.replace(':id', row.id)}`}
                  target={row.newWindow ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  {row.value}
                </a>
              ) : (
                row.value || '--'
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ReceiptSection.displayName = 'ReceiptSection'
ReceiptSection.propTypes = {
  rows: propTypes.array,
  classes: propTypes.string
}

export const ReceiptItem = ({
  name,
  short_name,
  price,
  // quantity,
  price_total,
  quantity_total,
  isHeader,
  printOut
}) => {
  return (
    <>
      <div className="receipt__item__name">
        {printOut ? short_name : name}
        {printOut && (
          <div className="receipt__item__name__quantity">{quantity_total}</div>
        )}
      </div>
      <div className="receipt__item__price">{formatPrice(price, isHeader)}</div>
      <div className="receipt__item__quantity">{quantity_total}</div>
      <div className="receipt__item__price">
        {formatPrice(price_total, isHeader)}
      </div>
    </>
  )
}

ReceiptItem.displayName = 'ReceiptItem'
ReceiptItem.propTypes = {
  name: propTypes.string,
  short_name: propTypes.string,
  price: propTypes.string,
  quantity: propTypes.oneOfType([propTypes.string, propTypes.number]),
  price_total: propTypes.string,
  isHeader: propTypes.bool,
  printOut: propTypes.bool
}

export const ReceiptItemRow = ({ item, itemNo, printOut }) => {
  const hasGroups = item.groups && item.groups.length
  const isParent =
    hasGroups || item.notes || item.made_for ? '-item -parent' : '-item'
  return (
    <>
      <div className={`receipt__row ${isParent}`}>
        <ReceiptItem {...item} printOut={printOut} />
      </div>
      {item.groups.map((g, i, a) =>
        g.options.map((option, index, arr) => {
          const isLast =
            !item.notes &&
            !item.made_for &&
            a.length === i + 1 &&
            arr.length === index + 1
          const classes = `receipt__row -child ${isLast ? ' -last' : ''}`
          return (
            <div key={`${itemNo}-${index}-${option.id}`} className={classes}>
              <ReceiptItem {...option} printOut={printOut} />
            </div>
          )
        })
      )}
      {item.made_for && (
        <div className={`receipt__row -child ${!item.notes ? '-last' : ''}`}>
          <span>Made for: {item.made_for}</span>
        </div>
      )}
      {item.notes && (
        <div className={`receipt__row -child -last`}>
          <span>Notes: {item.notes}</span>
        </div>
      )}
    </>
  )
}

ReceiptItemRow.displayName = 'ReceiptItemRow'
ReceiptItemRow.propTypes = {
  item: propTypes.object,
  itemNo: propTypes.number,
  printOut: propTypes.bool
}

const makeGroupedMods = items => {
  const grouped = items.forEach(i => {
    i.groups.forEach(g => {
      const groupedOptions = g.options.reduce((obj, o) => {
        if (obj[o.id]) {
          obj[o.id].quantity += parseInt(o.quantity)
          obj[o.id].quantity_total += parseInt(o.quantity_total)
          obj[o.id].price_total += parseFloat(o.price_total)
        } else {
          obj[o.id] = {
            ...o,
            quantity: parseInt(o.quantity),
            quantity_total: parseInt(o.quantity_total),
            price_total: parseFloat(o.price_total)
          }
        }
        return obj
      }, {})
      g.options = Object.values(groupedOptions)
      g.options = g.options.map(i => ({
        ...i,
        price_total: i.price_total.toFixed(2)
      }))
    })
  })
  return grouped
}

export const ReceiptItems = ({
  order,
  classes = '',
  printOut = false,
  groupMods = true
}) => {
  let items = JSON.parse(JSON.stringify(order.items))
  if (groupMods) makeGroupedMods(items)
  const printClass = printOut ? '-print-out' : ''
  return (
    <div id="items" className={`receipt__section ${classes} ${printClass}`}>
      <div className="receipt__row">
        <ReceiptItem
          name="Items"
          price="Price"
          quantity="Qty"
          price_total="Total"
          isHeader={true}
        />
      </div>
      {items.map((item, index) => (
        <ReceiptItemRow
          key={`${item.id}-${index}`}
          item={item}
          itemNo={index}
          printOut={printOut}
        />
      ))}
      <div className="receipt__row -totals">
        <ReceiptItem name="Cart Total" price_total={order.subtotal} />
      </div>
    </div>
  )
}

ReceiptItems.displayName = 'ReceiptItems'
ReceiptItems.propTypes = {
  order: propTypes.object,
  classes: propTypes.string,
  printOut: propTypes.bool,
  groupMods: propTypes.bool
}
