/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import propTypes from 'prop-types'
import React from 'react'
// import React, { useEffect, useCallback } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const MainOverlay = ({ show, toggle }) => {
  // const handleEscape = useCallback(evt => {
  //   console.log('this is firing')
  //   if (evt.target.id === 'main-overlay' && evt.keyCode === 27) toggle(evt)
  // }, [])

  // useEffect(() => {
  //   document.addEventListener('keydown', handleEscape, false)
  //   return () => document.removeEventListener('keydown', handleEscape, false)
  // }, [])

  return (
    <TransitionGroup component={null}>
      {show ? (
        <CSSTransition
          key="overlay"
          classNames="md"
          timeout={{ enter: 250, exit: 250 }}
        >
          <div
            id="main-overlay"
            className="main__overlay"
            onClick={toggle}
            role="banner"
          />
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

MainOverlay.displayName = 'MainOverlay'
MainOverlay.propTypes = {
  show: propTypes.bool,
  toggle: propTypes.func
}

export default MainOverlay
