import propTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { retrieveItem } from '../../reducers/itemList'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatDateStr } from '../../lib/helpersDatetime'

const Requests = ({ request, resp }) => {
  const [json, setJson] = useState(true)

  const handleToggle = evt => {
    evt.preventDefault()
    setJson(!json)
    evt.target.blur()
  }

  return (
    <>
      {json ? (
        <p>
          Request to 3rd Party API |{' '}
          <button className="btn-link" onClick={handleToggle}>
            Response from 3rd Party API
          </button>
        </p>
      ) : (
        <p>
          <button className="btn-link" onClick={handleToggle}>
            Request to 3rd Party API
          </button>{' '}
          | Response from 3rd Party API
        </p>
      )}
      <pre>{json ? request : resp}</pre>
    </>
  )
}

Requests.displayName = 'Requests'
Requests.propTypes = {
  request: propTypes.string,
  resp: propTypes.string
}

class ApiRequest extends Component {
  static propTypes = {
    requestId: propTypes.number,
    item: propTypes.object,
    retrieveItem: propTypes.func
  }

  componentDidMount() {
    const { requestId, retrieveItem } = this.props
    retrieveItem('ext_api_request_id', requestId)
  }

  render() {
    const { item } = this.props
    const requestJson = item ? JSON.stringify(item.request, null, 2) : ''
    const responseJson = item ? JSON.stringify(item.response, null, 2) : ''
    return (
      <div>
        {item ? (
          <div className="api-request">
            <p>Sent {formatDateStr(item.created_at)}</p>
            <Requests request={requestJson} resp={responseJson} />
          </div>
        ) : (
          <ClipLoader size={24} color={'#5a5aff'} />
        )}
      </div>
    )
  }
}

ApiRequest.displayName = 'ApiRequest'

export default connect(
  state => ({
    item: state.itemList.item
  }),
  { retrieveItem }
)(ApiRequest)
