import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { fetchMenuUpdates } from '../reducers/mapping'
import MappingRevenueCenters from './MappingRevenueCenters'
import Table from './Table'
import { RefreshCw } from 'react-feather'

class MenuExtUpdates extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    loading: propTypes.bool,
    revenueCenterId: propTypes.number,
    fields: propTypes.array,
    menuUpdates: propTypes.array,
    fetchMenuUpdates: propTypes.func
  }

  componentDidMount() {
    if (this.props.revenueCenterId) {
      this.props.fetchMenuUpdates()
    }
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.fetchMenuUpdates()
    evt.target.blur()
  }

  render() {
    const { loading, revenueCenterId, fields, menuUpdates } = this.props
    const items = menuUpdates && menuUpdates.length ? menuUpdates : []
    return (
      <div className="mapping">
        <MappingRevenueCenters />
        {loading ? (
          <div className="mapping__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        ) : revenueCenterId && items.length ? (
          <div className="table__wrapper">
            <div className="table__results">
              <div className="table__results__message">
                <span className="table__results__refresh">
                  <button className="btn-link" onClick={this.handleRefresh}>
                    <RefreshCw size={16} />
                  </button>
                </span>
                <p>Displaying {items.length} results.</p>
              </div>
            </div>
            <Table fields={fields} items={items} />
          </div>
        ) : (
          <div className="mapping__mappings">
            <p>
              This revenue center {"doesn't"} have any external menu updates yet
            </p>
          </div>
        )}
      </div>
    )
  }
}

MenuExtUpdates.displayName = 'MenuExtUpdates'

export default connect(
  state => ({
    ...state.mapping,
    ...state.page.menuExtUpdates
  }),
  { fetchMenuUpdates }
)(MenuExtUpdates)
