import propTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { difference } from 'lodash'
import { isEmpty, isString } from '../lib/utils'
import { formatEnum, makeAddress, sortItems } from '../lib/helpers'
import { humanTimeToDbTime } from '../lib/helpersDatetime'
import { valTypes } from '../lib/constants'
import { FormInput } from './FormInputs'

const handleArrayString = val => {
  let arrayVal = isString(val) ? val.split(',') : val
  arrayVal = arrayVal.filter(i => i.length)
  return arrayVal
}

const handleArrayInt = val => {
  let arrayVal = isString(val) ? val.split(',') : val
  arrayVal = arrayVal.map(i => parseInt(i)).filter(i => !isNaN(i))
  return arrayVal
}

const handleArrayObjects = val => {
  if (!val) return []
  try {
    const cleanVal = val
      .filter(i => Object.values(i).join('') !== '')
      .map(i => {
        return Object.entries(i).reduce((obj, item) => {
          const [k, v] = item
          const value = isNaN(parseFloat(v)) ? v : parseFloat(v).toFixed(2)
          return { ...obj, [k]: value }
        }, {})
      })
    return cleanVal
  } catch (e) {
    return []
  }
}

export const cleanFormValue = (valType, val) => {
  switch (valType) {
    case valTypes.wysiwyg:
      return val === '<p><br></p>'
        ? ''
        : val
        ? val.replace('<p class="ql-align-justify">', '<p>')
        : ''
    case valTypes.int:
      return parseInt(val)
    case valTypes.intNullable:
      return !val ? null : parseInt(val)
    case valTypes.decimalNullable:
      return !val ? null : val
    case valTypes.float:
      return parseFloat(val)
    case valTypes.arrayString:
      return handleArrayString(val)
    case valTypes.arrayInt:
      return handleArrayInt(val)
    case valTypes.arrayObjects:
      return handleArrayObjects(val)
    case valTypes.bool:
      return !isString(val) ? val : val === 'true' ? true : false
    case valTypes.time:
      return !val ? '' : humanTimeToDbTime(val)
    case valTypes.timeNullable:
      return val || null
    case valTypes.nullable:
      return val || null
    default:
      return val
  }
}

const makeStateOptions = (
  { endpoint, entity, value, label, defaultOption, sorting, hideNone },
  options
) => {
  if (!options) return null
  const stateOptions = options[endpoint]
  if (!stateOptions) return null
  if (Array.isArray(stateOptions)) {
    let fieldOptions = hideNone ? [] : [{ name: 'none selected', value: '' }]
    if (!stateOptions.length) return fieldOptions.length ? fieldOptions : null
    const sorted = sorting ? sortItems(stateOptions, sorting) : stateOptions
    sorted.map(i => {
      if (i.isDisabled) {
        fieldOptions.push({ ...i, value: '' })
      } else {
        const filterName = Array.isArray(label)
          ? `${i[label[0]]} (${formatEnum(i[label[1]])})`
          : i[label]
        fieldOptions.push({ name: filterName, value: i[value] })
      }
    })
    return fieldOptions
  } else {
    let fieldOptions = hideNone ? [] : [{ name: 'none selected', value: '' }]
    if (stateOptions[entity]) {
      stateOptions[entity].map(i => {
        if (i.isDisabled) {
          fieldOptions.push({ ...i, value: '' })
        } else {
          const filterName = Array.isArray(label)
            ? `${i[label[0]]} (${formatEnum(i[label[1]])})`
            : i[label]
          fieldOptions.push({ name: filterName, value: i[value] })
        }
      })
    }
    if (defaultOption) fieldOptions = [defaultOption, ...fieldOptions]
    return fieldOptions
  }
}

const makeSelfOption = (options, data) => {
  const name = data[options.name]
  const value = data[options.value]
  if (!name || !value) return undefined
  return [{ name: name, value: value }]
}

const makeFieldOptions = (options, stateOptions, data) => {
  if (!options || Array.isArray(options)) return options
  if (options.endpoint) return makeStateOptions(options, stateOptions)
  if (options.name) return makeSelfOption(options, data)
}

const makeEmptyValue = field => {
  if ('defaultValue' in field) return field.defaultValue
  if (field.type === 'hidden' && 'value' in field) return field.value
  if (field.type === 'checkbox') return false
  switch (field.valType) {
    case 'int':
      return 0
    case 'bool':
      return false
    case 'arrayObjects':
      return []
    default:
      return ''
  }
}

const makeFieldValue = (field, values, options) => {
  // console.log(field, values)
  let value
  if (field.field.indexOf('.') !== -1) {
    const [entity, fieldName] = field.field.split('.')
    value = values[entity] ? values[entity][fieldName] : values[entity]
  } else {
    value = values[field.field]
  }
  if (field.type === 'checkboxGroup') {
    const optionValues = options.map(i => i.value)
    value = value
      ? value.map(i => parseInt(i) || i).filter(i => optionValues.includes(i))
      : []
  }
  if (typeof value === 'undefined') {
    value = makeEmptyValue(field)
  }
  return value
}

export const defaultForType = valType => {
  switch (valType) {
    case valTypes.bool:
      return false
    case valTypes.int:
      return 0
    case valTypes.decimal:
      return '0.00'
    case valTypes.nullable:
    case valTypes.intNullable:
    case valTypes.decimalNullable:
    case valTypes.dateNullable:
      return null
    case valTypes.arrayString:
    case valTypes.arrayInt:
      return []
    default:
      return ''
  }
}

export const maybeSetDefaultValue = (field, val, options, inputs) => {
  if (typeof val !== 'undefined' || field.required) return val
  if ('defaultValue' in field) return field.defaultValue
  const fieldOptions = makeFieldOptions(field.options, options, inputs)
  switch (field.type) {
    case 'select':
      return fieldOptions && fieldOptions.length ? fieldOptions[0].value : ''
    case 'checkbox':
      return false
    case 'hidden':
      return 'value' in field ? field.value : defaultForType(field.valType)
    default:
      return defaultForType(field.valType)
  }
}

const translateError = error => {
  if (error.indexOf('required property') !== -1) {
    return 'This field is required'
  }
  return error
}

const makeUnhandledErrors = (fields, errors) => {
  if (!errors.fields) return []
  return difference(
    Object.keys(errors.fields),
    fields.map(field => field.field)
  ).map(key => {
    return { field: key, error: errors.fields[key] }
  })
}

const validateForm = (data, validation) => {
  let errors = {}
  if (!validation) return errors
  for (const [validationField, settings] of Object.entries(validation)) {
    const { required, empty } = settings[data[validationField]]
    // eslint-disable-next-line no-loop-func
    required.forEach(field => {
      if (!data[field]) errors[field] = 'This field is required'
    })
    empty.forEach(field => {
      data[field] = ''
    })
  }
  if (!isEmpty(errors)) {
    errors = {
      form: 'There are one or more errors below',
      fields: errors
    }
  }
  return errors
}

const geocoder = (data, errors, geocode, includeAddress) => {
  return new Promise(resolve => {
    if (!geocode) return resolve({ data, errors })
    const fields = ['street', 'city', 'state', 'postal_code']
    const geocodeErrors = fields.reduce((obj, field) => {
      if (!data[field] || !data[field].length)
        obj[field] = 'This field cannot be empty'
      return obj
    }, {})
    if (!isEmpty(geocodeErrors)) {
      errors.fields = { ...errors.fields, ...geocodeErrors }
      errors.form = 'There are one or more errors below'
      return resolve({ data, errors })
    }
    const address = makeAddress(data)
    return geocode(address)
      .then(({ lat, lng }) => {
        data.latitude = lat.toFixed(7)
        data.longitude = lng.toFixed(7)
        if (includeAddress) data.formatted_address = `${address}, USA`
        resolve({ data, errors })
      })
      .catch(msg => {
        errors.form = msg
        resolve({ data, errors })
      })
  })
}

const useForm = (
  fields,
  data,
  upsertItem,
  buttons,
  validation,
  showErrors,
  geocode,
  windowRef,
  options
) => {
  const [inputs, setInputs] = useState(data)
  const [isWorking, setIsWorking] = useState(false)

  useEffect(() => setInputs(data), [data])

  const handleSubmit = evt => {
    evt.preventDefault()
    setIsWorking(true)
    buttons.map(btn => (btn.current ? btn.current.blur() : null))
    const newData = {}
    const cleanFields = fields.filter(i => i.type !== 'break')
    cleanFields.map(f => {
      const val = maybeSetDefaultValue(f, inputs[f.field], options, inputs)
      if (typeof val !== 'undefined' && !f.disabled) {
        newData[f.field] = cleanFormValue(f.valType, val)
      }
    })
    const errors = validateForm(newData, validation)
    if (!isEmpty(errors)) {
      window.scroll(0, 0)
      setIsWorking(false)
      return showErrors(errors)
    }
    const includeAddress =
      fields.filter(i => i.field === 'formatted_address').length > 0
    geocoder(newData, errors, geocode, includeAddress).then(
      ({ data, errors }) => {
        if (!isEmpty(errors)) {
          showErrors(errors)
          setIsWorking(false)
          windowRef ? (windowRef.current.scrollTop = 0) : window.scrollTo(0, 0)
        } else {
          upsertItem(data).then(() => {
            setIsWorking(false)
            if (!windowRef) window.scrollTo(0, 0)
          })
        }
      }
    )
  }

  const handleInputChange = evt => {
    const { target, type } = evt
    const value =
      target.type === 'checkbox'
        ? target.checked
        : type === 'click' &&
          target.type !== 'textarea' &&
          target.type !== 'text'
        ? target.value.split(',')
        : target.value
    const field = fields.filter(i => i.field === target.id)[0]
    const resetFields =
      field && field.resetFields
        ? field.resetFields.reduce((obj, i) => ({ ...obj, [i]: '' }), {})
        : {}
    setInputs(inputs => ({ ...inputs, [target.id]: value, ...resetFields }))
  }

  return { inputs, handleInputChange, handleSubmit, isWorking }
}

const Form = React.memo(props => {
  const {
    isNew,
    endpoint,
    idField,
    fields,
    data,
    errors,
    options,
    actions,
    upsertItem,
    deleteItem,
    hasDelete,
    showErrors,
    validation,
    geocode,
    windowRef
  } = props
  const submitButton = useRef()

  const { inputs, handleInputChange, handleSubmit, isWorking } = useForm(
    fields,
    data,
    upsertItem,
    [submitButton],
    validation,
    showErrors,
    geocode,
    windowRef,
    options
  )

  const handleDelete = evt => {
    evt.preventDefault()
    return deleteItem(endpoint, data[idField])
  }

  const unhandledErrors = makeUnhandledErrors(fields, errors)

  // TODO: this isn't working after new item => redirect
  let activeFields = fields.filter(
    f => f.disabled !== true && f.type !== 'hidden' && f.readonly !== true
  )
  activeFields = actions && !isNew ? [] : activeFields

  const submitText = isWorking ? 'Submitting...' : isNew ? 'Submit' : 'Update'

  return (
    <div>
      <TransitionGroup>
        {unhandledErrors.length ? (
          <CSSTransition
            key="error"
            classNames="fade"
            timeout={{ enter: 250, exit: 0 }}
          >
            <>
              {unhandledErrors.map(e => (
                <div key={e.field} className="error error--top">
                  <p>
                    {e.field}: {e.error}
                  </p>
                </div>
              ))}
            </>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
      {fields && inputs ? (
        <form id="form" className="form" onSubmit={handleSubmit}>
          {fields
            .filter(i => !isNew || !i.hideNew)
            .map(f => {
              let error = errors.fields ? errors.fields[f.field] || '' : ''
              error = error.length ? translateError(error) : ''
              const fieldOptions = makeFieldOptions(f.options, options, inputs)
              const value = makeFieldValue(f, inputs, fieldOptions)
              if (typeof f.disabled !== 'undefined') {
                f.disabled = isNew ? false : true
              }
              if (typeof f.readonly !== 'undefined') {
                f.readonly = isNew ? false : true
              }
              return FormInput(f, value, error, handleInputChange, fieldOptions)
            })}
          <div className="form__submit">
            {activeFields.length ? (
              <input
                className="btn"
                type="submit"
                value={submitText}
                disabled={isWorking}
                ref={submitButton}
              />
            ) : null}
            {!isNew && hasDelete ? (
              <button className="btn btn--secondary" onClick={handleDelete}>
                Delete
              </button>
            ) : null}
          </div>
        </form>
      ) : null}
    </div>
  )
})

Form.displayName = 'Form'
Form.propTypes = {
  isNew: propTypes.bool,
  endpoint: propTypes.string,
  idField: propTypes.string,
  fields: propTypes.array,
  data: propTypes.object,
  errors: propTypes.object,
  options: propTypes.object,
  actions: propTypes.array,
  upsertItem: propTypes.func,
  deleteItem: propTypes.func,
  showErrors: propTypes.func,
  geocode: propTypes.func,
  hasDelete: propTypes.bool,
  validation: propTypes.object,
  windowRef: propTypes.shape({ current: propTypes.any })
}

export default Form
