import propTypes from 'prop-types'
import React, { Component } from 'react'
import { timezoneMap, orderTypeRevenueCenterMap } from '../lib/constants'
import {
  makeRequestedAt,
  makeZonedDateStr,
  dateStrToLocalDateStr
} from '../lib/helpersDatetime'
import { cleanPhone } from '../lib/helpers'

const Order = ({ order }) => {
  const a = order.customer_address
  const customer = order.customer
  return (
    <td>
      <p className="bold">{order.receipt_id}</p>
      {a ? (
        <>
          {a.company && <p>{a.company}</p>}
          <p>
            {a.street}
            {a.unit && `, ${a.unit}`}
          </p>
          <p>
            {a.city}, {a.state} {a.postal_code}
          </p>
        </>
      ) : (
        <>
          <p>Pickup Order</p>
          <p>{customer.first_name}</p>
        </>
      )}
    </td>
  )
}

Order.displayName = 'Order'
Order.propTypes = {
  order: propTypes.object
}

const Contact = ({ address }) => {
  return (
    <td>
      {address ? (
        <>
          {address.contact && <p>{address.contact}</p>}
          {address.phone && <p>{cleanPhone(address.phone)}</p>}
        </>
      ) : (
        'n/a'
      )}
    </td>
  )
}

Contact.displayName = 'Contact'
Contact.propTypes = {
  address: propTypes.object
}

class PrintRouter extends Component {
  static propTypes = {
    filtered: propTypes.array,
    orderType: propTypes.string,
    orderStatus: propTypes.array,
    businessDate: propTypes.string,
    store: propTypes.string
  }
  render() {
    const { filtered, orderType, orderStatus, businessDate, store } = this.props
    const orderTypeStr = orderType.length
      ? orderTypeRevenueCenterMap[orderType]
      : 'ALL'
    // const orderStatusStr = orderStatus.map(i => capitalize(i)).join(', ')
    const orderStatusStr = orderStatus.join(', ')
    const tz = timezoneMap[store.timezone]
    let orders = filtered,
      printDate = businessDate
    if (!printDate) {
      printDate = makeZonedDateStr(tz)
      orders = filtered.filter(i => i.business_date === printDate)
    }
    const displayDate = dateStrToLocalDateStr(printDate)
    return (
      <div className="print-order">
        <div className="print-order__header">
          <h2>{store.internal_name || store.full_name} Order Router</h2>
          <p>
            <span className="bold">{orderTypeStr}</span> orders for{' '}
            <span className="bold">{displayDate}</span> with status of{' '}
            <span className="bold">{orderStatusStr}</span>
          </p>
        </div>
        <table className="table--router">
          <thead>
            <tr>
              <th>Order / Address</th>
              <th>Contact</th>
              <th>Customer / Internal Notes</th>
              <th>Arrive</th>
              <th>Leave</th>
              <th>Assigned</th>
              <th>Tip</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              const evenOdd = (index + 1) % 2 === 0 ? '-even' : '-odd'
              const rc = order.revenue_center
              const timeRange =
                order.service_type === 'PICKUP'
                  ? rc.pickup_time_range
                  : rc.delivery_time_range
              const requestedAt = makeRequestedAt(
                order.requested_at,
                tz,
                timeRange
              )
              return (
                <tr key={order.receipt_id} className={evenOdd}>
                  <Order order={order} />
                  <Contact address={order.customer_address} />
                  <td>
                    {order.notes || 'n/a'}
                    {order.notes_internal && (
                      <>
                        <br />
                        <br />
                        <span className="bold">
                          INTERNAL: {order.notes_internal}
                        </span>
                      </>
                    )}
                  </td>
                  <td>{requestedAt.split(' @ ')[1]}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

PrintRouter.displayName = 'PrintRouter'

export default PrintRouter
