import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import logo from '../assets/open-tender-logo-white_1280x172.png'
// import logo from '../assets/open-tender-purple_720x97.png'

const Aside = ({ show }) => {
  // const fileUrl =
  //   '//s3.amazonaws.com/betterboh/u/img/local/2/1572716435_burger-black-and-white_1600x1809.jpg'
  return (
    <TransitionGroup>
      {show ? (
        <CSSTransition
          key="error"
          classNames="fade"
          timeout={{ enter: 250, exit: 0 }}
        >
          <aside
            className="aside"
            // style={{ backgroundImage: `url(${fileUrl})` }}
          >
            <div className="aside__container">
              <div className="aside__content">
                <img
                  className="aside__logo"
                  src={logo}
                  alt="Open Tender Logo"
                />
                {/* <div className="aside__message">
                  <p>an open platform for restaurant innovation</p>
                </div> */}
              </div>
            </div>
          </aside>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

Aside.displayName = 'Aside'
Aside.propTypes = {
  show: propTypes.bool
}

export default Aside
