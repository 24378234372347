import inside from 'point-in-polygon'
import { weekdaysUpper, RADIUS_MILES, RADIUS_KM } from './constants'
import { sortItems, capitalize } from './helpers'
import { isSameDay, format } from 'date-fns'
import { toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const displayOrderType = orderType => {
  return orderType === 'MAIN_MENU' ? 'OLO' : capitalize(orderType)
}

// https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
export const getDistance = (pointA, pointB, inMiles = true) => {
  const [lat1, lng1] = pointA
  const [lat2, lng2] = pointB
  const R = inMiles ? RADIUS_MILES : RADIUS_KM
  const dLat = deg2rad(lat2 - lat1) // see deg2rad below
  const dLng = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

const deg2rad = deg => {
  return deg * (Math.PI / 180)
}

export const inZone = (point, polygon) => {
  return inside(point, polygon)
}

export const parseDeliveryZone = delivery_zones => {
  try {
    const { coordinates, priority } = delivery_zones[0].delivery_zone
    return [JSON.parse(coordinates), priority]
  } catch {
    return [null, 0]
  }
}

export const getLatLng = address => {
  let latLng
  try {
    latLng = [parseFloat(address.latitude), parseFloat(address.longitude)]
  } catch {
    latLng = null
  }
  return latLng
}

export const sortRevenueCenters = (rcs, latLng) => {
  const open = rcs.filter(i => !i.closed).filter(i => !i.store.is_master)
  let sorting = { sortBy: 'full_name', sortType: 'alpha' }
  if (!latLng) return sortItems(open, sorting)
  const d = open.map(i => {
    const rcLatLng = i.address.latitude
      ? [i.address.latitude, i.address.longitude]
      : null
    i.distance = rcLatLng ? getDistance(latLng, rcLatLng) : 1000
    const [zone, priority] = parseDeliveryZone(i.delivery_zones)
    i.zone = zone
    i.priority = priority
    i.inZone = zone ? inZone(latLng, zone) : false
    return i
  })
  sorting = { sortBy: 'distance', sortType: 'order' }
  const inZoneWithPriority = sortItems(
    d.filter(i => i.inZone && i.priority),
    sorting
  )
  const inZoneWithoutPriority = sortItems(
    d.filter(i => i.inZone && !i.priority),
    sorting
  )
  const outOfZone = sortItems(
    d.filter(i => !i.inZone),
    sorting
  )
  return [...inZoneWithPriority, ...inZoneWithoutPriority, ...outOfZone]
}

export const tendersTotal = tenders => {
  return tenders
    ? tenders.reduce((t, i) => t + parseFloat(i.amount), 0.0).toFixed(2)
    : '0.00'
}

export const remainingBalance = (orderTotal, tendersTotal) => {
  const balance = parseFloat(orderTotal) - parseFloat(tendersTotal)
  return balance.toFixed(2)
}

export const tendersTotalAndBalance = (tenders, orderTotal) => {
  const total = tendersTotal(tenders)
  const balance = remainingBalance(orderTotal, total)
  return [total, balance]
}

// DATETIMES

export const makeIso = dateStr => {
  return new Date(dateStr).toISOString()
}

const setHoursMinutes = (date, hours, minutes) => {
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const makeNowTime = dateStr => {
  const date = toDate(dateStr)
  let newDate = new Date()
  newDate = setHoursMinutes(newDate, date.getHours(), date.getMinutes())
  return newDate.toISOString()
}

const minutesToMidnight = date => {
  return date.getHours() * 60 + date.getMinutes()
}

const getLastMinutes = (date, validTimes) => {
  const weekday = format(date, 'EEEE').toLowerCase()
  const endTimes = validTimes[weekday].map(i => i.end_min)
  return Math.max(...endTimes)
}

export const greaterIso = (current, first, tz, validTimes) => {
  let currentDate = utcToZonedTime(current, tz)
  let firstDate = utcToZonedTime(first, tz)
  // if the currently selected datetime is greater than the first available datetime, keep it
  if (currentDate >= firstDate) {
    // limit to last available time based on hours
    const lastMinutes = getLastMinutes(currentDate, validTimes)
    if (minutesToMidnight(currentDate) < lastMinutes) return current
    currentDate = setHoursMinutes(
      currentDate,
      Math.floor(lastMinutes / 60),
      lastMinutes % 60
    )
    const zonedDate = zonedTimeToUtc(currentDate, tz)
    return zonedDate.toISOString()
  }
  // if the currently selected datetime is on the same DATE (i.e. 2020-02-18) as the
  // first available datetime and the first available datetime is greater, we need to use that
  if (isSameDay(currentDate, firstDate)) return first
  // if the first available DATE is greater than the currently selected DATE and the
  // first available TIME day is greater than the currently selected TIME, use the first available
  if (minutesToMidnight(firstDate) > minutesToMidnight(currentDate))
    return first
  // otherwise use the first available DATE, but the currently selected TIME, limited
  // to the latest available time on the first available DATE
  const lastMinutes = getLastMinutes(firstDate, validTimes)
  const currentMinutes = minutesToMidnight(currentDate)
  const maxMinutes = Math.min(lastMinutes, currentMinutes)
  firstDate = setHoursMinutes(
    firstDate,
    Math.floor(maxMinutes / 60),
    maxMinutes % 60
  )
  const zonedDate = zonedTimeToUtc(firstDate, tz)
  return zonedDate.toISOString()
}

export const makeCurrentAt = () => {
  let date = new Date()
  const currentMinutes = date.getHours() * 60 + date.getMinutes()
  const nextInterval = Math.ceil(currentMinutes / 15) * 15
  const hours = Math.floor(nextInterval / 60)
  const minutes = nextInterval % 60
  date = setHoursMinutes(date, hours, minutes)
  return date.toISOString()
}

function* range(start, end, step) {
  for (let i = start; i <= end; i += step) {
    yield i
  }
}

export const makeExcludedTimes = (orderableTimes, interval) => {
  return [...range(0, 1440 - interval, interval)].filter(
    i => !orderableTimes.includes(i)
  )
}

export const makeWeekdaysExcluded = validTimes => {
  return Object.entries(validTimes).reduce((obj, entry) => {
    const [weekday, dayparts] = entry
    const orderableDayparts = dayparts.filter(d => d.is_orderable)
    if (!orderableDayparts.length) {
      obj[weekday.toUpperCase()] = null
      return obj
    }
    const interval = orderableDayparts[0].interval
    const orderableTimes = orderableDayparts.reduce((all, daypart) => {
      return all.concat(
        daypart.times.filter(i => i.is_orderable).map(i => i.minutes)
      )
    }, [])
    obj[weekday.toUpperCase()] = makeExcludedTimes(orderableTimes, interval)
    return obj
  }, {})
}

export const makeClosedWeekdays = weekdayTimes => {
  return Object.entries(weekdayTimes)
    .filter(([, times]) => !times)
    .map(([weekday]) => weekdaysUpper.indexOf(weekday))
}

// requestedDate must be submitted as a date object
export const makeDatepickerArgs = (
  requestedDate,
  weekdayTimes,
  excludedTimes
) => {
  const weekday = format(requestedDate, 'EEEE').toUpperCase()
  const dateStr = format(requestedDate, 'yyyy-MM-dd')
  // weekdayExcluded = times excluded based on regular hours + blocked hours
  const weekdayExcluded = weekdayTimes[weekday] || []
  // otherExcluded = times excluded due to holiday hours + throttled times
  const otherExcluded = excludedTimes[dateStr] || []
  const allExcluded = [
    ...new Set([...weekdayExcluded, ...otherExcluded])
  ].sort()
  // convert excluded minutes to excluded dates
  const excludeTimes = allExcluded.map(minutes => {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60
    const d = new Date()
    d.setHours(hours)
    d.setMinutes(mins)
    return d
  })
  // filter out days of the week that are always closed
  const closedWeekdays = makeClosedWeekdays(weekdayTimes)
  const isClosed = date => {
    return !closedWeekdays.includes(date.getDay())
  }
  return { excludeTimes, isClosed }
}
