import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Filter } from './Filters'
import Menu from './Menu'
import { updateFilter, viewItem, refreshMenu } from '../reducers/menu'
import { RefreshCw } from 'react-feather'

class BuiltMenu extends Component {
  static propTypes = {
    // state.menu
    loading: propTypes.bool,
    dayparts: propTypes.object,
    serviceTypes: propTypes.object,
    weekdays: propTypes.object,
    menu: propTypes.object,
    error: propTypes.string,
    isPreview: propTypes.bool,
    // mapdispatchtoprops
    updateFilter: propTypes.func,
    viewItem: propTypes.func,
    refreshMenu: propTypes.func
  }

  handleFilter = evt => {
    this.props.updateFilter(evt.target.id, evt.target.value)
    evt.target.blur()
  }

  handleRefresh = evt => {
    evt.preventDefault()
    this.props.refreshMenu()
    evt.target.blur()
  }

  render() {
    const {
      loading,
      dayparts,
      serviceTypes,
      weekdays,
      menu,
      viewItem
    } = this.props
    const filters =
      dayparts && serviceTypes && weekdays
        ? [weekdays, dayparts, serviceTypes]
        : []
    return (
      <div className="content__main">
        <div className="table__filters">
          {filters.map(filter => Filter(filter, this.handleFilter))}
        </div>
        {menu && (
          <div className="table__results">
            <div className="table__results__message">
              <span className="table__results__refresh">
                <button
                  className="btn-link"
                  type="button"
                  onClick={this.handleRefresh}
                >
                  <RefreshCw size={16} />
                </button>
              </span>
              <p>Refresh menu</p>
            </div>
          </div>
        )}
        <Menu
          menu={menu ? menu.menu : null}
          loading={loading}
          itemEdit={viewItem}
          isPreview={true}
        />
      </div>
    )
  }
}

BuiltMenu.displayName = 'BuiltMenu'

export default connect(
  state => ({
    ...state.menu
  }),
  { updateFilter, viewItem, refreshMenu }
)(BuiltMenu)
