import React from 'react'
import { Link } from 'react-router-dom'

const pageContent = pathname => {
  switch (pathname) {
    case '/':
      // return null
      return (
        <div className="content__main__content mobile">
          <p>
            <span className="hint">Hint:</span> the buttons below correspond to
            the primary sections of the Admin Portal and the icons you see in
            the lefthand sidebar.
          </p>
        </div>
      )
    case '/not_found':
      return (
        <div>
          <p>
            <Link to="/">Click here</Link> to head back to the homepage
          </p>
        </div>
      )
    default:
      return (
        <div>
          <p>{pathname}</p>
        </div>
      )
  }
}

export default pageContent
