import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  upsertSetting,
  deleteSetting,
  showSettingErrors
} from '../reducers/setting'
import { makeStateOptions, cssTransitionFade } from '../lib/helpersPage'
import Form from './Form'
import ClipLoader from 'react-spinners/ClipLoader'
import { PageError } from './PageError'
import withGecoder from './withGeoder'

class Setting extends Component {
  static propTypes = {
    // from page.setting state object
    endpoint: propTypes.string,
    fields: propTypes.array,
    hasDelete: propTypes.bool,
    hasGeocoder: propTypes.bool,
    validation: propTypes.object,
    // from setting state object
    data: propTypes.object,
    errors: propTypes.object,
    // from setting reducer
    upsertSetting: propTypes.func,
    deleteSetting: propTypes.func,
    showSettingErrors: propTypes.func,
    // other state items
    options: propTypes.array,
    stateOptions: propTypes.object
  }

  render() {
    const { endpoint, options, stateOptions } = this.props
    const formOptions = options ? makeStateOptions(options, stateOptions) : null
    const SettingForm = this.props.hasGeocoder ? withGecoder(Form) : Form
    return (
      <>
        <PageError msg={this.props.errors.form} />
        {!this.props.data && (
          <div className="content__loading">
            <ClipLoader size={44} color={'#5a5aff'} />
          </div>
        )}
        <TransitionGroup>
          {this.props.data ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="content__main">
                <SettingForm
                  isNew={false}
                  endpoint={endpoint}
                  // idField={id}
                  fields={this.props.fields}
                  data={this.props.data}
                  errors={this.props.errors}
                  options={formOptions}
                  upsertItem={this.props.upsertSetting}
                  deleteItem={this.props.deleteSetting}
                  hasDelete={this.props.hasDelete}
                  validation={this.props.validation}
                  showErrors={this.props.showSettingErrors}
                />
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>
    )
  }
}

Setting.displayName = 'Setting'

export default connect(
  state => ({
    ...state.page.setting,
    ...state.setting,
    options: state.page.options,
    stateOptions: state.options
  }),
  { upsertSetting, deleteSetting, showSettingErrors }
)(Setting)
