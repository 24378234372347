import propTypes from 'prop-types'
import React, { Component } from 'react'
import { isEmpty } from '../lib/utils'
import { connect } from 'react-redux'
import { makeListEndpoint } from '../lib/helpersPage'
import {
  reorderList,
  fetchLink,
  updateItem,
  listActions,
  selectItem,
  selectAll,
  refreshItems,
  updateDateRange,
  updateRefresh
} from '../reducers/list'
import { updateFilter, resetFilters } from '../reducers/page'
import { updateQuery, clearQuery, retrieveResults } from '../reducers/search'
import Table from './Table'
import DraggableList from './DraggableList'
import { TableResults } from './TableResults'
import { Filter } from './Filters'
import { SearchInput } from './Search'
import DateRange from './reporting/DateRange'

class List extends Component {
  static propTypes = {
    // props
    endpoint: propTypes.string,
    newEndpoint: propTypes.string,
    relation: propTypes.string,
    id: propTypes.string,
    contentClasses: propTypes.string,
    classes: propTypes.string,
    sorting: propTypes.object,
    filters: propTypes.array,
    fields: propTypes.array,
    search: propTypes.object,
    isList: propTypes.bool,
    isPunches: propTypes.bool,
    newButtonText: propTypes.string,
    hideNew: propTypes.bool,
    actions: propTypes.array,
    dateFilters: propTypes.bool,
    autoRefresh: propTypes.bool,
    // ...state.list
    items: propTypes.array,
    links: propTypes.object,
    loading: propTypes.bool,
    error: propTypes.string,
    range: propTypes.string,
    // mapStateToProps
    stateSearch: propTypes.object,
    pageId: propTypes.string,
    user: propTypes.object,
    // mapdispatchtoprops
    reorderList: propTypes.func,
    updateFilter: propTypes.func,
    resetFilters: propTypes.func,
    fetchLink: propTypes.func,
    updateQuery: propTypes.func,
    retrieveResults: propTypes.func,
    clearQuery: propTypes.func,
    updateItem: propTypes.func,
    listActions: propTypes.func,
    selectItem: propTypes.func,
    selectAll: propTypes.func,
    refreshItems: propTypes.func,
    updateDateRange: propTypes.func
  }

  handleFilter = evt => {
    this.props.clearQuery()
    const { type, checked, value, id } = evt.target
    const filterValue = type === 'checkbox' ? checked : value
    const filter = { field: id, value: filterValue }
    const autoRefresh =
      this.props.autoRefresh === undefined ? true : this.props.autoRefresh
    this.props.updateFilter(filter, autoRefresh)
    if (!autoRefresh) this.props.updateRefresh(true)
    if (type !== 'datepicker') evt.target.blur()
  }

  handleResetFilters = evt => {
    evt.preventDefault()
    this.props.clearQuery()
    const autoRefresh =
      this.props.autoRefresh === undefined ? true : this.props.autoRefresh
    this.props.resetFilters(autoRefresh)
    if (!autoRefresh) this.props.updateRefresh(true)
    evt.target.blur()
  }

  getNext = evt => {
    evt.preventDefault()
    this.props.fetchLink(evt.target.href)
  }

  handleInput = evt => {
    this.props.updateQuery(evt.target.value)
  }

  handleEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      const props = { endpoint: this.props.search.endpoint }
      this.props.retrieveResults(props)
      this.props.resetFilters()
    }
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearQuery()
    this.props.resetFilters()
  }

  handleDateRange = (evt, range) => {
    evt.preventDefault()
    this.props.updateDateRange(range)
    evt.target.blur()
  }

  render() {
    const {
      filters,
      endpoint,
      pageId,
      relation,
      search,
      stateSearch,
      dateFilters,
      range
    } = this.props
    const hasFilters = !isEmpty(filters)
    const listEndpoint = makeListEndpoint(endpoint, pageId, relation)
    const items = stateSearch.results || this.props.items
    const sorting = stateSearch.results ? null : this.props.sorting
    const csvAction = this.props.actions
      ? this.props.actions.find(a => a.endpoint && a.endpoint.includes('/csv'))
      : null
    const csvEndpoint = csvAction ? csvAction.endpoint : null
    const tableProps = {
      classes: this.props.classes,
      fields: this.props.fields,
      items: items,
      sorting: sorting,
      id: this.props.id,
      loading: stateSearch.loading || this.props.loading,
      listEndpoint: listEndpoint,
      reorderList: this.props.reorderList,
      updateItem: this.props.updateItem,
      selectItem: this.props.selectItem,
      selectAll: this.props.selectAll,
      isRelation: false,
      refreshNeeded: this.props.refreshNeeded,
      refreshItems: this.props.refreshItems,
      csvEndpoint: csvEndpoint
    }
    return (
      <div className="content__main">
        {hasFilters || search ? (
          <div className="table__filters">
            {filters.map(filter => Filter(filter, this.handleFilter))}
            {search && (
              <div className="table__filter">
                <SearchInput
                  label={search.label}
                  query={stateSearch.query}
                  placeholder={search.placeholder}
                  clearSize={14}
                  handleInput={this.handleInput}
                  handleEnter={this.handleEnter}
                  handleClear={this.handleClear}
                />
              </div>
            )}
          </div>
        ) : null}
        {dateFilters && (
          <DateRange value={range || ''} handler={this.handleDateRange} />
        )}
        <TableResults
          user={this.props.user}
          items={items}
          links={this.props.links}
          endpoint={listEndpoint}
          newEndpoint={this.props.newEndpoint || null}
          next={this.getNext}
          reset={this.handleResetFilters}
          hasFilters={hasFilters}
          buttonText={this.props.newButtonText}
          hideNew={this.props.hideNew}
          actions={this.props.actions}
          listActions={this.props.listActions}
          refreshItems={this.props.refreshItems}
        />
        {this.props.isList ? (
          <DraggableList {...tableProps} />
        ) : (
          <Table {...tableProps} />
        )}
      </div>
    )
  }
}

List.displayName = 'List'

export default connect(
  state => ({
    ...state.list,
    stateSearch: state.search,
    pageId: state.page.id,
    user: state.user
  }),
  {
    reorderList,
    updateFilter,
    resetFilters,
    fetchLink,
    updateQuery,
    clearQuery,
    retrieveResults,
    updateItem,
    listActions,
    selectItem,
    selectAll,
    refreshItems,
    updateDateRange,
    updateRefresh
  }
)(List)
