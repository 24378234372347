import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addDiscount } from '../../reducers/order'

class Discounts extends Component {
  static propTypes = {
    // state.options.discounts
    discounts: propTypes.array,
    // state.order.discountIds
    discountIds: propTypes.array,
    // mapdispatchtoprops
    addDiscount: propTypes.func
  }

  handleDiscount = evt => {
    evt.preventDefault()
    this.props.addDiscount(parseInt(evt.target.id))
    evt.target.blur()
  }

  render() {
    const { discounts, discountIds } = this.props
    const activeDiscounts = discounts.filter(i => i.is_active)
    return (
      <div className="discounts btn-group">
        {activeDiscounts.length ? (
          activeDiscounts.map((s, i, a) => {
            const lastOdd = i === a.length - 1 && i % 2 === 0 ? '-last-odd' : ''
            const lastEven =
              i === a.length - 1 && i % 2 === 1 ? '-last-even' : ''
            const last2ndOdd =
              i === a.length - 2 && i % 2 === 0 ? '-last-2nd-odd' : ''
            const classes = [lastOdd, lastEven, last2ndOdd].join(' ')
            return (
              <button
                key={s.discount_id}
                id={s.discount_id}
                className={`btn-group__btn ${classes}`}
                onClick={this.handleDiscount}
                disabled={(discountIds || []).includes(s.discount_id)}
              >
                <span>{s.name}</span>
              </button>
            )
          })
        ) : (
          <p>Looks like you {"don't"} currently have any active discounts.</p>
        )}
      </div>
    )
  }
}

Discounts.displayName = 'Discounts'

export default connect(
  state => ({
    discounts: state.options.discounts,
    discountIds: state.order.discountIds
  }),
  { addDiscount }
)(Discounts)
