import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editOrderAction, editOpenOrder } from '../../reducers/orderMgmt'

const options = [
  { name: 'Change Revenue Center', action: 'revenueCenter' },
  { name: 'Change Service Type', action: 'serviceType' },
  { name: 'Change Date and/or Time', action: 'requestedAt' },
  { name: 'Adjust Order Prep Time', action: 'prepTime' },
  { name: 'Adjust Tip', action: 'tip' },
  { name: 'Adjust Tax Exempt Status', action: 'tax' },
  { name: 'Adjust Internal Notes', action: 'notesInternal' }
]

class OrderEdit extends Component {
  static propTypes = {
    orderId: propTypes.number,
    permissions: propTypes.array,
    editOrderAction: propTypes.func,
    editOpenOrder: propTypes.func
  }

  handleOrderAction = (evt, orderId, action) => {
    evt.preventDefault()
    this.props.editOrderAction(orderId, action)
    evt.target.blur()
  }

  handleOrderEdit = (evt, orderId) => {
    evt.preventDefault()
    this.props.editOpenOrder(orderId)
    evt.target.blur()
  }

  render() {
    const { orderId, permissions } = this.props
    const hasFullEdit = permissions.includes('edit_orders_full')
    return (
      <div className="modal__content">
        <div className="modal__header">
          <h2>Edit Order #{orderId}</h2>
          <p>Choose one of the options below</p>
        </div>
        <div className="modal__body">
          <div className="order-edit-options">
            <ul className="btn-list -center">
              {options.map(i => (
                <li key={i.action}>
                  <button
                    className="btn"
                    onClick={evt =>
                      this.handleOrderAction(evt, orderId, i.action)
                    }
                  >
                    <span>{i.name}</span>
                  </button>
                </li>
              ))}
            </ul>
            {hasFullEdit && (
              <ul className="btn-list -center">
                <li>
                  <button
                    className="btn"
                    onClick={evt => this.handleOrderEdit(evt, orderId)}
                  >
                    <span>Or click here to perform a Full Edit</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    )
  }
}

OrderEdit.displayName = 'OrderEdit'

export default connect(
  state => ({
    permissions: state.user.permissions
  }),
  { editOrderAction, editOpenOrder }
)(OrderEdit)
