import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  weekdayOptions,
  serviceType as serviceTypeOptions
} from '../../lib/constants'
import Form from '../Form'
import { PageError } from '../PageError'
import { insertOrderTime } from '../../reducers/orderTimes'
import { isEmpty } from '../../lib/utils'

const fields = [
  {
    label: 'Weekday',
    field: 'weekday',
    type: 'select',
    options: weekdayOptions,
    defaultValue: 'SUNDAY',
    classes: '-half'
  },
  {
    label: 'Service Type',
    field: 'service_type',
    type: 'select',
    options: [...serviceTypeOptions].slice(1),
    defaultValue: 'PICKUP',
    classes: '-half -right'
  },
  {
    label: 'Start Time',
    field: 'start_time',
    type: 'text',
    placeholder: '2:00 PM',
    classes: '-half'
  },
  {
    label: 'End Time',
    field: 'end_time',
    type: 'text',
    placeholder: '3:00 PM',
    classes: '-half -right'
  },
  {
    label: 'Order By Weekday',
    field: 'order_by_weekday',
    type: 'select',
    options: weekdayOptions,
    defaultValue: 'SUNDAY',
    classes: '-half'
  },
  {
    label: 'Order By Time',
    field: 'order_by_time',
    type: 'text',
    placeholder: '9:00 AM',
    classes: '-half -right'
  }
]

class OrderTimes extends Component {
  static propTypes = {
    errors: propTypes.object,
    windowRef: propTypes.shape({ current: propTypes.any }),
    insertOrderTime: propTypes.func
  }

  render() {
    const { errors, windowRef, insertOrderTime } = this.props
    if (!isEmpty(errors) && windowRef.current) windowRef.current.scrollTop = 0
    return (
      <>
        <PageError msg={errors ? errors.form : ''} />
        <Form
          isNew={false}
          fields={fields}
          data={{}}
          errors={errors || {}}
          upsertItem={insertOrderTime}
          windowRef={windowRef}
        />
      </>
    )
  }
}

OrderTimes.displayName = 'OrderTimes'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { insertOrderTime }
)(OrderTimes)
