import propTypes from 'prop-types'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import PrintRouter from '../PrintRouter'
import { Printer } from 'react-feather'

class OrderRouter extends Component {
  static propTypes = {
    args: propTypes.object
  }

  render() {
    const { args } = this.props
    return (
      <div className="print-order__wrapper">
        <ReactToPrint
          trigger={() => (
            <button className="btn-link print-order__btn">
              <Printer size={16} />
              <span>Print Router</span>
            </button>
          )}
          content={() => this.componentRef}
        />
        <PrintRouter {...args} ref={el => (this.componentRef = el)} />
      </div>
    )
  }
}

OrderRouter.displayName = 'OrderRouter'

export default OrderRouter
