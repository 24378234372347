import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { retrieveMenuExtPayload } from '../../reducers/mapping'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatDateStr } from '../../lib/helpersDatetime'

class MenuExtPayload extends Component {
  static propTypes = {
    menuExtId: propTypes.number,
    menuExtPayload: propTypes.object,
    retrieveMenuExtPayload: propTypes.func
  }

  componentDidMount() {
    const { menuExtId, retrieveMenuExtPayload } = this.props
    retrieveMenuExtPayload('menu_ext_id', menuExtId)
  }

  render() {
    const { menuExtPayload } = this.props
    return (
      <div>
        {menuExtPayload ? (
          <div className="api-request">
            <p>
              Pushed to third party on{' '}
              {formatDateStr(
                menuExtPayload.created_at,
                null,
                "yyyy-MM-dd 'at' h:mm a"
              )}
            </p>
            <pre>{JSON.stringify(menuExtPayload.menu, null, 2)}</pre>
          </div>
        ) : (
          <ClipLoader size={24} color={'#5a5aff'} />
        )}
      </div>
    )
  }
}

MenuExtPayload.displayName = 'MenuExtPayload'

export default connect(
  state => ({
    menuExtPayload: state.mapping.menuExtPayload
  }),
  { retrieveMenuExtPayload }
)(MenuExtPayload)
