import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editRevenueCenter } from '../../reducers/orderMgmt'
import OrderRevenueCenters from './OrderRevenueCenters'

class EditRevenueCenter extends Component {
  static propTypes = {
    orderId: propTypes.number,
    revenueCenters: propTypes.array,
    windowRef: propTypes.shape({ current: propTypes.any }),
    errors: propTypes.object,
    editRevenueCenter: propTypes.func
  }

  handleChoose = (evt, revenueCenter) => {
    evt.preventDefault()
    const orderId = this.props.orderId
    this.props.editRevenueCenter(orderId, revenueCenter)
    evt.target.blur()
  }

  render() {
    const { revenueCenters } = this.props
    return (
      <OrderRevenueCenters
        revenueCenters={revenueCenters}
        handler={this.handleChoose}
      />
    )
  }
}

EditRevenueCenter.displayName = 'EditRevenueCenter'

export default connect(
  state => ({
    errors: state.modal.errors
  }),
  { editRevenueCenter }
)(EditRevenueCenter)
