import { request } from '../lib/services'
import { isEmpty } from '../lib/utils'
import {
  updatePage,
  makeNewFilters,
  makeResetFilters,
  handlePageError
} from './page'
import { flashMessage } from './messages'
import { makeFilterLookup } from '../lib/helpersPage'
import { sortItems } from '../lib/helpers'

const initState = {
  loading: false,
  items: null,
  counts: null,
  forecast: null,
  disabled: [],
  errors: {}
}

export const INVENTORY_CLEAR = 'INVENTORY_CLEAR'
export const INVENTORY_LOADING = 'INVENTORY_LOADING'
export const INVENTORY_LOAD = 'INVENTORY_LOAD'
export const INVENTORY_ERRORS = 'INVENTORY_ERRORS'
export const INVENTORY_UPDATE = 'INVENTORY_UPDATE'

export const clearInventory = () => ({ type: INVENTORY_CLEAR })
export const loadingInventory = () => ({ type: INVENTORY_LOADING })
export const loadInventory = data => ({
  type: INVENTORY_LOAD,
  payload: data
})
export const showInventoryErrors = errors => ({
  type: INVENTORY_ERRORS,
  payload: errors
})

export const fetchInventory = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearInventory())
    const lookup = makeFilterLookup(page.inventory.filters)
    const itemFilter = lookup.category_id || lookup.modifier_group_id
    if (!itemFilter) return
    dispatch(loadingInventory())
    const params = `${itemFilter.field}=${itemFilter.value}`
    const itemsPromise = request(token, brand, `/items?${params}`)
    const countEndpoint = `/stores/${page.id}/inventory`
    const countPromise = request(token, brand, countEndpoint)
    const forecastEndpoint = `/inventory?store_id=${page.id}`
    const forecastPromise = request(token, brand, forecastEndpoint)
    // const restrictedEndpoint = `/restricted-items?revenue_center_id=${page.id}&${params}`
    // const restrictedPromise = request(token, brand, restrictedEndpoint)
    Promise.all([itemsPromise, countPromise, forecastPromise])
      .then(values => {
        let [itemsResp, counts, forecast] = values
        const sorting = { sortBy: 'short_name', sortType: 'alpha' }
        const items = sortItems(itemsResp.data, sorting)
        // const disabled = restricted.data.map(i => i.item_id)
        dispatch(loadInventory({ items, counts, forecast }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const updateFilter = filter => {
  return (dispatch, getState) => {
    const { inventory } = getState().page
    const newFilters = makeNewFilters(inventory.filters, filter)
    const newInventory = { ...inventory, filters: newFilters }
    dispatch(updatePage({ inventory: newInventory }))
    dispatch(fetchInventory())
  }
}

export const resetFilters = () => {
  return (dispatch, getState) => {
    const { inventory } = getState().page
    const newFilters = makeResetFilters(inventory.filters)
    const newInventory = { ...inventory, filters: newFilters }
    dispatch(updatePage({ inventory: newInventory }))
    dispatch(fetchInventory())
  }
}

export const updateReset = (itemId, checked) => {
  return (dispatch, getState) => {
    const { inventory } = getState()
    const selected = inventory.counts.filter(i => i.item_id === itemId)
    const newCount = selected.length
      ? { ...selected[0], reset_daily: checked }
      : { item_id: itemId, reset_daily: checked, starting_quantity: 0 }
    const other = inventory.counts.filter(i => i.item_id !== itemId)
    const newCounts = [...other, newCount]
    dispatch(loadInventory({ counts: newCounts }))
  }
}

export const updateCount = (itemId, count) => {
  return (dispatch, getState) => {
    const { inventory } = getState()
    const selected = inventory.counts.filter(i => i.item_id === itemId)
    const newCount = selected.length
      ? { ...selected[0], starting_quantity: count }
      : { item_id: itemId, reset_daily: true, starting_quantity: count }
    const other = inventory.counts.filter(i => i.item_id !== itemId)
    const newCounts = [...other, newCount]
    dispatch(loadInventory({ counts: newCounts }))
  }
}

const validateCounts = counts => {
  return counts.map(i => ({
    ...i,
    starting_quantity:
      i.starting_quantity === '' ? 0 : parseInt(i.starting_quantity)
  }))
}

export const updateInventory = () => {
  return (dispatch, getState) => {
    const { token, brand, page, inventory } = getState()
    const validated = validateCounts(inventory.counts)
    const errors = validated.reduce((obj, i) => {
      if (isNaN(i.starting_quantity)) obj[i.item_id] = 'Invalid'
      return obj
    }, {})
    if (!isEmpty(errors)) {
      window.scroll(0, 0)
      return dispatch(showInventoryErrors(errors))
    }
    const deleted = validated.filter(
      i => i.starting_quantity === 0 && i.reset_daily
    )
    const updated = validated.filter(
      i => !(i.starting_quantity === 0 && i.reset_daily)
    )
    const endpoint = `/stores/${page.id}/inventory`
    const deletedRequest = request(token, brand, endpoint, 'DELETE', deleted)
    const updatedRequest = request(token, brand, endpoint, 'POST', updated)
    Promise.all([deletedRequest, updatedRequest])
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchInventory())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => dispatch(handlePageError(err)))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case INVENTORY_CLEAR:
      return { ...initState }
    case INVENTORY_LOADING:
      return { ...state, loading: true }
    case INVENTORY_LOAD:
      return { ...state, ...action.payload, errors: {}, loading: false }
    case INVENTORY_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    default:
      return state
  }
}
