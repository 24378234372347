import { request } from '../lib/services'
import { makeLocalDateStr } from '../lib/helpersDatetime'
import { handlePageError, clearPageError } from './page'
import { flashMessage } from './messages'

const initState = {
  storeId: null,
  businessDate: null,
  submitting: false,
  loading: false,
  xml: null
}

export const SYNC_CLEAR = 'SYNC_CLEAR'
export const SYNC_UPDATE = 'SYNC_UPDATE'
export const SYNC_ERRORS = 'SYNC_ERRORS'

export const clearSync = () => ({ type: SYNC_CLEAR })
export const updateSync = data => ({ type: SYNC_UPDATE, payload: data })

export const updateStore = storeId => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    const businessDate = getState().sync.businessDate || makeLocalDateStr(-1)
    dispatch(updateSync({ storeId, businessDate, submitting: false }))
  }
}

export const updateBusinessDate = businessDate => {
  return dispatch => {
    dispatch(clearPageError())
    dispatch(updateSync({ businessDate, submitting: false }))
  }
}

export const syncData = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(updateSync({ submitting: true, xml: null }))
    const { token, brand, page, sync } = getState()
    const { endpoint } = page.sync
    const { storeId, businessDate } = sync
    const data = { business_date: businessDate, store_id: storeId }
    request(token, brand, endpoint, 'POST', data)
      .then(() => {
        dispatch(flashMessage('Successfully imported'))
        dispatch(clearPageError())
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(updateSync({ submitting: false })))
  }
}

export const retrieveXml = () => {
  return (dispatch, getState) => {
    dispatch(clearPageError())
    dispatch(updateSync({ loading: true, xml: null }))
    const { token, brand, page, sync } = getState()
    const { xmlEndpoint } = page.sync
    const { storeId, businessDate } = sync
    const endpoint = `${xmlEndpoint}?store_id=${storeId}&business_date=${businessDate}`
    request(token, brand, endpoint, 'GET', null, null, 'text/xml')
      .then(xml => {
        dispatch(flashMessage('Successfully imported'))
        dispatch(updateSync({ xml }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(updateSync({ loading: false })))
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case SYNC_CLEAR:
      return { ...initState }
    case SYNC_UPDATE:
      return { ...state, ...action.payload }
    case SYNC_ERRORS:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
