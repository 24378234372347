import propTypes from 'prop-types'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import ClipLoader from 'react-spinners/ClipLoader'
import StickyNav from './StickyNav'
import { slugify } from '../lib/helpers'

const MenuItem = ({ item, handler, isPreview }) => {
  const handleClick = evt => {
    evt.preventDefault()
    handler(item)
    evt.target.blur()
  }
  return (
    <div key={item.id} className="menu__item">
      <button onClick={handleClick}>
        {isPreview && <p className="menu__item__id">{item.id}</p>}
        {item.small_image_url && (
          <div
            className="menu__item__image"
            style={{
              backgroundImage: `url(${item.small_image_url})`
            }}
          />
        )}
        <div className="menu__item__content">
          <h3 className="menu__item__name">{item.name}</h3>
          {item.description && (
            <p className="menu__item__desc">{item.description}</p>
          )}
          <p className="menu__item__price">
            ${item.price}
            {item.points && <span> / {item.points} points</span>}
          </p>
        </div>
      </button>
    </div>
  )
}

MenuItem.displayName = 'MenuItem'
MenuItem.propTypes = {
  item: propTypes.object,
  handler: propTypes.func,
  isPreview: propTypes.bool
}

const Category = ({ category, itemEdit, isChild, isPreview }) => {
  const child = isChild ? '-child' : ''
  return (
    <div
      key={category.id}
      id={slugify(category.name)}
      className={`menu__category ${child}`}
    >
      <div className="menu__category__header">
        {isChild ? (
          <h3 className="menu__category__title">{category.name}</h3>
        ) : (
          <h2 className="menu__category__title">{category.name}</h2>
        )}
        <p>{category.description}</p>
      </div>
      <div className="menu__items">
        {category.items.map(item => (
          <MenuItem
            key={item.id}
            item={item}
            handler={itemEdit}
            isPreview={isPreview}
          />
        ))}
      </div>
    </div>
  )
}

Category.displayName = 'Category'
Category.propTypes = {
  category: propTypes.object,
  itemEdit: propTypes.func,
  isChild: propTypes.bool,
  isPreview: propTypes.bool
}

const Menu = ({ menu, loading, itemEdit, isPreview = false }) => {
  const navItems = menu ? menu.map(i => i.name) : []
  return (
    <div className="menu">
      {loading && (
        <div className="content__loading">
          <ClipLoader size={44} color={'#5a5aff'} />
        </div>
      )}
      <TransitionGroup>
        {!loading && menu ? (
          <CSSTransition {...cssTransitionFade}>
            <div className="menu">
              <StickyNav items={navItems} offset={0} />
              {menu.map(category => (
                <div key={category.id}>
                  <Category
                    category={category}
                    itemEdit={itemEdit}
                    isPreview={isPreview}
                  />
                  {category.children.map(category => (
                    <Category
                      key={category.id}
                      category={category}
                      itemEdit={itemEdit}
                      isChild={true}
                      isPreview={isPreview}
                    />
                  ))}
                </div>
              ))}
            </div>
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </div>
  )
}

Menu.displayName = 'Menu'
Menu.propTypes = {
  menu: propTypes.array,
  loading: propTypes.bool,
  itemEdit: propTypes.func,
  isPreview: propTypes.bool
}

export default Menu
