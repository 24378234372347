const subtitles = {
  promoCodes: 'Discount codes that can be shared with your customers',
  deals: 'Offers that can be made available to ALL users',
  rewards: 'Targeted rewards that can be assigned to certain customers',
  regenerate:
    'Publish your latest menu changes to your customer-facing website for one or more revenue centers',
  stores:
    'Used for grouping revenue centers for order management and reporting purposes',
  storeGroups:
    'These are used for grouping stores for display purposes here in the admin portal',
  storeInventory:
    'Set inventory counts for individual items or modifiers at this store, which then applies to all revenue centers associated with this store.',
  revenueCenters:
    "You'll need one revenue center for each order type offered at each of your stores",
  pricingRegions: 'Used to manage pricing across groups of revenue centers',
  sales: 'A snapshot of sales performance for an individual store',
  throughput:
    'Traffic and sales data broken down by time segment (hourly, etc.)',
  itemCountsByStore: 'Counts by item type across each of your stores',
  orderPrepPerformance:
    'Order prep performance relative to the promised time for orders processed through the Open Tender KDS',
  orderPrep: 'Order prep timing details for individual orders',
  cash: 'Cash activity for each cashier shift at a single store on a single business date',
  discounts:
    'A list of discounts and promo codes redeemed, filterable in various way',
  paidInOuts:
    'A list of all paid ins and paid outs for a single store for a specified time range',
  voids:
    'A list of all voided orders for a single store for a specified time range',
  refunds:
    'A list of all refunds for a single store for a specified time range',
  hoursForWeek:
    'A summary of hours worked during a specified time period for one or all locatins',
  accounting: 'A summary of accounting metrics across all of your stores',
  reportPromoCodes:
    'A summary of promo code redemptions across all of your stores',
  reportLevelUpConnections:
    "A list of all LevelUp connections with the customer's Open Tender email and LevelUp email",
  ratings: 'Order ratings from customers filtered in various ways',
  productMix:
    'Quantities sold and Sales for your menu items and modifiers over a specified time range and filtered in various ways',
  subscribers:
    'A list of all of your customers that have opted into one or more of your email lists',
  dashboard:
    'A summary of performance across all of your stores for a specified time period',
  donations: 'A list of all donations made by your customers',
  salesByPeriod:
    'Sales over time broken down by day, week, or month and optionally filtered by location, service type, and order type',
  salesOverTime:
    'Sales & checks over time broken down by sales channel, order type, service type, or device type.',
  taxesSummary:
    'A summary of taxes by type for each of your stores during a certain time period',
  tendersSummary:
    'A summary of tenders by type for each of your stores during a certain time period',
  tendersDiff:
    'A list of any orders where the tender or refund total does not match the order total',
  creditSummary:
    'A summary of credit tenders by card type for each of your stores during a certain time period',
  giftCardCredits: 'All gift card credits within a specified date range',
  menuExtUpdates:
    'Review menus that were pushed to Chowly and see what changed over time',
  receipts:
    'A list of all orders with status of CLOSED and PAID over a certain period of time',
  taxes:
    'A list of individual taxes charged over a given time frame, optionally filtered by type of tax',
  tenders:
    'A list of individual tenders over a given time frame, optionally filtered by tender type',
  config:
    'Use this section to customize the design and content of your Open Tender apps',
  configContent:
    'The words that are displayed to customers throughout the different pages of the app',
  configContentHome:
    'This is the landing page where users choose an order type',
  configContentOrderType: 'The page where the user selects their order type',
  configContentLocations:
    'This is where the map appears with a list of locations',
  configContentAbout: 'The About page on your website',
  configContentCatering:
    'This is where users select a date & time for their catering event before being taken to the menu',
  configContentCateringSite:
    'The catering menu page on your website outside of the catering ordering flow, which is typically used to display your catering menu and policy',
  configContentMenu: 'The menu page within the online ordering flow',
  configContentMenuSite:
    'The menu page on your website outside of the online ordering flow',
  configContentUpsells:
    'Optional screens that present customers with add-on options',
  configContentCheckoutFlow:
    'The series of pages leading to the checkout page for logged out users',
  configContentCheckout: 'The checkout page',
  configContentConfirmation:
    'The order confirmation page that customers see after placing an order',
  configContentFulfillment:
    'The landing page for curbside pickup orders where customers let you know they have arrived',
  configContentGuest:
    "The landing for customer's who do not have accounts or are not logged in.",
  configContentAccount: "The customer's account page",
  configContentOrders:
    "The customer's order history page, which allows them to see both recent orders and recently ordered items",
  configContentFavorites:
    "The customer's favorites page where they can manage their favorites and reorder them",
  configContentRewards: "The customer's rewards page",
  configContentDeals: 'The deals page that shows all available deals (if any)',
  configContentAccountSettings:
    'Landing page that allows the customer to manage various account settings',
  configContentAllergens:
    'Allows customers to manage their allergen preferences',
  configContentCommunicationPrefs:
    'Allows customers to manage their communication preferences',
  configContentGiftCardsAccount:
    'Allows customers to manage their gift cards or purchase new ones (for themselves)',
  configContentCreditCards:
    'Allows customers to manage their saved & linked credit cards',
  configContentAddresses:
    "The page that lists all of a customer's addresses, accessible via their account page",
  configContentHouseAccounts:
    'Displays the house accounts that the customer is associated with, if any',
  configContentProfile:
    'Allows the customer to manage their account profile and communication preferences',
  configContentReset: 'The page where your customers reset their passwords',
  configContentSignUp: "The sign up page where customer's create an account",
  configContentGroupOrders:
    "The pages where customers join group orders and review the items they've submitted",
  configContentGiftCards:
    'The page where customers with or without accounts can purchase gift cards for digital and in-store use',
  configContentDonations: 'Collect donations for a worthy cause of your choice',
  configContentAccessibility: 'Your accessibility policy page',
  configContentRefunds: 'Your refund policy page',
  configContentNotFound:
    "The page that customers see if they try to browse to a URL that doesn't exist",
  configContentError:
    'The error page that customers may encounter in rare circumstances',
  announcements:
    'Use these to promote new items, specials, deals, or anything else you want to tell your customers about.',
  configStyles:
    'Colors, fonts, sizes, and other styles that control the look & feel of your Open Tender app',
  configStylesGlobal:
    'General style settings that impact various parts of the app',
  configStylesInputs: 'All the styles that apply to inputs, selects, etc.',
  configStylesCards:
    'The card styles used for individual orders, items, deals, and menu items',
  configStylesCounts: 'The circle counts that appear in various places',
  configStylesColors: 'Colors used for text and backgrounds throughout the app',
  configStylesFonts: 'Font styles and sizes for primary text and headings',
  configStylesLinks: 'Colors and hover colors for various types of links',
  configStylesButtonColors:
    'Colors and hover colors for various types of buttons',
  configStylesButtonStyles:
    'Padding, border, and border radius settings for various types of buttons',
  configStylesWelcome:
    'Styles for the greeting at the top of the landing page for the online ordering section of your website and homepage of your app.',
  configStylesBuilder: 'Design settings related to the menu item modal or page',
  configStylesCategories:
    'Design settings related to the category layout on the main page of the menu',
  configStylesItems:
    'Design settings related to the the menu items layout on the main page of the menu',
  configSettings: 'Various settings that impact the ordering experience',
  configSettingsLocations: 'Names, max distance, and auto selection',
  configSettingsOrderTypes:
    'Choose which order types to display on the home page',
  configSettingsDisplay:
    'Determine display of tags, allergens, nutritional info, and other info for each order type',
  configSettingsCheckout:
    'Determine which fields should be displayed and required on the checkout page.',
  configSettingsMaps:
    'Colors, icons, and other settings that affect how the Google Map appears on your site',
  configSettingsRecaptcha:
    'Decide which credit card forms should include a Google Recaptcha v2 for additional security',
  configClients: 'Set up clients and domains for your Open Tender apps',
  orderNotifications:
    'Customize the messages sent to your customers as the status of their order is updated',
  superSalesByMonth: 'Sales, Checks, and Stores by brand over time',
  integrationOnfleet:
    'Delivery management for restaurants using in-house delivery drivers',
  posMessages:
    'A list of messages that can be included at the bottom of the POS receipt'
}

export default subtitles
