import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Upload from './Upload'
import { makeMap } from '../lib/helpers'
import { fetchFiles, deleteFile } from '../reducers/files'
import { showPageError } from '../reducers/page'
import { resetSession } from '../reducers/user'

class Files extends Component {
  static propTypes = {
    header: propTypes.object,
    pageId: propTypes.string,
    token: propTypes.string,
    brandId: propTypes.number,
    endpoint: propTypes.string,
    files: propTypes.array,
    items: propTypes.array,
    error: propTypes.string,
    fetchFiles: propTypes.func,
    deleteFile: propTypes.func,
    showPageError: propTypes.func,
    resetSession: propTypes.func
  }

  render() {
    const { endpoint, pageId, token, brandId } = this.props
    const files = makeMap(this.props.items || [], 'file_type')
    return (
      <div className="content__main">
        {this.props.files
          ? this.props.files.map(fileType => (
              <Upload
                key={fileType.type}
                name={fileType.name}
                classes={fileType.classes}
                fileType={fileType.type}
                fileObj={files[fileType.type]}
                endpoint={`${endpoint.replace(':id', pageId)}`}
                token={token}
                brandId={brandId}
                fetchFiles={this.props.fetchFiles}
                deleteFile={this.props.deleteFile}
                showFileError={this.props.showPageError}
                resetSession={this.props.resetSession}
              />
            ))
          : null}
      </div>
    )
  }
}

Files.displayName = 'Files'

export default connect(
  state => ({
    ...state.page.files,
    ...state.files,
    pageId: state.page.id,
    token: state.token,
    brandId: state.brand.brand_id
  }),
  { fetchFiles, deleteFile, showPageError, resetSession }
)(Files)
