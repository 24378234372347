import propTypes from 'prop-types'
import React from 'react'
import { formatDollars } from '../../lib/helpers'

const ToastMenuItemName = ({ item }) => (
  <span>
    {item.name} -- {formatDollars(item.price)}{' '}
    <span className="err">{item.pricingStrategy}</span> -- {item.guid}
  </span>
)

ToastMenuItemName.displayName = 'ToastMenuItemName'
ToastMenuItemName.propTypes = {
  item: propTypes.object
}

const ToastModifierGroup = ({ modifierGroup }) => (
  <li>
    {modifierGroup.name} --{' '}
    <span className="err">{modifierGroup.pricingStrategy}</span> --{' '}
    <span className="success">
      min{' '}
      {typeof modifierGroup.minSelections === 'number'
        ? modifierGroup.minSelections
        : 'none'}
      , max{' '}
      {typeof modifierGroup.maxSelections === 'number'
        ? modifierGroup.maxSelections
        : 'none'}
    </span>{' '}
    -- {modifierGroup.guid}
  </li>
)

ToastModifierGroup.displayName = 'ToastModifierGroup'
ToastModifierGroup.propTypes = {
  modifierGroup: propTypes.object
}

const ToastMenuItem = ({ menuItem: i }) => (
  <li>
    <ToastMenuItemName item={i} />
    {i.modifierGroups.map(g => (
      <ul key={g.guid} className="mx__menu__modifier-group">
        <ToastModifierGroup modifierGroup={g} />
        <ul className="mx__menu__modifier-options">
          {g.modifierOptions.map(o => (
            <li key={o.guid}>
              <ToastMenuItemName item={o} />
              {o.modifierGroups.map(og => (
                <ul key={og.guid} className="mx__menu__modifier-group">
                  <ToastModifierGroup modifierGroup={og} />
                  <ul className="mx__menu__modifier-options">
                    {og.modifierOptions.map(o => (
                      <li key={o.guid}>
                        <ToastMenuItemName item={o} />
                      </li>
                    ))}
                  </ul>
                </ul>
              ))}
            </li>
          ))}
        </ul>
      </ul>
    ))}
  </li>
)

ToastMenuItem.displayName = 'ToastMenuItem'
ToastMenuItem.propTypes = {
  menuItem: propTypes.object
}

export const ToastMenu = ({ menus, mappedMenus }) => (
  <div className="mx">
    <div className="mx__links">
      {menus.map(i => (
        <li key={i.guid}>
          <a href={`#${i.guid}`}>{i.name}</a>
          {mappedMenus.includes(i.guid) && (
            <span> -- mapped to this revenue center</span>
          )}
        </li>
      ))}
    </div>
    <div className="mx__menus">
      {menus.map(i => (
        <div className="mx__menu" key={i.guid} id={i.guid}>
          <h2>
            {i.name} ({i.guid})
          </h2>
          {i.menuGroups.map(menuGroup => (
            <div key={menuGroup.guid} className="mx__menu__group">
              <h3>{menuGroup.name}</h3>
              <ul className="mx__menu__items">
                {menuGroup.menuItems.map(menuItem => (
                  <ToastMenuItem key={menuItem.guid} menuItem={menuItem} />
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
)

ToastMenu.displayName = 'ToastMenu'
ToastMenu.propTypes = {
  menus: propTypes.array,
  mappedMenus: propTypes.array
}
