import propTypes from 'prop-types'
import React from 'react'
import { dateRangeValues } from '../../lib/constants'

export const DateRange = ({ value, handler }) => {
  return (
    <div className="report__dates">
      {dateRangeValues.map(i => (
        <button
          key={i.value}
          className={`btn ${i.value === value ? '-active' : ''}`}
          value={i.value}
          onClick={evt => handler(evt, i.value)}
        >
          {i.name}
        </button>
      ))}
    </div>
  )
}

DateRange.displayName = 'DateRange'
DateRange.propTypes = {
  value: propTypes.string,
  handler: propTypes.func
}

export default DateRange
