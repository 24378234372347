import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import {
  updateAvail,
  updateRow,
  updateColumn,
  updateAll,
  updateAvails
} from '../reducers/itemAvails'
import { CheckboxSimple } from './FormInputs'
import ClipLoader from 'react-spinners/ClipLoader'

const makeItemsList = (items, rowId, colId) => {
  if (!items) return []
  return items.map(i => `${i[rowId]}-${i[colId]}`)
}

const makeAvails = (itemsList, disabledList, { rows, cols }) => {
  if (!rows || !cols) return []
  return rows.map(row => {
    return {
      name: row.name,
      value: row.value,
      cols: cols.map(col => {
        const availId = `${row.value}-${col.value}`
        return {
          id: availId,
          checked: itemsList.indexOf(availId) >= 0,
          disabled: disabledList.indexOf(availId) >= 0
        }
      })
    }
  })
}

class ItemAvails extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    checkboxClass: propTypes.string,
    submitting: propTypes.bool,
    rowId: propTypes.string,
    colId: propTypes.string,
    rows: propTypes.array,
    cols: propTypes.array,
    items: propTypes.array,
    disabled: propTypes.array,
    error: propTypes.string,
    updateAvail: propTypes.func,
    updateRow: propTypes.func,
    updateColumn: propTypes.func,
    updateAll: propTypes.func,
    updateAvails: propTypes.func
  }

  handleCheckbox = evt => {
    const { target } = evt
    const [rowVal, colVal] = target.id.split('-')
    const avail = {
      [this.props.rowId]: parseInt(rowVal) || rowVal,
      [this.props.colId]: parseInt(colVal) || colVal
    }
    // const ids = { rowId: this.props.rowId, colId: this.props.colId }
    this.props.updateAvail(avail, target.checked)
    evt.target.blur()
  }

  handleRow = evt => {
    evt.preventDefault()
    const rowVal = parseInt(evt.target.id) || evt.target.id
    this.props.updateRow(rowVal)
    evt.target.blur()
  }

  handleColumn = evt => {
    evt.preventDefault()
    const colVal = parseInt(evt.target.id) || evt.target.id
    this.props.updateColumn(colVal)
    evt.target.blur()
  }

  handleAll = evt => {
    evt.preventDefault()
    this.props.updateAll()
    evt.target.blur()
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.updateAvails()
    this.submitButton.current.blur()
  }

  render() {
    const {
      rows,
      cols,
      items,
      disabled,
      rowId,
      colId,
      error,
      submitting
    } = this.props
    const itemsList = makeItemsList(items, rowId, colId)
    const disabledList = makeItemsList(disabled, rowId, colId)
    const avails = makeAvails(itemsList, disabledList, this.props)
    const noRows = rows && !rows.length
    // console.log(JSON.stringify(itemsList, null, 2))
    // console.log(JSON.stringify(avails, null, 2))
    return (
      <div className="content__main">
        {/* <PageError msg={error} /> */}
        {noRows ? (
          <p>
            There {"aren't"} any items in this group. Please adjust your
            filters.
          </p>
        ) : (
          !items &&
          !error.length && (
            <div className="content__loading">
              <ClipLoader size={44} color={'#5a5aff'} />
            </div>
          )
        )}
        <TransitionGroup>
          {rows && rows.length && items ? (
            <CSSTransition {...cssTransitionFade}>
              <div className="avails">
                <form id="form" className="form" onSubmit={this.handleSubmit}>
                  <div className="table-scroll">
                    <table className="table--avails table--light">
                      <thead>
                        <tr>
                          <th>
                            <button onClick={this.handleAll}>Select All</button>
                          </th>
                          {cols.map(col => (
                            <th key={`${col.name}-${col.value}`}>
                              <button
                                id={`${col.value}`}
                                onClick={this.handleColumn}
                              >
                                {col.name}
                              </button>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {avails.map(row => (
                          <tr key={`${row.name}-${row.value}`}>
                            <td>
                              <button
                                id={`${row.value}`}
                                onClick={this.handleRow}
                              >
                                {row.name}
                              </button>
                            </td>
                            {row.cols.map(col => (
                              <td key={col.id}>
                                <CheckboxSimple
                                  id={col.id}
                                  checked={col.checked}
                                  disabled={col.disabled}
                                  handler={this.handleCheckbox}
                                  klass={this.props.checkboxClass}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="form__submit">
                    <input
                      className="btn"
                      type="submit"
                      value={
                        submitting ? 'Updating...' : 'Update Availabilities'
                      }
                      disabled={submitting}
                      ref={this.submitButton}
                    />
                  </div>
                </form>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    )
  }
}

ItemAvails.displayName = 'ItemAvails'

export default connect(
  state => ({
    ...state.itemAvails
  }),
  { updateAvail, updateRow, updateColumn, updateAll, updateAvails }
)(ItemAvails)
