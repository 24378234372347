import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import {
  updateStore,
  updateBusinessDate,
  syncData,
  retrieveXml
} from '../reducers/sync'
import { dateStrToLocalDate } from '../lib/helpersDatetime'
import { Label, Select } from './FormInputs'
import DatePicker from 'react-datepicker'

export const DateFilter = ({ businessDate, handler }) => {
  const value = businessDate ? dateStrToLocalDate(businessDate) : new Date()
  return (
    <label htmlFor="datepicker" className="-half -right">
      <Label label="Business Date" />
      <DatePicker
        showPopperArrow={false}
        dateFormat="yyyy-MM-dd"
        placeholderText="choose date"
        selected={value}
        onChange={date => handler(date)}
      />
    </label>
  )
}

DateFilter.displayName = 'DateFilter'
DateFilter.propTypes = {
  businessDate: propTypes.string,
  handler: propTypes.func
}

const field = {
  label: 'Store',
  field: 'revenueCenterId',
  type: 'select',
  classes: '-half'
}

class Sync extends Component {
  constructor(props) {
    super(props)
    this.submitButton = React.createRef()
  }

  static propTypes = {
    stores: propTypes.array,
    storeId: propTypes.number,
    businessDate: propTypes.string,
    submitting: propTypes.bool,
    loading: propTypes.bool,
    xml: propTypes.string,
    updateStore: propTypes.func,
    updateBusinessDate: propTypes.func,
    syncData: propTypes.func,
    retrieveXml: propTypes.func
  }

  handleStore = evt => {
    const { value } = evt.target
    const storeId = value.length ? parseInt(value) : null
    this.props.updateStore(storeId)
  }

  handleDatepicker = date => {
    const businessDate = date ? format(new Date(date), 'yyyy-MM-dd') : null
    this.props.updateBusinessDate(businessDate)
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.props.syncData()
    evt.target.blur()
  }

  handleXml = evt => {
    evt.preventDefault()
    this.props.retrieveXml()
    evt.target.blur()
  }

  render() {
    const {
      stores,
      storeId,
      businessDate,
      submitting,
      loading,
      xml
    } = this.props
    const submitDisabled = submitting || loading || !storeId
    let storeOptions = stores.map(i => ({
      name: i.full_name,
      value: i.store_id
    }))
    storeOptions = [
      { name: 'please choose a store', value: '' },
      ...storeOptions
    ]
    return (
      <div className="mapping">
        <div className="mapping__revenue-center">
          <Select
            field={field}
            value={storeId || ''}
            options={storeOptions}
            handler={this.handleStore}
          />
          <DateFilter
            businessDate={businessDate}
            handler={this.handleDatepicker}
          />
        </div>
        <div className="mapping__mappings">
          <div className="mapping__import">
            <form
              id="form-import"
              className="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form__submit">
                <input
                  className="btn"
                  type="submit"
                  value={submitting ? 'Syncing Data...' : 'Sync Data'}
                  disabled={submitDisabled}
                  ref={this.submitButton}
                />
                <button
                  className="btn btn--secondary"
                  disabled={submitDisabled}
                  onClick={this.handleXml}
                >
                  {loading ? 'Retrieving XML...' : 'Review XML'}
                </button>
              </div>
            </form>
            {xml && (
              <div className="mapping__import__results api-request">
                <pre>{xml}</pre>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Sync.displayName = 'Sync'

export default connect(
  state => ({
    ...state.sync,
    stores: state.options['stores']
  }),
  { updateStore, updateBusinessDate, syncData, retrieveXml }
)(Sync)
