import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from '../lib/utils'
import {
  reorderCustomer,
  reorderAddress,
  reorderHouseAccount
} from '../reducers/order'
import { sendActivationEmail } from '../reducers/user'

class TabActions extends Component {
  constructor(props) {
    super(props)
    this.linkRef = React.createRef()
  }

  static propTypes = {
    page: propTypes.object,
    item: propTypes.object,
    actions: propTypes.array,
    reorderCustomer: propTypes.func,
    reorderAddress: propTypes.func,
    reorderHouseAccount: propTypes.func,
    sendActivationEmail: propTypes.func
  }

  handleClick = (evt, actionType) => {
    evt.preventDefault()
    switch (actionType) {
      case 'reorderCustomer':
        this.props.reorderCustomer()
        break
      case 'reorderAddress':
        this.props.reorderAddress()
        break
      case 'reorderHouseAccount':
        this.props.reorderHouseAccount()
        break
      case 'activateUser':
        this.props.sendActivationEmail()
        break
      default:
        break
    }
    evt.target.blur()
  }

  render() {
    const { actions, page, item } = this.props
    let filtered = actions
    if (page.route === '/users/:id') {
      const isActivated =
        !item.data ||
        isEmpty(item.data) ||
        (item.data && item.data.is_activated)
      if (isActivated) filtered = actions.filter(i => i.type !== 'activateUser')
    }
    return (
      <ul>
        {filtered.map(action => (
          <li key={action.type}>
            <button
              className="btn"
              onClick={evt => this.handleClick(evt, action.type)}
            >
              {action.name}
            </button>
          </li>
        ))}
      </ul>
    )
  }
}

TabActions.displayName = 'TabActions'

export default connect(
  state => ({
    page: state.page,
    item: state.item
  }),
  {
    reorderCustomer,
    reorderAddress,
    reorderHouseAccount,
    sendActivationEmail
  }
)(TabActions)
