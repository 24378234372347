import { loadPage } from './page'
import { closeModal } from './modal'

const initState = null

const LOAD_TOKEN = 'LOAD_TOKEN'
const CLEAR_TOKEN = 'CLEAR_TOKEN'

export const clearToken = () => ({ type: CLEAR_TOKEN })
export const loadToken = token => ({ type: LOAD_TOKEN, payload: token })
export const resetToken = token => {
  return (dispatch, getState) => {
    const { page } = getState()
    dispatch(loadToken(token))
    dispatch(loadPage(page.route, page))
    dispatch(closeModal())
  }
}

export default function(state = initState, action) {
  switch (action.type) {
    case LOAD_TOKEN:
      return action.payload
    case CLEAR_TOKEN:
      return initState
    default:
      return state
  }
}
