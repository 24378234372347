import propTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { cssTransitionFade } from '../lib/helpersPage'
import { pageType } from '../lib/constants'
import { makeName } from '../lib/helpersPage'
import { X } from 'react-feather'
import { SearchInput } from './Search'
import { toggleTabs } from '../reducers/page'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  updateQuery,
  clearQuery,
  retrieveResults,
  clearResultsOnly,
  clearSearchResults
} from '../reducers/search'
import { redirectPage } from '../reducers/page'

const makeRelationsNav = (list, { relations, route, id }) => {
  const items =
    list.items &&
    list.items.map(item => {
      const name = makeName(
        relations.field,
        item,
        relations.separator || undefined
      )
      return {
        name: name,
        link: route.replace(':idd', item[relations.id]).replace(':id', id)
      }
    })
  return {
    items: items,
    loading: list.loading,
    error: list.error
  }
}

const makeRelatedItemsNav = (list, { relatedItems, route, id }) => {
  const items =
    list.items &&
    list.items.map(item => {
      const name = makeName(
        relatedItems.field,
        item,
        relatedItems.separator || undefined
      )
      return {
        name: name,
        link: route.replace(':idd', item[relatedItems.id]).replace(':id', id)
      }
    })
  return {
    items: items,
    loading: list.loading,
    error: list.error
  }
}

const makeListNav = (list, page) => {
  const items = !page.list
    ? []
    : list.items.map(item => {
        const name = makeName(
          page.list.field,
          item,
          page.list.separator || undefined
        )
        return {
          name: name,
          link: page.route.replace(':id', item[page.list.id])
        }
      })
  return {
    items: items,
    loading: list.loading,
    error: list.error
  }
}

class Nav extends Component {
  static propTypes = {
    pathname: propTypes.string,
    page: propTypes.object,
    list: propTypes.object,
    relations: propTypes.object,
    stateSearch: propTypes.object,
    toggleTabs: propTypes.func,
    updateQuery: propTypes.func,
    clearQuery: propTypes.func,
    retrieveResults: propTypes.func,
    clearResultsOnly: propTypes.func,
    clearSearchResults: propTypes.func,
    redirectPage: propTypes.func
  }

  handleClick = evt => {
    evt.preventDefault()
    this.props.toggleTabs()
    evt.target.blur()
  }

  handleInput = evt => {
    this.props.updateQuery(evt.target.value)
  }

  handleEnter = evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.props.clearResultsOnly()
      const props = { ...this.props.page.list.search }
      this.props.retrieveResults(props)
      // this.props.clearQuery()
    }
  }

  handleClear = evt => {
    evt.preventDefault()
    this.props.clearQuery()
  }

  handleAction = (evt, id) => {
    evt.preventDefault()
    evt.target.blur()
    this.props.clearSearchResults()
    const endpoint = this.props.page.route.replace(':id', id)
    // const endpoint = `/${this.props.page.list.search.endpoint}/${id}`
    this.props.redirectPage(endpoint)
  }

  render() {
    const { page, relations, list, stateSearch } = this.props
    const { results, loading, error } = stateSearch
    const search = page.list ? page.list.search : null
    let nav = {},
      title = '',
      noList = false
    if (page.type === pageType.itemRelation) {
      nav = makeRelationsNav(relations, page)
      title = page.relations.title
      noList = !page.relations
    } else if (page.type === pageType.relatedItem) {
      nav = makeRelatedItemsNav(relations, page)
      title = page.relatedItems.title
      noList = !page.relatedItems
    } else {
      nav = makeListNav(list, page)
      title = page.list && page.list.title
      noList = !page.list
    }
    return (
      <div className="nav siblings">
        <div className="nav__cover">
          <div className="nav__overlay" />
        </div>
        {page.tabsOpen && (
          <div className="nav__close">
            <button className="btn-link" onClick={this.handleClick}>
              <X size={null} />
            </button>
          </div>
        )}
        {search ? (
          <div className="nav__container">
            <div className="nav__title">
              <div className="container">
                <SearchInput
                  label={null}
                  query={stateSearch.query}
                  placeholder={search.placeholder}
                  clearSize={14}
                  handleInput={this.handleInput}
                  handleEnter={this.handleEnter}
                  handleClear={this.handleClear}
                />
              </div>
            </div>
            <div className="nav__search">
              {error && error.length ? (
                <p className="nav__search__error">{error}</p>
              ) : (
                <>
                  {loading && (
                    <div className="nav__search__loading">
                      <ClipLoader size={20} color={'#fff'} />
                    </div>
                  )}
                  <TransitionGroup>
                    {results ? (
                      <CSSTransition {...cssTransitionFade}>
                        <nav className="nav__search__nav">
                          <ul>
                            {results.length ? (
                              results.map(result => (
                                <li key={result.id}>
                                  <button
                                    className="btn-link"
                                    onClick={evt =>
                                      this.handleAction(evt, result.id)
                                    }
                                  >
                                    {result.name}
                                  </button>
                                </li>
                              ))
                            ) : (
                              <li>No results found</li>
                            )}
                          </ul>
                        </nav>
                      </CSSTransition>
                    ) : null}
                  </TransitionGroup>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="nav__container">
            <div className="nav__title">
              <div className="container">
                <p>{title}</p>
              </div>
            </div>
            <nav className="nav__nav">
              {nav.error ? (
                <p>{nav.error}</p>
              ) : nav.items && nav.items.length ? (
                <ul>
                  {nav.items.map(item => (
                    <li key={item.link}>
                      <NavLink exact to={item.link}>
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : noList ? null : nav.loading ? (
                <p>Loading...</p>
              ) : (
                <p>No other items...</p>
              )}
            </nav>
          </div>
        )}
      </div>
    )
  }
}

Nav.displayName = 'Nav'

export default connect(
  state => ({
    page: state.page,
    list: state.list,
    relations: state.relations,
    stateSearch: state.search
  }),
  {
    toggleTabs,
    updateQuery,
    clearQuery,
    retrieveResults,
    clearResultsOnly,
    clearSearchResults,
    redirectPage
  }
)(Nav)
