import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import store from './store'
import App from './App'
import DefaultErrorBoundary from './DefaultErrorBoundary'
import packageJson from '../package.json'

global.appVersion = packageJson.version

Sentry.init({
  dsn: 'https://36e22df407f9d3f0daafbdc063d83bbf@o184422.ingest.sentry.io/1881704',
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: cancelled',
    'TypeError: Illegal invocation',
    'MapsRequestError',
    'Missing brand'
  ]
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <DefaultErrorBoundary>
        <App />
      </DefaultErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
