import { request } from '../lib/services'
import { handlePageError, clearPageError } from './page'
import { openModal, closeModal, loadingModal, showModalErrors } from './modal'
import { flashMessage } from './messages'
import { handleError } from '../lib/errors'
import { weekdaysUpper } from '../lib/constants'
import { sortItems } from '../lib/helpers'
import { timeToMinutes } from '../lib/helpersDatetime'

const initState = {
  loading: true,
  hours: []
}

export const BLOCKED_CLEAR = 'BLOCKED_CLEAR'
export const BLOCKED_LOAD = 'BLOCKED_LOAD'
export const BLOCKED_LOADING_TOGGLE = 'BLOCKED_LOADING_TOGGLE'

export const clearBlocked = () => ({ type: BLOCKED_CLEAR })
export const toggleLoading = () => ({ type: BLOCKED_LOADING_TOGGLE })
export const loadBlocked = hours => ({
  type: BLOCKED_LOAD,
  payload: hours
})

export const fetchBlocked = () => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearPageError())
    dispatch(clearBlocked())
    const endpoint = `/blocked-hours?revenue_center_id=${page.id}`
    return request(token, brand, endpoint, 'GET')
      .then(resp => {
        let hours = resp.data.map(i => ({
          ...i,
          index: `${weekdaysUpper.indexOf(i.weekday)}-${timeToMinutes(
            i.start_time
          )}`
        }))
        hours = sortItems(hours, { sortBy: 'index', sortType: 'alpha' })
        dispatch(loadBlocked(hours))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
      .finally(() => dispatch(toggleLoading()))
  }
}

export const addBlocked = () => {
  return dispatch => {
    dispatch(loadingModal())
    const config = {
      title: 'Add New Blocked Hours',
      subtitle: 'Please choose a weekday and set your start and end times',
      type: 'blockedHours'
    }
    dispatch(openModal(config))
  }
}

export const insertBlocked = values => {
  return (dispatch, getState) => {
    const { token, brand, page } = getState()
    dispatch(clearPageError())
    const data = [{ ...values, revenue_center_id: parseInt(page.id) }]
    return request(token, brand, '/blocked-hours', 'POST', data)
      .then(() => {
        dispatch(closeModal())
        dispatch(fetchBlocked())
        dispatch(flashMessage('Successfully added!'))
      })
      .catch(err => {
        dispatch(handlePageError(err, true))
        const errors = handleError(err, true)
        dispatch(showModalErrors(errors))
      })
  }
}

export const removeBlocked = index => {
  return (dispatch, getState) => {
    const { token, brand, page, blockedHours } = getState()
    dispatch(clearPageError())
    const values = blockedHours.hours.filter((h, i) => i === index)[0]
    const data = [{ ...values, revenue_center_id: parseInt(page.id) }]
    return request(token, brand, '/blocked-hours', 'DELETE', data)
      .then(() => {
        dispatch(fetchBlocked())
        dispatch(flashMessage('Successfully deleted!'))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case BLOCKED_CLEAR:
      return { ...initState }
    case BLOCKED_LOAD:
      return { ...state, hours: action.payload }
    case BLOCKED_LOADING_TOGGLE:
      return { ...state, loading: !state.loading }
    default:
      return state
  }
}
