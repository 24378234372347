import propTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { retrieveError } from '../../reducers/mapping'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatDateStr } from '../../lib/helpersDatetime'

const Orders = ({ extOrder, order }) => {
  const [json, setJson] = useState(true)

  const handleToggle = evt => {
    evt.preventDefault()
    setJson(!json)
    evt.target.blur()
  }

  return (
    <>
      {json ? (
        <p>
          3rd Party Order |{' '}
          <button className="btn-link" onClick={handleToggle}>
            Open Tender Order
          </button>
        </p>
      ) : (
        <p>
          <button className="btn-link" onClick={handleToggle}>
            3rd Party Order
          </button>{' '}
          | Open Tender Order
        </p>
      )}
      <pre>
        {json
          ? JSON.stringify(extOrder, null, 2)
          : JSON.stringify(order, null, 2)}
      </pre>
    </>
  )
}

Orders.displayName = 'Orders'
Orders.propTypes = {
  extOrder: propTypes.string,
  order: propTypes.string
}

class ImportError extends Component {
  static propTypes = {
    errorId: propTypes.number,
    errorDetail: propTypes.object,
    retrieveError: propTypes.func
  }

  componentDidMount() {
    const { errorId, retrieveError } = this.props
    retrieveError('import_error_id', errorId)
  }

  render() {
    const { errorDetail } = this.props
    return (
      <div>
        {errorDetail ? (
          <div className="api-request">
            <p>Imported at {formatDateStr(errorDetail.created_at)}</p>
            {/* <p>Imported at {errorDetail.created_at}</p> */}
            <Orders
              extOrder={errorDetail.ext_order}
              order={errorDetail.order}
            />
          </div>
        ) : (
          <ClipLoader size={24} color={'#5a5aff'} />
        )}
      </div>
    )
  }
}

ImportError.displayName = 'ImportError'

export default connect(
  state => ({
    errorDetail: state.mapping.errorDetail
  }),
  { retrieveError }
)(ImportError)
