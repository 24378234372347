import { request } from '../lib/services'
import { flashMessage } from './messages'
import { convertFromAPI } from '../lib/helpers'
import { handleError } from '../lib/errors'
import { loadItem } from './item'
import { handlePageError } from './page'

const initState = {
  loading: false,
  menu_id: null,
  discounted_quantity: null,
  discounted_items: null,
  required_quantity: null,
  required_items: null,
  data: null,
  menus: null,
  menu: null,
  errors: {}
}

export const DISCOUNT_ITEMS_CLEAR = 'DISCOUNT_ITEMS_CLEAR'
export const DISCOUNT_ITEMS_FILTERS_UPDATE = 'DISCOUNT_ITEMS_FILTERS_UPDATE'
export const DISCOUNT_ITEMS_LOADING = 'DISCOUNT_ITEMS_LOADING'
export const DISCOUNT_ITEMS_LOAD = 'DISCOUNT_ITEMS_LOAD'
export const DISCOUNT_ITEMS_MENU_LOAD = 'DISCOUNT_ITEMS_MENU_LOAD'
export const DISCOUNT_ITEMS_ERRORS = 'DISCOUNT_ITEMS_ERRORS'

export const clearDiscountItems = () => ({ type: DISCOUNT_ITEMS_CLEAR })
export const laodingDisount = () => ({ type: DISCOUNT_ITEMS_LOADING })
export const loadDiscount = data => ({
  type: DISCOUNT_ITEMS_LOAD,
  payload: data
})
export const showItemErrors = errors => ({
  type: DISCOUNT_ITEMS_ERRORS,
  payload: errors
})

const stateKeys = [
  'menu_id',
  'discounted_quantity',
  'discounted_items',
  'required_quantity',
  'required_items'
]

export const fetchDiscountedItems = () => {
  return (dispatch, getState) => {
    const { token, brand, page, options } = getState()
    const { id, item } = page
    const endpoint = `${item.endpoint}/${id}`
    dispatch(clearDiscountItems())
    dispatch(laodingDisount())
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        const data = convertFromAPI(page.route, 'item', resp)
        dispatch(loadItem(data))
        const discountData = stateKeys.reduce((obj, key) => {
          obj[key] = data[key]
          delete data[key]
          return obj
        }, {})
        discountData.data = data
        discountData.menus = options.menus
        dispatch(loadDiscount(discountData))
        // const menuId = discountData.menu_id || options.menus[0].menu_id
        // dispatch(fetchMenu(menuId))
        dispatch(fetchCategories())
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchMenu = menu_id => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/categories?menu_id=${menu_id}&with_related=true&limit=1000`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadDiscount({ menu: resp.data }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const fetchCategories = () => {
  return (dispatch, getState) => {
    const { token, brand } = getState()
    const endpoint = `/categories?is_active=true&with_related=true&limit=1000`
    request(token, brand, endpoint, 'GET')
      .then(resp => {
        dispatch(loadDiscount({ menu: resp.data }))
      })
      .catch(err => {
        dispatch(handlePageError(err))
      })
  }
}

export const updateMenu = menu_id => {
  return dispatch => {
    const data = stateKeys.reduce((obj, key) => {
      obj[key] = key === 'menu_id' ? menu_id : null
      return obj
    }, {})
    dispatch(loadDiscount(data))
    dispatch(fetchMenu(menu_id))
  }
}

export const updateField = (field, value) => {
  return dispatch => {
    dispatch(loadDiscount({ [field]: value }))
  }
}

export const updateDiscountItems = () => {
  return (dispatch, getState) => {
    const { token, brand, page, discountItems } = getState()
    const { id, item } = page
    const data = {
      ...discountItems.data,
      // menu_id: discountItems.menu_id,
      menu_id: null,
      entity_type: 'MENU_ITEM',
      discounted_quantity: discountItems.discounted_quantity || 0,
      discounted_items: discountItems.discounted_items,
      required_quantity: discountItems.required_quantity || 0,
      required_items: discountItems.required_items
    }
    delete data.discount_id
    const endpoint = `${item.endpoint}/${id}`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchDiscountedItems())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        const errors = handleError(err)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

export const removeDiscountItems = () => {
  return (dispatch, getState) => {
    const { token, brand, page, discountItems } = getState()
    const { id, item } = page
    let data = stateKeys.reduce((obj, key) => {
      obj[key] = null
      return obj
    }, {})
    data = { ...discountItems.data, ...data }
    delete data.discount_id
    const endpoint = `${item.endpoint}/${id}`
    request(token, brand, endpoint, 'PUT', data)
      .then(() => {
        window.scroll(0, 0)
        dispatch(fetchDiscountedItems())
        dispatch(flashMessage('Successfully updated!'))
      })
      .catch(err => {
        const errors = handleError(err)
        err.detail = errors.form
        dispatch(handlePageError(err))
        dispatch(showItemErrors(errors))
      })
  }
}

const discountedItemsReducer = (state = initState, action) => {
  switch (action.type) {
    case DISCOUNT_ITEMS_CLEAR:
      return { ...initState }
    case DISCOUNT_ITEMS_LOADING:
      return { ...state, loading: true }
    case DISCOUNT_ITEMS_LOAD:
      return { ...state, ...action.payload, errors: {}, loading: false }
    case DISCOUNT_ITEMS_ERRORS:
      return { ...state, errors: action.payload, loading: false }
    case DISCOUNT_ITEMS_MENU_LOAD:
      return { ...state, menu: action.payload, errors: {}, loading: false }
    default:
      return state
  }
}

export default discountedItemsReducer
